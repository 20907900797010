import { upperFirst } from "lodash";

const interestAggregationKey = "interest";

export const getAggregationPresentation = (aggregationName: string): string =>
  aggregationName === interestAggregationKey
    ? "Segment"
    : upperFirst(aggregationName);

export const getOverlapColumnPresentation = new Map<string, string>([
  ["affinity_ratio", "Affinity"],
  ["share_in_overlap", "Share in overlap"],
  ["share_in_matchable_audience", "Share in matchable publisher audience"],
  ["addressable_audience_size", "Addressable users in publisher audience"],
  ["count_users_overlap", "Count in overlap"],
]);

export interface AggregationData {
  addressableAudienceSize: number;
  affinityRatio: number;
  age?: string;
  aggregationName?: string;
  interest?: string;
  gender?: string;
  shareInMatchableAudience: number;
  shareInOverlap: number;
}

export enum OverlapInsightsVisualizeDirection {
  top = "top",
  bottom = "bottom",
}

export type OverlapInsightsChartTypes = "RADAR" | "LINE" | "TAG_CLOUD";

export enum OverlapInsightsExecutionContext {
  insightsTab = "insights_tab",
  advertiserInsights = "advertiser_insights",
  publisherInsights = "publisher_insights",
}

export type MediaDataRoomInsightsFormVisualizeValue = {
  limit: number | undefined;
  orderBy: string;
  direction: OverlapInsightsVisualizeDirection;
};

export type OverlapInsightsAggregationHookResult = {
  aggregationData: AggregationData[];
  isEmptyAggregation: boolean;
  suppressedValues: string[];
  possibleValues: string[];
  hasSuppressedValues: boolean;
  hiddenValues: (string | undefined)[];
  hasHiddenValues: boolean;
};

export const INSIGHTS_CHART_VALUE_KEY_BUTTONS: {
  label: string;
  value: string;
}[] = [
  {
    label: "Overlap",
    value: "shareInOverlap",
  },
  {
    label: "Baseline",
    value: "shareInMatchableAudience",
  },
];

export interface MediaDataRoomInsightsFormValues {
  aggregation: string;
  audienceType: string;
  chartType: OverlapInsightsChartTypes;
  visualize: MediaDataRoomInsightsFormVisualizeValue;
}
