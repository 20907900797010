import { InfoTooltip } from "@decentriq/components";
import { testIds } from "@decentriq/utils";
import {
  Box,
  Checkbox,
  FormControl,
  FormLabel,
  Stack,
  Switch,
  Typography,
} from "@mui/joy";
import { memo } from "react";
import { RawSupportedFeatures } from "features/mediaDataRoom";
import { useCreationWizardConfiguration } from "features/mediaDataRoom/contexts";
import { CollaborationTypes } from "models";

const mediaDataRoomFeaturesTooltipMap: Record<
  CollaborationTypes,
  string | null
> = {
  [CollaborationTypes.Insights]:
    "If enabled, advertiser, publisher, agency and observer users will have access to the insights dashboards.",
  [CollaborationTypes.Remarketing]:
    "If enabled, advertiser and agency users will have access to the activation tab where they can see the automatically created remarketing audiences and make them available to the publisher.",
  [CollaborationTypes.Lookalike]:
    "If enabled, advertiser and agency users will have access to the activation tab where they can configure and generate lookalike audiences and make them available to the publisher.",
  [CollaborationTypes.Measurements]:
    "If enabled, advertiser users will be able to request measurement DCRs. This is a separate DCR that does rules-based attribution on brand conversions.",
  [CollaborationTypes.ExclusionTargeting]:
    "If enabled, advertiser and agency users will have access to the activation tab where they can see the automatically created exclusion targeting audiences and make them available to the publisher.",
  [CollaborationTypes.RuleBasedAudiences]:
    "If enabled, advertiser and agency users will be able to create rule-based audiences.",
};

const enabledFeaturesTooltipMap: Record<RawSupportedFeatures, string | null> = {
  [RawSupportedFeatures.ENABLE_ADVERTISER_AUDIENCE_DOWNLOAD]:
    "If enabled, advertiser and agency users will be able to export audiences they created.",
  [RawSupportedFeatures.ENABLE_HIDE_ABSOLUTE_AUDIENCE_SIZES]:
    "If enabled, absolute audience sizes will be shown in the overlap statistics in the data tab and (if Insights enabled) in the insights statistics in the insights tab.",
  [RawSupportedFeatures.ENABLE_MODEL_PERFORMANCE_EVALUATION]: null,
};

const CollaborationTypesEditor: React.FC = () => {
  const {
    allowedCollaborationTypes: allowedFeatures,
    enabledFeatures,
    setEnabledFeatures,
    showAbsoluteAudienceSizes,
    setShowAbsoluteAudienceSizes,
    showAdvertiserAudienceDownload,
    setEnableAdvertiserAudienceDownload,
    enableAdvertiserAudienceDownload,
  } = useCreationWizardConfiguration();
  return (
    <Stack>
      <FormControl required={true}>
        <FormLabel>Collaboration types</FormLabel>
        <Stack direction="row" flexWrap="wrap" rowGap={1} spacing={2}>
          {allowedFeatures.map((feature) => {
            const checked = enabledFeatures.includes(feature);
            const disabled =
              feature === CollaborationTypes.Remarketing &&
              enabledFeatures.includes(CollaborationTypes.RuleBasedAudiences);
            const tooltip =
              feature === CollaborationTypes.Remarketing && disabled
                ? "Remarketing is automatically enabled due to Rule-based collaboration settings and cannot be changed manually"
                : mediaDataRoomFeaturesTooltipMap[feature];
            return (
              <FormControl key={feature}>
                <Checkbox
                  checked={checked}
                  data-testid={`${testIds.mediaInsightsDcr.collaborationConfigurationStepRole.allowedFeaturesHelper}${feature}`}
                  disabled={disabled}
                  label={
                    <Box alignItems="center" display="flex">
                      {feature}
                      <InfoTooltip tooltip={tooltip} top="0px" />
                    </Box>
                  }
                  onChange={() => setEnabledFeatures(feature)}
                  slotProps={{
                    label: {
                      sx: {
                        cursor: "pointer",
                        userSelect: "none",
                        zIndex: 1,
                      },
                    },
                  }}
                  sx={{ alignItems: "center", display: "flex" }}
                />
              </FormControl>
            );
          })}
        </Stack>
      </FormControl>
      <FormControl>
        <FormLabel>Options</FormLabel>
        <FormControl>
          <Typography
            component="label"
            startDecorator={
              <Switch
                checked={showAbsoluteAudienceSizes}
                onChange={(event) =>
                  setShowAbsoluteAudienceSizes(event.target.checked)
                }
              />
            }
            sx={{ cursor: "pointer", userSelect: "none" }}
          >
            Show absolute audience sizes
            <InfoTooltip
              tooltip={
                enabledFeaturesTooltipMap[
                  RawSupportedFeatures.ENABLE_HIDE_ABSOLUTE_AUDIENCE_SIZES
                ]
              }
              top="0px"
            />
          </Typography>
        </FormControl>
        {showAdvertiserAudienceDownload && (
          <FormControl>
            <Typography
              component="label"
              startDecorator={
                <Switch
                  checked={enableAdvertiserAudienceDownload}
                  onChange={(event) =>
                    setEnableAdvertiserAudienceDownload(event.target.checked)
                  }
                />
              }
              sx={{ cursor: "pointer", userSelect: "none" }}
            >
              Allow advertiser and agency to export audiences
              <InfoTooltip
                tooltip={
                  enabledFeaturesTooltipMap[
                    RawSupportedFeatures.ENABLE_ADVERTISER_AUDIENCE_DOWNLOAD
                  ]
                }
                top="0px"
              />
            </Typography>
          </FormControl>
        )}
      </FormControl>
    </Stack>
  );
};

CollaborationTypesEditor.displayName = "CollaborationTypesEditor";

export default memo(CollaborationTypesEditor);
