import { useUpdateEffect } from "ahooks";
import {
  createContext,
  memo,
  type SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useOrganizationPreferences } from "hooks";

export enum MediaDataRoomCreationStep {
  SELECT_ORGANIZATION_ROLE = "SELECT_ORGANIZATION_ROLE",
  SELECT_PUBLISHER = "SELECT_PUBLISHER",
  SELECT_DATA_SOURCE = "SELECT_DATA_SOURCE",
  SELECT_DATA_PARTNER = "SELECT_DATA_PARTNER",
  COLLABORATION_CONFIGURATION = "COLLABORATION_CONFIGURATION",
  COLLABORATION_REQUEST_TO_PUBLISHER = "COLLABORATION_REQUEST_TO_PUBLISHER",
  COLLABORATION_REQUEST_TO_DATA_PARTNER = "COLLABORATION_REQUEST_TO_DATA_PARTNER",
}

export enum MediaDataRoomOrganizationRole {
  PUBLISHER = "Publisher",
  ADVERTISER = "Advertiser",
  DATA_PARTNER = "Data Partner",
}

export interface CreationWizardStepperContextValue {
  activeStep: MediaDataRoomCreationStep;
  setActiveStep: React.Dispatch<SetStateAction<MediaDataRoomCreationStep>>;
  canSelectDataPartner: boolean;
  hasOwnDataToProvide: boolean;
  setHasOwnDataToProvide: React.Dispatch<SetStateAction<boolean>>;
  organizationRole: MediaDataRoomOrganizationRole | null;
  setOrganizationRole: (
    role: SetStateAction<MediaDataRoomOrganizationRole | null>
  ) => void;
  handleNextStep: () => void;
  handleBackStep: () => void;
}

const CreationWizardStepperContext =
  createContext<CreationWizardStepperContextValue | null>(null);

const CreationWizardStepperContextProvider =
  CreationWizardStepperContext.Provider;

export const useCreationWizardStepper = () => {
  const contextValue = useContext(CreationWizardStepperContext);
  if (contextValue === null) {
    throw new Error(
      "useCreationWizardStepper must be used within a CreationWizardStepperContextProvider"
    );
  }
  return contextValue;
};

export interface CreationWizardStepperWrapperProps
  extends React.PropsWithChildren {
  restartCreation: () => void;
}

const CreationWizardStepperWrapper = memo<CreationWizardStepperWrapperProps>(
  ({ restartCreation, children }) => {
    const {
      hasAdvertiserFeatures,
      hasPublisherFeatures,
      hasDataPartnerFeatures,
      organizationId,
      canViewDataPartners,
      numberOfSupportedMediaRoles,
    } = useOrganizationPreferences();
    const [activeStep, setActiveStep] = useState<MediaDataRoomCreationStep>(
      MediaDataRoomCreationStep.SELECT_ORGANIZATION_ROLE
    );
    const [hasOwnDataToProvide, setHasOwnDataToProvide] =
      useState<boolean>(true);
    const [organizationRole, setOrganizationRole] =
      useState<MediaDataRoomOrganizationRole | null>(null);
    const hasMultipleRolesSupported = useMemo<boolean>(
      () => numberOfSupportedMediaRoles > 1,
      [numberOfSupportedMediaRoles]
    );
    const handleNextStep = useCallback(() => {
      if (
        (activeStep === MediaDataRoomCreationStep.SELECT_PUBLISHER &&
          !canViewDataPartners) ||
        activeStep === MediaDataRoomCreationStep.SELECT_DATA_PARTNER
      ) {
        setActiveStep(MediaDataRoomCreationStep.COLLABORATION_CONFIGURATION);
        return;
      }
      if (
        activeStep === MediaDataRoomCreationStep.SELECT_PUBLISHER &&
        canViewDataPartners
      ) {
        setActiveStep(MediaDataRoomCreationStep.SELECT_DATA_SOURCE);
        return;
      }
      if (
        activeStep === MediaDataRoomCreationStep.SELECT_DATA_SOURCE &&
        hasOwnDataToProvide
      ) {
        setActiveStep(MediaDataRoomCreationStep.COLLABORATION_CONFIGURATION);
        return;
      }
      if (
        activeStep === MediaDataRoomCreationStep.SELECT_DATA_SOURCE &&
        !hasOwnDataToProvide
      ) {
        setActiveStep(MediaDataRoomCreationStep.SELECT_DATA_PARTNER);
        return;
      }
    }, [activeStep, canViewDataPartners, hasOwnDataToProvide]);
    const handleBackStep = useCallback(() => {
      if (activeStep === MediaDataRoomCreationStep.SELECT_ORGANIZATION_ROLE) {
        restartCreation();
        return;
      }
      if (
        activeStep === MediaDataRoomCreationStep.SELECT_PUBLISHER &&
        !hasMultipleRolesSupported
      ) {
        restartCreation();
        return;
      }
      if (
        activeStep === MediaDataRoomCreationStep.SELECT_PUBLISHER &&
        hasMultipleRolesSupported
      ) {
        setActiveStep(MediaDataRoomCreationStep.SELECT_ORGANIZATION_ROLE);
        setOrganizationRole(null);
        return;
      }
      if (activeStep === MediaDataRoomCreationStep.SELECT_DATA_PARTNER) {
        setActiveStep(MediaDataRoomCreationStep.SELECT_DATA_SOURCE);
        return;
      }
      if (activeStep === MediaDataRoomCreationStep.SELECT_DATA_SOURCE) {
        setActiveStep(MediaDataRoomCreationStep.SELECT_PUBLISHER);
        return;
      }
      if (
        activeStep ===
        MediaDataRoomCreationStep.COLLABORATION_REQUEST_TO_DATA_PARTNER
      ) {
        setActiveStep(MediaDataRoomCreationStep.SELECT_DATA_PARTNER);
        return;
      }
      if (
        activeStep ===
        MediaDataRoomCreationStep.COLLABORATION_REQUEST_TO_PUBLISHER
      ) {
        setActiveStep(MediaDataRoomCreationStep.SELECT_PUBLISHER);
        return;
      }
      if (
        activeStep === MediaDataRoomCreationStep.COLLABORATION_CONFIGURATION &&
        hasMultipleRolesSupported
      ) {
        setActiveStep(MediaDataRoomCreationStep.SELECT_ORGANIZATION_ROLE);
        setOrganizationRole(null);
        return;
      }
      if (
        activeStep === MediaDataRoomCreationStep.COLLABORATION_CONFIGURATION &&
        !hasMultipleRolesSupported
      ) {
        restartCreation();
        return;
      }
    }, [
      setOrganizationRole,
      setActiveStep,
      activeStep,
      hasMultipleRolesSupported,
      restartCreation,
    ]);
    const canSelectDataPartner = useMemo(
      () =>
        organizationRole === MediaDataRoomOrganizationRole.ADVERTISER &&
        canViewDataPartners,
      [organizationRole, canViewDataPartners]
    );
    useEffect(() => {
      if (hasMultipleRolesSupported) {
        setActiveStep(MediaDataRoomCreationStep.SELECT_ORGANIZATION_ROLE);
        return;
      }
      if (hasAdvertiserFeatures) {
        setActiveStep(MediaDataRoomCreationStep.SELECT_PUBLISHER);
        setOrganizationRole(MediaDataRoomOrganizationRole.ADVERTISER);
        return;
      }
      if (hasDataPartnerFeatures || hasPublisherFeatures) {
        setActiveStep(MediaDataRoomCreationStep.COLLABORATION_CONFIGURATION);
        setOrganizationRole(
          hasPublisherFeatures
            ? MediaDataRoomOrganizationRole.PUBLISHER
            : MediaDataRoomOrganizationRole.DATA_PARTNER
        );
      }
    }, [
      hasMultipleRolesSupported,
      hasAdvertiserFeatures,
      hasPublisherFeatures,
      hasDataPartnerFeatures,
      organizationId,
    ]);
    useUpdateEffect(() => {
      if (organizationRole === MediaDataRoomOrganizationRole.ADVERTISER) {
        setActiveStep(MediaDataRoomCreationStep.SELECT_PUBLISHER);
        return;
      }
      if (
        organizationRole === MediaDataRoomOrganizationRole.PUBLISHER ||
        organizationRole === MediaDataRoomOrganizationRole.DATA_PARTNER
      ) {
        setActiveStep(MediaDataRoomCreationStep.COLLABORATION_CONFIGURATION);
      }
    }, [organizationRole]);
    const contextValue: CreationWizardStepperContextValue = useMemo(
      () => ({
        activeStep,
        canSelectDataPartner,
        handleBackStep,
        handleNextStep,
        hasOwnDataToProvide,
        organizationRole,
        setActiveStep,
        setHasOwnDataToProvide,
        setOrganizationRole,
      }),
      [
        activeStep,
        setActiveStep,
        organizationRole,
        setOrganizationRole,
        handleBackStep,
        canSelectDataPartner,
        handleNextStep,
        hasOwnDataToProvide,
        setHasOwnDataToProvide,
      ]
    );
    return (
      <CreationWizardStepperContextProvider value={contextValue}>
        {children}
      </CreationWizardStepperContextProvider>
    );
  }
);

CreationWizardStepperWrapper.displayName = "CreationWizardStepperWrapper";

export default CreationWizardStepperWrapper;
