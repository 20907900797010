import { faTable } from "@fortawesome/pro-light-svg-icons";
import { memo } from "react";
import { SidebarMenuItem } from "features/sidebar";

const DatasetsSidebarMenuItem = () => (
  <SidebarMenuItem
    data-testid="sidebar_datasets"
    icon={faTable}
    innerMenuItems={[
      { label: "Datasets", to: "/datasets/datasets" },
      { label: "Imports/Exports", to: "/datasets/external" },
    ]}
    label="Datasets"
    to="/datasets"
    tooltipTitle="Datasets"
  />
);

DatasetsSidebarMenuItem.displayName = "DatasetsSidebarMenuItem";

export default memo(DatasetsSidebarMenuItem);
