import { type FetchResult } from "@apollo/client";
import {
  useCreateDataLabMutation,
  useDataLabsQuery,
} from "@decentriq/graphql/dist/hooks";
import {
  type CreateDataLabInput,
  type CreateDataLabMutation,
  DataLabsDocument,
} from "@decentriq/graphql/dist/types";
import { createContext, type ReactNode, useCallback, useContext } from "react";
import { type DataLab } from "features/dataLabs/models";

const DataLabsContext = createContext<{
  dataLabs: {
    data?: DataLab[];
    loading: boolean;
  };
  createDataLab: (
    input: CreateDataLabInput
  ) => Promise<FetchResult<CreateDataLabMutation> | undefined>;
}>({
  createDataLab: async () => undefined,
  dataLabs: {
    loading: false,
  },
});

export const DataLabsWrapper: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const dataLabsResponse = useDataLabsQuery();
  const [createDataLabMutation] = useCreateDataLabMutation();
  const createDataLab = useCallback(
    async (input: CreateDataLabInput) => {
      return await createDataLabMutation({
        update: (cache, { data }) => {
          const record = data?.dataLab?.create?.record;
          if (!record) {
            return;
          }
          cache.updateQuery({ query: DataLabsDocument }, (data) => ({
            dataLabs: {
              nodes: [
                ...(data?.dataLabs.nodes ?? []),
                {
                  ...record,
                  demographicsDataset: null,
                  embeddingsDataset: null,
                  jobsDriverAttestationHash: null,
                  segmentsDataset: null,
                  statistics: null,
                  statisticsComputeJobId: null,
                  usersDataset: null,
                  validationComputeJobId: null,
                },
              ],
            },
          }));
        },
        variables: {
          input,
        },
      });
    },
    [createDataLabMutation]
  );
  return (
    <DataLabsContext.Provider
      value={{
        createDataLab,
        dataLabs: {
          data: dataLabsResponse.data?.dataLabs.nodes,
          loading: dataLabsResponse.loading,
        },
      }}
    >
      {children}
    </DataLabsContext.Provider>
  );
};

export const useDataLabsContext = () => useContext(DataLabsContext);
