import { memo } from "react";
import { EnableImmutabilityCheckbox } from "components";

interface ParticipantsInteractivitySettingsProps {
  id: string;
}

const ParticipantsInteractivitySettings: React.FC<ParticipantsInteractivitySettingsProps> =
  memo(({ id, ...restProps }) => {
    return <EnableImmutabilityCheckbox idForDataRoom={id} {...restProps} />;
  });
ParticipantsInteractivitySettings.displayName =
  "ParticipantsInteractivitySettings";

export default ParticipantsInteractivitySettings;
