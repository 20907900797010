import { useAuth0 } from "@auth0/auth0-react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useBoolean } from "ahooks";
import {
  memo,
  type PropsWithChildren,
  useCallback,
  useMemo,
  useState,
} from "react";
import {
  EnclaveKeychain,
  KeychainItemKind,
  type KeychainItemWithAcl,
} from "services";
import { useApiCore, useKeychain } from "contexts";
import {
  DatasetEditPermissionDialog,
  DatasetShareDialog,
} from "features/datasets/components";
import {
  type DatasetPemissionsContextValue,
  DatasetPermissionProvider,
} from "features/datasets/contexts";
import { type DatasetPermission } from "features/datasets/models";
import {
  CommonSnackbarOrigin,
  mapErrorToGeneralSnackbar,
  useGeneralSnackbar,
} from "hooks";
import { logWarning } from "utils";

type DatasetPermissionWrapperProps = PropsWithChildren<{
  manifestHash: string;
  skipFetching: boolean;
}>;

export const DatasetPermissionWrapper = memo<DatasetPermissionWrapperProps>(
  ({ children, manifestHash, skipFetching }) => {
    const { user } = useAuth0();
    const currentUserEmail = user?.email ?? "";
    const queryClient = useQueryClient();
    const keychain = useKeychain();
    const { enqueueSnackbar } = useGeneralSnackbar({
      origin: CommonSnackbarOrigin.DATASET_PORTAL,
    });
    const hasMigratedKeychain = keychain instanceof EnclaveKeychain;
    const { getSessionV2 } = useApiCore();
    const [
      isShareDialogOpen,
      { setTrue: openShareDialog, setFalse: closeShareDialog },
    ] = useBoolean(false);
    const [selectedPermission, setSelectedPermission] =
      useState<DatasetPermission | null>(null);
    const [isAddingPermission, setIsAddingPermission] =
      useState<boolean>(false);
    const [isEditingPermisison, setIsEditingPermisison] =
      useState<boolean>(false);
    const permissionQueryKey = useMemo(
      () => ["dataset", manifestHash, "permissions"],
      [manifestHash]
    );
    const { data, isLoading } = useQuery<KeychainItemWithAcl | null>({
      enabled: hasMigratedKeychain && !skipFetching && Boolean(manifestHash),
      queryFn: async () => {
        if (!hasMigratedKeychain) {
          return null;
        }
        const keychainItem = await keychain.getItem(
          manifestHash,
          KeychainItemKind.Dataset
        );
        return keychainItem;
      },
      queryKey: permissionQueryKey,
    });
    const setKeychainItemPermissions = useCallback(
      async (
        manifestHash: string,
        kind: KeychainItemKind,
        updater: (permissions: DatasetPermission[]) => DatasetPermission[],
        throwOnError: boolean = true
      ): Promise<boolean> => {
        if (!hasMigratedKeychain) {
          throw Error("Unable to add permission without Enclave keychain");
        }
        try {
          const session = await getSessionV2();
          const keychainItem = await keychain.getItem(manifestHash, kind);
          const updated = await session.updateSecretAcl(
            keychainItem.secretId,
            {
              type: "UsersList",
              users: updater(keychainItem.acl),
            },
            keychainItem.casIndex
          );
          return updated;
        } catch (error) {
          if (throwOnError) {
            throw error;
          }
          logWarning(
            `Failed to update keychain item permissions for id: ${manifestHash} and kind ${kind}`,
            error
          );
          return false;
        }
      },
      [getSessionV2, hasMigratedKeychain, keychain]
    );
    const addPermission = useCallback(
      async (permission: DatasetPermission) => {
        try {
          setIsAddingPermission(true);
          const updater = (
            permissions: DatasetPermission[]
          ): DatasetPermission[] => [...permissions, permission];
          const updated = await setKeychainItemPermissions(
            manifestHash,
            KeychainItemKind.Dataset,
            updater
          );
          if (!updated) {
            throw Error("Permissions state is outdated");
          }
          await setKeychainItemPermissions(
            manifestHash,
            KeychainItemKind.DatasetMetadata,
            updater,
            false
          );
          // TODO: Add more granular update of the query result
          queryClient.invalidateQueries({ queryKey: permissionQueryKey });
          enqueueSnackbar(`Dataset successfully shared with ${permission.id}`, {
            variant: "info",
          });
        } catch (error) {
          enqueueSnackbar(
            ...mapErrorToGeneralSnackbar(error, "Failed to share dataset")
          );
        } finally {
          setIsAddingPermission(false);
        }
      },
      [
        queryClient,
        permissionQueryKey,
        manifestHash,
        enqueueSnackbar,
        setIsAddingPermission,
        setKeychainItemPermissions,
      ]
    );
    const changePermission = useCallback(
      async (permission: DatasetPermission) => {
        try {
          setIsEditingPermisison(true);
          const updater = (
            permissions: DatasetPermission[]
          ): DatasetPermission[] =>
            permissions.map((p) => (p.id === permission.id ? permission : p));
          const updated = await setKeychainItemPermissions(
            manifestHash,
            KeychainItemKind.Dataset,
            updater
          );
          if (!updated) {
            throw Error("Permissions state is outdated");
          }
          await setKeychainItemPermissions(
            manifestHash,
            KeychainItemKind.DatasetMetadata,
            updater,
            false
          );
          // TODO: Add more granular update of the query result
          queryClient.invalidateQueries({ queryKey: permissionQueryKey });
          enqueueSnackbar(
            `Dataset permission successfully updated for ${permission.id}`,
            {
              variant: "info",
            }
          );
        } catch (error) {
          enqueueSnackbar(
            ...mapErrorToGeneralSnackbar(error, "Failed to update permission")
          );
        } finally {
          setIsEditingPermisison(false);
        }
      },
      [
        queryClient,
        permissionQueryKey,
        manifestHash,
        enqueueSnackbar,
        setIsEditingPermisison,
        setKeychainItemPermissions,
      ]
    );
    const permissions = useMemo<DatasetPermission[]>(
      () => data?.acl ?? [],
      [data?.acl]
    );
    const isCurrentUserOwner = useMemo(
      () => data?.isUserOwner(currentUserEmail) ?? false,
      [data, currentUserEmail]
    );
    const canDeleteDatasetOrDefault = useCallback(
      (defaultValue?: boolean) =>
        hasMigratedKeychain ? isCurrentUserOwner : Boolean(defaultValue),
      [hasMigratedKeychain, isCurrentUserOwner]
    );
    const canDeprovisionDatasetOrDefault = useCallback(
      (defaultValue?: boolean) =>
        hasMigratedKeychain ? isCurrentUserOwner : Boolean(defaultValue),
      [hasMigratedKeychain, isCurrentUserOwner]
    );
    const canShareDatasetOrDefault = useCallback(
      (defaultValue?: boolean) =>
        hasMigratedKeychain ? isCurrentUserOwner : Boolean(defaultValue),
      [hasMigratedKeychain, isCurrentUserOwner]
    );
    const canViewDatasetImportDetailsOrDefault = useCallback(
      (defaultValue?: boolean) =>
        hasMigratedKeychain ? isCurrentUserOwner : Boolean(defaultValue),
      [hasMigratedKeychain, isCurrentUserOwner]
    );
    const canExportDatasetOrDefault = useCallback(
      (defaultValue?: boolean) =>
        hasMigratedKeychain
          ? (data?.isUserOwnerOrUser(currentUserEmail) ?? false)
          : Boolean(defaultValue),
      [hasMigratedKeychain, data, currentUserEmail]
    );
    const canEditPermissionsOrDefault = useCallback(
      (defaultValue?: boolean) =>
        hasMigratedKeychain
          ? isCurrentUserOwner && (data?.hasMultipleOwners ?? false)
          : Boolean(defaultValue),
      [hasMigratedKeychain, isCurrentUserOwner, data?.hasMultipleOwners]
    );
    const value = useMemo<DatasetPemissionsContextValue>(
      () => ({
        addPermission,
        canDeleteDatasetOrDefault,
        canDeprovisionDatasetOrDefault,
        canEditPermissionsOrDefault,
        canExportDatasetOrDefault,
        canShareDatasetOrDefault,
        canViewDatasetImportDetailsOrDefault,
        changePermission,
        hasMigratedKeychain,
        isAddingPermission,
        isEditingPermisison,
        isPermissionsDataLoading: isLoading,
        openEditDialog: setSelectedPermission,
        openShareDialog,
        permissions,
      }),
      [
        addPermission,
        changePermission,
        isLoading,
        isEditingPermisison,
        setSelectedPermission,
        openShareDialog,
        isAddingPermission,
        canDeleteDatasetOrDefault,
        canShareDatasetOrDefault,
        canEditPermissionsOrDefault,
        canExportDatasetOrDefault,
        canViewDatasetImportDetailsOrDefault,
        canDeprovisionDatasetOrDefault,
        permissions,
        hasMigratedKeychain,
      ]
    );
    return (
      <DatasetPermissionProvider value={value}>
        {children}
        <DatasetShareDialog
          onCancel={closeShareDialog}
          open={isShareDialogOpen}
        />
        {selectedPermission && (
          <DatasetEditPermissionDialog
            onCancel={() => setSelectedPermission(null)}
            open={Boolean(selectedPermission)}
            permission={selectedPermission}
          />
        )}
      </DatasetPermissionProvider>
    );
  }
);

DatasetPermissionWrapper.displayName = "DatasetPermissionWrapper";

export default DatasetPermissionWrapper;
