import { testIds } from "@decentriq/utils";
import { FormControl, Grid, Option, Select } from "@mui/joy";
import { useMatchNodeDataSources } from "../../hooks";

interface TablesSelectorProps {
  id: string;
  readOnly?: boolean;
}

const TablesSelector: React.FC<TablesSelectorProps> = ({
  id,
  readOnly = false,
}) => {
  const {
    availableDataSources,
    selectedLeftDataSourceNodeId,
    setSelectedLeftDataSourceNodeId,
    selectedRightDataSourceNodeId,
    setSelectedRightDataSourceNodeId,
  } = useMatchNodeDataSources(id);
  return (
    <Grid container={true} spacing={2}>
      <Grid alignItems="center" display="flex" xs={1}>
        Matching
      </Grid>
      <Grid alignItems="center" display="flex" xs="auto">
        <FormControl>
          <Select
            disabled={readOnly}
            onChange={(event, value) => {
              if (value !== null && value !== selectedLeftDataSourceNodeId) {
                setSelectedLeftDataSourceNodeId(value);
              }
            }}
            renderValue={(selectedOption) =>
              (selectedOption &&
                availableDataSources.find((o) => o.id === selectedOption.value)
                  ?.name) ||
              "Data source"
            }
            slotProps={{
              button: {
                "data-testid": testIds.computeNode.matchingNode.compareSelect,
              },
            }}
            sx={{ textAlign: "center", width: "280px" }}
            value={selectedLeftDataSourceNodeId || ""}
          >
            {availableDataSources.map(({ name, id }) => (
              <Option
                data-testid={`${testIds.computeNode.matchingNode.compareSelectHelper}${name}`}
                key={id}
                value={id}
              >
                {name}
              </Option>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid alignItems="center" display="flex" xs="auto">
        with
      </Grid>
      <Grid alignItems="center" display="flex" xs="auto">
        <FormControl>
          <Select
            disabled={readOnly}
            onChange={(event, value) => {
              if (value !== null && value !== selectedRightDataSourceNodeId) {
                setSelectedRightDataSourceNodeId(value);
              }
            }}
            renderValue={(selectedOption) => {
              return (
                (selectedOption &&
                  availableDataSources.find(
                    (o) => o.id === selectedOption.value
                  )?.name) ||
                "Data source"
              );
            }}
            slotProps={{
              button: {
                "data-testid": testIds.computeNode.matchingNode.compareToSelect,
              },
            }}
            sx={{ textAlign: "center", width: "280px" }}
            value={selectedRightDataSourceNodeId || ""}
          >
            {availableDataSources.map(({ name, id }) => (
              <Option
                data-testid={`${testIds.computeNode.matchingNode.compareToSelectHelper}${name}`}
                key={id}
                value={id}
              >
                {name}
              </Option>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
};
TablesSelector.displayName = "TablesSelector";

export default TablesSelector;
