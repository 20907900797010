import { useDataConnectorJobQuery } from "@decentriq/graphql/dist/hooks";

type ConnectionTooltipData = {
  seatId: string | undefined;
  userDefinedAudienceName: string | undefined;
};

const useSplickyConnectionConfigurationData = (
  connectionId: string
): ConnectionTooltipData => {
  const { data: { dataConnectorJob } = {} } = useDataConnectorJobQuery({
    skip: !connectionId,
    variables: { id: connectionId },
  });

  const { seatId, userDefinedAudienceName } = dataConnectorJob?.config || {};

  return {
    seatId,
    userDefinedAudienceName,
  };
};

export default useSplickyConnectionConfigurationData;
