import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Modal,
  ModalDialog,
} from "@mui/joy";
import { memo } from "react";
import { DataLabsWrapper } from "features/dataLabs";
import DataLabConnectionTable from "./DataLabConnectionTable";
import usePublisherDataNodeActions from "./usePublisherDataNodeActions";

interface DataLabConnectDialogProps {
  open: boolean;
  onCancel: () => void;
}

const DataLabConnectDialog = memo<DataLabConnectDialogProps>(
  ({ open, onCancel }) => {
    const actions = usePublisherDataNodeActions();
    const { connecting } = actions;
    return (
      <Modal onClose={connecting ? undefined : onCancel} open={open}>
        <ModalDialog>
          <DialogTitle>Provision datalab</DialogTitle>
          <Divider />
          <DialogContent>
            <DataLabsWrapper>
              <DataLabConnectionTable {...actions} onCancel={onCancel} />
            </DataLabsWrapper>
          </DialogContent>
          <Divider />
          <DialogActions>
            <Button disabled={connecting} onClick={onCancel}>
              Cancel
            </Button>
          </DialogActions>
        </ModalDialog>
      </Modal>
    );
  }
);

DataLabConnectDialog.displayName = "DataLabConnectDialog";

export default DataLabConnectDialog;
