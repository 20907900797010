import { Dropdown, Menu, MenuButton, Tooltip } from "@mui/joy";
import { memo, useState } from "react";
import { type ActivationType, type Audience } from "features/MediaInsightsDcr";
import {
  ActivationDownloadMenuItem,
  SaveAsDatasetMenuItem,
} from "./components";

type ExportAudienceButtonProps = {
  activationType: ActivationType;
  activatedAudiences: Audience[];
  audienceType: string;
  reach: number;
};

const ExportAudienceButton: React.FC<ExportAudienceButtonProps> = ({
  activatedAudiences,
  activationType,
  reach,
  audienceType,
}) => {
  const popupId = "export-audience-menu";
  const [isExportAudienceLoading, setExportAudienceLoading] = useState(false);
  return (
    <Dropdown>
      <Tooltip title="Export audience with the list of activation IDs">
        <MenuButton
          loading={isExportAudienceLoading}
          loadingPosition="start"
          variant="plain"
        >
          Export
        </MenuButton>
      </Tooltip>
      <Menu
        id={popupId}
        placement="bottom-end"
        sx={{ "--ListItemDecorator-size": "1.625rem" }}
      >
        <SaveAsDatasetMenuItem
          activatedAudiences={activatedAudiences}
          activationType={activationType}
          audienceType={audienceType}
          reach={reach}
          setExportAudienceLoading={setExportAudienceLoading}
        />

        <ActivationDownloadMenuItem
          activatedAudiences={activatedAudiences}
          activationType={activationType}
          audienceType={audienceType}
          reach={reach}
          setExportAudienceLoading={setExportAudienceLoading}
        />
      </Menu>
    </Dropdown>
  );
};
ExportAudienceButton.displayName = "ExportAudienceButton";

export default memo(ExportAudienceButton);
