import { testIds } from "@decentriq/utils";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Stack,
  Tooltip,
  Typography,
} from "@mui/joy";
import { memo } from "react";
import {
  useCreationWizardConfiguration,
  useCreationWizardStepper,
} from "features/mediaDataRoom/contexts";
import CollaborationTypesEditor from "./CollaborationTypesEditor";
import DataRoomName from "./DataRoomName";
import MatchingIdSelector from "./MatchingIdSelector";
import RolesAndParticipants from "./RolesAndParticipants";

const CollaborationConfigurationStep: React.FC = () => {
  const { contactButtonTooltip, handleSubmit } =
    useCreationWizardConfiguration();
  const { handleBackStep } = useCreationWizardStepper();
  return (
    <>
      <DialogTitle>Create new media clean room</DialogTitle>
      <Divider />
      <DialogContent>
        <Stack>
          <DataRoomName />
          <RolesAndParticipants />
          <Stack>
            <Typography>3. Media clean room configuration</Typography>
            <MatchingIdSelector />
            <CollaborationTypesEditor />
          </Stack>
        </Stack>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={handleBackStep}>Back</Button>
        <Tooltip title={contactButtonTooltip}>
          <Button
            color="primary"
            data-testid={
              testIds.mediaInsightsDcr.mediaInsightsDcrCreateWizard.submitButton
            }
            onClick={handleSubmit}
            variant="solid"
          >
            Publish
          </Button>
        </Tooltip>
      </DialogActions>
    </>
  );
};

CollaborationConfigurationStep.displayName = "CollaborationConfigurationStep";

export default memo(CollaborationConfigurationStep);
