import { type VariantType } from "notistack";
import { type EnqueueSnackbarOptions } from "hooks";
import { getEffectiveErrorMessage } from "utils";

const mapErrorToGeneralSnackbar = (
  originalError: unknown,
  message: string
): [string, EnqueueSnackbarOptions<VariantType> | undefined] => {
  message = message.endsWith(".") ? message.slice(0, -1) : message;
  return [
    "An error occurred. Please refresh your browser and try again. If the error persists, please report it to Decentriq using the button to the right.",
    {
      context: `${message}. ${getEffectiveErrorMessage(originalError)}`,
      persist: true,
      variant: "error",
    },
  ];
};

export default mapErrorToGeneralSnackbar;
