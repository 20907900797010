import { useAuth0 } from "@auth0/auth0-react";
import {
  type MatchingColumnFormat,
  type TableColumnHashingAlgorithm,
} from "@decentriq/graphql/dist/types";
import { useUpdateEffect } from "ahooks";
import React, {
  createContext,
  type Dispatch,
  memo,
  type SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  DEFAULT_PARTICIPANTS_EMAILS,
  MediaDataRoomUserRole,
  type PublishMediaDataRoomInput,
} from "features/mediaDataRoom/models";
import { CollaborationTypes, mediaDataRoomCollaborationTypes } from "models";
import { useCreationWizardDataPartner } from "./CreationWizardDataPartnerContext";
import { useCreationWizardPublisher } from "./CreationWizardPublisherContext";
import {
  MediaDataRoomCreationStep,
  MediaDataRoomOrganizationRole,
  useCreationWizardStepper,
} from "./CreationWIzardStepperContext";

interface CreationWizardConfigurationContextValue {
  hasSelectedPublisher: boolean;
  hasSelectedDataPartner: boolean;
  dataRoomName: string;
  setDataRoomName: (name: SetStateAction<string>) => void;
  enabledFeatures: CollaborationTypes[];
  setEnabledFeatures: (feature: CollaborationTypes) => void;
  setWithObserver: (withObserver: SetStateAction<boolean>) => void;
  setWithAgency: (withAgency: SetStateAction<boolean>) => void;
  withObserver: boolean;
  withAgency: boolean;
  withDataPartner: boolean;
  showAbsoluteAudienceSizes: boolean;
  setShowAbsoluteAudienceSizes: (
    showAudienceSizes: SetStateAction<boolean>
  ) => void;
  matchingIdFormat: MatchingColumnFormat | null;
  matchingIdHashingAlgorithm: TableColumnHashingAlgorithm | null;
  setMatchingIdFormat: (matchingIdFormat: MatchingColumnFormat) => void;
  setMatchingIdHashingAlgorithm: (
    tableColumnHashingAlgorithm: TableColumnHashingAlgorithm | null
  ) => void;
  addParticipantEmail: (role: MediaDataRoomUserRole, email: string) => void;
  removeParticipantEmail: (role: MediaDataRoomUserRole, email: string) => void;
  participantsEmails: Map<MediaDataRoomUserRole, string[]>;
  mainPublisherUserEmail: string;
  mainAdvertiserUserEmail: string;
  setMainPublisherUserEmail: (email: SetStateAction<string>) => void;
  setMainAdvertiserUserEmail: (email: SetStateAction<string>) => void;
  requestForCollaborationMessage: string;
  collaborationRequestRecipientId: string | undefined;
  setRequestForCollaborationMessage: (message: SetStateAction<string>) => void;
  getValues: () => PublishMediaDataRoomInput;
  handleSendCollaborationRequest: () => Promise<void>;
  handleSubmit: () => Promise<void>;
  allowedCollaborationTypes: CollaborationTypes[];
  showAdvertiserAudienceDownload: boolean;
  enableAdvertiserAudienceDownload: boolean;
  setEnableAdvertiserAudienceDownload: (
    enableAdvertiserAudienceDownload: SetStateAction<boolean>
  ) => void;
  canProceedToConfiguration: boolean;
  contactButtonEnabled: boolean;
  contactButtonTooltip: string | null;
  setParticipantsEmails: Dispatch<
    SetStateAction<Map<MediaDataRoomUserRole, string[]>>
  >;
}

const CreationWizardConfigurationContext =
  createContext<CreationWizardConfigurationContextValue | null>(null);

const CreationWizardConfigurationContextProvider =
  CreationWizardConfigurationContext.Provider;

export const useCreationWizardConfiguration = () => {
  const contextValue = useContext(CreationWizardConfigurationContext);
  if (contextValue === null) {
    throw new Error(
      "useCreationWizardConfiguration must be used within a CreationWizardConfigurationWrapper"
    );
  }
  return contextValue;
};

export interface CreationWizardConfigurationWrapperProps
  extends React.PropsWithChildren {
  submit: (input: PublishMediaDataRoomInput) => Promise<void>;
  sendCollaborationRequest: (input: {
    requestRecipientId: string;
    message: string;
    receiver: "publisher" | "dataPartner";
  }) => Promise<void>;
}

const CreationWizardConfigurationWrapper =
  memo<CreationWizardConfigurationWrapperProps>(
    ({ children, submit, sendCollaborationRequest }) => {
      const { user = {} } = useAuth0();
      const { email: currentUserEmail } = user || {};
      const { organizationRole, activeStep, canSelectDataPartner } =
        useCreationWizardStepper();
      const {
        selectedPublisher,
        hasSelectedPublisher,
        hasSkippedSelection: hasSkippedPublisherSelection,
      } = useCreationWizardPublisher();
      const {
        selectedDataPartner,
        hasSelectedDataPartner,
        hasSkippedSelection: hasSkippedDataPartnerSelection,
      } = useCreationWizardDataPartner();
      const [dataRoomName, setDataRoomName] = useState<string>("");
      // Participants
      const withDataPartner = useMemo<boolean>(
        () =>
          (canSelectDataPartner && !hasSkippedDataPartnerSelection) ||
          organizationRole === MediaDataRoomOrganizationRole.DATA_PARTNER,
        [canSelectDataPartner, organizationRole, hasSkippedDataPartnerSelection]
      );
      const [withAgency, setWithAgency] = useState<boolean>(false);
      const [withObserver, setWithObserver] = useState<boolean>(false);
      const [participantsEmails, setParticipantsEmails] = useState<
        Map<MediaDataRoomUserRole, string[]>
      >(DEFAULT_PARTICIPANTS_EMAILS);
      // Handle main participant and main advertiser - to use their emails for showing Org data in the published Media DCR
      const [mainPublisherUserEmail, setMainPublisherUserEmail] =
        useState<string>("");
      const [mainAdvertiserUserEmail, setMainAdvertiserUserEmail] =
        useState<string>("");
      const [showAbsoluteAudienceSizes, setShowAbsoluteAudienceSizes] =
        useState(true);
      const [
        enableAdvertiserAudienceDownload,
        setEnableAdvertiserAudienceDownload,
      ] = useState(false);
      const addParticipantEmail = useCallback(
        (role: MediaDataRoomUserRole, email: string) => {
          const participantsEmailsClone = new Map(participantsEmails);
          const existingParticipants: string[] =
            participantsEmailsClone.get(role) || [];
          const newParticipantEmailsList = participantsEmailsClone.set(role, [
            ...existingParticipants,
            email,
          ]);
          setParticipantsEmails(newParticipantEmailsList);
        },
        [participantsEmails]
      );
      const removeParticipantEmail = useCallback(
        (role: MediaDataRoomUserRole, email: string) => {
          const participantsEmailsClone = new Map(participantsEmails);
          const existingParticipants: string[] =
            participantsEmailsClone.get(role) || [];
          const newParticipantEmailsList = participantsEmailsClone.set(
            role,
            existingParticipants.filter(
              (existingEmail) => existingEmail !== email
            )
          );
          setParticipantsEmails(newParticipantEmailsList);
        },
        [participantsEmails]
      );
      // Set list of available DCR features transformed to enum strings instead of booleans
      const [enabledFeatures, setEnabledFeatures] = useState<
        CollaborationTypes[]
      >([]);
      const handleSetEnabledFeatures = useCallback(
        (feature: CollaborationTypes) => {
          setEnabledFeatures((enabledFeatures) =>
            enabledFeatures.includes(feature)
              ? enabledFeatures.filter(
                  (enabledFeature) => enabledFeature !== feature
                )
              : [...enabledFeatures, feature]
          );
          // ShowAbsoluteAudienceSizes should always be set as true by default
          // even if Insights feature disables
          if (feature === CollaborationTypes.Insights) {
            setShowAbsoluteAudienceSizes(true);
          }
        },
        [setEnabledFeatures, setShowAbsoluteAudienceSizes]
      );
      const mapCollaborationTypes = useCallback(
        ({
          allowInsights,
          allowRemarketing,
          allowLookalike,
          allowRuleBasedAudiences,
        }: {
          allowInsights: boolean | undefined;
          allowRemarketing: boolean | undefined;
          allowLookalike: boolean | undefined;
          allowRuleBasedAudiences: boolean | undefined;
        }) => [
          ...(allowInsights ? [CollaborationTypes.Insights] : []),
          ...(allowRemarketing ? [CollaborationTypes.Remarketing] : []),
          ...(allowLookalike ? [CollaborationTypes.Lookalike] : []),
          ...(allowRuleBasedAudiences
            ? [CollaborationTypes.RuleBasedAudiences]
            : []),
        ],
        []
      );
      const allowedCollaborationTypes = useMemo(() => {
        if (!hasSelectedDataPartner && !hasSelectedPublisher) {
          return mediaDataRoomCollaborationTypes;
        }
        if (hasSelectedPublisher && !hasSelectedDataPartner) {
          return mapCollaborationTypes({
            allowInsights: selectedPublisher?.allowInsights,
            allowLookalike: selectedPublisher?.allowLookalike,
            allowRemarketing: selectedPublisher?.allowRetargeting,
            allowRuleBasedAudiences: selectedPublisher?.allowRuleBasedAudiences,
          });
        }
        if (hasSkippedPublisherSelection && hasSelectedDataPartner) {
          return mapCollaborationTypes({
            allowInsights: selectedDataPartner?.allowInsights,
            allowLookalike: selectedDataPartner?.allowLookalike,
            allowRemarketing: selectedDataPartner?.allowRetargeting,
            allowRuleBasedAudiences:
              selectedDataPartner?.allowRuleBasedAudiences,
          });
        }
        return mapCollaborationTypes({
          allowInsights:
            selectedPublisher?.allowInsights &&
            selectedDataPartner?.allowInsights,
          allowLookalike:
            selectedPublisher?.allowLookalike &&
            selectedDataPartner?.allowLookalike,
          allowRemarketing:
            selectedPublisher?.allowRetargeting &&
            selectedDataPartner?.allowRetargeting,
          allowRuleBasedAudiences:
            selectedPublisher?.allowRuleBasedAudiences &&
            selectedDataPartner?.allowRuleBasedAudiences,
        });
      }, [
        hasSelectedDataPartner,
        hasSelectedPublisher,
        mapCollaborationTypes,
        hasSkippedPublisherSelection,
        selectedPublisher?.allowRuleBasedAudiences,
        selectedDataPartner?.allowRuleBasedAudiences,
        selectedPublisher?.allowInsights,
        selectedDataPartner?.allowLookalike,
        selectedPublisher?.allowRetargeting,
        selectedPublisher?.allowLookalike,
        selectedDataPartner?.allowRetargeting,
        selectedDataPartner?.allowInsights,
      ]);
      const showAdvertiserAudienceDownload = useMemo(
        () =>
          enabledFeatures.some((feature) =>
            [
              CollaborationTypes.Remarketing,
              CollaborationTypes.ExclusionTargeting,
              CollaborationTypes.Lookalike,
            ].includes(feature)
          ) && !enabledFeatures.includes(CollaborationTypes.RuleBasedAudiences),
        [enabledFeatures]
      );
      const [matchingIdFormat, setMatchingIdFormat] =
        useState<MatchingColumnFormat | null>(null);
      const [matchingIdHashingAlgorithm, setMatchingIdHashingAlgorithm] =
        useState<TableColumnHashingAlgorithm | null>(null);
      const [
        requestForCollaborationMessage,
        setRequestForCollaborationMessage,
      ] = useState("");
      const collaborationRequestRecipientId = useMemo(() => {
        if (
          activeStep ===
          MediaDataRoomCreationStep.COLLABORATION_REQUEST_TO_PUBLISHER
        ) {
          return selectedPublisher?.requestRecipientId;
        }
        if (
          activeStep ===
          MediaDataRoomCreationStep.COLLABORATION_REQUEST_TO_DATA_PARTNER
        ) {
          return selectedDataPartner?.requestRecipientId;
        }
        return undefined;
      }, [
        activeStep,
        selectedPublisher?.requestRecipientId,
        selectedDataPartner?.requestRecipientId,
      ]);
      const contactButtonEnabled = useMemo(
        () =>
          (activeStep === MediaDataRoomCreationStep.SELECT_PUBLISHER &&
            hasSelectedPublisher) ||
          (activeStep === MediaDataRoomCreationStep.SELECT_DATA_PARTNER &&
            hasSelectedDataPartner),
        [hasSelectedDataPartner, hasSelectedPublisher, activeStep]
      );
      const hasMatchingIdSelected = Boolean(matchingIdFormat);
      const arePublisherAndDataPartnerMatchingIdMatched =
        hasSelectedDataPartner &&
        hasSelectedPublisher &&
        selectedDataPartner?.matchingIdFormat ===
          selectedPublisher?.matchingIdFormat &&
        selectedDataPartner?.matchingIdHashingAlgorithm ===
          selectedPublisher?.matchingIdHashingAlgorithm;
      const hasAllowedCollaborationTypes = allowedCollaborationTypes.length > 0;
      const canProceedToConfiguration = useMemo<boolean>(() => {
        const hasSelectedPublisherOrSkipped =
          (hasSelectedPublisher && hasMatchingIdSelected) ||
          hasSkippedPublisherSelection;
        if (!canSelectDataPartner) {
          return hasSelectedPublisherOrSkipped;
        }
        const hasSelectedDataPartnerOrSkipped =
          hasSelectedDataPartner || hasSkippedDataPartnerSelection;
        return (
          hasSelectedPublisherOrSkipped &&
          (activeStep === MediaDataRoomCreationStep.SELECT_PUBLISHER ||
            (hasSelectedDataPartnerOrSkipped &&
              (hasSkippedPublisherSelection ||
                hasSkippedDataPartnerSelection ||
                arePublisherAndDataPartnerMatchingIdMatched) &&
              hasAllowedCollaborationTypes))
        );
      }, [
        hasSelectedPublisher,
        hasMatchingIdSelected,
        canSelectDataPartner,
        hasSkippedPublisherSelection,
        hasSkippedDataPartnerSelection,
        activeStep,
        hasSelectedDataPartner,
        arePublisherAndDataPartnerMatchingIdMatched,
        hasAllowedCollaborationTypes,
      ]);
      const contactButtonTooltip = useMemo(
        () =>
          canProceedToConfiguration
            ? null
            : `To continue, please contact the ${activeStep === MediaDataRoomCreationStep.SELECT_PUBLISHER ? "publisher" : "data partner"} to create a DCR configuration for you.`,
        [canProceedToConfiguration, activeStep]
      );
      const getValues = useCallback<() => PublishMediaDataRoomInput>(
        () => ({
          advertiserEmails:
            participantsEmails.get(MediaDataRoomUserRole.Advertiser) || [],
          agencyEmails:
            participantsEmails.get(MediaDataRoomUserRole.Agency) || [],
          dataPartnerEmails:
            participantsEmails.get(MediaDataRoomUserRole.DataPartner) || [],
          enableAdvertiserAudienceDownload: enableAdvertiserAudienceDownload,
          enableDataPartner:
            (participantsEmails.get(MediaDataRoomUserRole.DataPartner) || [])
              .length > 0,
          enableInsights:
            enabledFeatures.includes(CollaborationTypes.Insights) || false,
          enableLookalike:
            enabledFeatures.includes(CollaborationTypes.Lookalike) || false,
          enableRemarketing:
            enabledFeatures.includes(CollaborationTypes.Remarketing) || false,
          enableRuleBasedAudiences:
            enabledFeatures.includes(CollaborationTypes.RuleBasedAudiences) ||
            false,
          hideAbsoluteValuesFromInsights: !showAbsoluteAudienceSizes,
          mainAdvertiserEmail: mainAdvertiserUserEmail,
          mainPublisherEmail: mainPublisherUserEmail,
          matchingIdFormat: matchingIdFormat!,
          matchingIdHashingAlgorithm: matchingIdHashingAlgorithm!,
          name: dataRoomName,
          observerEmails:
            participantsEmails.get(MediaDataRoomUserRole.Observer) || [],
          publisherEmails:
            participantsEmails.get(MediaDataRoomUserRole.Publisher) || [],
        }),
        [
          participantsEmails,
          dataRoomName,
          mainAdvertiserUserEmail,
          mainPublisherUserEmail,
          matchingIdFormat,
          enableAdvertiserAudienceDownload,
          matchingIdHashingAlgorithm,
          enabledFeatures,
          showAbsoluteAudienceSizes,
        ]
      );
      const handleSendCollaborationRequest = useCallback(async () => {
        await sendCollaborationRequest({
          message: requestForCollaborationMessage,
          receiver:
            activeStep ===
            MediaDataRoomCreationStep.COLLABORATION_REQUEST_TO_PUBLISHER
              ? "publisher"
              : "dataPartner",
          requestRecipientId: collaborationRequestRecipientId!,
        });
      }, [
        collaborationRequestRecipientId,
        requestForCollaborationMessage,
        sendCollaborationRequest,
        activeStep,
      ]);
      const handleSubmit = useCallback(
        () => submit(getValues()),
        [getValues, submit]
      );
      useEffect(() => {
        setParticipantsEmails((participantsEmails) => {
          if (organizationRole === null) {
            return DEFAULT_PARTICIPANTS_EMAILS;
          }
          const participantsEmailsClone = new Map(participantsEmails);
          if (organizationRole === MediaDataRoomOrganizationRole.ADVERTISER) {
            participantsEmailsClone.set(
              MediaDataRoomUserRole.Publisher,
              selectedPublisher?.publisherParticipants || []
            );
            participantsEmailsClone.set(
              MediaDataRoomUserRole.DataPartner,
              selectedDataPartner?.participants || []
            );
            participantsEmailsClone.set(MediaDataRoomUserRole.Advertiser, [
              currentUserEmail!,
            ]);
          }
          if (organizationRole === MediaDataRoomOrganizationRole.PUBLISHER) {
            participantsEmailsClone.set(MediaDataRoomUserRole.Publisher, [
              currentUserEmail!,
            ]);
          }
          if (organizationRole === MediaDataRoomOrganizationRole.DATA_PARTNER) {
            participantsEmailsClone.set(MediaDataRoomUserRole.DataPartner, [
              currentUserEmail!,
            ]);
          }
          participantsEmailsClone.set(MediaDataRoomUserRole.Observer, []);
          participantsEmailsClone.set(MediaDataRoomUserRole.Agency, []);
          return participantsEmailsClone;
        });
      }, [
        selectedPublisher?.publisherParticipants,
        selectedDataPartner?.participants,
        currentUserEmail,
        organizationRole,
      ]);
      useUpdateEffect(() => {
        setEnabledFeatures([]);
        setMainPublisherUserEmail("");
        setDataRoomName("");
        setMainAdvertiserUserEmail("");
        setWithAgency(false);
        setWithObserver(false);
        setMatchingIdFormat(selectedPublisher?.matchingIdFormat || null);
        setMatchingIdHashingAlgorithm(
          selectedPublisher?.matchingIdHashingAlgorithm || null
        );
      }, [selectedPublisher?.id]);
      const shouldResetCollaborationMessage = [
        MediaDataRoomCreationStep.SELECT_DATA_PARTNER,
        MediaDataRoomCreationStep.SELECT_PUBLISHER,
      ].includes(activeStep);
      useEffect(() => {
        if (shouldResetCollaborationMessage) {
          setRequestForCollaborationMessage("");
        }
      }, [shouldResetCollaborationMessage, setRequestForCollaborationMessage]);
      useEffect(() => {
        setEnabledFeatures((currentlyEnabled) => {
          const currentlyAllowedFeatures = currentlyEnabled.filter((feature) =>
            allowedCollaborationTypes.includes(feature)
          );
          if (currentlyAllowedFeatures.length !== currentlyEnabled.length) {
            return currentlyAllowedFeatures;
          }
          return currentlyEnabled;
        });
      }, [allowedCollaborationTypes, setEnabledFeatures]);
      useEffect(() => {
        if (!showAdvertiserAudienceDownload) {
          setEnableAdvertiserAudienceDownload(false);
        }
      }, [showAdvertiserAudienceDownload, setEnableAdvertiserAudienceDownload]);
      const enabledRuleBasedAudiencesFeature = useMemo(
        () => enabledFeatures.includes(CollaborationTypes.RuleBasedAudiences),
        [enabledFeatures]
      );
      useEffect(() => {
        if (enabledRuleBasedAudiencesFeature) {
          setEnabledFeatures((features) => {
            return [...new Set([...features, CollaborationTypes.Remarketing])];
          });
        }
      }, [setEnabledFeatures, enabledRuleBasedAudiencesFeature]);
      const contextValue: CreationWizardConfigurationContextValue = useMemo(
        () => ({
          addParticipantEmail,
          allowedCollaborationTypes,
          canProceedToConfiguration,
          collaborationRequestRecipientId,
          contactButtonEnabled,
          contactButtonTooltip,
          dataRoomName,
          enableAdvertiserAudienceDownload,
          enabledFeatures,
          getValues,
          handleSendCollaborationRequest,
          handleSubmit,
          hasSelectedDataPartner,
          hasSelectedPublisher,
          mainAdvertiserUserEmail,
          mainPublisherUserEmail,
          matchingIdFormat,
          matchingIdHashingAlgorithm,
          participantsEmails,
          removeParticipantEmail,
          requestForCollaborationMessage,
          setDataRoomName,
          setEnableAdvertiserAudienceDownload,
          setEnabledFeatures: handleSetEnabledFeatures,
          setMainAdvertiserUserEmail,
          setMainPublisherUserEmail,
          setMatchingIdFormat,
          setMatchingIdHashingAlgorithm,
          setParticipantsEmails,
          setRequestForCollaborationMessage,
          setShowAbsoluteAudienceSizes,
          setWithAgency,
          setWithObserver,
          showAbsoluteAudienceSizes,
          showAdvertiserAudienceDownload,
          withAgency,
          withDataPartner,
          withObserver,
        }),
        [
          hasSelectedPublisher,
          hasSelectedDataPartner,
          mainAdvertiserUserEmail,
          setMainPublisherUserEmail,
          mainPublisherUserEmail,
          contactButtonTooltip,
          setMainAdvertiserUserEmail,
          collaborationRequestRecipientId,
          requestForCollaborationMessage,
          setRequestForCollaborationMessage,
          contactButtonEnabled,
          showAbsoluteAudienceSizes,
          setShowAbsoluteAudienceSizes,
          dataRoomName,
          allowedCollaborationTypes,
          participantsEmails,
          setDataRoomName,
          withDataPartner,
          withAgency,
          handleSubmit,
          handleSendCollaborationRequest,
          setWithAgency,
          canProceedToConfiguration,
          withObserver,
          setWithObserver,
          addParticipantEmail,
          showAdvertiserAudienceDownload,
          enableAdvertiserAudienceDownload,
          setEnableAdvertiserAudienceDownload,
          removeParticipantEmail,
          enabledFeatures,
          handleSetEnabledFeatures,
          matchingIdFormat,
          matchingIdHashingAlgorithm,
          setMatchingIdFormat,
          setMatchingIdHashingAlgorithm,
          getValues,
          setParticipantsEmails,
        ]
      );
      return (
        <CreationWizardConfigurationContextProvider value={contextValue}>
          {children}
        </CreationWizardConfigurationContextProvider>
      );
    }
  );

CreationWizardConfigurationWrapper.displayName =
  "CreationWizardConfigurationWrapper";

export default CreationWizardConfigurationWrapper;
