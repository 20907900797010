// TODO: Fix joy migration
import { useAuth0 } from "@auth0/auth0-react";
import { useSubmittedRequestSigneesLazyQuery } from "@decentriq/graphql/dist/hooks";
import { faCheck, faInfoCircle } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  Divider,
  Modal,
  ModalDialog,
  Typography,
} from "@mui/joy";
import { memo, useEffect } from "react";
import { mapErrorToGeneralSnackbar, useDataRoomSnackbar } from "hooks";

interface SubmittedRequestApprovalDialogProps {
  requestId: string;
  open: boolean;
  loading?: boolean;
  onCancel: () => void;
  onConfirm: () => void;
}

const SubmittedRequestApprovalDialog: React.FC<
  SubmittedRequestApprovalDialogProps
> = ({ open, requestId, onCancel, onConfirm, loading }) => {
  const { enqueueSnackbar } = useDataRoomSnackbar();
  const { user } = useAuth0();
  const currentUserEmail = user?.email || "";
  const [
    fetchSubmittedRequestSignees,
    { data, loading: loadingRequest, error },
  ] = useSubmittedRequestSigneesLazyQuery({
    onError: (error) => {
      enqueueSnackbar(
        ...mapErrorToGeneralSnackbar(error, "Failed to get signees")
      );
    },
  });
  useEffect(() => {
    if (open) {
      fetchSubmittedRequestSignees({
        variables: { id: requestId },
      });
    }
  }, [open, requestId, fetchSubmittedRequestSignees]);
  const isMergingAvailable =
    data &&
    (data?.submittedDataRoomRequest?.signers?.nodes?.every((s) =>
      Boolean(s?.signature)
    ) ||
      (data.submittedDataRoomRequest?.signers?.nodes?.filter(
        (s) => !s?.signature
      )?.length === 1 &&
        data.submittedDataRoomRequest?.signers?.nodes?.some(
          (s) => s?.userEmail === currentUserEmail && !s?.signature
        )));
  return (
    <Modal open={open}>
      <ModalDialog>
        <DialogContent>
          {loadingRequest ? (
            <Box
              sx={{
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
                minHeight: "4rem",
              }}
            >
              <CircularProgress sx={{ "--CircularProgress-size": "16px" }} />
            </Box>
          ) : error ? (
            <Typography align="center" level="body-sm">
              Something went wrong with getting request
            </Typography>
          ) : (
            <>
              <Box sx={{ mb: 1, textAlign: "center" }}>
                <FontAwesomeIcon
                  fixedWidth={true}
                  icon={faInfoCircle}
                  size="3x"
                />
              </Box>
              <Typography
                align="center"
                color="textPrimary"
                gutterBottom={true}
                level="title-md"
                style={{ lineHeight: 1.25 }}
              >
                <strong>
                  Are you sure you would like to approve
                  <br />
                  this request?
                </strong>
              </Typography>
              {isMergingAvailable ? (
                <Typography align="center" level="body-sm">
                  The new computation will be integrated to the data clean room.
                </Typography>
              ) : (
                <Typography align="center" level="body-sm">
                  Once all data owners have approved,
                  <br />
                  it can be integrated to the data clean room.
                </Typography>
              )}
            </>
          )}
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button disabled={loading} onClick={onCancel}>
            Go back to review
          </Button>
          <div>
            {!loadingRequest && !error ? (
              <Button
                data-testid="popup_publish_dcr_btn"
                loading={loading}
                loadingPosition="start"
                onClick={onConfirm}
                startDecorator={<FontAwesomeIcon icon={faCheck} />}
              >
                {isMergingAvailable
                  ? "Approve and integrate"
                  : "Confirm approval"}
              </Button>
            ) : null}
          </div>
        </DialogActions>
      </ModalDialog>
    </Modal>
  );
};

SubmittedRequestApprovalDialog.displayName = "SubmittedRequestApprovalDialog";

export default memo(SubmittedRequestApprovalDialog);
