import {
  useOrganizationNameQuery,
  useUpdateOrganizationNameMutation,
} from "@decentriq/graphql/dist/hooks";
import { FormControl, FormLabel, Input } from "@mui/joy";
import { useCallback, useEffect, useState } from "react";
import { CommonSnackbarOrigin, useGeneralSnackbar, useUserRole } from "hooks";

interface OrganizationNameEditorProps {
  organizationId: string;
}

const OrganizationNameEditor: React.FC<OrganizationNameEditorProps> = ({
  organizationId,
}) => {
  const [organizationName, setOrganizationName] = useState<string>("");
  const { enqueueSnackbar } = useGeneralSnackbar({
    origin: CommonSnackbarOrigin.ADMIN,
  });
  const { isSuperAdminReadOnly } = useUserRole();

  const [updateOrganizationNameMutation] = useUpdateOrganizationNameMutation({
    onCompleted: () => {
      enqueueSnackbar(`Organization name has been successfully updated.`);
    },
  });
  const { data: organizationNameData } = useOrganizationNameQuery({
    variables: { organizationId },
  });

  // Set default value
  useEffect(() => {
    const name = organizationNameData?.organization?.name;
    if (name) {
      setOrganizationName(name);
    }
  }, [organizationNameData]);

  const updateOrganizationName = useCallback(
    async (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { target } = event;
      if (
        !target.value ||
        organizationNameData?.organization?.name === target.value
      )
        return;
      try {
        await updateOrganizationNameMutation({
          update: (cache, { data: { organization = {} } }: any) => {
            cache.modify({
              fields: {
                name: () => {
                  return organization?.update?.name;
                },
              },
              id: cache.identify({
                __typename: "Organization",
                id: organizationId,
              }),
            });
          },
          variables: {
            input: {
              id: organizationId,
              name: target.value,
            },
          },
        });
      } catch (error) {
        enqueueSnackbar("Organization name could not be updated.", {
          context: error?.message,
          persist: true,
          variant: "error",
        });
      }
    },
    [
      organizationNameData?.organization?.name,
      updateOrganizationNameMutation,
      organizationId,
      enqueueSnackbar,
    ]
  );

  const updateOrganizationNameOnEnter = useCallback(
    (event: any) => {
      const { target, keyCode } = event || {};
      if (keyCode !== 13 || !target.value) return;
      updateOrganizationName(event);
    },
    [updateOrganizationName]
  );

  return (
    <FormControl sx={{ mb: 1 }}>
      <FormLabel>Name</FormLabel>
      <Input
        disabled={isSuperAdminReadOnly}
        name="name"
        onBlur={updateOrganizationName}
        onChange={({ target }) => setOrganizationName(target.value)}
        onKeyDown={updateOrganizationNameOnEnter}
        placeholder="Name"
        value={organizationName}
      />
    </FormControl>
  );
};

export default OrganizationNameEditor;
