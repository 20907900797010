import { dqSortableAccordionSummaryClasses } from "@decentriq/components";
import { faAngleDown, faXmark } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  accordionClasses,
  accordionSummaryClasses,
  alertClasses,
  autocompleteClasses,
  Box,
  buttonClasses,
  dialogActionsClasses,
  dialogContentClasses,
  dialogTitleClasses,
  dividerClasses,
  iconButtonClasses,
  menuButtonClasses,
  modalDialogClasses,
  selectClasses,
  stepIndicatorClasses,
  stepperClasses,
  switchClasses,
  tabClasses,
} from "@mui/joy";
import { extendTheme } from "@mui/joy/styles";
import { forwardRef } from "react";

const xmark = <FontAwesomeIcon icon={faXmark} />;
const indicator = <FontAwesomeIcon fixedWidth={true} icon={faAngleDown} />;
const indicatorActiveStyles = { transform: "rotate(-180deg)" };

export const joyTheme = extendTheme({
  colorSchemes: {
    light: {
      palette: {
        chart: {
          100: "#B3FFFA",
          200: "#00FFEE",
          300: "#00EBDB",
          400: "#00D6C8",
          50: "#DBFFFD",
          500: "#00BFB3",
          600: "#00ADA2",
          700: "#00998F",
          800: "#008077",
          900: "#005C56",
        },
        primary: {
          100: "#ccfbf1",
          200: "#99f6e4",
          300: "#5eead4",
          400: "#2dd4bf",
          50: "#f0fdfa",
          500: "#00bfb3",
          600: "#0d9488",
          700: "#0f766e",
          800: "#115e59",
          900: "#134e4a",
          solidColor: "black",
        },
        secondary: {
          100: "#EBE5FB",
          200: "#D8CAF7",
          300: "#BDA7F1",
          400: "#9C7BEA",
          50: "#F5F2FD",
          500: "#6732DE",
          600: "#5722D3",
          700: "#4C1EB9",
          800: "#3F199A",
          900: "#2C1169",
          outlinedActiveBg: "var(--joy-palette-secondary-100)",
          outlinedBorder: "var(--joy-palette-secondary-500)",
          outlinedColor: "var(--joy-palette-secondary-700)",
          plainActiveBg: "var(--joy-palette-secondary-100)",
          plainColor: "var(--joy-palette-secondary-700)",
          softActiveBg: "var(--joy-palette-secondary-300)",
          softBg: "var(--joy-palette-secondary-200)",
          softColor: "var(--joy-palette-secondary-800)",
          solidActiveBg: "var(--joy-palette-secondary-500)",
          solidBg: "var(--joy-palette-secondary-400)",
        },
        success: {
          100: "#B3FFFA",
          200: "#00FFEE",
          300: "#00EBDB",
          400: "#00D6C8",
          50: "#DBFFFD",
          500: "#00BFB3",
          600: "#00ADA2",
          700: "#00998F",
          800: "#008077",
          900: "#005C56",
          solidColor: "black",
        },
      },
    },
  },
  components: {
    DqSortableAccordion: {
      defaultProps: {
        variant: "outlined",
      },
      styleOverrides: {
        root: {
          border:
            "var(--variant-borderWidth) solid var(--variant-outlinedBorder, var(--joy-palette-neutral-outlinedBorder, var(--joy-palette-neutral-300, #CDD7E1)))",
          borderRadius: "var(--List-radius)",
        },
      },
    },
    DqSortableAccordionDetails: {
      styleOverrides: {
        root: {
          borderBottomLeftRadius: "var(--List-radius)",
          borderBottomRightRadius: "var(--List-radius)",
        },
      },
    },
    DqSortableAccordionGroup: {
      defaultProps: {
        variant: "plain",
      },
      styleOverrides: {
        root: {
          "--List-radius":
            "calc(var(--ListItem-paddingX) + var(--joy-radius-sm))", // NOTE: Uses the expected radius of toolbar buttons
          "--ListItem-paddingX": "0.25rem",
          gap: "0.5rem",
        },
      },
    },
    DqSortableAccordionSummary: {
      defaultProps: {
        slotProps: {
          button: {
            sx: {
              [`.${dqSortableAccordionSummaryClasses.grip}`]: {
                opacity: 1,
                transition: "all 0.2s",
              },
              [`&:not(.Mui-selected,[aria-selected="true"],[aria-pressed="true"]):not(:hover) .${dqSortableAccordionSummaryClasses.grip}`]:
                {
                  marginRight: "calc((0.25rem * 2 + 0.5rem + 0.5rem) * -1)",
                  opacity: 0,
                },
              [`&:not(.Mui-selected,[aria-selected="true"],[aria-pressed="true"]):not(:hover) .${accordionSummaryClasses.indicator}`]:
                {
                  marginLeft: "0.25rem",
                },
            },
          },
          grip: {
            variant: "plain",
          },
        },
      },
      styleOverrides: {
        grip: {
          minWidth: "auto",
        },
        root: {
          borderRadius: "var(--List-radius)",
          [`& > .${accordionSummaryClasses.button}`]: {
            borderRadius: "var(--List-radius)",
          },
          [`& > .${accordionSummaryClasses.button}.${accordionSummaryClasses.expanded}`]:
            {
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0,
            },
        },
      },
    },
    JoyAccordionDetails: {
      styleOverrides: {
        root: {
          backgroundColor: "var(--joy-palette-background-body)",
        },
      },
    },
    JoyAccordionGroup: {
      defaultProps: {
        variant: "plain",
      },
      styleOverrides: {
        root: {
          "&.separated": {
            [`& .${accordionSummaryClasses.root}`]: {
              borderRadius: "var(--List-radius)",
              minHeight: "46px",
              [`& > .${accordionSummaryClasses.button}`]: {
                borderRadius: "var(--List-radius)",
              },
              [`& > .${accordionSummaryClasses.button}.${accordionSummaryClasses.expanded}`]:
                {
                  borderBottomLeftRadius: 0,
                  borderBottomRightRadius: 0,
                },
            },
            [`& .${accordionClasses.root}`]: {
              border:
                "var(--variant-borderWidth) solid var(--variant-outlinedBorder, var(--joy-palette-neutral-outlinedBorder, var(--joy-palette-neutral-300, #CDD7E1)))",
              borderRadius: "var(--List-radius)",
            },
            "--List-radius":
              "calc(var(--ListItem-paddingX) + var(--joy-radius-sm))", // NOTE: Uses the expected radius of toolbar buttons
            "--ListItem-paddingX": "0.25rem",
            gap: "0.5rem",
          },
        },
      },
    },
    JoyAccordionSummary: {
      defaultProps: {
        indicator,
      },
      styleOverrides: {
        indicator: {
          [`&.${selectClasses.expanded}`]: {
            ...indicatorActiveStyles,
          },
          transition: "0.2s",
        },
      },
    },
    JoyAutocomplete: {
      defaultProps: {
        clearIcon: xmark,
        noOptionsText: "No more options",
        popupIcon: indicator,
      },
      styleOverrides: {
        input: {
          minWidth: "50%", // NOTE: Up from `30px`
        },
        popupIndicator: {
          "& > svg": {
            transition: "0.2s",
          },
          [`&.${autocompleteClasses.popupIndicatorOpen}`]: {
            "& > svg": {
              ...indicatorActiveStyles,
            },
            transform: "none",
          },
        },
        root: {
          boxShadow: "none",
        },
      },
    },
    JoyAvatar: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.size === "sm" && {
            fontSize: `calc(var(--Avatar-size, 2rem) * 0.4375)`,
            height: `var(--Avatar-size, 2rem)`,
            width: `var(--Avatar-size, 2rem)`, // default as 14px
          }),
          ...(ownerState.size === "md" && {
            fontSize: `calc(var(--Avatar-size, 2.25rem) * 0.4444)`,
            height: `var(--Avatar-size, 2.25rem)`,
            width: `var(--Avatar-size, 2.25rem)`, // default as 16px
          }),
          ...(ownerState.size === "lg" && {
            fontSize: `calc(var(--Avatar-size, 2.5rem) * 0.45)`,
            height: `var(--Avatar-size, 2.5rem)`,
            width: `var(--Avatar-size, 2.5rem)`, // default as 18px
          }),
        }),
      },
    },
    JoyButton: {
      defaultProps: {
        color: "neutral",
        variant: "plain",
      },
    },
    JoyButtonGroup: {
      styleOverrides: {
        root: {
          [`& > .${iconButtonClasses.root}:last-child[aria-haspopup="menu"] > svg`]:
            { transition: "0.2s" },
          [`& > .${iconButtonClasses.root}:last-child[aria-haspopup="menu"][aria-expanded="true"] > svg`]:
            { ...indicatorActiveStyles },
        },
      },
    },
    JoyChip: {
      defaultProps: {
        size: "md",
      },
    },
    JoyChipDelete: {
      defaultProps: {
        children: xmark,
      },
    },
    JoyCircularProgress: {
      defaultProps: {
        color: "neutral",
        thickness: 1,
      },
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState?.size === "xs" && {
            "--CircularProgress-size": "18px",
            "--_root-size": "var(--CircularProgress-size, 18px)",
          }),
        }),
      },
    },
    JoyDialogActions: {
      defaultProps: {
        orientation: "horizontal",
      },
      styleOverrides: {
        root: {
          [`& > ${buttonClasses.root}:only-child`]: { flex: "0 0 auto" },
          justifyContent: "space-between",
          zIndex: "unset", // NOTE: Fixes the `z-index` issue with the drawer being used inside the modal dialog
        },
      },
    },
    JoyDrawer: {
      styleOverrides: {
        content: {
          "& > form": {
            flex: 1,
            overflow: "hidden",
          },
          [`& .${modalDialogClasses.root}`]: {
            height: "100%",
            maxHeight: "100%",
            maxWidth: "100%",
            minWidth: "100%",
            position: "relative",
            width: "100%",
          },
        },
      },
    },
    JoyFormHelperText: {
      styleOverrides: {
        root: {
          "&:empty": {
            display: "none",
          },
        },
      },
    },
    // TODO - @volo add "narrow" class to all IconButton components which are next to each other
    JoyIconButton: {
      styleOverrides: {
        root: {
          "&.narrow": {
            minHeight: "auto",
            minWidth: "auto",
            padding: "6px",
          },
        },
      },
    },
    JoyInput: {
      styleOverrides: {
        root: {
          boxShadow: "none",
        },
      },
    },
    JoyMenuButton: {
      defaultProps: {
        endDecorator: <FontAwesomeIcon icon={faAngleDown} />,
      },
      styleOverrides: {
        // @ts-expect-error // NOTE: The prop exists, but the type definition is missing
        endDecorator: {
          color: "var(--Icon-color)",
          fontSize: "1.14285714286em",
          marginInlineEnd: "calc(var(--Button-paddingInline, 1rem) / -4)",
          transition: "0.2s",
        },
        root: {
          [`&[aria-expanded="true"] > .${menuButtonClasses.endDecorator}`]: {
            ...indicatorActiveStyles,
          },
        },
        startDecorator: {
          color: "var(--Icon-color)", // NOTE: Should probably be applied to `FontAwesomeIcon`'s `svg` element the same way as it is applied to the `SvgIcon` component
          fontSize: "var(--Icon-fontSize)", // NOTE: Should probably be applied to `FontAwesomeIcon`'s `svg` element the same way as it is applied to the `SvgIcon` component
        },
      },
    },
    JoyModalDialog: {
      defaultProps: {
        minWidth: 400,
        variant: "plain",
      },
      styleOverrides: {
        root: ({ theme }) => ({
          /* 
            NOTE: ModalDialog considers itself a Card, thus applies Card padding.
            That CSS variable can be used to adjust the paddings of the title, content, and actions.
            The content is expanded to fill the entire width using the same technique as `CardOverflow` does.
            This allows for better scrollbar positioning and avoids cutting off overflowing shadows.
          */
          [`& > .${dialogActionsClasses.root}`]: {
            paddingTop: "var(--Card-padding)",
          },
          [`& > .${dialogContentClasses.root}`]: {
            marginInline: "calc(var(--Card-padding) * -1)",
            padding: "var(--Card-padding)",
          },
          [`& > .${dialogTitleClasses.root}`]: {
            paddingBottom: "var(--Card-padding)",
          },
          [`& > .${dialogTitleClasses.root} + ${dialogContentClasses.root}`]: {
            paddingTop: 0,
          },
          [`& > .${dividerClasses.root} + .${dividerClasses.root}`]: {
            display: "none", // NOTE: Regardless of how many dividers are rendered, only the first one is displayed
          },
          [`& > .${alertClasses.root}`]: {
            borderRadius: 0, // NOTE: Since the `Alert` is stretched, the border radius should be removed
            marginInline: "var(--Divider-inset)", // NOTE: Stretching `Alert`s to the full width of the dialog
          },
          "&[role=alertdialog]": {
            [`& > .${dialogContentClasses.root}`]: {
              textAlign: "center",
              textWrap: "balance",
            },
            [`& > .${dialogTitleClasses.root}`]: {
              "& >:not(svg)": {
                color: theme.palette.text.primary, // NOTE: The same effect can be achieved by using the `style={{ color: "initial" }}` prop on the `span` tag
              },
              alignItems: "center",
              flexDirection: "column",
              textAlign: "center",
              textWrap: "balance",
            },
            "--ModalDialog-maxWidth": "450px",
            "--ModalDialog-minWidth": "300px",
          },
          "--Card-padding": "1rem",
          backgroundColor: "var(--joy-palette-background-body)",
          gap: 0,
        }),
      },
    },
    JoyRadio: {
      styleOverrides: {
        action: ({ theme, ownerState }) => ({
          ...(ownerState.overlay &&
            ownerState.checked && {
              border: "2px solid",
              borderColor: theme.variants.solid.primary.backgroundColor,
              inset: -1,
            }),
        }),
        radio: ({ theme, ownerState }) => ({
          ...(ownerState.variant === "outlined" &&
            ownerState.checked && {
              ...theme.variants.solid.primary,
              "&:hover": {
                ...theme.variants.solidHover.primary,
              },
            }),
        }),
        root: ({ ownerState }) => ({
          ...(ownerState.overlay && { flexShrink: 0 }),
        }),
      },
    },
    JoySelect: {
      defaultProps: {
        indicator,
        slotProps: {
          button: ({ value }) => ({
            ...((value === null ||
              value === undefined ||
              value === "" ||
              (Array.isArray(value) && value.length === 0)) && {
              sx: {
                opacity: "var(--Select-placeholderOpacity)", // NOTE: Fixing opacity for multi-selects
              },
            }),
          }),
          listbox: {
            onKeyDown: (event) => {
              /*
                NOTE:
                Not sure if only Escape should not be propagated by default,
                but currently it is the only key that causes problems
                (e.g. closing the dialog along with the select's menu).
              */
              if (event.key === "Escape") {
                event.stopPropagation();
              }
            },
            placement: "bottom-start",
          },
        },
      },
      styleOverrides: {
        indicator: {
          [`&.${selectClasses.expanded}`]: {
            ...indicatorActiveStyles,
          },
          transition: "0.2s",
        },
        root: ({ theme, ownerState }) => ({
          '&:active, &[aria-pressed="true"]':
            theme.variants[`${ownerState.variant!}Active`]?.[ownerState.color!],
          boxShadow: "none",
        }),
      },
    },
    JoyStack: {
      defaultProps: {
        spacing: 1,
        useFlexGap: true,
      },
    },
    JoyStepper: {
      styleOverrides: {
        root: {
          [`&.${stepperClasses.sizeSm} .${stepIndicatorClasses.root}`]: {
            fontSize: 12,
          },
        },
      },
    },
    JoySwitch: {
      defaultProps: {
        variant: "outlined",
      },
      styleOverrides: {
        root: ({ theme, ownerState }) => ({
          ...(ownerState.variant === "outlined" &&
            ownerState.checked && {
              [`& .${switchClasses.track}.${switchClasses.checked}`]: {
                ...theme.variants.solid[ownerState.color ?? "primary"],
              },
              [`&:hover .${switchClasses.track}.${switchClasses.checked}`]: {
                ...theme.variants.solidHover[ownerState.color ?? "primary"],
              },
              [`& .${switchClasses.thumb}.${switchClasses.checked}`]: {
                backgroundColor: theme.variants.solid.primary.color,
                boxShadow: "none",
                color: theme.variants.solid.primary.color,
              },
            }),
        }),
      },
    },
    JoyTab: {
      defaultProps: {
        color: "neutral",
        indicatorInset: true,
      },
    },
    JoyTabList: {
      styleOverrides: {
        root: {
          [`& .${tabClasses.root}`]: {
            backgroundColor: "transparent",
            [`&.${tabClasses.selected}`]: {
              backgroundColor: "transparent",
            },
          },
        },
      },
    },
    JoyTabs: {
      styleOverrides: {
        root: {
          backgroundColor: "var(--joy-palette-background-body)",
        },
      },
    },
    JoyTextarea: {
      styleOverrides: {
        root: {
          boxShadow: "none",
        },
      },
    },
    JoyTooltip: {
      defaultProps: {
        enterNextDelay: 100,
        leaveDelay: 100,
        placement: "top",
        slotProps: {
          // @ts-expect-error // NOTE: Wrongfully expects `Popper`'s `open` prop to be present
          root: {
            component: forwardRef(({ children, ownerState, ...props }, ref) => (
              <div ref={ref} {...props}>
                <Box sx={{ maxHeight: 200, overflow: "auto" }}>{children}</Box>
              </div>
            )),
          },
        },
      },
      styleOverrides: {
        root: {
          maxWidth: 400,
        },
      },
    },
  },
});
