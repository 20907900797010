import { type FetchResult } from "@apollo/client";
import {
  useDraftDataRoomShowOrganizationLogoQuery,
  useUpdateDraftDataRoomShowOrganizationLogoMutation,
} from "@decentriq/graphql/dist/hooks";
import { type UpdateDraftDataRoomShowOrganizationLogoMutation } from "@decentriq/graphql/dist/types";

interface useDraftDataRoomShowOrganizationLogoArgs {
  id: string;
  skip?: boolean;
}

interface useDraftDataRoomShowOrganizationLogoResult {
  draftDataRoomShowOrganizationLogo: boolean;
  draftDataRoomShowOrganizationLogoLoading: boolean;
  setDraftDataRoomShowOrganizationLogo: (
    isFavorite: boolean
  ) => Promise<
    FetchResult<
      UpdateDraftDataRoomShowOrganizationLogoMutation,
      Record<string, any>,
      Record<string, any>
    >
  >;
  setDraftDataRoomShowOrganizationLogoLoading: boolean;
}

const useDraftDataRoomShowOrganizationLogo = ({
  id: dataRoomId,
  skip = false,
}: useDraftDataRoomShowOrganizationLogoArgs): useDraftDataRoomShowOrganizationLogoResult => {
  const { data, loading: showOrganizationLogoLoading } =
    useDraftDataRoomShowOrganizationLogoQuery({
      skip,
      variables: { id: dataRoomId },
    });
  const { draftDataRoom } = data || {};
  const { showOrganizationLogo = false } = draftDataRoom || {};
  const [
    updateDraftDataRoomShowOrganizationLogoMutation,
    { loading: updateDraftDataRoomShowOrganizationLogoLoading },
  ] = useUpdateDraftDataRoomShowOrganizationLogoMutation();
  const setShowOrganizationLogo = (showOrganizationLogo: boolean) => {
    return updateDraftDataRoomShowOrganizationLogoMutation({
      optimisticResponse: {
        draftDataRoom: {
          __typename: "DraftDataRoomMutations",
          update: {
            __typename: "DraftDataRoom",
            id: dataRoomId,
            showOrganizationLogo,
          },
        },
      },
      variables: {
        id: dataRoomId,
        showOrganizationLogo,
      },
    });
  };
  const setShowOrganizationLogoLoading =
    updateDraftDataRoomShowOrganizationLogoLoading;
  return {
    draftDataRoomShowOrganizationLogo: showOrganizationLogo,
    draftDataRoomShowOrganizationLogoLoading: showOrganizationLogoLoading,
    setDraftDataRoomShowOrganizationLogo: setShowOrganizationLogo,
    setDraftDataRoomShowOrganizationLogoLoading: setShowOrganizationLogoLoading,
  };
};

export default useDraftDataRoomShowOrganizationLogo;
