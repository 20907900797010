import { useCreateDataRoomPlaygroundMutation } from "@decentriq/graphql/dist/hooks";
import {
  type CreateDraftComputationNodeInput,
  ScriptingLanguage,
  WorkerTypeShortName,
} from "@decentriq/graphql/dist/types";
import { useCallback } from "react";
import { v4 as uuidv4 } from "uuid";
import { useComputeNodesVars } from "contexts";
import { ComputeNodeCreator } from "features/computeNode";
import { mapErrorToGeneralSnackbar, useDataRoomSnackbar } from "hooks";
import { DEFAULT_SCRIPT, scriptingWorkerTypeToScriptingLanguage } from "models";

interface PlaygroundCreatorProps {
  dataRoomId: string;
  excludeComputationTypes?: WorkerTypeShortName[];
}

const inputCreationKey: Omit<
  Record<WorkerTypeShortName, keyof CreateDraftComputationNodeInput>,
  "POST" | "S3"
> = {
  [WorkerTypeShortName.Sql]: "sql",
  [WorkerTypeShortName.Sqlite]: "sqlite",
  [WorkerTypeShortName.Match]: "match",
  [WorkerTypeShortName.R]: "scripting",
  [WorkerTypeShortName.Python]: "scripting",
  [WorkerTypeShortName.Synthetic]: "synthetic",
  [WorkerTypeShortName.Preview]: "preview",
};

export const scriptingLanguageFromWorkerType = new Map<
  WorkerTypeShortName,
  ScriptingLanguage
>([
  [WorkerTypeShortName.Python, ScriptingLanguage.Python],
  [WorkerTypeShortName.R, ScriptingLanguage.R],
]);

const PlaygroundCreator = ({
  dataRoomId,
  excludeComputationTypes = [WorkerTypeShortName.S3],
}: PlaygroundCreatorProps) => {
  const { enqueueSnackbar } = useDataRoomSnackbar();
  const { expand } = useComputeNodesVars();
  const [createPlaygroundComputeNodeMutation] =
    useCreateDataRoomPlaygroundMutation({
      onError: (error) => {
        enqueueSnackbar(
          ...mapErrorToGeneralSnackbar(
            error,
            "The development computation could not be created."
          )
        );
      },
      // TODO: write to cache using a fragment depending on typename
      refetchQueries: ["DevComputeNodes"],
    });
  const onComputationCreate = useCallback(
    (computationName: string, computationType: WorkerTypeShortName) => {
      const nodeId = uuidv4();
      const commonPayload = { id: nodeId, name: computationName };
      createPlaygroundComputeNodeMutation({
        onCompleted: () => {
          expand(nodeId);
        },
        variables: {
          input: {
            draftNode: {
              [inputCreationKey[
                computationType as keyof typeof inputCreationKey
              ]]: {
                ...commonPayload,
                ...([
                  WorkerTypeShortName.R,
                  WorkerTypeShortName.Python,
                ].includes(computationType)
                  ? {
                      // Adding Main Script
                      initialScript: {
                        content: DEFAULT_SCRIPT.get(
                          scriptingWorkerTypeToScriptingLanguage.get(
                            computationType
                          )!
                        ),
                        isMainScript: true,
                        name: "Main Script",
                      },
                      // By default, output folder should be /output and the logic of its changing highly unlikely will be available on th UI
                      output: "/output",
                      scriptingLanguage:
                        scriptingLanguageFromWorkerType.get(computationType),
                    }
                  : {}),
              },
            },
            publishedDataRoomId: dataRoomId,
          },
        },
      });
    },
    [createPlaygroundComputeNodeMutation, dataRoomId, expand]
  );
  return (
    <ComputeNodeCreator
      dataRoomId={dataRoomId}
      excludeComputationTypes={excludeComputationTypes}
      onComputationCreate={onComputationCreate}
    />
  );
};

PlaygroundCreator.displayName = "PlaygroundCreator";

export default PlaygroundCreator;
