import {
  type DraftSqlNode,
  type PublishedSqlNode,
  WorkerTypeShortName,
} from "@decentriq/graphql/dist/types";
import { faShield } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Chip, Tooltip } from "@mui/joy";
import { memo } from "react";
import { WORKER_TYPE_COLOR } from "models";
import useComputeNodeType, { WORKER_TYPE_LABEL } from "./useComputeNodeType";

interface ComputeNodeTypeProps {
  computeNodeId: string;
  className?: string;
}

const ComputeNodeType: React.FC<ComputeNodeTypeProps> = memo(
  ({ computeNodeId }) => {
    const node = useComputeNodeType(computeNodeId);
    const computationType = node?.computationType!;
    return (
      <Chip
        endDecorator={
          computationType === WorkerTypeShortName.Sql &&
          !!(node as PublishedSqlNode | DraftSqlNode).privacyFilter
            ?.minimumRows ? (
            <Tooltip title={"This computation has a privacy filter enabled"}>
              <FontAwesomeIcon icon={faShield} />
            </Tooltip>
          ) : null
        }
        slotProps={{ endDecorator: { sx: { pointerEvents: "auto" } } }}
        sx={{
          backgroundColor:
            WORKER_TYPE_COLOR[
              computationType as keyof typeof WORKER_TYPE_COLOR
            ],
        }}
      >
        {WORKER_TYPE_LABEL[computationType]}
      </Chip>
    );
  }
);
ComputeNodeType.displayName = "ComputeNodeType";

export default ComputeNodeType;
