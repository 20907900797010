import { useAuth0 } from "@auth0/auth0-react";
import { useUserRoleQuery } from "@decentriq/graphql/dist/hooks";
import { UserRole } from "@decentriq/graphql/dist/types";

type UserRoleReturn = {
  isLoading: boolean;
  isNormalUser: boolean;
  isOrganizationAdmin: boolean;
  isSuperAdmin: boolean;
  isSuperAdminReadOnly: boolean;
  isDecentriqAdmin: boolean;
  organizationId?: string;
  userId?: string;
};

const useUserRole = (): UserRoleReturn => {
  const { user = {} } = useAuth0();
  const { email: userEmail } = user || {};
  const { data: userRoleData, loading } = useUserRoleQuery({
    variables: { userEmail },
  });
  const {
    id: userId,
    userRole,
    organization,
  } = userRoleData?.userByEmail || {};
  const { id: organizationId } = organization || {};
  return {
    isDecentriqAdmin:
      userRole === UserRole.SuperAdmin ||
      userRole === UserRole.SuperAdminReadOnly,
    isLoading: loading,
    isNormalUser: userRole === UserRole.NormalUser,
    isOrganizationAdmin: userRole === UserRole.OrganizationAdmin,
    isSuperAdmin: userRole === UserRole.SuperAdmin,
    isSuperAdminReadOnly: userRole === UserRole.SuperAdminReadOnly,
    organizationId,
    userId,
  };
};

export default useUserRole;
