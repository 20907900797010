import { type IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Stack, Typography } from "@mui/joy";
import { memo, type ReactNode } from "react";

export const DetailsGridIcon = memo(({ icon }: { icon: IconProp }) => (
  <FontAwesomeIcon fixedWidth={true} icon={icon} />
));

export const DetailsGridLabel = memo(({ label }: { label: string }) => (
  <Typography component="div" gutterBottom={true} level="body-sm">
    <strong>{label}</strong>
  </Typography>
));

export const DetailsGridValue = memo(
  ({
    value,
    icon,
    iconPosition = "left",
  }: {
    value: ReactNode;
    icon?: IconProp;
    iconPosition?: "left" | "right";
  }) => (
    <Stack
      alignContent="center"
      alignItems="center"
      direction="row"
      gap={1}
      maxWidth="100%"
    >
      {icon && iconPosition === "left" ? <DetailsGridIcon icon={icon} /> : null}
      <Typography
        level="body-sm"
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
      >
        {value}
      </Typography>
      {icon && iconPosition === "right" ? (
        <DetailsGridIcon icon={icon} />
      ) : null}
    </Stack>
  )
);
