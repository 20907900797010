import { DqLoader } from "@decentriq/components";
import { Grid } from "@mui/joy";
import { memo } from "react";
import AdvertiserAudienceGeneratorHeader from "../AdvertiserAudienceGeneratorStep/AdvertiserAudienceGeneratorHeader";

const AdvertiserAudienceGeneratorLoadingState = memo(() => {
  return (
    <Grid container={true} rowSpacing={6}>
      <Grid xs={7}>
        <AdvertiserAudienceGeneratorHeader />
      </Grid>
      <Grid display="flex" justifyContent="center" xs={12}>
        <DqLoader />
      </Grid>
    </Grid>
  );
});

AdvertiserAudienceGeneratorLoadingState.displayName =
  "AdvertiserAudienceGeneratorLoadingState";

export default AdvertiserAudienceGeneratorLoadingState;
