import { useBoolean } from "ahooks";
import { createContext, memo, useContext, useMemo } from "react";
import { OverlapInsightsExecutionContext } from "features/mediaDataRoom";

interface OverlapInsightsContextValue {
  executionContext: OverlapInsightsExecutionContext; // Defines whether insights rendered on the Insights tab, or Publisher's Insights Modal, ModalDialog, or Advertiser's Insights dialog
  reach?: number;
  audienceType?: string;
  isInsightsDialogOpen: boolean;
  openInsightsDialog: () => void;
  closeInsightsDialog: () => void;
}

export const MediaDataRoomOverlapInsightsContext =
  createContext<OverlapInsightsContextValue>({
    audienceType: "",
    closeInsightsDialog: () => {},
    executionContext: OverlapInsightsExecutionContext?.insightsTab,
    isInsightsDialogOpen: false,
    openInsightsDialog: () => {},
    reach: undefined,
  });

export const MediaDataRoomOverlapInsightsProvider =
  MediaDataRoomOverlapInsightsContext.Provider;

export const useMediaDataRoomOverlapInsights = () =>
  useContext(MediaDataRoomOverlapInsightsContext);

type MediaDataRoomOverlapInsightsWrapperProps = React.PropsWithChildren<{
  executionContext: OverlapInsightsExecutionContext;
  audienceType?: string;
  reach?: number;
}>;

const MediaDataRoomOverlapInsightsWrapper: React.FC<MediaDataRoomOverlapInsightsWrapperProps> =
  memo(
    ({
      executionContext = OverlapInsightsExecutionContext.advertiserInsights,
      audienceType = "",
      reach,
      children,
    }) => {
      const [
        isInsightsDialogOpen,
        { setTrue: openInsightsDialog, setFalse: closeInsightsDialog },
      ] = useBoolean(false);
      const contextValue = useMemo(
        () => ({
          audienceType,
          closeInsightsDialog,
          executionContext,
          isInsightsDialogOpen,
          openInsightsDialog,
          reach,
        }),
        [
          audienceType,
          closeInsightsDialog,
          executionContext,
          isInsightsDialogOpen,
          openInsightsDialog,
          reach,
        ]
      );
      return (
        <MediaDataRoomOverlapInsightsProvider value={contextValue}>
          {children}
        </MediaDataRoomOverlapInsightsProvider>
      );
    }
  );

MediaDataRoomOverlapInsightsWrapper.displayName =
  "MediaDataRoomInsightsWrapper";

export default MediaDataRoomOverlapInsightsWrapper;
