import {
  type DraftTableLeafNodeColumn,
  type PublishedTableLeafNodeColumn,
} from "@decentriq/graphql/dist/types";

export enum SyntheticNodeDataSourceTypeNames {
  DraftMatchNode = "DraftMatchNode",
  DraftSqlNode = "DraftSqlNode",
  DraftTableLeafNode = "DraftTableLeafNode",
}

export interface DataRoomSyntheticDataSourceOption {
  computeNodeId: string;
  name: string;
  type: "tabular" | "computation";
  namedColumns: (PublishedTableLeafNodeColumn | DraftTableLeafNodeColumn)[];
  typename: SyntheticNodeDataSourceTypeNames;
  updatedAt?: string;
}

export enum SyntheticNodeAccuracy {
  Balanced = 0,
  Strict = 3,
  Strictest = 1,
}

export const syntheticNodeAccuracyPresentation = new Map<
  SyntheticNodeAccuracy,
  string
>([
  [SyntheticNodeAccuracy.Balanced, "Balanced"],
  [SyntheticNodeAccuracy.Strict, "Strict"],
  [SyntheticNodeAccuracy.Strictest, "Strictest"],
]);

export const defaultSyntheticNodeAccuracy = SyntheticNodeAccuracy.Balanced;
