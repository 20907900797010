import { DqTable } from "@decentriq/components";
import { useExternalInvitationsQuery } from "@decentriq/graphql/dist/hooks";
import { type ExternalInvitationsQuery } from "@decentriq/graphql/dist/types";
import { Avatar, Box } from "@mui/joy";
import { format } from "date-fns";
import { type MRT_ColumnDef } from "material-react-table";
import { memo, useMemo } from "react";
import { SendInvitationButton } from "components";

type ExternalInvitationsListProps = {
  organizationId: string;
};

const ExternalInvitationsList: React.FC<ExternalInvitationsListProps> = ({
  organizationId,
}) => {
  const { data } = useExternalInvitationsQuery({
    variables: {
      organizationId,
    },
  });
  const { externalInvitations } = useMemo(() => {
    const externalInvitations =
      data?.organization?.userInvitations?.nodes || [];
    const totalCount = data?.organization?.userInvitations?.totalCount || 0;
    return {
      externalInvitations,
      totalCount,
    };
  }, [data]);
  type Invitation =
    ExternalInvitationsQuery["organization"]["userInvitations"]["nodes"][number];
  const externalInvitationsColumns = useMemo<MRT_ColumnDef<Invitation>[]>(
    () => [
      {
        Cell: ({ cell }) => (
          <Avatar>
            {cell.getValue<string>()?.substring(0, 2)?.toUpperCase() || ""}
          </Avatar>
        ),
        accessorKey: "user.email",
        header: "",
        id: "avatar",
        maxSize: 10,
      },
      {
        accessorFn: (row) => row?.user?.email || "—",
        header: "Email",
        id: "email",
      },
      {
        Cell: ({ cell }) => {
          const lastInvitedAt = cell.getValue<string>();
          return lastInvitedAt
            ? format(new Date(lastInvitedAt), "dd.MM.yyy, HH:mm")
            : "—";
        },
        accessorKey: "lastInvitedAt",
        header: "Last invited",
        id: "lastInvitedAt",
      },
      {
        accessorFn: (row) => row?.lastInvitedBy?.email || "—",
        header: "Last invited by",
        id: "lastInvitedBy",
      },
    ],
    []
  );
  return (
    <DqTable
      columns={externalInvitationsColumns}
      data={externalInvitations}
      enableGlobalFilter={true}
      enableSorting={true}
      enableTopToolbar={true}
      initialState={{
        showGlobalFilter: true,
        sorting: [{ desc: true, id: "lastInvitedAt" }],
      }}
      localization={{
        noRecordsToDisplay: "No external invitations found",
      }}
      muiSearchTextFieldProps={{
        placeholder: "Search users",
      }}
      muiTableBodyRowProps={{
        sx: {
          "& > .MuiTableCell-root:first-child": { pl: 2 },
          "& > .MuiTableCell-root:last-child": { pr: 2 },
        },
      }}
      muiTableHeadRowProps={{
        sx: {
          "& > .MuiTableCell-root:first-child": { pl: 2 },
          "& > .MuiTableCell-root:last-child": {
            "& .Mui-TableHeadCell-ResizeHandle-Wrapper": {
              right: "-1rem",
            },
            pr: 2,
          },
        },
      }}
      muiTablePaperProps={{
        sx: {
          display: "flex",
          flex: 1,
          flexDirection: "column",
          height: "100%",
          overflow: "hidden",
          width: "100%",
        },
      }}
      renderTopToolbarCustomActions={() => {
        return (
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              m: 1,
            }}
          >
            <SendInvitationButton organizationId={organizationId} />
          </Box>
        );
      }}
    />
  );
};
ExternalInvitationsList.displayName = "ExternalInvitationsList";

export default memo(ExternalInvitationsList);
