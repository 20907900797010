import { useDatasetByHashQuery } from "@decentriq/graphql/dist/hooks";
import { type DatasetByHashQuery } from "@decentriq/graphql/dist/types";
import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { type KeychainItem, KeychainItemKind } from "services/keychain";
import { useKeychain } from "contexts";
import { logError } from "utils";
import { type DatasetSchema, validateDatasetSchema } from "utils/dataset";

interface DatasetWithSchemaHookPayload {
  skip: boolean;
  manifestHash?: string;
}

interface DatasetWithSchemaHookResult {
  dataset?: DatasetByHashQuery["datasetByManifestHash"];
  schema?: DatasetSchema;
  keychainItem?: KeychainItem;
  loading: boolean;
}

export const useDatasetWithSchema = ({
  skip,
  manifestHash,
}: DatasetWithSchemaHookPayload): DatasetWithSchemaHookResult => {
  const keychain = useKeychain();
  const { data: datasetData, loading } = useDatasetByHashQuery({
    skip: skip || !manifestHash,
    variables: {
      manifestHash: manifestHash!,
    },
  });
  const { data, isLoading: loadingSchema } = useQuery<{
    schema: DatasetSchema;
    keychainItem: KeychainItem;
  } | null>({
    enabled: Boolean(manifestHash) && !skip,
    queryFn: async () => {
      try {
        const keychainItem = await keychain.getItem(
          manifestHash!,
          KeychainItemKind.DatasetMetadata
        );
        const schema = validateDatasetSchema(
          JSON.parse(keychainItem.value ?? "")
        );
        return { keychainItem, schema };
      } catch (error) {
        logError("Failed to parse dataset metadata", error);
        return null;
      }
    },
    queryKey: ["dataset", manifestHash, "schema"],
  });
  return {
    dataset: useMemo(() => datasetData?.datasetByManifestHash, [datasetData]),
    keychainItem: data?.keychainItem,
    loading: loading || loadingSchema,
    schema: data?.schema,
  };
};
