import { faExclamationTriangle as fatExclamationTriangle } from "@fortawesome/pro-thin-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Modal,
  ModalDialog,
  Typography,
} from "@mui/joy";
import { memo, useMemo } from "react";
import { type Audience, useAdvertiserAudiences } from "features/mediaDataRoom";
import AudienceCreationDate from "../AudienceCreationDate/AudienceCreationDate";

interface DeleteAccountDialogProps {
  open: boolean;
  onClose: () => void;
  audience: Audience;
}

const DeleteAudienceDialog: React.FC<DeleteAccountDialogProps> = ({
  open,
  onClose,
  audience,
}) => {
  const { getAudiencePreqrequisites, isDeletingAudience, deleteAudience } =
    useAdvertiserAudiences();
  const dependencies = useMemo(() => {
    if (!open) {
      return [];
    }
    return getAudiencePreqrequisites(audience.id);
  }, [audience.id, getAudiencePreqrequisites, open]);
  return (
    <Modal onClose={!isDeletingAudience ? onClose : undefined} open={open}>
      <ModalDialog color="danger" role="alertdialog">
        <DialogTitle>
          <FontAwesomeIcon icon={fatExclamationTriangle} size="4x" />
          <Typography>Permanent delete {audience.mutable.name}</Typography>
        </DialogTitle>
        <DialogContent>
          {dependencies && dependencies.length > 0 ? (
            <>
              <Typography>
                Deleting this audience will also delete all the following
                associated rule-based audiences created with it.
                <br />
                <b>This action cannot be undone.</b>
              </Typography>
              {dependencies.map(({ mutable: { name }, created_at, id }) => {
                return (
                  <Typography key={id} textAlign="left">
                    <b>Audience Name:</b> {name} <b>Creation Date: </b>
                    <AudienceCreationDate createdAt={created_at as string} />
                  </Typography>
                );
              })}
            </>
          ) : (
            <Typography>
              Deleting this audience will also delete it for the publisher.
              <br />
              <b>This action cannot be undone.</b>
            </Typography>
          )}
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button disabled={isDeletingAudience} onClick={onClose}>
            Cancel
          </Button>
          <Button
            color="danger"
            loading={isDeletingAudience}
            loadingPosition="start"
            onClick={() => deleteAudience(audience)}
            startDecorator={<FontAwesomeIcon icon={fatExclamationTriangle} />}
            variant="solid"
          >
            Delete audience
          </Button>
        </DialogActions>
      </ModalDialog>
    </Modal>
  );
};

DeleteAudienceDialog.displayName = "DeleteAudienceDialog";

export default memo(DeleteAudienceDialog);
