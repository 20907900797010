import { useComputeNodeRunMutation } from "@decentriq/graphql/dist/hooks";
import { type TestDataset } from "@decentriq/graphql/dist/types";
import { usePublishedDataRoom } from "contexts";
import useTestDatasets from "features/dataNodes/hooks/useTestDatasets";
import { mapDataRoomErrorToSnackbar, useDataRoomSnackbar } from "hooks";
import { type ComputeNodeTypeNames } from "models";

interface ComputeNodeRunHookArgs {
  computationType?: ComputeNodeTypeNames;
  computeNodeId: string;
}

const useComputeNodeRun = ({
  computationType,
  computeNodeId,
}: ComputeNodeRunHookArgs) => {
  const { enqueueSnackbar } = useDataRoomSnackbar();
  const { dataRoomId, dcrHash, driverAttestationHash, testing } =
    usePublishedDataRoom();
  const [computeNodeRunMutation, { loading }] = useComputeNodeRunMutation({
    onError: (error) => {
      enqueueSnackbar(
        ...mapDataRoomErrorToSnackbar(
          error,
          `The computation could not be run.`
        )
      );
    },
  });
  const fetchTestDatasets = useTestDatasets();
  const runComputeNode = async () => {
    const testDatasets: TestDataset[] | undefined = testing
      ? await fetchTestDatasets()
      : undefined;
    return computeNodeRunMutation({
      context: {
        __typename: computationType,
        dataRoomId,
      },
      variables: {
        autoFetching: true,
        computeNodeId,
        dataRoomId,
        dcrHash,
        driverAttestationHash,
        testDatasets,
      },
    });
  };
  return {
    loading,
    runComputeNode,
  };
};

export default useComputeNodeRun;
