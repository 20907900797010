import {
  CurrentUserDomainDocument,
  useOrganizationLogoQuery,
  useUpsertOrganizationLogoMutation,
} from "@decentriq/graphql/dist/hooks";
import { faTrashCan } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button } from "@mui/joy";
import { useCallback } from "react";
import { ImageUploadButton } from "components";
import { CommonSnackbarOrigin, useGeneralSnackbar, useUserRole } from "hooks";
import { getEffectiveErrorMessage } from "utils";

interface OrganizationLogoEditorProps {
  organizationId: string;
}

const OrganizationLogoEditor = ({
  organizationId,
}: OrganizationLogoEditorProps) => {
  const { enqueueSnackbar } = useGeneralSnackbar({
    origin: CommonSnackbarOrigin.ADMIN,
  });
  const [upsertOrganizationLogoMutation] = useUpsertOrganizationLogoMutation();
  const { data: organizationLogoData } = useOrganizationLogoQuery({
    variables: { organizationId },
  });
  const updateOrganizationLogo = useCallback(
    async (logo: string | null) => {
      try {
        await upsertOrganizationLogoMutation({
          refetchQueries: [{ query: CurrentUserDomainDocument }],
          update: (cache, data) => {
            cache.modify({
              fields: {
                logo: () => data?.data?.organization?.update?.logo,
              },
              id: cache.identify({
                __typename: "Organization",
                id: organizationId,
              }),
            });
          },
          variables: {
            input: {
              id: organizationId,
              logo: { value: logo },
            },
          },
        });

        enqueueSnackbar(`Organization logo has been successfully updated.`);
      } catch (error) {
        enqueueSnackbar("Organization logo could not be updated.", {
          context: getEffectiveErrorMessage(error),
          persist: true,
          variant: "error",
        });
      }
    },
    [enqueueSnackbar, organizationId, upsertOrganizationLogoMutation]
  );
  const { isSuperAdminReadOnly } = useUserRole();
  const logo = organizationLogoData?.organization?.logo;

  return (
    <Box
      sx={{ alignItems: "center", display: "flex", flexDirection: "column" }}
    >
      {logo && (
        <img
          alt={organizationId}
          loading="lazy"
          src={`data:image;base64,${logo}`}
          style={{ height: 250, marginRight: 16 }}
        />
      )}
      <Box>
        <ImageUploadButton
          disabled={isSuperAdminReadOnly}
          onImageSelected={updateOrganizationLogo}
        />
        {logo && (
          <Button
            disabled={isSuperAdminReadOnly}
            onClick={() => updateOrganizationLogo(null)}
            startDecorator={<FontAwesomeIcon icon={faTrashCan} />}
            sx={{ height: "fit-content", marginLeft: 2 }}
          >
            Remove logo
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default OrganizationLogoEditor;
