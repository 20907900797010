import {
  type AuditLog,
  type QueryAuditLogArgs,
} from "@decentriq/graphql/dist/types";
import { type ApiCoreContextValue } from "contexts";
import { type LocalResolverContext } from "../../../models";

const makeAuditLogResolver =
  (
    client: ApiCoreContextValue["client"],
    sessionManager: ApiCoreContextValue["sessionManager"],
    store: ApiCoreContextValue["store"]
  ) =>
  async (
    parent = null,
    args: QueryAuditLogArgs,
    context: LocalResolverContext
  ): Promise<AuditLog> => {
    const { dcrHash, driverAttestationHash } = args;
    const sdkSession = await sessionManager.get({
      driverAttestationHash,
    });
    const { log } = await sdkSession.retrieveAuditLog(dcrHash);
    if (!log) {
      return {
        log: "",
      };
    }
    const parsedAuditLog = new TextDecoder().decode(log);
    return {
      log: parsedAuditLog,
    };
  };

export default makeAuditLogResolver;
