import { FormControl, FormLabel, Grid, Input, Option, Select } from "@mui/joy";
import { useUpdateEffect } from "ahooks";
import { memo, useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { OverlapInsightsVisualizeDirection } from "features/MediaInsightsDcr";
import useVisualizeSelectorOrderByData from "./useVisualizeSelectorOrderByData";

const visualizeDirectionMap = new Map<
  OverlapInsightsVisualizeDirection,
  string
>([
  [OverlapInsightsVisualizeDirection.top, "Top"],
  [OverlapInsightsVisualizeDirection.bottom, "Bottom"],
]);

const LookalikeMediaInsightsVisualizeSelector: React.FC = () => {
  const { control: formControl, setValue, getValues } = useFormContext();

  const { visualizeSelectorData: orderByData, rowsTotalCount } =
    useVisualizeSelectorOrderByData();

  const optionsTag = orderByData?.map(({ value }) => value).join("|") || "";

  // Set default value
  useEffect(() => {
    const options = optionsTag.split("|");
    const defaultOrderByValue = options[0];
    const currentValue = getValues().visualize.orderBy;
    if (defaultOrderByValue && !options.includes(currentValue)) {
      setValue("visualize.orderBy", defaultOrderByValue);
    }
  }, [optionsTag, setValue, getValues]);

  useUpdateEffect(() => {
    setValue("visualize.limit", rowsTotalCount >= 20 ? 20 : rowsTotalCount);
  }, [setValue, rowsTotalCount]);

  return (
    <>
      <FormControl>
        <FormLabel>Visualize</FormLabel>
        <Grid columnSpacing={1} container={true}>
          <Grid xs={9}>
            <Controller
              control={formControl}
              name="visualize.direction"
              render={({ field }) => (
                <Select
                  renderValue={({ value }) => visualizeDirectionMap.get(value)}
                  {...field}
                  onChange={(event, value) => field.onChange(value)}
                >
                  {Object.keys(OverlapInsightsVisualizeDirection).map(
                    (direction) => (
                      <Option key={direction} value={direction}>
                        {visualizeDirectionMap.get(
                          direction as OverlapInsightsVisualizeDirection
                        )}
                      </Option>
                    )
                  )}
                </Select>
              )}
            />
          </Grid>
          <Grid xs={3}>
            <Controller
              control={formControl}
              name="visualize.limit"
              render={({ field: { onChange, ...restField } }) => (
                <FormControl>
                  <Input
                    onChange={(event) =>
                      onChange(parseInt(event?.target?.value))
                    }
                    slotProps={{
                      input: { max: rowsTotalCount, min: 0, type: "number" },
                    }}
                    {...restField}
                  />
                </FormControl>
              )}
            />
          </Grid>
        </Grid>
      </FormControl>
      <FormControl>
        <FormLabel>By</FormLabel>
        <Controller
          control={formControl}
          name="visualize.orderBy"
          render={({ field }) => (
            <Select
              renderValue={({ value }) =>
                orderByData.find((orderBy) => orderBy?.value === value)
                  ?.label || ""
              }
              {...field}
              onChange={(event, value) => field.onChange(value)}
            >
              {orderByData.map(({ value, label }) => (
                <Option key={value} value={value}>
                  {label}
                </Option>
              ))}
            </Select>
          )}
        />
      </FormControl>
    </>
  );
};

LookalikeMediaInsightsVisualizeSelector.displayName =
  "LookalikeMediaInsightsVisualizeSelector";

export default memo(LookalikeMediaInsightsVisualizeSelector);
