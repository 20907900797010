import { useCallback } from "react";
import { type Formatter } from "react-timeago";

const useTimeFormatter = () => {
  const timeFormatter = useCallback<Formatter>(
    (value, unit, suffix) =>
      unit === "second" && value <= 59
        ? "just now"
        : `${value} ${unit}${value === 1 ? "" : "s"} ${suffix}`,
    []
  );
  return timeFormatter;
};

export default useTimeFormatter;
