import { useAuth0 } from "@auth0/auth0-react";
import { usePublishedMediaInsightsDcrOrganizationsQuery } from "@decentriq/graphql/dist/hooks";
import {
  type Organization,
  type PublishedMediaDataRoomQuery,
  type TableColumnFormatType,
  type TableColumnHashingAlgorithm,
} from "@decentriq/graphql/dist/types";
import {
  type ab_media_response as ddcAbMediaResponse,
  type AbMediaComputeLatest,
} from "ddc";
import { createContext, memo, useMemo } from "react";
import { MediaDataRoomJobInput } from "features/mediaDataRoom/hooks";
import { RawSupportedFeatures } from "features/mediaDataRoom/models";
import { isNormalizedEmailIncluded } from "features/mediaDataRoom/utils";
import { useSafeContext } from "hooks";
import { RawMediaDataRoomFeatures } from "models";
import useMediaDataRoomHlDefinition from "./useMediaDataRoomHlDefinition";

export interface MediaDataRoomContextValue {
  definition: AbMediaComputeLatest;
  rawDefinition: ddcAbMediaResponse.AbMediaDcr;
  rawSupportedFeatures: string[];
  loading: boolean;
  dataRoomId: string;
  driverAttestationHash: string;
  hasDataPartner: boolean;
  isAdvertiser: boolean;
  isAgency: boolean;
  hasAgency: boolean;
  isDataPartner: boolean;
  isDeactivated: boolean;
  isPublisher: boolean;
  isStopped: boolean;
  features: {
    remarketing: boolean;
    insights: boolean;
    lookalike: boolean;
    ruleBasedAudiences: boolean;
  };
  isObserver: boolean;
  enableExtendedLookalikeStatistics: boolean;
  publisherOrganization:
    | Pick<Organization, "logo" | "__typename" | "id" | "name">
    | undefined;
  advertiserOrganization:
    | Pick<Organization, "logo" | "__typename" | "id" | "name">
    | undefined;
  supportedFeatures: {
    enableAdvertiserAudienceDownload: boolean;
    performanceReportEnabled: boolean;
    hideAbsoluteValues: boolean;
  };
  matchingIdFormat: TableColumnFormatType;
  hashMatchingIdWith: TableColumnHashingAlgorithm | null;
}

export const MediaDataRoomContext =
  createContext<MediaDataRoomContextValue | null>(null);
MediaDataRoomContext.displayName = "MediaDataRoomContext";

export const useMediaDataRoom = () => useSafeContext(MediaDataRoomContext);

export type MediaDataRoomWrapperProps = React.PropsWithChildren<{
  data: PublishedMediaDataRoomQuery["publishedMediaDataRoom"];
}>;

const MediaDataRoomWrapper = memo<MediaDataRoomWrapperProps>(
  ({
    data: {
      id: dataRoomId,
      driverAttestationHash,
      isStopped,
      deactivatedAt,
      enableExtendedLookalikeQualityStatistics,
    },
    children,
  }) => {
    const { user } = useAuth0();
    const currentUserEmail = user?.email || "";
    const { data: hlDefinition, isLoading: isHlDefinitionLoading } =
      useMediaDataRoomHlDefinition({
        dataRoomId,
        driverAttestationHash,
        queryKey: MediaDataRoomJobInput.create(
          "retrieveDataRoom",
          dataRoomId,
          driverAttestationHash
        )
          .withFetchResultsSubkey()
          .buildQueryKey(),
      });
    const {
      mainAdvertiserEmail,
      mainPublisherEmail,
      publisherEmails: publisherUserEmails = [],
      advertiserEmails: advertiserUserEmails = [],
      agencyEmails: agencyUserEmails = [],
      observerEmails: observerUserEmails = [],
      dataPartnerEmails: dataPartnerUserEmails = [],
      hashMatchingIdWith,
      matchingIdFormat,
    } = hlDefinition?.definition ?? {};
    const {
      hasDataPartner,
      isAdvertiser,
      isAgency,
      hasAgency,
      isDataPartner,
      isObserver,
      isPublisher,
    } = useMemo(
      () => ({
        hasAgency: agencyUserEmails.length > 0,
        hasDataPartner:
          dataPartnerUserEmails !== null && dataPartnerUserEmails.length > 0,
        isAdvertiser: isNormalizedEmailIncluded(
          advertiserUserEmails,
          currentUserEmail
        ),
        isAgency: isNormalizedEmailIncluded(agencyUserEmails, currentUserEmail),
        isDataPartner: isNormalizedEmailIncluded(
          dataPartnerUserEmails || [],
          currentUserEmail
        ),
        isObserver: isNormalizedEmailIncluded(
          observerUserEmails,
          currentUserEmail
        ),
        isPublisher: isNormalizedEmailIncluded(
          publisherUserEmails,
          currentUserEmail
        ),
      }),
      [
        advertiserUserEmails,
        agencyUserEmails,
        currentUserEmail,
        dataPartnerUserEmails,
        observerUserEmails,
        publisherUserEmails,
      ]
    );
    const { data: organizationsData, loading: isOrganisationsDataLoading } =
      usePublishedMediaInsightsDcrOrganizationsQuery({
        skip: !mainAdvertiserEmail || !mainPublisherEmail,
        variables: {
          advertiserEmail: mainAdvertiserEmail!,
          publisherEmail: mainPublisherEmail!,
        },
      });
    const contextValue = useMemo(
      (): MediaDataRoomContextValue => ({
        advertiserOrganization: {
          ...organizationsData?.advertiserOrganization?.organization!,
          logo: organizationsData?.advertiserOrganization?.organizationLogo,
        },
        dataRoomId,
        definition: hlDefinition?.definition!,
        driverAttestationHash,
        enableExtendedLookalikeStatistics:
          enableExtendedLookalikeQualityStatistics || false,
        features: {
          insights:
            hlDefinition?.supportedFeatures?.includes(
              RawMediaDataRoomFeatures.Insights
            ) ?? false,
          lookalike:
            hlDefinition?.supportedFeatures?.includes(
              RawMediaDataRoomFeatures.Lookalike
            ) ?? false,
          remarketing:
            hlDefinition?.supportedFeatures?.includes(
              RawMediaDataRoomFeatures.Remarketing
            ) ?? false,
          ruleBasedAudiences:
            hlDefinition?.supportedFeatures?.includes(
              RawMediaDataRoomFeatures.RuleBasedAudiences
            ) ?? false,
        },
        hasAgency,
        hasDataPartner,
        hashMatchingIdWith: (hashMatchingIdWith ||
          null) as TableColumnHashingAlgorithm | null,
        isAdvertiser,
        isAgency,
        isDataPartner,
        isDeactivated: Boolean(deactivatedAt),
        isObserver,
        isPublisher,
        isStopped,
        loading: isHlDefinitionLoading || isOrganisationsDataLoading,
        matchingIdFormat: matchingIdFormat as TableColumnFormatType,
        publisherOrganization: {
          ...organizationsData?.publisherOrganization?.organization!,
          logo: organizationsData?.publisherOrganization?.organizationLogo,
        },
        rawDefinition: hlDefinition?.rawDefinition!,
        rawSupportedFeatures: hlDefinition?.supportedFeatures!,
        supportedFeatures: {
          enableAdvertiserAudienceDownload:
            hlDefinition?.supportedFeatures?.includes(
              RawSupportedFeatures.ENABLE_ADVERTISER_AUDIENCE_DOWNLOAD
            ) ?? false,
          hideAbsoluteValues:
            hlDefinition?.supportedFeatures?.includes(
              RawSupportedFeatures.ENABLE_HIDE_ABSOLUTE_AUDIENCE_SIZES
            ) ?? false,
          performanceReportEnabled:
            hlDefinition?.supportedFeatures?.includes(
              RawSupportedFeatures.ENABLE_MODEL_PERFORMANCE_EVALUATION
            ) ?? false,
        },
      }),
      [
        enableExtendedLookalikeQualityStatistics,
        organizationsData?.advertiserOrganization?.organization,
        organizationsData?.advertiserOrganization?.organizationLogo,
        organizationsData?.publisherOrganization?.organization,
        organizationsData?.publisherOrganization?.organizationLogo,
        dataRoomId,
        hlDefinition?.definition,
        hlDefinition?.supportedFeatures,
        hlDefinition?.rawDefinition,
        driverAttestationHash,
        hasAgency,
        hasDataPartner,
        hashMatchingIdWith,
        isAdvertiser,
        isAgency,
        isDataPartner,
        deactivatedAt,
        isObserver,
        isPublisher,
        isStopped,
        isHlDefinitionLoading,
        isOrganisationsDataLoading,
        matchingIdFormat,
      ]
    );
    return (
      <MediaDataRoomContext.Provider value={contextValue}>
        {children}
      </MediaDataRoomContext.Provider>
    );
  }
);

MediaDataRoomWrapper.displayName = "MediaDataRoomWrapper";

export default MediaDataRoomWrapper;
