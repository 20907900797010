import { CircularProgress } from "@mui/joy";
import { memo, useMemo } from "react";
import { Navigate, useResolvedPath, useRoutes } from "react-router-dom";
import { EmptyStateContainer } from "features/MediaInsightsDcr";
import MediaDataRoomActivation from "features/MediaInsightsDcr/components/MediaDataRoomActivation/MediaDataRoomActivation";
import { usePublishedMediaInsightsDcr } from "../contexts/MediaInsightsDcrContext/MediaInsightsDcrContext";
import {
  MediaDataRoomActivationTab,
  MediaDataRoomTab,
  mediaDataRoomTabs,
} from "../models";
import {
  AdvertiserAudienceGenerator,
  AdvertiserAudienceGeneratorWrapper,
} from "./AdvertiserAudienceGenerator";
import { LookalikeAdvertiserAudienceGenerator } from "./LookalikeAdvertiserAudienceGenerator";
import MediaDataRoomTabs from "./MediaDataRoomTabs";

const MediaDataRoomActiveTab: React.FC = memo(() => {
  const { isAgency, isAdvertiser, loading } = usePublishedMediaInsightsDcr();
  const resolvedPath = useResolvedPath("");
  const url = resolvedPath.pathname;

  const tabs = useMemo(
    () =>
      !loading
        ? [
            {
              element: <Navigate to={`${url}/${MediaDataRoomTab.data}`} />,
              path: "/",
            },
            ...mediaDataRoomTabs.map((tab) => ({
              element: <MediaDataRoomTabs activeTab={tab} baseUrl={url} />,
              path: tab,
            })),
            {
              children: [
                ...(isAdvertiser || isAgency
                  ? [
                      {
                        element: (
                          <AdvertiserAudienceGeneratorWrapper>
                            <AdvertiserAudienceGenerator
                              LookalikeAudienceGenerator={
                                LookalikeAdvertiserAudienceGenerator
                              }
                            />
                          </AdvertiserAudienceGeneratorWrapper>
                        ),
                        path: MediaDataRoomActivationTab.creation,
                      },
                    ]
                  : []),
                {
                  element: <MediaDataRoomActivation />,
                  path: "",
                },
              ],
              element: (
                <MediaDataRoomTabs
                  activeTab={MediaDataRoomTab.activation}
                  baseUrl={url}
                />
              ),
              path: MediaDataRoomTab.activation,
            },
            {
              element: <Navigate to={`${url}/${MediaDataRoomTab.data}`} />,
              path: "*",
            },
          ]
        : [],
    [isAdvertiser, isAgency, loading, url]
  );
  const activeTab = useRoutes(tabs);

  if (loading) {
    return (
      <EmptyStateContainer>
        <CircularProgress sx={{ "--CircularProgress-size": "16px" }} />
      </EmptyStateContainer>
    );
  }

  return activeTab;
});

MediaDataRoomActiveTab.displayName = "MediaDataRoomActiveTab";

export default MediaDataRoomActiveTab;
