import { exceptions } from "@decentriq/utils";
import { useCallback, useEffect } from "react";
import { DataNodeUploadDataDialog } from "features";
import { DataNodeConstructorMode } from "features/dataNodes/models";
import {
  type DataIngestionPayload,
  type DatasetIngestionDefinition,
  type FileIngestionDefinition,
} from "features/datasets";
import { useReportError } from "hooks";
import {
  type DataRoomData,
  type DataRoomDataTable,
  DataRoomType,
} from "models";
import DataNodeActions from "../../components/DataNodeConstructor/DataNodeActions";
import useTestDraftDataNodeActions from "./useTestDraftDataNodeActions";

interface TestDataNodeActionsProps {
  dataNode: DataRoomData;
  dataRoomId: string;
  mode: DataNodeConstructorMode;
  nodesLoading: boolean;
}

const TestDataNodeActions: React.FC<TestDataNodeActionsProps> = ({
  dataNode,
  dataRoomId,
  mode,
  nodesLoading,
}) => {
  const {
    activeDataRoomUpload,
    currentUserEmail,
    dataNodeForIngestion,
    handleIngestData,
    handleDataDeprovision,
    handleConnectFromKeychain,
    resetUploadings,
    setDataNodeForIngestion,
    uploadings,
    handleUploadClose,
  } = useTestDraftDataNodeActions();
  const reportError = useReportError();
  useEffect(() => {
    if (
      mode === DataNodeConstructorMode.ACTION ||
      mode === DataNodeConstructorMode.STATUS ||
      mode === DataNodeConstructorMode.DEGRADE_ACTION
    ) {
      resetUploadings();
    }
  }, [mode, dataRoomId, resetUploadings]);
  const onDataDeprovision = useCallback(
    async () => await handleDataDeprovision(dataNode.id),
    [handleDataDeprovision, dataNode.id]
  );
  const handleError = useCallback(
    (error: Error) => {
      if (
        error instanceof exceptions.DatasetValidationError &&
        error.hasReport
      ) {
        return;
      }
      reportError(
        {
          details: error.message,
          errorContext: [
            {
              content: dataRoomId,
              name: "dataRoomId",
            },
          ],
          origin: `${DataRoomType.DataScience}_TEST`,
        },
        { silent: true }
      );
    },
    [reportError, dataRoomId]
  );
  const key = `${dataNode.id}-${currentUserEmail}`;
  const id = dataNodeForIngestion?.id;
  const onIngest = useCallback(
    async (
      payload:
        | DataIngestionPayload<DatasetIngestionDefinition>
        | DataIngestionPayload<FileIngestionDefinition>
    ) => {
      if (payload.source === "local") {
        return await handleIngestData({
          dataNodeId: id!,
          schema: payload.schema,
          shouldStoreInKeychain: !!payload.shouldStoreInKeychain,
          uploadResult: payload.uploadResult!,
        });
      }
      if (payload.source === "keychain") {
        return await handleConnectFromKeychain(
          id!,
          payload.datasetKeychainItem!
        );
      }
    },
    [id, handleIngestData, handleConnectFromKeychain]
  );
  return (
    <div onClick={(event) => event.stopPropagation()}>
      <DataNodeActions
        dataType={dataNode.dataType}
        datasetManifestHash={dataNode?.testDataset?.datasetHash}
        id={dataNode.id}
        isLoading={
          uploadings[key]?.isLoading ||
          activeDataRoomUpload === key ||
          nodesLoading
        }
        onDeprovision={onDataDeprovision}
        onUpload={() => {
          setDataNodeForIngestion(dataNode);
        }}
        testing={true}
      />
      {dataNodeForIngestion && (
        <DataNodeUploadDataDialog
          columns={
            dataNodeForIngestion.dataType === "table"
              ? (dataNodeForIngestion as DataRoomDataTable).columns
              : undefined
          }
          columnsOrder={
            dataNodeForIngestion.dataType === "table"
              ? (dataNodeForIngestion as DataRoomDataTable).columnsOrder
              : undefined
          }
          id={dataNodeForIngestion.id}
          name={dataNodeForIngestion.name}
          onClose={handleUploadClose}
          onError={handleError}
          onIngest={onIngest}
          open={!!dataNodeForIngestion}
          uniqueColumnIds={
            dataNodeForIngestion.dataType === "table"
              ? (dataNodeForIngestion as DataRoomDataTable).uniqueColumnIds
              : undefined
          }
        />
      )}
    </div>
  );
};

export default TestDataNodeActions;
