import {
  type DraftTableLeafNodeUpdateUniquenessMutationFn,
  useDraftTableLeafNodeUniquenessQuery,
  useDraftTableLeafNodeUpdateUniquenessMutation,
  usePublishedTableLeafNodeUniquenessQuery,
} from "@decentriq/graphql/dist/hooks";
import {
  type DraftTableLeafNode,
  type DraftTableLeafNodeUniquenessQuery,
  type PublishedTableLeafNode,
  type PublishedTableLeafNodeUniquenessQuery,
} from "@decentriq/graphql/dist/types";
import { useCallback, useMemo } from "react";
import {
  useComputeNodesVars,
  useDataRoom,
  usePublishedDataRoom,
  useRequest,
} from "contexts";
import { useDataNodes } from "features/dataNodes/containers/DataNodes/useDataNodes";
import { DataNodeConstructorMode } from "features/dataNodes/models";
import {
  mapDraftDataRoomErrorToSnackbar,
  mapErrorToGeneralSnackbar,
  useDataRoomSnackbar,
} from "hooks";
import { type DataRoomDataTable } from "models";

export type useUniquenessProps = (id: string) => {
  dataNodesColumnIdsByName: Map<string, Map<string, string> | undefined>;
  dataNodesColumnNamesById: Map<string, Map<string, string> | undefined>;
  draftTableLeafNodeUpdateUniquenessMutation: DraftTableLeafNodeUpdateUniquenessMutationFn;
  draftTableLeafNodeUpdateUniquenessMutationLoading: boolean;
  isLoading: boolean;
  uniqueColumnIds: Array<Array<string>>;
  updateUniqueColumnIds: (uniqueColumnIds: string[][]) => void;
};

const useUniqueness: useUniquenessProps = (id) => {
  const { enqueueSnackbar } = useDataRoomSnackbar();
  const { dataRoomId, isPublished } = useDataRoom();
  const { dataRoomId: dcrHash, driverAttestationHash } = usePublishedDataRoom();
  const { dataNodes, loading: dataNodesLoading } = useDataNodes({
    dataRoomId,
    mode: DataNodeConstructorMode.EDIT,
    permittedOnly: false,
  });
  const dataNodesColumnNamesById = useMemo(() => {
    return new Map(
      dataNodes
        ?.filter((dataNode) => dataNode.dataType === "table")
        ?.map((dataNode) => [
          dataNode.id,
          new Map(
            (dataNode as DataRoomDataTable)?.columns?.map((column) => [
              column.id,
              column.name,
            ])
          ),
        ])
    );
  }, [dataNodes]);
  const dataNodesColumnIdsByName = useMemo(() => {
    return new Map(
      dataNodes
        ?.filter((dataNode) => dataNode.dataType === "table")
        ?.map((dataNode) => [
          dataNode.id,
          new Map(
            (dataNode as DataRoomDataTable)?.columns?.map((column) => [
              column.name,
              column.id,
            ])
          ),
        ])
    );
  }, [dataNodes]);
  const { commitId } = useRequest();
  const { executionContext } = useComputeNodesVars();
  const shouldUseDraft =
    !isPublished ||
    executionContext === "development" ||
    executionContext === "requests";
  const {
    data: draftTableLeafNodeUniquenessData,
    loading: draftTableLeafNodeUniquenessLoading,
  } = useDraftTableLeafNodeUniquenessQuery({
    onError: (error) => {
      enqueueSnackbar(
        ...mapDraftDataRoomErrorToSnackbar(
          error,
          "Can't fetch columns uniqueness"
        )
      );
    },
    skip: !shouldUseDraft,
    variables: { id },
  });
  const {
    data: publishedTableLeafNodeUniquenessData,
    loading: publishedTableLeafNodeUniquenessLoading,
  } = usePublishedTableLeafNodeUniquenessQuery({
    onError: (error) => {
      enqueueSnackbar(
        ...mapErrorToGeneralSnackbar(error, "Can't fetch columns uniqueness")
      );
    },
    skip: shouldUseDraft,
    variables: { commitId, dcrHash, driverAttestationHash, id },
  });
  const uniquenessLoading = shouldUseDraft
    ? draftTableLeafNodeUniquenessLoading
    : publishedTableLeafNodeUniquenessLoading;
  const uniquenessData = shouldUseDraft
    ? (draftTableLeafNodeUniquenessData as DraftTableLeafNodeUniquenessQuery)
    : (publishedTableLeafNodeUniquenessData as PublishedTableLeafNodeUniquenessQuery);
  const node = shouldUseDraft
    ? ((uniquenessData as DraftTableLeafNodeUniquenessQuery)
        ?.draftNode as DraftTableLeafNode)
    : ((uniquenessData as PublishedTableLeafNodeUniquenessQuery)
        ?.publishedNode as PublishedTableLeafNode);
  const uniqueColumnIds = shouldUseDraft
    ? (node as DraftTableLeafNode)?.uniqueColumnIds || []
    : (node as PublishedTableLeafNode)?.uniqueColumnIds || [];
  const [
    draftTableLeafNodeUpdateUniquenessMutation,
    { loading: draftTableLeafNodeUpdateUniquenessMutationLoading },
  ] = useDraftTableLeafNodeUpdateUniquenessMutation({
    onError: (error) => {
      enqueueSnackbar("Can't update columns uniqueness", {
        context: error?.message,
        persist: true,
        variant: "error",
      });
    },
  });
  const updateUniqueColumnIds = useCallback(
    async (uniqueColumnIds: string[][]) => {
      await draftTableLeafNodeUpdateUniquenessMutation({
        variables: {
          id,
          uniqueness: {
            value: {
              uniqueColumnIds,
            },
          },
        },
      });
    },
    [draftTableLeafNodeUpdateUniquenessMutation, id]
  );
  return {
    dataNodesColumnIdsByName,
    dataNodesColumnNamesById,
    draftTableLeafNodeUpdateUniquenessMutation,
    draftTableLeafNodeUpdateUniquenessMutationLoading,
    isLoading: uniquenessLoading || dataNodesLoading,
    uniqueColumnIds,
    updateUniqueColumnIds,
  };
};

export default useUniqueness;
