// TODO: Fix joy migration
import { useDeleteUserMutation } from "@decentriq/graphql/dist/hooks";
import { faExclamationTriangle } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Modal,
  ModalDialog,
  Typography,
} from "@mui/joy";
import { memo } from "react";
import { CommonSnackbarOrigin, useGeneralSnackbar } from "hooks";

interface UserDeleteDialogProps {
  open: boolean;
  userId: string;
  onCancel: () => void;
  closeUserDetailsDialog: () => void;
}

const UserDeleteDialog: React.FC<UserDeleteDialogProps> = ({
  open,
  userId,
  onCancel,
  closeUserDetailsDialog,
}) => {
  const { enqueueSnackbar } = useGeneralSnackbar({
    origin: CommonSnackbarOrigin.ADMIN,
  });
  const [deleteUser, { loading: isDeleteUserLoading }] = useDeleteUserMutation({
    onCompleted: () => {
      enqueueSnackbar(`User has been successfully deleted.`);
      onCancel();
      closeUserDetailsDialog();
    },
    onError: (error) => {
      onCancel();
      enqueueSnackbar(`User could not be deleted.`, {
        context: error?.message,
        persist: true,
        variant: "error",
      });
    },
    update: (cache) => {
      cache.evict({
        id: cache.identify({
          __typename: "User",
          id: userId,
        }),
      });
      cache.gc();
    },
    variables: {
      userId,
    },
  });
  return (
    <Modal onClose={!isDeleteUserLoading ? onCancel : undefined} open={open}>
      <ModalDialog role="alertdialog">
        <DialogTitle>
          <Box sx={{ mb: 1, textAlign: "center" }}>
            <FontAwesomeIcon
              fixedWidth={true}
              icon={faExclamationTriangle}
              size="4x"
            />
          </Box>
          <Typography
            align="center"
            level="title-md"
            sx={{ textWrap: "balance" }}
          >
            <strong>Are you sure you want to delete this user?</strong>
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Typography
            align="center"
            level="body-sm"
            sx={{ textWrap: "balance" }}
          >
            This action will remove this user and all data uploaded by this
            user. Also, this action will deactivate all data clean rooms created
            by this user, rendering them unusable for all participants therein.
          </Typography>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button disabled={isDeleteUserLoading} onClick={onCancel}>
            Cancel
          </Button>
          <Box sx={{ color: "common.white" }}>
            <Button
              loading={isDeleteUserLoading}
              loadingPosition="start"
              onClick={() => deleteUser()}
              startDecorator={
                <FontAwesomeIcon
                  fixedWidth={true}
                  icon={faExclamationTriangle}
                />
              }
              sx={{ color: "error.main" }}
              variant="solid"
            >
              Delete user
            </Button>
          </Box>
        </DialogActions>
      </ModalDialog>
    </Modal>
  );
};
UserDeleteDialog.displayName = "UserDeleteDialog";

export default memo(UserDeleteDialog);
