import { WorkerTypeShortName } from "@decentriq/graphql/dist/types";

const WorkerTypeShortNameToExpandActionLabel: Record<
  WorkerTypeShortName,
  string
> = {
  [WorkerTypeShortName.Sql]: "query",
  [WorkerTypeShortName.Sqlite]: "query",
  [WorkerTypeShortName.Python]: "script",
  [WorkerTypeShortName.R]: "script",
  [WorkerTypeShortName.Match]: "settings",
  [WorkerTypeShortName.Post]: "settings",
  [WorkerTypeShortName.Preview]: "settings",
  [WorkerTypeShortName.S3]: "settings",
  [WorkerTypeShortName.Synthetic]: "settings",
};

export const getConfigExpandActionLabel = (
  isHidded: boolean,
  computationTypename: WorkerTypeShortName | undefined
): string => {
  return `${isHidded ? "Show" : "Hide"} ${
    computationTypename
      ? WorkerTypeShortNameToExpandActionLabel[computationTypename]
      : "settings"
  }`;
};
