import {
  faExclamationTriangle,
  faTrashCan,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Modal,
  ModalDialog,
} from "@mui/joy";
import { memo, useCallback, useState } from "react";
import { KeychainItemKind } from "services/keychain/types";
import { useKeychain } from "contexts";
import { CommonSnackbarOrigin, useGeneralSnackbar } from "hooks";
import { getEffectiveErrorMessage } from "utils";

type DeleteKeychainItemDialogProps = {
  id: string;
  kind: KeychainItemKind;
  open: boolean;
  onCancel: () => void;
};

const DeleteKeychainItemDialog: React.FC<DeleteKeychainItemDialogProps> = ({
  id,
  kind,
  onCancel,
  open = false,
}) => {
  const { enqueueSnackbar } = useGeneralSnackbar({
    origin: CommonSnackbarOrigin.KEYCHAIN,
  });
  const keychain = useKeychain();
  const [isDeleting, setIsDeleting] = useState(false);
  const deleteKeychainItem = useCallback(async () => {
    setIsDeleting(true);
    try {
      if (kind === KeychainItemKind.Dataset) {
        await keychain.removeItems([
          { id, kind: KeychainItemKind.Dataset },
          { id, kind: KeychainItemKind.DatasetMetadata },
        ]);
      } else {
        await keychain.removeItem({ id, kind });
      }
    } catch (error) {
      enqueueSnackbar(`Item could not be deleted from the Keychain.`, {
        context: getEffectiveErrorMessage(error),
        persist: true,
        variant: "error",
      });
    } finally {
      setIsDeleting(false);
    }
  }, [kind, keychain, id, enqueueSnackbar]);
  return (
    <Modal onClose={isDeleting ? undefined : onCancel} open={open}>
      <ModalDialog color="danger" role="alertdialog">
        <DialogTitle>
          <FontAwesomeIcon
            icon={faExclamationTriangle}
            size="4x"
            swapOpacity={true}
          />
          <span>
            Are you sure you want to delete this key from your Keychain?
          </span>
        </DialogTitle>
        <DialogContent>
          You will not be able anymore to reprovision the associated dataset to
          data clean rooms.
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button disabled={isDeleting} onClick={onCancel}>
            Cancel
          </Button>
          <Button
            color="danger"
            loading={isDeleting}
            loadingPosition="start"
            onClick={deleteKeychainItem}
            startDecorator={<FontAwesomeIcon icon={faTrashCan} />}
            variant="solid"
          >
            Delete
          </Button>
        </DialogActions>
      </ModalDialog>
    </Modal>
  );
};
DeleteKeychainItemDialog.displayName = "DeleteKeychainItemDialog";

export default memo(DeleteKeychainItemDialog);
