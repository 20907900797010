import {
  useDraftPreviewComputeNodeQuery,
  usePublishedPreviewComputeNodeQuery,
} from "@decentriq/graphql/dist/hooks";
import {
  type DraftNode,
  type DraftNodeConnection,
  type DraftPreviewNode,
  type PublishedPreviewNode,
} from "@decentriq/graphql/dist/types";
import {
  useComputeNodesVars,
  useDataRoom,
  usePublishedDataRoom,
  useRequest,
} from "contexts";
import {
  mapDraftDataRoomErrorToSnackbar,
  mapErrorToGeneralSnackbar,
  useDataRoomSnackbar,
} from "hooks";
import { type PublishedNode } from "types";

const usePreviewComputeNode = (
  computeNodeId: string
): {
  node: PublishedPreviewNode | DraftPreviewNode | undefined;
  dependency: PublishedNode | DraftNode | undefined;
  isLoading: boolean;
} => {
  const { enqueueSnackbar } = useDataRoomSnackbar();
  const { isPublished } = useDataRoom();
  const { dataRoomId: dcrHash, driverAttestationHash } = usePublishedDataRoom();
  const { commitId } = useRequest();
  const { executionContext } = useComputeNodesVars();
  const shouldUseDraft =
    !isPublished ||
    executionContext === "development" ||
    executionContext === "requests";
  // draft & development
  const { data: draftData, loading: draftLoading } =
    useDraftPreviewComputeNodeQuery({
      onError: (error) => {
        enqueueSnackbar(
          ...mapDraftDataRoomErrorToSnackbar(
            error,
            "The computation could not be retrieved. Please try again by refreshing the page."
          )
        );
      },
      skip: !shouldUseDraft,
      variables: { computeNodeId },
    });
  // published
  const { data: publishedData, loading: publishedLoading } =
    usePublishedPreviewComputeNodeQuery({
      onError: (error) => {
        enqueueSnackbar(
          ...mapErrorToGeneralSnackbar(
            error,
            "The computation could not be retrieved. Please try again by refreshing the page."
          )
        );
      },
      // temp fix to resolve issue with dependency being populated into 2 cache data nodes
      returnPartialData: true,
      skip: shouldUseDraft,
      variables: { commitId, computeNodeId, dcrHash, driverAttestationHash },
    });
  const publishedNode = publishedData?.publishedNode as
    | PublishedPreviewNode
    | undefined;
  const draftNode = draftData?.draftNode as DraftPreviewNode | undefined;
  return {
    dependency: shouldUseDraft
      ? (draftNode?.dependency as DraftNodeConnection)?.draftNode ||
        draftNode?.dependency
      : publishedNode?.dependency,
    isLoading: publishedLoading || draftLoading,
    node: shouldUseDraft ? draftNode : publishedNode,
  };
};

export default usePreviewComputeNode;
