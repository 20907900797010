import { data_science } from "@decentriq/core";
import {
  type MutationRetrieveDraftComputationFormatArgs,
  type RetrieveComputationFormatInDraftDataRoomPayload,
} from "@decentriq/graphql/dist/types";
import { type ApiCoreContextValue } from "contexts";
import { buildAndPublishDraftDataRoom } from "../../../helpers";
import { type LocalResolverContext } from "../../../models";

export const makeRetrieveDraftComputationFormatResolver =
  (
    client: ApiCoreContextValue["client"],
    sessionManager: ApiCoreContextValue["sessionManager"],
    store: ApiCoreContextValue["store"]
  ) =>
  async (
    _obj: null,
    args: MutationRetrieveDraftComputationFormatArgs,
    context: LocalResolverContext,
    _info: any
  ): Promise<RetrieveComputationFormatInDraftDataRoomPayload> => {
    // Get the SDK client and session and publish the draft data room
    const sdkSession = await sessionManager.get();
    const id = await buildAndPublishDraftDataRoom(
      args.input.id,
      context.client,
      client,
      sdkSession,
      true
    );
    const dataScienceDataRoom =
      await sdkSession.retrieveDataScienceDataRoom(id);
    const wrapper = data_science.createDataScienceDataRoomWrapper(
      id,
      dataScienceDataRoom!,
      sdkSession
    );
    const result = await wrapper.retrieveComputationFormat(args.input.nodeId);
    const computationFormatReference = store.push(result);
    return { format: computationFormatReference };
  };
