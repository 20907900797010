import { Box } from "@mui/joy";
import { RulesComposer, TablesSelector } from "./components";
import { useMatchNodeDataSources } from "./hooks";

interface MatchComputeNodeEditorProps {
  computeNodeId: string;
  readOnly?: boolean;
}

const MatchComputeNodeEditor: React.FC<MatchComputeNodeEditorProps> = ({
  computeNodeId: id,
  readOnly,
}) => {
  const { selectedLeftDataSourceNodeId, selectedRightDataSourceNodeId } =
    useMatchNodeDataSources(id);
  return (
    <Box sx={{ height: "100%", overflow: "auto" }}>
      <TablesSelector id={id} readOnly={readOnly} />
      {selectedLeftDataSourceNodeId &&
      selectedRightDataSourceNodeId &&
      selectedLeftDataSourceNodeId !== selectedRightDataSourceNodeId ? (
        <RulesComposer id={id} readOnly={readOnly} />
      ) : null}
    </Box>
  );
};

MatchComputeNodeEditor.displayName = "MatchComputeNodeEditor";

export default MatchComputeNodeEditor;
