import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { memo, useState } from "react";

const QueryClientWrapper = memo<React.PropsWithChildren>(({ children }) => {
  const [queryClient] = useState(
    new QueryClient({
      defaultOptions: {
        queries: {
          // By default all queries are marked as stale when the user re-focuses. This can be quite expensive,
          // so it's turned off globally, can be turned back on on a per-request basis.
          refetchOnMount: false,
          refetchOnReconnect: false,
          refetchOnWindowFocus: false,
          staleTime: Infinity,
        },
      },
    })
  );
  return (
    <QueryClientProvider client={queryClient}>
      {children}
      <ReactQueryDevtools buttonPosition="bottom-right" initialIsOpen={false} />
    </QueryClientProvider>
  );
});

QueryClientWrapper.displayName = "QueryClientWrapper";

export default QueryClientWrapper;
