import { useSubmittedRequestComputeNodeRunMutation } from "@decentriq/graphql/dist/hooks";
import { useCallback } from "react";
import { usePublishedDataRoom } from "contexts";
import { mapDataRoomErrorToSnackbar, useDataRoomSnackbar } from "hooks";
import { type ComputeNodeTypeNames } from "models";
import { useCheckScriptingNodeInputs } from "..";

interface SubmittedRequestComputeNodeRunHookArgs {
  computeNodeId: string;
  commitId: string;
  computationType: ComputeNodeTypeNames | undefined;
}

const useSubmittedRequestComputeNodeRun = ({
  computationType,
  commitId,
  computeNodeId,
}: SubmittedRequestComputeNodeRunHookArgs) => {
  const { enqueueSnackbar } = useDataRoomSnackbar();
  const checkScriptingNodeInputs = useCheckScriptingNodeInputs(
    computeNodeId,
    computationType
  );
  const { dcrHash, driverAttestationHash, dataRoomId } = usePublishedDataRoom();
  const [devComputeNodeRunMutation, { loading }] =
    useSubmittedRequestComputeNodeRunMutation({
      onError: (error) => {
        enqueueSnackbar(
          ...mapDataRoomErrorToSnackbar(
            error,
            `The computation could not be run.`
          )
        );
      },
    });
  const runSubmittedRequestComputeNode = useCallback(() => {
    checkScriptingNodeInputs();
    return devComputeNodeRunMutation({
      variables: {
        input: {
          autoFetching: true,
          commitId,
          computeNodeId,
          dataRoomId,
          dcrHash,
          driverAttestationHash,
        },
      },
    });
  }, [
    checkScriptingNodeInputs,
    devComputeNodeRunMutation,
    commitId,
    computeNodeId,
    dataRoomId,
    dcrHash,
    driverAttestationHash,
  ]);
  return {
    loading,
    runSubmittedRequestComputeNode,
  };
};

export default useSubmittedRequestComputeNodeRun;
