import { createContext, useContext } from "react";

interface SidebarContextValue {
  isSidebarExpanded: boolean;
  expandSidebar: () => void;
  collapseSidebar: () => void;
  toggleSidebar: () => void;
}

export const SidebarContext = createContext<SidebarContextValue>({
  collapseSidebar: () => {},
  expandSidebar: () => {},
  isSidebarExpanded: false,
  toggleSidebar: () => {},
});

export const SidebarProvider = SidebarContext.Provider;

export const useSidebar = () => useContext(SidebarContext);
