import {
  createContext,
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useDataRoom, usePublishedDataRoom } from "contexts";
import { type DataRoomData } from "models";
import DataNodeWithTestDataChangedDialog, {
  type DataNodeChangeOutcomeType,
} from "../DataNodeWithTestDataChangedDialog/DataNodeWithTestDataChangedDialog";

interface DataNodeOutcomeHandlerPayload {
  dataNode: DataRoomData;
  columnId?: string;
}

interface DataNodeChangeOutcomeHandlerContextValue {
  handleChangeOutcome: (
    actionType: DataNodeChangeOutcomeType,
    payload: DataNodeOutcomeHandlerPayload
  ) => void;
}

const DataNodeChangeOutcomeHandlerContext =
  createContext<DataNodeChangeOutcomeHandlerContextValue>({
    handleChangeOutcome: () => {},
  });

export const useDataNodeChangeOutcomeHandler = () =>
  useContext(DataNodeChangeOutcomeHandlerContext);

type DataNodeChangeOutcomeProps = React.PropsWithChildren;

const DataNodeChangeOutcomeHandler: React.FC<DataNodeChangeOutcomeProps> = memo(
  ({ children }) => {
    const { allowTestMode, isPublished } = useDataRoom();
    const publishedDataRoomContext = usePublishedDataRoom();
    const testing = isPublished
      ? publishedDataRoomContext.testing
      : allowTestMode;
    const [outcomeDialogOpenType, setOutcomeDialogOpenType] = useState<
      DataNodeChangeOutcomeType | undefined
    >(undefined);
    const [handlerPayload, setHandlerPayload] = useState<
      DataNodeOutcomeHandlerPayload | undefined
    >();
    const isOutcomeDialogOpen = !!outcomeDialogOpenType;
    useEffect(() => {
      if (!isOutcomeDialogOpen) {
        setHandlerPayload(undefined);
      }
    }, [isOutcomeDialogOpen, setHandlerPayload]);
    const handleChangeOutcome = useCallback(
      (
        actionType: DataNodeChangeOutcomeType,
        payload: DataNodeOutcomeHandlerPayload
      ) => {
        if (
          (actionType === "deleted" &&
            testing &&
            payload.dataNode.testDataset) ||
          ((actionType === "columnAdded" || actionType === "changed") &&
            allowTestMode &&
            payload.dataNode.testDataset)
        ) {
          setHandlerPayload(payload);
          setOutcomeDialogOpenType(actionType);
          return;
        }
      },
      [setHandlerPayload, setOutcomeDialogOpenType, allowTestMode, testing]
    );
    const contextValue = useMemo<DataNodeChangeOutcomeHandlerContextValue>(
      () => ({
        handleChangeOutcome,
      }),
      [handleChangeOutcome]
    );
    return (
      <DataNodeChangeOutcomeHandlerContext.Provider value={contextValue}>
        {children}
        {testing && (
          <DataNodeWithTestDataChangedDialog
            actionType={outcomeDialogOpenType!}
            columnToDeleteId={handlerPayload?.columnId}
            dataNodeId={handlerPayload?.dataNode.id || ""}
            dataNodeName={handlerPayload?.dataNode.name || ""}
            manifestHash={
              handlerPayload?.dataNode.testDataset?.datasetHash || ""
            }
            onCancel={() => setOutcomeDialogOpenType(undefined)}
            open={isOutcomeDialogOpen}
          />
        )}
      </DataNodeChangeOutcomeHandlerContext.Provider>
    );
  }
);

DataNodeChangeOutcomeHandler.displayName = "DataNodeChangeOutcomeHandler";

export default DataNodeChangeOutcomeHandler;
