import { DqSortableAccordionDetails } from "@decentriq/components";
import {
  Accordion,
  accordionDetailsClasses,
  AccordionGroup,
  AccordionSummary,
  Box,
  Chip,
  Stack,
  Typography,
} from "@mui/joy";
import { memo } from "react";
import { useRequest, useRequestsVars } from "contexts";
import { ComputeNode } from "features/computeNode";
import RequestActions from "../RequestActions/RequestActions";
import RequestAnalysts from "../RequestAnalysts/RequestAnalysts";
import RequestApprovers from "../RequestApprovers/RequestApprovers";
import SubmittedRequestRebase from "../SubmittedRequestRebase/SubmittedRequestRebase";
import SubmittedRequestSignees from "../SubmittedRequestSignees/SubmittedRequestSignees";
import SubmittedRequestSubmittionStatus from "../SubmittedRequestSubmittionStatus/SubmittedRequestSubmittionStatus";
import useRequestName from "./useRequestName";

interface RequestItemProps {
  requestId: string;
}

const RequestItem: React.FC<RequestItemProps> = memo(({ requestId }) => {
  const { checkRequestSelection, context } = useRequestsVars();
  const isRequestSelected = checkRequestSelection(requestId);
  const { computeNodeId } = useRequest();
  const { name } = useRequestName(computeNodeId);
  return (
    <Accordion defaultExpanded={isRequestSelected} variant="outlined">
      <AccordionSummary slotProps={{ indicator: { sx: { order: 1 } } }}>
        <Typography
          endDecorator={
            <Chip>{context === "draft" ? "Draft" : "Pending"}</Chip>
          }
          level="title-md"
          sx={{ flex: 1, order: 2 }}
        >
          {name}
        </Typography>
        <Stack direction="row" sx={{ order: 3 }}>
          {context === "submitted" && (
            <SubmittedRequestSubmittionStatus requestId={requestId} />
          )}
          <RequestActions requestId={requestId} />
        </Stack>
        {context === "submitted" ? (
          <Box sx={{ order: 5, width: "100%" }}>
            <SubmittedRequestRebase requestId={requestId} />
          </Box>
        ) : null}
      </AccordionSummary>
      <DqSortableAccordionDetails>
        <Stack>
          <RequestAnalysts requestId={requestId} />
          {context === "submitted" ? (
            <SubmittedRequestSignees submittedRequestId={requestId} />
          ) : null}
          {context === "draft" ? (
            <RequestApprovers requestId={requestId} />
          ) : null}
          <AccordionGroup
            className="separated"
            sx={{
              [`& .${accordionDetailsClasses.content}`]: {
                boxShadow: (theme) =>
                  `inset 0 1px ${theme.vars.palette.divider}`,
                [`&.${accordionDetailsClasses.expanded}`]: {
                  paddingBlock: "0.75rem",
                },
              },
            }}
          >
            <ComputeNode computeNodeId={computeNodeId} />
          </AccordionGroup>
        </Stack>
      </DqSortableAccordionDetails>
    </Accordion>
  );
});
RequestItem.displayName = "RequestItem";

export default RequestItem;
