import { useSelections } from "ahooks";
import { RequestsVarsProvider } from "contexts";
import { ComputeNodeConstructorMode } from "features";
import { ComputeNodesVarsWrapper } from "wrappers";

interface SubmittedRequestsVarsWrapperProps {
  defaultExpandedRequests?: string[];
  defaultExpandedComputations?: string[];
  children?: React.ReactNode;
  submittedRequestsIds: string[];
}

const SubmittedRequestsVarsWrapper: React.FC<
  SubmittedRequestsVarsWrapperProps
> = ({
  children,
  defaultExpandedComputations,
  defaultExpandedRequests,
  submittedRequestsIds,
}) => {
  const {
    isSelected: checkRequestSelection,
    toggle: toggleRequest,
    select: selectRequest,
    unSelectAll: unSelectAllRequests,
  } = useSelections(submittedRequestsIds, defaultExpandedRequests || []);
  return (
    <RequestsVarsProvider
      value={{
        checkRequestSelection,
        context: "submitted",
        selectRequest,
        toggleRequest,
        unSelectAllRequests,
      }}
    >
      <ComputeNodesVarsWrapper
        defaultExpandedComputeNodes={defaultExpandedComputations}
        executionContext="submitted_requests"
        hasComputeNodeActions={true}
        hasComputeNodeParticipants={false}
        mode={ComputeNodeConstructorMode.ACTION}
      >
        {children}
      </ComputeNodesVarsWrapper>
    </RequestsVarsProvider>
  );
};

export default SubmittedRequestsVarsWrapper;
