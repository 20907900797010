import { type DqCreateMeasurementDcrFormProviderSchemaType } from "@decentriq/components";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useMeasurement } from "features/measurementDcr/components/CreateMeasurementDcr/hooks";
import {
  CommonSnackbarOrigin,
  mapErrorToGeneralSnackbar,
  useGeneralSnackbar,
} from "hooks";
import { dataRoomPathBuilder, DataRoomTypeNames } from "models";

type UseSubmitCreateMeasurementDcrReturn = {
  submit: (
    payload: DqCreateMeasurementDcrFormProviderSchemaType
  ) => Promise<void>;
};

const useSubmitCreateMeasurementDcr =
  (): UseSubmitCreateMeasurementDcrReturn => {
    const navigate = useNavigate();
    const { enqueueSnackbar } = useGeneralSnackbar({
      origin: CommonSnackbarOrigin.DASHBOARD,
    });
    const { publish } = useMeasurement();
    const submit = useCallback(
      async (payload: DqCreateMeasurementDcrFormProviderSchemaType) => {
        try {
          const id = await publish({ payload });
          const dataRoomLink = dataRoomPathBuilder(
            id,
            DataRoomTypeNames.PublishedDataRoom
          );
          navigate(dataRoomLink);
        } catch (error) {
          enqueueSnackbar(
            ...mapErrorToGeneralSnackbar(
              error,
              "Error creating measurement clean room"
            )
          );
        }
      },
      [enqueueSnackbar, navigate, publish]
    );
    return { submit };
  };

export default useSubmitCreateMeasurementDcr;
