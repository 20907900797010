import { Box, Stack } from "@mui/joy";
import { memo } from "react";
import { NavLink } from "react-router-dom";
import DecentriqPlatformLogo from "assets/logos/decentriq-platform-logo.svg";
import ContactUsButton from "../ContactUsButton/ContactUsButton";
import UserMenu from "../UserMenu/UserMenu";

export const HEADER_HEIGHT = 50;

const Header = memo(() => {
  return (
    <Box
      alignItems="center"
      display="flex"
      height={HEADER_HEIGHT}
      justifyContent="space-between"
      sx={({ spacing, shadow }) => ({
        boxShadow: shadow.md,
        padding: spacing(1.25, 2),
        position: "relative",
        zIndex: 10,
      })}
      width="100%"
    >
      <Box alignItems="center" component={NavLink} display="flex" to="/">
        <DecentriqPlatformLogo />
      </Box>
      <Stack direction="row" spacing={1}>
        <ContactUsButton />
        <UserMenu />
      </Stack>
    </Box>
  );
});

Header.displayName = "Header";

export default Header;
