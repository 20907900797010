import { useAuditLogsLazyQuery } from "@decentriq/graphql/dist/hooks";
import { useMount } from "ahooks";
import { memo } from "react";
import { DataRoomLogsPreview } from "components";
import { mapErrorToGeneralSnackbar, useDataRoomSnackbar } from "hooks";

interface DataRoomAuditLogsProps {
  dcrHash?: string;
  driverAttestationHash?: string;
}

const DataRoomAuditLogs: React.FC<DataRoomAuditLogsProps> = memo(
  ({ dcrHash, driverAttestationHash }) => {
    const { enqueueSnackbar } = useDataRoomSnackbar();
    const [fetchLogs, { loading, data }] = useAuditLogsLazyQuery({
      fetchPolicy: "cache-and-network",
      onError: (error) => {
        enqueueSnackbar(
          ...mapErrorToGeneralSnackbar(
            error,
            "Audit log could not be retrieved."
          )
        );
      },
      variables: {
        dcrHash,
        driverAttestationHash,
      },
    });
    useMount(() => {
      fetchLogs();
    });
    return (
      <DataRoomLogsPreview
        asTable={true}
        isLoading={loading}
        logs={data?.auditLog?.log || ""}
        onFetchLogs={fetchLogs}
      />
    );
  }
);
DataRoomAuditLogs.displayName = "DataRoomAuditLogs";

export default DataRoomAuditLogs;
