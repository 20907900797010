import { faInfoCircle as fasInfoCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert, Box, Link, Typography } from "@mui/joy";
import { NavLink } from "react-router-dom";
import { useDocsLink } from "hooks";
import AccessTokensConstructor from "./AccessTokensConstructor";

const AccessTokens: React.FC = () => {
  const docsBaseLink = useDocsLink();
  return (
    <Box
      sx={({ spacing }) => ({ margin: spacing(2, 2.5), overflow: "hidden" })}
    >
      <Typography level="title-md" marginBottom={1}>
        API tokens
      </Typography>
      <Alert
        color="primary"
        slotProps={{
          root: { sx: { textAlign: "left" } },
        }}
        startDecorator={<FontAwesomeIcon icon={fasInfoCircle} />}
      >
        <Typography level="body-sm">
          The access tokens created on this page can be used in our SDKs to
          authenticate with the Decentriq Platform. For more details, please
          refer{" "}
          <Link
            href={`${docsBaseLink}/sdk/getting-started`}
            rel="noreferrer"
            target="_blank"
          >
            to the Python SDK documentation.
          </Link>{" "}
          If you are looking for previously created tokens, please refer to the{" "}
          <NavLink to="/legacy-tokens">
            <Link>old API tokens page.</Link>
          </NavLink>
        </Typography>
      </Alert>
      <AccessTokensConstructor />
    </Box>
  );
};

export default AccessTokens;
