import { Typography } from "@mui/joy";
import { memo } from "react";

const AudienceGeneratorStepSectionDescription = memo<React.PropsWithChildren>(
  ({ children }) => <Typography level="body-sm">{children}</Typography>
);

AudienceGeneratorStepSectionDescription.displayName =
  "AudienceGeneratorStepSectionDescription";

export default AudienceGeneratorStepSectionDescription;
