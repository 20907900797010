import { logWarning } from "utils";
import {
  ApiCoreWrapper,
  ApolloWrapper,
  Auth0Wrapper,
  ConfigurationWrapper,
  EffectiveSearchParamsWrapper,
  EnclaveEmailMfaWrapper,
  EnclaveTokenStorageWrapper,
  KeychainMigrationWrapper,
  KeychainWrapper,
  PagesWrapper,
  QueryClientWrapper,
  RouterWrapper,
  SignupWrapper,
  SnackbarWrapper,
  StylesWrapper,
  useKeychainSharedInstance,
  WelcomeWrapper,
} from "wrappers";

function queryName(query: any): string {
  let name = "";
  for (const definition of query.definitions) {
    name = name + definition.name.value + ":";
  }
  if (name === "") {
    return "UNKNOWN: " + query;
  } else {
    return name;
  }
}

// Returns null if there is no undefined
function findUndefinedOrEmptyPath(
  value: any | undefined,
  path: string
): string | null {
  if (value === undefined) {
    return path;
  }
  switch (typeof value) {
    case "boolean": {
      return null;
    }
    case "number": {
      return null;
    }
    case "string": {
      if (value === "") {
        return path;
      }
      return null;
    }
    case "function": {
      return null;
    }
    case "object": {
      for (const field in value) {
        const pathNested = findUndefinedOrEmptyPath(
          value[field],
          path + "." + field
        );
        if (pathNested != null) {
          return pathNested;
        }
      }
      return null;
    }
  }

  throw new Error(`Unexpected type ${typeof value}`);
}

const exceptions: any = {
  "options.variables.commitId": {},
  "options.variables.filter": {},
};

window.apolloUseQueryHook = function (query, options) {
  if (options === undefined) {
    return;
  }
  const path = findUndefinedOrEmptyPath(options, "options");
  if (path !== null && exceptions[path] === undefined) {
    logWarning(queryName(query) + " undefined/empty path " + path);
  }
};

const App = () => {
  const [getKeychainSharedInstance, setKeychainSharedInstance] =
    useKeychainSharedInstance();
  return (
    <RouterWrapper>
      <ConfigurationWrapper>
        <StylesWrapper>
          <QueryClientWrapper>
            <Auth0Wrapper>
              <SignupWrapper>
                <EnclaveTokenStorageWrapper>
                  <ApiCoreWrapper>
                    <EnclaveEmailMfaWrapper>
                      <ApolloWrapper
                        getKeychainSharedInstance={getKeychainSharedInstance}
                      >
                        <SnackbarWrapper>
                          <KeychainWrapper
                            onKeychainInstanceUpdated={
                              setKeychainSharedInstance
                            }
                          >
                            <KeychainMigrationWrapper>
                              <WelcomeWrapper>
                                <EffectiveSearchParamsWrapper>
                                  <PagesWrapper />
                                </EffectiveSearchParamsWrapper>
                              </WelcomeWrapper>
                            </KeychainMigrationWrapper>
                          </KeychainWrapper>
                        </SnackbarWrapper>
                      </ApolloWrapper>
                    </EnclaveEmailMfaWrapper>
                  </ApiCoreWrapper>
                </EnclaveTokenStorageWrapper>
              </SignupWrapper>
            </Auth0Wrapper>
          </QueryClientWrapper>
        </StylesWrapper>
      </ConfigurationWrapper>
    </RouterWrapper>
  );
};

export default App;
