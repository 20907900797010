import { faTriangleExclamation } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Container, Stack, styled, Typography } from "@mui/joy";
import { useNavigate } from "react-router-dom";

const PageWrapper = styled(Container)({
  alignItems: "center",
  display: "flex",
  flexDirection: "column",
  height: "100%",
  justifyContent: "center",
});

const FourOFourPage = () => {
  const navigate = useNavigate();
  return (
    <PageWrapper>
      <Stack alignItems="center">
        <FontAwesomeIcon
          fixedWidth={true}
          icon={faTriangleExclamation}
          size="5x"
        />
        <Typography fontWeight={600} level="title-md">
          404 Not found
        </Typography>
        <Typography>We can't find the page you were looking for</Typography>
        <Button color="primary" onClick={() => navigate("/")} variant="solid">
          BACK TO HOME SCREEN
        </Button>
      </Stack>
    </PageWrapper>
  );
};

export default FourOFourPage;
