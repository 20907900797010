// TODO: Fix joy migration
import { faFileExport, faFileImport } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Typography } from "@mui/joy";
import { memo } from "react";
import { EXTERNAL_CONNECTIONS_TYPES } from "../ExternalConnections";
import { useExternalConnectionData } from "../ExternalConnections/hooks";

interface ExternalConnectionNameProps {
  connectionId: string;
}

const ExternalConnectionName: React.FC<ExternalConnectionNameProps> = ({
  connectionId,
}) => {
  const { name, type } = useExternalConnectionData(connectionId);
  return (
    <Typography fontWeight="fontWeightSemiBold" noWrap={true} variant={"h6"}>
      <FontAwesomeIcon
        icon={
          type === EXTERNAL_CONNECTIONS_TYPES.EXPORT
            ? faFileExport
            : faFileImport
        }
        style={{ marginRight: "8px" }}
      />
      {name}
    </Typography>
  );
};

ExternalConnectionName.displayName = "ExternalConnectionName";

export default memo(ExternalConnectionName);
