import { testIds } from "@decentriq/utils";
import { faInfoCircle } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormControl, Switch, Tooltip } from "@mui/joy";
import { memo } from "react";
import { useDataNodeActions } from "features/dataNodes/containers/DataNodes/DataNodesActionsWrapper";
import { type DataType } from "models";

interface DatasetConstraintsProps {
  id: string;
  readOnly?: boolean;
  disabled?: boolean;
  value?: boolean;
  dataType: DataType;
}

const DatasetConstraints: React.FC<DatasetConstraintsProps> = memo(
  ({ id, disabled, readOnly, value, dataType }) => {
    const { handleConstraintUpdate } = useDataNodeActions();
    if (readOnly && !value) return null;
    return (
      <FormControl
        sx={{
          alignItems: "center",
          display: "flex",
          flexDirection: "row",
          gap: 1,
        }}
      >
        <Switch
          checked={value}
          disabled={readOnly || disabled}
          endDecorator="Dataset is required"
          onChange={async (event) => {
            await handleConstraintUpdate({
              dataType,
              id,
              isRequired: event.target.checked,
            });
          }}
          slotProps={{
            input: {
              "data-testid":
                testIds.computeNode.computeNodeEditor.datasetRequired,
            },
          }}
        />
        <Tooltip
          title={`Computations which depend on this ${
            dataType === "table" ? "table" : "file"
          } will fail unless a dataset has
        been provisioned to it.`}
        >
          <FontAwesomeIcon icon={faInfoCircle} />
        </Tooltip>
      </FormControl>
    );
  }
);

DatasetConstraints.displayName = "DatasetConstraints";

export default DatasetConstraints;
