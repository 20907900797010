import { faClone } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Tooltip, Typography } from "@mui/joy";
import { TreeItem, type TreeItemProps } from "@mui/x-tree-view";
import { useSnackbar } from "notistack";
import { useCopyToClipboard } from "hooks";
import {
  type ScriptingInputNodeSnapshot,
  scriptingNodeGroupLabelsMap,
} from "models";

interface FileExplorerItemProps {
  copyValue: string | undefined;
  itemId: string;
  input: ScriptingInputNodeSnapshot;
  disabled?: boolean;
  slots?: TreeItemProps["slots"];
}

const FileExplorerItem: React.FC<FileExplorerItemProps> = ({
  itemId,
  copyValue,
  input,
  disabled,
  slots,
}): JSX.Element => {
  const { enqueueSnackbar } = useSnackbar();
  const [, copyToClipboard] = useCopyToClipboard();
  return (
    <TreeItem
      disabled={disabled}
      itemId={itemId}
      key={itemId}
      label={
        <Box
          style={{
            alignItems: "center",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              gap: "0.25rem",
              lineHeight: "1.5rem",
              overflow: "hidden",
            }}
          >
            <Typography
              level="body-sm"
              style={{ overflow: "hidden", whiteSpace: "nowrap" }}
            >
              {input?.name || ""}
            </Typography>
            <Typography
              component="span"
              level="body-sm"
              style={{
                backgroundColor: "rgba(0, 0, 0, 0.08)",
                borderRadius: "3px",
                color: "black",
                lineHeight: "0.75rem",
                padding: "0.125rem",
              }}
            >
              {scriptingNodeGroupLabelsMap[input?.typename]?.toUpperCase()}
            </Typography>
          </Box>
          <Tooltip title="Click to copy path for this file">
            <div
              onClick={(e) => {
                e.stopPropagation();
                copyToClipboard(copyValue || "");
                enqueueSnackbar(`'${input?.path}' path copied to clipboard`);
              }}
              style={{ cursor: "pointer" }}
            >
              <FontAwesomeIcon
                icon={faClone}
                style={{
                  marginLeft: "auto",
                }}
              />
            </div>
          </Tooltip>
        </Box>
      }
      slots={slots}
    />
  );
};

FileExplorerItem.displayName = "FileExplorerItem";

export default FileExplorerItem;
