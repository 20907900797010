// TODO: Fix joy migration
import { faExclamationTriangle } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Modal,
  ModalDialog,
  Typography,
} from "@mui/joy";
import { memo } from "react";

interface DeleteAccountDialogProps {
  open: boolean;
  loading: boolean;
  onCancel: () => void;
  onConfirm: () => void;
}

const DeleteAccountDialog: React.FC<DeleteAccountDialogProps> = ({
  open,
  loading,
  onCancel,
  onConfirm,
}) => {
  return (
    <Modal onClose={!loading ? onCancel : () => {}} open={open}>
      <ModalDialog role="alertdialog">
        <DialogTitle>
          <Box sx={{ mb: 1, textAlign: "center" }}>
            <FontAwesomeIcon
              fixedWidth={true}
              icon={faExclamationTriangle}
              size="4x"
            />
          </Box>
          <Typography
            align="center"
            level="title-md"
            sx={{ textWrap: "balance" }}
          >
            <strong>Are you sure you want to delete your account?</strong>
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Typography
            align="center"
            level="body-sm"
            sx={{ textWrap: "balance" }}
          >
            This is an irreversible action. All your created data clean rooms
            will be deactivated and all traces of your data will be deleted from
            the platform.
          </Typography>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button disabled={loading} onClick={onCancel}>
            Cancel
          </Button>
          <Button
            color="danger"
            loading={loading}
            loadingPosition="start"
            onClick={onConfirm}
            startDecorator={<FontAwesomeIcon icon={faExclamationTriangle} />}
            sx={{ color: "error.main" }}
            variant="solid"
          >
            Delete account
          </Button>
        </DialogActions>
      </ModalDialog>
    </Modal>
  );
};

DeleteAccountDialog.displayName = "DeleteAccountDialog";

export default memo(DeleteAccountDialog);
