import { faDownload } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton, Tooltip } from "@mui/joy";
import { useTheme } from "@mui/material";
import format from "date-fns/format";
import { saveAs } from "file-saver";
import { memo, useCallback } from "react";
import { useDataLabContext } from "features/dataLabs";

const DataLabDownloadValidationReportButton: React.FC = () => {
  const { palette } = useTheme();
  const {
    dataLab: { data: dataLab },
    statisticsLoading,
  } = useDataLabContext();
  const { statistics: dataLabStatistics, name: dataLabName } = dataLab || {};

  const downloadValidationReport = useCallback(() => {
    const statisticsBlob = new Blob(
      [JSON.stringify(dataLabStatistics, null, 2)],
      { type: "application/json" }
    );
    saveAs(
      statisticsBlob,
      `${dataLabName}_data_quality_statistics_${format(
        new Date(),
        "dd_MM_yyyy HH_mm"
      )}`
    );
  }, [dataLabStatistics, dataLabName]);

  const isDownloadButtonDisabled: boolean =
    !dataLabStatistics || statisticsLoading;

  return (
    <Tooltip placement="top-start" title="Download statistics">
      <div style={{ display: "flex" }}>
        <IconButton
          disabled={isDownloadButtonDisabled}
          onClick={downloadValidationReport}
          sx={{ mr: 1, padding: 0.5 }}
        >
          <FontAwesomeIcon
            color={
              isDownloadButtonDisabled
                ? palette?.action?.disabled
                : palette?.primary?.main
            }
            fixedWidth={true}
            icon={faDownload}
          />
        </IconButton>
      </div>
    </Tooltip>
  );
};

DataLabDownloadValidationReportButton.displayName =
  "DataLabDownloadValidationReportButton";

export default memo(DataLabDownloadValidationReportButton);
