import { faInfoCircle } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Checkbox,
  FormControl,
  FormHelperText,
  FormLabel,
  Option,
  Select,
  Tooltip,
} from "@mui/joy";
import { memo } from "react";
import {
  Controller,
  type FieldValues,
  type Path,
  useFormContext,
} from "react-hook-form";
import { type CollaborationTypes } from "models";

interface ConfigurationFormFeaturesFieldProps<F extends FieldValues> {
  featuresList: CollaborationTypes[];
  fieldName: Path<F>;
  tooltip?: string;
  testId?: string;
  optionTestId?: string;
}

type ConfigurationFormFeaturesFieldComponent = <F extends FieldValues>(
  props: ConfigurationFormFeaturesFieldProps<F>
) => React.ReactNode;

const ConfigurationFormFeaturesField = memo(
  <F extends FieldValues>({
    featuresList,
    fieldName,
    tooltip,
    testId,
    optionTestId,
  }: ConfigurationFormFeaturesFieldProps<F>) => {
    const { control } = useFormContext<F>();
    return (
      <Controller
        control={control}
        name={fieldName}
        render={({ field, fieldState }) => {
          const { error } = fieldState;
          return (
            <FormControl
              disabled={field.disabled}
              error={Boolean(error)}
              required={true}
            >
              <FormLabel>
                Collaboration types
                {tooltip ? (
                  <Box ml={0.5}>
                    <Tooltip title={tooltip}>
                      <FontAwesomeIcon icon={faInfoCircle} />
                    </Tooltip>
                  </Box>
                ) : null}
              </FormLabel>
              <Select
                multiple={true}
                placeholder="No collaboration types selected"
                {...field}
                data-testid={testId}
                onChange={(event, value) => field.onChange(value)}
              >
                {featuresList.map((feature) => (
                  <Option
                    data-testid={
                      optionTestId
                        ? `${optionTestId}${feature.toLowerCase()}`
                        : undefined
                    }
                    key={feature}
                    value={feature}
                  >
                    <FormControl>
                      <Checkbox
                        checked={field.value?.includes(
                          feature as CollaborationTypes
                        )}
                      />
                    </FormControl>
                    {feature}
                  </Option>
                ))}
              </Select>
              <FormHelperText>{error?.message}</FormHelperText>
            </FormControl>
          );
        }}
      />
    );
  }
);
ConfigurationFormFeaturesField.displayName = "ConfigurationFormFeaturesField";

export default ConfigurationFormFeaturesField as ConfigurationFormFeaturesFieldComponent;
