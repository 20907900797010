import { Stack, Typography } from "@mui/joy";
import { Fragment, memo } from "react";
import { OrganizationsAddButton, OrganizationsList } from "components";

const OrganizationsPage = () => {
  return (
    <Fragment>
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        sx={{ minHeight: 68 - 16, pt: 2, px: 2 }}
      >
        <Typography level="title-md">Manage Organizations</Typography>
        <OrganizationsAddButton />
      </Stack>
      <OrganizationsList />
    </Fragment>
  );
};

export default memo(OrganizationsPage);
