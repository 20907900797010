import { ApolloError } from "@apollo/client";
import { exceptions } from "@decentriq/utils";
import { type VariantType } from "notistack";
import { type EnqueueSnackbarOptions } from "hooks";
import { getEffectiveErrorMessage } from "utils";

const mapDataRoomErrorToSnackbar = (
  originalError: unknown,
  message: string
): [string, EnqueueSnackbarOptions<VariantType> | undefined] => {
  const error =
    originalError instanceof ApolloError
      ? originalError.networkError || new Error(originalError.message)
      : originalError;

  if (error instanceof exceptions.DataRoomComputationDatasetRequiredError) {
    return [
      "The computation could not be run due to a missing dataset. Please check if the dataset is provisioned accordingly.",
      {
        context: error.message,
        persist: true,
        variant: "error",
      },
    ];
  }

  if (error instanceof exceptions.DataRoomComputationValidationError) {
    return [
      "The computation could not be run due to a validation error. Please check if the required datasets are in the correct format.",
      {
        context: error.message,
        persist: true,
        variant: "error",
      },
    ];
  }

  if (error instanceof exceptions.DataRoomComputationMissingEnclaveError) {
    return [
      "The computation could not be run due an unavailable enclave. If this is an old DCR, please duplicate it. Otherwise please report it to Decentriq using the button to the right.",
      {
        context: error.message,
        persist: true,
        variant: "error",
      },
    ];
  }

  if (error instanceof exceptions.DataRoomComputationOutOfMemoryError) {
    return [
      "The computation could not be run due to an out of memory error. Please check the dataset size and possibly the executed code. If all is as expected, please report the issue to Decentriq using the button to the right.",
      {
        context: error.message,
        persist: true,
        variant: "error",
      },
    ];
  }

  if (error instanceof exceptions.DataRoomComputationError) {
    return [
      "An error occurred while executing the code. Please find the full error message in the expandable details below.",
      {
        context: error.message,
        persist: true,
        variant: "error",
      },
    ];
  }

  return [
    message,
    {
      context: getEffectiveErrorMessage(originalError),
      persist: true,
      variant: "error",
    },
  ];
};

export default mapDataRoomErrorToSnackbar;
