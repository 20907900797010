import { ColumnDataType } from "@decentriq/graphql/dist/types";

export const DraftDataNodeTypeNames = {
  DraftRawLeafNode: "DraftRawLeafNode",
  DraftTableLeafNode: "DraftTableLeafNode",
} as const;

export const PublishedDataNodeTypeNames = {
  PublishedRawLeafNode: "PublishedRawLeafNode",
  PublishedTableLeafNode: "PublishedTableLeafNode",
} as const;

export const DataNodeTypeNames = {
  ...DraftDataNodeTypeNames,
  ...PublishedDataNodeTypeNames,
} as const;

export type PublishedDataNodeTypeNames =
  keyof typeof PublishedDataNodeTypeNames;
export type DataNodeTypeNames = keyof typeof DataNodeTypeNames;

export const tableLeafNodePrimitiveTypePresentation = {
  [ColumnDataType.Int]: "Number (whole)",
  [ColumnDataType.Text]: "String",
  [ColumnDataType.Float]: "Number (decimal)",
} as const;
