import { Box, Container } from "@mui/joy";
import { type ReactNode } from "react";
import { Header, HEADER_HEIGHT, Sidebar, SIDEBAR_WIDTH } from "features";

interface MainProps {
  children: ReactNode;
}

const Main: React.FC<MainProps> = ({ children }) => {
  return (
    <Box width="100%">
      <Header />
      <Container
        disableGutters={true}
        maxWidth={false}
        sx={{ display: "flex", height: `calc(100% - ${HEADER_HEIGHT}px)` }}
      >
        <Sidebar />
        <Container
          disableGutters={true}
          maxWidth={false}
          sx={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
            marginLeft: `${SIDEBAR_WIDTH}px`,
            width: `calc(100% - ${SIDEBAR_WIDTH}px)`,
          }}
        >
          {children}
        </Container>
      </Container>
    </Box>
  );
};

export default Main;
