import { memo } from "react";
import { PermissionsVarsProvider } from "contexts";

interface PermissionsVarsWrapperProps {
  canEditPermissions: boolean;
  children: React.ReactNode;
}

const PermissionsVarsWrapper: React.FC<PermissionsVarsWrapperProps> = ({
  children,
  canEditPermissions = true,
}) => {
  return (
    <PermissionsVarsProvider value={{ canEditPermissions }}>
      {children}
    </PermissionsVarsProvider>
  );
};

export default memo(PermissionsVarsWrapper);
