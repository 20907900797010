import {
  ColumnDataType,
  TableColumnFormatType,
} from "@decentriq/graphql/dist/types";
import {
  type DataRoomData,
  dataRoomTablePrimitiveTypePresentation,
} from "models";

export interface DataRoomTablePrimitiveTypeOption {
  label: string;
  value: ColumnDataType;
}

export const dataRoomTablePrimitiveTypeOptions: DataRoomTablePrimitiveTypeOption[] =
  [ColumnDataType.Int, ColumnDataType.Text, ColumnDataType.Float].map(
    (key) => ({
      label: dataRoomTablePrimitiveTypePresentation.get(key) as string,
      value: key,
    })
  );

export const dataRoomTableFormatTypePresentation = new Map<
  TableColumnFormatType,
  string
>([
  [TableColumnFormatType.DateIso8601, "Date (YYYY-MM-DD)"],
  [TableColumnFormatType.Email, "Email"],
  [TableColumnFormatType.Float, "Number (decimal)"],
  [TableColumnFormatType.HashSha256Hex, "Hash (SHA256 hex)"],
  [TableColumnFormatType.Integer, "Number (whole)"],
  [TableColumnFormatType.PhoneNumberE164, "Phone number (E.164)"],
  [TableColumnFormatType.String, "String"],
]);

export interface DataRoomTableFormatTypeOption {
  label: string;
  value: TableColumnFormatType;
}

export const dataRoomTableFormatTypeOptions: DataRoomTableFormatTypeOption[] = [
  TableColumnFormatType.String,
  TableColumnFormatType.Integer,
  TableColumnFormatType.Float,
  TableColumnFormatType.Email,
  TableColumnFormatType.HashSha256Hex,
  TableColumnFormatType.DateIso8601,
  TableColumnFormatType.PhoneNumberE164,
].map((key) => ({
  label: dataRoomTableFormatTypePresentation.get(key) as string,
  value: key,
}));

export interface DataRoomDataNodeUploading {
  id: string;
  isLoading: boolean;
  uploadedAt?: string;
  user?: string;
  hash?: string;
  error?: any;
}

export type DataRoomDataNodeActionsRenderer = (
  dataNode: DataRoomData
) => React.ReactNode;

export type DataRoomIngestionsRendered = (
  dataNode: DataRoomData
) => React.ReactNode;
