import { createContext } from "react";
import { type DatasetPermission } from "features/datasets/models";
import { useSafeContext } from "hooks";

export interface DatasetPemissionsContextValue {
  addPermission: (permission: DatasetPermission) => void;
  changePermission: (permission: DatasetPermission) => void;
  permissions: DatasetPermission[];
  isPermissionsDataLoading: boolean;
  isAddingPermission: boolean;
  isEditingPermisison: boolean;
  openShareDialog: () => void;
  openEditDialog: (permission: DatasetPermission) => void;
  hasMigratedKeychain: boolean;
  // These feature flags are defined as a function to seemlesly handle users who are not migrated to the new keychain, as soon as they are migrated, the flags will be updated to be only boolean values
  canDeleteDatasetOrDefault: (defaultValue?: boolean) => boolean;
  canDeprovisionDatasetOrDefault: (defaultValue?: boolean) => boolean;
  canShareDatasetOrDefault: (defaultValue?: boolean) => boolean;
  canExportDatasetOrDefault: (defaultValue?: boolean) => boolean;
  canEditPermissionsOrDefault: (defaultValue?: boolean) => boolean;
  canViewDatasetImportDetailsOrDefault: (defaultValue?: boolean) => boolean;
}

const DatasetPermissionContext =
  createContext<DatasetPemissionsContextValue | null>(null);

export const DatasetPermissionProvider = DatasetPermissionContext.Provider;

export const DatasetPermissionConsumer = DatasetPermissionContext.Consumer;

DatasetPermissionContext.displayName = "DatasetPermissionContext";

export const useDatasetPermission = () =>
  useSafeContext(DatasetPermissionContext);
