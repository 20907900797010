import { faTriangleExclamation } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, CircularProgress, Typography } from "@mui/joy";
import { memo, type MouseEventHandler, useCallback, useState } from "react";
import { useMediaDataRoomInsightsData } from "features/mediaDataRoom/contexts";

const AdvertiserDataReportLabel: React.FC<{}> = () => {
  const { advertiserDataReport } = useMediaDataRoomInsightsData();
  const entryLabel = useCallback(
    (num: number) => (num === 1 ? "entry" : "entries"),
    []
  );
  const [retryInProgress, setRetryInProgress] = useState(false);
  const retry: MouseEventHandler<HTMLAnchorElement> = useCallback(
    async (event) => {
      event.stopPropagation();
      setRetryInProgress(true);
      try {
        await advertiserDataReport.retry();
      } finally {
        setRetryInProgress(false);
      }
    },
    [advertiserDataReport]
  );
  if (advertiserDataReport.error) {
    return (
      <>
        <FontAwesomeIcon
          fixedWidth={true}
          icon={faTriangleExclamation}
          size="2x"
        />
        <Typography> Failed to fetch data report </Typography>
        <Button loading={retryInProgress} onClick={retry} variant="plain">
          Retry
        </Button>
      </>
    );
  }
  if (advertiserDataReport.status !== "COMPLETED") {
    return (
      <>
        Checking for duplicates in the data...
        <CircularProgress
          sx={{ "--CircularProgress-size": "12px", marginLeft: "6px" }}
        />
      </>
    );
  }
  if (!advertiserDataReport.computeResults) {
    return <>Data report is missing</>;
  }
  const { numberOfDeduplicatedRows, numberOfIngestedRows } =
    advertiserDataReport.computeResults;
  if (!numberOfDeduplicatedRows) {
    return <>No duplicates found in the data</>;
  }
  return (
    <>{`Dropped ${numberOfDeduplicatedRows} duplicate ${entryLabel(
      numberOfDeduplicatedRows
    )}. There are ${numberOfIngestedRows} remaining ${entryLabel(
      numberOfIngestedRows
    )}.`}</>
  );
};

export default memo(AdvertiserDataReportLabel);
