import {
  useDraftDataRoomEnableImmutabilityQuery,
  usePublishedDataRoomEnableImmutabilityQuery,
} from "@decentriq/graphql/dist/hooks";
import { useDataRoom } from "contexts";
import {
  mapDraftDataRoomErrorToSnackbar,
  mapErrorToGeneralSnackbar,
  useDataRoomSnackbar,
} from "hooks";

const useDataRoomEnableImmutability = () => {
  const { enqueueSnackbar } = useDataRoomSnackbar();
  const { isPublished, dataRoomId } = useDataRoom();
  // Draft
  const { data: draftData } = useDraftDataRoomEnableImmutabilityQuery({
    onError: (error) => {
      enqueueSnackbar(
        ...mapDraftDataRoomErrorToSnackbar(
          error,
          "DCR immutability settings could not be retrieved."
        )
      );
    },
    skip: isPublished,
    variables: { id: dataRoomId },
  });
  // Published
  const { data: publishedData } = usePublishedDataRoomEnableImmutabilityQuery({
    onError: (error) => {
      enqueueSnackbar(
        ...mapErrorToGeneralSnackbar(
          error,
          "DCR immutability settings could not be retrieved."
        )
      );
    },
    skip: !isPublished,
    variables: { id: dataRoomId },
  });

  if (isPublished) {
    return !publishedData?.publishedDataRoom?.enableInteractivity;
  }

  return !draftData?.draftDataRoom?.enableInteractivity;
};

export default useDataRoomEnableImmutability;
