import {
  useDataConnectorJobQuery,
  useDataConnectorJobResultQuery,
} from "@decentriq/graphql/dist/hooks";
import {
  type DataConnectorJobResult,
  DataImportExportStatus,
} from "@decentriq/graphql/dist/types";

type DatasetExportResultHookReturn = {
  status: DataImportExportStatus | undefined;
  result: DataConnectorJobResult | undefined | null;
};

export const useDatasetExportResult = (
  datasetExportId: string
): DatasetExportResultHookReturn => {
  const { data: datasetExportData } = useDataConnectorJobQuery({
    skip: !datasetExportId,
    variables: { id: datasetExportId as string },
  });
  const {
    dataConnectorJob: {
      status: datasetExportStatus = DataImportExportStatus.Pending,
    } = {},
  } = datasetExportData || {};
  const { data: datasetExportResult } = useDataConnectorJobResultQuery({
    variables: { id: datasetExportId },
  });
  const { dataConnectorJob: { result: exportResult = {} } = {} } =
    datasetExportResult || {};
  return {
    result: exportResult,
    status: datasetExportStatus,
  };
};
