import {
  useAdvertiserMarketFiltersQuery,
  useAvailablePublishersQuery,
  useUpdateAdvertiserMarketFiltersMutation,
} from "@decentriq/graphql/dist/hooks";
import {
  type AvailablePublisher,
  type AvailablePublisherFragment,
} from "@decentriq/graphql/dist/types";
import { useUpdateEffect } from "ahooks";
import {
  createContext,
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { type ActiveMarket } from "features/mediaPortalShared";
import {
  MediaDataRoomCreationStep,
  MediaDataRoomOrganizationRole,
  useCreationWizardStepper,
} from "./CreationWIzardStepperContext";

interface CreationWizardPublisherContextValue {
  advertiserMarketFilters: (ActiveMarket & { selected: boolean })[];
  isAvailablePublishersLoading: boolean;
  availablePublishers: AvailablePublisherFragment[];
  updateAdvertiserMarketFilters: (marketIds: string[]) => void;
  selectedPublisher: AvailablePublisherFragment | null;
  selectPublisher: (publisher: AvailablePublisherFragment | null) => void;
  invitePublisher: () => void;
  hasSelectedPublisher: boolean;
  hasSkippedSelection: boolean;
}

const CreationWizardPublisherContext =
  createContext<CreationWizardPublisherContextValue | null>(null);

const CreationWizardPublisherContextProvider =
  CreationWizardPublisherContext.Provider;

export const useCreationWizardPublisher = () => {
  const contextValue = useContext(CreationWizardPublisherContext);
  if (contextValue === null) {
    throw new Error(
      "useCreationWizardPublisher must be used within a CreationWizardPublisherWrapper"
    );
  }
  return contextValue;
};

const CreationWizardPublisherWrapper = memo<React.PropsWithChildren>(
  ({ children }) => {
    const { organizationRole, activeStep } = useCreationWizardStepper();
    const [selectedPublisher, setSelectedPublisher] =
      useState<AvailablePublisher | null>(null);
    const [hasSkippedSelection, setHasSkippedSelection] =
      useState<boolean>(false);
    const hasSelectedPublisher = Boolean(selectedPublisher);
    // Fetch Available Publishers data
    const [updateAdvertiserMarketFiltersMutation] =
      useUpdateAdvertiserMarketFiltersMutation();
    const { data: advertiserMarketFiltersData } =
      useAdvertiserMarketFiltersQuery();
    const updateAdvertiserMarketFilters = useCallback(
      (marketIds: string[]) => {
        updateAdvertiserMarketFiltersMutation({
          optimisticResponse: {
            availablePublisher: {
              updateMarketFilters: marketIds,
            },
          },
          update: (cache, { data }) => {
            cache.modify({
              fields: {
                advertiserMarketFilters: () =>
                  data?.availablePublisher.updateMarketFilters || [],
              },
            });
          },
          variables: {
            input: marketIds,
          },
        });
      },
      [updateAdvertiserMarketFiltersMutation]
    );
    const {
      data: availablePublishersQueryData,
      loading: isAvailablePublishersLoading,
    } = useAvailablePublishersQuery();
    const allAvailablePublishers = useMemo<AvailablePublisher[]>(
      () => availablePublishersQueryData?.availablePublishers?.nodes || [],
      [availablePublishersQueryData?.availablePublishers?.nodes]
    );
    const advertiserMarketFilters = useMemo(() => {
      const publisherMarkets =
        advertiserMarketFiltersData?.publisherMarkets?.nodes || [];
      const activeFilters =
        advertiserMarketFiltersData?.advertiserMarketFilters || [];
      return publisherMarkets
        .filter(({ id }) =>
          allAvailablePublishers.some(({ marketIds }) => marketIds.includes(id))
        )
        .map((market) => ({
          ...market,
          selected: activeFilters.includes(market.id),
        }));
    }, [advertiserMarketFiltersData, allAvailablePublishers]);
    const filteredAvailablePublishers = useMemo<AvailablePublisher[]>(() => {
      const selectedMarketFilters = advertiserMarketFilters.filter(
        ({ selected }) => selected
      );
      const hasAnyFilterSelected = selectedMarketFilters.length > 0;
      if (!hasAnyFilterSelected) {
        return allAvailablePublishers;
      }
      return allAvailablePublishers.filter(({ marketIds }) =>
        selectedMarketFilters.some(({ id }) => marketIds.includes(id))
      );
    }, [allAvailablePublishers, advertiserMarketFilters]);
    const invitePublisher = useCallback(
      () => setHasSkippedSelection(true),
      [setHasSkippedSelection]
    );
    const shouldKeepSelectedPublisher =
      [
        MediaDataRoomCreationStep.COLLABORATION_REQUEST_TO_PUBLISHER,
        MediaDataRoomCreationStep.COLLABORATION_REQUEST_TO_DATA_PARTNER,
        MediaDataRoomCreationStep.SELECT_DATA_PARTNER,
        MediaDataRoomCreationStep.SELECT_PUBLISHER,
        MediaDataRoomCreationStep.SELECT_DATA_SOURCE,
        MediaDataRoomCreationStep.COLLABORATION_CONFIGURATION,
      ].includes(activeStep) &&
      organizationRole === MediaDataRoomOrganizationRole.ADVERTISER;
    useEffect(() => {
      if (
        !filteredAvailablePublishers.length ||
        !shouldKeepSelectedPublisher ||
        hasSkippedSelection
      ) {
        setSelectedPublisher(null);
        return;
      }
      setSelectedPublisher((currentSelectedPublisher) => {
        if (
          filteredAvailablePublishers.some(
            ({ id }) => id === currentSelectedPublisher?.id
          )
        ) {
          return currentSelectedPublisher;
        }
        return filteredAvailablePublishers[0];
      });
    }, [
      setSelectedPublisher,
      organizationRole,
      hasSkippedSelection,
      filteredAvailablePublishers,
      shouldKeepSelectedPublisher,
    ]);
    const isSelectionStep =
      activeStep === MediaDataRoomCreationStep.SELECT_PUBLISHER;
    useUpdateEffect(() => {
      if (isSelectionStep) {
        setHasSkippedSelection(false);
      }
    }, [isSelectionStep, setHasSkippedSelection]);
    const contextValue = useMemo<CreationWizardPublisherContextValue>(
      () => ({
        advertiserMarketFilters,
        availablePublishers: filteredAvailablePublishers,
        hasSelectedPublisher,
        hasSkippedSelection,
        invitePublisher,
        isAvailablePublishersLoading,
        selectPublisher: setSelectedPublisher,
        selectedPublisher,
        updateAdvertiserMarketFilters,
      }),
      [
        advertiserMarketFilters,
        hasSelectedPublisher,
        invitePublisher,
        filteredAvailablePublishers,
        isAvailablePublishersLoading,
        hasSkippedSelection,
        setSelectedPublisher,
        selectedPublisher,
        updateAdvertiserMarketFilters,
      ]
    );
    return (
      <CreationWizardPublisherContextProvider value={contextValue}>
        {children}
      </CreationWizardPublisherContextProvider>
    );
  }
);

CreationWizardPublisherWrapper.displayName = "CreationWizardPublisherWrapper";

export default CreationWizardPublisherWrapper;
