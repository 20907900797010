// TODO: Fix joy migration
import {
  useAccessTokenQuery,
  useRevokeAccessTokenMutation,
} from "@decentriq/graphql/dist/hooks";
import { type ApiToken } from "@decentriq/graphql/dist/types";
import { faCopy, faTrashCan } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Grid, IconButton, Tooltip, Typography } from "@mui/joy";
import { grey } from "@mui/material/colors";
import { format } from "date-fns";
import { useSnackbar } from "notistack";
import { memo, useCallback } from "react";
import { useCopyToClipboard } from "hooks";

interface AccessTokensTileProps {
  tokenId: string;
  revoked?: boolean;
}

const AccessTokensTile: React.FC<AccessTokensTileProps> = ({
  tokenId,
  revoked,
}) => {
  const { data: tokenData } = useAccessTokenQuery({
    variables: {
      tokenId,
    },
  });
  const token: Partial<ApiToken> = tokenData?.myself?.accessTokenById || {};
  const tokenCreatedAt = format(
    new Date(token.createdAt),
    "MMMM, do yyyy' at 'HH:mm"
  );

  const [, copyToClipboard] = useCopyToClipboard();
  const { enqueueSnackbar } = useSnackbar();

  // Copy Access token
  const handleCopyToken = useCallback(() => {
    if (!token?.token) {
      return;
    }
    copyToClipboard(token.token);
    enqueueSnackbar("Token copied");
  }, [copyToClipboard, enqueueSnackbar, token?.token]);

  // Revoke Access token
  const [revokeAccessTokenMutation] = useRevokeAccessTokenMutation();
  const handleRevokeToken = useCallback(() => {
    revokeAccessTokenMutation({
      variables: { tokenId },
    });
  }, [revokeAccessTokenMutation, tokenId]);

  return (
    <Grid container={true} spacing={1}>
      <Grid alignItems="baseline" container={true} xs={12}>
        <Typography level="body-sm">{token?.name || <i>Unnamed</i>}</Typography>
        <Typography
          level="body-sm"
          style={{ color: grey["600"], marginLeft: "8px" }}
        >
          {token?.token}
          <Tooltip title={<span>Copy access token to clipboard</span>}>
            <IconButton onClick={handleCopyToken}>
              <FontAwesomeIcon fixedWidth={true} icon={faCopy} />
            </IconButton>
          </Tooltip>
        </Typography>
        <div
          style={{
            alignItems: "center",
            display: "flex",
            marginLeft: "auto",
          }}
        >
          <Typography
            color={grey["400"]}
            level="body-sm"
            style={{ marginRight: "8px" }}
          >
            Created at: {tokenCreatedAt}
          </Typography>
          <IconButton disabled={revoked} onClick={handleRevokeToken}>
            <FontAwesomeIcon fixedWidth={true} icon={faTrashCan} />
          </IconButton>
        </div>
      </Grid>
    </Grid>
  );
};

AccessTokensTile.displayName = "AccessTokensTile";

export default memo(AccessTokensTile);
