import { useAuth0 } from "@auth0/auth0-react";
import { testIds } from "@decentriq/utils";
import { Stack, Tab, TabList, TabPanel, Tabs, Typography } from "@mui/joy";
import { type SxProps } from "@mui/system";
import { memo } from "react";
import { Link } from "react-router-dom";
import { DataRoomParticipants } from "containers";
import { useDataRoom } from "contexts";
import {
  ComputeNodeConstructorMode,
  ComputeNodes,
  DataNodeConstructorMode,
  DataNodes,
} from "features";
import { DraftDataRoomTab } from "models";
import { ComputeNodesVarsWrapper, PermissionsVarsWrapper } from "wrappers";

const tabsSx: SxProps = { flex: 1, overflow: "hidden" };
const tabPanelSx: SxProps = {
  "&:not([hidden])": {
    display: "flex",
    flexDirection: "column",
    overflow: "auto",
  },
};

interface DraftDataRoomTabsProps {
  activeTab: string;
  baseUrl: string;
  ownerEmail: string | undefined;
}

const DraftDataRoomTabs: React.FC<DraftDataRoomTabsProps> = ({
  activeTab,
  baseUrl,
  ownerEmail,
}) => {
  const { user = {} } = useAuth0();
  const { email: currentUserEmail } = user || {};
  const { allowTestMode, dataRoomId } = useDataRoom();

  return (
    <Tabs sx={tabsSx} value={activeTab}>
      <TabList>
        <Tab
          component={Link}
          data-testid={testIds.containers.draftDataRoom.dataTab}
          to={`${baseUrl}/${DraftDataRoomTab.data}`}
          value={DraftDataRoomTab.data}
        >
          Data
        </Tab>
        <Tab
          component={Link}
          data-testid={testIds.containers.draftDataRoom.computationsTab}
          to={`${baseUrl}/${DraftDataRoomTab.computations}`}
          value={DraftDataRoomTab.computations}
        >
          Computations
        </Tab>
        <Tab
          component={Link}
          data-testid={testIds.containers.draftDataRoom.permissionsTab}
          to={`${baseUrl}/${DraftDataRoomTab.permissions}`}
          value={DraftDataRoomTab.permissions}
        >
          Permissions
        </Tab>
        <Tab
          component={Link}
          data-testid={testIds.containers.draftDataRoom.overviewTab}
          to={`${baseUrl}/${DraftDataRoomTab.overview}`}
          value={DraftDataRoomTab.overview}
        >
          Overview
        </Tab>
      </TabList>

      <TabPanel sx={tabPanelSx} value={DraftDataRoomTab.data}>
        <DataNodes
          currentUserEmail={currentUserEmail!}
          dataRoomId={dataRoomId}
          mode={
            allowTestMode
              ? DataNodeConstructorMode.EDIT_WITH_TESTING
              : DataNodeConstructorMode.EDIT
          }
          ownerEmail={ownerEmail!}
        />
      </TabPanel>
      <TabPanel sx={tabPanelSx} value={DraftDataRoomTab.computations}>
        <ComputeNodesVarsWrapper
          hasComputeNodeActions={true}
          hasComputeNodeParticipants={true}
          mode={ComputeNodeConstructorMode.EDIT}
        >
          <ComputeNodes />
        </ComputeNodesVarsWrapper>
      </TabPanel>
      <TabPanel sx={tabPanelSx} value={DraftDataRoomTab.permissions}>
        <PermissionsVarsWrapper canEditPermissions={true}>
          <DataRoomParticipants />
        </PermissionsVarsWrapper>
      </TabPanel>
      <TabPanel sx={tabPanelSx} value={DraftDataRoomTab.overview}>
        <Stack>
          <Typography level="title-md">Data</Typography>
          <DataNodes
            currentUserEmail={currentUserEmail!}
            dataRoomId={dataRoomId}
            mode={DataNodeConstructorMode.READONLY}
            ownerEmail={ownerEmail!}
          />
          <ComputeNodesVarsWrapper
            hasComputeNodeActions={false}
            hasComputeNodeParticipants={true}
            mode={ComputeNodeConstructorMode.READONLY}
          >
            <Typography level="title-md">Computations</Typography>
            <ComputeNodes relaxed={true} />
          </ComputeNodesVarsWrapper>
          <Typography level="title-md">Permissions</Typography>
          <PermissionsVarsWrapper canEditPermissions={false}>
            <DataRoomParticipants />
          </PermissionsVarsWrapper>
        </Stack>
      </TabPanel>
    </Tabs>
  );
};

DraftDataRoomTabs.displayName = "DraftDataRoomTabs";

export default memo(DraftDataRoomTabs);
