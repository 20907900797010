// Transforms data for UI purposes
export const formatBasePropensity = (basePropensity: number): string =>
  `${basePropensity}%`;
export const formatOverlapPropensity = (overlapPropensity: number): string =>
  `${overlapPropensity}%`;
export const formatNetPropensity = (netPropensity: number): string =>
  `${netPropensity}x`;

export const normalizeEmail = (email: string) => email.trim().toLowerCase();

// TODO: this should be removed after checking with Lorenzo that DCR with email case issue is not used anymore
export const isNormalizedEmailIncluded = (
  emails: (string | null | undefined)[],
  currentEmail: string
) =>
  emails.some(
    (email) => normalizeEmail(email || "") === normalizeEmail(currentEmail)
  );

export const replaceDecentriqOrgName = (
  orgName: string | undefined,
  replacement: string
): string => (orgName === "Decentriq" || !orgName ? replacement : orgName);
