import { useSnackbar } from "notistack";
import { useCallback } from "react";
import useScriptingComputeNode from "features/computeNode/components/ComputeNodeEditor/components/ScriptingComputeNodeEditor/useScriptingComputeNode";
import { ComputeNodeTypeNames } from "models";

const useCheckScriptingNodeInputs = (
  computeNodeId: string,
  computationType: ComputeNodeTypeNames | undefined
) => {
  const { enqueueSnackbar } = useSnackbar();
  const skip =
    computationType !== ComputeNodeTypeNames.DraftScriptingNode &&
    computationType !== ComputeNodeTypeNames.PublishedScriptingNode;
  const { dependencies, name } = useScriptingComputeNode(computeNodeId, skip);
  const depsLength = dependencies?.length || 0;
  return useCallback(() => {
    if (skip) {
      return;
    }
    if (depsLength === 0) {
      enqueueSnackbar(
        `${name} does not have any input file selected in the “Available data” field. Please double-check if it is intentional.`,
        { persist: true, variant: "default" }
      );
    }
  }, [skip, depsLength, enqueueSnackbar, name]);
};

export default useCheckScriptingNodeInputs;
