// TODO: Fix joy migration
import { faEnvelopeOpenText } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Divider,
  Modal,
  ModalDialog,
  Typography,
} from "@mui/joy";
import { type Theme } from "@mui/material";
import { grey } from "@mui/material/colors";
import { makeStyles } from "tss-react/mui";

const useChangePasswordDialogStyles = makeStyles()((theme: Theme) => ({
  icon: {
    color: grey["400"],
  },
  iconTitleWrapper: {
    padding: theme.spacing(3, 3, 2, 3),
  },
  iconWrapper: {
    marginBottom: theme.spacing(2),
    textAlign: "center",
  },
  title: {
    lineHeight: 1.25,
  },
}));

interface ChangePasswordDialogProps {
  open: boolean;
  onClose: () => void;
}

const ChangePasswordDialog: React.FC<ChangePasswordDialogProps> = ({
  open,
  onClose,
}) => {
  const { classes: changePasswordDialogClasses } =
    useChangePasswordDialogStyles();
  return (
    <Modal onClose={onClose} open={open}>
      <ModalDialog>
        <Box className={changePasswordDialogClasses.iconTitleWrapper}>
          <Box className={changePasswordDialogClasses.iconWrapper}>
            <FontAwesomeIcon
              className={changePasswordDialogClasses.icon}
              fixedWidth={true}
              icon={faEnvelopeOpenText}
              size="5x"
            />
          </Box>
          <Typography
            align="center"
            className={changePasswordDialogClasses.title}
            color="textPrimary"
            level="title-md"
          >
            <strong>Please check your email</strong>
          </Typography>
        </Box>
        <DialogContent>
          <Typography align="center" component="div" level="body-sm">
            Please check your email address for further
            <br />
            instructions on how to reset your password.
          </Typography>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button onClick={onClose}>OK</Button>
        </DialogActions>
      </ModalDialog>
    </Modal>
  );
};

export default ChangePasswordDialog;
