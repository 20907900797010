import { DqCard, DqCardContent } from "@decentriq/components";
import { faSparkles } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  FormControl,
  FormLabel,
  Grid,
  Input,
  Typography,
} from "@mui/joy";
import { LoadingButton } from "@mui/lab";
import { memo, useMemo } from "react";
import { useAdvertiserAudienceGenerator } from "features/MediaInsightsDcr/containers/AdvertiserAudienceGenerator";
import {
  AudienceGeneratorStepActionsWrapper,
  AudienceGeneratorStepSectionDescription,
  AudienceGeneratorStepSectionTitle,
} from "features/MediaInsightsDcr/containers/AdvertiserAudienceGenerator/components";
import { usePublishedMediaInsightsDcr } from "features/MediaInsightsDcr/contexts";
import { useLookalikeAdvertiserAudienceGenerator } from "../..";
import { QualityScore } from "..";

const LookalikeAdvertiserAudienceGeneratorSummaryStep = memo(() => {
  const {
    generateLookalikeAudienceLoading,
    generateLookalikeAudience,
    estimatedAudienceSize,
    reach,
    selectedLookalikeAudience,
  } = useLookalikeAdvertiserAudienceGenerator();
  const formatter = Intl.NumberFormat("en", { notation: "compact" });
  const { handleBackStep } = useAdvertiserAudienceGenerator();
  const {
    supportedFeatures: {
      enableExtendedLalQualityStats: enableDcrExtendedLalQualityStats = false,
    },
    publisherOrganization,
  } = usePublishedMediaInsightsDcr();
  const enableOrganizationExtendedLalQualityStats =
    publisherOrganization?.enableExtendedLookalikeQualityStatistics || false;
  const enableExtendedLookalikeQualityStatistics =
    enableDcrExtendedLalQualityStats &&
    enableOrganizationExtendedLalQualityStats;
  const audienceName = useMemo(
    () => `${selectedLookalikeAudience?.audience_type} - ${reach}%`,
    [reach, selectedLookalikeAudience]
  );
  return (
    <>
      <Grid mb={2} xs={7}>
        <AudienceGeneratorStepSectionTitle>
          1. Audience name
        </AudienceGeneratorStepSectionTitle>
        <AudienceGeneratorStepSectionDescription>
          Audience name reflects your audience's purpose or focus.
        </AudienceGeneratorStepSectionDescription>
      </Grid>
      <Grid container={true} mb={2} xs={4}>
        <FormControl sx={{ width: "100%" }}>
          <FormLabel>Audience name</FormLabel>
          <Input defaultValue={audienceName} readOnly={true} />
        </FormControl>
      </Grid>
      <Grid mb={2} xs={7}>
        <AudienceGeneratorStepSectionTitle>
          2. Estimated audience size
        </AudienceGeneratorStepSectionTitle>
        <AudienceGeneratorStepSectionDescription>
          This section displays the estimated size of your new audience.
        </AudienceGeneratorStepSectionDescription>
      </Grid>
      <Grid container={true} mb={1} xs={4}>
        <Grid xs={12}>
          <DqCard>
            <DqCardContent sx={{ justifyContent: "center" }}>
              <Typography
                gutterBottom={true}
                level="body-sm"
                textColor="inherit"
              >
                Estimated new audience size
              </Typography>
              <Typography fontSize={36} fontWeight={600}>
                {formatter.format(estimatedAudienceSize)}
              </Typography>
            </DqCardContent>
          </DqCard>
        </Grid>
      </Grid>
      <Grid container={true} mb={1} xs={4}>
        <Grid xs={12}>
          <DqCard>
            <DqCardContent>
              <Typography
                gutterBottom={true}
                level="body-sm"
                textColor="inherit"
              >
                Precision/reach tradeoff
              </Typography>
              <Typography fontSize={36} fontWeight={600}>
                {reach}%
              </Typography>
            </DqCardContent>
          </DqCard>
        </Grid>
      </Grid>
      {enableExtendedLookalikeQualityStatistics && (
        <Grid container={true} xs={4}>
          <Grid xs={12}>
            <DqCard>
              <DqCardContent>
                <QualityScore />
              </DqCardContent>
            </DqCard>
          </Grid>
        </Grid>
      )}
      <Grid mb={2} mt={2} xs={7}>
        <AudienceGeneratorStepSectionTitle>
          3. Audience summary
        </AudienceGeneratorStepSectionTitle>
        <AudienceGeneratorStepSectionDescription>
          Details of your audience configuration below:
        </AudienceGeneratorStepSectionDescription>
      </Grid>
      <Grid container={true} mb={1} xs={4}>
        <Grid xs={12}>
          <DqCard>
            <DqCardContent sx={{ alignItems: "flex-start" }}>
              <Typography level="body-sm" mb={0.5} textColor="inherit">
                <strong>Type:</strong> Lookalike
              </Typography>
              <Typography level="body-sm" textColor="inherit">
                <strong>Name of the audience:</strong> {audienceName}
              </Typography>
            </DqCardContent>
          </DqCard>
        </Grid>
      </Grid>
      <AudienceGeneratorStepActionsWrapper>
        <Button onClick={handleBackStep} sx={{ mr: 1 }}>
          Back
        </Button>
        <LoadingButton
          color="primary"
          loading={generateLookalikeAudienceLoading}
          loadingPosition="start"
          onClick={generateLookalikeAudience}
          startIcon={<FontAwesomeIcon icon={faSparkles} />}
          sx={{ height: "fit-content" }}
          variant="contained"
        >
          Generate results
        </LoadingButton>
      </AudienceGeneratorStepActionsWrapper>
    </>
  );
});

LookalikeAdvertiserAudienceGeneratorSummaryStep.displayName =
  "LookalikeAdvertiserAudienceGeneratorSummaryStep";

export default LookalikeAdvertiserAudienceGeneratorSummaryStep;
