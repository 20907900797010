import { faInfoCircle } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Tooltip,
} from "@mui/joy";
import { memo } from "react";
import {
  Controller,
  type FieldValues,
  type Path,
  useFormContext,
} from "react-hook-form";

interface ConfigurationFormNameFieldProps<F extends FieldValues> {
  fieldName: Path<F>;
  title: string;
  placeholder: string;
  tooltip?: string;
  testId?: string;
}

type ConfigurationFormNameFieldComponent = <F extends FieldValues>(
  props: ConfigurationFormNameFieldProps<F>
) => React.ReactNode;

const ConfigurationFormNameField = memo(
  <F extends FieldValues>({
    fieldName,
    title,
    placeholder,
    tooltip,
    testId,
  }: ConfigurationFormNameFieldProps<F>) => {
    const { control } = useFormContext<F>();
    return (
      <Controller
        control={control}
        name={fieldName}
        render={({ field, fieldState }) => {
          const { error } = fieldState;
          return (
            <FormControl
              disabled={field.disabled}
              error={Boolean(error)}
              required={true}
            >
              <FormLabel>
                {title}
                {tooltip ? (
                  <Box ml={0.5}>
                    <Tooltip title={tooltip}>
                      <FontAwesomeIcon icon={faInfoCircle} />
                    </Tooltip>
                  </Box>
                ) : null}
              </FormLabel>
              <Input
                placeholder={placeholder}
                {...field}
                data-testid={testId}
              />
              <FormHelperText>{error?.message}</FormHelperText>
            </FormControl>
          );
        }}
      />
    );
  }
);
ConfigurationFormNameField.displayName = "ConfigurationFormNameField";

export default ConfigurationFormNameField as ConfigurationFormNameFieldComponent;
