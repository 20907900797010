import { type Key } from "@decentriq/utils";
import { useCallback, useState } from "react";
import useCommonDataNodeActions from "features/dataNodes/hooks/useCommonDataNodeActions";
import { useDataLabContext } from "../contexts";
import { type DataLabDataNodeType } from "../models";

const useDataLabDataNodeActions = () => {
  const [typeForUpload, setTypeForUpload] = useState<
    DataLabDataNodeType | undefined
  >();
  const { setDataset } = useDataLabContext();
  const onDatasetDeprovision = useCallback(
    async ({ dataNodeId }: { dataNodeId: string }) =>
      setDataset(dataNodeId as DataLabDataNodeType, null),
    [setDataset]
  );
  const onConnectDataset = useCallback(
    async (args: { key: Key; dataNodeId: string; manifestHash: string }) =>
      setDataset(typeForUpload!, args.manifestHash),
    [typeForUpload, setDataset]
  );
  const {
    activeDataRoomUpload,
    setActiveDataRoomUpload,
    addOrUpdateUploading,
    currentUserEmail,
    handleAwaitedDataUploadingResult,
    handleConnectFromKeychain,
    handleDataDeprovision,
    handleIngestData,
    isUploadFailed,
    isUploadSuccessful,
    isUploadValidating,
    removeUploading,
    resetUploadings,
    uploadings,
  } = useCommonDataNodeActions({
    onAfterIngestData: onConnectDataset,
    onConnectDataset,
    onDatasetDeprovision,
  });
  const handleUploadClose = useCallback(() => {
    setTypeForUpload(undefined);
    setActiveDataRoomUpload(null);
  }, [setTypeForUpload, setActiveDataRoomUpload]);
  return {
    activeDataRoomUpload,
    addOrUpdateUploading,
    currentUserEmail,
    handleAwaitedDataUploadingResult,
    handleConnectFromKeychain,
    handleDataDeprovision,
    handleIngestData,
    handleUploadClose,
    isUploadFailed,
    isUploadSuccessful,
    isUploadValidating,
    removeUploading,
    resetUploadings,
    setTypeForUpload,
    typeForUpload,
    uploadings,
  };
};

export default useDataLabDataNodeActions;
