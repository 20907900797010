import { Typography } from "@mui/joy";
import { memo } from "react";

const AudienceGeneratorStepSectionTitle = memo<React.PropsWithChildren>(
  ({ children }) => (
    <Typography fontWeight={600} level="title-md">
      {children}
    </Typography>
  )
);

AudienceGeneratorStepSectionTitle.displayName =
  "AudienceGeneratorStepSectionTitle";

export default AudienceGeneratorStepSectionTitle;
