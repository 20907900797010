import {
  useRetrieveDraftComputationFormatMutation,
  useRetrievePublishedComputationFormatMutation,
} from "@decentriq/graphql/dist/hooks";
import { useCallback } from "react";
import { useDataRoom, usePublishedDataRoom } from "contexts";
import {
  mapDraftDataRoomErrorToSnackbar,
  mapErrorToGeneralSnackbar,
  useDataRoomSnackbar,
} from "hooks";

interface SqlNodeSchemaHookArgs {
  computeNodeId: string;
}

const useComputationNodeSchema = ({ computeNodeId }: SqlNodeSchemaHookArgs) => {
  const { dataRoomId, isPublished } = useDataRoom();
  const { enqueueSnackbar } = useDataRoomSnackbar();
  const { dataRoomId: dcrHash, driverAttestationHash } = usePublishedDataRoom();
  const [retrieveDraftNodeSchema, { loading: isDraftLoading }] =
    useRetrieveDraftComputationFormatMutation({
      onError: (error) => {
        enqueueSnackbar(
          ...mapDraftDataRoomErrorToSnackbar(
            error,
            `The data source computation schema could not be determined.`
          )
        );
      },
      variables: {
        id: dataRoomId,
        nodeId: computeNodeId,
      },
    });
  const [retrievePublishedNodeSchema, { loading: isPublishedLoading }] =
    useRetrievePublishedComputationFormatMutation({
      onError: (error) => {
        enqueueSnackbar(
          ...mapErrorToGeneralSnackbar(
            error,
            `The data source computation schema could not be determined.`
          )
        );
      },
      variables: {
        computeNodeId,
        dcrHash,
        driverAttestationHash,
      },
    });
  const retrieveSchema = useCallback(
    () =>
      isPublished
        ? retrievePublishedNodeSchema().then(
            ({ data }) => data?.retrievePublishedComputationFormat?.format
          )
        : retrieveDraftNodeSchema().then(
            ({ data }) => data?.retrieveDraftComputationFormat?.format
          ),
    [retrievePublishedNodeSchema, retrieveDraftNodeSchema, isPublished]
  );
  return {
    loading: isDraftLoading || isPublishedLoading,
    retrieveSchema,
  };
};

export default useComputationNodeSchema;
