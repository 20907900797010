import { SANITIZE_IDENTIFIER_INPUT } from "constants/index";
import { InlineEditor, type InlineEditorProps } from "@decentriq/components";
import { testIds } from "@decentriq/utils";
import { memo, useCallback } from "react";
import {
  mapDraftDataRoomErrorToSnackbar,
  useDataRoomSnackbar,
  useNodes,
} from "hooks";
import { type ComputeNodeTypeNames } from "models";
import { isValidIdentifier, sanitizeIdentifier } from "utils/validation";
import useComputeNodeNameEditMutation from "./useComputeNodeNameEdit/useComputeNodeNameEdit";
import useComputeNodeName from "./useComputeNodeName";

interface ComputeNodeNameEditorProps extends Partial<InlineEditorProps> {
  computeNodeId: string;
}

const ComputeNodeNameEditor: React.FC<ComputeNodeNameEditorProps> = memo(
  ({ computeNodeId, ...props }) => {
    const { enqueueSnackbar } = useDataRoomSnackbar();
    const { name, readOnly, __typename } = useComputeNodeName(computeNodeId);
    const { nodes } = useNodes();
    const nodeNames = nodes.map(({ name }) => name);
    const [setComputeNodeNameMutation] = useComputeNodeNameEditMutation(
      __typename as ComputeNodeTypeNames
    );
    const updateComputeNodeName = useCallback(
      async (value: string) => {
        try {
          const name = SANITIZE_IDENTIFIER_INPUT
            ? sanitizeIdentifier(value)
            : value;
          await setComputeNodeNameMutation({
            optimisticResponse: {
              [`${__typename?.[0]?.toLowerCase()}${__typename?.slice(1)}`]: {
                __typename: `${__typename}Mutations`,
                update: {
                  __typename,
                  id: computeNodeId,
                  name,
                },
              },
            },
            variables: {
              input: {
                id: computeNodeId,
                name,
              },
            },
          });
        } catch (error) {
          enqueueSnackbar(
            ...mapDraftDataRoomErrorToSnackbar(
              error,
              "Computation could not be renamed."
            )
          );
        }
      },
      [setComputeNodeNameMutation, __typename, computeNodeId, enqueueSnackbar]
    );
    return (
      <InlineEditor
        ContentProps={{
          ContentProps: {
            maxHeight: "none !important",
          },
        }}
        cancelEditingButtonEnabled={false}
        dataTestid={testIds.computeNode.computeNodeNameEditor.nameInput}
        helperTestid={testIds.computeNode.computeNodeNameEditor.nameInputHelper}
        onChange={updateComputeNodeName}
        placeholder="Name"
        readOnly={readOnly}
        saveEditingButtonEnabled={false}
        saveEditingOnClickAway={true}
        validate={(value: string) => {
          return !isValidIdentifier(value)
            ? "Identifiers should begin with a letter, not end in an underscore, and should contain only alphanumeric characters"
            : nodeNames.includes(value)
              ? "Name must be unique across all data and compute nodes"
              : value.length > 100
                ? "Computation name must be less than 100 characters"
                : !value
                  ? "Computation name must be set"
                  : null;
        }}
        value={name}
        {...props}
      />
    );
  }
);

ComputeNodeNameEditor.displayName = "ComputeNodeNameEditor";

export default ComputeNodeNameEditor;
