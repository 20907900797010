import {
  DialogContent,
  DialogTitle,
  LinearProgress,
  Modal,
  ModalDialog,
  Stack,
} from "@mui/joy";
import { Typography } from "@mui/material";
import { memo } from "react";

export interface MigrationProgressDialogProps {
  open: boolean;
  /**
   * progress as a percentage (0-100)
   */
  progress: number;
}

const MigrationProgressDialog = memo<MigrationProgressDialogProps>(
  ({ open, progress }) => {
    return (
      <Modal open={open}>
        <ModalDialog color="danger" role="alertdialog">
          <DialogTitle>
            <span style={{ color: "initial" }}>Migration progress</span>
          </DialogTitle>
          <DialogContent>
            <Stack direction="column" spacing={2}>
              <Typography>Initializing...</Typography>
              <LinearProgress
                color="primary"
                determinate={true}
                value={progress}
              />
            </Stack>
          </DialogContent>
        </ModalDialog>
      </Modal>
    );
  }
);

MigrationProgressDialog.displayName = "MigrationProgressDialog";
export default MigrationProgressDialog;
