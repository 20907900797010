export const SANITIZE_IDENTIFIER_INPUT = true;
export const EMAIL_REGEXP: RegExp =
  /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]{2,24}){1,3}$/;
export const DOMAIN_REGEXP: RegExp =
  /^[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]{2,24}){1,3}$/;
export const UUID_V4_REGEXP = /\w{8}-\w{4}-\w{4}-\w{4}-\w{12}/;
export const ENCLAVE_LEAF_ID_REGEXP =
  /\w{8}-\w{4}-\w{4}-\w{4}-\w{12}_(leaf|verification)/g;
export const PASSWORD_REGEXP: RegExp =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d/\W|_]{8,}$/;
