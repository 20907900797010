import { faChartSimple } from "@fortawesome/pro-light-svg-icons";
import { Box, Typography, useTheme } from "@mui/joy";
import { Bar, type BarDatum } from "@nivo/bar";
import { memo, useMemo } from "react";
import { AutoSizer } from "react-virtualized";
import { EmptyData } from "components";
import {
  SuppressedSegmentsInformation,
  useMediaDataRoomOverlapInsights,
} from "features/MediaInsightsDcr";

const AffinityRatioAgeBarChart: React.FC = () => {
  const {
    aggregationData: ageAggregationData,
    hasHiddenValues,
    hasSuppressedValues,
    suppressedValues,
    hiddenValues,
    possibleValues,
  } = useMediaDataRoomOverlapInsights({ aggregation: ["age"] });

  const { palette } = useTheme();

  const affinityRatioAgeBarData: BarDatum[] = useMemo(
    () =>
      ageAggregationData
        ? ageAggregationData.map(({ affinityRatio, age }) => ({
            age: age as string,
            value: affinityRatio,
          }))
        : [],
    [ageAggregationData]
  );

  return (
    <Box display="flex" flexDirection="column" height="100%">
      <Box alignItems="baseline" display="flex">
        <Typography fontWeight={600} mb={1} mr={1}>
          Affinity vs. Age
        </Typography>
        {(hasSuppressedValues || hasHiddenValues) && (
          <SuppressedSegmentsInformation
            hiddenValues={hiddenValues}
            possibleValues={possibleValues}
            suppressedValues={suppressedValues}
          />
        )}
      </Box>
      {affinityRatioAgeBarData.length ? (
        <AutoSizer>
          {({ width, height }) => (
            <Bar
              axisBottom={{
                legend: "Age",
                legendOffset: 32,
                legendPosition: "middle",
                tickPadding: 5,
                tickSize: 5,
              }}
              axisLeft={{
                legend: "Affinity",
                legendOffset: -48,
                legendPosition: "middle",
                tickPadding: 5,
                tickSize: 5,
              }}
              colors={palette.chart["500"]}
              data={affinityRatioAgeBarData}
              enableGridY={true}
              enableLabel={false}
              groupMode="stacked"
              height={height}
              indexBy="age"
              key="age"
              layout="vertical"
              margin={{
                bottom: 64,
                left: 64,
                top: 8,
              }}
              padding={0.2}
              tooltipLabel={({ data }) => `${data.age}`}
              width={width}
            />
          )}
        </AutoSizer>
      ) : (
        <EmptyData
          icon={faChartSimple}
          secondaryText="No chart data available"
        />
      )}
    </Box>
  );
};

AffinityRatioAgeBarChart.displayName = "AffinityRatioAgeBarChart";

export default memo(AffinityRatioAgeBarChart);
