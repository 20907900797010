import {
  type ModelledSegmentsCollection,
  type OverlapSegmentsCollection,
} from "wrappers/ApolloWrapper/resolvers/LookalikeMediaMutations";

export const filterNullableOverlapInsightsRows = <
  T extends OverlapSegmentsCollection | ModelledSegmentsCollection,
>(
  lookalikeMediaOverlapInsights: T
): T["audiences"] => {
  const overlapInsights = (lookalikeMediaOverlapInsights?.audiences || []).map(
    ({ aggregations = [], ...restAudience }) => ({
      ...restAudience,
      aggregations: aggregations.map(
        ({ columns = [], rows = [], ...restAggregation }) => {
          const affinityRatioDataIndex = columns.indexOf("affinity_ratio");
          const filteredRows = rows.filter(
            (row) =>
              ![null, undefined, "N/A"].includes(row[affinityRatioDataIndex])
          );
          return {
            ...restAggregation,
            columns,
            rows: filteredRows,
          };
        }
      ),
    })
  );
  return overlapInsights || [];
};
