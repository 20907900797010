import { useUserDetailsQuery } from "@decentriq/graphql/dist/hooks";
import { UserRole } from "@decentriq/graphql/dist/types";
import { faUndo } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@mui/joy";
import { useBoolean } from "ahooks";
import { memo } from "react";
import { useUserRole } from "hooks";
import { default as RollbackMigrationDialog } from "../RollbackMigrationDialog/RollbackMigrationDialog";

interface RollbackMigrationButtonProps {
  userId: string;
  onComplete: () => void;
}

const RollbackMigrationButton: React.FC<RollbackMigrationButtonProps> = ({
  userId,
  onComplete,
}) => {
  const { data: { user } = {} } = useUserDetailsQuery({
    variables: { userId },
  });

  const { isOrganizationAdmin, isSuperAdmin } = useUserRole();

  const [
    isUserDeleteDialogOpen,
    { setTrue: openUserDeleteDialog, setFalse: closeUserDeleteDialog },
  ] = useBoolean(false);

  // User with lower role than SUPER_ADMIN cannot change role for super admin
  const isDeleteUserButtonDisabled =
    user?.userRole === UserRole.SuperAdmin && !isSuperAdmin;

  return isSuperAdmin || isOrganizationAdmin ? (
    <>
      <Button
        color="danger"
        disabled={isDeleteUserButtonDisabled}
        onClick={(event) => {
          event.stopPropagation();
          openUserDeleteDialog();
        }}
        startDecorator={<FontAwesomeIcon fixedWidth={true} icon={faUndo} />}
        sx={{ marginTop: 1, width: "fit-content" }}
      >
        Roll back migration
      </Button>
      <RollbackMigrationDialog
        onCancel={closeUserDeleteDialog}
        onComplete={onComplete}
        open={isUserDeleteDialogOpen}
        userId={userId}
      />
    </>
  ) : null;
};

RollbackMigrationButton.displayName = "RollbackMigrationButton";

export default memo(RollbackMigrationButton);
