import { type CollaboratingOrganizationFilter } from "@decentriq/graphql/dist/types";
import { faInfoCircle } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Checkbox, Tooltip } from "@mui/joy";
import { Fragment, memo, useCallback } from "react";

type ShowOwnedByOrganizationCheckboxProps = {
  setQueryFilter: (filter: CollaboratingOrganizationFilter) => void;
  checked: boolean;
};

const ShowOwnedByOrganizationCheckbox: React.FC<
  ShowOwnedByOrganizationCheckboxProps
> = ({ setQueryFilter, checked }) => {
  const handleOwnedByOrganizationChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      // If checkbox is not checked, value should be passed as null, not false, for proper logic
      setQueryFilter({ isOwnedByOrganization: event.target.checked || null });
    },
    [setQueryFilter]
  );
  return (
    <Checkbox
      checked={checked}
      label={
        <Fragment>
          Only DCRs created by my organization
          <span style={{ verticalAlign: "middle", whiteSpace: "nowrap" }}>
            &nbsp;
            <Tooltip
              title={
                <span>
                  If checked, this applies two filters:
                  <br />
                  #1 This only counts organisations from data clean rooms
                  created by someone from your organisation.
                  <br />
                  #2 This only counts data clean rooms which have been
                  activated.
                </span>
              }
            >
              <FontAwesomeIcon icon={faInfoCircle} />
            </Tooltip>
          </span>
        </Fragment>
      }
      onChange={handleOwnedByOrganizationChange}
      slotProps={{
        label: {
          sx: { cursor: "pointer", userSelect: "none", zIndex: 1 },
        },
      }}
    />
  );
};
ShowOwnedByOrganizationCheckbox.displayName = "ShowOwnedByOrganizationCheckbox";

export default memo(ShowOwnedByOrganizationCheckbox);
