import { FormControl, FormHelperText, FormLabel, Input } from "@mui/joy";
import { memo } from "react";
import { Controller, useFormContext } from "react-hook-form";

interface ControlledInputProps {
  label: React.ReactNode;
  name: string;
  placeholder: string;
  required?: boolean | undefined;
}

const ControlledInput: React.FC<ControlledInputProps> = memo(
  ({ label, name, placeholder, required = true }) => {
    const { control } = useFormContext();
    return (
      <Controller
        control={control}
        name={name}
        render={({ field, fieldState }) => {
          const { error } = fieldState;
          return (
            <FormControl
              disabled={field.disabled}
              error={Boolean(error)}
              required={required}
            >
              <FormLabel>{label}</FormLabel>
              <Input placeholder={placeholder} {...field} />
              <FormHelperText>{error?.message}</FormHelperText>
            </FormControl>
          );
        }}
      />
    );
  }
);
ControlledInput.displayName = "ControlledInput";

export default ControlledInput;
