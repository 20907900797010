import {
  useAdvertiserMarketFiltersQuery,
  useAvailableDataPartnersQuery,
  useUpdateAdvertiserMarketFiltersMutation,
} from "@decentriq/graphql/dist/hooks";
import {
  type AvailableDataPartner,
  type AvailableDataPartnerFragment,
} from "@decentriq/graphql/dist/types";
import { useUpdateEffect } from "ahooks";
import {
  createContext,
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { type ActiveMarket } from "features/mediaPortalShared";
import {
  MediaDataRoomCreationStep,
  MediaDataRoomOrganizationRole,
  useCreationWizardStepper,
} from "./CreationWIzardStepperContext";

interface CreationWizardDataPartnerContextValue {
  advertiserMarketFilters: (ActiveMarket & { selected: boolean })[];
  availableDataPartners: AvailableDataPartnerFragment[];
  isAvailableDataPartnersLoading: boolean;
  updateAdvertiserMarketFilters: (marketIds: string[]) => void;
  selectedDataPartner: AvailableDataPartnerFragment | null;
  selectDataPartner: (dataPartner: AvailableDataPartnerFragment | null) => void;
  inviteDataPartner: () => void;
  hasSelectedDataPartner: boolean;
  hasSkippedSelection: boolean;
}

const CreationWizardDataPartnerContext =
  createContext<CreationWizardDataPartnerContextValue | null>(null);

const CreationWizardDataPartnerContextProvider =
  CreationWizardDataPartnerContext.Provider;

export const useCreationWizardDataPartner = () => {
  const contextValue = useContext(CreationWizardDataPartnerContext);
  if (contextValue === null) {
    throw new Error(
      "useCreationWizardDataPartner must be used within a CreationWizardDataPartnerWrapper"
    );
  }
  return contextValue;
};

const CreationWizardDataPartnerWrapper = memo<React.PropsWithChildren>(
  ({ children }) => {
    const {
      organizationRole,
      activeStep,
      hasOwnDataToProvide,
      canSelectDataPartner,
    } = useCreationWizardStepper();
    const [selectedDataPartner, setSelectedDataPartner] =
      useState<AvailableDataPartnerFragment | null>(null);
    const [hasSkippedSelection, setHasSkippedSelection] =
      useState<boolean>(false);
    const hasSelectedDataPartner = Boolean(selectedDataPartner);
    // Fetch Available Publishers data
    const [updateAdvertiserMarketFiltersMutation] =
      useUpdateAdvertiserMarketFiltersMutation();
    const { data: advertiserMarketFiltersData } =
      useAdvertiserMarketFiltersQuery();
    const updateAdvertiserMarketFilters = useCallback(
      (marketIds: string[]) => {
        updateAdvertiserMarketFiltersMutation({
          optimisticResponse: {
            availablePublisher: {
              updateMarketFilters: marketIds,
            },
          },
          update: (cache, { data }) => {
            cache.modify({
              fields: {
                advertiserMarketFilters: () =>
                  data?.availablePublisher.updateMarketFilters || [],
              },
            });
          },
          variables: {
            input: marketIds,
          },
        });
      },
      [updateAdvertiserMarketFiltersMutation]
    );
    const {
      data: availableDataPartnersQueryData,
      loading: isAvailableDataPartnersLoading,
    } = useAvailableDataPartnersQuery();
    const allAvailableDataPartners = useMemo<AvailableDataPartner[]>(
      () => availableDataPartnersQueryData?.availableDataPartners?.nodes || [],
      [availableDataPartnersQueryData?.availableDataPartners?.nodes]
    );
    const advertiserMarketFilters = useMemo(() => {
      const dataPartnerMarkets =
        advertiserMarketFiltersData?.publisherMarkets?.nodes || [];
      const activeFilters =
        advertiserMarketFiltersData?.advertiserMarketFilters || [];
      return dataPartnerMarkets
        .filter(({ id }) =>
          allAvailableDataPartners.some(({ marketIds }) =>
            marketIds.includes(id)
          )
        )
        .map((market) => ({
          ...market,
          selected: activeFilters.includes(market.id),
        }));
    }, [advertiserMarketFiltersData, allAvailableDataPartners]);
    const filteredAvailableDataPartners = useMemo<
      AvailableDataPartner[]
    >(() => {
      const selectedMarketFilters = advertiserMarketFilters.filter(
        ({ selected }) => selected
      );
      const hasAnyFilterSelected = selectedMarketFilters.length > 0;
      if (!hasAnyFilterSelected) {
        return allAvailableDataPartners;
      }
      return allAvailableDataPartners.filter(({ marketIds }) =>
        selectedMarketFilters.some(({ id }) => marketIds.includes(id))
      );
    }, [allAvailableDataPartners, advertiserMarketFilters]);
    const inviteDataPartner = useCallback(
      () => setHasSkippedSelection(true),
      [setHasSkippedSelection]
    );
    const shouldKeepSelectedDataPartner =
      [
        MediaDataRoomCreationStep.COLLABORATION_REQUEST_TO_DATA_PARTNER,
        MediaDataRoomCreationStep.SELECT_DATA_PARTNER,
        MediaDataRoomCreationStep.COLLABORATION_CONFIGURATION,
      ].includes(activeStep) &&
      organizationRole === MediaDataRoomOrganizationRole.ADVERTISER;
    useEffect(() => {
      if (
        !filteredAvailableDataPartners.length ||
        !shouldKeepSelectedDataPartner ||
        hasSkippedSelection
      ) {
        setSelectedDataPartner(null);
        return;
      }
      setSelectedDataPartner((currentSelectedDataPartner) => {
        if (
          filteredAvailableDataPartners.some(
            ({ id }) => id === currentSelectedDataPartner?.id
          )
        ) {
          return currentSelectedDataPartner;
        }
        return filteredAvailableDataPartners[0];
      });
    }, [
      setSelectedDataPartner,
      organizationRole,
      hasSkippedSelection,
      shouldKeepSelectedDataPartner,
      filteredAvailableDataPartners,
    ]);
    const isSelectionStep =
      activeStep === MediaDataRoomCreationStep.SELECT_DATA_PARTNER;
    useUpdateEffect(() => {
      if (isSelectionStep) {
        setHasSkippedSelection(false);
      }
    }, [isSelectionStep, setHasSkippedSelection]);
    useEffect(() => {
      setHasSkippedSelection(!canSelectDataPartner || hasOwnDataToProvide);
    }, [setHasSkippedSelection, canSelectDataPartner, hasOwnDataToProvide]);
    const contextValue = useMemo<CreationWizardDataPartnerContextValue>(
      () => ({
        advertiserMarketFilters,
        availableDataPartners: filteredAvailableDataPartners,
        hasSelectedDataPartner,
        hasSkippedSelection,
        inviteDataPartner,
        isAvailableDataPartnersLoading,
        selectDataPartner: setSelectedDataPartner,
        selectedDataPartner,
        updateAdvertiserMarketFilters,
      }),
      [
        advertiserMarketFilters,
        filteredAvailableDataPartners,
        isAvailableDataPartnersLoading,
        selectedDataPartner,
        hasSelectedDataPartner,
        inviteDataPartner,
        setSelectedDataPartner,
        hasSkippedSelection,
        updateAdvertiserMarketFilters,
      ]
    );
    return (
      <CreationWizardDataPartnerContextProvider value={contextValue}>
        {children}
      </CreationWizardDataPartnerContextProvider>
    );
  }
);

CreationWizardDataPartnerWrapper.displayName =
  "CreationWizardDataPartnerWrapper";

export default CreationWizardDataPartnerWrapper;
