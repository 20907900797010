import { InfoTooltip } from "@decentriq/components";
import { Box, Checkbox } from "@mui/joy";
import { memo, useCallback, useMemo, useState } from "react";
import { ErrorReportDialog } from "components";
import { CommonSnackbarOrigin, type ReportErrorInput } from "hooks";
import { type DataLabErrorReportContextValue } from "./DataLabErrorReportProvider";

interface DataLabErrorReportDialogProps extends DataLabErrorReportContextValue {
  open: boolean;
  dataLabId: string;
  onCancel: () => void;
  onFinish: () => void;
  error: string;
  details: string;
}

const DataLabErrorReportDialog: React.FC<DataLabErrorReportDialogProps> = ({
  open,
  dataLabId,
  onCancel,
  onFinish,
  error,
  details,
  getConfiguration,
}) => {
  const [shouldReportConfiguration, setShouldReportConfiguration] =
    useState<boolean>(true);
  const defaultDetails = useMemo(
    () =>
      `Datalab ID: ${dataLabId}\nError message: ${error}\nError details: ${details}`,
    [dataLabId, error, details]
  );
  const handleGenerateReport = useCallback(
    async (details: string) => {
      const report: ReportErrorInput = {
        details: details || "",
        errorContext: [
          {
            content: dataLabId,
            name: "dataLabId",
          },
        ],
        origin: CommonSnackbarOrigin.DATA_LAB,
      };
      let configuration: string | undefined;
      if (shouldReportConfiguration) {
        configuration = await getConfiguration();
        report.errorContext.push({
          content: configuration,
          name: "configuration",
        });
      }
      return report;
    },
    [dataLabId, getConfiguration, shouldReportConfiguration]
  );
  return (
    <ErrorReportDialog
      defaultDetails={defaultDetails}
      generateReport={handleGenerateReport}
      header={
        <Box
          sx={{
            display: "inline-flex",
            marginLeft: "1.5rem",
            marginTop: "1.25rem",
          }}
        >
          <Checkbox
            checked={shouldReportConfiguration}
            label={
              <>
                Send datalab details
                <InfoTooltip
                  tooltip={`This will attach the same content as when clicking the “…” top-right menu in the datalab and choosing the "Details" option.`}
                />
              </>
            }
            onChange={(event) =>
              setShouldReportConfiguration(event.target.checked)
            }
            sx={{ display: "flex", margin: 0 }}
          />
        </Box>
      }
      onCancel={onCancel}
      onFinish={onFinish}
      open={open}
    />
  );
};

DataLabErrorReportDialog.displayName = "DataLabErrorReportDialog";

export default memo(DataLabErrorReportDialog);
