import { memo } from "react";
import {
  MediaDataRoomInsightsTable,
  useOverlapInsightsAggregation,
} from "features/mediaDataRoom";

const MediaDataRoomInsightsExplorerTable: React.FC = () => {
  const { aggregationData: tableData, isEmptyAggregation } =
    useOverlapInsightsAggregation();
  return (
    <MediaDataRoomInsightsTable
      isEmpty={isEmptyAggregation}
      tableData={tableData}
    />
  );
};

MediaDataRoomInsightsExplorerTable.displayName =
  "MediaDataRoomInsightsExplorerTable";

export default memo(MediaDataRoomInsightsExplorerTable);
