import { useDataConnectorJobQuery } from "@decentriq/graphql/dist/hooks";

type ConnectionTooltipData = {
  bucket: string | undefined;
  objectKey: string | undefined;
  region: string | undefined;
};

const useAdFormConnectionConfigurationData = (
  connectionId: string
): ConnectionTooltipData => {
  const { data: { dataConnectorJob } = {} } = useDataConnectorJobQuery({
    skip: !connectionId,
    variables: { id: connectionId },
  });

  const config = dataConnectorJob?.config?.aws?.targetConfig || {};

  return {
    bucket: config?.bucket,
    objectKey: config?.key,
    region: config?.region,
  };
};

export default useAdFormConnectionConfigurationData;
