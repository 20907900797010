import { createContext, useContext } from "react";

export interface DatasetDetailsConfigContextValue {
  disableDeprovision?: boolean;
  disableProvisionedDataRoomNavigation?: boolean;
}

export const DatasetDetailsConfigContext =
  createContext<DatasetDetailsConfigContextValue>({
    disableDeprovision: false,
    disableProvisionedDataRoomNavigation: false,
  });

export const DatasetDetailsConfigProvider =
  DatasetDetailsConfigContext.Provider;

export const useDatasetDetailsConfig = () =>
  useContext(DatasetDetailsConfigContext);
