import { data_connector_job, export_dataset } from "@decentriq/core";
import {
  type CreateDatasetExportPayload,
  DataConnectorJobDocument,
  DataConnectorJobFragment,
  type DataConnectorJobQuery,
  type DataConnectorJobQueryVariables,
  type DataConnectorJobResult,
  DataConnectorJobResultFragment,
  DataConnectorJobsDocument,
  type DataConnectorJobsQuery,
  type DataConnectorJobsQueryVariables,
  type DataImportExportStatus,
  type MutationCreateDatasetExportArgs,
  type MutationPollDatasetExportArgs,
} from "@decentriq/graphql/dist/types";
import { Key } from "@decentriq/utils";
import * as forge from "node-forge";
import {
  type Keychain,
  type KeychainItem,
  KeychainItemKind,
} from "services/keychain";
import { type ApiCoreContextValue } from "contexts";
import { getLatestEnclaveSpecsPerType } from "utils/apicore";
import { type LocalResolverContext } from "../../../models";

export const makeCreateDatasetExportResolver =
  (
    client: ApiCoreContextValue["client"],
    sessionManager: ApiCoreContextValue["sessionManager"],
    getKeychain: () => Keychain
  ) =>
  async (
    _obj: null,
    args: MutationCreateDatasetExportArgs,
    context: LocalResolverContext,
    _info: any
  ): Promise<CreateDatasetExportPayload> => {
    const {
      dv360,
      s3,
      meta,
      gcs,
      googleAdManager,
      azure,
      permutive,
      microsoftDsp,
      adformDsp,
      splicky,
      manifestHash,
    } = args.input;
    let encryptionKeyPayload: KeychainItem = await getKeychain()
      .getItem(manifestHash, KeychainItemKind.Dataset)
      .catch((error) => {
        throw new Error(
          `No encryption key in keychain for dataset with manifest hash '${manifestHash}'`
        );
      });
    const encryptionKey = new Key(
      forge.util.binary.hex.decode(encryptionKeyPayload.value)
    );
    let enclaveSpecifications;
    let enclaveSpecs;

    try {
      enclaveSpecifications = await client.getEnclaveSpecifications();
      enclaveSpecs = getLatestEnclaveSpecsPerType(enclaveSpecifications);
    } catch (error) {
      throw new Error(error as string);
    }

    let datasetExportId;

    if (s3 !== undefined) {
      const { credentials, targetConfig } = s3!;
      const session = await sessionManager.get();
      datasetExportId = await export_dataset.exportDatasetS3(
        client,
        session,
        enclaveSpecs,
        {
          credentials,
          encryptionKey,
          manifestHash,
          targetConfig: {
            bucket: targetConfig.bucket,
            key: targetConfig.objectKey,
            region: targetConfig.region,
          },
        }
      );
    } else if (meta !== undefined) {
      const { accessToken, adAccountId, audienceName } = meta!;
      const session = await sessionManager.get();
      datasetExportId = await export_dataset.exportDatasetMeta(
        client,
        session,
        enclaveSpecs,
        {
          accessToken,
          adAccountId,
          audienceName,
          encryptionKey,
          manifestHash,
        }
      );
    } else if (dv360 !== undefined) {
      const {
        advertiserId,
        displayName,
        description,
        membershipDurationDays,
        credentials,
      } = dv360!;
      const session = await sessionManager.get();
      datasetExportId = await export_dataset.exportDatasetDv360(
        client,
        session,
        enclaveSpecs,
        {
          advertiserId,
          credentials,
          description,
          displayName,
          encryptionKey,
          manifestHash,
          membershipDurationDays,
        }
      );
    } else if (azure !== undefined) {
      const { credentials } = azure!;
      const session = await sessionManager.get();
      datasetExportId = await export_dataset.exportDatasetAzure(
        client,
        session,
        enclaveSpecs,
        {
          credentials,
          encryptionKey,
          manifestHash,
        }
      );
    } else if (googleAdManager !== undefined) {
      const {
        credentials,
        identifierKind,
        listId,
        inputHasHeader,
        bucket,
        objectName,
      } = googleAdManager!;
      const session = await sessionManager.get();
      datasetExportId = await export_dataset.exportDatasetGoogleAdManager(
        client,
        session,
        enclaveSpecs,
        {
          bucket,
          credentials,
          encryptionKey,
          identifierKind,
          inputHasHeader,
          listId,
          manifestHash,
          objectName,
        }
      );
    } else if (gcs !== undefined) {
      const { credentials, bucketName, objectName } = gcs!;
      const session = await sessionManager.get();
      datasetExportId = await export_dataset.exportDatasetGCS(
        client,
        session,
        enclaveSpecs,
        {
          bucketName,
          credentials,
          encryptionKey,
          manifestHash,
          objectName,
        }
      );
    } else if (permutive !== undefined) {
      const {
        credentials,
        importId,
        segmentName,
        segmentCode,
        aws,
        gcs,
        inputHasHeader,
      } = permutive!;
      const session = await sessionManager.get();
      datasetExportId = await export_dataset.exportDatasetPermutive(
        client,
        session,
        enclaveSpecs,
        {
          aws:
            aws !== undefined
              ? {
                  credentials: aws!.credentials,
                  targetConfig: {
                    bucket: aws!.targetConfig.bucket,
                    key: aws!.targetConfig.objectKey,
                    region: aws!.targetConfig.region,
                  },
                }
              : undefined,
          credentials,
          encryptionKey,
          gcs: gcs ?? undefined,
          importId,
          inputHasHeader,
          manifestHash,
          segmentCode,
          segmentName,
        }
      );
    } else if (microsoftDsp !== undefined) {
      const { memberId, segmentShortName, segmentCode } = microsoftDsp!;
      const session = await sessionManager.get();
      datasetExportId = await export_dataset.exportDatasetMicrosoftDsp(
        client,
        session,
        enclaveSpecs,
        {
          encryptionKey,
          manifestHash,
          memberId,
          segmentCode,
          segmentShortName,
        }
      );
    } else if (adformDsp !== undefined) {
      const { aws, segmentOwners } = adformDsp!;
      const session = await sessionManager.get();
      datasetExportId = await export_dataset.exportDatasetAdformDsp(
        client,
        session,
        enclaveSpecs,
        {
          aws: {
            credentials: aws!.credentials,
            targetConfig: {
              bucket: aws!.targetConfig.bucket,
              key: aws!.targetConfig.objectKey,
              region: aws!.targetConfig.region,
            },
          },
          encryptionKey,
          manifestHash,
          segmentOwners,
        }
      );
    } else if (splicky !== undefined) {
      const { seatId, userDefinedAudienceName } = splicky!;
      const session = await sessionManager.get();
      datasetExportId = await export_dataset.exportDatasetSplicky(
        client,
        session,
        enclaveSpecs,
        {
          encryptionKey,
          manifestHash,
          seatId,
          userDefinedAudienceName,
        }
      );
    } else {
      throw new Error("No target type configured");
    }

    const response = await context.client.query<
      DataConnectorJobQuery,
      DataConnectorJobQueryVariables
    >({
      query: DataConnectorJobDocument,
      variables: {
        id: datasetExportId,
      },
    });
    const newDatasetExport = response.data.dataConnectorJob;

    const exportsQuery = context.cache.readQuery<
      DataConnectorJobsQuery,
      DataConnectorJobsQueryVariables
    >({
      query: DataConnectorJobsDocument,
      variables: { filter: null },
    });

    const currentExports = exportsQuery
      ? exportsQuery!.dataConnectorJobs?.nodes
      : [];
    context.cache.writeQuery<
      DataConnectorJobsQuery,
      DataConnectorJobsQueryVariables
    >({
      data: {
        dataConnectorJobs: {
          __typename: "DataConnectorJobCollection",
          nodes: [newDatasetExport, ...currentExports],
        },
      },
      query: DataConnectorJobsDocument,
      variables: {
        filter: null,
      },
    });

    context.cache.modify({
      fields: {
        dataConnectorJobs: (existing = {}) => {
          const datasetExportRef = context.cache.writeFragment({
            data: newDatasetExport,
            fragment: DataConnectorJobFragment,
          });
          return {
            ...existing,
            nodes: [datasetExportRef, ...(existing?.nodes || [])],
          };
        },
      },
    });

    return {
      dataConnectorJob: { ...newDatasetExport },
    };
  };

export const makePollDatasetExportResolver =
  (
    client: ApiCoreContextValue["client"],
    _sessionManager: ApiCoreContextValue["sessionManager"]
  ) =>
  async (
    _obj: null,
    args: MutationPollDatasetExportArgs,
    context: LocalResolverContext,
    _info: any
  ): Promise<DataConnectorJobResult> => {
    const datasetExportId = args.id;

    const enclaveSpecifications = await client.getEnclaveSpecifications();

    let error: string | null = null;
    let success = false;

    try {
      await data_connector_job.getDataConnectorJobResult({
        client,
        dataConnectorJobId: datasetExportId,
        options: { additionalEnclaveSpecs: enclaveSpecifications },
      });
      success = true;
    } catch (e) {
      success = false;
      error = e.message;
    }

    // Fetch finishedAt value
    const response = await context.client.query<
      DataConnectorJobQuery,
      DataConnectorJobQueryVariables
    >({
      fetchPolicy: "network-only",
      query: DataConnectorJobDocument,
      variables: {
        id: datasetExportId,
      },
    });

    const { finishedAt } = response?.data?.dataConnectorJob || {};
    const result = { error, success };

    // Update cached DatasetExport with result and status field
    context.client.writeFragment({
      data: {
        finishedAt,
        id: datasetExportId,
        result,
        status: (result.success
          ? "SUCCESS"
          : "FAILED") as DataImportExportStatus,
      },
      fragment: DataConnectorJobResultFragment,
      id: context.cache.identify({
        __typename: "DataConnectorJob",
        id: datasetExportId,
      }),
    });

    return result;
  };
