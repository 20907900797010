import { useAuth0 } from "@auth0/auth0-react";
import { testIds } from "@decentriq/utils";
import {
  faCircleInfo,
  faEye,
  faEyeSlash,
  faKey,
  faLockKeyholeOpen,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Accordion,
  AccordionDetails,
  AccordionGroup,
  AccordionSummary,
  Alert,
  Avatar,
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  IconButton,
  Input,
  Link,
  ListItemContent,
  Modal,
  ModalDialog,
  Stack,
  Typography,
} from "@mui/joy";
import { memo, useCallback, useEffect, useState } from "react";
import * as yup from "yup";
import { useDocsLink, useSignOut } from "hooks";
import { useKeychainSetup } from "wrappers";

const validationSchema = yup.object({
  password: yup.string().trim().required().defined(),
});

type UnlockKeychainFormValues = yup.InferType<typeof validationSchema>;

interface UnlockKeychainProps {
  onForgotPassword: () => void;
  open: boolean;
}

const UnlockKeychain: React.FC<UnlockKeychainProps> = ({
  onForgotPassword,
  open,
}) => {
  const [unlocking, setUnlocking] = useState(false);
  const { unlockKeychain } = useKeychainSetup();
  const { user } = useAuth0();
  const [password, setPassword] = useState<string | undefined>();
  const [showPassword, setShowPassword] = useState(false);
  const toggleShowPassword = () => setShowPassword(!showPassword);
  const [validationErrors, setValidationErrors] = useState<
    string[] | undefined
  >(undefined);
  const isValid = validationErrors === undefined;
  const validate = useCallback(
    async (values: UnlockKeychainFormValues): Promise<boolean> => {
      try {
        await validationSchema.validate(values);
        setValidationErrors(undefined);
        return true;
      } catch (error) {
        setValidationErrors((error as yup.ValidationError).errors);
        return false;
      }
    },
    [setValidationErrors]
  );
  const onSignOut = useSignOut();
  const docsBaseLink = useDocsLink();
  useEffect(() => {
    if (password === undefined) {
      return;
    }
    validate({
      password,
    });
  }, [password, validate]);
  const handleSubmit = useCallback(
    async (password: string | undefined) => {
      setUnlocking(true);
      const isValid = await validate({
        password: password || "",
      });
      if (isValid) {
        await unlockKeychain(password!.trim());
      }
      setUnlocking(false);
    },
    [unlockKeychain, setUnlocking, validate]
  );
  return (
    <Modal open={open}>
      <ModalDialog maxWidth={500}>
        <DialogTitle>
          <span>
            <FontAwesomeIcon fixedWidth={true} icon={faKey} /> Keychain
          </span>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Stack>
            <Typography>
              To interact with the platform as <strong>{user?.email}</strong>{" "}
              your Keychain must be activated.
            </Typography>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit(password);
              }}
            >
              <FormControl error={!isValid}>
                <Input
                  autoComplete="new-password"
                  endDecorator={
                    <IconButton onClick={toggleShowPassword}>
                      {showPassword ? (
                        <FontAwesomeIcon fixedWidth={true} icon={faEyeSlash} />
                      ) : (
                        <FontAwesomeIcon fixedWidth={true} icon={faEye} />
                      )}
                    </IconButton>
                  }
                  onChange={(event) => setPassword(event.target.value)}
                  placeholder="Keychain password"
                  slotProps={{
                    input: {
                      "data-testid":
                        testIds.keychain.unlockKeychain.keychainInput,
                    },
                  }}
                  type={showPassword ? "text" : "password"}
                  value={password || ""}
                />
              </FormControl>
            </form>
            {!isValid ? (
              <Alert color="danger">
                <Box>
                  <Box>
                    <strong>Invalid password:</strong>
                  </Box>
                  <ul style={{ margin: 0, paddingInlineStart: "1rem" }}>
                    {validationErrors?.map((validationError, index) => (
                      <li key={index}>{validationError}</li>
                    ))}
                  </ul>
                </Box>
              </Alert>
            ) : null}
          </Stack>
        </DialogContent>
        <Divider />
        <AccordionGroup
          sx={{
            "--ListItem-paddingX": "1rem",
            marginInline: "calc(-1 * var(--Card-padding))",
          }}
          variant="plain"
        >
          <Accordion>
            <AccordionSummary sx={{ gap: "0.5rem" }}>
              <Avatar
                color="secondary"
                sx={{ "--Avatar-size": "24px", fontSize: "1rem" }}
              >
                <FontAwesomeIcon icon={faCircleInfo} />
              </Avatar>
              <ListItemContent>How does it work?</ListItemContent>
            </AccordionSummary>
            <AccordionDetails>
              <Stack spacing={0.5}>
                <Typography>
                  The Keychain operates like a traditional password manager. It
                  derives an encryption key from your password. This key is used
                  to locally encrypt the secrets (e.g. dataset encryption keys)
                  you want to store in the Keychain.
                </Typography>
                <Typography>
                  The encrypted secrets are then stored on the Decentriq
                  Platform. They are retrieved when needed and the same
                  password-derived encryption key is used to decrypt them.
                </Typography>
                <Typography>
                  As Decentriq does not have access to your password, Decentriq
                  can never access the secrets stored in the Keychain.
                </Typography>
                <Typography>
                  If you lose your password, you will have to reset it and lose
                  access to all previously stored secrets.
                </Typography>
                <Typography>
                  For more information, please{" "}
                  <Link
                    href={`${docsBaseLink}/welcome-to-decentriq#setting-up-your-keychain`}
                    rel="noreferrer"
                    target="_blank"
                  >
                    check the documentation
                  </Link>
                  .
                </Typography>
              </Stack>
            </AccordionDetails>
          </Accordion>
        </AccordionGroup>
        <Divider />
        <DialogActions>
          <Box sx={{ display: "flex", gap: 1 }}>
            <Button disabled={unlocking} onClick={onSignOut}>
              Sign out
            </Button>
            <Button disabled={unlocking} onClick={onForgotPassword}>
              Forgot password
            </Button>
          </Box>
          <Button
            color="primary"
            data-testid={testIds.keychain.unlockKeychain.submitButton}
            loading={unlocking}
            loadingPosition="start"
            onClick={() => handleSubmit(password)}
            startDecorator={<FontAwesomeIcon icon={faLockKeyholeOpen} />}
            variant="solid"
          >
            Activate Keychain
          </Button>
        </DialogActions>
      </ModalDialog>
    </Modal>
  );
};

export default memo(UnlockKeychain);
