import { type Theme } from "@mui/material";
import { forwardRef } from "react";
import { makeStyles } from "tss-react/mui";

interface LabelProps {
  label: string;
  prefix?: React.ReactNode;
  suffix?: React.ReactNode;
}

const useLabelStyles = makeStyles()((theme: Theme) => ({
  wrapper: {
    "&:not(:only-child)": {
      marginRight: theme.spacing(0.5),
    },
    border: "1px solid",
    borderRadius: "2px",
    color: theme.palette.text.secondary,
    cursor: "default",
    fontSize: theme.typography.body2.fontSize,
    lineHeight: 1,
    padding: "0.111em 0.222em",
    position: "relative",
  },
}));

const Label = forwardRef<HTMLDivElement, LabelProps>(
  ({ label, prefix, suffix, ...rest }, ref) => {
    const { classes: labelClasses } = useLabelStyles();
    const { wrapper } = labelClasses;
    return (
      <div ref={ref} {...rest} className={wrapper}>
        {prefix}
        {label}
        {suffix}
      </div>
    );
  }
);

export default Label;
