import { CircularProgress } from "@mui/joy";
import { useMount } from "ahooks";
import isNil from "lodash/isNil";
import { memo } from "react";
import { type AudienceKind } from "features/mediaDataRoom/models";
import { formatNumber } from "features/mediaDataRoom/utils";
import { useAudiences } from "features/mediaDataRoom/wrappers";

interface AudienceSizeProps {
  id: string;
  kind: AudienceKind;
  audienceSize: unknown;
}

const AudienceSize: React.FC<AudienceSizeProps> = memo(
  ({ id, kind, audienceSize }) => {
    const {
      audienceSizes: { enableSizeEstimationForAudience, audienceSizes },
    } = useAudiences();
    useMount(() => {
      if (kind !== "advertiser") {
        enableSizeEstimationForAudience(id);
      }
    });
    if (kind === "advertiser") {
      return formatNumber(audienceSize as number);
    }
    const computing = audienceSizes[id]?.loading;
    if (computing) {
      return <CircularProgress sx={{ "--CircularProgress-size": "14px" }} />;
    }
    if (!isNil(audienceSizes[id]?.audienceSize)) {
      return formatNumber(audienceSizes[id]!.audienceSize!);
    }
    return "Computation should be started";
  }
);

AudienceSize.displayName = "AudienceSize";

export default AudienceSize;
