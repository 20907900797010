import { InfoTooltip } from "@decentriq/components";
import { Typography } from "@mui/joy";
import { memo } from "react";

interface SuppressedSegmentsInformationProps {
  possibleValues: string[];
  suppressedValues: string[];
  hiddenValues: (string | undefined)[];
}

const SuppressedSegmentsInformation = memo<SuppressedSegmentsInformationProps>(
  ({ possibleValues, suppressedValues, hiddenValues }) => {
    return (
      <Typography fontSize={12} level="body-sm">
        Showing{" "}
        {possibleValues.length - hiddenValues.length - suppressedValues.length}{" "}
        of {possibleValues.length} values
        <InfoTooltip
          size="sm"
          tooltip={
            <>
              {suppressedValues.length ? (
                <Typography level="body-sm" textColor="common.white">
                  The following values have been suppressed for privacy. There
                  were too few users in the overlap.
                  {suppressedValues.map((value) => (
                    <li style={{ marginLeft: 16 }}>"{value}"</li>
                  ))}
                </Typography>
              ) : null}
              {hiddenValues.length ? (
                <Typography
                  level="body-sm"
                  mt={suppressedValues.length ? 1 : 0}
                  textColor="common.white"
                >
                  The following values are not shown because of the chart size.
                  {hiddenValues.map((value, index) => (
                    <li key={`${index}-${value}`} style={{ marginLeft: 16 }}>
                      "{value}"
                    </li>
                  ))}
                </Typography>
              ) : null}
            </>
          }
          top="2px"
        />
      </Typography>
    );
  }
);

SuppressedSegmentsInformation.displayName = "SuppressedSegmentsInformation";

export default SuppressedSegmentsInformation;
