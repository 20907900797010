import { usePublishedDataRoomDuplicateMutation } from "@decentriq/graphql/dist/hooks";
import set from "lodash/set";
import { useState } from "react";
import { useApiCore } from "contexts";
import { exportDataScienceDataRoom, getDcrProperties } from "utils/apicore";

interface usePublishedDataRoomDuplicateArgs {
  id: string;
  driverAttestationHash: string | undefined;
}

interface usePublishedDataRoomDuplicateResult {
  publishedDataRoomDuplicate: () => Promise<string | undefined>;
  publishedDataRoomDuplicateLoading: boolean;
}

const usePublishedDataRoomDuplicate = ({
  id: dataRoomId,
  driverAttestationHash,
}: usePublishedDataRoomDuplicateArgs): usePublishedDataRoomDuplicateResult => {
  const { sessionManager } = useApiCore();
  const [exporting, setExporting] = useState(false);
  const [publishedDataRoomDuplicateMutation, { loading: importing }] =
    usePublishedDataRoomDuplicateMutation();
  const publishedDataRoomDuplicate = async () => {
    setExporting(true);
    try {
      const sdkSession = await sessionManager.get({ driverAttestationHash });
      const publishedDataRoom =
        await sdkSession.retrieveDataScienceDataRoom(dataRoomId);
      if (!publishedDataRoom) {
        throw new Error(
          `Could not retrieve Data Science Data Room "${dataRoomId}"`
        );
      }
      const exportedDataScienceDataRoom = exportDataScienceDataRoom(
        sdkSession.compiler,
        publishedDataRoom
      );
      // Duplicated DCR must have "Copy of ..." prefix in the name
      const {
        version: dataRoomVersion,
        isInteractive: isDcrInteractive,
        title: dataRoomTitle,
      } = getDcrProperties(exportedDataScienceDataRoom.dataScienceDataRoom);
      set(
        exportedDataScienceDataRoom,
        !isDcrInteractive
          ? `dataScienceDataRoom.${dataRoomVersion}.static.title`
          : `dataScienceDataRoom.${dataRoomVersion}.interactive.initialConfiguration.title`,
        `Copy of ${dataRoomTitle}`
      );

      return publishedDataRoomDuplicateMutation({
        variables: { input: { export: exportedDataScienceDataRoom } },
      }).then(({ data }) => {
        const { draftDataRoom } = data || {};
        const { importDataScienceDataRoom } = draftDataRoom || {};
        const { record } = importDataScienceDataRoom || {};
        const { id } = record || {};
        return id;
      });
    } finally {
      setExporting(false);
    }
  };
  return {
    publishedDataRoomDuplicate,
    publishedDataRoomDuplicateLoading: exporting || importing,
  };
};

export default usePublishedDataRoomDuplicate;
