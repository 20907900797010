import {
  type AudienceAttributes,
  type AudienceRulesTree,
  type AudienceSeedData,
} from "@decentriq/components";

export type RulesBasedAudienceSeedOption = AudienceSeedData;
export type RulesBasedAudienceRulesTree = AudienceRulesTree;
export type RulesBasedAudienceDataAttributes = AudienceAttributes;

export const allPublisherUsersAudienceSeedOption: Pick<
  RulesBasedAudienceSeedOption,
  "id" | "name"
> = {
  id: "ALL_PUBLISHER_USERS",
  name: "All Publisher Users",
};

export interface RulesBasedAudienceDataAttributesFileStructure {
  attributes: RulesBasedAudienceDataAttributes;
}

export interface RulesBasedAudienceAudienceSizeFileStructure {
  audience_size: number;
}
