import { DqAudienceSummary } from "@decentriq/components";
import { memo, useMemo } from "react";
import { type RuleBasedAudience } from "features/mediaDataRoom/models";
import { mapRulesBasedAudiencetoAudienceRulesTree } from "features/mediaDataRoom/utils";

type AudienceDrawerRuleBasedDetailsProps = {
  seedAudienceName: string | null;
} & RuleBasedAudience;

const AudienceDrawerRuleBasedDetails: React.FC<AudienceDrawerRuleBasedDetailsProps> =
  memo((audience) => {
    const audienceTree = useMemo(() => {
      const { seedAudienceName, ...rest } = audience;
      return mapRulesBasedAudiencetoAudienceRulesTree(rest);
    }, [audience]);

    return <DqAudienceSummary audienceTree={audienceTree} />;
  });

AudienceDrawerRuleBasedDetails.displayName = "AudienceDrawerRuleBasedDetails";

export default AudienceDrawerRuleBasedDetails;
