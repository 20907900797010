import { ComputeNodeTypeNames, DataNodeTypeNames } from "models";

export const ScriptingNodeInputTypeNames = {
  ...ComputeNodeTypeNames,
  ...DataNodeTypeNames,
  DraftScript: "DraftScript",
  PublishedScript: "PublishedScript",
} as const;

export interface ScriptingInputNodeSnapshot {
  id: string;
  computeNodeId: string;
  name: string;
  label: string;
  path: string;
  typename: keyof typeof ScriptingNodeInputTypeNames;
  allowedForCurrent: boolean;
  airlockedDependencies?: ScriptingInputNodeSnapshot[];
  usedQuotaBytes?: number;
  remainingQuotaBytes?: number;
  totalQuotaBytes?: number;
}

export const scriptingNodeGroupLabelsMap: {
  [k in ScriptingInputNodeSnapshot["typename"]]: string;
} = {
  [ScriptingNodeInputTypeNames.DraftRawLeafNode]: "File",
  [ScriptingNodeInputTypeNames.DraftPostNode]: "Post",
  [ScriptingNodeInputTypeNames.DraftPreviewNode]: "Airlock",
  [ScriptingNodeInputTypeNames.DraftScriptingNode]: "Script",
  [ScriptingNodeInputTypeNames.DraftS3SinkNode]: "S3",
  [ScriptingNodeInputTypeNames.DraftSqlNode]: "Native-SQL",
  [ScriptingNodeInputTypeNames.DraftSqliteNode]: "SQL",
  [ScriptingNodeInputTypeNames.DraftMatchNode]: "Matching",
  [ScriptingNodeInputTypeNames.DraftScript]: "File",
  [ScriptingNodeInputTypeNames.PublishedScript]: "File",
  [ScriptingNodeInputTypeNames.DraftSyntheticNode]: "Synthetic Data",
  [ScriptingNodeInputTypeNames.DraftTableLeafNode]: "Table",
  [ScriptingNodeInputTypeNames.PublishedRawLeafNode]: "File",
  [ScriptingNodeInputTypeNames.PublishedPostNode]: "Post",
  [ScriptingNodeInputTypeNames.PublishedPreviewNode]: "Airlock",
  [ScriptingNodeInputTypeNames.PublishedScriptingNode]: "Script",
  [ScriptingNodeInputTypeNames.PublishedS3SinkNode]: "S3",
  [ScriptingNodeInputTypeNames.PublishedSqlNode]: "Native-SQL",
  [ScriptingNodeInputTypeNames.PublishedSqliteNode]: "SQL",
  [ScriptingNodeInputTypeNames.PublishedMatchNode]: "Matching",
  [ScriptingNodeInputTypeNames.PublishedSyntheticNode]: "Synthetic Data",
  [ScriptingNodeInputTypeNames.PublishedTableLeafNode]: "Table",
};
