import { ClientApiError } from "@decentriq/core";
import {
  useCreateMediaInsightsComputeJobMutation,
  useGetMediaInsightsComputeJobLazyQuery,
} from "@decentriq/graphql/dist/hooks";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { loadAsync } from "jszip";
import * as forge from "node-forge";
import { useCallback, useEffect, useMemo } from "react";
import { logDebug, parseMediaDataRoomError } from "utils";
import {
  type MediaDataRoomJobHookPayload,
  type MediaDataRoomJobHookResult,
  type MediaDataRoomRequestKey,
} from "./models";
import useMediaDataRoomRequest from "./useMediaDataRoomRequest";

/**
 *This hook is used to create request, run it if not skipped and fetch the results of the job for using job ID and compute node name from request response. It also caches the job in API platform database.
 *
 * @param input `MediaDataRoomJobInput` includes essential info for sending request, like request key, dataRoomId, driverAttestationHash, etc, please check `MediaDataRoomJobInput` for more details
 * @param requestCreator function which provides `dataRoomIdHex`, `scopeIdHex` and needs to return body of the request for the given key
 * @param session optional `Session`, if not provided, it will be fetched
 * @param skip if true, the job result fetching will be skipped
 * @param transform function to transform the job result, it provides the zip file in `JSZip` format
 * @returns object with the job result, error, loading state, retry function and status
 */
const useMediaDataRoomJob = <T, K extends MediaDataRoomRequestKey>({
  input,
  requestCreator,
  session,
  skip,
  transform,
}: MediaDataRoomJobHookPayload<T, K>): MediaDataRoomJobHookResult<T> => {
  const { key, dataRoomId, driverAttestationHash } = input;
  const queryClient = useQueryClient();
  const [createMediaInsightsComputeJobMutation] =
    useCreateMediaInsightsComputeJobMutation();
  const [getMediaInsightsComputeJob] = useGetMediaInsightsComputeJobLazyQuery();
  const { data: existingJob, isLoading: existingJobLoading } = useQuery<{
    computeNodeName: string;
    jobIdHex: string;
  } | null>({
    enabled: !skip,
    queryFn: async () => {
      if (!input.canBeCached || input.skipCaching) {
        return null;
      }
      const getJobResult = await getMediaInsightsComputeJob({
        variables: {
          input: {
            cacheKey: input.buildJobKey(),
            jobType: input.buildJobType(),
            publishedDataRoomId: dataRoomId,
          },
        },
      });
      const mediaComputeJob = getJobResult?.data?.mediaComputeJob;
      if (mediaComputeJob) {
        logDebug("||| Existing job found, fetching results", key);
      }
      const existingJob = mediaComputeJob
        ? {
            computeNodeName: mediaComputeJob.computeNodeName,
            jobIdHex: mediaComputeJob.jobIdHex,
          }
        : null;
      return existingJob;
    },
    queryKey: input.withExistingJobSubkey().buildQueryKey(),
  });
  const [sendRequest, getSession] = useMediaDataRoomRequest({
    dataRoomId,
    driverAttestationHash,
    key,
    requestCreator,
  });
  const createJob = useCallback(async () => {
    const effectiveSession = session ?? (await getSession());
    const response = await sendRequest({
      options: { session: effectiveSession },
    });
    if (!("computeNodeName" in response) || !("jobIdHex" in response)) {
      throw new Error(`Invalid response for job ${key}`);
    }
    // For some reason can't just return response, TS complains
    return {
      computeNodeName: response.computeNodeName,
      jobIdHex: response.jobIdHex,
    };
  }, [sendRequest, session, getSession, key]);
  const { mutate: createJobMutation } = useMutation({
    mutationFn: async () => {
      const job = await createJob();
      if (input.skipCaching) {
        return job;
      }
      const createResponse = await createMediaInsightsComputeJobMutation({
        variables: {
          input: {
            ...job,
            cacheKey: input.buildJobKey(),
            jobType: input.buildJobType(),
            publishedDataRoomId: dataRoomId,
          },
        },
      });
      return createResponse.data?.mediaComputeJob?.create?.record;
    },
    onSuccess: (data) => {
      queryClient.setQueryData(input.withExistingJobSubkey().buildQueryKey(), {
        computeNodeName: data?.computeNodeName,
        jobIdHex: data?.jobIdHex,
      });
    },
  });
  // If existingJob is null, we need to create a new job
  useEffect(() => {
    if (!skip && existingJob == null && !existingJobLoading) {
      createJobMutation();
    }
  }, [createJobMutation, existingJob, existingJobLoading, skip]);
  // Polling for job status
  const {
    data: jobStatus,
    isLoading: jobStatusIsLoading,
    error: statusError,
  } = useQuery({
    enabled: Boolean(existingJob),
    queryFn: async () => {
      try {
        const effectiveSession = session ?? (await getSession());
        if (!existingJob || !effectiveSession) {
          return null;
        }
        const statusResponse = await effectiveSession.getComputationStatus(
          existingJob?.jobIdHex
        );
        const isCompleted = statusResponse.completeComputeNodeIds?.includes(
          existingJob.computeNodeName
        );
        return isCompleted ? "COMPLETED" : "COMPUTING";
      } catch (error) {
        logDebug("||| Failed to get job status", key);
        throw error;
      }
    },
    queryKey: input.withJobStatusSubkey().withJob(existingJob).buildQueryKey(),
    refetchInterval: (query) => {
      if (existingJob && query.state.data === "COMPUTING") {
        // TODO @matyasfodor - polling interval should be configurable
        return 1000;
      }
    },
  });
  const setCacheData = useCallback(
    (data: T | undefined) => {
      queryClient.setQueryData<T | undefined>(
        input.withFetchResultsSubkey().withJob(existingJob).buildQueryKey(),
        () => data
      );
    },
    // Its safe to rely only on `input.queryChangingKey` being changed
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [queryClient, input.queryChangingKey, existingJob]
  );
  // Query the results
  const {
    data: computeResults,
    isLoading: resultsFetchingLoading,
    error: resultsFetchingError,
  } = useQuery({
    enabled: jobStatus === "COMPLETED" && !skip,
    queryFn: async () => {
      logDebug("||| Querying results for job", key);
      try {
        if (!existingJob) {
          throw new Error("Compute job not found");
        }
        const result = await session?.getJobResults(
          forge.util.binary.hex.decode(existingJob.jobIdHex),
          existingJob.computeNodeName
        );
        if (!result) {
          throw new Error("No result");
        }
        const zip = await loadAsync(result);
        return await transform(zip);
      } catch (error) {
        throw parseMediaDataRoomError(error);
      }
    },
    queryKey: input
      .withFetchResultsSubkey()
      .withJob(existingJob)
      .buildQueryKey(),
  });
  const error = useMemo(() => {
    if (statusError) {
      return statusError instanceof ClientApiError
        ? statusError?.message
        : `${statusError}`;
    }
    if (resultsFetchingError) {
      return resultsFetchingError instanceof ClientApiError
        ? resultsFetchingError?.message
        : `${resultsFetchingError}`;
    }
    return undefined;
  }, [statusError, resultsFetchingError]);
  const retry = useCallback(async () => {
    if (skip || !session) {
      throw new Error("Cannot retry disabled query");
    }
    return createJobMutation();
  }, [skip, createJobMutation, session]);
  const status = useMemo(
    () =>
      jobStatusIsLoading || jobStatus !== "COMPLETED"
        ? "COMPUTING"
        : resultsFetchingLoading
          ? "FETCHING"
          : "COMPLETED",
    [jobStatus, jobStatusIsLoading, resultsFetchingLoading]
  );
  return useMemo(
    () => ({
      computeResults,
      error,
      loading: status !== "COMPLETED",
      retry,
      setCacheData,
      status,
    }),
    [computeResults, error, status, retry, setCacheData]
  );
};

export default useMediaDataRoomJob;
