import { Box, styled } from "@mui/joy";

const EmptyStateContainer = styled(Box)(({ height }: { height?: string }) => ({
  alignItems: "center",
  display: "flex",
  flexDirection: "column",
  height: height || "100%",
  justifyContent: "center",
}));

export default EmptyStateContainer;
