import { DqCard, DqCardContent } from "@decentriq/components";
import { Typography } from "@mui/joy";
import { isNil } from "lodash";
import { memo } from "react";
import { formatNumber } from "features/mediaDataRoom/utils";
import AudienceQualityStatisticsLoading from "./AudienceQualityStatisticsLoading";

type EstimatedAudienceSizeProps = {
  estimatedAudienceSize: number | null | undefined;
  loading?: boolean;
};

const EstimatedAudienceSize: React.FC<EstimatedAudienceSizeProps> = ({
  estimatedAudienceSize,
  loading,
}) => {
  return (
    <DqCard>
      <DqCardContent>
        <Typography gutterBottom={true} level="body-sm" textColor="inherit">
          Estimated audience size
        </Typography>
        {loading ? (
          <AudienceQualityStatisticsLoading />
        ) : (
          <Typography fontWeight={500} level="h1">
            {!isNil(estimatedAudienceSize)
              ? formatNumber(estimatedAudienceSize)
              : "-"}
          </Typography>
        )}
      </DqCardContent>
    </DqCard>
  );
};

EstimatedAudienceSize.displayName = "EstimatedAudienceSize";

export default memo(EstimatedAudienceSize);
