import { type Session } from "@decentriq/core";
import { useQueries } from "@tanstack/react-query";
import { useCallback, useMemo, useState } from "react";
import {
  MediaDataRoomJobInput,
  type MediaDataRoomJobResultTransform,
  useMediaDataRoomLazyJob,
} from "features/mediaDataRoom/hooks";
import {
  type Audience,
  type PublishedDatasetsHashes,
} from "features/mediaDataRoom/models";
import { logError, logInfo } from "utils";

interface AudienceSizesHookPayload {
  dataRoomId: string;
  driverAttestationHash: string;
  audiences: Audience[] | undefined;
  session: Session | undefined;
  isPublisher?: boolean;
  publishedDatasetsHashes: PublishedDatasetsHashes;
}

interface DisableSizeEstimationForAudienceFn {
  (audienceId: string): void;
  (audienceIds: string[]): void;
}

export interface AudienceSizesHookResult {
  enableSizeEstimationForAudience: (audienceId: string) => void;
  audienceSizes: Record<
    string,
    // TODO: we might want to expose option to retry the estimation
    { audienceSize: number | null; loading: boolean }
  >;
  disableSizeEstimationForAudience: DisableSizeEstimationForAudienceFn;
}

const useAudienceSizes = ({
  dataRoomId,
  driverAttestationHash,
  audiences,
  session,
  publishedDatasetsHashes,
  isPublisher,
}: AudienceSizesHookPayload): AudienceSizesHookResult => {
  const [enableAudienceSizeEstimation, setEnableAudienceSizeEstimation] =
    useState<string[]>([]);
  const enableSizeEstimationForAudience = useCallback(
    (audienceId: string) => {
      setEnableAudienceSizeEstimation((currentIds) =>
        currentIds.includes(audienceId)
          ? currentIds
          : [...currentIds, audienceId]
      );
    },
    [setEnableAudienceSizeEstimation]
  );
  // TODO: handle case when audience is deleted and there is a running query for it we abort the query
  const disableSizeEstimationForAudience =
    useCallback<DisableSizeEstimationForAudienceFn>(
      (id) =>
        setEnableAudienceSizeEstimation((currentIds) => {
          const ids = Array.isArray(id) ? id : [id];
          const filteredIds = currentIds.filter(
            (currentId) => !ids.includes(currentId)
          );
          if (filteredIds.length !== currentIds.length) {
            return filteredIds;
          }
          return currentIds;
        }),
      [setEnableAudienceSizeEstimation]
    );
  const transformEstimatedAudienceSize = useCallback<
    MediaDataRoomJobResultTransform<number>
  >(async (zip) => {
    const audienceSizeFile = zip.file("audience_size.json");
    if (audienceSizeFile === null) {
      throw new Error("audience_size.json not found in zip");
    }
    return (
      JSON.parse(await audienceSizeFile.async("string")) as {
        audience_size: number;
      }
    ).audience_size;
  }, []);
  const [estimateAudienceSizeForAdvertiser] = useMediaDataRoomLazyJob({
    input: MediaDataRoomJobInput.create(
      "estimateAudienceSizeForAdvertiser",
      dataRoomId,
      driverAttestationHash,
      publishedDatasetsHashes
    ),
    session,
    transform: transformEstimatedAudienceSize,
  });
  const [estimateAudienceSizeForAdvertiserLal] = useMediaDataRoomLazyJob({
    input: MediaDataRoomJobInput.create(
      "estimateAudienceSizeForAdvertiserLal",
      dataRoomId,
      driverAttestationHash,
      publishedDatasetsHashes
    ),
    session,
    transform: transformEstimatedAudienceSize,
  });
  const [estimateAudienceSizeForPublisher] = useMediaDataRoomLazyJob({
    input: MediaDataRoomJobInput.create(
      "estimateAudienceSizeForPublisher",
      dataRoomId,
      driverAttestationHash,
      publishedDatasetsHashes
    ),
    session,
    transform: transformEstimatedAudienceSize,
  });
  const [estimateAudienceSizeForPublisherLal] = useMediaDataRoomLazyJob({
    input: MediaDataRoomJobInput.create(
      "estimateAudienceSizeForPublisherLal",
      dataRoomId,
      driverAttestationHash,
      publishedDatasetsHashes
    ),
    session,
    transform: transformEstimatedAudienceSize,
  });
  const audienceSizes = useQueries({
    queries: enableAudienceSizeEstimation.map((id) => ({
      enabled:
        publishedDatasetsHashes.hasRequiredData &&
        audiences?.some((a) => a.id === id),
      queryFn: async () => {
        const payloadParams = session!.compiler.abMedia.getParameterPayloads(
          id,
          audiences!
        );
        logInfo(
          "Estimating audience size with payload params for table/sidepanel",
          payloadParams,
          id,
          audiences
        );
        let size: number;
        if (payloadParams.lal) {
          size = await (
            isPublisher
              ? estimateAudienceSizeForPublisherLal
              : estimateAudienceSizeForAdvertiserLal
          )({
            requestCreator: (dataRoomIdHex, scopeIdHex) => ({
              dataRoomIdHex,
              generateAudience: payloadParams.generate,
              lalAudience: payloadParams.lal!,
              scopeIdHex,
            }),
            updateInput: (input) => input.withResourceId(id),
          });
        } else {
          size = await (
            isPublisher
              ? estimateAudienceSizeForPublisher
              : estimateAudienceSizeForAdvertiser
          )({
            requestCreator: (dataRoomIdHex, scopeIdHex) => ({
              dataRoomIdHex,
              generateAudience: payloadParams.generate,
              scopeIdHex,
            }),
            updateInput: (input) => input.withResourceId(id),
          });
        }
        return { [id]: size };
      },
      queryKey: [
        "ab",
        "v0",
        dataRoomId,
        "driverAttestationHash",
        driverAttestationHash,
        "datasets",
        publishedDatasetsHashes.datasetsHash,
        isPublisher
          ? "estimateAudienceSizeForPublisher"
          : "estimateAudienceSizeForAdvertiser",
        id,
      ],
    })),
  });
  return useMemo(
    () => ({
      audienceSizes: enableAudienceSizeEstimation.reduce((acc, id, index) => {
        const { data, error } = audienceSizes[index];
        if (error) {
          logError(error);
        }
        if (data && Object.hasOwn(data, id)) {
          return {
            ...acc,
            [id]: { audienceSize: data[id], loading: false },
          };
        }
        return {
          ...acc,
          [id]: { audienceSize: null, loading: !error },
        };
      }, {}),
      disableSizeEstimationForAudience,
      enableSizeEstimationForAudience,
    }),
    [
      enableSizeEstimationForAudience,
      disableSizeEstimationForAudience,
      enableAudienceSizeEstimation,
      audienceSizes,
    ]
  );
};

export default useAudienceSizes;
