// @ts-nocheck
import { testIds } from "@decentriq/utils";
import Editor, { type EditorProps } from "@monaco-editor/react";
import { memo, useCallback, useRef, useState } from "react";
import "./CodeEditor.css";

const DEFAULT_LINE_HEIGHT = 19;

type CustomEditorOptions = {
  height?: number;
  resizable?: boolean;
  readOnly?: boolean;
  width?: number;
  dynamicHeight?: boolean;
  defaultLanguage?: string;
};

interface CodeEditorProps extends EditorProps {
  editorOptions?: EditorProps["options"] & CustomEditorOptions;
}

const defaultProps: EditorProps = {
  defaultLanguage: "sql",
};

const defaultEditorOptions: EditorProps["options"] = {
  minimap: { enabled: false },
  scrollBeyondLastLine: false,
  scrollbar: {
    alwaysConsumeMouseWheel: false,
  },
};

const MonacoEditor = ({
  height,
  handleEditorChange,
  handleEditorMount,
  editorOptions,
  resizable,
  dynamicHeight,
  defaultValue,
  ...restEditorProps
}) => (
  <Editor
    defaultValue={defaultValue}
    height={height}
    onChange={handleEditorChange}
    onMount={handleEditorMount}
    options={{
      ...defaultEditorOptions,
      ...editorOptions,
    }}
    wrapperClassName="code-editor"
    {...defaultProps}
    {...restEditorProps}
  />
);

const CodeEditor: React.FC<CodeEditorProps> = memo(
  ({
    editorOptions: {
      height = 200,
      resizable = true,
      dynamicHeight = false,
      ...restEditorOptions
    } = {},
    onMount,
    onChange,
    ...rest
  }) => {
    const editorRef = useRef(null);
    const [editorHeight, setEditorHeight] = useState(
      dynamicHeight ? DEFAULT_LINE_HEIGHT : height
    );
    const setHeight = useCallback((lineCount) => {
      if (lineCount <= 10) {
        setEditorHeight(lineCount * DEFAULT_LINE_HEIGHT);
      } else {
        setEditorHeight(10 * DEFAULT_LINE_HEIGHT);
      }
    }, []);

    const handleEditorMount = useCallback(
      (editor, monaco) => {
        editorRef.current = editor;
        if (dynamicHeight) {
          const lineCount = editor.getModel().getLineCount();
          setHeight(lineCount);
        }
        onMount?.(editor, monaco);
      },
      [dynamicHeight, onMount, setHeight]
    );
    const handleEditorChange = useCallback(
      (value) => {
        if (dynamicHeight) {
          const lineCount = editorRef.current.getModel().getLineCount();
          setHeight(lineCount);
        }
        onChange?.(value);
      },
      [dynamicHeight, onChange, setHeight]
    );

    return (
      <MonacoEditor
        data-testid={testIds.components.codeEditor.input}
        dynamicHeight={dynamicHeight}
        editorOptions={restEditorOptions}
        handleEditorChange={handleEditorChange}
        handleEditorMount={handleEditorMount}
        height={editorHeight}
        {...rest}
      />
    );
  }
);

export const SqlEditorField: React.FC<CodeEditorProps> = memo((props) => (
  <CodeEditor {...props} defaultLanguage="sql"></CodeEditor>
));

export const ScriptingEditorField: React.FC<CodeEditorProps> = memo(
  ({ defaultLanguage, ...props }) => (
    <CodeEditor {...props} defaultLanguage={defaultLanguage}></CodeEditor>
  )
);

export const JsonEditorField: React.FC<CodeEditorProps> = memo((props) => (
  <CodeEditor {...props} defaultLanguage="json"></CodeEditor>
));

export const PlainTextEditorField: React.FC<CodeEditorProps> = memo((props) => (
  <CodeEditor {...props} defaultLanguage="plaintext"></CodeEditor>
));

CodeEditor.displayName = "CodeEditor";

export default CodeEditor;
