import { faExclamationTriangle as fatExclamationTriangle } from "@fortawesome/pro-thin-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Modal,
  ModalDialog,
  Typography,
} from "@mui/joy";
import { useCallback, useState } from "react";

interface DeprovisionConfirmationDialogProps {
  open: boolean;
  onCancel: () => void;
  onDeprovision: () => Promise<void>;
}

const DeprovisionConfirmationDialog: React.FC<
  DeprovisionConfirmationDialogProps
> = ({ open, onCancel, onDeprovision }) => {
  const [deprovisioning, setDeprovisioning] = useState(false);
  const handleDeprovision = useCallback(async () => {
    try {
      setDeprovisioning(true);
      await onDeprovision();
      onCancel();
    } finally {
      setDeprovisioning(false);
    }
  }, [setDeprovisioning, onDeprovision, onCancel]);
  return (
    <Modal onClose={deprovisioning ? undefined : onCancel} open={open}>
      <ModalDialog color="danger" role="alertdialog">
        <DialogTitle>
          <FontAwesomeIcon
            fixedWidth={true}
            icon={fatExclamationTriangle}
            size="4x"
          />
          <Typography>
            Are you sure you want to deprovision the advertiser audience data?
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Typography
            level="body-sm"
            sx={{ textWrap: "balance" }}
            textAlign="center"
          >
            This operation will delete all insights and audiences. Audiences
            which have previously been exported from the DCR will remain
            available.
          </Typography>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button disabled={deprovisioning} onClick={onCancel}>
            Cancel
          </Button>
          <Button
            color="danger"
            disabled={deprovisioning}
            loading={deprovisioning}
            onClick={handleDeprovision}
            startDecorator={<FontAwesomeIcon icon={fatExclamationTriangle} />}
            variant="solid"
          >
            Deprovision
          </Button>
        </DialogActions>
      </ModalDialog>
    </Modal>
  );
};

DeprovisionConfirmationDialog.displayName = "DeprovisionConfirmationDialog";

export default DeprovisionConfirmationDialog;
