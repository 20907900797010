import { DqLoader } from "@decentriq/components";
import {
  faCircleCheck,
  faCircleExclamation,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert, Stack } from "@mui/joy";
import { memo } from "react";
import { useDataLabContext } from "features/dataLabs";

const DataLabValidationAlerts: React.FC = () => {
  const {
    dataLab: { data: dataLab },
    statisticsLoading,
    computationError,
    datasetValidationErrors,
  } = useDataLabContext();

  if (statisticsLoading) {
    return (
      <Alert color="neutral" startDecorator={<DqLoader />} variant="soft">
        Statistics are being computed. This may take up to several hours. You
        can close this tab and come back later.
      </Alert>
    );
  }

  if (datasetValidationErrors?.size > 0) {
    return (
      <Alert
        color="danger"
        startDecorator={
          <FontAwesomeIcon
            fixedWidth={true}
            icon={faCircleExclamation}
            size="lg"
          />
        }
        variant="soft"
      >
        One or more datasets have failed validation
      </Alert>
    );
  }

  if (dataLab?.statistics?.errors?.length > 0) {
    return (
      <Alert
        color="danger"
        startDecorator={
          <FontAwesomeIcon
            fixedWidth={true}
            icon={faCircleExclamation}
            size="lg"
          />
        }
        sx={{ alignItems: "flex-start" }}
        variant="soft"
      >
        <Stack spacing={0}>
          Data quality issue
          <ul style={{ margin: 0, paddingLeft: "32px" }}>
            {dataLab?.statistics?.errors.map((error: string) => (
              <li key={error}>{error}</li>
            ))}
          </ul>
        </Stack>
      </Alert>
    );
  }

  if (computationError?.originalError) {
    return (
      <Alert
        color="danger"
        startDecorator={
          <FontAwesomeIcon
            fixedWidth={true}
            icon={faCircleExclamation}
            size="lg"
          />
        }
        variant="soft"
      >
        Statistics failed to compute, please retry. If the problem persists,
        contact support.
      </Alert>
    );
  }

  if (dataLab?.statistics && datasetValidationErrors?.size === 0) {
    return (
      <Alert
        color="primary"
        startDecorator={
          <FontAwesomeIcon fixedWidth={true} icon={faCircleCheck} size="lg" />
        }
        variant="soft"
      >
        No blocking issues have been detected. This datalab can now be used in
        Media DCRs.
      </Alert>
    );
  }

  return null;
};

DataLabValidationAlerts.displayName = "DataLabValidationAlerts";

export default memo(DataLabValidationAlerts);
