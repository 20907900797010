import { faDatabase } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ListItemDecorator, MenuItem } from "@mui/joy";
import { memo, useCallback } from "react";
import { type Audience } from "features/mediaDataRoom/models";
import { useAudiences } from "features/mediaDataRoom/wrappers";

interface ExportAudienceAsDatasetProps {
  audience: Audience;
}

const ExportAudienceAsDataset: React.FC<ExportAudienceAsDatasetProps> = memo(
  ({ audience }) => {
    const { isExportingAudience, exportAudienceAsDataset } = useAudiences();
    const handleExportAudienceAsDataset = useCallback(
      () => exportAudienceAsDataset(audience),
      [exportAudienceAsDataset, audience]
    );
    return (
      <MenuItem
        disabled={isExportingAudience[audience.id]}
        onClick={handleExportAudienceAsDataset}
      >
        <ListItemDecorator>
          <FontAwesomeIcon fixedWidth={true} icon={faDatabase} />
        </ListItemDecorator>
        Store as dataset
      </MenuItem>
    );
  }
);

ExportAudienceAsDataset.displayName = "ExportAudienceAsDataset";

export default ExportAudienceAsDataset;
