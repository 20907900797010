import { useSelections } from "ahooks";
import React, { useMemo } from "react";
import { RequestsVarsProvider } from "contexts";
import { ComputeNodeConstructorMode } from "features";
import { ComputeNodesVarsWrapper } from "wrappers";

interface RequestsVarsWrapperProps {
  defaultExpandedRequests?: string[];
  defaultExpandedComputations?: string[];
  children?: React.ReactNode;
  requestsIds: string[];
}

const RequestsVarsWrapper: React.FC<RequestsVarsWrapperProps> = ({
  children,
  defaultExpandedComputations,
  defaultExpandedRequests,
  requestsIds,
}) => {
  const {
    isSelected: checkRequestSelection,
    toggle: toggleRequest,
    select: selectRequest,
    unSelectAll: unSelectAllRequests,
  } = useSelections(requestsIds, defaultExpandedRequests || []);
  const value = useMemo(
    () => ({
      checkRequestSelection,
      context: "draft" as "draft" | "submitted",
      selectRequest,
      toggleRequest,
      unSelectAllRequests,
    }),
    [checkRequestSelection, selectRequest, toggleRequest, unSelectAllRequests]
  );
  return (
    <RequestsVarsProvider value={value}>
      <ComputeNodesVarsWrapper
        defaultExpandedComputeNodes={defaultExpandedComputations}
        executionContext="requests"
        hasComputeNodeActions={true}
        hasComputeNodeParticipants={false}
        mode={ComputeNodeConstructorMode.EDIT}
      >
        {children}
      </ComputeNodesVarsWrapper>
    </RequestsVarsProvider>
  );
};

export default RequestsVarsWrapper;
