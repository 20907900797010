import { Grid, Typography } from "@mui/joy";
import { memo } from "react";
import useS3ConnectionConfigurationData from "./useS3ConnectionConfigurationData";

const ConfigurationRow: React.FC<{
  label: string;
  value: string | undefined;
}> = ({ label, value }) => (
  <Typography level="body-sm">
    <strong>{label}:</strong> {value}
  </Typography>
);

type S3ConnectionConfigurationProps = {
  connectionId: string;
};

const S3ConnectionConfiguration: React.FC<S3ConnectionConfigurationProps> = ({
  connectionId,
}) => {
  const { bucket, region, objectKey } =
    useS3ConnectionConfigurationData(connectionId);
  return (
    <Grid container={true} direction="column" xs={12}>
      <ConfigurationRow label="Bucket" value={bucket} />
      <ConfigurationRow label="Region" value={region} />
      <ConfigurationRow label="Object" value={objectKey} />
    </Grid>
  );
};

S3ConnectionConfiguration.displayName = "S3ConnectionConfiguration";

export default memo(S3ConnectionConfiguration);
