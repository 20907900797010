import { faUserPlus } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, ButtonGroup } from "@mui/joy";
import { useBoolean } from "ahooks";
import { Fragment, memo } from "react";
import { useParams } from "react-router-dom";
import { AddDemoUserDialog, AddOrganizationUserDialog } from "components";
import { useIsDecentriqOrganization } from "hooks";

const UsersAddButton: React.FC = () => {
  const { organizationId } = useParams();
  const isDecentriqOrganization = useIsDecentriqOrganization(organizationId!);
  const [
    isAddOrganizationUserDialogOpen,
    {
      setTrue: openAddOrganizationUserDialog,
      setFalse: closeAddOrganizationUserDialog,
    },
  ] = useBoolean(false);
  const [
    isAddDemoUserDialogOpen,
    { setTrue: openAddDemoUserDialog, setFalse: closeAddDemoUserDialog },
  ] = useBoolean(false);
  return (
    <Fragment>
      <ButtonGroup sx={{ m: 1 }}>
        <Button
          color="primary"
          onClick={openAddOrganizationUserDialog}
          startDecorator={
            <FontAwesomeIcon fixedWidth={true} icon={faUserPlus} />
          }
          variant="solid"
        >
          Add organization user
        </Button>
        {isDecentriqOrganization && (
          <Button onClick={openAddDemoUserDialog}>Add demo user</Button>
        )}
      </ButtonGroup>
      <AddOrganizationUserDialog
        onCancel={closeAddOrganizationUserDialog}
        open={isAddOrganizationUserDialogOpen}
        organizationId={organizationId!}
      />
      <AddDemoUserDialog
        onCancel={closeAddDemoUserDialog}
        open={isAddDemoUserDialogOpen}
        organizationId={organizationId!}
      />
    </Fragment>
  );
};

UsersAddButton.displayName = "UsersAddButton";

export default memo(UsersAddButton);
