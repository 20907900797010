import { type ApolloError } from "@apollo/client";
import { useOrganizationUserEmailsQuery } from "@decentriq/graphql/dist/hooks";
import { type OrganizationUserEmailsQuery } from "@decentriq/graphql/dist/types";
import { sortBy } from "lodash";
import { useMemo } from "react";

export type OrganizationUserEmail = Omit<
  OrganizationUserEmailsQuery["organization"]["userEmails"]["nodes"][number],
  "__typename"
>;

interface OrganizationUserEmailsHookPayload {
  organizationId: string | undefined;
}
interface OrganizationUserEmailsHookResult {
  organizationUserEmails: OrganizationUserEmail[];
  loading: boolean;
  error: ApolloError | undefined;
}

const useOrganizationUserEmails = ({
  organizationId,
}: OrganizationUserEmailsHookPayload): OrganizationUserEmailsHookResult => {
  const { data, loading, error } = useOrganizationUserEmailsQuery({
    skip: !organizationId,
    variables: {
      organizationId: organizationId!,
    },
  });
  const organizationUsers = useMemo(
    () => sortBy(data?.organization?.userEmails?.nodes ?? [], "email"),
    [data?.organization?.userEmails?.nodes]
  );
  return {
    error,
    loading,
    organizationUserEmails: organizationUsers,
  };
};

export default useOrganizationUserEmails;
