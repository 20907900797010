import { faBookOpen } from "@fortawesome/pro-light-svg-icons";
import { Link } from "@mui/joy";
import { memo } from "react";
import { SidebarMenuItem } from "features/sidebar";
import { useDocsLink } from "hooks";

const DocumentationSidebarMenuItem = () => {
  const docsBaseLink = useDocsLink();
  return (
    <SidebarMenuItem
      data-testid="sidebar_documentation"
      href={docsBaseLink}
      icon={faBookOpen}
      label="Docs"
      target="_blank"
      tooltipTitle="Docs"
      wrapperComponent={Link}
    />
  );
};

DocumentationSidebarMenuItem.displayName = "DocumentationSidebarMenuItem";

export default memo(DocumentationSidebarMenuItem);
