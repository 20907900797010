import {
  type Client,
  data_science,
  type data_science_commit,
} from "@decentriq/core";
import {
  type MutationRebaseSubmittedDataRoomRequestArgs,
  RebaseSubmittedDataRoomRequestDocument,
  type RebaseSubmittedDataRoomRequestLocalPayload,
  type RebaseSubmittedDataRoomRequestMutation,
  type RebaseSubmittedDataRoomRequestMutationVariables,
} from "@decentriq/graphql/dist/types";
import { type Mutex } from "async-mutex";
import { type ApiCoreContextValue } from "contexts";
import { getDataRoomUsedSpecs } from "wrappers/ApolloWrapper/helpers/createDataScienceCommit";
import { type LocalResolverContext } from "wrappers/ApolloWrapper/models";
import * as apicoreUtils from "../../../../../utils/apicore";
import { fetchDataScienceDataRoom } from "../../PublishedDataRoom";

async function rebaseCommit(
  commit: data_science_commit.DataScienceCommit,
  newHistoryPin: string,
  dataScienceWrapper: data_science.DataScienceDataRoomWrapper,
  sdkClient: Client
) {
  const latestEnclaveSpecs = await sdkClient.getEnclaveSpecifications();
  const usedSpecs = await getDataRoomUsedSpecs(
    dataScienceWrapper,
    latestEnclaveSpecs
  );
  apicoreUtils.rebaseCommit(commit, newHistoryPin, usedSpecs);
}

export const makeRebaseSubmittedDataRoomRequestResolver =
  (
    client: ApiCoreContextValue["client"],
    sessionManager: ApiCoreContextValue["sessionManager"],
    store: ApiCoreContextValue["store"],
    mutexMap: Map<string, Mutex>
  ) =>
  async (
    _obj: any,
    args: MutationRebaseSubmittedDataRoomRequestArgs,
    context: LocalResolverContext,
    _info: any
  ): Promise<RebaseSubmittedDataRoomRequestLocalPayload> => {
    const { id, dcrHash, driverAttestationHash, commitId } = args.input;
    const sdkSession = await sessionManager.get({
      driverAttestationHash,
    });
    const dataScienceDataRoom =
      await sdkSession.retrieveDataScienceDataRoom(dcrHash);
    const wrapper = data_science.createDataScienceDataRoomWrapper(
      dcrHash,
      dataScienceDataRoom!,
      sdkSession
    );
    const commit = await wrapper.retrieveCommit(commitId);
    const currentHistoryPin = (await wrapper.retrieveCurrentConfiguration())
      .historyPin;
    await rebaseCommit(commit, currentHistoryPin, wrapper, client);
    const newCommitId = await wrapper.createCommit(commit);
    const newSigners = await wrapper.retrieveCommitApprovers(newCommitId);
    const result = await context.client.mutate<
      RebaseSubmittedDataRoomRequestMutation,
      RebaseSubmittedDataRoomRequestMutationVariables
    >({
      mutation: RebaseSubmittedDataRoomRequestDocument,
      variables: {
        input: {
          newEnclaveCommitId: newCommitId,
          newEnclaveConfigurationPin: currentHistoryPin,
          newSigners,
          submittedDataRoomRequestId: id,
        },
      },
    });
    await fetchDataScienceDataRoom(
      {
        driverAttestationHash,
        id: dcrHash,
      },
      context.cache,
      context.client,
      sessionManager,
      client,
      mutexMap,
      true
    );
    return {
      id: result.data?.submittedDataRoomRequest.rebase.id,
    };
  };
