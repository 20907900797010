import { data_science } from "@decentriq/core";
import {
  type MutationPublishDatasetArgs,
  type PublishDatasetPayload,
} from "@decentriq/graphql/dist/types";
import { type ApiCoreContextValue } from "contexts";
import { type LocalResolverContext } from "../../../models";

export const makePublishDatasetResolver =
  (
    client: ApiCoreContextValue["client"],
    sessionManager: ApiCoreContextValue["sessionManager"],
    store: ApiCoreContextValue["store"]
  ) =>
  async (
    _obj: null,
    args: MutationPublishDatasetArgs,
    context: LocalResolverContext,
    _info: any
  ): Promise<PublishDatasetPayload> => {
    const {
      dcrHash,
      driverAttestationHash,
      manifestHash,
      leafNodeId,
      encryptionKey,
    } = args.input;
    const sdkSession = await sessionManager.get({
      driverAttestationHash,
    });
    const dataScienceDataRoom =
      await sdkSession.retrieveDataScienceDataRoom(dcrHash);
    const wrapper = data_science.createDataScienceDataRoomWrapper(
      dcrHash,
      dataScienceDataRoom!,
      sdkSession
    );
    await wrapper.publishDataset(
      manifestHash,
      leafNodeId,
      store.get(encryptionKey)!
    );
    return {
      dataset: {
        datasetHash: manifestHash,
        leafId: leafNodeId,
        timestamp: Date.now(),
        user: sdkSession.metaData.email,
      },
    };
  };
