import { FormControl, FormHelperText, FormLabel, Textarea } from "@mui/joy";
import { memo } from "react";
import {
  Controller,
  type FieldValues,
  type Path,
  useFormContext,
} from "react-hook-form";

interface DisplayConfigurationDescriptionFieldProps<F extends FieldValues> {
  fieldName: Path<F>;
  title: string;
  placeholder: string;
  testId?: string;
}

type DisplayConfigurationDescriptionFieldComponent = <F extends FieldValues>(
  props: DisplayConfigurationDescriptionFieldProps<F>
) => React.ReactNode;

const DisplayConfigurationDescriptionField = memo(
  <F extends FieldValues>({
    fieldName,
    title,
    placeholder,
    testId,
  }: DisplayConfigurationDescriptionFieldProps<F>) => {
    const { control } = useFormContext<F>();
    return (
      <Controller
        control={control}
        name={fieldName}
        render={({ field, fieldState }) => {
          const { error } = fieldState;
          return (
            <FormControl disabled={field.disabled} error={Boolean(error)}>
              <FormLabel>{title}</FormLabel>
              <Textarea
                minRows={3}
                placeholder={placeholder}
                {...field}
                data-testid={testId}
              />
              <FormHelperText>{error?.message}</FormHelperText>
            </FormControl>
          );
        }}
      />
    );
  }
);
DisplayConfigurationDescriptionField.displayName =
  "DisplayConfigurationDescriptionField";

export default DisplayConfigurationDescriptionField as DisplayConfigurationDescriptionFieldComponent;
