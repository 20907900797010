import { DqCard, DqCardContent } from "@decentriq/components";
import { Typography } from "@mui/joy";
import { isNil } from "lodash";
import { memo } from "react";
import AudienceQualityStatisticsLoading from "./AudienceQualityStatisticsLoading";

type ReachTradeoffProps = {
  reach: number | null | undefined;
  loading?: boolean;
};

const ReachTradeoff: React.FC<ReachTradeoffProps> = ({ reach, loading }) => {
  return (
    <DqCard>
      <DqCardContent>
        <Typography gutterBottom={true} level="body-sm" textColor="inherit">
          Precision/reach tradeoff
        </Typography>
        {loading ? (
          <AudienceQualityStatisticsLoading />
        ) : (
          <Typography fontSize={36} fontWeight={600}>
            {!isNil(reach) ? `${reach}%` : "-"}
          </Typography>
        )}
      </DqCardContent>
    </DqCard>
  );
};

ReachTradeoff.displayName = "ReachTradeoff";

export default memo(ReachTradeoff);
