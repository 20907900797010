import { DataSourceType, DataTargetType } from "@decentriq/graphql/dist/types";
import { faLaptop, faMicrochip } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { memo } from "react";
import * as ExternalConnectionTypeIcons from "features/datasets/icons";
import SalesforceLogoPng from "features/datasets/icons/SalesforceLogo.png";
import SplickyLogoPng from "features/datasets/icons/SplickyLogo.png";

type ExternalConnectionsIconProps = {
  connectionType: DataSourceType | DataTargetType;
  size?: ExternalConnectionsIconSize;
};

export enum ExternalConnectionsIconSize {
  xs,
  sm,
  md,
  lg,
}

const iconSize = new Map<
  ExternalConnectionsIconSize,
  { default: string; compute: string }
>([
  [ExternalConnectionsIconSize.xs, { compute: "16px", default: "18px" }],
  [ExternalConnectionsIconSize.sm, { compute: "18px", default: "20px" }],
  [ExternalConnectionsIconSize.md, { compute: "20px", default: "24px" }],
  [ExternalConnectionsIconSize.lg, { compute: "62px", default: "64px" }],
]);

const ExternalConnectionsIcon: React.FC<ExternalConnectionsIconProps> = ({
  connectionType,
  size = ExternalConnectionsIconSize.md,
}) => {
  const { compute: computeSize, default: defaultSize } = iconSize.get(size)!;
  const width = defaultSize;
  const height = defaultSize;
  const computeTypeHeight = computeSize;
  const computeTypeWidth = computeSize;
  switch (connectionType) {
    case DataSourceType.S3 || DataTargetType.S3:
      return (
        <ExternalConnectionTypeIcons.AmazonS3Logo
          height={height}
          width={width}
        />
      );
    case DataSourceType.Azure || DataTargetType.Azure:
      return (
        <ExternalConnectionTypeIcons.AzureLogo height={height} width={width} />
      );
    case DataSourceType.GoogleCloudStorage || DataTargetType.GoogleCloudStorage:
      return (
        <ExternalConnectionTypeIcons.GoogleCloudStorageLogo
          height={height}
          width={width}
        />
      );
    case DataSourceType.Snowflake:
      return (
        <ExternalConnectionTypeIcons.SnowflakeLogo
          height={height}
          width={width}
        />
      );
    case DataSourceType.Salesforce:
      return <img alt="Saleforce" src={SalesforceLogoPng} width={width} />;
    // TODO: check why this is not working on safari
    // return (
    //   <ExternalConnectionTypeIcons.SalesforceLogo
    //     height={height}
    //     width={width}
    //   />
    // );
    case DataSourceType.Local:
      return <FontAwesomeIcon icon={faLaptop} style={{ height, width }} />;
    case DataSourceType.Compute:
      return (
        <FontAwesomeIcon
          icon={faMicrochip}
          style={{ height: computeTypeHeight, width: computeTypeWidth }}
        />
      );
    case DataTargetType.GoogleDv_360:
      return (
        <ExternalConnectionTypeIcons.GoogleDV360Logo
          height={height}
          width={width}
        />
      );
    case DataTargetType.GoogleAdManager:
      return (
        <ExternalConnectionTypeIcons.GoogleAdManagerLogo
          height={height}
          width={width}
        />
      );
    case DataTargetType.Meta:
      return (
        <ExternalConnectionTypeIcons.MetaLogo height={height} width={width} />
      );
    case DataTargetType.TradeDesk:
      return (
        <ExternalConnectionTypeIcons.TradeDeskLogo
          height={height}
          width={width}
        />
      );
    case DataTargetType.Sportradar:
      return (
        <ExternalConnectionTypeIcons.SportradarLogo
          height={height}
          width={width}
        />
      );
    case DataSourceType.Permutive:
    case DataTargetType.Permutive:
      return (
        <ExternalConnectionTypeIcons.PermutiveLogo
          height={height}
          width={width}
        />
      );
    case DataTargetType.Adform:
      return (
        <ExternalConnectionTypeIcons.AdFormLogo height={height} width={width} />
      );
    case DataTargetType.MicrosoftDsp:
      return (
        <ExternalConnectionTypeIcons.MicrosoftLogo
          height={height}
          width={width}
        />
      );
    case DataTargetType.Splicky:
      return <img alt="Splicky" src={SplickyLogoPng} width={width} />;
  }
  return null;
};

ExternalConnectionsIcon.displayName = "ExternalConnectionsIcon";

export default memo(ExternalConnectionsIcon);
