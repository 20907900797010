import { memo, useCallback, useMemo, useReducer, useState } from "react";
import { ComputeNodesVarsProvider } from "contexts";
import { ComputeNodeConstructorMode } from "features/computeNodes";

interface ComputeNodesVarsWrapperProps {
  hasComputeNodeActions?: boolean;
  hasComputeNodeParticipants?: boolean;
  executionContext?:
    | "committed"
    | "development"
    | "requests"
    | "submitted_requests";
  mode: ComputeNodeConstructorMode;
  children?: React.ReactNode;
  defaultExpandedComputeNodes?: string[];
  defaultExpandedComputeNodeConfigs?: string[];
  permittedOnly?: boolean;
  /*
  expandedComputeNodes: string[];
  isExpanded: (computeNodeId: string) => boolean;
  expand: (computeNodeId: string) => void;
  collapse: (computeNodeId: string) => void;
  toggle: (computeNodeId: string) => void;
  isEditorDialogOpened: (computeNodeId: string) => boolean;
  closeEditorDialog: () => void;
  openEditorDialog: (computeNodeId: string) => void;
  */
}

type State = string[];
type StateAction = {
  type: "EXPAND" | "COLLAPSE" | "TOGGLE";
  payload: string;
};

const computeNodesStateReducer = (state: State, action: StateAction) => {
  switch (action.type) {
    case "EXPAND":
      return [...state, action.payload];
    case "COLLAPSE":
      return state.filter((computeNodeId) => computeNodeId !== action.payload);
    case "TOGGLE":
      return state.includes(action.payload)
        ? state.filter((computeNodeId) => computeNodeId !== action.payload)
        : [...state, action.payload];
    default:
      return state;
  }
};

/* In order to avoid passing computeNodeId for openEditorDialog, expandComputeNode, toggleComputeNode
  and other functions, consider wrapping <ComputeNode /> component into another context ComputeNodeState
  and pass a computeNodeId as a prop to this wrapper, so an id will be taken as a proper like hasComputeNodeActions
  or hasComputeNodeParticipants
*/
const ComputeNodesVarsWrapper: React.FC<ComputeNodesVarsWrapperProps> = memo(
  ({
    permittedOnly = false,
    hasComputeNodeActions = false,
    hasComputeNodeParticipants = false,
    mode,
    executionContext = "committed" as
      | "committed"
      | "development"
      | "requests"
      | "submitted_requests",
    defaultExpandedComputeNodes,
    defaultExpandedComputeNodeConfigs,
    children,
  }) => {
    // Accordion state
    const [expandedComputeNodes, dispatchExpanded] = useReducer(
      computeNodesStateReducer,
      defaultExpandedComputeNodes || []
    );
    const expandComputeNode = useCallback(
      (computeNodeId: string) =>
        dispatchExpanded({ payload: computeNodeId, type: "EXPAND" }),
      [dispatchExpanded]
    );
    const collapseComputeNode = useCallback(
      (computeNodeId: string) =>
        dispatchExpanded({ payload: computeNodeId, type: "COLLAPSE" }),
      [dispatchExpanded]
    );
    const toggleComputeNode = useCallback(
      (computeNodeId: string) =>
        dispatchExpanded({ payload: computeNodeId, type: "TOGGLE" }),
      [dispatchExpanded]
    );
    const isExpanded = useCallback(
      (computeNodeId: string) => expandedComputeNodes.includes(computeNodeId),
      [expandedComputeNodes]
    );
    // Config state state
    const [expandedComputeNodeConfigs, dispatchExpandedConfig] = useReducer(
      computeNodesStateReducer,
      defaultExpandedComputeNodeConfigs || []
    );
    const expandComputeNodeConfig = useCallback(
      (computeNodeId: string) =>
        dispatchExpandedConfig({ payload: computeNodeId, type: "EXPAND" }),
      [dispatchExpandedConfig]
    );
    const collapseComputeNodeConfig = useCallback(
      (computeNodeId: string) =>
        dispatchExpandedConfig({ payload: computeNodeId, type: "COLLAPSE" }),
      [dispatchExpandedConfig]
    );
    const toggleComputeNodeConfig = useCallback(
      (computeNodeId: string) =>
        dispatchExpandedConfig({ payload: computeNodeId, type: "TOGGLE" }),
      [dispatchExpandedConfig]
    );
    const isExpandedConfig = useCallback(
      (computeNodeId: string) =>
        expandedComputeNodeConfigs.includes(computeNodeId),
      [expandedComputeNodeConfigs]
    );
    // Edit dialog state
    const [openedEditorDialogComputeNodeId, toggleEditorDialog] = useState<
      string | null
    >(null);
    const closeEditorDialog = useCallback(() => toggleEditorDialog(null), []);
    const openEditorDialog = useCallback(
      (computeNodeId: string) => toggleEditorDialog(computeNodeId),
      []
    );
    const isEditorDialogOpened = useCallback(
      (computeNodeId: string) =>
        computeNodeId === openedEditorDialogComputeNodeId,
      [openedEditorDialogComputeNodeId]
    );
    // SDG quality report dialog state
    const [
      openedSdgQualityReportDialogComputeNodeId,
      toggleSdgQualityReportDialog,
    ] = useState<string | null>(null);
    const closeSdgQualityReportDialog = useCallback(
      () => toggleSdgQualityReportDialog(null),
      []
    );
    const openSdgQualityReportDialog = useCallback(
      (computeNodeId: string) => toggleSdgQualityReportDialog(computeNodeId),
      []
    );
    const isSdgQualityReportDialogOpened = useCallback(
      (computeNodeId: string) =>
        computeNodeId === openedSdgQualityReportDialogComputeNodeId,
      [openedSdgQualityReportDialogComputeNodeId]
    );
    // isReadOnly mode
    const { readOnly } = useMemo(
      () => ({
        readOnly:
          mode !== ComputeNodeConstructorMode.EDIT &&
          mode !== ComputeNodeConstructorMode.ACTION_EDIT,
      }),
      [mode]
    );
    return (
      <ComputeNodesVarsProvider
        value={{
          closeEditorDialog,
          closeSdgQualityReportDialog,
          collapse: collapseComputeNode,
          collapseConfig: collapseComputeNodeConfig,
          executionContext,
          expand: expandComputeNode,
          expandConfig: expandComputeNodeConfig,
          expandedComputeNodes,
          hasComputeNodeActions,
          hasComputeNodeParticipants,
          isEditorDialogOpened,
          isExpanded,
          isExpandedConfig,
          isSdgQualityReportDialogOpened,
          mode,
          openEditorDialog,
          openSdgQualityReportDialog,
          permittedOnly,
          readOnly,
          toggle: toggleComputeNode,
          toggleConfig: toggleComputeNodeConfig,
        }}
      >
        {children}
      </ComputeNodesVarsProvider>
    );
  }
);

export default ComputeNodesVarsWrapper;
