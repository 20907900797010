import { DqLoader } from "@decentriq/components";
import { accordionDetailsClasses, AccordionGroup, Box } from "@mui/joy";
import {
  MediaDataroomDataTabOverlap,
  usePublishedMediaInsightsDcr,
} from "features/MediaInsightsDcr";
import AdvertiserDataNode from "../components/AdvertiserDataNode/AdvertiserDataNode";
import PublisherDataNode from "../components/PublisherDataNode/PublisherDataNode";
import { useMediaInsightsDcrData } from "../contexts/MediaInsightsDcrContext/MediaInsightsDcrContext";

const MediaDataRoomData: React.FC = () => {
  const {
    advertiserDatasetHash,
    publisherDatasetsHashes: {
      demographicsDatasetHash = null,
      embeddingsDatasetHash = null,
      segmentsDatasetHash = null,
      usersDatasetHash = null,
    } = {},
    retrieveDatasets,
    datasetsLoading,
  } = useMediaInsightsDcrData();
  const {
    supportedFeatures: { hideAbsoluteValues = false },
  } = usePublishedMediaInsightsDcr();

  if (datasetsLoading) {
    return (
      <Box
        alignItems="center"
        display="flex"
        height="100%"
        justifyContent="center"
        padding="1rem"
      >
        <DqLoader label="Loading data..." />
      </Box>
    );
  }
  return (
    <>
      <AccordionGroup
        className="separated"
        sx={{
          [`& .${accordionDetailsClasses.content}`]: {
            boxShadow: (theme) => `inset 0 1px ${theme.vars.palette.divider}`,
            [`&.${accordionDetailsClasses.expanded}`]: {
              paddingBlock: "0.75rem",
            },
          },
          flex: 0,
        }}
      >
        <AdvertiserDataNode
          datasetHash={advertiserDatasetHash}
          retrieveDatasets={retrieveDatasets}
        />
        <PublisherDataNode
          demographicsDatasetHash={demographicsDatasetHash}
          embeddingsDatasetHash={embeddingsDatasetHash}
          retrieveDatasets={retrieveDatasets}
          segmentsDatasetHash={segmentsDatasetHash}
          usersDatasetHash={usersDatasetHash}
        />
      </AccordionGroup>
      {!hideAbsoluteValues && <MediaDataroomDataTabOverlap />}
    </>
  );
};
MediaDataRoomData.displayName = "MediaDataRoomData";

export default MediaDataRoomData;
