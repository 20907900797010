import { faDownload } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ListItemDecorator, MenuItem } from "@mui/joy";
import { memo, useCallback } from "react";
import { type Audience } from "features/mediaDataRoom/models";
import { useAudiences } from "features/mediaDataRoom/wrappers";

type DownloadAudienceMenuItemProps = {
  audience: Audience;
};

const DownloadAudienceMenuItem: React.FC<DownloadAudienceMenuItemProps> = memo(
  ({ audience }) => {
    const { isExportingAudience, downloadAudience } = useAudiences();
    const handleDownloadAudience = useCallback(
      async () => downloadAudience(audience),
      [downloadAudience, audience]
    );
    return (
      <MenuItem
        disabled={isExportingAudience[audience.id]}
        onClick={handleDownloadAudience}
      >
        <ListItemDecorator>
          <FontAwesomeIcon fixedWidth={true} icon={faDownload} />
        </ListItemDecorator>
        Download file
      </MenuItem>
    );
  }
);

DownloadAudienceMenuItem.displayName = "DownloadAudienceMenuItem";

export default DownloadAudienceMenuItem;
