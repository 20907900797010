import { AdvertiserAudienceGeneratorStep } from "../contexts/AdvertiserAudienceGeneratorContext";

export const mapGenerationStepToPresentation = new Map<
  AdvertiserAudienceGeneratorStep,
  string
>([
  [AdvertiserAudienceGeneratorStep.SELECT_TYPE, "Audience type"],
  [AdvertiserAudienceGeneratorStep.CONFIGURATION, "Audience configuration"],
  [AdvertiserAudienceGeneratorStep.SUMMARY, "Audience preview"],
]);
