import { createContext, useContext } from "react";

export const DataContext = createContext({
  condition: undefined,
  data: undefined,
  dataKeyName: undefined,
  dataTotalCount: 0,
  fetchMore: () => {},
  filter: undefined,
  groupBy: [],
  idKeyName: undefined,
  idsKeyName: undefined,
  loading: false,
  offset: 0,
  orderBy: [],
  page: undefined,
  pageSize: 10,
  refetch: () => {},
  search: "",
  setCondition: () => {},
  setFilter: () => {},
  setGroupBy: () => {},
  setOrderBy: () => {},
  setPage: () => {},
  setPageSize: () => {},
  setSearch: () => {},
});

export const DataProvider = DataContext.Provider;

export const useData = () => useContext(DataContext);

export default useData;
