import { type PublishedMediaDataRoomQuery } from "@decentriq/graphql/dist/types";
import { memo } from "react";
import { MediaDataRoomMainbar } from "features/mediaDataRoom/components";
import {
  MediaDataRoomHandlersWrapper,
  MediaDataRoomInsightsDataWrapper,
  MediaDataRoomWrapper,
} from "features/mediaDataRoom/contexts";
import MediaDataRoomActiveTab from "./MediaDataRoomActiveTab";
import MediaDataRoomErrorReport from "./MediaDataRoomErrorReport";

interface MediaDataRoomProps {
  data: PublishedMediaDataRoomQuery["publishedMediaDataRoom"];
}

const MediaDataRoom: React.FC<MediaDataRoomProps> = memo(({ data }) => {
  return (
    <MediaDataRoomErrorReport
      createdAt={data.createdAt}
      dataRoomId={data.id}
      driverAttestationHash={data.driverAttestationHash}
    >
      <MediaDataRoomWrapper data={data}>
        <MediaDataRoomInsightsDataWrapper>
          <MediaDataRoomHandlersWrapper
            dataRoomId={data.id}
            driverAttestationHash={data.driverAttestationHash}
          >
            <MediaDataRoomMainbar id={data.id} />
            <MediaDataRoomActiveTab />
          </MediaDataRoomHandlersWrapper>
        </MediaDataRoomInsightsDataWrapper>
      </MediaDataRoomWrapper>
    </MediaDataRoomErrorReport>
  );
});

export default MediaDataRoom;
