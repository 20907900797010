import { Box } from "@mui/joy";
import { styled } from "@mui/material";

const ExternalConnectionActionsWrapper = styled(Box)({
  alignItems: "center",
  display: "flex",
  justifyContent: "space-between",
  padding: "16px 0",
  width: "100%",
});

export default ExternalConnectionActionsWrapper;
