import { type MutationBaseOptions } from "@apollo/client/core/watchQueryOptions";
import {
  type CreateActivationConfigurationMutationOptions,
  type CreatePublisherDisplayConfigurationMutationOptions,
  type UpdatePublisherDisplayConfigurationMutationOptions,
  useActivationConfigurationsQuery,
  useCreateActivationConfigurationMutation,
  useCreatePublisherDisplayConfigurationMutation,
  useDeleteActivationConfigurationMutation,
  useDeletePublisherDisplayConfigurationMutation,
  useGetPublisherDisplayConfigurationQuery,
  useUpdatePublisherDisplayConfigurationMutation,
} from "@decentriq/graphql/dist/hooks";
import {
  ActivationConfigurationsDocument,
  type FullActivationConfigurationFragment,
  GetPublisherDisplayConfigurationDocument,
  type PublisherDisplayConfiguration,
} from "@decentriq/graphql/dist/types";
import { createContext, type ReactNode, useContext, useMemo } from "react";
import {
  type ActiveMarket,
  type OrganizationUserEmail,
  useActiveMarkets,
  useOrganizationUserEmails,
} from "features/mediaPortalShared";
import { useUserRole } from "hooks";

interface PublisherPortalContextValue {
  organizationUserEmails: OrganizationUserEmail[];
  publisherMarkets: ActiveMarket[];
  activationConfigurations: FullActivationConfigurationFragment[];
  addActivationConfiguration: (
    args: CreateActivationConfigurationMutationOptions
  ) => Promise<void>;
  deleteActivationConfiguration: (id: string) => Promise<void>;
  publisherDisplayConfiguration: PublisherDisplayConfiguration | null;
  isPublisherDisplayConfigurationLoading: boolean;
  createPublisherDisplayConfiguration: (
    mutationParams: CreatePublisherDisplayConfigurationMutationOptions
  ) => Promise<void>;
  updatePublisherDisplayConfiguration: (
    mutationParams: UpdatePublisherDisplayConfigurationMutationOptions
  ) => Promise<void>;
  deletePublisherDisplayConfiguration: (id: string) => Promise<void>;
}

const PublisherPortalContext =
  createContext<PublisherPortalContextValue | null>(null);

interface PublisherPortalWrapperProps {
  children: ReactNode;
  organizationId?: string;
}

export const PublisherPortalWrapper: React.FC<PublisherPortalWrapperProps> = ({
  children,
  organizationId,
}) => {
  const isAdmin = useMemo(() => organizationId !== undefined, [organizationId]);

  const queryVariabes = useMemo(
    () => ({
      variables: {
        id: organizationId ?? "",
        isAdmin,
      },
    }),
    [organizationId, isAdmin]
  );

  const { organizationId: currentUserOrganizationId } = useUserRole();
  const { organizationUserEmails } = useOrganizationUserEmails({
    organizationId: isAdmin ? organizationId : currentUserOrganizationId,
  });
  const {
    data: activationConfigurationsQueryData,
    refetch: refetchActivationConfigurations,
  } = useActivationConfigurationsQuery(queryVariabes);
  const activationConfigurations = useMemo(
    () =>
      (isAdmin
        ? activationConfigurationsQueryData?.otherOrganization
            ?.activationConfigurations.nodes
        : activationConfigurationsQueryData?.ownOrganization.organization
            ?.activationConfigurations.nodes) ?? [],
    [isAdmin, activationConfigurationsQueryData]
  );
  // Need to evict availablePublishers from the cache when a publisher display configuration
  // or an activation configuration is created or deleted
  const commonMutationOptions: Pick<MutationBaseOptions, "update"> = {
    update: (cache) => {
      cache.evict({ fieldName: "availablePublishers" });
    },
  };
  const [createActivationConfigurationMutation] =
    useCreateActivationConfigurationMutation(commonMutationOptions);
  const [deleteActivationConfigurationMutation] =
    useDeleteActivationConfigurationMutation(commonMutationOptions);
  const { activeMarkets: publisherMarkets } = useActiveMarkets();
  const {
    data: publisherDisplayConfigurationQueryData,
    loading: isPublisherDisplayConfigurationLoading,
  } = useGetPublisherDisplayConfigurationQuery(queryVariabes);
  const publisherDisplayConfiguration = useMemo(
    () =>
      (isAdmin
        ? publisherDisplayConfigurationQueryData?.otherOrganization
            ?.publisherDisplayConfiguration
        : publisherDisplayConfigurationQueryData?.ownOrganization?.organization
            ?.publisherDisplayConfiguration) ?? null,
    [isAdmin, publisherDisplayConfigurationQueryData]
  );

  const [createPublisherDisplayConfigurationMutation] =
    useCreatePublisherDisplayConfigurationMutation(commonMutationOptions);
  const [deletePublisherDisplayConfigurationMutation] =
    useDeletePublisherDisplayConfigurationMutation(commonMutationOptions);
  const [updatePublisherDisplayConfigurationMutation] =
    useUpdatePublisherDisplayConfigurationMutation(commonMutationOptions);

  const value = useMemo(
    (): PublisherPortalContextValue => ({
      activationConfigurations,
      addActivationConfiguration: async (
        mutationParams: CreateActivationConfigurationMutationOptions
      ) => {
        const variables = mutationParams.variables!;
        await createActivationConfigurationMutation({
          ...mutationParams,
          refetchQueries: [
            ActivationConfigurationsDocument.definitions[0].name.value,
          ],
          variables: {
            input: {
              ...variables?.input,
            },
          },
        });
      },
      createPublisherDisplayConfiguration: async (
        mutationParams: CreatePublisherDisplayConfigurationMutationOptions
      ) => {
        const variables = mutationParams.variables!;
        await createPublisherDisplayConfigurationMutation({
          ...mutationParams,
          refetchQueries: [
            GetPublisherDisplayConfigurationDocument.definitions[0].name.value,
          ],
          variables: {
            input: {
              ...variables?.input,
              organizationId,
            },
          },
        });
      },
      deleteActivationConfiguration: async (id: string) => {
        await deleteActivationConfigurationMutation({
          refetchQueries: [
            ActivationConfigurationsDocument.definitions[0].name.value,
          ],
          variables: {
            id,
          },
        });
      },
      deletePublisherDisplayConfiguration: async (id: string) => {
        await deletePublisherDisplayConfigurationMutation({
          refetchQueries: [
            GetPublisherDisplayConfigurationDocument.definitions[0].name.value,
          ],
          variables: {
            id,
          },
        }).then(() => refetchActivationConfigurations());
      },
      isPublisherDisplayConfigurationLoading,
      organizationUserEmails,
      publisherDisplayConfiguration,
      publisherMarkets,
      updatePublisherDisplayConfiguration: async (
        mutationParams: UpdatePublisherDisplayConfigurationMutationOptions
      ) => {
        await updatePublisherDisplayConfigurationMutation({
          refetchQueries: [
            GetPublisherDisplayConfigurationDocument.definitions[0].name.value,
          ],
          ...mutationParams,
        });
      },
    }),
    [
      activationConfigurations,
      organizationUserEmails,
      publisherDisplayConfiguration,
      isPublisherDisplayConfigurationLoading,
      createActivationConfigurationMutation,
      refetchActivationConfigurations,
      organizationId,
      publisherMarkets,
      createPublisherDisplayConfigurationMutation,
      deleteActivationConfigurationMutation,
      deletePublisherDisplayConfigurationMutation,
      updatePublisherDisplayConfigurationMutation,
    ]
  );
  return (
    <PublisherPortalContext.Provider value={value}>
      {children}
    </PublisherPortalContext.Provider>
  );
};

export const usePublisherPortal = () => {
  const contextValue = useContext(PublisherPortalContext);
  if (contextValue === null) {
    throw new Error(
      "usePublisherPortal must be used within a PublisherPortalWrapper"
    );
  }
  return contextValue;
};
