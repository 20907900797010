export const delay = (ms: number): Promise<void> => {
  return new Promise<void>((resolve) => {
    setTimeout(() => resolve(), ms);
  });
};

export const noSoonerThan = async <T>(
  ms: number,
  promise: Promise<T>
): Promise<T> => {
  const [result] = await Promise.all([promise, delay(ms)]);
  return result;
};
