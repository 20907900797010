import { EMAIL_REGEXP } from "constants/index";
import { useOrganizationDomainsQuery } from "@decentriq/graphql/dist/hooks";
import { ExternalInvitationFragment } from "@decentriq/graphql/dist/types";
import { FormControl, FormHelperText, FormLabel, Input } from "@mui/joy";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { AdminDialog } from "components/base";
import { CommonSnackbarOrigin, useGeneralSnackbar } from "hooks";
import useSendExternalInvitation from "./useSendExternalInvitation/useSendExternalInvitation";

type SendInvitationDialogProps = {
  organizationId: string;
  open: boolean;
  onCancel: () => void;
};

const SendInvitationDialog: React.FC<SendInvitationDialogProps> = ({
  onCancel,
  open,
  organizationId,
}) => {
  const [email, setEmail] = useState<string>("");
  const [isTouched, setIsTouched] = useState<boolean>(false);

  const { enqueueSnackbar } = useGeneralSnackbar({
    origin: CommonSnackbarOrigin.ADMIN,
  });

  const { data: organizationDomainsData } = useOrganizationDomainsQuery({
    variables: { organizationId },
  });
  const organizationDomains = useMemo(
    () => organizationDomainsData?.organization?.domains || [],
    [organizationDomainsData]
  );

  const [sendExternalInvitation, { loading }] = useSendExternalInvitation({
    onCompleted: () => {
      enqueueSnackbar(`External user invitation has been successfully sent.`);
      onCancel();
    },
    onError: (error) =>
      enqueueSnackbar(`External user invitation could not be sent.`, {
        context: error?.message,
        persist: true,
        variant: "error",
      }),
    organizationId,
    variables: {
      input: {
        email,
      },
    },
  });

  const handleSendExternalInvitation = useCallback(() => {
    sendExternalInvitation({
      update: (cache, { data }) => {
        // Add invitation to the list
        cache.modify({
          fields: {
            userInvitations: (existing = {}) => {
              const userInvitationRef = cache.writeFragment({
                data: data?.organization?.createExternalUser?.record,
                fragment: ExternalInvitationFragment,
              });
              return {
                ...existing,
                nodes: [userInvitationRef, ...(existing?.nodes || [])],
              };
            },
          },
          id: cache.identify({
            __typename: "Organization",
            id: organizationId,
          }),
        });
      },
    });
  }, [organizationId, sendExternalInvitation]);

  const handleEmailChange = useCallback(
    ({ target }: React.ChangeEvent<HTMLInputElement>) => {
      setEmail(target.value);
      if (!isTouched) setIsTouched(true);
    },
    [isTouched]
  );

  const withEmailValidationError = !email.match(EMAIL_REGEXP);
  // Emails for invitations should not be same as the organization domain (those users should be added thru Add User)
  const withDomainValidationError = organizationDomains.some(
    (organizationDomain) => {
      const [, domain] = email.split("@");
      return organizationDomain === domain;
    }
  );

  const withError = withEmailValidationError || withDomainValidationError;

  useEffect(() => {
    // Clear email field state whenever modal is closed
    setEmail("");
    setIsTouched(false);
  }, [open]);

  return (
    <AdminDialog
      confirmButtonText="Send invitation"
      disabled={withError || !email}
      loading={loading}
      onClose={onCancel}
      onConfirm={handleSendExternalInvitation}
      open={open}
      title="Send external invitation"
    >
      <FormControl error={isTouched && (withError || !email)}>
        <FormLabel>Email</FormLabel>
        <Input onChange={handleEmailChange} placeholder="Email" value={email} />
        <FormHelperText>
          {isTouched
            ? !email
              ? "Email is required"
              : withEmailValidationError
                ? "Email is invalid"
                : withDomainValidationError
                  ? "Please add this organization user via the Users tab"
                  : ""
            : ""}
        </FormHelperText>
      </FormControl>
    </AdminDialog>
  );
};

SendInvitationDialog.displayName = "SendInvitationDialog";

export default memo(SendInvitationDialog);
