import { faCheckCircle, faWarning } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Chip } from "@mui/joy";
import { memo } from "react";

interface DataLabStatusLabelProps {
  isValidated: boolean;
}

const DataLabStatusLabel: React.FC<DataLabStatusLabelProps> = ({
  isValidated,
}) => {
  return (
    <Chip
      color={isValidated ? "success" : "warning"}
      startDecorator={
        <FontAwesomeIcon
          fixedWidth={true}
          icon={isValidated ? faCheckCircle : faWarning}
        />
      }
    >
      {isValidated ? "Validated" : "Not validated"}
    </Chip>
  );
};

DataLabStatusLabel.displayName = "DataLabStatusLabel";

export default memo(DataLabStatusLabel);
