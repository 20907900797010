import {
  useCreateDraftTableLeafNodeColumnMutation,
  useCreateDraftTableLeafNodeMutation,
  useDeleteDraftTableLeafNodeColumnMutation,
  useDeleteDraftTableLeafNodeMutation,
  useDraftDataRoomDataNodesQuery,
  useUpdateDraftTableLeafNodeColumnMutation,
  useUpdateDraftTableLeafNodeMutation,
  useUpdateDraftTableLeafNodeNameMutation,
} from "@decentriq/graphql/dist/hooks";
import {
  ColumnDataType,
  DraftDataRoomDataNodesDocument,
  type DraftTableLeafNode,
  TableColumnFormatType,
  TableColumnHashingAlgorithm,
  type UpdateDraftTableLeafNodeMutation,
  type UpdateDraftTableLeafNodeMutationVariables,
} from "@decentriq/graphql/dist/types";
import { useCallback, useMemo } from "react";
import { v4 as uuidv4 } from "uuid";
import { mapDraftDataRoomErrorToSnackbar, useDataRoomSnackbar } from "hooks";
import {
  type DataRoomData,
  type DataRoomDataDefinition,
  type DataRoomDataTable,
  type DataRoomTableColumnDefinition,
} from "models";

interface TableNodeActionsHookParams {
  dataRoomId: string;
}

export const useTableNodeAcitons = ({
  dataRoomId,
}: TableNodeActionsHookParams) => {
  const { enqueueSnackbar } = useDataRoomSnackbar();

  const { data: dataRoomData } = useDraftDataRoomDataNodesQuery({
    skip: !dataRoomId,
    variables: {
      dataRoomId,
    },
  });

  const dataNodesById = useMemo(() => {
    const dataNodesById =
      dataRoomData?.draftDataRoom.draftNodes?.nodes.reduce(
        (acc, node) => {
          if (node.__typename === "DraftTableLeafNode") {
            acc[node.id] = node;
          }
          return acc;
        },
        {} as Record<
          string,
          Pick<
            DraftTableLeafNode,
            "isRequired" | "columnsOrder" | "id" | "name"
          >
        >
      ) ?? {};
    return dataNodesById;
  }, [dataRoomData]);

  /// mutations declarations
  const [createDraftTableLeafNode] = useCreateDraftTableLeafNodeMutation({
    onError: (error) => {
      enqueueSnackbar(
        ...mapDraftDataRoomErrorToSnackbar(error, "Table could not be created.")
      );
    },
    refetchQueries: [
      { query: DraftDataRoomDataNodesDocument, variables: { dataRoomId } },
    ],
  });
  const [deleteDraftTableLeafNode] = useDeleteDraftTableLeafNodeMutation({
    onError: (error) => {
      enqueueSnackbar(
        ...mapDraftDataRoomErrorToSnackbar(error, "Table could not be deleted.")
      );
    },
    refetchQueries: [
      { query: DraftDataRoomDataNodesDocument, variables: { dataRoomId } },
    ],
  });
  const [createDraftTableLeafNodeColumn] =
    useCreateDraftTableLeafNodeColumnMutation({
      onError: (error) => {
        enqueueSnackbar(
          ...mapDraftDataRoomErrorToSnackbar(
            error,
            "Table column could not be created."
          )
        );
      },
      refetchQueries: [
        { query: DraftDataRoomDataNodesDocument, variables: { dataRoomId } },
      ],
    });
  const [deleteDraftTableLeafNodeColumn] =
    useDeleteDraftTableLeafNodeColumnMutation({
      onError: (error) => {
        enqueueSnackbar(
          ...mapDraftDataRoomErrorToSnackbar(
            error,
            "Table column could not be deleted."
          )
        );
      },
      refetchQueries: [
        { query: DraftDataRoomDataNodesDocument, variables: { dataRoomId } },
      ],
    });
  const [updateDraftTableLeafNode] = useUpdateDraftTableLeafNodeMutation({
    onError: (error) => {
      enqueueSnackbar(
        ...mapDraftDataRoomErrorToSnackbar(error, "Table could not be updated.")
      );
    },
  });
  const [updateDraftTableLeafNodeName] =
    useUpdateDraftTableLeafNodeNameMutation({
      onError: (error) => {
        enqueueSnackbar(
          ...mapDraftDataRoomErrorToSnackbar(
            error,
            "Table could not be updated."
          )
        );
      },
      refetchQueries: [
        { query: DraftDataRoomDataNodesDocument, variables: { dataRoomId } },
      ],
    });
  const [updateDraftTableLeafNodeColumn] =
    useUpdateDraftTableLeafNodeColumnMutation({
      onError: (error) => {
        enqueueSnackbar(
          ...mapDraftDataRoomErrorToSnackbar(
            error,
            "Table column could not be updated."
          )
        );
      },
      refetchQueries: [
        { query: DraftDataRoomDataNodesDocument, variables: { dataRoomId } },
      ],
    });
  const handleColumnsOrderUpdate = useCallback(
    (node: { id: string; columnsOrder: string[] }) =>
      updateDraftTableLeafNode({
        variables: {
          input: {
            ...node,
          },
        },
      }),
    [updateDraftTableLeafNode]
  );
  /// handlers
  return useMemo(
    () => ({
      handleColumnCreate: (
        nodeId: string,
        {
          nullable,
          name,
          primitiveType,
          formatType,
        }: DataRoomTableColumnDefinition
      ) => {
        return createDraftTableLeafNodeColumn({
          variables: {
            id: nodeId,
            input: {
              dataType: primitiveType!,
              draftTableLeafNodeId: nodeId,
              formatType,
              isNullable: nullable,
              name,
            },
          },
        });
      },
      handleColumnDataTypeUpdate: (
        id: string,
        primitiveType: ColumnDataType,
        formatType?: TableColumnFormatType
      ) =>
        updateDraftTableLeafNodeColumn({
          variables: {
            input: {
              dataType: primitiveType,
              formatType: {
                value: formatType,
              },
              hashWith:
                formatType &&
                [
                  TableColumnFormatType.DateIso8601,
                  TableColumnFormatType.Float,
                  TableColumnFormatType.HashSha256Hex,
                  TableColumnFormatType.Integer,
                  TableColumnFormatType.String,
                ].includes(formatType)
                  ? { value: undefined }
                  : undefined,
              id,
            },
          },
        }),
      handleColumnDelete: (id: string) =>
        deleteDraftTableLeafNodeColumn({
          variables: {
            id,
          },
        }),
      handleColumnHashWithUpdate: (id: string, hashWith: boolean) =>
        updateDraftTableLeafNodeColumn({
          variables: {
            input: {
              hashWith: hashWith
                ? { value: TableColumnHashingAlgorithm.Sha256Hex }
                : { value: undefined },
              id,
            },
          },
        }),
      handleColumnNameUpdate: (id: string, name: string) =>
        updateDraftTableLeafNodeColumn({
          variables: {
            input: {
              id,
              name,
            },
          },
        }),
      handleColumnNullableUpdate: (id: string, nullable: boolean) =>
        updateDraftTableLeafNodeColumn({
          variables: {
            input: {
              id,
              isNullable: nullable,
            },
          },
        }),
      handleColumnsOrderUpdate,
      handleConstraintUpdate: async (node: {
        id: string;
        isRequired: boolean;
      }) => {
        await updateDraftTableLeafNode({
          optimisticResponse: ({
            input,
          }: UpdateDraftTableLeafNodeMutationVariables): UpdateDraftTableLeafNodeMutation => {
            const originalNode = dataNodesById[node.id];
            // Cannot bail from an optimistic response,
            // this feature is only available in @apollo/client v3.9
            if (!originalNode) {
              return null as unknown as UpdateDraftTableLeafNodeMutation;
            }
            return {
              __typename: "Mutation",
              draftTableLeafNode: {
                update: {
                  __typename: "DraftTableLeafNode",
                  ...dataNodesById[node.id],
                  id: node.id,
                  // TODO add utility that removes null and undefined values
                  ...(input.isRequired !== undefined &&
                  input.isRequired !== null
                    ? { isRequired: input.isRequired }
                    : {}),
                  ...(input.columnsOrder !== undefined &&
                  input.columnsOrder !== null
                    ? { columnsOrder: input.columnsOrder }
                    : {}),
                },
              },
            };
          },
          variables: {
            input: {
              ...node,
            },
          },
        });
      },
      handleCreate: async ({ name }: DataRoomDataDefinition) => {
        const result = await createDraftTableLeafNode({
          variables: {
            input: {
              columns: [
                {
                  dataType: ColumnDataType.Text,
                  isNullable: true,
                  name: "Column 1",
                },
              ],
              draftDataRoomId: dataRoomId,
              id: uuidv4(),
              isRequired: true,
              name,
            },
          },
        });
        return result.data?.dataNode?.create?.id as string | undefined;
      },
      handleDelete: ({ id }: DataRoomData) =>
        deleteDraftTableLeafNode({
          variables: {
            id,
          },
        }),
      handleDuplicate: async ({
        isRequired,
        name,
        columns,
        columnsOrder,
      }: DataRoomDataTable) => {
        const previousColumnOrder =
          columnsOrder && columnsOrder.length
            ? columnsOrder
            : columns.map(({ id }) => id);
        return createDraftTableLeafNode({
          variables: {
            input: {
              columns: [...columns]
                .sort(
                  (a, b) =>
                    previousColumnOrder.indexOf(a.id) -
                    previousColumnOrder.indexOf(b.id)
                )
                .map((c) => {
                  return {
                    dataType: c.primitiveType!,
                    isNullable: c.nullable,
                    name: c.name,
                  };
                }),
              draftDataRoomId: dataRoomId,
              id: uuidv4(),
              isRequired,
              name,
            },
          },
        });
      },
      handleNameUpdate: (node: { id: string; name: string }) =>
        updateDraftTableLeafNodeName({
          optimisticResponse: {
            __typename: "Mutation",
            draftTableLeafNode: {
              update: {
                __typename: "DraftTableLeafNode",
                ...node,
              },
            },
          },
          variables: {
            input: {
              ...node,
            },
          },
        }),
    }),
    [
      handleColumnsOrderUpdate,
      createDraftTableLeafNodeColumn,
      updateDraftTableLeafNodeColumn,
      deleteDraftTableLeafNodeColumn,
      dataNodesById,
      updateDraftTableLeafNode,
      createDraftTableLeafNode,
      dataRoomId,
      deleteDraftTableLeafNode,
      updateDraftTableLeafNodeName,
    ]
  );
};
