import { faBuildings } from "@fortawesome/pro-light-svg-icons";
import { memo } from "react";
import { SidebarMenuItem } from "features/sidebar";
import { useUserRole } from "hooks";

const AdminPortalSidebarMenuItem = () => {
  const { isSuperAdmin, isSuperAdminReadOnly } = useUserRole();
  return (
    <SidebarMenuItem
      data-testid="sidebar_admin_portal"
      icon={faBuildings}
      innerMenuItems={
        isSuperAdmin || isSuperAdminReadOnly
          ? [
              { label: "Organizations", to: "/admin/organizations" },
              { label: "Users", to: "/admin/users" },
            ]
          : []
      }
      label="Admin"
      to="/admin"
      tooltipTitle="Admin"
    />
  );
};

AdminPortalSidebarMenuItem.displayName = "AdminPortalSidebarMenuItem";

export default memo(AdminPortalSidebarMenuItem);
