import {
  type AppState,
  Auth0Provider,
  useAuth0,
  withAuthenticationRequired,
} from "@auth0/auth0-react";
import { Box } from "@mui/joy";
import React, { memo } from "react";
import { useNavigate } from "react-router-dom";
import { Loading as Auth0Loading, UnauthorizedDialog } from "components";
import { useConfiguration } from "contexts";
import { clearDecentriqStorage } from "utils";

const Auth0Wrapper = memo<React.PropsWithChildren>(({ children }) => {
  const { configuration } = useConfiguration();
  const navigate = useNavigate();
  const onRedirectCallback = (appState?: AppState | undefined) => {
    navigate(appState?.returnTo || window.location.pathname, { replace: true });
  };
  // If an enclave token is not available we trigger a more complex authentication flow,
  // the goal of which is to gather the right tokens from Auth0 and exchange those for
  // an enclave token.
  return (
    <Auth0Provider
      authorizationParams={{
        audience: configuration.auth0Audience,
        redirect_uri: window.location.origin,
      }}
      clientId={configuration.auth0ClientId}
      domain={configuration.auth0Domain}
      onRedirectCallback={onRedirectCallback}
      useRefreshTokens={true}
    >
      <Auth0Consumer>{children}</Auth0Consumer>
    </Auth0Provider>
  );
});
Auth0Wrapper.displayName = "Auth0Wrapper";

const Auth0Error = memo<{ message?: string }>(
  ({ message = "Unknown error" }) => {
    const { logout } = useAuth0();
    return (
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          height: "100%",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <UnauthorizedDialog
          error={message}
          onClose={() => {
            clearDecentriqStorage();
            logout({ logoutParams: { returnTo: window.location.origin } });
          }}
          open={true}
        />
      </Box>
    );
  }
);
Auth0Error.displayName = "Auth0Error";

const Auth0Consumer = memo<React.PropsWithChildren>(({ children }) => {
  const { error, isLoading } = useAuth0();
  return withAuthenticationRequired(() =>
    error ? (
      <Auth0Error message={error?.message} />
    ) : isLoading ? (
      <Auth0Loading />
    ) : (
      children
    )
  )({});
});
Auth0Consumer.displayName = "Auth0Consumer";

export default Auth0Wrapper;
