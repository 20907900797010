import { type Session } from "@decentriq/core";
import {
  useQuery,
  useQueryClient,
  type UseQueryResult,
} from "@tanstack/react-query";
import * as forge from "node-forge";
import { type SnackbarKey } from "notistack";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  MediaDataRoomJobInput,
  useMediaDataRoomRequest,
} from "features/mediaDataRoom/hooks";
import {
  PublishedDatasetsHashes,
  type PublisherDatasetsHashes,
} from "features/mediaDataRoom/models";
import { mapMediaDataRoomErrorToSnackbar, useDataRoomSnackbar } from "hooks";

export type DatasetsHookResult = Omit<
  UseQueryResult<PublishedDatasetsHashes, Error>,
  "data"
> & {
  data: PublishedDatasetsHashes;
  updateAudiencesDatasetHash: (value: string | null) => void;
  updateAdvertiserDatasetHash: (value: string | null) => void;
  updatePublisherDatasetsHashes: (
    value: PublisherDatasetsHashes | null
  ) => void;
  updatePublishedDatasetsHashes: (
    value: PublishedDatasetsHashes | null
  ) => void;
  fetch: () => Promise<PublishedDatasetsHashes>;
};

interface DatasetsHookPayload {
  dataRoomId: string;
  driverAttestationHash: string;
  session: Session | undefined;
  refetchDataLab?: () => void;
}

const useDatasets = ({
  dataRoomId,
  driverAttestationHash,
  session,
  refetchDataLab,
}: DatasetsHookPayload): DatasetsHookResult => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar, closeSnackbar } = useDataRoomSnackbar();
  const [retrievePublishedDatasets] = useMediaDataRoomRequest({
    dataRoomId,
    driverAttestationHash,
    key: "retrievePublishedDatasets",
    requestCreator: useCallback(
      (dataRoomIdHex: string) => ({ dataRoomIdHex }),
      []
    ),
  });
  const setErrorSnackbarId = useState<SnackbarKey | undefined>()[1];
  const queryKey = useMemo(
    () =>
      MediaDataRoomJobInput.create(
        "retrievePublishedDatasets",
        dataRoomId,
        driverAttestationHash
      )
        .withFetchResultsSubkey()
        .buildQueryKey(),
    [dataRoomId, driverAttestationHash]
  );
  const updateAudiencesDatasetHash = useCallback(
    (audiencesDatasetHash: string | null) =>
      queryClient.setQueryData<PublishedDatasetsHashes>(queryKey, (oldData) =>
        (oldData ?? new PublishedDatasetsHashes()).updateAudiencesDatasetHash(
          audiencesDatasetHash
        )
      ),
    [queryKey, queryClient]
  );
  const updateAdvertiserDatasetHash = useCallback(
    (advertiserDatasetHash: string | null) => {
      queryClient.setQueryData<PublishedDatasetsHashes>(queryKey, (oldData) =>
        (oldData ?? new PublishedDatasetsHashes()).updateAdvertiserDatasetHash(
          advertiserDatasetHash
        )
      );
    },
    [queryKey, queryClient]
  );
  const updatePublishedDatasetsHashes = useCallback(
    (value: PublishedDatasetsHashes | null) => {
      queryClient.setQueryData<PublishedDatasetsHashes>(
        queryKey,
        () => value ?? new PublishedDatasetsHashes()
      );
    },
    [queryKey, queryClient]
  );
  const updatePublisherDatasetsHashes = useCallback(
    (publisherDatasetsHashes: PublisherDatasetsHashes | null) => {
      queryClient.setQueryData<PublishedDatasetsHashes>(queryKey, (oldData) =>
        (
          oldData ?? new PublishedDatasetsHashes()
        ).updatePublisherDatasetsHashes(
          publisherDatasetsHashes ??
            PublishedDatasetsHashes.defaultPublisherDatasetsHashes
        )
      );
      refetchDataLab?.();
    },
    [queryKey, queryClient, refetchDataLab]
  );
  const handleFetch = useCallback<DatasetsHookResult["fetch"]>(async () => {
    const {
      audiencesDatasetHashHex: advertiserDatasetHash = null,
      demographicsDatasetHashHex: demographicsDatasetHash = null,
      embeddingsDatasetHashHex: embeddingsDatasetHash = null,
      segmentsDatasetHashHex: segmentsDatasetHash = null,
      matchingDatasetHashHex: matchingDatasetHash = null,
    } = await retrievePublishedDatasets({ options: { session } });
    const audiencesDatasetHash = (
      await session?.retrievePublishedDatasets(dataRoomId)
    )?.publishedDatasets?.find(
      ({ leafId }) => leafId === "audiences.json"
    )?.datasetHash;
    const publisherDatasetsHashes = {
      demographicsDatasetHash,
      embeddingsDatasetHash,
      matchingDatasetHash,
      segmentsDatasetHash,
    };
    const hashes = new PublishedDatasetsHashes(
      advertiserDatasetHash,
      publisherDatasetsHashes,
      audiencesDatasetHash
        ? forge.util.binary.hex.encode(audiencesDatasetHash)
        : null
    );
    return hashes;
  }, [retrievePublishedDatasets, session, dataRoomId]);
  const queryResult = useQuery({
    enabled: Boolean(session),
    queryFn: handleFetch,
    queryKey,
  });
  useEffect(() => {
    if (queryResult.error) {
      const snackbarId = enqueueSnackbar(
        ...mapMediaDataRoomErrorToSnackbar(
          queryResult.error,
          `Unable to fetch datasets`
        )
      );
      setErrorSnackbarId(snackbarId);
    } else {
      setErrorSnackbarId((snackbarId) => {
        if (snackbarId) {
          closeSnackbar(snackbarId);
        }
        return undefined;
      });
    }
  }, [enqueueSnackbar, closeSnackbar, setErrorSnackbarId, queryResult.error]);
  return useMemo(
    () => ({
      ...queryResult,
      data: queryResult.data ?? new PublishedDatasetsHashes(),
      fetch: handleFetch,
      updateAdvertiserDatasetHash,
      updateAudiencesDatasetHash,
      updatePublishedDatasetsHashes,
      updatePublisherDatasetsHashes,
    }),
    [
      queryResult,
      updateAudiencesDatasetHash,
      updateAdvertiserDatasetHash,
      updatePublisherDatasetsHashes,
      updatePublishedDatasetsHashes,
      handleFetch,
    ]
  );
};

export default useDatasets;
