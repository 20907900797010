
URL = globalThis.URL
import __vite__wasmUrl from "/home/runner/work/delta/delta/avato-backend/frontend/sql-deps-extraction/build/sql_deps_extraction_bg.wasm?url"
import __vite__initWasm from "/__vite-plugin-wasm-helper"

import { __wbindgen_string_new as __vite__wasmImport_0_0 } from "./sql_deps_extraction_bg.js";
const __vite__wasmModule = await __vite__initWasm({ "./sql_deps_extraction_bg.js": { __wbindgen_string_new: __vite__wasmImport_0_0 } }, __vite__wasmUrl);
export const memory = __vite__wasmModule.memory;
export const get_dependencies = __vite__wasmModule.get_dependencies;
export const get_native_sql_worker_specific_features = __vite__wasmModule.get_native_sql_worker_specific_features;
export const __wbindgen_add_to_stack_pointer = __vite__wasmModule.__wbindgen_add_to_stack_pointer;
export const __wbindgen_malloc = __vite__wasmModule.__wbindgen_malloc;
export const __wbindgen_realloc = __vite__wasmModule.__wbindgen_realloc;
export const __wbindgen_free = __vite__wasmModule.__wbindgen_free;
