import {
  type ApiCoreSessionManager,
  type media_insights_request,
} from "@decentriq/core";
import { type OverlapInsightsCacheKey, type PublishedDatasets } from "./models";

// TODO @matyasfodor cache this
export async function retrievePublishedDatasets(
  sessionManager: ApiCoreSessionManager,
  dataRoomId: string,
  driverAttestationHash: string
): Promise<PublishedDatasets> {
  const session = await sessionManager.get({ driverAttestationHash });
  const request: media_insights_request.MediaInsightsRequest = {
    retrievePublishedDatasets: {
      dataRoomIdHex: dataRoomId,
    },
  };
  const response = await session.sendMediaInsightsRequest(request);
  if (!("retrievePublishedDatasets" in response)) {
    throw new Error("Expected retrievePublishedDatasets response");
  }
  return {
    advertiserDatasetHash:
      response.retrievePublishedDatasets.advertiserDatasetHashHex,
    publisherDemographicsDatasetHash:
      response.retrievePublishedDatasets.demographicsDatasetHashHex,
    publisherEmbeddingsDatasetHash:
      response.retrievePublishedDatasets.embeddingsDatasetHashHex,
    publisherSegmentsDatasetHash:
      response.retrievePublishedDatasets.segmentsDatasetHashHex,
    publisherUsersDatasetHash:
      response.retrievePublishedDatasets.publisherDatasetHashHex,
  };
}

export async function getOverlapInsightsCacheKey(
  sessionManager: ApiCoreSessionManager,
  dataRoomId: string,
  driverAttestationHash: string
): Promise<OverlapInsightsCacheKey | null> {
  const datasets = await retrievePublishedDatasets(
    sessionManager,
    dataRoomId,
    driverAttestationHash
  );

  if (
    datasets.advertiserDatasetHash == null ||
    datasets.publisherUsersDatasetHash == null
  ) {
    return null;
  }

  return {
    advertiserDatasetHash: datasets.advertiserDatasetHash,
    dataRoomId,
    ...(datasets.publisherSegmentsDatasetHash && {
      publisherSegmentsDatasetHash: datasets.publisherSegmentsDatasetHash,
    }),
    publisherUsersDatasetHash: datasets.publisherUsersDatasetHash,
    ...(datasets.publisherDemographicsDatasetHash && {
      publisherDemographicsDatasetHash:
        datasets.publisherDemographicsDatasetHash,
    }),
    ...(datasets.publisherEmbeddingsDatasetHash && {
      publisherEmbeddingsDatasetHash: datasets.publisherEmbeddingsDatasetHash,
    }),
  };
}
