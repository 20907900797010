import { memo } from "react";
import { Navigate, useRoutes } from "react-router-dom";
import { AdminPortal, AdminPortalTabs } from "features/admin";
import { OrganizationPage } from "pages";

const AdminPortalPage: React.FC = () => {
  const element = useRoutes([
    {
      element: <AdminPortal activeTab={AdminPortalTabs.ORGANIZATIONS} />,
      path: AdminPortalTabs.ORGANIZATIONS,
    },
    {
      element: <AdminPortal activeTab={AdminPortalTabs.USERS} />,
      path: AdminPortalTabs.USERS,
    },
    {
      element: <OrganizationPage />,
      path: "organizations/:organizationId/*",
    },
    {
      element: <Navigate to="/admin/organizations" />,
      path: "*",
    },
  ]);

  return element;
};

AdminPortalPage.displayName = "AdminPortalPage";

export default memo(AdminPortalPage);
