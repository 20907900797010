import { type Session } from "@decentriq/core";
import type JSZip from "jszip";
import { type SnackbarKey } from "notistack";
import { useCallback, useEffect, useState } from "react";
import {
  type MediaDataRoomJobHookResult,
  MediaDataRoomJobInput,
  useMediaDataRoomJob,
} from "features/mediaDataRoom/hooks";
import { type PublishedDatasetsHashes } from "features/mediaDataRoom/models";
import { mapMediaDataRoomErrorToSnackbar, useDataRoomSnackbar } from "hooks";

interface OverlapStatistic {
  audience_type: string;
  advertiser_size: number;
  overlap_size: number;
}

interface OverlapStatisticsCollection {
  overlap_statistics: OverlapStatistic[];
  total_publisher: number;
}

interface AudienceOverlapStatisticsRaw {
  advertiserSize: number;
  audienceType: string;
  overlapSize: number;
}

interface OverlapStatisticsHookPayload {
  dataRoomId: string;
  driverAttestationHash: string;
  publishedDatasetsHashes: PublishedDatasetsHashes;
  session?: Session;
  skip: boolean;
}

export interface OverlapStatisticsData {
  statistics: AudienceOverlapStatisticsRaw[];
  isEmpty: boolean;
  totalPublisherCount: number;
  withNoiseCalculation: boolean;
}

export type OverlapStatisticsHookResult =
  MediaDataRoomJobHookResult<OverlapStatisticsData>;

const useOverlapStatistics = ({
  dataRoomId,
  driverAttestationHash,
  publishedDatasetsHashes,
  session,
  skip,
}: OverlapStatisticsHookPayload): OverlapStatisticsHookResult => {
  const { enqueueSnackbar, closeSnackbar } = useDataRoomSnackbar();
  const setErrorSnackbarId = useState<SnackbarKey | undefined>()[1];
  const transform = useCallback(
    async (zip: JSZip): Promise<OverlapStatisticsData> => {
      const overlapInsightsStatisticsFile = zip.file("overlap.json");
      if (overlapInsightsStatisticsFile === null) {
        throw new Error("overlap.json not found in zip");
      }
      const overlapInsightsStatistics: OverlapStatisticsCollection = JSON.parse(
        await overlapInsightsStatisticsFile.async("string")
      );
      const formattedStatistics: AudienceOverlapStatisticsRaw[] = (
        overlapInsightsStatistics?.overlap_statistics || []
      ).map((overlap: OverlapStatistic) => ({
        advertiserSize: overlap.advertiser_size,
        audienceType: overlap.audience_type,
        overlapSize: overlap.overlap_size,
      }));
      return {
        isEmpty: formattedStatistics.every(
          ({ overlapSize }) => overlapSize === 0
        ),
        statistics: formattedStatistics,
        totalPublisherCount: overlapInsightsStatistics?.total_publisher,
        withNoiseCalculation: !!overlapInsightsStatistics?.total_publisher,
      };
    },
    []
  );
  const computeJob = useMediaDataRoomJob({
    input: MediaDataRoomJobInput.create(
      "computeOverlapStatistics",
      dataRoomId,
      driverAttestationHash,
      publishedDatasetsHashes
    ),
    requestCreator: useCallback(
      (dataRoomIdHex, scopeIdHex) => ({
        dataRoomIdHex,
        scopeIdHex,
      }),
      []
    ),
    session,
    skip,
    transform,
  });
  useEffect(() => {
    if (computeJob.error) {
      const snackbarId = enqueueSnackbar(
        ...mapMediaDataRoomErrorToSnackbar(
          computeJob.error,
          `Cannot fetch Overlap statistics`
        )
      );
      setErrorSnackbarId(snackbarId);
    } else {
      setErrorSnackbarId((snackbarId) => {
        if (snackbarId) {
          closeSnackbar(snackbarId);
        }
        return undefined;
      });
    }
  }, [enqueueSnackbar, closeSnackbar, setErrorSnackbarId, computeJob.error]);
  return computeJob;
};

export default useOverlapStatistics;
