import {
  DqDatasetExportFormProvider,
  type DqDatasetExportFormProviderSchemaType,
  DqDatasetSyncDrawer,
} from "@decentriq/components";
import { type DataTargetType } from "@decentriq/graphql/dist/types";
import { memo, useEffect, useState } from "react";
import { Wizard } from "react-use-wizard";
import CreateExportStep from "../CreateExportStep/CreateExportStep";
import SelectSyncStep from "../SelectSyncStep/SelectSyncStep";
import ShowStatusStep from "../ShowStatusStep/ShowStatusStep";

interface DatasetExportDialogProps {
  manifestHash: string;
  open: boolean;
  onClose: () => void;
  onSubmit: (values: DqDatasetExportFormProviderSchemaType) => Promise<void>;
}

const DatasetExportDialog: React.FC<DatasetExportDialogProps> = memo(
  ({ open, onClose, manifestHash, onSubmit }) => {
    const [syncType, setSyncType] = useState<DataTargetType | null>(null);
    useEffect(() => {
      if (!open) {
        setSyncType(null);
      }
    }, [open]);
    return (
      <DqDatasetExportFormProvider
        datasetManifestHash={manifestHash}
        syncType={syncType || undefined}
      >
        <DqDatasetSyncDrawer onClose={onClose} open={open}>
          <Wizard key={open.toString()}>
            <SelectSyncStep onClose={onClose} onSelect={[setSyncType]} />
            <CreateExportStep
              onClose={onClose}
              onSubmit={onSubmit}
              syncType={syncType!}
            />
            <ShowStatusStep onClose={onClose} syncType={syncType!} />
          </Wizard>
        </DqDatasetSyncDrawer>
      </DqDatasetExportFormProvider>
    );
  }
);

DatasetExportDialog.displayName = "DatasetExportDialog";

export default DatasetExportDialog;
