import {
  useDraftS3ConnectionParamsQuery,
  usePublishedS3ConnectionParamsQuery,
} from "@decentriq/graphql/dist/hooks";
import {
  type DraftNode,
  type DraftNodeConnection,
  type DraftS3SinkNode,
  type PublishedNode,
  type PublishedS3SinkNode,
} from "@decentriq/graphql/dist/types";
import {
  useComputeNodesVars,
  useDataRoom,
  usePublishedDataRoom,
  useRequest,
} from "contexts";
import {
  mapDraftDataRoomErrorToSnackbar,
  mapErrorToGeneralSnackbar,
  useDataRoomSnackbar,
} from "hooks";

const useS3ConnectionParams = (
  computeNodeId: string
): {
  node: DraftS3SinkNode | PublishedS3SinkNode | undefined;
  credentialsDependency: PublishedNode | DraftNode | undefined;
} => {
  const { enqueueSnackbar } = useDataRoomSnackbar();
  const { isPublished } = useDataRoom();
  const { dataRoomId: dcrHash, driverAttestationHash } = usePublishedDataRoom();
  const { commitId } = useRequest();
  const { executionContext } = useComputeNodesVars();
  const shouldUseDraft =
    !isPublished ||
    executionContext === "development" ||
    executionContext === "requests";
  // draft & development
  const { data: draftData } = useDraftS3ConnectionParamsQuery({
    fetchPolicy: "cache-only",
    onError: (error) => {
      enqueueSnackbar(
        ...mapDraftDataRoomErrorToSnackbar(
          error,
          "The computation could not be retrieved. Please try again by refreshing the page."
        )
      );
    },
    skip: !shouldUseDraft,
    variables: { computeNodeId },
  });
  // published
  const { data: publishedData } = usePublishedS3ConnectionParamsQuery({
    fetchPolicy: "cache-only",
    onError: (error) => {
      enqueueSnackbar(
        ...mapErrorToGeneralSnackbar(
          error,
          "The computation could not be retrieved. Please try again by refreshing the page."
        )
      );
    },
    skip: shouldUseDraft,
    variables: { commitId, computeNodeId, dcrHash, driverAttestationHash },
  });
  const publishedNode = publishedData?.publishedNode as
    | PublishedS3SinkNode
    | undefined;
  const draftNode = draftData?.draftNode as DraftS3SinkNode | undefined;
  return {
    credentialsDependency: shouldUseDraft
      ? (draftNode?.credentialsDependency as DraftNodeConnection)?.draftNode
      : publishedNode?.credentialsDependency,
    node: shouldUseDraft ? draftNode : publishedNode,
  };
};

export default useS3ConnectionParams;
