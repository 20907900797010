import * as forge from "node-forge";

export function computeCacheKeyString(key: any): string {
  function cacheKeyHashed(digest: forge.md.sha256.MessageDigest, key: any) {
    switch (typeof key) {
      case "string": {
        digest.update(key);
        return;
      }
      case "boolean": {
        digest.update("" + key);
        return;
      }
      case "number": {
        digest.update("" + key);
        return;
      }
      case "object": {
        if (Array.isArray(key)) {
          for (const element of key) {
            cacheKeyHashed(digest, element);
          }
        }
        for (const field of Object.getOwnPropertyNames(key).sort()) {
          cacheKeyHashed(digest, key[field]);
        }
        return;
      }
      default: {
        throw new Error(`Unexpected nested key type ${typeof key}`);
      }
    }
  }
  if (typeof key === "string") {
    return key;
  }
  const digest = forge.md.sha256.create();
  cacheKeyHashed(digest, key);
  return digest.digest().toHex();
}

export class LruCache<K, T> {
  // Use Map to implement LRU. Map keeps the insertion order, so we just need
  // to reinsert elements on read to keep them "in the front".
  private values: Map<string, T> = new Map<string, T>();
  private maxEntries: number;

  public constructor(maxEntries: number) {
    this.maxEntries = maxEntries;
  }

  private cacheKey(key: K): string {
    return computeCacheKeyString(key);
  }

  public get(key: K): T | undefined {
    const cacheKey = this.cacheKey(key);
    const entry = this.values.get(cacheKey);
    if (entry != undefined) {
      // Reinsert the element to put the just-read key to the front.
      this.values.delete(cacheKey);
      this.values.set(cacheKey, entry);
    }
    return entry;
  }

  public put(key: K, value: T) {
    if (this.values.size >= this.maxEntries) {
      const keyToDelete = this.values.keys().next().value;
      this.values.delete(keyToDelete);
    }

    this.values.set(this.cacheKey(key), value);
  }

  public evict(key: K) {
    this.values.delete(this.cacheKey(key));
  }
}

export class LruPromiseCache<K, T> {
  private cache: LruCache<K, Promise<T>>;

  public constructor(maxEntries: number) {
    this.cache = new LruCache(maxEntries);
  }

  public get(key: K): Promise<T> | undefined {
    return this.cache.get(key)?.catch((error) => {
      this.cache.evict(key);
      throw error;
    });
  }

  public put(key: K, value: Promise<T>) {
    this.cache.put(key, value);
  }

  public evict(key: K) {
    this.cache.evict(key);
  }
}

export class LruExpiringPromiseCache<K, T> {
  private cache: LruCache<K, [number, Promise<T>]>;
  public constructor(
    maxEntries: number,
    private expiryMs: number
  ) {
    this.cache = new LruCache(maxEntries);
  }

  public get(key: K): Promise<T> | undefined {
    const existing = this.cache.get(key);
    if (existing !== undefined) {
      if (Date.now() - existing[0] > this.expiryMs) {
        this.cache.evict(key);
        return undefined;
      }
      return existing[1].catch((error) => {
        this.cache.evict(key);
        throw error;
      });
    } else {
      return undefined;
    }
  }

  public put(key: K, value: Promise<T>) {
    this.cache.put(key, [Date.now(), value]);
  }

  public evict(key: K) {
    this.cache.evict(key);
  }
}
