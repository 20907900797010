import {
  DqSidePanelTitle,
  type DqSidePanelTitleToolbarMenuItem,
} from "@decentriq/components";
import {
  faFileExport as falFileExport,
  faShare as falShare,
  faTrashCan as falTrashCan,
} from "@fortawesome/pro-light-svg-icons";
import { memo } from "react";
import { useDatasetPermission } from "features/datasets/contexts";
import { DatasetDelete } from "../DatasetDelete";
import { DatasetExport } from "../DatasetExport";

const DatasetDrawerTitle = memo<{
  datasetName: string | undefined;
  manifestHash: string;
  onClose: () => void;
}>(({ datasetName, onClose, manifestHash }) => {
  const {
    openShareDialog,
    canDeleteDatasetOrDefault,
    canExportDatasetOrDefault,
    canShareDatasetOrDefault,
  } = useDatasetPermission();
  const canDeleteDataset = canDeleteDatasetOrDefault(true);
  const canExportDataset = canExportDatasetOrDefault(true);
  const canShareDataset = canShareDatasetOrDefault(false);
  const hasMenuActions =
    canDeleteDataset || canExportDataset || canShareDataset;
  return (
    <DatasetDelete
      manifestHash={manifestHash}
      renderTrigger={(openDeleteDialog) => (
        <DatasetExport
          manifestHash={manifestHash}
          renderTrigger={(openExportDialog) => (
            <DqSidePanelTitle
              ToolbarProps={{
                menuItems: hasMenuActions
                  ? ([
                      ...(canExportDataset
                        ? [
                            {
                              icon: falFileExport,
                              label: "Export dataset",
                              onClick: (closeMenu) => {
                                closeMenu();
                                openExportDialog();
                              },
                            },
                          ]
                        : []),
                      ...(canShareDataset
                        ? [
                            {
                              icon: falShare,
                              label: "Share dataset",
                              onClick: (closeMenu) => {
                                closeMenu();
                                openShareDialog();
                              },
                            },
                          ]
                        : []),
                      ...(canDeleteDataset
                        ? [
                            {
                              dangerous: true,
                              icon: falTrashCan,
                              label: "Delete dataset",
                              onClick: (closeMenu) => {
                                closeMenu();
                                openDeleteDialog();
                              },
                            },
                          ]
                        : []),
                    ] as DqSidePanelTitleToolbarMenuItem[])
                  : undefined,
              }}
              onClose={onClose}
              separated={true}
            >
              {datasetName}
            </DqSidePanelTitle>
          )}
        />
      )}
    />
  );
});

DatasetDrawerTitle.displayName = "DatasetDrawerTitle";

export default DatasetDrawerTitle;
