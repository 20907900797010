import { memo } from "react";
import {
  AdvertiserAudienceGeneratorTypeSelectionStep,
  AudienceGeneratorStepsWrapper,
} from "../components";
import {
  AdvertiserAudienceGeneratorStep,
  AdvertiserAudienceGeneratorType,
  useAdvertiserAudienceGenerator,
} from "../contexts/AdvertiserAudienceGeneratorContext";

interface AdvertiserAudienceGeneratorProps {
  LookalikeAudienceGenerator: React.ComponentType;
}

const AdvertiserAudienceGenerator = memo<AdvertiserAudienceGeneratorProps>(
  ({ LookalikeAudienceGenerator }) => {
    const { currentStep, generatorType } = useAdvertiserAudienceGenerator();
    return (
      <AudienceGeneratorStepsWrapper>
        {currentStep === AdvertiserAudienceGeneratorStep.SELECT_TYPE && (
          <AdvertiserAudienceGeneratorTypeSelectionStep />
        )}
        {generatorType === AdvertiserAudienceGeneratorType.LOOKALIKE && (
          <LookalikeAudienceGenerator />
        )}
      </AudienceGeneratorStepsWrapper>
    );
  }
);

AdvertiserAudienceGenerator.displayName = "AdvertiserAudienceGenerator";

export default AdvertiserAudienceGenerator;
