import {
  CustomSnackbarComponents,
  snackbarAnchorOrigin,
  snackbarIconVariant,
} from "@decentriq/components";
import { css } from "@emotion/css";
import { type BoxProps } from "@mui/joy";
import { type CollapseProps } from "@mui/material";
import { SnackbarProvider } from "notistack";
import { memo } from "react";

interface SnackbarWrapperProps {
  children?: React.ReactNode;
}

declare module "notistack" {
  interface VariantOverrides {
    copied: true;
    default: {
      expand?: React.ReactNode;
      action?: React.ReactNode;
      dismiss?: React.ReactNode;
      context?: React.ReactNode;
      showExpand?: boolean;
      showDismiss?: boolean;
      startExpanded?: boolean;
      ContainerProps?: BoxProps;
      IconWrapperProps?: BoxProps;
      ContentWrapperProps?: BoxProps;
      SubjectWrapperProps?: BoxProps;
      MessageWrapperProps?: BoxProps;
      ExpandWrapperProps?: BoxProps;
      ContextWrapperProps?: CollapseProps;
      ActionWrapperProps?: BoxProps;
      DismissWrapperProps?: BoxProps;
    };
    bug: {
      expand?: React.ReactNode;
      action?: React.ReactNode;
      dismiss?: React.ReactNode;
      context?: React.ReactNode;
      showExpand?: boolean;
      showDismiss?: boolean;
      startExpanded?: boolean;
      ContainerProps?: BoxProps;
      IconWrapperProps?: BoxProps;
      ContentWrapperProps?: BoxProps;
      SubjectWrapperProps?: BoxProps;
      MessageWrapperProps?: BoxProps;
      ExpandWrapperProps?: BoxProps;
      ContextWrapperProps?: CollapseProps;
      ActionWrapperProps?: BoxProps;
      DismissWrapperProps?: BoxProps;
    };
    error: {
      expand?: React.ReactNode;
      action?: React.ReactNode;
      dismiss?: React.ReactNode;
      context?: React.ReactNode;
      showExpand?: boolean;
      showDismiss?: boolean;
      startExpanded?: boolean;
      ContainerProps?: BoxProps;
      IconWrapperProps?: BoxProps;
      ContentWrapperProps?: BoxProps;
      SubjectWrapperProps?: BoxProps;
      MessageWrapperProps?: BoxProps;
      ExpandWrapperProps?: BoxProps;
      ContextWrapperProps?: CollapseProps;
      ActionWrapperProps?: BoxProps;
      DismissWrapperProps?: BoxProps;
    };
    info: {
      expand?: React.ReactNode;
      action?: React.ReactNode;
      dismiss?: React.ReactNode;
      context?: React.ReactNode;
      showExpand?: boolean;
      showDismiss?: boolean;
      startExpanded?: boolean;
      ContainerProps?: BoxProps;
      IconWrapperProps?: BoxProps;
      ContentWrapperProps?: BoxProps;
      SubjectWrapperProps?: BoxProps;
      MessageWrapperProps?: BoxProps;
      ExpandWrapperProps?: BoxProps;
      ContextWrapperProps?: CollapseProps;
      ActionWrapperProps?: BoxProps;
      DismissWrapperProps?: BoxProps;
    };
    success: {
      expand?: React.ReactNode;
      action?: React.ReactNode;
      dismiss?: React.ReactNode;
      context?: React.ReactNode;
      showExpand?: boolean;
      showDismiss?: boolean;
      startExpanded?: boolean;
      ContainerProps?: BoxProps;
      IconWrapperProps?: BoxProps;
      ContentWrapperProps?: BoxProps;
      SubjectWrapperProps?: BoxProps;
      MessageWrapperProps?: BoxProps;
      ExpandWrapperProps?: BoxProps;
      ContextWrapperProps?: CollapseProps;
      ActionWrapperProps?: BoxProps;
      DismissWrapperProps?: BoxProps;
    };
    warning: {
      expand?: React.ReactNode;
      action?: React.ReactNode;
      dismiss?: React.ReactNode;
      context?: React.ReactNode;
      showExpand?: boolean;
      showDismiss?: boolean;
      startExpanded?: boolean;
      ContainerProps?: BoxProps;
      IconWrapperProps?: BoxProps;
      ContentWrapperProps?: BoxProps;
      SubjectWrapperProps?: BoxProps;
      MessageWrapperProps?: BoxProps;
      ExpandWrapperProps?: BoxProps;
      ContextWrapperProps?: CollapseProps;
      ActionWrapperProps?: BoxProps;
      DismissWrapperProps?: BoxProps;
    };
  }
}

const SnackbarWrapper: React.FC<SnackbarWrapperProps> = memo(({ children }) => {
  return (
    <SnackbarProvider
      Components={CustomSnackbarComponents}
      anchorOrigin={snackbarAnchorOrigin}
      autoHideDuration={2000}
      classes={{
        /**
         * NOTE: Sometimes modals are rendered by snackbar's children, hence these
         * should have a higher `z-index` than the snackbar itself. Ideally snackbar
         * should be dismissed first — then this rule would become redundant.
         */
        containerRoot: css`
          z-index: 1000;
        `,
      }}
      iconVariant={snackbarIconVariant}
      maxSnack={3}
    >
      {children}
    </SnackbarProvider>
  );
});

export default SnackbarWrapper;
