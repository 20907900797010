import { useApolloClient } from "@apollo/client";
import {
  useMediaInsightsInviteParticipantsMutation,
  useSendCollaborationRequestToDataPartnerMutation,
  useSendCollaborationRequestToPublisherMutation,
} from "@decentriq/graphql/dist/hooks";
import { MyDataRoomsDocument } from "@decentriq/graphql/dist/types";
import { type PublishMediaDataRoomInput } from "@decentriq/types";
import { useSafeState } from "ahooks";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useApiCore } from "contexts";
import usePublishMediaDataRoom from "features/mediaDataRoom/containers/MediaDataRoomCreateDialog/usePublishMediaDataRoom";
import useValidateMediaDataRoom from "features/mediaDataRoom/containers/MediaDataRoomCreateDialog/useValidateMediaDataRoom";
import {
  CommonSnackbarOrigin,
  useGeneralSnackbar,
  useOrganizationPreferences,
} from "hooks";
import { dataRoomPathBuilder, DataRoomTypeNames } from "models";
import { delay, getEffectiveErrorMessage, noSoonerThan } from "utils";
import usePublishMediaInsightsDcr from "./usePublishMediaInsightsDcr";
import useValidateMediaInsightsDcr from "./useValidateMediaInsightsDcr";

type UseSubmitCreateMediaDcrFormReturn = {
  publishing: boolean;
  publishingCalled: boolean;
  publishingError: unknown | undefined;
  sendCollaborationRequest: ({
    requestRecipientId,
    message,
    receiver,
  }: {
    requestRecipientId: string;
    message: string;
    receiver: "publisher" | "dataPartner";
  }) => Promise<void>;
  submit: (mediaDataRoomInput: PublishMediaDataRoomInput) => Promise<void>;
};

const useSubmitCreateMediaDcrForm = ({
  onCancel,
}: {
  onCancel: () => void;
}): UseSubmitCreateMediaDcrFormReturn => {
  const { enableAudienceBuilder: enableAudienceBuilderOrgFlag } =
    useOrganizationPreferences();
  const { isMigrated } = useApiCore();
  const enableAudienceBuilderMediaDataRoom =
    enableAudienceBuilderOrgFlag && isMigrated;
  const navigate = useNavigate();
  const { enqueueSnackbar } = useGeneralSnackbar({
    origin: CommonSnackbarOrigin.DASHBOARD,
  });
  const apolloClient = useApolloClient();
  const [publishing, setPublishing] = useSafeState(false);
  const [publishingCalled, setPublishingCalled] = useSafeState(false);
  const [publishingError, setPublishingError] = useSafeState<
    unknown | undefined
  >();
  const [mediaInviteParticipants] = useMediaInsightsInviteParticipantsMutation({
    onError: (error) => {
      enqueueSnackbar("Data clean room participants could not be invited.", {
        context: error?.message,
        persist: true,
        variant: "error",
      });
    },
  });
  const [sendCollaborationRequestToPublisherMutation] =
    useSendCollaborationRequestToPublisherMutation();
  const [sendCollaborationRequestToDataPartnerMutation] =
    useSendCollaborationRequestToDataPartnerMutation();
  const sendCollaborationRequest = useCallback(
    async ({
      requestRecipientId,
      message,
      receiver,
    }: {
      requestRecipientId: string;
      message: string;
      receiver: "publisher" | "dataPartner";
    }) => {
      const mutation =
        receiver === "publisher"
          ? sendCollaborationRequestToPublisherMutation
          : sendCollaborationRequestToDataPartnerMutation;
      await mutation({
        onCompleted: () => {
          enqueueSnackbar("Contact request sent");
          onCancel();
        },
        onError: (error) => {
          enqueueSnackbar("Contact request could not be sent", {
            context: error?.message,
            persist: true,
            variant: "error",
          });
        },
        variables: {
          message,
          requestRecipientId,
        },
      });
    },
    [
      enqueueSnackbar,
      onCancel,
      sendCollaborationRequestToPublisherMutation,
      sendCollaborationRequestToDataPartnerMutation,
    ]
  );
  const { publish: publishMediaDataRoom } = usePublishMediaDataRoom();
  const { validateMediaDataRoom } = useValidateMediaDataRoom();
  const { publish: publishMediaInsightsDcr } = usePublishMediaInsightsDcr();
  const { validateMediaInsightsDcr } = useValidateMediaInsightsDcr();
  const submit = useCallback(
    async (mediaDataRoomInput: PublishMediaDataRoomInput) => {
      const isMediaDataRoomValid = enableAudienceBuilderMediaDataRoom
        ? validateMediaDataRoom(mediaDataRoomInput)
        : validateMediaInsightsDcr(mediaDataRoomInput);
      if (!isMediaDataRoomValid) {
        return;
      }
      setPublishing(true);
      setPublishingCalled(true);
      setPublishingError(undefined);
      try {
        await delay(1000);
        const { id: dataRoomId, driverAttestationHash } = await noSoonerThan(
          1000,
          enableAudienceBuilderMediaDataRoom
            ? publishMediaDataRoom(mediaDataRoomInput)
            : publishMediaInsightsDcr(mediaDataRoomInput)
        );
        await noSoonerThan(
          1000,
          mediaInviteParticipants({
            variables: {
              input: {
                dataRoomDescription: "",
                publishedDataRoomDriverAttestationHash: driverAttestationHash,
                publishedDataRoomEnclaveId: dataRoomId,
              },
            },
          })
        );
        await delay(1000);
        onCancel();
        await apolloClient.query({
          errorPolicy: "ignore",
          fetchPolicy: "network-only",
          query: MyDataRoomsDocument,
        });
        navigate(
          dataRoomPathBuilder(
            dataRoomId,
            DataRoomTypeNames.PublishedMediaInsightsDcr
          )
        );
      } catch (error) {
        enqueueSnackbar(`Data clean room could not be published.`, {
          context: getEffectiveErrorMessage(error),
          persist: true,
          variant: "error",
        });
        setPublishingError(error);
      } finally {
        setPublishing(false);
      }
    },
    [
      apolloClient,
      enableAudienceBuilderMediaDataRoom,
      enqueueSnackbar,
      mediaInviteParticipants,
      navigate,
      onCancel,
      publishMediaDataRoom,
      publishMediaInsightsDcr,
      setPublishing,
      setPublishingCalled,
      setPublishingError,
      validateMediaDataRoom,
      validateMediaInsightsDcr,
    ]
  );
  return {
    publishing,
    publishingCalled,
    publishingError,
    sendCollaborationRequest,
    submit,
  };
};

export default useSubmitCreateMediaDcrForm;
