import { faTrashCan } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@mui/joy";
import { memo } from "react";
import DatasetDelete from "./DatasetDelete";

interface DeleteDatasetButtonProps {
  manifestHash: string;
  onComplete?: () => void;
  testing?: {
    dataNodeId: string;
  };
}

const DatasetDeleteButton = memo<DeleteDatasetButtonProps>((props) => {
  return (
    <DatasetDelete
      {...props}
      renderTrigger={(openDialog) => (
        <Button
          color="danger"
          onClick={openDialog}
          startDecorator={
            <FontAwesomeIcon fixedWidth={true} icon={faTrashCan} />
          }
          sx={{ borderWidth: "1px", marginRight: 1, maxWidth: "200px" }}
        >
          Delete dataset
        </Button>
      )}
    />
  );
});

DatasetDeleteButton.displayName = "DatasetDeleteButton";

export default DatasetDeleteButton;
