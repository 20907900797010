import {
  ColumnDataType,
  TableColumnFormatType,
  type TableColumnHashingAlgorithm,
} from "@decentriq/graphql/dist/types";
import { type DataRoomTableColumn } from "models";

export enum MediaDataRoomTab {
  data = "data",
  insights = "insights",
  activation = "activation",
  auditLog = "auditLog",
}

export enum MediaDataRoomActivationTab {
  creation = "creation",
}

export const mediaDataRoomTabs = [
  MediaDataRoomTab.data,
  MediaDataRoomTab.insights,
  MediaDataRoomTab.activation,
  MediaDataRoomTab.auditLog,
];

export interface OverlapInsightsCacheKey {
  dataRoomId: string;
  advertiserDatasetHash: string;
  publisherUsersDatasetHash: string;
  publisherSegmentsDatasetHash?: string | null;
  publisherDemographicsDatasetHash?: string | null;
  publisherEmbeddingsDatasetHash?: string | null;
}

interface SegmentAggregation {
  column: string;
  possible_values: string[];
}

export interface SegmentAggregationsCollection {
  aggregation: SegmentAggregation[];
  audience_type: string;
  columns: string[];
  id: string;
  rows: any[][];
}

export interface OverlapSegment {
  audience_type: string;
  aggregations: SegmentAggregationsCollection[];
}

interface SegmentAggregation {
  column: string;
  possible_values: string[];
}

export interface SegmentAggregationsCollection {
  aggregation: SegmentAggregation[];
  audience_type: string;
  columns: string[];
  id: string;
  rows: any[][];
}

export interface OverlapSegment {
  audience_type: string;
  aggregations: SegmentAggregationsCollection[];
}

export interface OverlapSegmentsCollection {
  audiences: OverlapSegment[];
}

export interface PublisherDatasetsHashes {
  usersDatasetHash: string | null;
  segmentsDatasetHash: string | null;
  demographicsDatasetHash: string | null;
  embeddingsDatasetHash: string | null;
}

export interface AdvertiserDataReportCacheKey {
  dataRoomId: string;
  advertiserDatasetHash: string;
}

export interface PublishedDatasets {
  advertiserDatasetHash?: string | null;
  publisherDemographicsDatasetHash?: string | null;
  publisherEmbeddingsDatasetHash?: string | null;
  publisherSegmentsDatasetHash?: string | null;
  publisherUsersDatasetHash?: string | null;
}

export enum RawSupportedFeatures {
  ENABLE_MODEL_PERFORMANCE_EVALUATION = "ENABLE_MODEL_PERFORMANCE_EVALUATION",
  HIDE_ABSOLUTE_VALUES_FROM_INSIGHTS = "HIDE_ABSOLUTE_VALUES_FROM_INSIGHTS",
  ENABLE_ADVERTISER_AUDIENCE_DOWNLOAD = "ENABLE_ADVERTISER_AUDIENCE_DOWNLOAD",
  ENABLE_EXTENDED_LAL_QUALITY_STATS = "ENABLE_EXTENDED_LAL_QUALITY_STATS",
}

export const getAdvertiserAudienceColumns: (
  matchingIdFormat: TableColumnFormatType,
  hashMatchingIdWith: TableColumnHashingAlgorithm | null
) => DataRoomTableColumn[] = (matchingIdFormat, hashMatchingIdWith) => [
  {
    formatType: matchingIdFormat,
    hashWith: hashMatchingIdWith ?? undefined,
    id: "matchingId",
    name: "matchingId",
    nullable: false,
    primitiveType: ColumnDataType.Text,
    tableNodeId: "audience_leaf",
  },
  {
    default: "All customers",
    formatType: TableColumnFormatType.String,
    id: "audienceType",
    name: "audienceType",
    nullable: false,
    primitiveType: ColumnDataType.Text,
    tableNodeId: "audience_leaf",
  },
];

export interface RetargetAudience {
  audience_type: string;
  size: number;
}

export interface ExclusionTargetingAudience {
  audience_type: string;
  size: number;
}

export interface LookalikeAudienceROCCurve {
  auc: number;
  fpr: [number, number];
  reach: [number, number];
  test_set_size?: number;
  test_set_positive_examples_size?: number;
  tpr: [number, number];
}

export interface LookalikeAudienceQuality {
  roc_curve: LookalikeAudienceROCCurve;
}

export interface FilteredLookalikeAudienceROCCurve {
  fpr: number;
  tpr: number;
  score: number;
}

export interface FilteredLookalikeAudienceQuality {
  roc_curve: FilteredLookalikeAudienceROCCurve;
}

export interface FilteredLookalikeAudience {
  audience_type: string;
  reach: number;
  size: number;
  quality: FilteredLookalikeAudienceQuality;
}

// For v2 MDCR Audience, addressable_audience_size, quality and seed_audience_size fields do not exist
export interface LookalikeAudience {
  addressable_audience_size?: number | undefined;
  audience_type: string;
  quality?: LookalikeAudienceQuality;
  seed_audience_size?: number | undefined;
  filtered_audiences: FilteredLookalikeAudience[];
}

export interface AvailableAudiences {
  retarget: RetargetAudience[];
  exclusion_targeting: ExclusionTargetingAudience[];
  lookalike: LookalikeAudience[];
}

export interface Audience {
  id: string;
  audienceType: string;
  activationType: ActivationType;
  excludeSeedAudience: boolean;
  published: boolean;
  reach: number;
}

export interface ActivatedAudience {
  activation_type: ActivationType;
  audience_type: string;
  reach: number;
  is_published: boolean;
  exclude_seed_audience: boolean;
}

export interface ActivatedAudiencesConfigWrapper {
  advertiser_manifest_hash: string;
  activated_audiences: ActivatedAudience[];
}

export type ActivationType = "retarget" | "lookalike" | "exclusion_targeting";

export interface MediaInsightsAudienceCacheKey {
  activationType: ActivationType;
  dataRoomId: string;
  advertiserDatasetHash: string;
  publisherUsersDatasetHash: string;
  publisherSegmentsDatasetHash?: string | null;
  publisherDemographicsDatasetHash?: string | null;
  publisherEmbeddingsDatasetHash?: string | null;
  activatedAudiences: Audience[];
  audienceType: string;
  reach?: number;
}

export interface InsightsBasicViewFormValues {
  audienceType: string;
  genderChartValueKey: string;
}

export interface InsightsComparisonViewFormValues {
  aggregation: string;
  audienceType: string;
}

export interface InsightsDetailedViewFormValues {
  audienceType: string;
  genderChartValueKey: string;
  // Defines whether selected best or worst affinityRatio values
  // TODO: check why boolean is needed s a part of definition
  dataSortDirection: boolean | "desc" | "asc";
}
