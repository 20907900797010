import { useQuery } from "@tanstack/react-query";
import { type SnackbarKey } from "notistack";
import { useEffect, useState } from "react";
import { useApiCore } from "contexts";
import { type ApiToken } from "features/accessTokens/models";
import { CommonSnackbarOrigin, useGeneralSnackbar } from "hooks";

const useApiTokens = () => {
  const { getSessionV2 } = useApiCore();
  const setErrorSnackbarId = useState<SnackbarKey | undefined>()[1];

  const { enqueueSnackbar, closeSnackbar } = useGeneralSnackbar({
    origin: CommonSnackbarOrigin.API_TOKENS,
    withReportOnError: true,
  });

  const response = useQuery<ApiToken[]>({
    queryFn: async () => {
      try {
        const session = await getSessionV2();
        return (await session.getApiTokens()) as ApiToken[];
      } catch (error) {
        throw new Error(`Fetching API tokens failed: ${error}`);
      }
    },
    queryKey: ["apiTokens"],
  });
  useEffect(() => {
    if (response.error?.message) {
      const key = enqueueSnackbar(response.error.message, {
        persist: true,
        variant: "error",
      });
      setErrorSnackbarId(key);
    } else {
      setErrorSnackbarId((snackbarId) => {
        if (snackbarId) {
          closeSnackbar(snackbarId);
        }
        return undefined;
      });
    }
  }, [closeSnackbar, enqueueSnackbar, response.error, setErrorSnackbarId]);
  return response;
};

export default useApiTokens;
