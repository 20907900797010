import {
  type data_science_commit,
  type data_science_data_room,
  type proto,
  type types,
} from "@decentriq/core";
import {
  type CompleteDraftDataRoomQuery,
  type CompletePublishedDataRoomQuery,
  type CompleteSubmittedDataRoomRequestQuery,
  type DraftNode,
  PublishedDataRoomSource,
} from "@decentriq/graphql/dist/types";
import { v4 as uuidv4 } from "uuid";
import {
  type PublishedComputeNodeTypeNames,
  type PublishedDataNodeTypeNames,
} from "models";
import {
  BuildError,
  dataScienceToPublishedTypename,
  EnclaveSpecificationManager,
  type NodePermissionsType,
  type PermissionsType,
  translateDataScienceNodeToPublishedNode,
  translateDraftNodeToDataScienceNode,
} from "../shared";

type DataRoom = data_science_data_room.DataScienceDataRoomV1;
type Commit = data_science_data_room.DataScienceCommitV1;
type Configuration = data_science_data_room.DataScienceDataRoomConfiguration;

function unwrapDataRoom(
  dcr: data_science_data_room.DataScienceDataRoom
): DataRoom {
  if ("v1" in dcr) {
    return dcr.v1;
  } else {
    throw new Error("Cannot get DCR, unsupported version.");
  }
}

function wrapDataRoom(
  dcr: DataRoom
): data_science_data_room.DataScienceDataRoom {
  return {
    v1: dcr,
  };
}

function wrapCommit(commit: Commit): data_science_commit.DataScienceCommit {
  return {
    v1: commit,
  };
}

function unwrapCommit(commit: data_science_commit.DataScienceCommit): Commit {
  if ("v1" in commit) {
    return commit.v1;
  } else {
    throw new Error("Cannot get Commit, unsupported version.");
  }
}

export function rebaseCommit(
  versionedCommit: data_science_commit.DataScienceCommit,
  newHistoryPin: string,
  usedSpecIds: string[]
): void {
  const commit = unwrapCommit(versionedCommit);
  const commitKind = commit.kind;
  commit.id = uuidv4();
  commit.historyPin = newHistoryPin;
  if ("addComputation" in commitKind) {
    const addCommit = commitKind.addComputation;
    addCommit.enclaveSpecifications = addCommit.enclaveSpecifications.filter(
      (spec) => !usedSpecIds.includes(spec.id)
    );
  } else {
    throw new Error("Unsupported commit kind type");
  }
}

export function changeCommitExecutionPermissions(
  versionedCommit: data_science_commit.DataScienceCommit,
  user: string
) {
  const commit = unwrapCommit(versionedCommit);
  const commitKind = commit.kind;
  if ("addComputation" in commitKind) {
    const addCommit = commitKind.addComputation;
    addCommit.analysts = [user];
  } else {
    throw new Error("Unsupported commit kind type");
  }
}

function getConfiguration(
  dcr: data_science_data_room.DataScienceDataRoom
): Configuration {
  const dataScienceDataRoom = unwrapDataRoom(dcr);
  if ("interactive" in dataScienceDataRoom) {
    return dataScienceDataRoom.interactive
      .initialConfiguration as Configuration;
  } else if ("static" in dcr) {
    return dataScienceDataRoom.static;
  } else {
    throw new Error("Cannot get DCR configuration, invalid DCR structure.");
  }
}

function translateDataScienceDataRoomConfiguration(
  configuration: data_science_data_room.DataScienceDataRoomConfiguration,
  enableInteractivity: boolean,
  driverAttestationHash: string,
  dcrId: string,
  isStopped: boolean,
  publishedDatasetByNode: Map<string, proto.gcg.IPublishedDataset>
): CompletePublishedDataRoomQuery["publishedDataRoom"] {
  const nodeTypeNamesMap = new Map<
    string,
    {
      __typename: CompletePublishedDataRoomQuery["publishedDataRoom"]["publishedNodes"][number]["__typename"];
      name: string;
    }
  >();
  for (const node of configuration.nodes) {
    nodeTypeNamesMap.set(node.id, {
      __typename: dataScienceToPublishedTypename(node),
      name: node.name,
    });
  }
  const participants: CompletePublishedDataRoomQuery["publishedDataRoom"]["participants"] =
    [];
  let owner = undefined;
  for (const participant of configuration.participants) {
    const permissions: PermissionsType = [];
    for (const permission of participant.permissions) {
      if ("manager" in permission) {
        if (owner) {
          throw new Error("Multiple owners");
        }
        owner = {
          __typename: "User",
          email: participant.user,
        };
      } else if ("analyst" in permission) {
        const analystPermission = permission.analyst;
        permissions.push({
          __typename: "PublishedAnalystPermission",
          node: {
            __typename: nodeTypeNamesMap.get(analystPermission.nodeId)! as any,
            commitId: null,
            dcrHash: dcrId,
            driverAttestationHash,
            id: analystPermission.nodeId,
          },
        });
      } else if ("dataOwner" in permission) {
        const dataOwnerPermission = permission.dataOwner;
        permissions.push({
          __typename: "PublishedDataOwnerPermission",
          node: {
            __typename: nodeTypeNamesMap.get(
              dataOwnerPermission.nodeId
            )! as any,
            commitId: null,
            dcrHash: dcrId,
            driverAttestationHash,
            id: dataOwnerPermission.nodeId,
          },
        });
      } else {
        throw new Error("Unsupported permission");
      }
    }
    participants.push({
      permissions,
      userEmail: participant.user,
    });
  }
  if (!owner) {
    throw new Error("No owner");
  }
  const permissionsMap = new Map<string, NodePermissionsType>();
  for (const participant of participants) {
    for (const permission of participant.permissions) {
      const nodePermissions = permissionsMap.get(permission.node.id);
      const { node, ...rest } = permission;
      const newPermission = rest as NodePermissionsType[number];
      newPermission.participant = participant;
      if (nodePermissions) {
        nodePermissions.push(newPermission);
      } else {
        permissionsMap.set(permission.node.id, [newPermission]);
      }
    }
  }
  type PublishedNodesType =
    CompletePublishedDataRoomQuery["publishedDataRoom"]["publishedNodes"];
  const publishedNodes: PublishedNodesType = configuration.nodes.map((node) => {
    return translateDataScienceNodeToPublishedNode(
      node,
      driverAttestationHash,
      dcrId,
      null,
      nodeTypeNamesMap,
      permissionsMap.get(node.id) || [],
      publishedDatasetByNode
    );
  });
  return {
    description: configuration.description || "",
    driverAttestationHash,
    enableAutomergeFeature: false,
    enableDevelopment: configuration.enableDevelopment,
    enableInteractivity,
    enablePostWorker: false,
    enableServersideWasmValidation: false,
    enableSqliteWorker: false,
    enableTestDatasets: false,
    id: configuration.id,
    isOwner: false,
    isStopped,
    name: configuration.title,
    owner,
    participants,
    publishedNodes,
    source: PublishedDataRoomSource.Web,
  };
}

function applyInteractiveDataScienceDcrCommit(
  current: data_science_data_room.InteractiveDataScienceDataRoomV1,
  commit: data_science_data_room.DataScienceCommitV1
): data_science_data_room.InteractiveDataScienceDataRoomV1 {
  let currentConfiguration = current.initialConfiguration;
  const commitKind = commit.kind;

  if ("addComputation" in commitKind) {
    const addComputation = commitKind.addComputation;

    // Check whether there are new participants in the data room
    for (const analyst of addComputation.analysts) {
      const participant = currentConfiguration.participants.find(
        (participant) => participant.user === analyst
      );
      const analystPermission = {
        analyst: {
          nodeId: addComputation.node.id,
        },
      };
      if (!participant) {
        currentConfiguration.participants.push({
          permissions: [analystPermission],
          user: analyst,
        });
      } else {
        participant.permissions.push(analystPermission);
      }
    }

    // The commit builder would already have de-duplicated the attestation specs,
    // simply add all new specs to the DCR
    for (const enclaveSpec of addComputation.enclaveSpecifications) {
      currentConfiguration.enclaveSpecifications.push(enclaveSpec);
    }

    currentConfiguration.nodes.push(addComputation.node);
  } else {
    throw new Error(
      "Unsupported kind of commit, cannot apply to data room configuration"
    );
  }

  return current;
}

/** Flatten the given data science DCR in place */
export function flattenInteractiveDataScienceDataRoom(
  dcr: data_science_data_room.DataScienceDataRoom
): data_science_data_room.DataScienceDataRoom {
  const dataScienceDataRoom = unwrapDataRoom(dcr);
  if ("interactive" in dataScienceDataRoom) {
    let interactiveDataRoom = dataScienceDataRoom.interactive;
    for (const commit of interactiveDataRoom.commits) {
      applyInteractiveDataScienceDcrCommit(interactiveDataRoom, commit);
    }
    interactiveDataRoom.commits = [];
  }
  return wrapDataRoom(dataScienceDataRoom);
}

export function translateDataScienceDataRoom(
  dcr: data_science_data_room.DataScienceDataRoom,
  driverAttestationHash: string,
  dcrId: string,
  isStopped: boolean,
  publishedDatasetByNode: Map<string, proto.gcg.IPublishedDataset>
): CompletePublishedDataRoomQuery["publishedDataRoom"] {
  const dataScienceDataRoom = unwrapDataRoom(dcr);
  let publishedDataRoom: CompletePublishedDataRoomQuery["publishedDataRoom"];
  if ("static" in dataScienceDataRoom) {
    publishedDataRoom = translateDataScienceDataRoomConfiguration(
      dataScienceDataRoom.static,
      false,
      driverAttestationHash,
      dcrId,
      isStopped,
      publishedDatasetByNode
    );
  } else if ("interactive" in dataScienceDataRoom) {
    let flattenedInteractiveDcr = unwrapDataRoom(
      flattenInteractiveDataScienceDataRoom(wrapDataRoom(dataScienceDataRoom))
    );
    if ("interactive" in flattenedInteractiveDcr) {
      publishedDataRoom = {
        ...translateDataScienceDataRoomConfiguration(
          flattenedInteractiveDcr.interactive.initialConfiguration,
          true,
          driverAttestationHash,
          dcrId,
          isStopped,
          publishedDatasetByNode
        ),
        enableAutomergeFeature:
          flattenedInteractiveDcr.interactive.enableAutomergeFeature,
      };
    } else {
      throw new Error("Flattening operation returned a non-interactive DCR");
    }
  } else {
    throw new Error("Unsupported configuration");
  }
  return publishedDataRoom;
}

export function translateDataScienceCommit(
  submittedDataRoomRequestId: string,
  dcr: data_science_data_room.DataScienceDataRoom,
  dcrCommit: data_science_commit.DataScienceCommit,
  driverAttestationHash: string,
  dcrId: string,
  commitId: string
): CompleteSubmittedDataRoomRequestQuery["submittedDataRoomRequest"] {
  const commit = unwrapCommit(dcrCommit);
  const configuration = getConfiguration(dcr);
  const nodeTypeNamesMap = new Map<
    string,
    {
      __typename: PublishedComputeNodeTypeNames | PublishedDataNodeTypeNames;
      name: string;
    }
  >(
    configuration.nodes.map((node) => [
      node.id,
      {
        __typename: dataScienceToPublishedTypename(node),
        name: node.name,
      },
    ])
  );
  if ("addComputation" in commit.kind) {
    const addComputation = commit.kind.addComputation;
    const nodePermissions: NodePermissionsType = addComputation.analysts.map(
      (analyst) => ({
        __typename: "PublishedAnalystPermission",
        participant: {
          userEmail: analyst,
        },
      })
    );
    return {
      __typename: "SubmittedDataRoomRequest",
      id: submittedDataRoomRequestId,
      node: translateDataScienceNodeToPublishedNode(
        addComputation.node,
        driverAttestationHash,
        dcrId,
        commitId,
        nodeTypeNamesMap,
        nodePermissions,
        undefined
      ),
    };
  } else {
    throw new Error("Unsupported commit kind");
  }
}

export function buildDataScienceCommit(
  draftNode: DraftNode,
  analysts: Array<string>,
  dataRoomId: string,
  historyPin: string,
  availableSpecs: Map<string, types.EnclaveSpecification>,
  usedSpecs: string[],
  nodeNamesMap: Map<string, string>,
  isDevMode: boolean
): data_science_commit.DataScienceCommit {
  const enclaveSpecificationsManager = new EnclaveSpecificationManager(
    availableSpecs,
    usedSpecs || []
  );
  const node = translateDraftNodeToDataScienceNode(
    draftNode,
    enclaveSpecificationsManager,
    nodeNamesMap,
    isDevMode
  );
  const commit: Commit = {
    enclaveDataRoomId: dataRoomId,
    historyPin,
    id: uuidv4(),
    kind: {
      addComputation: {
        analysts,
        enclaveSpecifications:
          enclaveSpecificationsManager.getAddedEnclaveSpecifications(),
        node,
      },
    },
    name: "",
  };
  return wrapCommit(commit);
}

export function buildDataScienceDataRoom(
  draftDataRoom: CompleteDraftDataRoomQuery["draftDataRoom"],
  rootCertificatePem: Uint8Array,
  availableSpecs: Map<string, types.EnclaveSpecification>,
  isDraftMode: boolean,
  dcrSecretIdBase64?: string
): data_science_data_room.DataScienceDataRoom {
  // setup enclave specification manager
  const enclaveSpecificationsManager = new EnclaveSpecificationManager(
    availableSpecs,
    []
  );
  // translate participants
  if (
    !draftDataRoom.participants.nodes.some(
      (p) =>
        p.userEmail.toLowerCase() === draftDataRoom.owner?.email.toLowerCase()
    )
  ) {
    throw new BuildError(
      `As a data clean room creator, you must also be in the participants list. Please access the PERMISSIONS tab and add yourself as a participant.`
    );
  }
  const participants: Array<data_science_data_room.Participant> = [];
  participants.push({
    permissions: [
      {
        manager: {},
      },
    ],
    user: draftDataRoom.owner!.email,
  });
  for (const participant of draftDataRoom.participants.nodes) {
    const permissions = participant.permissions.nodes.map((permission) => {
      switch (permission.__typename) {
        case "DraftAnalystPermission": {
          return {
            analyst: {
              nodeId: permission.node.id,
            },
          };
        }
        case "DraftDataOwnerPermission": {
          return {
            dataOwner: {
              nodeId: permission.node.id,
            },
          };
        }
        default: {
          throw new BuildError(
            `Unknown permission type '${permission.__typename}' for participant ${participant.userEmail}`
          );
        }
      }
    });
    if (participant.userEmail === draftDataRoom.owner!.email) {
      participants[0].permissions.push(...permissions);
    } else {
      participants.push({
        permissions,
        user: participant.userEmail,
      });
    }
  }
  // translate nodes
  const nodeNamesMap = new Map<string, string>(
    draftDataRoom.draftNodes.nodes.map((node) => {
      return [node.name, node.id];
    })
  );
  let sortedNodes = draftDataRoom.draftNodes.nodes;
  if (
    draftDataRoom.dataNodesOrder.length > 0 ||
    draftDataRoom.computeNodesOrder.length > 0
  ) {
    const nodesOrderMap = new Map();
    draftDataRoom.dataNodesOrder.forEach((id, index) =>
      nodesOrderMap.set(id, index)
    );
    draftDataRoom.computeNodesOrder.forEach((id, index) =>
      nodesOrderMap.set(id, index + draftDataRoom.dataNodesOrder.length)
    );
    sortedNodes = sortedNodes
      .slice()
      .sort(
        (a, b) =>
          (nodesOrderMap.get(a.id) || 0) - (nodesOrderMap.get(b.id) || 0)
      );
  }
  const nodes = sortedNodes.map((node) =>
    translateDraftNodeToDataScienceNode(
      node,
      enclaveSpecificationsManager,
      nodeNamesMap,
      isDraftMode
    )
  );
  const configuration: data_science_data_room.DataScienceDataRoomConfiguration =
    {
      description: draftDataRoom.description,
      enableDevelopment: true,
      enclaveRootCertificatePem: new TextDecoder().decode(rootCertificatePem),
      enclaveSpecifications:
        enclaveSpecificationsManager.getAddedEnclaveSpecifications(),
      id: uuidv4(),
      nodes,
      participants,
      title: draftDataRoom.title,
    };
  if (dcrSecretIdBase64) {
    configuration.dcrSecretIdBase64 = dcrSecretIdBase64;
  }
  if (draftDataRoom.enableInteractivity) {
    const interactiveDcr: data_science_data_room.DataScienceDataRoomV1 = {
      interactive: {
        commits: [],
        enableAutomergeFeature: true,
        initialConfiguration: configuration,
      },
    };
    return wrapDataRoom(interactiveDcr);
  } else {
    const staticDcr: data_science_data_room.DataScienceDataRoomV1 = {
      static: configuration,
    };
    return wrapDataRoom(staticDcr);
  }
}
