import {
  faEllipsisV as falEllipsisV,
  faFileExport as falFileExport,
  faTrash as falTrash,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  ButtonGroup,
  Dropdown,
  IconButton,
  ListItemDecorator,
  Menu,
  MenuButton,
  MenuItem,
  Tooltip,
} from "@mui/joy";
import React, { memo, useCallback } from "react";
import { useAdvertiserAudiences } from "features/mediaDataRoom/contexts";
import { type Audience } from "features/mediaDataRoom/models";
import DeleteAudienceButton from "../DeleteAudienceButton/DeleteAudienceButton";

interface ActionsMenuProps {
  audience: Audience;
  mode?: "icons" | "dropdown";
}

const ActionsMenu: React.FC<ActionsMenuProps> = memo(
  ({ audience, mode = "icons" }) => {
    const { publishAudience, isPublishingAudience } = useAdvertiserAudiences();
    const publish = useCallback(
      async () => publishAudience(audience),
      [publishAudience, audience]
    );
    return mode === "icons" ? (
      <ButtonGroup
        sx={{ "--ButtonGroup-separatorColor": "neutral" }}
        variant="plain"
      >
        <Tooltip title="Grant publisher access">
          <IconButton
            disabled={
              audience.mutable.status === "published" || isPublishingAudience
            }
            onClick={publish}
          >
            <FontAwesomeIcon icon={falFileExport} />
          </IconButton>
        </Tooltip>
        <DeleteAudienceButton
          audience={audience}
          renderTrigger={(openDeleteAudienceDialog) => (
            <Tooltip title="Delete audience">
              <IconButton onClick={openDeleteAudienceDialog}>
                <FontAwesomeIcon icon={falTrash} />
              </IconButton>
            </Tooltip>
          )}
        />
      </ButtonGroup>
    ) : (
      <>
        <Dropdown>
          <MenuButton
            endDecorator={null}
            slotProps={{
              root: { color: "neutral" },
            }}
            slots={{ root: IconButton }}
          >
            <FontAwesomeIcon icon={falEllipsisV} />
          </MenuButton>
          <Menu keepMounted={true} placement="bottom-end">
            {audience.mutable.status !== "published" && (
              <MenuItem disabled={isPublishingAudience} onClick={publish}>
                <ListItemDecorator>
                  <FontAwesomeIcon icon={falFileExport} />
                </ListItemDecorator>
                Grant publisher access
              </MenuItem>
            )}
            <DeleteAudienceButton
              audience={audience}
              renderTrigger={(openDeleteAudienceDialog) => (
                <MenuItem onClick={openDeleteAudienceDialog}>
                  <ListItemDecorator>
                    <FontAwesomeIcon icon={falTrash} />
                  </ListItemDecorator>
                  Delete audience
                </MenuItem>
              )}
            />
          </Menu>
        </Dropdown>
      </>
    );
  }
);

ActionsMenu.displayName = "ActionsMenu";

export default ActionsMenu;
