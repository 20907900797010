import { CircularProgress } from "@mui/joy";
import { Backdrop, styled } from "@mui/material";
import { memo } from "react";

const Loading: React.FC<{ className?: string; loading?: boolean }> = ({
  className,
  loading = true,
}) => {
  return (
    <Backdrop className={className} open={loading}>
      <CircularProgress sx={{ "--CircularProgress-size": "1rem" }} />
    </Backdrop>
  );
};

const StyledLoading = styled(Loading)({
  backgroundColor: "inherit",
  position: "absolute",
});

export default memo(StyledLoading);
