import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import {
  getOverlapColumnPresentation,
  useSelectedAudienceAggregations,
} from "features/MediaInsightsDcr";

const useVisualizeSelectorOrderByData = () => {
  const { watch } = useFormContext();

  const [visualizeSelectorData, setVisualizeSelectorData] = useState<
    { label: string; value: string }[]
  >([]);
  const [rowsTotalCount, setRowsTotalCount] = useState<number>(0);

  const selectedAudienceType = watch("audienceType");
  const selectedAggregation = watch("aggregation");
  const aggregations = useSelectedAudienceAggregations(selectedAudienceType);

  useEffect(() => {
    const {
      columns = [],
      aggregation = [],
      rows = [],
    } = aggregations.find(({ id }) => id === selectedAggregation) || {};

    const orderByFieldsData = columns
      ?.slice(aggregation.length)
      ?.filter((column) => column !== "count_users_overlap")
      ?.map((column) => {
        return {
          label: getOverlapColumnPresentation.get(column) || column,
          value: column,
        };
      });

    setVisualizeSelectorData(orderByFieldsData);
    setRowsTotalCount(rows?.length || 0);
  }, [aggregations, selectedAggregation, selectedAudienceType]);

  return { rowsTotalCount, visualizeSelectorData };
};

export default useVisualizeSelectorOrderByData;
