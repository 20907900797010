import { useUpdateScriptingNodeOutputFolderMutation } from "@decentriq/graphql/dist/hooks";
import { ScriptingLanguage } from "@decentriq/graphql/dist/types";
import { faInfoCircle } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormControl, FormLabel, Input, Tooltip } from "@mui/joy";
import { memo, useCallback } from "react";
import { mapDraftDataRoomErrorToSnackbar, useDataRoomSnackbar } from "hooks";
import useScriptingComputeNode from "../../useScriptingComputeNode";

interface OutputFolderEditorProps {
  computeNodeId: string;
}

const INFO_TOOLTIP = new Map<ScriptingLanguage, React.ReactNode>([
  [
    ScriptingLanguage.Python,
    <span>
      All output files of this script must be written to a specific folder, so
      its result becomes available as input to other computations.
      <br />
      Use the absolute path when writing to it, for example:
      <br />
      <code>
        f = open("/output/my_computation_result.ext", "w")
        <br />
        f.write(computation_results)
      </code>
    </span>,
  ],
  [
    ScriptingLanguage.R,
    <span>
      All output files of this script must be written to a specific folder, so
      its result becomes available as input to other computations.
      <br />
      Use the absolute path when writing to it, for example:
      <code>"/output/my_result_file.csv"</code>
    </span>,
  ],
]);

const OutputFolderEditor: React.FC<OutputFolderEditorProps> = ({
  computeNodeId,
}) => {
  const { enqueueSnackbar } = useDataRoomSnackbar();
  const { scriptingLanguage, output } = useScriptingComputeNode(computeNodeId);
  // Update value
  const [updateScriptingNodeOutputFolderMutation] =
    useUpdateScriptingNodeOutputFolderMutation({
      onError: (error) => {
        enqueueSnackbar(
          ...mapDraftDataRoomErrorToSnackbar(
            error,
            "The script output directory could not be updated."
          )
        );
      },
    });
  const updateScriptingNodeOutputFolder = useCallback(
    (value: string) => {
      updateScriptingNodeOutputFolderMutation({
        variables: {
          input: {
            id: computeNodeId,
            output: value,
          },
        },
      });
    },
    [updateScriptingNodeOutputFolderMutation, computeNodeId]
  );
  return (
    <FormControl>
      <FormLabel>
        Output folder:
        <Tooltip title={INFO_TOOLTIP.get(scriptingLanguage!)!}>
          <FontAwesomeIcon icon={faInfoCircle} />
        </Tooltip>
      </FormLabel>
      <Input
        onChange={(event) => {
          const { value = "/output" } = event.target;
          updateScriptingNodeOutputFolder(value);
        }}
        value={output || ""}
      />
    </FormControl>
  );
};
OutputFolderEditor.displayName = "OutputFolderEditor";

export default memo(OutputFolderEditor);
