import { DqSidePanel, DqSidePanelContent } from "@decentriq/components";
import { useDatasetByHashQuery } from "@decentriq/graphql/dist/hooks";
import { Fragment, memo } from "react";
import { DatasetTabs } from "features/datasets/components";
import { DatasetPermissionWrapper } from "features/datasets/wrappers";
import DatasetDrawerTitle from "./DatasetDrawerTitle";

interface DatasetDrawerProps {
  open: boolean;
  manifestHash: string | null;
  onClose: () => void;
}

const DatasetDrawer = memo<DatasetDrawerProps>(
  ({ open, onClose, manifestHash }) => {
    const { data } = useDatasetByHashQuery({
      skip: !manifestHash || !open,
      variables: { manifestHash: manifestHash! },
    });
    const datasetName = data?.datasetByManifestHash.name;
    // TODO: desired behavior is to do not show backdrop when drawer is open but close it if click was outside of the drawer and not on the table row
    return (
      <DqSidePanel
        PaperProps={{ sx: { width: 650 } }}
        anchor="right"
        onClose={onClose}
        open={open}
      >
        <DatasetPermissionWrapper
          manifestHash={manifestHash!}
          skipFetching={!data || !open}
        >
          <Fragment>
            {open && data ? (
              <DatasetDrawerTitle
                datasetName={datasetName}
                manifestHash={manifestHash!}
                onClose={onClose}
              />
            ) : null}
            <DqSidePanelContent>
              {open && data ? (
                <DatasetTabs
                  manifestHash={data.datasetByManifestHash.manifestHash}
                  skipFetching={false}
                />
              ) : null}
            </DqSidePanelContent>
          </Fragment>
        </DatasetPermissionWrapper>
      </DqSidePanel>
    );
  }
);

DatasetDrawer.displayName = "DatasetDrawer";

export default DatasetDrawer;
