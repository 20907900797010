import { useAuth0 } from "@auth0/auth0-react";
import { type PublishMediaDataRoomInput } from "@decentriq/types";
import { useDataRoomSnackbar } from "hooks";

const useValidateMediaDataRoom = () => {
  const { enqueueSnackbar } = useDataRoomSnackbar();
  const { user = {} } = useAuth0();
  const { email: currentUserEmail = "" } = user || {};

  const validateMediaInsightsDcr = ({
    advertiserEmails,
    agencyEmails,
    mainPublisherEmail,
    mainAdvertiserEmail,
    dataPartnerEmails,
    name,
    observerEmails,
    publisherEmails,
    matchingIdFormat,
  }: PublishMediaDataRoomInput) => {
    const allParticipants = [
      ...publisherEmails,
      ...advertiserEmails,
      ...dataPartnerEmails,
      ...observerEmails,
      ...agencyEmails,
    ];

    if (!matchingIdFormat) {
      enqueueSnackbar("Please select the matching ID.", {
        variant: "error",
      });
      return false;
    }
    if (!name) {
      enqueueSnackbar("Please fill in the data clean room's name.", {
        variant: "error",
      });
      return false;
    }
    if (!publisherEmails.length) {
      enqueueSnackbar("At least one Publisher participant is required.", {
        variant: "error",
      });
      return false;
    }
    if (!mainPublisherEmail) {
      enqueueSnackbar(
        "Please select the domain corresponding to the Publisher organization.",
        { variant: "error" }
      );
      return false;
    }
    if (!advertiserEmails.length) {
      enqueueSnackbar("At least one Advertiser participant is required.", {
        variant: "error",
      });
      return false;
    }
    if (!mainAdvertiserEmail) {
      enqueueSnackbar(
        "Please select the domain corresponding to the Advertiser organization.",
        { variant: "error" }
      );
      return false;
    }
    if (!allParticipants.includes(currentUserEmail)) {
      enqueueSnackbar(
        "Your user must also be a participant of this data clean room.",
        { variant: "error" }
      );
      return false;
    }

    return true;
  };

  return {
    validateMediaInsightsDcr,
  };
};

export default useValidateMediaDataRoom;
