import { Stack } from "@mui/joy";
import { memo } from "react";
import {
  MediaDataRoomInsightsAdvertiserAudienceSelector,
  MediaDataRoomInsightsAggregationSelector,
  MediaDataRoomInsightsChartTypeSelector,
  MediaDataRoomInsightsVisualizeSelector,
} from "./components";

const MediaDataRoomInsightsForm = memo(() => {
  return (
    <Stack width="100%">
      <MediaDataRoomInsightsAdvertiserAudienceSelector />
      <MediaDataRoomInsightsAggregationSelector />
      <MediaDataRoomInsightsVisualizeSelector />
      <MediaDataRoomInsightsChartTypeSelector />
    </Stack>
  );
});

MediaDataRoomInsightsForm.displayName = "MediaDataRoomInsightsForm";

export default MediaDataRoomInsightsForm;
