import { memo } from "react";
import { type TableCellProps } from "react-virtualized";
import { VirtualizedTable } from "components";
import { dataRoomTablePrimitiveTypePresentation } from "models";
import { type TableScheme } from "utils/apicore";

interface ComputationSchemeProps {
  scheme: TableScheme;
}

const ComputationScheme: React.FC<ComputationSchemeProps> = memo(
  ({ scheme }) => {
    if (scheme?.namedColumns?.length) {
      const { namedColumns } = scheme;
      return (
        <div data-testid="computation_results" style={{ marginTop: "1rem" }}>
          <VirtualizedTable
            cellRenderer={({ cellData, columnIndex }: TableCellProps) => {
              if (columnIndex === 0) {
                return cellData;
              }
              return dataRoomTablePrimitiveTypePresentation.get(
                cellData.primitiveType
              );
            }}
            columns={[
              {
                dataKey: "name",
                label: "Column name",
              },
              {
                dataKey: "columnType",
                label: "Data type",
              },
            ]}
            maxRowsShown={5}
            rowCount={namedColumns.length}
            rowGetter={({ index }) => namedColumns[index]}
            withHeader={true}
          />
        </div>
      );
    }
    return <></>;
  }
);

export default ComputationScheme;
