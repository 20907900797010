import intersection from "lodash/intersection";
import { createContext, memo, useMemo } from "react";
import {
  type AdvertiserAudiencesContextValue,
  AdvertiserAudiencesWrapper,
  type PublisherAudiencesContextValue,
  PublisherAudiencesWrapper,
  useAdvertiserAudiences,
  useMediaDataRoom,
  usePublisherAudiences,
} from "features/mediaDataRoom/contexts";
import { useSafeContext } from "hooks";

type AudiencesContextValue =
  | PublisherAudiencesContextValue
  | AdvertiserAudiencesContextValue;

type AudiencesContextValueKeys = keyof AudiencesContextValue;

const AudiencesContext = createContext<AudiencesContextValue | null>(null);

AudiencesContext.displayName = "AudiencesContext";

export const useAudiences = () => useSafeContext(AudiencesContext);

const InnerAudiencesWrapper = memo<React.PropsWithChildren>(({ children }) => {
  const { isPublisher } = useMediaDataRoom();
  const publisherAudiences = usePublisherAudiences();
  const advertiserAudiences = useAdvertiserAudiences();
  const contextValue = useMemo<AudiencesContextValue>(() => {
    const intersectedFields = intersection(
      Object.keys(publisherAudiences),
      Object.keys(advertiserAudiences)
    ) as AudiencesContextValueKeys[];
    return intersectedFields.reduce<AudiencesContextValue>(
      (acc, field) => ({
        ...acc,
        [field]: isPublisher
          ? publisherAudiences[field]
          : advertiserAudiences[field],
      }),
      {} as unknown as AudiencesContextValue
    );
  }, [publisherAudiences, advertiserAudiences, isPublisher]);
  return (
    <AudiencesContext.Provider value={contextValue}>
      {children}
    </AudiencesContext.Provider>
  );
});

export const AudiencesWrapper = memo<React.PropsWithChildren>(
  ({ children }) => (
    <PublisherAudiencesWrapper>
      <AdvertiserAudiencesWrapper>
        <InnerAudiencesWrapper>{children}</InnerAudiencesWrapper>
      </AdvertiserAudiencesWrapper>
    </PublisherAudiencesWrapper>
  )
);

AudiencesWrapper.displayName = "AudiencesWrapper";

export default AudiencesWrapper;
