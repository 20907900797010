import { type Session } from "@decentriq/core";
import { loadAsync } from "jszip";
import { type SnackbarKey } from "notistack";
import { useCallback, useEffect, useState } from "react";
import { mapMediaDataRoomErrorToSnackbar, useDataRoomSnackbar } from "hooks";
import { computeCacheKeyString } from "wrappers/ApolloWrapper/resolvers/LruCache";
import useQueryMediaInsightsComputeJob, {
  type QueryMediaInsightsComputeJobHookResult,
} from "./useQueryMediaInsightsComputeJob/useQueryMediaInsightsComputeJob";

interface AdvertiserDataReportCacheKey {
  dataRoomId: string;
  advertiserDatasetHash: string;
}

interface AdvertiserDataReportHookPayload {
  key?: AdvertiserDataReportCacheKey | null;
  session?: Session | null;
  skip: boolean;
}

const useAdvertiserDataReport = ({
  session,
  key,
  skip,
}: AdvertiserDataReportHookPayload): QueryMediaInsightsComputeJobHookResult<{
  numberOfDeduplicatedRows: number;
  numberOfIngestedRows: number;
}> => {
  const jobType = "MEDIA_INSIGHTS_ADVERTISER_DATA_REPORT";
  const { enqueueSnackbar, closeSnackbar } = useDataRoomSnackbar();
  const setErrorSnackbarId = useState<SnackbarKey | undefined>()[1];

  const createCacheKeyString = useCallback(
    async (key: AdvertiserDataReportCacheKey): Promise<string> => {
      const publishedDatasets =
        (await session?.retrievePublishedDatasets(key.dataRoomId))
          ?.publishedDatasets || [];
      return computeCacheKeyString({
        ...key,
        publishedDatasets,
      });
    },
    [session]
  );

  const transform = useCallback(async (result: Uint8Array) => {
    const zip = await loadAsync(result);
    const reportFile = zip.file("report.json");
    if (reportFile === null) {
      throw new Error("report.json not found in zip");
    }
    const report:
      | { num_ingested_rows: number; num_deduplicated_rows: number }
      | undefined = JSON.parse(await reportFile.async("string"))["audiences"];
    if (
      !report ||
      report.num_deduplicated_rows === undefined ||
      report.num_ingested_rows === undefined
    ) {
      throw new Error("Advertiser data report is missing");
    }
    return {
      numberOfDeduplicatedRows: report.num_deduplicated_rows,
      numberOfIngestedRows: report.num_ingested_rows,
    };
  }, []);

  const computeJob = useQueryMediaInsightsComputeJob({
    createCacheKeyString,
    jobCacheKey: key ?? undefined,
    jobName: "ingestAudiencesReport",
    jobType,
    queryKeyPrefix: ["mi-dcr-advertiser-data-report"],
    session,
    skip,
    transform,
  });

  useEffect(() => {
    const error = computeJob.error;
    if (error) {
      const key = enqueueSnackbar(
        ...mapMediaDataRoomErrorToSnackbar(
          error,
          `Cannot fetch Advertiser data report`
        )
      );
      setErrorSnackbarId(key);
    } else {
      setErrorSnackbarId((snackbarId) => {
        if (snackbarId) {
          closeSnackbar(snackbarId);
        }
        return undefined;
      });
    }
  }, [closeSnackbar, computeJob.error, enqueueSnackbar, setErrorSnackbarId]);

  return computeJob;
};

export default useAdvertiserDataReport;
