import { Button } from "@mui/joy";
import { useBoolean } from "ahooks";
import { memo, useCallback } from "react";
import DataLabErrorReportDialog from "./DataLabErrorReportDialog";
import { type DataLabErrorReportContextValue } from "./DataLabErrorReportProvider";

interface DataLabErrorReportButtonProps extends DataLabErrorReportContextValue {
  dataLabId: string;
  error: string;
  details: string;
  closeSnackbar: () => void;
}

const DataLabErrorReportButton: React.FC<DataLabErrorReportButtonProps> = ({
  dataLabId,
  error,
  details,
  closeSnackbar,
  ...contextValue
}) => {
  const [isDialogOpen, { setFalse: closeDialog, setTrue: openDialog }] =
    useBoolean(false);
  const handleFinish = useCallback(() => {
    closeSnackbar();
    closeDialog();
  }, [closeDialog, closeSnackbar]);
  return (
    <>
      <Button
        onClick={openDialog}
        size="sm"
        sx={{
          "--Button-minHeight": "24px",
          borderRadius: "2px",
          lineHeight: 1,
          paddingInline: "0.5rem",
        }}
      >
        Report issue to Decentriq
      </Button>
      <DataLabErrorReportDialog
        dataLabId={dataLabId}
        details={details}
        error={error}
        onCancel={closeDialog}
        onFinish={handleFinish}
        open={isDialogOpen}
        {...contextValue}
      />
    </>
  );
};

DataLabErrorReportButton.displayName = "DataLabErrorReportButton";

export default memo(DataLabErrorReportButton);
