import {
  Accordion,
  AccordionDetails,
  AccordionGroup,
  AccordionSummary,
  List,
  ListItem,
} from "@mui/joy";
import { memo, type SetStateAction } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export type SidebarInnerMenuItem = {
  label: string;
  to: string;
};

type SidebarMenuItemContentProps = {
  innerMenuItems: SidebarInnerMenuItem[];
  menuItemLabel: string;
  isInnerMenuExpanded: boolean;
  setInnerMenuExpanded: (isExpanded: SetStateAction<boolean>) => void;
};

const SidebarMenuItemContent: React.FC<SidebarMenuItemContentProps> = memo(
  ({
    innerMenuItems = [],
    menuItemLabel,
    isInnerMenuExpanded,
    setInnerMenuExpanded,
  }) => {
    const navigate = useNavigate();
    const { pathname } = useLocation();

    return innerMenuItems.length ? (
      <AccordionGroup transition="0.2s linear">
        <Accordion
          expanded={isInnerMenuExpanded}
          onChange={(_, expanded) => setInnerMenuExpanded(expanded)}
          sx={{
            "--ListItem-minHeight": "16px",
          }}
        >
          <AccordionSummary
            slotProps={{
              button: {
                sx: {
                  backgroundColor: "transparent !important",
                  border: "none",
                  color: "initial",
                  fontWeight: 400,
                  lineHeight: "16px",
                  padding: 0,
                },
              },
            }}
            sx={{ padding: "6px 12px" }}
          >
            {menuItemLabel}
          </AccordionSummary>
          <AccordionDetails
            slotProps={{
              content: {
                sx: {
                  backgroundColor: "transparent",
                  paddingBottom: 0,
                },
              },
              root: {
                sx: {
                  backgroundColor: "transparent",
                },
              },
            }}
          >
            {innerMenuItems.length ? (
              <List
                sx={{
                  "--ListItem-minHeight": "16px",
                  fontSize: "14px",
                  marginTop: "6px",
                }}
              >
                {innerMenuItems.map(({ label, to }) => (
                  <ListItem
                    key={label}
                    onClick={() => navigate(to)}
                    sx={({ spacing }) => ({
                      "&:hover": {
                        fontWeight: pathname === to ? 600 : 500,
                      },
                      fontWeight: pathname === to ? 600 : "inherit",
                      padding: spacing(0.5, 0),
                    })}
                  >
                    {label}
                  </ListItem>
                ))}
              </List>
            ) : null}
          </AccordionDetails>
        </Accordion>
      </AccordionGroup>
    ) : (
      menuItemLabel
    );
  }
);

SidebarMenuItemContent.displayName = "SidebarMenuItemContent";

export default SidebarMenuItemContent;
