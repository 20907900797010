// TODO: Fix joy migration
import {
  faExclamationTriangle,
  faLockAlt,
  faLockOpenAlt,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, Tooltip, Typography } from "@mui/joy";
import { Portal } from "@mui/material";
import { useBoolean } from "ahooks";
import { format, isValid, parseISO } from "date-fns";
import { memo } from "react";
import { PublishedAndProtectedDialog } from "components";

const getDataRoomStatusIconAndTooltip = ({
  isLocked,
  updatedAt,
  strong,
}: any) => {
  const updatedAtDate = parseISO(updatedAt);
  const formatUpdatedAt = isValid(updatedAtDate)
    ? format(updatedAtDate, "yyyy-MM-dd HH:mm:ss.SSSxxx")
    : null;
  switch (true) {
    case isLocked === false:
      return {
        icon: strong ? faLockOpenAlt : faLockOpenAlt,
        status: "Draft",
        tooltip: formatUpdatedAt ? `Last saved ${formatUpdatedAt}` : "",
      };
    case isLocked === true:
      return {
        icon: strong ? faLockAlt : faLockAlt,
        status: "Published and protected",
        tooltip: formatUpdatedAt ? `Published on ${formatUpdatedAt}` : "",
      };
    default:
      return {
        icon: strong ? faExclamationTriangle : faExclamationTriangle,
        status: "Unknown",
        tooltip: formatUpdatedAt ? `Last saved ${formatUpdatedAt}` : "",
      };
  }
};

// @ts-expect-error ts-migrate(2339) FIXME: Property 'isLocked' does not exist on type '{ c... Remove this comment to see the full error message
const Status = memo(({ isLocked, updatedAt, strong = false }) => {
  const { status, icon, tooltip } = getDataRoomStatusIconAndTooltip({
    isLocked,
    strong,
    updatedAt,
  });
  const [
    isPublishedAndProtectedDialogOpen,
    {
      setTrue: openPublishedAndProtectedDialog,
      setFalse: closePublishedAndProtectedDialog,
    },
  ] = useBoolean(false);
  return (
    <>
      <Tooltip placement={strong ? "top" : "top-start"} title={tooltip}>
        <Button
          onClick={isLocked ? openPublishedAndProtectedDialog : () => {}}
          style={{
            minWidth: "auto",
            padding: "4px",
          }}
        >
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
            }}
          >
            <Typography
              color={strong ? "textPrimary" : "textSecondary"}
              component="div"
              style={{ lineHeight: 1 }}
            >
              <FontAwesomeIcon
                fixedWidth={true}
                icon={icon}
                style={{ marginRight: "0.125em" }}
              />
            </Typography>
            <Typography
              color={strong ? "textPrimary" : "textSecondary"}
              component="div"
              style={{
                borderRadius: "2px",
                display: "inline",
                lineHeight: 1,
                padding: "2px",
              }}
            >
              {strong ? <strong>{status}</strong> : status}
            </Typography>
          </Box>
        </Button>
      </Tooltip>
      <Portal>
        <PublishedAndProtectedDialog
          onClose={closePublishedAndProtectedDialog}
          open={isPublishedAndProtectedDialogOpen}
        />
      </Portal>
    </>
  );
});

export default Status;
