import { testIds } from "@decentriq/utils";
import { faPlus } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@mui/joy";
import { useBoolean } from "ahooks";
import { memo } from "react";
import UpsertPublisherDisplayConfigurationDialog from "../UpsertPublisherDisplayConfigurationDialog/UpsertPublisherDisplayConfigurationDialog";

const CreatePublisherDisplayConfigurationButton = memo(() => {
  const [isDialogOpen, { setTrue: openDialog, setFalse: closeDialog }] =
    useBoolean(false);
  return (
    <span>
      <Button
        color="primary"
        data-testid={testIds.publisherPortal.displayConfiguration.createButton}
        onClick={openDialog}
        startDecorator={<FontAwesomeIcon fixedWidth={true} icon={faPlus} />}
        variant="solid"
      >
        Display configuration
      </Button>
      <UpsertPublisherDisplayConfigurationDialog
        onClose={closeDialog}
        open={isDialogOpen}
      />
    </span>
  );
});
CreatePublisherDisplayConfigurationButton.displayName =
  "CreatePublisherDisplayConfigurationButton";

export default CreatePublisherDisplayConfigurationButton;
