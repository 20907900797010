import { InfoTooltip } from "@decentriq/components";
import { Box, Checkbox } from "@mui/joy";
import { Alert } from "@mui/material";
import { memo, useCallback, useMemo, useState } from "react";
import { ErrorReportDialog } from "components";
import { type DataRoomErrorReportContextValue } from "contexts";
import { type ReportErrorInput } from "hooks";
import { DataRoomType, dataRoomTypePresentationMap } from "models";
import { getEffectiveErrorMessage, logError } from "utils";

interface DataRoomErrorReportDialogProps
  extends DataRoomErrorReportContextValue {
  open: boolean;
  dataRoomId: string;
  onCancel: () => void;
  onFinish: () => void;
  error: string;
  details: string;
}

const DataRoomErrorReportDialog: React.FC<DataRoomErrorReportDialogProps> = ({
  open,
  dataRoomId,
  onCancel,
  onFinish,
  error,
  details,
  getDcrHLRepresentation,
  getAuditLog,
  getDevelopmentInfo,
  getRequestsInfo,
  dataRoomType = DataRoomType.DataScience,
}) => {
  const askForHLRepresentation = Boolean(getDcrHLRepresentation);
  const askForAuditLog = Boolean(getAuditLog);
  const askForRequestsInfo = Boolean(getRequestsInfo);
  const askForDevelopmentInfo = Boolean(getDevelopmentInfo);
  const [shouldReportHLRepresentation, setShouldReportHLRepresentation] =
    useState<boolean>(askForHLRepresentation);
  const [shouldReportAuditLog, setShouldReportAuditLog] =
    useState<boolean>(askForAuditLog);
  const [shouldReportRequestsInfo, setShouldReportRequestsInfo] =
    useState<boolean>(askForRequestsInfo);
  const [shouldReportDevelopmentInfo, setShouldReportDevelopmentInfo] =
    useState<boolean>(askForDevelopmentInfo);
  const defaultDetails = useMemo(
    () =>
      `Data clean room ID: ${dataRoomId}\nData clean room type: ${dataRoomTypePresentationMap[dataRoomType]}\nError message: ${error}\nError details: ${details}`,
    [dataRoomId, dataRoomType, error, details]
  );
  const handleGenerateReport = useCallback(
    async (details: string) => {
      const report: ReportErrorInput = {
        details: details || "",
        errorContext: [
          {
            content: dataRoomId,
            name: "dataRoomId",
          },
        ],
        origin: dataRoomType,
      };
      const errorContextFetchers: {
        name: string;
        fetcher: () => Promise<string>;
      }[] = [];
      if (shouldReportAuditLog) {
        errorContextFetchers.push({
          fetcher: getAuditLog!,
          name: "auditLog",
        });
      }
      if (shouldReportHLRepresentation) {
        errorContextFetchers.push({
          fetcher: getDcrHLRepresentation!,
          name: "hlRepresentation",
        });
      }
      if (shouldReportDevelopmentInfo) {
        errorContextFetchers.push({
          fetcher: getDevelopmentInfo!,
          name: "developmentInfo",
        });
      }
      if (shouldReportRequestsInfo) {
        errorContextFetchers.push({
          fetcher: getRequestsInfo!,
          name: "requestsInfo",
        });
      }
      await Promise.all(
        errorContextFetchers.map(({ name, fetcher }) =>
          fetcher()
            .then((content) => report.errorContext.push({ content, name }))
            .catch((error) => {
              logError(error);
              report.errorContext.push({
                content: getEffectiveErrorMessage(error),
                name,
              });
            })
        )
      );
      return report;
    },
    [
      dataRoomId,
      dataRoomType,
      getAuditLog,
      getDcrHLRepresentation,
      shouldReportAuditLog,
      getDevelopmentInfo,
      getRequestsInfo,
      shouldReportDevelopmentInfo,
      shouldReportRequestsInfo,
      shouldReportHLRepresentation,
    ]
  );
  return (
    <ErrorReportDialog
      defaultDetails={defaultDetails}
      footer={
        <Alert
          severity="info"
          sx={(theme) => ({ marginTop: theme.spacing(2) })}
        >
          Note: Data and computation results are never included in the error
          report.
        </Alert>
      }
      generateReport={handleGenerateReport}
      header={
        <Box
          sx={{
            display: "inline-flex",
            marginLeft: "1.5rem",
            marginTop: "1.25rem",
          }}
        >
          {askForHLRepresentation && (
            <Checkbox
              checked={shouldReportHLRepresentation}
              label={
                <>
                  Send DCR definition
                  <InfoTooltip
                    tooltip={`This will attach the same content as when clicking the “…” top-right menu in the DCR and choosing the options “Export to JSON” or "View configuration".`}
                  />
                </>
              }
              onChange={(event) =>
                setShouldReportHLRepresentation(event.target.checked)
              }
              sx={{ display: "flex", margin: 0, marginRight: "1.5rem" }}
            />
          )}
          {askForDevelopmentInfo && (
            <Checkbox
              checked={shouldReportDevelopmentInfo}
              label={
                <>
                  Send Development Info
                  <InfoTooltip
                    tooltip={`This will attach computations that you have created under Development tab.`}
                  />
                </>
              }
              onChange={(event) =>
                setShouldReportDevelopmentInfo(event.target.checked)
              }
              sx={{ display: "flex", margin: 0, marginRight: "1.5rem" }}
            />
          )}
          {askForRequestsInfo && (
            <Checkbox
              checked={shouldReportRequestsInfo}
              label={
                <>
                  Send Requests Info
                  <InfoTooltip
                    tooltip={`This will attach computations that you have under Requests tab.`}
                  />
                </>
              }
              onChange={(event) =>
                setShouldReportRequestsInfo(event.target.checked)
              }
              sx={{ display: "flex", margin: 0, marginRight: "1.5rem" }}
            />
          )}
          {askForAuditLog && (
            <Checkbox
              checked={shouldReportAuditLog}
              label={
                <>
                  Send audit log
                  <InfoTooltip
                    tooltip={`This will attach the same content as when accessing the "Audit log" tab and clicking "Export".`}
                  />
                </>
              }
              onChange={(event) =>
                setShouldReportAuditLog(event.target.checked)
              }
              sx={{ display: "flex", margin: 0 }}
            />
          )}
        </Box>
      }
      onCancel={onCancel}
      onFinish={onFinish}
      open={open}
    />
  );
};

DataRoomErrorReportDialog.displayName = "DataRoomErrorReportDialog";

export default memo(DataRoomErrorReportDialog);
