import { memo } from "react";
import { useFormContext } from "react-hook-form";
import { LineChart, RadarChart, TagsCloud } from "./components";

const MediaDataRoomInsightsCharts: React.FC = () => {
  const { watch } = useFormContext();
  const selectedChartType = watch("chartType");

  switch (selectedChartType) {
    case "RADAR":
      return <RadarChart />;
    case "LINE":
      return <LineChart />;
    case "TAG_CLOUD":
      return <TagsCloud />;
    default:
      return null;
  }
};

MediaDataRoomInsightsCharts.displayName = "MediaDataRoomInsightsCharts";

export default memo(MediaDataRoomInsightsCharts);
