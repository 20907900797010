import { type Session } from "@decentriq/core";
import { loadAsync } from "jszip";
import { type SnackbarKey } from "notistack";
import { useCallback, useEffect, useState } from "react";
import { mapMediaDataRoomErrorToSnackbar, useDataRoomSnackbar } from "hooks";
import {
  type OverlapInsightsCacheKey,
  type OverlapSegment,
  type OverlapSegmentsCollection,
} from "../models";
import useQueryMediaInsightsComputeJob, {
  type QueryMediaInsightsComputeJobHookResult,
} from "./useQueryMediaInsightsComputeJob/useQueryMediaInsightsComputeJob";
import { filterNullableOverlapInsightsRows } from "./helpers";

// @matyasfodor TODO add zod schema to parse the response

interface QueryInsightsHookPayload {
  createCacheKeyString: (key: OverlapInsightsCacheKey) => Promise<string>;
  key?: OverlapInsightsCacheKey | null;
  session?: Session | null;
  skip: boolean;
}

export interface QueryInsights {
  insights: OverlapSegment[];
}

export type QueryInsightsHookResult =
  QueryMediaInsightsComputeJobHookResult<QueryInsights>;

/**
 * useQueryInsights - powers the charts on the Inisghts tab
 * Should only be activated if the flag is enabled
 */
const useQueryInsights = ({
  createCacheKeyString,
  key,
  session,
  skip,
}: QueryInsightsHookPayload): QueryInsightsHookResult => {
  const { enqueueSnackbar, closeSnackbar } = useDataRoomSnackbar();
  const setErrorSnackbarId = useState<SnackbarKey | undefined>()[1];

  const jobType = "MEDIA_INSIGHTS_INSIGHTS";

  const transform = useCallback(
    async (result: Uint8Array): Promise<QueryInsights> => {
      const zip = await loadAsync(result);

      const overlapInsightsSegmentsFile = zip.file("segments.json");
      if (overlapInsightsSegmentsFile === null) {
        throw new Error("segments.json not found in zip");
      }
      const overlapInsightsSegments: OverlapSegmentsCollection = JSON.parse(
        await overlapInsightsSegmentsFile.async("string")
      );
      const insights = filterNullableOverlapInsightsRows(
        overlapInsightsSegments
      );

      return {
        insights,
      };
    },
    []
  );

  const computeJob = useQueryMediaInsightsComputeJob({
    createCacheKeyString,
    jobCacheKey: key ?? undefined,
    jobName: "computeInsights",
    jobType,
    queryKeyPrefix: ["mi", jobType],
    session,
    skip,
    transform,
  });

  useEffect(() => {
    if (computeJob.error) {
      const snackbarId = enqueueSnackbar(
        ...mapMediaDataRoomErrorToSnackbar(
          computeJob.error,
          `Cannot fetch insights`
        )
      );
      setErrorSnackbarId(snackbarId);
    } else {
      setErrorSnackbarId((snackbarId) => {
        if (snackbarId) {
          closeSnackbar(snackbarId);
        }
        return undefined;
      });
    }
  }, [enqueueSnackbar, closeSnackbar, setErrorSnackbarId, computeJob.error]);

  return computeJob;
};

export default useQueryInsights;
