import {
  DqDatasetSyncInfo,
  DqDatasetSyncsList,
  SyncDirectionType,
} from "@decentriq/components";
import { useOrganizationMicrosoftDspMemberQuery } from "@decentriq/graphql/dist/hooks";
import { DataTargetType } from "@decentriq/graphql/dist/types";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  ModalDialog,
} from "@mui/joy";
import { type Dispatch, memo, type SetStateAction, useState } from "react";
import { useWizard } from "react-use-wizard";
import { type MICROSOFT_DSP_MEMBER } from "containers/OrganizationDataConnectorConfigurationsEditor/OrganizationDataConnectorConfigurationsEditor";
import { useOrganizationPreferences } from "hooks";

interface SelectSyncStepProps {
  onClose?: () => void;
  onSelect: [Dispatch<SetStateAction<DataTargetType | null>>];
}

const SelectSyncStep: React.FC<SelectSyncStepProps> = memo(
  ({ onClose, onSelect: [onSelectSyncType] }) => {
    const { handleStep, nextStep } = useWizard();
    const [sync, setSync] = useState<[DataTargetType | null, null]>([
      null,
      null,
    ]);
    const [syncType] = sync || [];
    handleStep(() => onSelectSyncType(syncType));
    const { organizationId } = useOrganizationPreferences();
    const { data: organizationMicrosoftDspMemberQueryData } =
      useOrganizationMicrosoftDspMemberQuery({ variables: { organizationId } });
    const microsoftDspMember = organizationMicrosoftDspMemberQueryData
      ?.organization?.microsoftDspMember as MICROSOFT_DSP_MEMBER;
    const isMicrosoftDspDisabled =
      !microsoftDspMember || microsoftDspMember === "None";
    const disabledSyncTypes: Partial<Record<DataTargetType, boolean>> = {
      [DataTargetType.MicrosoftDsp]: isMicrosoftDspDisabled,
      [DataTargetType.TradeDesk]: true,
      [DataTargetType.Sportradar]: true,
    };
    return (
      <ModalDialog>
        <DialogTitle>Export dataset</DialogTitle>
        <Divider />
        <DialogContent>
          <DqDatasetSyncsList
            getDisabledItems={(syncTypes) =>
              syncTypes.filter(
                (syncType) => disabledSyncTypes[syncType as DataTargetType]
              ) as DataTargetType[]
            }
            onChange={(value) => {
              setSync(value as [DataTargetType, null]);
            }}
            syncDirection={SyncDirectionType.EXPORT}
            value={sync}
          />
        </DialogContent>
        <Divider />
        {syncType ? (
          <DqDatasetSyncInfo
            syncDirection={SyncDirectionType.EXPORT}
            syncType={syncType}
          />
        ) : null}
        <Divider />
        <DialogActions>
          <Button onClick={() => onClose?.()}>Cancel</Button>
          <Button
            color="primary"
            disabled={!syncType}
            onClick={nextStep}
            variant="solid"
          >
            Continue
          </Button>
        </DialogActions>
      </ModalDialog>
    );
  }
);

export default SelectSyncStep;
