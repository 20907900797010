import { type IconProp } from "@fortawesome/fontawesome-svg-core";
import { faChartLine, faRadar } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, FormLabel, ToggleButtonGroup } from "@mui/joy";
import { memo } from "react";
import { Controller, useFormContext } from "react-hook-form";
import {
  type OverlapInsightsChartTypes,
  useMediaDataRoomOverlapInsights,
} from "features/MediaInsightsDcr";

type ChartTypeButton = {
  icon: IconProp;
  label: string;
  value: OverlapInsightsChartTypes;
};

const CHART_TYPES_BUTTONS: ChartTypeButton[] = [
  {
    icon: faRadar,
    label: "Radar",
    value: "RADAR",
  },
  {
    icon: faChartLine,
    label: "Line",
    value: "LINE",
  },
];

const MediaDataRoomInsightsChartTypeSelector: React.FC = () => {
  const { isEmptyAggregation } = useMediaDataRoomOverlapInsights();
  const { control: formControl } = useFormContext();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
      }}
    >
      <FormLabel sx={{ mb: "6px" }}>Chart Type</FormLabel>
      <Controller
        control={formControl}
        name="chartType"
        render={({ field: { onChange = () => {}, value } = {} }) => (
          <ToggleButtonGroup
            onChange={(_, newValue: OverlapInsightsChartTypes) =>
              onChange(newValue)
            }
            size="sm"
            value={value}
          >
            {CHART_TYPES_BUTTONS.map(({ label, value, icon }) => (
              <Button
                disabled={isEmptyAggregation}
                key={value}
                startDecorator={<FontAwesomeIcon icon={icon} />}
                value={value}
              >
                {label}
              </Button>
            ))}
          </ToggleButtonGroup>
        )}
      />
    </Box>
  );
};

MediaDataRoomInsightsChartTypeSelector.displayName =
  "MediaDataRoomInsightsChartTypeSelector";

export default memo(MediaDataRoomInsightsChartTypeSelector);
