import { createContext, useContext } from "react";

export interface PublishedDataRoomContextValue {
  dataRoomId: string;
  dcrHash: string;
  driverAttestationHash: string;
  enableAirlock: boolean;
  enableRequests: boolean;
  isStopped: boolean;
  isDeactivated: boolean;
  testing: boolean;
  switchToOverview: () => void;
  switchToDev: () => void;
  switchToRequests: () => void;
  enableAutomergeFeature: boolean;
}

export const PublishedDataRoomContext =
  createContext<PublishedDataRoomContextValue>({
    dataRoomId: "__default__",
    dcrHash: "__default__",
    driverAttestationHash: "__default__",
    enableAirlock: false,
    enableAutomergeFeature: false,
    enableRequests: false,
    isDeactivated: false,
    isStopped: false,
    switchToDev: () => {},
    switchToOverview: () => {},
    switchToRequests: () => {},
    testing: false,
  });

export const PublishedDataRoomProvider = PublishedDataRoomContext.Provider;

export const usePublishedDataRoom = () => useContext(PublishedDataRoomContext);
