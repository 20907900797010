import { z } from "zod";

export const schema = z.object({
  collaborationRequestUsers: z
    .array(z.string())
    .min(1, "At least one participant is required"),
  description: z
    .string()
    .max(800, "Description must be no longer than 800 characters"),
  logo: z.string().nullish(),
  marketIds: z
    .array(z.string())
    .min(1, "At least one active market is required"),
  name: z.string().min(1, "Name is required"),
});

export type SchemaType = z.infer<typeof schema>;

export const defaultValues: SchemaType = {
  collaborationRequestUsers: [],
  description: "",
  logo: undefined,
  marketIds: [],
  name: "",
};
