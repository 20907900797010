import { Key } from "@decentriq/utils";
import { Button, Typography } from "@mui/joy";
import { useSnackbar } from "notistack";
import { memo, useCallback } from "react";
import { type KeychainItem, KeychainItemKind } from "services/keychain";
import { useKeychain } from "contexts";
import { DatasetsList, DatasetsView } from "features/datasets";
import { hashedIdAsArray } from "utils/apicore";

export interface DatasetSelectorProps {
  onSelect: (dataset: { key: Key; manifestHash: string }) => void;
}

const DatasetSelector: React.FC<DatasetSelectorProps> = memo(({ onSelect }) => {
  const { enqueueSnackbar } = useSnackbar();
  const keychain = useKeychain();
  const handleDatasetSelect = useCallback(
    async (datasetHash: string) => {
      const keychainItem: KeychainItem | null = await keychain
        .getItem(datasetHash, KeychainItemKind.Dataset)
        .catch((error) => {
          enqueueSnackbar(
            "Cannot select the dataset as it does not exist in the keychain",
            {
              persist: true,
              variant: "info",
            }
          );
          return null;
        });
      if (!keychainItem) {
        return;
      }
      const key = new Key(hashedIdAsArray(keychainItem.value));
      const manifestHash = keychainItem.id;
      onSelect({ key, manifestHash });
    },
    [onSelect, enqueueSnackbar, keychain]
  );
  const customDatasetActions = useCallback(
    (datasetHash: string) => {
      return (
        <Button
          color="primary"
          onClick={() => handleDatasetSelect(datasetHash)}
          size="sm"
          variant="solid"
        >
          Select dataset
        </Button>
      );
    },
    [handleDatasetSelect]
  );
  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        flexDirection: "column",
        height: "100%",
        justifyContent: "space-between",
        maxHeight: "100%",
        overflowX: "auto",
      }}
    >
      <Typography
        sx={{
          color: "inherit",
          marginBottom: 0.5,
        }}
      >
        Select from existing datasets
      </Typography>
      <DatasetsList
        customDatasetActions={customDatasetActions}
        view={DatasetsView.KeychainOnly}
      />
    </div>
  );
});
DatasetSelector.displayName = "DatasetSelector";

export default DatasetSelector;
