import {
  useUpdateUserLogoMutation,
  useUserDetailsQuery,
} from "@decentriq/graphql/dist/hooks";
import { faTrashCan } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button } from "@mui/joy";
import { memo, useCallback } from "react";
import { ImageUploadButton } from "components";
import { CommonSnackbarOrigin, useGeneralSnackbar, useUserRole } from "hooks";
import { getEffectiveErrorMessage } from "utils";

type UserLogoEditorProps = {
  userId: string;
};

const UserLogoEditor: React.FC<UserLogoEditorProps> = ({ userId }) => {
  const { enqueueSnackbar } = useGeneralSnackbar({
    origin: CommonSnackbarOrigin.ADMIN,
  });
  const { isSuperAdminReadOnly } = useUserRole();
  const [updateUserLogoMutation] = useUpdateUserLogoMutation();
  const { data: { user } = {} } = useUserDetailsQuery({
    variables: { userId },
  });
  const updateUserLogo = useCallback(
    async (logo: string | undefined) => {
      try {
        await updateUserLogoMutation({
          refetchQueries: ["CurrentUserDomain"],
          update: (cache, { data }) => {
            cache.modify({
              fields: {
                logo: () => {
                  return data?.user?.update?.record?.logo;
                },
              },
              id: cache.identify({
                __typename: "User",
                id: userId,
              }),
            });
          },
          variables: {
            input: {
              logo: { value: logo || null },
              userId,
            },
          },
        });
      } catch (error) {
        enqueueSnackbar("User logo could not be updated.", {
          context: getEffectiveErrorMessage(error),
          persist: true,
          variant: "error",
        });
      }
    },
    [enqueueSnackbar, updateUserLogoMutation, userId]
  );
  return (
    <Box
      sx={(theme) => ({
        alignItems: "start",
        display: "flex",
        flexDirection: "column",
        mb: theme.spacing(2),
      })}
    >
      {user?.logo && (
        <img
          alt={userId}
          loading="lazy"
          src={`data:image;base64,${user?.logo}`}
          style={{ height: 250, marginRight: 16 }}
        />
      )}
      <Box sx={(theme) => ({ mt: theme.spacing(1) })}>
        <ImageUploadButton
          disabled={isSuperAdminReadOnly}
          onImageSelected={updateUserLogo}
        />
        {user?.logo && (
          <Button
            disabled={isSuperAdminReadOnly}
            onClick={() => updateUserLogo(undefined)}
            startDecorator={<FontAwesomeIcon icon={faTrashCan} />}
            sx={{ height: "fit-content", marginLeft: 2 }}
          >
            Remove logo
          </Button>
        )}
      </Box>
    </Box>
  );
};

UserLogoEditor.displayName = "UserLogoEditor";

export default memo(UserLogoEditor);
