import { faArrowRightArrowLeft as falArrowRightArrowLeft } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Modal,
  ModalDialog,
  Stack,
} from "@mui/joy";
import { Typography } from "@mui/material";
import { memo, useCallback } from "react";

export interface MigrationAvailableDialogProps {
  open: boolean;
  postponeable: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

const MigrationAvailableDialog = memo<MigrationAvailableDialogProps>(
  ({ open, onClose, onConfirm, postponeable }) => {
    const onModalClose = useCallback(() => {
      if (postponeable) {
        onClose();
      }
    }, [onClose, postponeable]);
    return (
      <Modal
        disableEscapeKeyDown={!postponeable}
        onClose={onModalClose}
        open={open}
      >
        <ModalDialog role="alertdialog">
          <DialogTitle>
            <FontAwesomeIcon
              fixedWidth={true}
              icon={falArrowRightArrowLeft}
              size="3x"
            />
            Migration Available
          </DialogTitle>
          <DialogContent>
            <Stack direction="column" spacing={2}>
              <Typography>
                New keychain migration available. Your keychain will be moved to
                a secure enclave. You’ll receive a code via email.
              </Typography>
              <Typography>
                Would you like to proceed with the migration?
              </Typography>
            </Stack>
          </DialogContent>
          <DialogActions sx={{ flexDirection: "column" }}>
            <Button
              color="primary"
              loadingIndicator="Migrating..."
              onClick={onConfirm}
              variant="solid"
            >
              Yes, migrate
            </Button>
            {postponeable && <Button onClick={onClose}>Not now</Button>}
          </DialogActions>
        </ModalDialog>
      </Modal>
    );
  }
);

MigrationAvailableDialog.displayName = "MigrationAvailableDialog";
export default MigrationAvailableDialog;
