import { useApolloClient } from "@apollo/client";
import {
  DeprovisionDataLabFromMediaInsightsDcrDocument,
  ProvisionDataLabToMediaInsightsDcrDocument,
} from "@decentriq/graphql/dist/types";
import { type Key } from "@decentriq/utils";
import {
  type data_lab as ddcDataLab,
  data_lab_schema as ddcDataLabSchema,
} from "ddc";
import * as forge from "node-forge";
import { createContext, memo, useCallback, useMemo } from "react";
import { type DataLab } from "features/dataLabs/models";
import {
  useMediaDataRoom,
  useMediaDataRoomInsightsData,
} from "features/mediaDataRoom/contexts";
import { useMediaDataRoomRequest } from "features/mediaDataRoom/hooks";
import { useSafeContext } from "hooks";

interface DataRoomInput {
  datasetHash: string;
  encryptionKey: Key;
}

export interface MediaDataRoomHandlersContextValue {
  publishAdvertiserDataset: (props: {
    dataset: DataRoomInput;
  }) => Promise<void>;
  unpublishAdvertiserDataset: () => Promise<void>;
  publishPublisherDatasets: (props: {
    dataLabId: string;
    usersDataset: DataRoomInput;
    segmentsDataset?: DataRoomInput;
    demographicsDataset?: DataRoomInput;
    embeddingsDataset?: DataRoomInput;
  }) => Promise<void>;
  unpublishPublisherDatasets: (props: {
    withDemographicsDataset: boolean;
    withEmbeddingsDataset: boolean;
    withSegmentsDataset: boolean;
  }) => Promise<void>;
  canProvisionDataLab: (dataLab: DataLab) => boolean;
}

export const MediaDataRoomHandlersContext =
  createContext<MediaDataRoomHandlersContextValue | null>(null);
MediaDataRoomHandlersContext.displayName = "MediaDataRoomHandlersContext";

export const useMediaDataRoomHandlers = () =>
  useSafeContext(MediaDataRoomHandlersContext);

export type MediaDataRoomHandlersWrapperProps = React.PropsWithChildren<{
  dataRoomId: string;
  driverAttestationHash: string;
}>;

const MediaDataRoomHandlersWrapper = memo<MediaDataRoomHandlersWrapperProps>(
  ({ children, dataRoomId, driverAttestationHash }) => {
    const apolloClient = useApolloClient();
    const { rawDefinition } = useMediaDataRoom();
    const {
      session,
      datasets: {
        updateAdvertiserDatasetHash,
        updatePublisherDatasetsHashes,
        updateAudiencesDatasetHash,
      },
    } = useMediaDataRoomInsightsData();
    const commonRequestCreator = useCallback(
      (dataRoomIdHex: string) => ({ dataRoomIdHex }),
      []
    );
    const [publishAdvertiserDatasetRequest] = useMediaDataRoomRequest({
      dataRoomId,
      driverAttestationHash,
      key: "publishAudiencesDataset",
    });
    const [unpublishAdvertiserDatasetRequest] = useMediaDataRoomRequest({
      dataRoomId,
      driverAttestationHash,
      key: "unpublishAudiencesDataset",
      requestCreator: commonRequestCreator,
    });
    const [unpublishMatchingDatasetRequest] = useMediaDataRoomRequest({
      dataRoomId,
      driverAttestationHash,
      key: "unpublishMatchingDataset",
      requestCreator: commonRequestCreator,
    });
    const [unpublishSegmentsDatasetRequest] = useMediaDataRoomRequest({
      dataRoomId,
      driverAttestationHash,
      key: "unpublishSegmentsDataset",
      requestCreator: commonRequestCreator,
    });
    const [unpublishDemographicsDatasetRequest] = useMediaDataRoomRequest({
      dataRoomId,
      driverAttestationHash,
      key: "unpublishDemographicsDataset",
      requestCreator: commonRequestCreator,
    });
    const [unpublishEmbeddingsDatasetRequest] = useMediaDataRoomRequest({
      dataRoomId,
      driverAttestationHash,
      key: "unpublishEmbeddingsDataset",
      requestCreator: commonRequestCreator,
    });
    const [publishMatchingDatasetRequest] = useMediaDataRoomRequest({
      dataRoomId,
      driverAttestationHash,
      key: "publishMatchingDataset",
    });
    const [publishSegmentsDatasetRequest] = useMediaDataRoomRequest({
      dataRoomId,
      driverAttestationHash,
      key: "publishSegmentsDataset",
    });
    const [publishEmbeddingsDatasetRequest] = useMediaDataRoomRequest({
      dataRoomId,
      driverAttestationHash,
      key: "publishEmbeddingsDataset",
    });
    const [publishDemographicsDatasetRequest] = useMediaDataRoomRequest({
      dataRoomId,
      driverAttestationHash,
      key: "publishDemographicsDataset",
    });
    const [unpublishAudiencesJson] = useMediaDataRoomRequest({
      dataRoomId,
      driverAttestationHash,
      key: "unpublishAudiencesJson",
      requestCreator: commonRequestCreator,
    });
    const publishAdvertiserDataset = useCallback<
      MediaDataRoomHandlersContextValue["publishAdvertiserDataset"]
    >(
      async ({ dataset }) => {
        const encryptionKey: Uint8Array = dataset.encryptionKey.material;
        await publishAdvertiserDatasetRequest({
          options: { session },
          requestCreator: (dataRoomIdHex, scopeIdHex) => ({
            dataRoomIdHex,
            datasetHashHex: dataset.datasetHash,
            encryptionKeyHex: forge.util.binary.hex.encode(encryptionKey),
            scopeIdHex,
          }),
        });
        updateAdvertiserDatasetHash(dataset.datasetHash);
      },
      [publishAdvertiserDatasetRequest, session, updateAdvertiserDatasetHash]
    );
    const unpublishAdvertiserDataset = useCallback<
      MediaDataRoomHandlersContextValue["unpublishAdvertiserDataset"]
    >(async () => {
      await unpublishAdvertiserDatasetRequest({ options: { session } });
      await unpublishAudiencesJson({ options: { session } });
      updateAdvertiserDatasetHash(null);
      updateAudiencesDatasetHash(null);
    }, [
      unpublishAdvertiserDatasetRequest,
      updateAudiencesDatasetHash,
      unpublishAudiencesJson,
      session,
      updateAdvertiserDatasetHash,
    ]);
    const unpublishPublisherDatasets = useCallback<
      MediaDataRoomHandlersContextValue["unpublishPublisherDatasets"]
    >(
      async ({
        withDemographicsDataset,
        withEmbeddingsDataset,
        withSegmentsDataset,
      }) => {
        await unpublishMatchingDatasetRequest({ options: { session } });

        if (withSegmentsDataset) {
          await unpublishSegmentsDatasetRequest({ options: { session } });
        }
        if (withDemographicsDataset) {
          await unpublishDemographicsDatasetRequest({ options: { session } });
        }
        if (withEmbeddingsDataset) {
          await unpublishEmbeddingsDatasetRequest({ options: { session } });
        }
        await apolloClient.mutate({
          mutation: DeprovisionDataLabFromMediaInsightsDcrDocument,
          variables: {
            dataRoomId,
          },
        });
        updatePublisherDatasetsHashes(null);
      },
      [
        unpublishMatchingDatasetRequest,
        unpublishSegmentsDatasetRequest,
        unpublishDemographicsDatasetRequest,
        unpublishEmbeddingsDatasetRequest,
        dataRoomId,
        apolloClient,
        session,
        updatePublisherDatasetsHashes,
      ]
    );
    const publishPublisherDatasets = useCallback<
      MediaDataRoomHandlersContextValue["publishPublisherDatasets"]
    >(
      async ({
        dataLabId,
        usersDataset,
        segmentsDataset,
        demographicsDataset,
        embeddingsDataset,
      }) => {
        await publishMatchingDatasetRequest({
          options: { session },
          requestCreator: (dataRoomIdHex, scopeIdHex) => ({
            dataRoomIdHex,
            datasetHashHex: usersDataset.datasetHash,
            encryptionKeyHex: forge.util.binary.hex.encode(
              usersDataset.encryptionKey.material
            ),
            scopeIdHex,
          }),
        });
        if (segmentsDataset !== null && segmentsDataset !== undefined) {
          await publishSegmentsDatasetRequest({
            options: { session },
            requestCreator: (dataRoomIdHex, scopeIdHex) => ({
              dataRoomIdHex,
              datasetHashHex: segmentsDataset.datasetHash,
              encryptionKeyHex: forge.util.binary.hex.encode(
                segmentsDataset.encryptionKey.material
              ),
              scopeIdHex,
            }),
          });
        }
        if (demographicsDataset !== null && demographicsDataset !== undefined) {
          await publishDemographicsDatasetRequest({
            options: { session },
            requestCreator: (dataRoomIdHex, scopeIdHex) => ({
              dataRoomIdHex,
              datasetHashHex: demographicsDataset.datasetHash,
              encryptionKeyHex: forge.util.binary.hex.encode(
                demographicsDataset.encryptionKey.material
              ),
              scopeIdHex,
            }),
          });
        }
        if (embeddingsDataset !== null && embeddingsDataset !== undefined) {
          await publishEmbeddingsDatasetRequest({
            options: { session },
            requestCreator: (dataRoomIdHex, scopeIdHex) => ({
              dataRoomIdHex,
              datasetHashHex: embeddingsDataset.datasetHash,
              encryptionKeyHex: forge.util.binary.hex.encode(
                embeddingsDataset.encryptionKey.material
              ),
              scopeIdHex,
            }),
          });
        }
        await apolloClient.mutate({
          mutation: ProvisionDataLabToMediaInsightsDcrDocument,
          variables: {
            input: {
              dataLabId,
              dataRoomId,
            },
          },
        });
        updatePublisherDatasetsHashes({
          demographicsDatasetHash: demographicsDataset?.datasetHash ?? null,
          embeddingsDatasetHash: embeddingsDataset?.datasetHash ?? null,
          matchingDatasetHash: usersDataset.datasetHash,
          segmentsDatasetHash: segmentsDataset?.datasetHash ?? null,
        });
      },
      [
        session,
        apolloClient,
        dataRoomId,
        publishDemographicsDatasetRequest,
        publishSegmentsDatasetRequest,
        publishMatchingDatasetRequest,
        updatePublisherDatasetsHashes,
        publishEmbeddingsDatasetRequest,
      ]
    );
    const canProvisionDataLab = useCallback<
      MediaDataRoomHandlersContextValue["canProvisionDataLab"]
    >(
      (dataLab: DataLab) => {
        if (!session) {
          return false;
        }
        return session.compiler.dataLab.isCompatibleWithAbMediaDcr(
          ddcDataLabSchema.default.parse(
            JSON.parse(dataLab.highLevelRepresentationAsString)
          ) as ddcDataLab.DataLab,
          rawDefinition
        );
      },
      [session, rawDefinition]
    );
    const contextValue = useMemo<MediaDataRoomHandlersContextValue>(
      () => ({
        canProvisionDataLab,
        publishAdvertiserDataset,
        publishPublisherDatasets,
        unpublishAdvertiserDataset,
        unpublishPublisherDatasets,
      }),
      [
        publishAdvertiserDataset,
        unpublishAdvertiserDataset,
        unpublishPublisherDatasets,
        publishPublisherDatasets,
        canProvisionDataLab,
      ]
    );
    return (
      <MediaDataRoomHandlersContext.Provider value={contextValue}>
        {children}
      </MediaDataRoomHandlersContext.Provider>
    );
  }
);

MediaDataRoomHandlersWrapper.displayName = "MediaDataRoomHandlersWrapper";

export default MediaDataRoomHandlersWrapper;
