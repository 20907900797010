import {
  DqCard,
  DqCardContent,
  DqSidePanel,
  DqSidePanelContent,
  DqSidePanelTitle,
} from "@decentriq/components";
import { memo } from "react";
import { useLookalikeAdvertiserAudienceGenerator } from "../../contexts/LookalikeAdvertiserAudienceGeneratorContext";
import {
  PositiveCurveScoreBar,
  PositiveCurveScoreChart,
} from "../AudienceQualityStatistics";

const AudienceQualityStatisticsDrawer: React.FC = () => {
  const {
    isAdvertiserAudienceQualityDrawerOpen,
    closeAdvertiserAudienceQualityDrawer,
  } = useLookalikeAdvertiserAudienceGenerator();

  return (
    <DqSidePanel
      PaperProps={{ sx: { width: 650 } }}
      anchor="right"
      hideBackdrop={false}
      onClose={closeAdvertiserAudienceQualityDrawer}
      open={isAdvertiserAudienceQualityDrawerOpen}
    >
      <DqSidePanelTitle
        onClose={closeAdvertiserAudienceQualityDrawer}
        separated={true}
      >
        Recall
      </DqSidePanelTitle>
      <DqSidePanelContent>
        <DqCard sx={{ height: "fit-content" }}>
          <DqCardContent>
            <PositiveCurveScoreBar withChartButton={false} />
          </DqCardContent>
        </DqCard>
        <PositiveCurveScoreChart />
      </DqSidePanelContent>
    </DqSidePanel>
  );
};

AudienceQualityStatisticsDrawer.displayName = "AudienceQualityStatisticsDrawer";

export default memo(AudienceQualityStatisticsDrawer);
