import { DqLoader } from "@decentriq/components";
import { Stack } from "@mui/joy";
import { memo } from "react";
import { DatasetSchema as DatasetSchemaComponent } from "features/datasets";
import {
  type DatasetSchema,
  mapSchemaToUnifiedSchemaPresentation,
} from "utils/dataset";

interface DatasetSchemaTabPanelProps {
  schema: DatasetSchema;
  loading: boolean;
}

const DatasetSchemaTabPanel: React.FC<DatasetSchemaTabPanelProps> = memo(
  ({ schema, loading }) =>
    loading ? (
      <Stack alignItems="center" padding={3}>
        <DqLoader />
      </Stack>
    ) : (
      <DatasetSchemaComponent
        schema={mapSchemaToUnifiedSchemaPresentation(schema)}
      />
    )
);

DatasetSchemaTabPanel.displayName = "DatasetSchemaTabPanel";

export default DatasetSchemaTabPanel;
