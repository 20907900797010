import {
  useDataConnectorJobQuery,
  useDataConnectorJobResultQuery,
} from "@decentriq/graphql/dist/hooks";
import {
  type DataConnectorJobResult,
  DataImportExportStatus,
} from "@decentriq/graphql/dist/types";

type DatasetImportResultHookReturn = {
  status: DataImportExportStatus | undefined;
  result: DataConnectorJobResult | undefined | null;
};

export const useDatasetImportResult = (
  datasetImportId: string
): DatasetImportResultHookReturn => {
  const { data: datasetImportData } = useDataConnectorJobQuery({
    skip: !datasetImportId,
    variables: { id: datasetImportId as string },
  });
  const {
    dataConnectorJob: {
      status: datasetImportStatus = DataImportExportStatus.Pending,
    } = {},
  } = datasetImportData || {};
  const { data } = useDataConnectorJobResultQuery({
    variables: { id: datasetImportId },
  });
  const result = data?.dataConnectorJob?.result;
  return {
    result,
    status: datasetImportStatus,
  };
};
