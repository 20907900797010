import { InfoTooltip } from "@decentriq/components";
import { Box, Checkbox } from "@mui/joy";
import { memo } from "react";
import { useLookalikeAdvertiserAudienceGenerator } from "../../contexts/LookalikeAdvertiserAudienceGeneratorContext";

const ExcludeSeedAudienceCheckbox: React.FC = () => {
  const { setExcludeSeedAudience, excludeSeedAudience } =
    useLookalikeAdvertiserAudienceGenerator();
  return (
    <Box width="fit-content">
      <Checkbox
        checked={excludeSeedAudience}
        // TODO: Add disabled state if selected audience has no seed audience
        disabled={false}
        label="Exclude seed audience from your new audience"
        onChange={(event) => setExcludeSeedAudience(event.target.checked)}
        sx={{ alignItems: "center", ml: 0, width: "fit-content" }}
      />
      <InfoTooltip tooltip="Checking this box will remove any individuals in your data from the generated audience. For privacy reasons, all audiences shared with a publisher must be of the same type (excluded / included)." />
    </Box>
  );
};

ExcludeSeedAudienceCheckbox.displayName = "ExcludeSeedAudienceCheckbox";

export default memo(ExcludeSeedAudienceCheckbox);
