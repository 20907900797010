import { type ApolloClient, type NormalizedCacheObject } from "@apollo/client";
import { type Client, proto, type Session } from "@decentriq/core";
import {
  CompleteDraftDataRoomDocument,
  type CompleteDraftDataRoomQuery,
  type CompleteDraftDataRoomQueryVariables,
} from "@decentriq/graphql/dist/types";
import {
  buildDataScienceDataRoom,
  getLatestEnclaveSpecsPerType,
} from "utils/apicore";

const buildAndPublishDraftDataRoom = async (
  draftDataRoomId: string,
  client: ApolloClient<NormalizedCacheObject>,
  sdkClient: Client,
  sdkSession: Session,
  isDraft: boolean,
  dcrSecretIdBase64?: string
): Promise<string> => {
  // Get the complete draft data room so we can translate it to a data science data room
  const completeDraftDataRoom = await client.query<
    CompleteDraftDataRoomQuery,
    CompleteDraftDataRoomQueryVariables
  >({
    query: CompleteDraftDataRoomDocument,
    variables: {
      id: draftDataRoomId,
    },
  });
  // Get the latest enclave specs and decentriq pki root certificate
  const rootCertificatePem = await sdkClient.decentriqCaRootCertificate();
  const enclaveSpecifications = await sdkClient.getEnclaveSpecifications();
  const latestEnclaveSpecifications = getLatestEnclaveSpecsPerType(
    enclaveSpecifications
  );
  // Translate the data science data room and publish it
  const dataScienceDataRoom = buildDataScienceDataRoom(
    completeDraftDataRoom.data.draftDataRoom,
    rootCertificatePem,
    latestEnclaveSpecifications,
    isDraft,
    dcrSecretIdBase64
  );
  const id = await sdkSession.publishDataScienceDataRoom(dataScienceDataRoom, {
    dataRoomPurpose: isDraft
      ? proto.metering.CreateDcrPurpose.VALIDATION
      : proto.metering.CreateDcrPurpose.STANDARD,
    requirePassword: !!dcrSecretIdBase64,
    showOrganizationLogo:
      completeDraftDataRoom.data.draftDataRoom.showOrganizationLogo,
  });
  return id;
};

export default buildAndPublishDraftDataRoom;
