import { type MatchingIdFormat } from "@decentriq/core/dist/data_lab";
import {
  ColumnDataType,
  type DataLab as GqDataLab,
  MatchingColumnFormat,
  TableColumnFormatType,
  type TableColumnHashingAlgorithm,
} from "@decentriq/graphql/dist/types";
import { type DataRoomTableColumn } from "models";

export interface DataLabDataset {
  id: string;
  name: string;
  manifestHash: string;
}

export type DataLab = Omit<
  GqDataLab,
  | "owner"
  | "datasets"
  | "demographicsDataset"
  | "embeddingsDataset"
  | "segmentsDataset"
  | "usersDataset"
> & {
  demographicsDataset?: DataLabDataset | null | undefined;
  embeddingsDataset?: DataLabDataset | null | undefined;
  segmentsDataset?: DataLabDataset | null | undefined;
  usersDataset?: DataLabDataset | null | undefined;
};

export enum DataLabDataNodeType {
  matching = "Matching",
  segments = "Segments",
  demographics = "Demographics",
  embeddings = "Embeddings",
}

export const matchingColumnFormatToTableColumnFormatType = new Map<
  MatchingColumnFormat,
  TableColumnFormatType
>([
  [MatchingColumnFormat.Email, TableColumnFormatType.Email],
  [MatchingColumnFormat.HashSha256Hex, TableColumnFormatType.HashSha256Hex],
  [MatchingColumnFormat.PhoneNumberE164, TableColumnFormatType.PhoneNumberE164],
  [MatchingColumnFormat.String, TableColumnFormatType.String],
]);

const matchingTableId = "matching_leaf";
const segmentsTableId = "segments_leaf";
const demographicsTableId = "demographics_leaf";
const embeddingsTableId = "embeddings_leaf";

const userIdColumnId = "userId";
const matchingIdColumnId = "matchingId";
const segmentColumnId = "segment";
const scopeColumnId = "scope";

export const dataLabNodeColumnUniqueness: Map<DataLabDataNodeType, string[][]> =
  new Map([
    [DataLabDataNodeType.matching, [[userIdColumnId, matchingIdColumnId]]],
    [DataLabDataNodeType.segments, [[userIdColumnId, segmentColumnId]]],
    [DataLabDataNodeType.demographics, [[userIdColumnId]]],
    [DataLabDataNodeType.embeddings, [[userIdColumnId, scopeColumnId]]],
  ]);

export const getDataLabDataNodeColumnsMap = ({
  matchingIdFormat,
  matchingIdHashingAlgorithm,
  numEmbeddings,
}: {
  matchingIdFormat: MatchingColumnFormat;
  matchingIdHashingAlgorithm: TableColumnHashingAlgorithm | undefined | null;
  numEmbeddings: number;
}): Map<DataLabDataNodeType, DataRoomTableColumn[]> => {
  return new Map<DataLabDataNodeType, DataRoomTableColumn[]>([
    [
      DataLabDataNodeType.matching,
      [
        {
          formatType: TableColumnFormatType.String,
          id: userIdColumnId,
          name: userIdColumnId,
          nullable: false,
          primitiveType: ColumnDataType.Text,
          tableNodeId: matchingTableId,
        },
        {
          formatType:
            matchingColumnFormatToTableColumnFormatType.get(matchingIdFormat),
          hashWith: matchingIdHashingAlgorithm ?? undefined,
          id: matchingIdColumnId,
          name: matchingIdColumnId,
          nullable: false,
          primitiveType: ColumnDataType.Text,
          tableNodeId: matchingTableId,
        },
      ],
    ],
    [
      DataLabDataNodeType.segments,
      [
        {
          formatType: TableColumnFormatType.String,
          id: userIdColumnId,
          name: userIdColumnId,
          nullable: false,
          primitiveType: ColumnDataType.Text,
          tableNodeId: segmentsTableId,
        },
        {
          formatType: TableColumnFormatType.String,
          id: segmentColumnId,
          name: segmentColumnId,
          nullable: false,
          primitiveType: ColumnDataType.Text,
          tableNodeId: segmentsTableId,
        },
      ],
    ],
    [
      DataLabDataNodeType.demographics,
      [
        {
          formatType: TableColumnFormatType.String,
          id: userIdColumnId,
          name: userIdColumnId,
          nullable: false,
          primitiveType: ColumnDataType.Text,
          tableNodeId: demographicsTableId,
        },
        {
          formatType: TableColumnFormatType.String,
          id: "age",
          name: "age",
          nullable: true,
          primitiveType: ColumnDataType.Text,
          tableNodeId: demographicsTableId,
        },
        {
          formatType: TableColumnFormatType.String,
          id: "gender",
          name: "gender",
          nullable: true,
          primitiveType: ColumnDataType.Text,
          tableNodeId: demographicsTableId,
        },
      ],
    ],
    [
      DataLabDataNodeType.embeddings,
      [
        {
          formatType: TableColumnFormatType.String,
          id: userIdColumnId,
          name: userIdColumnId,
          nullable: false,
          primitiveType: ColumnDataType.Text,
          tableNodeId: embeddingsTableId,
        },
        {
          formatType: TableColumnFormatType.String,
          id: scopeColumnId,
          name: scopeColumnId,
          nullable: true,
          primitiveType: ColumnDataType.Text,
          tableNodeId: embeddingsTableId,
        },
        ...(numEmbeddings
          ? Array.from({ length: numEmbeddings }, (_, index) => ({
              formatType: TableColumnFormatType.String,
              id: `embeddingId_${index}`,
              name: `e${index}`,
              nullable: false,
              primitiveType: ColumnDataType.Float,
              tableNodeId: embeddingsTableId,
            }))
          : []),
      ],
    ],
  ]);
};

export const convertMatchingIdFormat = (
  matchingIdFormat?: MatchingColumnFormat
): MatchingIdFormat => {
  switch (matchingIdFormat) {
    case undefined:
      throw new Error("Matching ID format is undefined");
    case MatchingColumnFormat.Email:
      return "EMAIL";
    case MatchingColumnFormat.HashSha256Hex:
      return "HASH_SHA256_HEX";
    case MatchingColumnFormat.PhoneNumberE164:
      return "PHONE_NUMBER_E164";
    case MatchingColumnFormat.String:
      return "STRING";
  }
};

export const matchingIdTypeAndHashingAlgorithmPresentation = ({
  matchingIdFormat,
  matchingIdHashingAlgorithm,
}: {
  matchingIdFormat: MatchingColumnFormat;
  matchingIdHashingAlgorithm: TableColumnHashingAlgorithm | undefined | null;
}): string => {
  const hashed = Boolean(matchingIdHashingAlgorithm);
  switch (matchingIdFormat) {
    case MatchingColumnFormat.String: {
      return "String";
    }
    case MatchingColumnFormat.Email: {
      return hashed ? "SHA256 Hashed Email" : "Email";
    }
    case MatchingColumnFormat.PhoneNumberE164: {
      return hashed ? "SHA256 Hashed Phone (E.164)" : "Phone number (E.164)";
    }
    default:
      return "";
  }
};
