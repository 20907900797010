import {
  useDraftDataRoomOverviewQuery,
  usePublishedDataRoomOverviewQuery,
  usePublishedLookalikeMediaDataRoomOverviewQuery,
  usePublishedMediaDataRoomOverviewQuery,
  usePublishedMediaInsightsDcrOverviewQuery,
} from "@decentriq/graphql/dist/hooks";
import {
  type DraftDataRoomOverviewQuery,
  type PublishedDataRoomOverviewQuery,
  type PublishedLookalikeMediaDataRoomOverviewQuery,
  type PublishedMediaDataRoomOverviewQuery,
  type PublishedMediaInsightsDcrOverviewQuery,
} from "@decentriq/graphql/dist/types";
import { get } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { DataRoomTypeNames } from "models";

type DataRoomOverview = {
  collaboratingOrganizationNames: string[];
  isArchived: boolean;
  isFavorite: boolean;
  isStopped: boolean;
  participants: { id: string; userEmail: string }[];
  name: string;
  id: string;
  createdAt: string;
  owner: { email: string } | null;
  driverAttestationHash?: string;
  activatedAt: string | null;
};

type DataRoomOverviewData = {
  data: DataRoomOverview | null;
  isLoading: boolean;
};

const useDataRoomOverviewData = (
  dataRoomId: string,
  typename: DataRoomTypeNames
): DataRoomOverviewData => {
  const [targetDataRoomData, setTargetDataRoomData] = useState<
    | DraftDataRoomOverviewQuery["draftDataRoom"]
    | PublishedDataRoomOverviewQuery["publishedDataRoom"]
    | PublishedMediaDataRoomOverviewQuery["publishedMediaDataRoom"]
    | PublishedLookalikeMediaDataRoomOverviewQuery["publishedLookalikeMediaDataRoom"]
    | PublishedMediaInsightsDcrOverviewQuery["publishedMediaInsightsDcr"]
    | null
  >(null);
  const [isTargetDataRoomDataLoading, setIsTargetDataRoomDataLoading] =
    useState<boolean>(false);
  // Draft Data Room
  const {
    data: draftDataRoomOverviewData,
    loading: isDraftDataRoomOverviewLoading,
  } = useDraftDataRoomOverviewQuery({
    skip: !dataRoomId || typename !== DataRoomTypeNames.DraftDataRoom,
    variables: { dataRoomId },
  });
  // Published Data Room
  const {
    data: publishedDataRoomOverviewData,
    loading: isPublishedDataRoomOverviewLoading,
  } = usePublishedDataRoomOverviewQuery({
    skip: !dataRoomId || typename !== DataRoomTypeNames.PublishedDataRoom,
    variables: { dataRoomId },
  });
  // Published Media Data Room
  const {
    data: publishedMediaDataRoomOverviewData,
    loading: isPublishedMediaDataRoomOverviewLoading,
  } = usePublishedMediaDataRoomOverviewQuery({
    skip: !dataRoomId || typename !== DataRoomTypeNames.PublishedMediaDataRoom,
    variables: { dataRoomId },
  });
  // Published Lookalike Media Data Room
  const {
    data: publishedLookalikeMediaDataRoomOverviewData,
    loading: isPublishedLookalikeMediaDataRoomOverviewLoading,
  } = usePublishedLookalikeMediaDataRoomOverviewQuery({
    skip:
      !dataRoomId ||
      typename !== DataRoomTypeNames.PublishedLookalikeMediaDataRoom,
    variables: { dataRoomId },
  });

  // Published Media Insights Data Room
  const {
    data: publishedMediaInsightsDcrOverviewData,
    loading: isPublishedMediaInsightsDcrOverviewLoading,
  } = usePublishedMediaInsightsDcrOverviewQuery({
    skip:
      !dataRoomId || typename !== DataRoomTypeNames.PublishedMediaInsightsDcr,
    variables: { dataRoomId },
  });

  useEffect(() => {
    setTargetDataRoomData(
      draftDataRoomOverviewData?.draftDataRoom ||
        publishedDataRoomOverviewData?.publishedDataRoom ||
        publishedMediaDataRoomOverviewData?.publishedMediaDataRoom ||
        publishedLookalikeMediaDataRoomOverviewData?.publishedLookalikeMediaDataRoom ||
        publishedMediaInsightsDcrOverviewData?.publishedMediaInsightsDcr ||
        null
    );
    setIsTargetDataRoomDataLoading(
      isDraftDataRoomOverviewLoading ||
        isPublishedDataRoomOverviewLoading ||
        isPublishedMediaDataRoomOverviewLoading ||
        isPublishedLookalikeMediaDataRoomOverviewLoading ||
        isPublishedMediaInsightsDcrOverviewLoading
    );
  }, [
    draftDataRoomOverviewData,
    isDraftDataRoomOverviewLoading,
    isPublishedDataRoomOverviewLoading,
    isPublishedLookalikeMediaDataRoomOverviewLoading,
    isPublishedMediaDataRoomOverviewLoading,
    isPublishedMediaInsightsDcrOverviewLoading,
    publishedDataRoomOverviewData,
    publishedLookalikeMediaDataRoomOverviewData,
    publishedMediaDataRoomOverviewData,
    publishedMediaInsightsDcrOverviewData,
  ]);

  const dataRoomOverview = useMemo<DataRoomOverview | null>(() => {
    if (targetDataRoomData === null) {
      return null;
    }
    const userSettings = targetDataRoomData?.userSettings || {};
    const participantUsers = targetDataRoomData.participantUsers.nodes;
    const isStopped =
      "isStopped" in targetDataRoomData ? targetDataRoomData.isStopped : false;
    const deactivatedAt =
      "deactivatedAt" in targetDataRoomData
        ? targetDataRoomData.deactivatedAt
        : null;
    const activatedAt =
      "activatedAt" in targetDataRoomData
        ? targetDataRoomData.activatedAt
        : null;
    const owner = targetDataRoomData.owner ?? null;

    const collaboratingOrganizationNames = Array.from(
      participantUsers.reduce((orgNames, participantUser) => {
        if ("organization" in participantUser && participantUser.organization) {
          orgNames.add(participantUser.organization.name);
        }
        return orgNames;
      }, new Set<string>())
    );

    return {
      ...targetDataRoomData,
      activatedAt,
      collaboratingOrganizationNames,
      isArchived: get(userSettings, "isArchived", false),
      isFavorite: get(userSettings, "isFavorite", false),
      isStopped: isStopped || !!deactivatedAt,
      owner,
      participants: participantUsers,
    };
  }, [targetDataRoomData]);

  return { data: dataRoomOverview, isLoading: isTargetDataRoomDataLoading };
};

export default useDataRoomOverviewData;
