import { useBoolean } from "ahooks";
import { memo, useCallback } from "react";
import DatasetDeletionDialog from "./components/DatasetDeleteDialog/DatasetDeleteDialog";
import useDeleteDataset from "./hooks/useDatasetDelete";
import { DatasetDeletionMode } from "./models";

interface DatasetDeleteProps {
  manifestHash: string;
  onComplete?: () => void;
  renderTrigger: (openDialog: () => void) => React.ReactNode;
  testing?: {
    dataNodeId: string;
  };
}

const DatasetDelete = memo<DatasetDeleteProps>(
  ({ manifestHash, onComplete, testing, renderTrigger }) => {
    const [isDialogOpen, { setTrue: openDialog, setFalse: closeDialog }] =
      useBoolean(false);
    const [deleteDataset, isDeletionLoading] = useDeleteDataset({
      manifestHash,
      testing,
    });
    const handleDatasetDeletion = useCallback(async () => {
      const deleted = await deleteDataset();
      if (deleted && onComplete) {
        onComplete();
      }
    }, [deleteDataset, onComplete]);
    return (
      <>
        {renderTrigger(openDialog)}
        <DatasetDeletionDialog
          dataType={"table"}
          loading={isDeletionLoading}
          mode={DatasetDeletionMode.delete}
          onCancel={closeDialog}
          onConfirm={handleDatasetDeletion}
          open={isDialogOpen}
        />
      </>
    );
  }
);

DatasetDelete.displayName = "DeleteDataset";

export default DatasetDelete;
