import { styled, Tab, TabList, TabPanel, Tabs } from "@mui/joy";
import { memo, useCallback, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import {
  type InsightsBasicViewFormValues,
  MediaDataRoomInsightsBasicView,
  MediaDataRoomInsightsComparison,
  MediaDataRoomInsightsDetailedView,
  MediaDataRoomInsightsExplorer,
  useMediaDataRoomInsightsData,
} from "features/mediaDataRoom";
import { useMediaDataRoomOverlapInsights } from "features/mediaDataRoom/contexts";

enum InsightsTabs {
  Basic = "Basic",
  Detailed = "Detailed",
  Comparison = "Comparison",
  Explorer = "Explorer",
}

const MediaDataRoomInsightsTabPanel = styled(TabPanel)(
  ({ isActive }: { isActive: boolean }) => ({
    // MUI was changing the display property for hidden items explicitly, not depending on what is set from sx prop
    // Joy, on the contrary does not do this, so for the hidden items empty "flex" block with margins and paddings is shown
    // Therefore, this hack is applied in order to properly show and hide TabPanels
    display: isActive ? "flex" : "none",
    flexDirection: "column",
    height: "100%",
  })
);

const MediaDataRoomInsightsTabs = memo(() => {
  const [activeTab, setActiveTab] = useState<InsightsTabs>(InsightsTabs.Basic);
  const handleTabChange = useCallback(
    (
      event: React.SyntheticEvent<Element, Event> | null,
      newValue: string | number | null
    ) => setActiveTab(newValue as InsightsTabs),
    [setActiveTab]
  );
  const { audienceType } = useMediaDataRoomOverlapInsights();
  const form = useForm<InsightsBasicViewFormValues>({
    defaultValues: {
      audienceType: audienceType || "",
      genderChartValueKey: "shareInOverlap",
    },
    mode: "onChange",
  });
  const {
    publishedDatasetsHashes: {
      publisherDatasetsHashes: { demographicsDatasetHash },
    },
  } = useMediaDataRoomInsightsData();
  return (
    <Tabs onChange={handleTabChange} sx={{ height: "100%" }} value={activeTab}>
      <TabList>
        <Tab value={InsightsTabs.Basic}>Basic</Tab>
        {demographicsDatasetHash && (
          <Tab value={InsightsTabs.Detailed}>Detailed</Tab>
        )}
        <Tab value={InsightsTabs.Comparison}>Comparison</Tab>
        <Tab value={InsightsTabs.Explorer}>Explorer</Tab>
      </TabList>
      <MediaDataRoomInsightsTabPanel
        isActive={activeTab === InsightsTabs.Basic}
        value={InsightsTabs.Basic}
      >
        {/* This is temp fix, form provider should be inside of the basic view. Issue is in audienceType being not updated inside overlap statistics */}
        <FormProvider {...form}>
          <MediaDataRoomInsightsBasicView />
        </FormProvider>
      </MediaDataRoomInsightsTabPanel>
      {demographicsDatasetHash && (
        <MediaDataRoomInsightsTabPanel
          isActive={activeTab === InsightsTabs.Detailed}
          value={InsightsTabs.Detailed}
        >
          <MediaDataRoomInsightsDetailedView />
        </MediaDataRoomInsightsTabPanel>
      )}
      <MediaDataRoomInsightsTabPanel
        isActive={activeTab === InsightsTabs.Comparison}
        value={InsightsTabs.Comparison}
      >
        <MediaDataRoomInsightsComparison />
      </MediaDataRoomInsightsTabPanel>
      <MediaDataRoomInsightsTabPanel
        isActive={activeTab === InsightsTabs.Explorer}
        value={InsightsTabs.Explorer}
      >
        <MediaDataRoomInsightsExplorer />
      </MediaDataRoomInsightsTabPanel>
    </Tabs>
  );
});

MediaDataRoomInsightsTabs.displayName = "MediaDataRoomInsightsTabs";

export default MediaDataRoomInsightsTabs;
