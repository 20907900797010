import { type DqDatasetExportFormProviderSchemaType } from "@decentriq/components";
import { useCreateDatasetExportMutation } from "@decentriq/graphql/dist/hooks";
import { useBoolean } from "ahooks";
import { Fragment, memo, useCallback } from "react";
import { mapDraftDataRoomErrorToSnackbar, useDataRoomSnackbar } from "hooks";
import DatasetExportDialog from "./components/DatasetExportDialog/DatasetExportDialog";
import {
  DatasetExportProvider,
  useDatasetExport,
} from "./contexts/DatasetExportContext/DatasetExportContext";

interface DatasetExportProps {
  manifestHash: string;
  renderTrigger: (open: () => void) => React.ReactNode;
}

const DatasetExport = memo<DatasetExportProps>(
  ({ manifestHash, renderTrigger }) => {
    const [isDialogOpen, { setTrue: openDialog, setFalse: closeDialog }] =
      useBoolean(false);
    const { enqueueSnackbar } = useDataRoomSnackbar();
    const { setDatasetExportId, setIsExportCreated, setDatasetExportError } =
      useDatasetExport();
    const [createDatasetExportMutation] = useCreateDatasetExportMutation();
    const onSubmit = useCallback(
      async (
        createDatasetExportMutationVariables: DqDatasetExportFormProviderSchemaType
      ) => {
        await createDatasetExportMutation({
          onCompleted: ({ createDatasetExport }) => {
            setDatasetExportId(createDatasetExport?.dataConnectorJob?.id);
            setIsExportCreated(true);
          },
          onError: (error) => {
            setDatasetExportError(error?.message);
            enqueueSnackbar(
              ...mapDraftDataRoomErrorToSnackbar(
                error,
                "The exporting process could not get started. Please try again by refreshing the page."
              )
            );
          },
          variables: {
            input: {
              ...createDatasetExportMutationVariables,
              manifestHash,
            },
          },
        });
      },
      [
        createDatasetExportMutation,
        manifestHash,
        enqueueSnackbar,
        setDatasetExportError,
        setDatasetExportId,
        setIsExportCreated,
      ]
    );
    return (
      <Fragment>
        {renderTrigger(openDialog)}
        <DatasetExportDialog
          manifestHash={manifestHash}
          onClose={closeDialog}
          onSubmit={onSubmit}
          open={isDialogOpen}
        />
      </Fragment>
    );
  }
);

const DatasetExportWithProvider = memo<DatasetExportProps>((props) => (
  <DatasetExportProvider manifestHash={props.manifestHash}>
    <DatasetExport {...props} />
  </DatasetExportProvider>
));

DatasetExportWithProvider.displayName = "DatasetExport";

export default DatasetExportWithProvider;
