import {
  type IconProp,
  type SizeProp,
} from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { memo } from "react";
import { isElement, isValidElementType } from "react-is";

type SidebarMenuItemIconProps = {
  icon?: React.ReactNode | IconProp;
  iconSize?: SizeProp;
};

const SidebarMenuItemIcon: React.FC<SidebarMenuItemIconProps> = ({
  icon: Icon,
  iconSize,
}) => {
  return isElement(Icon) || typeof Icon === "string" ? (
    Icon
  ) : isValidElementType(Icon as React.ElementType) ? (
    <Icon />
  ) : (
    <FontAwesomeIcon
      fixedWidth={true}
      icon={Icon as IconProp}
      size={iconSize}
    />
  );
};

SidebarMenuItemIcon.displayName = "SidebarMenuItemIcon";

export default memo(SidebarMenuItemIcon);
