import {
  ColumnDataType,
  type PublishedDataset,
  TableColumnFormatType,
  type TableColumnHashingAlgorithm,
} from "@decentriq/graphql/dist/types";
import { type Dataset } from "features/datasets/models";

export interface DataRoomDataNodeBaseValidationConfig {
  tableSchema?: {
    namedColumns: DataRoomTableColumnBase[];
  };
  unstructured?: boolean;
  computeNodeId?: string;
}

export type DataRoomTableBaseValidationConfig = Required<
  Pick<DataRoomDataNodeBaseValidationConfig, "tableSchema">
>;
export type DataRoomFileBaseValidationConfig = Required<
  Pick<DataRoomDataNodeBaseValidationConfig, "unstructured">
>;

export interface DataRoomDataNodeBase<
  V = DataRoomDataNodeBaseValidationConfig,
> {
  id: string;
  dataRoomId: string;
  computeNodeId: string;
  computeNodeType: string;
  nodeName: string;
  index: number;
  isSaving: boolean;
  config: {
    config: {
      validation: V;
      datasetConstraintsEnabled?: boolean;
    };
  };
}

export type DataRoomTableBase =
  DataRoomDataNodeBase<DataRoomTableBaseValidationConfig>;
export type DataRoomFileBase =
  DataRoomDataNodeBase<DataRoomFileBaseValidationConfig>;

export interface DataRoomTableColumnBase {
  name: string;
  columnType: {
    primitiveType: number;
    nullable: boolean;
  };
}
export interface DataRoomTableColumnDefinition {
  name: string;
  primitiveType: ColumnDataType | undefined;
  nullable: boolean;
  formatType?: TableColumnFormatType;
  hashWith?: TableColumnHashingAlgorithm;
}

export interface DataRoomTableColumn extends DataRoomTableColumnDefinition {
  id: string;
  tableNodeId: string;
  default?: string;
}

export const dataRoomTablePrimitiveTypePresentation = new Map<
  ColumnDataType,
  string
>([
  [ColumnDataType.Int, "Number (whole)"],
  [ColumnDataType.Text, "String"],
  [ColumnDataType.Float, "Number (decimal)"],
]);

export const legacyDataRoomTablePrimitiveTypeToColumnDataType = new Map<
  number,
  ColumnDataType
>([
  [0, ColumnDataType.Int],
  [1, ColumnDataType.Text],
  [2, ColumnDataType.Float],
]);

export type DataType = "table" | "unstructured";

export type DataIngestionSource = "local" | "keychain";

export type DataIngestionDestination = "dataRoom" | "dataLab";

export interface DataRoomDataDefinition {
  name: string;
  dataType: DataType;
}
export interface DataRoomData extends DataRoomDataDefinition {
  id: string;
  participants: DataRoomDataNodeParticipant[];
  isRequired: boolean;
  hasPermission?: boolean;
  dataset?: PublishedDataset;
  testDataset?: PublishedDataset;
}

export interface DataRoomTableDefinition extends DataRoomDataDefinition {
  columns: DataRoomTableColumn[];
  columnsOrder: string[];
  uniqueColumnIds?: string[][];
  dataset?: PublishedDataset;
}
export interface DataRoomFileDefinition extends DataRoomDataDefinition {
  dataset?: PublishedDataset;
}

export interface DataRoomDataTable
  extends DataRoomData,
    DataRoomTableDefinition {}

export interface DataRoomDataFile
  extends DataRoomData,
    DataRoomFileDefinition {}

export interface DataRoomDataNodeParticipant {
  userEmail: string;
  uploadedAt?: string;
  datasetLinkId?: string;
  dataset?: Dataset;
}

export const castPrimitiveTypeToFormatType = (
  primitiveType?: ColumnDataType
) => {
  switch (primitiveType) {
    case ColumnDataType.Int:
      return TableColumnFormatType.Integer;
    case ColumnDataType.Float:
      return TableColumnFormatType.Float;
    case ColumnDataType.Text:
      return TableColumnFormatType.String;
    default:
      return undefined;
  }
};

export const castFormatTypeToPrimitiveType = (
  formatType?: TableColumnFormatType
) => {
  switch (formatType) {
    case TableColumnFormatType.Integer:
      return ColumnDataType.Int;
    case TableColumnFormatType.Float:
      return ColumnDataType.Float;
    case TableColumnFormatType.DateIso8601:
    case TableColumnFormatType.Email:
    case TableColumnFormatType.HashSha256Hex:
    case TableColumnFormatType.String:
      return ColumnDataType.Text;
    default:
      return ColumnDataType.Text;
  }
};
