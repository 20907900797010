// TODO: Fix joy migration
import { useArchiveOrganizationMutation } from "@decentriq/graphql/dist/hooks";
import { OrganizationFragment } from "@decentriq/graphql/dist/types";
import { faExclamationTriangle } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Modal,
  ModalDialog,
  Typography,
} from "@mui/joy";
import { memo } from "react";
import { useNavigate } from "react-router-dom";
import { CommonSnackbarOrigin, useGeneralSnackbar } from "hooks";

interface OrganizationArchiveDialogProps {
  open: boolean;
  organizationId: string;
  onCancel: () => void;
}

const OrganizationArchiveDialog: React.FC<OrganizationArchiveDialogProps> = ({
  open,
  organizationId,
  onCancel,
}) => {
  const { enqueueSnackbar } = useGeneralSnackbar({
    origin: CommonSnackbarOrigin.ADMIN,
  });
  const navigate = useNavigate();

  const [archiveOrganization, { loading: isArchiveOrganizationLoading }] =
    useArchiveOrganizationMutation({
      onCompleted: () => {
        onCancel();
        enqueueSnackbar(`Organization has been successfully archived.`);
        navigate("/admin/organizations");
      },
      onError: (error) => {
        onCancel();
        enqueueSnackbar(`Organization could not be archived.`, {
          context: error?.message,
          persist: true,
          variant: "error",
        });
      },
      update: (cache, { data }) => {
        cache.writeFragment({
          data: data?.organization?.archive?.record,
          fragment: OrganizationFragment,
          id: cache.identify({
            __typename: "Organization",
            id: organizationId,
          }),
        });
      },
      variables: {
        organizationId,
      },
    });

  return (
    <Modal onClose={onCancel} open={open}>
      <ModalDialog role="alertdialog">
        <DialogTitle>
          <Box sx={{ mb: 1, textAlign: "center" }}>
            <FontAwesomeIcon
              fixedWidth={true}
              icon={faExclamationTriangle}
              size="4x"
            />
          </Box>
          <Typography
            align="center"
            level="title-md"
            sx={{ textWrap: "balance" }}
          >
            <strong>Are you sure you want to archive this organization?</strong>
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Typography
            align="center"
            level="body-sm"
            sx={{ textWrap: "balance" }}
          >
            This is an irreversible action. This action will remove all users
            within this organisation. This action will remove all data uploaded
            by any user of this organisation. This action will stop all data
            clean rooms created by users of this organisation, rendering them
            unusable for all participants therein.
          </Typography>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button disabled={isArchiveOrganizationLoading} onClick={onCancel}>
            Cancel
          </Button>
          <Button
            loading={isArchiveOrganizationLoading}
            loadingPosition="start"
            onClick={archiveOrganization}
            startDecorator={
              <FontAwesomeIcon fixedWidth={true} icon={faExclamationTriangle} />
            }
            sx={{ color: "error.main" }}
            variant="solid"
          >
            Archive organization
          </Button>
        </DialogActions>
      </ModalDialog>
    </Modal>
  );
};

OrganizationArchiveDialog.displayName = "OrganizationArchiveDialog";

export default memo(OrganizationArchiveDialog);
