import { Grid, Typography } from "@mui/joy";
import { memo } from "react";
import useGoogleCloudStorageConnectionConfigurationData from "./useGoogleCloudStorageConfigurationData";

type GoogleCloudStorageConnectionConfigurationProps = {
  connectionId: string;
};

const GoogleCloudStorageConnectionConfiguration: React.FC<
  GoogleCloudStorageConnectionConfigurationProps
> = ({ connectionId }) => {
  const { bucketName, objectName } =
    useGoogleCloudStorageConnectionConfigurationData(connectionId);

  return (
    <Grid container={true} direction="column" xs={12}>
      <Typography level="body-sm">
        <strong>Bucket name:</strong> {bucketName}
      </Typography>
      <Typography level="body-sm">
        <strong>Object name:</strong> {objectName}
      </Typography>
    </Grid>
  );
};

GoogleCloudStorageConnectionConfiguration.displayName =
  "GoogleCloudStorageConnectionConfiguration";

export default memo(GoogleCloudStorageConnectionConfiguration);
