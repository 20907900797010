import { faTrashCan } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton } from "@mui/joy";
import { useBoolean } from "ahooks";
import { Fragment, memo } from "react";
import { type KeychainItemKind } from "services/keychain";
import { DeleteKeychainItemDialog } from "..";

type DeleteKeychainItemButtonProps = {
  id: string;
  kind: KeychainItemKind;
};

const DeleteKeychainItemButton: React.FC<DeleteKeychainItemButtonProps> = ({
  id,
  kind,
}) => {
  const [
    isKeychainItemDeleteDialogOpen,
    {
      setTrue: openKeychainItemDeleteDialog,
      setFalse: closeKeychainItemDeleteDialog,
    },
  ] = useBoolean(false);
  return (
    <Fragment>
      <IconButton color="danger" onClick={openKeychainItemDeleteDialog}>
        <FontAwesomeIcon fixedWidth={true} icon={faTrashCan} />
      </IconButton>
      <DeleteKeychainItemDialog
        id={id}
        kind={kind}
        onCancel={closeKeychainItemDeleteDialog}
        open={isKeychainItemDeleteDialogOpen}
      />
    </Fragment>
  );
};
DeleteKeychainItemButton.displayName = "DeleteKeychainItem";

export default memo(DeleteKeychainItemButton);
