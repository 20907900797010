import { CircularProgress, Stack } from "@mui/joy";
import { Backdrop, type Theme } from "@mui/material";
import React, { Fragment, memo } from "react";
import { Navigate } from "react-router-dom";
import { makeStyles } from "tss-react/mui";
import {
  DataLabErrorReport,
  DataLabNodes,
  DataLabQualityStatistics,
  DataLabToolbar,
} from "features/dataLabs";
import { DataLabWrapper, useDataLabContext } from "features/dataLabs/contexts";

const useBackdropStyles = makeStyles()((theme: Theme) => ({
  root: {
    zIndex: theme.zIndex.tooltip + 1,
  },
}));

interface DataLabProps {
  id: string;
}

const DataLab: React.FC<DataLabProps> = ({ id }) => {
  const { classes: backdropClasses } = useBackdropStyles();
  const {
    dataLab: { data, loading },
  } = useDataLabContext();
  if (loading) {
    return (
      <Backdrop classes={backdropClasses} invisible={true} open={loading}>
        <CircularProgress sx={{ "--CircularProgress-size": "2.5rem" }} />
      </Backdrop>
    );
  }
  if (data) {
    return (
      <Fragment>
        <DataLabToolbar id={id} />
        <Stack direction="column" sx={{ flex: 1, overflow: "auto", p: 2 }}>
          <DataLabNodes />
          <DataLabQualityStatistics />
        </Stack>
      </Fragment>
    );
  }
  return <Navigate replace={true} to="/publisherportal/datalabs" />;
};

const DataLabPageWrapper: React.FC<DataLabProps> = memo(({ id }) => {
  return (
    <DataLabErrorReport id={id}>
      <DataLabWrapper id={id}>
        <DataLab id={id} />
      </DataLabWrapper>
    </DataLabErrorReport>
  );
});

export default DataLabPageWrapper;
