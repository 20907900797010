import { useAuth0 } from "@auth0/auth0-react";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Modal,
  ModalDialog,
  Stack,
  Typography,
} from "@mui/joy";
import { useCallback, useEffect, useState } from "react";
import { Loading as Auth0Loading } from "components";
import { useApiCore, useConfiguration } from "contexts";
import { clearDecentriqStorage, logError } from "utils";
import { type EnclaveToken } from "wrappers";

interface SignInModalProps {
  authErrorState: string;
  open: boolean;
}

const SignInModal: React.FC<SignInModalProps> = ({ authErrorState, open }) => {
  const { logout } = useAuth0();
  const signOut = useCallback(() => {
    clearDecentriqStorage();
    logout({
      logoutParams: {
        federated: true,
        returnTo: window.location.origin,
      },
    });
  }, [logout]);

  return (
    <Modal disableEscapeKeyDown={true} open={open}>
      <ModalDialog role="alertdialog">
        <DialogTitle>{authErrorState}</DialogTitle>
        <DialogContent>
          <Typography align="center" component="div" variant="body2">
            Please sign in again
          </Typography>
        </DialogContent>
        <DialogActions sx={{ flexDirection: "column" }}>
          <Button color="primary" onClick={signOut} variant="solid">
            Sign in
          </Button>
        </DialogActions>
      </ModalDialog>
    </Modal>
  );
};

type EmailMfaState =
  | null
  | { state: "expecting-code-input"; message: string | null }
  | { state: "waiting"; message: string }
  | { state: "code-retrieved"; code: string }
  | { state: "error"; error: string };

interface EnclaveMfaTokenDialogProps {
  setEnclaveToken: (value: string) => void;
  open: boolean;
}

const EnclaveMfaTokenDialog: React.FC<EnclaveMfaTokenDialogProps> = ({
  setEnclaveToken,
  open,
}) => {
  const { configuration } = useConfiguration();
  const { sessionManager } = useApiCore();
  const [authErrorState, setAuthErrorState] = useState<string | null>(null);

  const auth0 = useAuth0();
  const email = auth0.user?.email;
  const [emailMfaState, setEmailMfaState] = useState<EmailMfaState>(null);
  useEffect(() => {
    if (!open) {
      return;
    }
    const transitionState = async () => {
      let session = await sessionManager.getV2();
      if (!emailMfaState) {
        const request = {
          emailStart: { email },
        };
        setEmailMfaState({
          message: "Requesting email MFA code...",
          state: "waiting",
        });
        await session.sendAuthenticationRequest(request);
        setEmailMfaState({
          message: null,
          state: "expecting-code-input",
        });
      } else if (emailMfaState.state === "code-retrieved") {
        const request = {
          emailFinish: { code: emailMfaState.code, email },
        };
        const authResponse = await session.sendAuthenticationRequest(request);
        if (!authResponse.emailFinish) {
          setEmailMfaState({
            error: "Malformed authentication response",
            state: "error",
          });
        } else {
          if (authResponse.emailFinish.incorrectCode) {
            setEmailMfaState({
              message: `The code is incorrect, please enter it again`,
              state: "expecting-code-input",
            });
          } else if (authResponse.emailFinish.notFound) {
            setEmailMfaState({
              message: `The MFA session cannot be found. Please try to send the email again. If the issue persists, please contact support@decentriq.com`,
              state: "expecting-code-input",
            });
          } else if (
            authResponse.emailFinish.success &&
            authResponse.emailFinish.success.token
          ) {
            setEmailMfaState(null);
            const enclaveToken: EnclaveToken = {
              email,
              token: authResponse.emailFinish.success.token,
            };
            setEnclaveToken(JSON.stringify(enclaveToken));
          } else {
            setEmailMfaState({
              error: "Malformed authentication response",
              state: "error",
            });
          }
        }
      }
    };

    transitionState().catch((error) => {
      logError(error);
      setAuthErrorState(`${error}`);
    });
  }, [
    setEnclaveToken,
    sessionManager,
    configuration.auth0ClientId,
    configuration.auth0Domain,
    setAuthErrorState,
    emailMfaState,
    email,
    open,
  ]);

  const [mfaCodeFieldState, setMfaCodeFieldState] = useState("");

  if (authErrorState) {
    return <SignInModal authErrorState={authErrorState} open={open} />;
  }

  if (!emailMfaState || !email) {
    return <Auth0Loading loading={open} />;
  }

  return (
    <Modal disableEscapeKeyDown={true} open={open}>
      <ModalDialog role="alertdialog">
        <DialogTitle>Email MFA</DialogTitle>
        <DialogContent>
          {emailMfaState.state === "expecting-code-input" ? (
            <Stack spacing={2}>
              <Typography>
                An MFA email has been sent to <strong>{email}</strong>.
              </Typography>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  setEmailMfaState({
                    code: mfaCodeFieldState,
                    state: "code-retrieved",
                  });
                }}
              >
                <FormControl>
                  <FormLabel>Please enter the code from the email:</FormLabel>
                  <Input
                    onChange={(e) => setMfaCodeFieldState(e.target.value)}
                    placeholder="- - - - - -"
                    slotProps={{
                      input: {
                        autoComplete: "off",
                        inputMode: "numeric",
                        maxLength: 6,
                        pattern: "[0-9]{6}",
                      },
                    }}
                    type="text"
                    value={mfaCodeFieldState}
                  />
                  {emailMfaState.message && (
                    <FormHelperText>{emailMfaState.message}</FormHelperText>
                  )}
                </FormControl>
              </form>
            </Stack>
          ) : emailMfaState.state === "waiting" ? (
            <Typography>Sending MFA code...</Typography>
          ) : emailMfaState.state === "code-retrieved" ? (
            <Typography>Verifying code...</Typography>
          ) : emailMfaState.state === "error" ? (
            <Typography>{emailMfaState.error}</Typography>
          ) : null}
        </DialogContent>
        <DialogActions sx={{ flexDirection: "column" }}>
          {emailMfaState.state === "expecting-code-input" && (
            <>
              <Button
                color="primary"
                onClick={() => {
                  setEmailMfaState({
                    code: mfaCodeFieldState,
                    state: "code-retrieved",
                  });
                }}
                type="submit"
                variant="solid"
              >
                Submit
              </Button>
              <Button
                onClick={() => {
                  setEmailMfaState(null);
                }}
              >
                Resend code
              </Button>
            </>
          )}
        </DialogActions>
      </ModalDialog>
    </Modal>
  );
};

export default EnclaveMfaTokenDialog;
