import {
  useDraftSqlComputeNodeQuery,
  usePublishedSqlComputeNodeQuery,
} from "@decentriq/graphql/dist/hooks";
import {
  type DraftSqlNode,
  type PublishedSqlNode,
} from "@decentriq/graphql/dist/types";
import {
  useComputeNodesVars,
  useDataRoom,
  usePublishedDataRoom,
  useRequest,
} from "contexts";
import {
  mapDraftDataRoomErrorToSnackbar,
  mapErrorToGeneralSnackbar,
  useDataRoomSnackbar,
} from "hooks";

const useSqlComputeNode = (computeNodeId: string) => {
  const { enqueueSnackbar } = useDataRoomSnackbar();
  const { isPublished } = useDataRoom();
  const { dataRoomId: dcrHash, driverAttestationHash } = usePublishedDataRoom();
  const { commitId } = useRequest();
  const { executionContext } = useComputeNodesVars();
  const shouldUseDraft =
    !isPublished ||
    executionContext === "development" ||
    executionContext === "requests";
  // draft branch
  const { data: draftData, loading: isDraftLoading } =
    useDraftSqlComputeNodeQuery({
      onError: (error) => {
        enqueueSnackbar(
          ...mapDraftDataRoomErrorToSnackbar(
            error,
            "The computation could not be retrieved. Please try again by refreshing the page."
          )
        );
      },
      skip: !shouldUseDraft,
      variables: { computeNodeId },
    });
  // published branch
  const { data: publishedData, loading: isPublishedLoading } =
    usePublishedSqlComputeNodeQuery({
      fetchPolicy: "cache-only",
      onError: (error) => {
        enqueueSnackbar(
          ...mapErrorToGeneralSnackbar(
            error,
            "The computation could not be retrieved. Please try again by refreshing the page."
          )
        );
      },
      skip: shouldUseDraft,
      variables: { commitId, computeNodeId, dcrHash, driverAttestationHash },
    });
  return {
    isLoading: isDraftLoading || isPublishedLoading,
    node:
      (draftData?.draftNode as DraftSqlNode) ||
      (publishedData?.publishedNode as PublishedSqlNode),
  };
};

export default useSqlComputeNode;
