// TODO: Fix joy migration
import { faInfoCircle, faPaperPlane } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Divider,
  Modal,
  ModalDialog,
  Typography,
} from "@mui/joy";
import { memo } from "react";

interface DataRoomRequestSubmissionDialogProps {
  warning?: React.ReactElement | null;
  open: boolean;
  loading?: boolean;
  onCancel: () => void;
  onConfirm: () => void;
}

const DataRoomRequestSubmissionDialog: React.FC<
  DataRoomRequestSubmissionDialogProps
> = ({ warning, open, loading, onCancel, onConfirm }) => {
  return (
    <Modal open={open}>
      <ModalDialog>
        <DialogContent>
          <Box sx={{ mb: 1, textAlign: "center" }}>
            <FontAwesomeIcon fixedWidth={true} icon={faInfoCircle} size="3x" />
          </Box>
          <Typography
            align="center"
            color="textPrimary"
            gutterBottom={true}
            level="title-md"
            style={{ lineHeight: 1.25 }}
          >
            <strong>
              Submitting this request will ask all affected Data Owners for
              approval.
            </strong>
          </Typography>
          {warning}
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button disabled={loading} onClick={onCancel}>
            Go back to editing
          </Button>
          <Button
            data-testid="popup_publish_dcr_btn"
            loading={loading}
            loadingPosition="start"
            onClick={onConfirm}
            startDecorator={<FontAwesomeIcon icon={faPaperPlane} />}
          >
            Submit for approval
          </Button>
        </DialogActions>
      </ModalDialog>
    </Modal>
  );
};

DataRoomRequestSubmissionDialog.displayName = "DataRoomRequestSubmissionDialog";

export default memo(DataRoomRequestSubmissionDialog);
