import {
  faClock,
  faCopy,
  faDiagramProject,
  faHashtagLock,
  faUser,
  faUsers,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Tooltip,
  Typography,
} from "@mui/joy";
import { format } from "date-fns";
import { useSnackbar } from "notistack";
import { memo } from "react";
import { DetailsGridIcon, DetailsGridValue, StateIcon } from "components";
import { DataRoomToolbar } from "components/entities/dataRoom";
import { useDataRoomOverviewData } from "components/entities/dataRoom/DataRoomInformation/hooks";
import { useCopyToClipboard } from "hooks";
import {
  dataRoomTypeCardPresentation,
  DataRoomTypeNames,
  getDataRoomTypeFromTypename,
} from "models";
import { DataRoomDatasetsInformation, ParticipantsPopover } from "./components";

type DataRoomInformationProps = {
  dataRoomId: string;
  __typename: DataRoomTypeNames;
};

const DataRoomInformation: React.FC<DataRoomInformationProps> = ({
  dataRoomId,
  __typename,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [, copyToClipboard] = useCopyToClipboard();
  // Data needed for the info block
  const { data: overviewData, isLoading } = useDataRoomOverviewData(
    dataRoomId,
    __typename
  );
  const {
    id,
    owner,
    createdAt,
    collaboratingOrganizationNames = [],
    participants = [],
    isStopped,
  } = overviewData || {};
  // DataRoomType field
  const dataRoomType = getDataRoomTypeFromTypename[__typename];
  const { title: dataRoomTypeTitle, icon: dataRoomTypeIcon } =
    dataRoomTypeCardPresentation[dataRoomType];
  // isLocked
  const isLocked =
    __typename === DataRoomTypeNames.PublishedDataRoom ||
    __typename === DataRoomTypeNames.PublishedMediaDataRoom ||
    __typename === DataRoomTypeNames.PublishedLookalikeMediaDataRoom ||
    __typename === DataRoomTypeNames.PublishedMediaInsightsDcr;

  // Loading state
  if (isLoading) {
    return (
      <Box
        alignItems="center"
        display="flex"
        height="100%"
        justifyContent="center"
        left="0"
        top="0"
        width="100%"
      >
        <CircularProgress sx={{ "--CircularProgress-size": "2.5rem" }} />
      </Box>
    );
  }
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        height: "100%",
        overflow: "hidden",
        p: 2,
      }}
    >
      <DataRoomToolbar __typename={__typename} dataRoomId={dataRoomId} />
      <Box sx={{ height: "100%", overflow: "auto" }}>
        <Grid columnSpacing={2} container={true} rowSpacing={2}>
          <Grid sm={6} xs={12}>
            <Typography component="div" gutterBottom={true} level="body-sm">
              <strong>ID</strong>
            </Typography>
            <Box
              sx={{
                alignItems: "center",
                display: "flex",
                gap: 1,
                width: "100%",
              }}
            >
              <FontAwesomeIcon fixedWidth={true} icon={faHashtagLock} />
              <Tooltip title={id}>
                <Typography level="body-sm" noWrap={true}>
                  {id}
                </Typography>
              </Tooltip>
              <Tooltip title={<span>Copy Data Clean Room id</span>}>
                <Button
                  onClick={() => {
                    if (id) {
                      copyToClipboard(id);
                      enqueueSnackbar("DataRoom id copied");
                    }
                  }}
                  sx={{ minWidth: "14px" }}
                >
                  <FontAwesomeIcon fixedWidth={true} icon={faCopy} />
                </Button>
              </Tooltip>
            </Box>
          </Grid>
          <Grid sm={6} xs={12}>
            <Typography component="div" gutterBottom={true} level="body-sm">
              <strong>Creator</strong>
            </Typography>
            <DetailsGridValue icon={faUser} value={owner?.email || "—"} />
          </Grid>
          <Grid sm={6} xs={12}>
            <Typography component="div" gutterBottom={true} level="body-sm">
              <strong>Status</strong>
            </Typography>
            <Box sx={{ alignItems: "center", display: "flex", gap: 1 }}>
              <StateIcon
                status={
                  isLocked ? (isStopped ? "STOPPED" : "PUBLISHED") : "DRAFT"
                }
              />
              <DetailsGridValue
                value={
                  isLocked ? (isStopped ? "Stopped" : "Published") : "Draft"
                }
              />
            </Box>
          </Grid>
          <Grid sm={6} xs={12}>
            <Typography component="div" gutterBottom={true} level="body-sm">
              <strong>{`${
                __typename === DataRoomTypeNames.DraftDataRoom
                  ? "Created"
                  : "Published"
              } date`}</strong>
            </Typography>
            {createdAt ? (
              <DetailsGridValue
                icon={faClock}
                value={format(new Date(createdAt), "dd.MM.yyy, HH:mm")}
              />
            ) : (
              "—"
            )}
          </Grid>
          <Grid sm={6} xs={12}>
            <Typography component="div" gutterBottom={true} level="body-sm">
              <strong>Participants</strong>
            </Typography>
            {participants.length ? (
              <Box sx={{ alignItems: "center", display: "flex", gap: 1 }}>
                <DetailsGridIcon icon={faUsers} />
                {participants.length === 1 ? (
                  <DetailsGridValue value={participants[0]?.userEmail} />
                ) : (
                  <ParticipantsPopover
                    ownerEmail={owner?.email!}
                    participants={participants}
                  />
                )}
              </Box>
            ) : (
              "—"
            )}
          </Grid>
          <Grid sm={6} xs={12}>
            <Typography component="div" gutterBottom={true} level="body-sm">
              <strong>Collaborating organizations</strong>
            </Typography>
            {collaboratingOrganizationNames.length ? (
              <DetailsGridValue
                icon={faDiagramProject}
                value={collaboratingOrganizationNames.join(", ")}
              />
            ) : (
              <span>—</span>
            )}
          </Grid>
          <Grid xs={12}>
            <Typography component="div" gutterBottom={true} level="body-sm">
              <strong>Type</strong>
            </Typography>
            <DetailsGridValue
              icon={dataRoomTypeIcon!}
              value={dataRoomTypeTitle!}
            />
          </Grid>
          <Grid xs={12}>
            <Typography component="div" gutterBottom={true} level="body-sm">
              <strong>Datasets provisioned</strong>
            </Typography>
            <DataRoomDatasetsInformation dataRoomId={dataRoomId} />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
DataRoomInformation.displayName = "DataRoomInformation";

export default memo(DataRoomInformation);
