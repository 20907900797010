import { Box, Button, ToggleButtonGroup, Typography } from "@mui/joy";
import { memo } from "react";
import { Controller, useFormContext } from "react-hook-form";
import {
  INSIGHTS_CHART_VALUE_KEY_BUTTONS,
  type InsightsBasicViewFormValues,
  type InsightsDetailedViewFormValues,
} from "features/mediaDataRoom";

interface MediaDataRoomInsightsChartValueKeySelectorProps {
  title: string;
  withSuppressedSegments: boolean;
  suppressedSegmentsInfoComponent: React.ReactNode;
}

// Component defines whether shareInOverlap or shareInMatchableAudience value is taken for the chart
const MediaDataRoomInsightsChartValueKeySelector =
  memo<MediaDataRoomInsightsChartValueKeySelectorProps>(
    ({ title, withSuppressedSegments, suppressedSegmentsInfoComponent }) => {
      const { control: formControl, watch } = useFormContext<
        InsightsBasicViewFormValues | InsightsDetailedViewFormValues
      >();
      const chartValueKey = watch("genderChartValueKey");

      const chartKeyTitle = INSIGHTS_CHART_VALUE_KEY_BUTTONS.find(
        ({ value }) => value === chartValueKey
      )?.label;

      return (
        <Box
          alignItems="flex-end"
          display="flex"
          justifyContent="space-between"
        >
          <Box
            alignItems="baseline"
            display="flex"
            flexDirection={{ md: "row", xs: "column" }}
          >
            <Typography fontWeight={600} mr={1}>
              {title} ({chartKeyTitle})
            </Typography>
            {withSuppressedSegments && suppressedSegmentsInfoComponent}
          </Box>
          <Controller
            control={formControl}
            name="genderChartValueKey"
            render={({ field: { value, onChange } }) => {
              return (
                <ToggleButtonGroup
                  onChange={(_, value: string | null) => onChange(value)}
                  size="sm"
                  value={value}
                >
                  {INSIGHTS_CHART_VALUE_KEY_BUTTONS.map(({ label, value }) => (
                    <Button key={value} value={value}>
                      {label}
                    </Button>
                  ))}
                </ToggleButtonGroup>
              );
            }}
          />
        </Box>
      );
    }
  );

MediaDataRoomInsightsChartValueKeySelector.displayName =
  "MediaDataRoomInsightsChartValueKeySelector";

export default MediaDataRoomInsightsChartValueKeySelector;
