import { v4 as uuidV4 } from "uuid";
import { type ApiCoreStore } from "contexts/apicore/apicore";

export class Store implements ApiCoreStore {
  storage: Map<string, any>;

  constructor() {
    this.storage = new Map();
  }

  reset(): void {
    this.storage.clear();
  }

  get<T = any>(key: string): T | undefined {
    return this.storage.get(key);
  }

  pop<T = any>(key: string): T | undefined {
    const value = this.storage.get(key);
    this.storage.delete(key);
    return value;
  }

  popStrict<T = any>(key: string): T {
    const value = this.pop(key);
    if (value === undefined) {
      throw new Error(`No value corresponding to key '${key}'`);
    }
    return value;
  }

  push(value: any): string {
    const id = uuidV4();
    this.storage.set(id, value);
    return id;
  }
}
