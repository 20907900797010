import { createContext, type ReactNode } from "react";
import { useSafeContext } from "hooks";
import useMediaInsightsDcrDataQuery, {
  type UseMediaInsightsDcrDataQueryResult,
} from "./hooks/useMediaInsightsDcrDataQuery";
import {
  useMediaInsightsDcrStateQuery,
  type UseMediaInsightsDcrStateQueryResult,
} from "./hooks/useMediaInsightsDcrStateQuery";
import usePublishedMediaInsightsDcrQuery, {
  type PublishedMediaInsightsDcrQueryHookResult,
} from "./hooks/usePublishedMediaDataRoomQuery/usePublishedMediaInsightsDcrQuery";

export const PublishedMediaInsightsDcrContext =
  createContext<PublishedMediaInsightsDcrQueryHookResult | null>(null);
PublishedMediaInsightsDcrContext.displayName =
  "PublishedMediaInsightsDcrContext";

export const MediaInsightsDcrStateContext =
  createContext<UseMediaInsightsDcrStateQueryResult | null>(null);
MediaInsightsDcrStateContext.displayName = "MediaInsightsDcrStateContext";

export const MediaInsightsDcrDataContext =
  createContext<UseMediaInsightsDcrDataQueryResult | null>(null);
MediaInsightsDcrDataContext.displayName = "MediaInsightsDcrDataContext";

interface MediaInsightsDcrWrapperProps {
  // TODO @matyasfodor: make sure these props are not drilled through like this.
  isDeactivated: boolean;
  isStopped: boolean;
  dataRoomId: string;
  driverAttestationHash: string;
  children: ReactNode;
  enableExtendedLookalikeQualityStatistics: boolean;
}

export const MediaInsightsDcrWrapper: React.FC<
  MediaInsightsDcrWrapperProps
> = ({
  children,
  dataRoomId,
  driverAttestationHash,
  isDeactivated,
  isStopped,
  enableExtendedLookalikeQualityStatistics,
}) => {
  const publishedMediaDataRoom = usePublishedMediaInsightsDcrQuery({
    dataRoomId,
    driverAttestationHash,
    enableExtendedLookalikeQualityStatistics,
    isDeactivated,
    isStopped,
  });
  const mediaInsightsDcrData = useMediaInsightsDcrDataQuery({
    publishedMediaDataRoom,
  });
  const state = useMediaInsightsDcrStateQuery({
    dataRoomId,
    driverAttestationHash,
  });
  return (
    <PublishedMediaInsightsDcrContext.Provider value={publishedMediaDataRoom}>
      <MediaInsightsDcrStateContext.Provider value={state}>
        <MediaInsightsDcrDataContext.Provider value={mediaInsightsDcrData}>
          {children}
        </MediaInsightsDcrDataContext.Provider>
      </MediaInsightsDcrStateContext.Provider>
    </PublishedMediaInsightsDcrContext.Provider>
  );
};

export const usePublishedMediaInsightsDcr = () =>
  useSafeContext(PublishedMediaInsightsDcrContext);

export const useMediaInsightsDcrData = () =>
  useSafeContext(MediaInsightsDcrDataContext);

export const useMediaInsightsDcrState = () =>
  useSafeContext(MediaInsightsDcrStateContext);
