import { faCalendarDays } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  IconButton,
  type IconButtonProps,
  Input,
  type InputProps,
} from "@mui/joy";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import {
  unstable_useDateField as useDateField,
  type UseDateFieldProps,
} from "@mui/x-date-pickers/DateField";
import {
  DatePicker,
  type DatePickerProps,
} from "@mui/x-date-pickers/DatePicker";
import {
  type BaseSingleInputFieldProps,
  type DateValidationError,
  type FieldSection,
} from "@mui/x-date-pickers/models";
import { forwardRef, Fragment, type MouseEventHandler } from "react";

interface TextFieldToInputProps extends InputProps {
  inputRef?: React.Ref<HTMLInputElement>;
  InputProps?: {
    ref?: React.Ref<HTMLDivElement>;
    endAdornment?: React.ReactNode;
    startAdornment?: React.ReactNode;
  };
  enableAccessibleFieldDOMStructure: boolean;
  clearable: boolean;
  onClear: MouseEventHandler<Element>;
}

type TextFieldToInputComponent = (
  props: TextFieldToInputProps & React.RefAttributes<HTMLDivElement>
) => React.JSX.Element;

const TextFieldToInput = forwardRef(
  (props: TextFieldToInputProps, ref: React.Ref<HTMLDivElement>) => {
    const {
      endDecorator,
      InputProps: { ref: rootRef, startAdornment, endAdornment } = {},
      inputRef,
      slotProps,
      startDecorator,
      // NOTE: Ignoring the following as these cannot be spread to `Input`
      enableAccessibleFieldDOMStructure,
      clearable,
      onClear,
      ...rest
    } = props;
    return (
      <Input
        endDecorator={
          <Fragment>
            {endAdornment}
            {endDecorator}
          </Fragment>
        }
        ref={ref}
        slotProps={{
          ...slotProps,
          endDecorator: { sx: { "&:empty": { display: "none" } } },
          input: {
            ...slotProps?.input,
            ref: inputRef,
            sx: {
              "&[disabled]": {
                pointerEvents: "none",
              },
            },
          },
          root: { ...slotProps?.root, ref: rootRef },
          startDecorator: { sx: { "&:empty": { display: "none" } } },
        }}
        startDecorator={
          <Fragment>
            {startAdornment}
            {startDecorator}
          </Fragment>
        }
        {...rest}
      />
    );
  }
) as TextFieldToInputComponent;

interface DatePickerFieldProps
  extends UseDateFieldProps<Date, false>,
    BaseSingleInputFieldProps<
      Date | null,
      Date,
      FieldSection,
      false,
      DateValidationError
    > {
  slotProps: {
    textField?: {
      onClick?: () => void;
    };
  };
}

const DatePickerField = forwardRef(
  (props: DatePickerFieldProps, ref: React.Ref<HTMLDivElement>) => {
    const { slots, slotProps, ...rest } = props;
    const fieldResponse = useDateField<Date, false, typeof rest>({
      ...rest,
      enableAccessibleFieldDOMStructure: false,
    });
    return (
      <TextFieldToInput
        ref={ref}
        slotProps={{ input: slotProps?.textField }}
        {...fieldResponse}
        onClick={slotProps?.textField?.onClick}
      />
    );
  }
);

const DatePickerOpenPickerButton: React.FC<
  Pick<IconButtonProps, "children" | "onClick">
> = ({ children, onClick }) => {
  return <IconButton onClick={onClick}>{children}</IconButton>;
};

const JoyDatePicker = forwardRef(
  (
    props: Omit<DatePickerProps<Date, false>, "slots">,
    ref: React.Ref<HTMLDivElement>
  ) => {
    return (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          ref={ref}
          {...props}
          slots={{
            field: DatePickerField,
            openPickerButton: DatePickerOpenPickerButton,
            openPickerIcon: () => <FontAwesomeIcon icon={faCalendarDays} />,
          }}
        />
      </LocalizationProvider>
    );
  }
);

export default JoyDatePicker;
