import { type ApiCoreSessionManager, data_science } from "@decentriq/core";
import {
  GetComputeJobEnclaveHandleDocument,
  type GetComputeJobEnclaveHandleQuery,
  type GetComputeJobEnclaveHandleQueryVariables,
  type MutationRetrieveComputeJobResultArgs,
} from "@decentriq/graphql/dist/types";
import * as forge from "node-forge";
import { type LocalResolverContext } from "wrappers/ApolloWrapper/models";

export async function fetchJobResultSize(
  args: MutationRetrieveComputeJobResultArgs,
  context: LocalResolverContext,
  sessionManager: ApiCoreSessionManager
): Promise<number> {
  const { dcrHash, driverAttestationHash, jobId, computeNodeId } = args.input;
  const sdkSession = await sessionManager.get({
    driverAttestationHash,
  });
  const dataScienceDataRoom =
    await sdkSession.retrieveDataScienceDataRoom(dcrHash);
  const wrapper = data_science.createDataScienceDataRoomWrapper(
    dcrHash,
    dataScienceDataRoom!,
    sdkSession
  );
  const computeJobEnclaveHandleEncoded = (
    await context.client.query<
      GetComputeJobEnclaveHandleQuery,
      GetComputeJobEnclaveHandleQueryVariables
    >({
      query: GetComputeJobEnclaveHandleDocument,
      variables: {
        id: jobId,
      },
    })
  ).data.computeJob.enclaveComputeJobHandleBase64;
  const computeJobEnclaveHandle: data_science.DataScienceJobId = JSON.parse(
    new TextDecoder().decode(
      forge.util.binary.base64.decode(computeJobEnclaveHandleEncoded)
    )
  );
  const result = await wrapper.fetchJobResultSize(computeJobEnclaveHandle);
  const computeNodeResult = result.get(computeNodeId)!;
  return computeNodeResult;
}
