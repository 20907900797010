import { Stack, Tab, TabList, TabPanel, Tabs, Typography } from "@mui/joy";
import { type SxProps } from "@mui/system";
import { Fragment, memo } from "react";
import { Link } from "react-router-dom";
import { DatasetImportButton } from "features/datasets/components";
import { DatasetsList } from "features/datasets/containers";
import { useDatasetImportExportWatcher } from "hooks";
import ExternalConnectionsList from "../ExternalConnectionsList/ExternalConnectionsList";

const tabsSx: SxProps = { flex: 1, overflow: "hidden" };
const tabPanelSx: SxProps = {
  "&:not([hidden])": {
    "--Tabs-spacing": 0,
    display: "flex",
    flexDirection: "column",
    overflow: "auto",
  },
};

export enum DatasetsTabs {
  DATASETS = "datasets",
  EXTERNAL = "external",
}

interface DatasetsProps {
  activeTab: DatasetsTabs;
  baseUrl: string;
}

const Datasets = memo<DatasetsProps>(({ activeTab, baseUrl }) => {
  useDatasetImportExportWatcher();
  return (
    <Fragment>
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        sx={{ minHeight: 68, p: 2 }}
      >
        <Typography level="h4">Datasets</Typography>
        <DatasetImportButton />
      </Stack>
      <Tabs sx={tabsSx} value={activeTab}>
        <TabList>
          <Tab
            component={Link}
            data-testid="tab_datasets_datasets"
            to={`${baseUrl}/${DatasetsTabs.DATASETS}`}
            value={DatasetsTabs.DATASETS}
          >
            Datasets
          </Tab>
          <Tab
            component={Link}
            data-testid="tab_datasets_external"
            to={`${baseUrl}/${DatasetsTabs.EXTERNAL}`}
            value={DatasetsTabs.EXTERNAL}
          >
            Imports/Exports
          </Tab>
        </TabList>
        <TabPanel sx={tabPanelSx} value={DatasetsTabs.DATASETS}>
          <DatasetsList />
        </TabPanel>
        <TabPanel sx={tabPanelSx} value={DatasetsTabs.EXTERNAL}>
          <ExternalConnectionsList />
        </TabPanel>
      </Tabs>
    </Fragment>
  );
});

Datasets.displayName = "Datasets";

export default Datasets;
