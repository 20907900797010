import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Modal,
  ModalDialog,
} from "@mui/joy";
import { Fragment } from "react";
import {
  DatasetDeleteButton,
  DatasetExportButton,
} from "features/datasets/containers";
import {
  DatasetPermissionConsumer,
  useDatasetPopupConfig,
} from "features/datasets/contexts";
import { useDatasetWithSchema } from "features/datasets/hooks";
import { DatasetPermissionWrapper } from "features/datasets/wrappers";
import DatasetTabs from "../DatasetTabs/DatasetTabs";

interface DatasetPopupProps {
  open: boolean;
  isDatasetOwner?: boolean;
  onDismiss: () => void;
  manifestHash?: string;
  testing?: {
    dataNodeId: string;
  };
  withActions?: boolean;
}

const DatasetPopup = ({
  manifestHash,
  open,
  onDismiss,
  testing,
  withActions = true,
}: DatasetPopupProps) => {
  const { disableDelete, disableExport } = useDatasetPopupConfig();
  const { dataset } = useDatasetWithSchema({
    manifestHash,
    skip: !open || !manifestHash,
  });
  if (!dataset || !manifestHash) return <></>;
  return (
    <Modal onClose={onDismiss} open={open}>
      <ModalDialog>
        <DialogTitle>{dataset.name || dataset.id?.slice(0, 8)}</DialogTitle>
        <Divider />
        <DatasetPermissionWrapper
          manifestHash={manifestHash!}
          skipFetching={!open || !dataset}
        >
          <Fragment>
            <DialogContent>
              <DatasetTabs manifestHash={manifestHash} skipFetching={!open} />
            </DialogContent>
            {withActions && (
              <Fragment>
                <Divider />
                <DatasetPermissionConsumer>
                  {(contextValue) => (
                    <DialogActions>
                      <Box sx={{ alignItems: "center", display: "flex" }}>
                        {!disableDelete &&
                        contextValue!.canDeleteDatasetOrDefault(
                          dataset.isUploader
                        ) ? (
                          <DatasetDeleteButton
                            manifestHash={dataset.manifestHash!}
                            onComplete={onDismiss}
                            testing={testing}
                          />
                        ) : null}
                        {!disableExport &&
                        contextValue!.canExportDatasetOrDefault(
                          dataset.isUploader
                        ) ? (
                          <DatasetExportButton manifestHash={manifestHash} />
                        ) : null}
                      </Box>
                      <Button onClick={onDismiss}>Close</Button>
                    </DialogActions>
                  )}
                </DatasetPermissionConsumer>
              </Fragment>
            )}
          </Fragment>
        </DatasetPermissionWrapper>
      </ModalDialog>
    </Modal>
  );
};

export default DatasetPopup;
