import { createContext, type PropsWithChildren } from "react";
import { useSafeContext } from "hooks";
import { useStorageState } from "utils";

interface StorageContextValue {
  state: string | null;
  setState: (value: string | null) => void;
}

const localStorageEnclaveTokenKeyId = "dq:driver-enclave-token";

const EnclaveTokenStorageContext = createContext<StorageContextValue | null>(
  null
);

const EnclaveTokenStorageWrapper: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const [state, setState] = useStorageState(
    localStorage,
    localStorageEnclaveTokenKeyId
  );
  return (
    <EnclaveTokenStorageContext.Provider value={{ setState, state }}>
      {children}
    </EnclaveTokenStorageContext.Provider>
  );
};

export const useEnclaveTokenStorage = () =>
  useSafeContext(EnclaveTokenStorageContext);

export default EnclaveTokenStorageWrapper;
