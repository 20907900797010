import { faArrowRight } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Grid, Typography } from "@mui/joy";
import { styled } from "@mui/material";
import { memo } from "react";
import { useNavigate } from "react-router-dom";
import TimeAgo from "react-timeago";
import { StateIcon } from "components";
import { useTimeFormatter } from "hooks";
import { dataRoomPathBuilder, DataRoomTypeNames } from "models";

// TODO: typings
type RecentDataRoomProps = any;

const StyledContainer = styled(Box)(({ theme }) => ({
  backgroundColor: "common.white",
  border: "1px solid",
  borderColor: theme.palette.grey[300],
  borderRadius: theme.shape.borderRadius,
  overflow: "hidden",
  padding: theme.spacing(1),
}));

const RecentDataRoom: React.FC<RecentDataRoomProps> = ({
  __typename,
  id,
  isStopped,
  name,
  userSettings = {},
  updatedAt,
}) => {
  const timeFormatter = useTimeFormatter();
  const navigate = useNavigate();
  const dataRoomLink = dataRoomPathBuilder(id, __typename as DataRoomTypeNames);
  const isLocked =
    __typename === DataRoomTypeNames.PublishedDataRoom ||
    __typename === DataRoomTypeNames.PublishedMediaDataRoom ||
    __typename === DataRoomTypeNames.PublishedMediaInsightsDcr ||
    __typename === DataRoomTypeNames.PublishedLookalikeMediaDataRoom;
  return (
    <Grid
      onClick={() => navigate(dataRoomLink)}
      sx={{ cursor: "pointer" }}
      xs={4}
    >
      <StyledContainer>
        <Box sx={{ alignItems: "center", display: "flex", gap: 1 }}>
          <StateIcon
            status={isLocked ? (isStopped ? "STOPPED" : "PUBLISHED") : "DRAFT"}
          />
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              flex: 1,
              gap: 1,
              overflow: "hidden",
            }}
          >
            <Typography
              fontWeight="fontWeightSemiBold"
              level="body-md"
              noWrap={true}
            >
              {name}
            </Typography>
          </Box>
          <FontAwesomeIcon fixedWidth={true} icon={faArrowRight} />
        </Box>
        <Typography level="body-sm">
          Last activity{" "}
          <TimeAgo
            date={updatedAt}
            formatter={timeFormatter}
            style={{ whiteSpace: "nowrap" }}
          />
        </Typography>
      </StyledContainer>
    </Grid>
  );
};

RecentDataRoom.displayName = "RecentDataRoom";

export default memo(RecentDataRoom);
