import { InfoTooltip } from "@decentriq/components";
import { useSetPrivacyFilterMutation } from "@decentriq/graphql/dist/hooks";
import { Box, Checkbox, Input, Typography } from "@mui/joy";
import { useDebounceFn } from "ahooks";
import { Fragment, useCallback, useEffect, useRef, useState } from "react";
import usePrivacyFilter from "./usePrivacyFilter";

interface PrivacyFilterEditorProps {
  computeNodeId: string;
  readOnly?: boolean;
}

const PrivacyFilterEditor: React.FC<PrivacyFilterEditorProps> = ({
  computeNodeId,
  readOnly,
}) => {
  const node = usePrivacyFilter(computeNodeId);
  const isPrivacyFilterEnabled = node?.privacyFilter?.isEnabled || false;
  const minimumRows = node?.privacyFilter?.minimumRows;
  const [privacyFilterValue, setPrivacyFilterValue] = useState<
    number | undefined
  >();
  useEffect(() => {
    if (privacyFilterValue === undefined && minimumRows !== undefined) {
      setPrivacyFilterValue(minimumRows);
    }
  }, [privacyFilterValue, minimumRows, setPrivacyFilterValue]);
  const [setPrivacyFilterMutation] = useSetPrivacyFilterMutation();
  const onCheckboxChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { checked } = event.target;
      if (!checked) {
        setPrivacyFilterValue(0);
      }
      setPrivacyFilterMutation({
        variables: {
          input: {
            id: computeNodeId,
            privacyFilterIsEnabled: checked,
            ...(!checked ? { privacyFilterMinimumRow: 0 } : {}),
          },
        },
      });
    },
    [computeNodeId, setPrivacyFilterMutation, setPrivacyFilterValue]
  );
  const updatePrivacyFilter = useCallback(
    (privacyFilterValue: number) =>
      setPrivacyFilterMutation({
        refetchQueries: ["DraftPrivacyFilter"],
        variables: {
          input: {
            id: computeNodeId,
            privacyFilterMinimumRow: privacyFilterValue,
          },
        },
      }),
    [computeNodeId, setPrivacyFilterMutation]
  );
  const { run: updatePrivacyFilterDebounced } = useDebounceFn(
    updatePrivacyFilter,
    { wait: 750 }
  );
  const onInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const privacyFilterValue = Number(event.target.value);
      setPrivacyFilterValue(privacyFilterValue);
      updatePrivacyFilterDebounced(privacyFilterValue);
    },
    [setPrivacyFilterValue, updatePrivacyFilterDebounced]
  );
  const inputRef = useRef<HTMLInputElement | null>(null);
  const Label = (
    <Typography level="body-sm" ml={1} mr={0.5} sx={{ lineHeight: 1.2 }}>
      Privacy filter: Enforce all SQL results to come from aggregating over at
      least
    </Typography>
  );
  const LabelTail = (
    <Fragment>
      {readOnly && <strong>{privacyFilterValue || 0}</strong>}
      <Typography level="body-md" ml={0.5}>
        rows
      </Typography>
      <InfoTooltip
        tooltip={
          <Fragment>
            This k-anonymity privacy filter removes all rows from the output of
            a query that have not been calculated by aggregating over at least k
            rows, where k is the specified parameter.
            <br />
            If this option is enabled, it enforces that all computations with an
            associated analyst include a GROUP BY in the final SELECT statement.
          </Fragment>
        }
      />
    </Fragment>
  );
  return readOnly ? (
    isPrivacyFilterEnabled ? (
      <Box sx={{ alignItems: "center", display: "flex", p: 1 }}>
        {Label}
        {LabelTail}
      </Box>
    ) : null
  ) : (
    <Box sx={{ alignItems: "center", display: "flex", p: 1 }}>
      <Checkbox
        checked={isPrivacyFilterEnabled}
        label={Label}
        onChange={onCheckboxChange}
      />
      {!readOnly && (
        <Input
          disabled={!isPrivacyFilterEnabled}
          onChange={onInputChange}
          onFocus={() => {
            if (!Number(privacyFilterValue || 0)) {
              inputRef.current?.select();
            }
          }}
          onKeyDown={(e) => {
            if (e.key === "," || e.key === ".") {
              e.preventDefault();
            }
          }}
          slotProps={{
            input: {
              inputMode: "numeric",
              min: 0,
              pattern: "[0-9]",
              ref: inputRef,
              step: 1,
              type: "number",
            },
          }}
          style={{ width: "3.75rem" }}
          sx={({ spacing }) => ({ margin: spacing(0, 1) })}
          value={privacyFilterValue || 0}
        />
      )}
      {LabelTail}
    </Box>
  );
};

export default PrivacyFilterEditor;
