import { createContext, useContext } from "react";
import { type Configuration } from "utils/configuration/configuration";

export const ConfigurationContext = createContext<{
  configuration: Configuration;
}>({
  configuration: {
    apiUrl: "",
    auth0Audience: "",
    auth0ClientId: "",
    auth0Domain: "",
    auth0RelayClientId: "",
    azureAppConfigurationConnectionString: "",
    clientLogRequests: false,
    diswaHost: "",
    diswaPort: 443,
    diswaUseTls: false,
    environment: "",
    graphqlUrl: "",
    insecureEnclavesEnabled: false,
    nodeEnv: "",
    optimizelyDatafile: null,
    publicUrl: "",
    websocketUrl: "",
  },
});

export const ConfigurationProvider = ConfigurationContext.Provider;

export const useConfiguration = () => useContext(ConfigurationContext);

export default useConfiguration;
