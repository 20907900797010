import { useSnackbar } from "notistack";
import { useCallback } from "react";
import { DataRoomErrorReportButton } from "components";
import { useDataRoomErrorReport } from "contexts";
import {
  type EnqueueSnackbar,
  type SnackbarHookResult,
  wrapSnackbarAction,
} from "hooks";

interface DataRoomSnackbarHookPayload {
  withReportOnError?: boolean;
}

const useDataRoomSnackbar = (
  payload?: DataRoomSnackbarHookPayload
): SnackbarHookResult => {
  const withReportOnError = Boolean(payload?.withReportOnError ?? true);
  const {
    getDcrHLRepresentation,
    getAuditLog,
    dataRoomId,
    dataRoomType,
    getDevelopmentInfo,
    getRequestsInfo,
  } = useDataRoomErrorReport();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const handleEnqueueSnackbar: EnqueueSnackbar = useCallback(
    (message, options) => {
      const action = wrapSnackbarAction({
        action: options?.action,
        suffixAction: (closeSnackbar) => (
          <DataRoomErrorReportButton
            closeSnackbar={closeSnackbar}
            dataRoomId={dataRoomId}
            dataRoomType={dataRoomType}
            details={options?.context || ""}
            error={message}
            getAuditLog={getAuditLog}
            getDcrHLRepresentation={getDcrHLRepresentation}
            getDevelopmentInfo={getDevelopmentInfo}
            getRequestsInfo={getRequestsInfo}
          />
        ),
        variant: options?.variant,
        withReportOnError,
      });
      const effectiveOptions: typeof options =
        typeof options === "object"
          ? {
              // @ts-ignore
              ...options,
              ...action,
            }
          : { ...action };
      return enqueueSnackbar(message, effectiveOptions);
    },
    [
      enqueueSnackbar,
      withReportOnError,
      dataRoomId,
      getAuditLog,
      getDcrHLRepresentation,
      dataRoomType,
      getRequestsInfo,
      getDevelopmentInfo,
    ]
  );
  return {
    closeSnackbar,
    enqueueSnackbar: handleEnqueueSnackbar,
  };
};

export default useDataRoomSnackbar;
