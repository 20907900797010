import {
  type ApolloClient,
  ApolloError,
  type NormalizedCacheObject,
} from "@apollo/client";
import { data_science } from "@decentriq/core";
import { type TestDataset } from "@decentriq/core/dist/session";
import {
  CompletePlaygroundDocument,
  type CompletePlaygroundQuery,
  type CompletePlaygroundQueryVariables,
  type ComputeJob,
  ComputeJobAutoFetching,
  ComputeJobPurpose,
  CreateComputeJobDocument,
  DevComputeNodeJobDocument,
  type DevComputeNodeJobQuery,
  DevComputeNodeTypenameDocument,
  type DevComputeNodeTypenameQuery,
  type DraftNode,
  type MutationRunDevComputationArgs,
} from "@decentriq/graphql/dist/types";
import * as forge from "node-forge";
import { type ApiCoreContextValue } from "contexts";
import { parseDataRoomComputationError } from "utils";
import { parseErrorMessage } from "wrappers/ApolloWrapper/helpers";
import { createDataScienceCommit } from "wrappers/ApolloWrapper/helpers/createDataScienceCommit";
import { type LocalResolverContext } from "wrappers/ApolloWrapper/models";

export async function getPlaygroundCommitData(
  playgroundId: string,
  apolloClient: ApolloClient<NormalizedCacheObject>
): Promise<DraftNode> {
  const completePlayground = await apolloClient.query<
    CompletePlaygroundQuery,
    CompletePlaygroundQueryVariables
  >({
    query: CompletePlaygroundDocument,
    variables: {
      id: playgroundId,
    },
  });
  const draftNode = completePlayground.data.playground.draftNode;
  return draftNode as unknown as DraftNode;
}

export const makeRunDevComputationResolver =
  (
    client: ApiCoreContextValue["client"],
    sessionManager: ApiCoreContextValue["sessionManager"],
    store: ApiCoreContextValue["store"]
  ) =>
  async (
    _obj: null,
    args: MutationRunDevComputationArgs,
    context: LocalResolverContext,
    _info: any
  ): Promise<Partial<ComputeJob>> => {
    const { id, dcrHash, driverAttestationHash, autoFetching } = args.input;
    const sdkSession = await sessionManager.get({
      driverAttestationHash,
    });
    const dataScienceDataRoom =
      await sdkSession.retrieveDataScienceDataRoom(dcrHash);
    const wrapper = data_science.createDataScienceDataRoomWrapper(
      dcrHash,
      dataScienceDataRoom!,
      sdkSession
    );
    const draftNode = await getPlaygroundCommitData(id, context.client);
    try {
      const { commitId } = await createDataScienceCommit(
        draftNode,
        [sdkSession.metaData.email],
        true,
        client,
        wrapper
      );
      let job;
      const testDatasetsInput = args.input.testDatasets;
      const testing = !!testDatasetsInput;
      if (testing) {
        const testDatasetsInput = args.input.testDatasets;
        const testDatasets = new Map<string, TestDataset>();
        for (const dataset of testDatasetsInput || []) {
          testDatasets.set(dataset.leafNodeId, {
            key: store.pop(dataset.encryptionKey)!,
            manifestHash: dataset.manifestHash,
          });
        }
        job = await wrapper.createCommitJob([draftNode.id], commitId, {
          dryRun: {
            testDatasets,
          },
        });
      } else {
        job = await wrapper.createCommitJob([draftNode.id], commitId);
      }
      const encodedDataScienceJobHandle = forge.util.binary.base64.encode(
        new TextEncoder().encode(JSON.stringify(job))
      );
      const result = await context.client.mutate({
        mutation: CreateComputeJobDocument,
        variables: {
          input: {
            computeNodeId: {
              draft: draftNode.id,
            },
            dataRoomHash: dcrHash,
            driverAttestationHash,
            enclaveComputeJobHandleBase64: encodedDataScienceJobHandle,
            enclaveComputeJobId: job.jobId,
            purpose: testing
              ? ComputeJobPurpose.Test
              : ComputeJobPurpose.Standard,
          },
        },
      });
      const computeJob = result.data?.computeJob.create.record;
      if (computeJob) {
        const playground = context.cache.readQuery<DevComputeNodeTypenameQuery>(
          {
            query: DevComputeNodeTypenameDocument,
            variables: {
              playgroundId: id,
            },
          }
        );
        context.client.writeQuery<DevComputeNodeJobQuery>({
          data: {
            draftNode: {
              __typename: playground?.playground?.draftNode?.__typename,
              id: playground?.playground?.draftNode?.id!,
              job: {
                __typename: "ComputeJob",
                autoFetching: autoFetching
                  ? testing
                    ? ComputeJobAutoFetching.TestResult
                    : ComputeJobAutoFetching.Result
                  : ComputeJobAutoFetching.None,
                createdAt: computeJob.createdAt,
                dataRoomHash: computeJob.dataRoomHash,
                driverAttestationHash: computeJob.driverAttestationHash,
                enclaveComputeJobHandleBase64: encodedDataScienceJobHandle,
                enclaveComputeJobId: computeJob.enclaveComputeJobId,
                id: computeJob.id,
                purpose: testing
                  ? ComputeJobPurpose.Test
                  : ComputeJobPurpose.Standard,
                status: computeJob.status,
              },
            },
          },
          query: DevComputeNodeJobDocument,
        });
        return computeJob;
      } else {
        throw new ApolloError({
          errorMessage: "Failed to create job",
          graphQLErrors: result.errors,
        });
      }
    } catch (error) {
      throw parseDataRoomComputationError(
        parseErrorMessage(context.cache, error, args),
        draftNode?.name
      );
    }
  };
