import { DataSourceType } from "@decentriq/graphql/dist/types";

export const dataSourceTypePresentation = new Map<DataSourceType, string>([
  [DataSourceType.S3, "Amazon S3"],
  [DataSourceType.Snowflake, "Snowflake"],
  [DataSourceType.Local, "Computer"],
  [DataSourceType.Compute, "Computation"],
  [DataSourceType.Salesforce, "Salesforce"],
  [DataSourceType.Azure, "Azure Blob Storage"],
  [DataSourceType.GoogleCloudStorage, "Google Cloud Storage"],
  [DataSourceType.Permutive, "Permutive"],
]);
