import { data_science } from "@decentriq/core";
import {
  type MutationRetrievePublishedComputationFormatArgs,
  type RetrieveComputationFormatInPublishedDataRoomPayload,
} from "@decentriq/graphql/dist/types";
import { type ApiCoreContextValue } from "contexts";
import { type LocalResolverContext } from "../../../models";

export const makeRetrievePublishedComputationFormatResolver =
  (
    client: ApiCoreContextValue["client"],
    sessionManager: ApiCoreContextValue["sessionManager"],
    store: ApiCoreContextValue["store"]
  ) =>
  async (
    _obj: null,
    args: MutationRetrievePublishedComputationFormatArgs,
    context: LocalResolverContext,
    _info: any
  ): Promise<RetrieveComputationFormatInPublishedDataRoomPayload> => {
    const { dcrHash, driverAttestationHash, computeNodeId } = args.input;
    const sdkSession = await sessionManager.get({
      driverAttestationHash,
    });
    const dataScienceDataRoom =
      await sdkSession.retrieveDataScienceDataRoom(dcrHash);
    const wrapper = data_science.createDataScienceDataRoomWrapper(
      dcrHash,
      dataScienceDataRoom!,
      sdkSession
    );
    const result = await wrapper.retrieveComputationFormat(computeNodeId);
    const computationFormatReference = store.push(result);
    return { format: computationFormatReference };
  };
