import { faFileImport, faFileLines } from "@fortawesome/pro-light-svg-icons";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Typography,
} from "@mui/joy";
import { memo } from "react";
import { useCreationWizardStepper } from "features/mediaDataRoom/contexts";
import SelectDataSourceTypeButton from "./SelectDataSourceTypeButton";

const SelectDataSourceStep: React.FC = () => {
  const {
    hasOwnDataToProvide,
    setHasOwnDataToProvide,
    handleBackStep,
    handleNextStep,
  } = useCreationWizardStepper();
  return (
    <>
      <DialogTitle>Create new media clean room</DialogTitle>
      <Divider />
      <DialogContent>
        <Box display="flex" flexDirection="column" width="100%">
          <Typography fontWeight="fontWeightSemiBold" level="body-md" mb={0.5}>
            Data source
          </Typography>
          <Grid columnSpacing={4} container={true} xs={12}>
            <Grid xs={6}>
              <SelectDataSourceTypeButton
                icon={faFileLines}
                onClick={() => setHasOwnDataToProvide(true)}
                selected={hasOwnDataToProvide}
                title="Bring my own data"
              />
            </Grid>
            <Grid xs={6}>
              <SelectDataSourceTypeButton
                icon={faFileImport}
                onClick={() => setHasOwnDataToProvide(false)}
                selected={!hasOwnDataToProvide}
                title="From data partner"
              />
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={handleBackStep}>Back</Button>
        <Button color="primary" onClick={handleNextStep} variant="solid">
          Continue
        </Button>
      </DialogActions>
    </>
  );
};

SelectDataSourceStep.displayName = "SelectDataSourceStep";

export default memo(SelectDataSourceStep);
