import { ApolloError } from "@apollo/client";
import { exceptions } from "@decentriq/utils";

export const getEffectiveErrorMessage = (
  error: unknown,
  /**
   * This is dark API, please use it carefully to do not expose unneeded data
   */
  fallbackMessage?: string
): string => {
  if (typeof error === "string") {
    return error;
  }

  if (error instanceof ApolloError) {
    return error.message;
  }

  if (error instanceof Error) {
    return error.message;
  }

  return fallbackMessage || "Unknown error";
};

export const parseDataRoomComputationError = (
  error: unknown,
  computeNodeName?: string
): Error => {
  const message = getEffectiveErrorMessage(error).toLowerCase();

  if (message.includes("requires a dataset to be provisioned")) {
    return new exceptions.DataRoomComputationDatasetRequiredError(
      message,
      computeNodeName
    );
  }

  if (
    message.includes("validation error") ||
    message.includes("validation command error")
  ) {
    return new exceptions.DataRoomComputationValidationError(
      message,
      computeNodeName
    );
  }

  if (message.includes("no available enclave")) {
    return new exceptions.DataRoomComputationMissingEnclaveError(
      message,
      computeNodeName
    );
  }

  if (message.includes("error code: 137")) {
    return new exceptions.DataRoomComputationOutOfMemoryError(
      message,
      computeNodeName
    );
  }

  if (message.includes("request size exceeds remaining quota")) {
    return new exceptions.DataRoomComputationOutOfQuotaError(
      message,
      computeNodeName
    );
  }

  return new exceptions.DataRoomComputationError(message, computeNodeName);
};

export const parseMediaDataRoomError = (error: unknown): Error => {
  const message = getEffectiveErrorMessage(error).toLowerCase();

  if (message.includes("error code: 137")) {
    return new exceptions.DataRoomComputationOutOfMemoryError(message);
  }

  return new Error(message);
};
