import { InfoTooltip } from "@decentriq/components";
import { useUpdateSyntheticNodeAccuracyMutation } from "@decentriq/graphql/dist/hooks";
import { FormControl, FormLabel, Option, Select } from "@mui/joy";
import { memo, useCallback } from "react";
import { mapDraftDataRoomErrorToSnackbar, useDataRoomSnackbar } from "hooks";
import {
  type SyntheticNodeAccuracy,
  syntheticNodeAccuracyPresentation,
} from "models";
import useSyntheticNodeAccuracy from "./useSyntheticNodeAccuracy";

type SyntheticNodeAccuracySelectProps = {
  computeNodeId: string;
  readOnly?: boolean;
};

const SyntheticNodeAccuracySelect: React.FC<
  SyntheticNodeAccuracySelectProps
> = ({ computeNodeId, readOnly }) => {
  const { accuracy } = useSyntheticNodeAccuracy(computeNodeId);
  const { enqueueSnackbar } = useDataRoomSnackbar();
  const [updateSyntheticNodeAccuracyMutation] =
    useUpdateSyntheticNodeAccuracyMutation();
  const updateSyntheticNodeAccuracy = useCallback(
    async (accuracy: SyntheticNodeAccuracy) => {
      try {
        return await updateSyntheticNodeAccuracyMutation({
          variables: {
            input: {
              accuracy,
              id: computeNodeId,
            },
          },
        });
      } catch (error) {
        enqueueSnackbar(
          ...mapDraftDataRoomErrorToSnackbar(
            error,
            "The privacy mode settings could not be updated."
          )
        );
        throw error;
      }
    },
    [updateSyntheticNodeAccuracyMutation, computeNodeId, enqueueSnackbar]
  );

  return (
    <FormControl>
      <FormLabel>
        Privacy mode{" "}
        <InfoTooltip
          tooltip={
            <>
              Choose <i>Balanced</i> for best accuracy.
              <br />
              Choose <i>Strictest</i> for the strongest privacy guarantees.
            </>
          }
        />
      </FormLabel>
      <Select
        disabled={readOnly}
        onChange={(event, value) => {
          updateSyntheticNodeAccuracy(value as SyntheticNodeAccuracy);
        }}
        sx={{ marginLeft: "0.5rem", minWidth: 144, textAlign: "center" }}
        value={accuracy}
      >
        {Array.from(syntheticNodeAccuracyPresentation.entries()).map(
          ([value, label], index) => (
            <Option key={index} value={value}>
              {label}
            </Option>
          )
        )}
      </Select>
    </FormControl>
  );
};

SyntheticNodeAccuracySelect.displayName = "SyntheticNodeAccuracySelect";

export default memo(SyntheticNodeAccuracySelect);
