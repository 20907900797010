import { useDataConnectorJobQuery } from "@decentriq/graphql/dist/hooks";
import { Grid, Typography } from "@mui/joy";
import { memo } from "react";

type SnowflakeConnectionConfigurationProps = {
  connectionId: string;
};

const SnowflakeConnectionConfiguration: React.FC<
  SnowflakeConnectionConfigurationProps
> = ({ connectionId }) => {
  const {
    data: { dataConnectorJob: { config: datasetImportConfig = {} } = {} } = {},
  } = useDataConnectorJobQuery({
    variables: { id: connectionId },
  });
  const { databaseName, schemaName, stageName, tableName, warehouseName } =
    datasetImportConfig || {};
  return (
    <Grid container={true} direction="column" xs={12}>
      <Typography level="body-sm">
        <strong>Warehouse name:</strong> {warehouseName}
      </Typography>
      <Typography level="body-sm">
        <strong>Database name:</strong> {databaseName}
      </Typography>
      <Typography level="body-sm">
        <strong>Schema name:</strong> {schemaName}
      </Typography>
      <Typography level="body-sm">
        <strong>Table name:</strong> {tableName}
      </Typography>
      <Typography level="body-sm">
        <strong>Stage name:</strong> {stageName}
      </Typography>
    </Grid>
  );
};

SnowflakeConnectionConfiguration.displayName =
  "SnowflakeConnectionConfiguration";

export default memo(SnowflakeConnectionConfiguration);
