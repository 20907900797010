import { Box, useTheme } from "@mui/joy";
import { ResponsiveLine } from "@nivo/line";
import { memo, useMemo } from "react";
import { useLookalikeAdvertiserAudienceGenerator } from "features/mediaDataRoom/containers/LookalikeAdvertiserAudienceGenerator";

const PositiveCurveScoreChart: React.FC = () => {
  const { palette } = useTheme();
  const { selectedAudienceStatistics, selectedSeedAudience } =
    useLookalikeAdvertiserAudienceGenerator();
  const { recall, rawStatistics } = selectedAudienceStatistics!;

  const { tpr: truePositiveValues = [], fpr: falsePositiveValues = [] } =
    rawStatistics!.quality.roc_curve;

  const selectedAudienceFalsePositiveRecallValue = useMemo(
    () => parseFloat(recall!.fpr.toFixed(2) || "0"),
    [recall]
  );

  const positiveCurveScoreChartData = useMemo(
    () => [
      {
        data: falsePositiveValues.map((falsePositiveValue, index) => ({
          x: falsePositiveValue,
          y: truePositiveValues[index],
        })),
        id: `${selectedSeedAudience?.name}`,
      },
      {
        data: [
          {
            x: selectedAudienceFalsePositiveRecallValue,
            y: 0,
          },
          {
            x: selectedAudienceFalsePositiveRecallValue,
            y: 1,
          },
        ],
        id: `${(selectedAudienceFalsePositiveRecallValue * 100).toFixed(0)}% of addressable audience`,
      },
    ],
    [
      selectedSeedAudience?.name,
      falsePositiveValues,
      selectedAudienceFalsePositiveRecallValue,
      truePositiveValues,
    ]
  );
  return (
    <Box height={400} mt={2} width="100%">
      <ResponsiveLine
        axisBottom={{
          format: (value) => `${value * 100}%`,
          legend: "“False Positive” Rate",
          legendOffset: 40,
          legendPosition: "middle",
        }}
        axisLeft={{
          format: (value) => `${value * 100}%`,
          legend: "“True Positive” Rate",
          legendOffset: -48,
          legendPosition: "middle",
        }}
        colors={[palette.warning.plainColor, palette.primary.plainColor]}
        data={positiveCurveScoreChartData}
        enableGridX={true}
        enableGridY={false}
        enablePointLabel={false}
        enablePoints={false}
        isInteractive={false}
        legends={[
          {
            anchor: "bottom-left",
            direction: "column",
            itemDirection: "left-to-right",
            itemHeight: 20,
            itemOpacity: 0.85,
            itemWidth: 100,
            symbolShape: "circle",
            symbolSize: 8,
            translateX: -32,
            translateY: 82,
          },
        ]}
        lineWidth={3}
        margin={{
          bottom: 90,
          left: 54,
          right: 16,
          top: 16,
        }}
        xScale={{
          type: "linear",
        }}
      />
    </Box>
  );
};

PositiveCurveScoreChart.displayName = "PositiveCurveScoreChart";

export default memo(PositiveCurveScoreChart);
