import { faInfoCircle } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Checkbox,
  FormControl,
  FormHelperText,
  FormLabel,
  Option,
  Select,
  type SelectOption,
  Tooltip,
} from "@mui/joy";
import { memo, useCallback } from "react";
import {
  Controller,
  type FieldValues,
  type Path,
  useFormContext,
} from "react-hook-form";
import { type ActiveMarket } from "../../hooks/useActiveMarkets/useActiveMarkets";

interface DisplayConfigurationActiveMarketsFieldProps<F extends FieldValues> {
  fieldName: Path<F>;
  activeMarkets: ActiveMarket[];
  tooltip?: string;
  testId?: string;
  optionTestId?: string;
}

type DisplayConfigurationActiveMarketsFieldComponent = <F extends FieldValues>(
  props: DisplayConfigurationActiveMarketsFieldProps<F>
) => React.ReactNode;

const DisplayConfigurationActiveMarketsField = memo(
  <F extends FieldValues>({
    fieldName,
    activeMarkets,
    tooltip,
    testId,
    optionTestId,
  }: DisplayConfigurationActiveMarketsFieldProps<F>) => {
    const { control } = useFormContext<F>();
    const handleRenderValue = useCallback(
      (selectedIdOptions: SelectOption<string>[]): React.ReactNode => {
        const selected = activeMarkets
          .filter(({ id }) =>
            selectedIdOptions.map(({ value }) => value).includes(id)
          )
          .map(({ name }) => name);
        if (selected.length === 0) return "No active markets selected";
        if (selected.length <= 2) return selected.join(", ");
        return (
          <Tooltip title={selected.join(", ")}>
            <span>{`${selected.slice(0, 2).join(", ")} + ${selected.length - 2} more`}</span>
          </Tooltip>
        );
      },
      [activeMarkets]
    );
    return (
      <Controller
        control={control}
        name={fieldName}
        render={({ field, fieldState }) => {
          const { error } = fieldState;
          return (
            <FormControl
              disabled={field.disabled}
              error={Boolean(error)}
              required={true}
            >
              <FormLabel>
                Active markets
                {tooltip ? (
                  <Box ml={0.5}>
                    <Tooltip title={tooltip}>
                      <FontAwesomeIcon icon={faInfoCircle} />
                    </Tooltip>
                  </Box>
                ) : null}
              </FormLabel>
              <Select
                multiple={true}
                placeholder="No active markets selected"
                renderValue={handleRenderValue}
                {...field}
                data-testid={testId}
                onChange={(event, value) => field.onChange(value)}
              >
                {activeMarkets.map(({ id, name }) => (
                  <Option
                    data-testid={`${optionTestId}${id.toLowerCase()}`}
                    key={id}
                    value={id}
                  >
                    <FormControl>
                      <Checkbox checked={field.value.includes(id)} />
                    </FormControl>
                    {name}
                  </Option>
                ))}
              </Select>
              <FormHelperText>{error?.message}</FormHelperText>
            </FormControl>
          );
        }}
      />
    );
  }
);

DisplayConfigurationActiveMarketsField.displayName =
  "DisplayConfigurationActiveMarketsField";

export default DisplayConfigurationActiveMarketsField as DisplayConfigurationActiveMarketsFieldComponent;
