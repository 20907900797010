import { useDataConnectorJobQuery } from "@decentriq/graphql/dist/hooks";
import { SalesforceProductType } from "@decentriq/graphql/dist/types";
import { Grid, Typography } from "@mui/joy";
import { memo } from "react";

export const SalesforceProductTypeMap = new Map<SalesforceProductType, string>([
  [SalesforceProductType.Core, "Core"],
  [SalesforceProductType.MarketingCloud, "Marketing Cloud"],
]);

type SalesforceConnectionConfigurationProps = {
  connectionId: string;
};

const SalesforceConnectionConfiguration: React.FC<
  SalesforceConnectionConfigurationProps
> = ({ connectionId }) => {
  const {
    data: { dataConnectorJob: { config: datasetImportConfig = {} } = {} } = {},
  } = useDataConnectorJobQuery({
    variables: { id: connectionId },
  });
  const { apiName, domainUrl, productType } = datasetImportConfig || {};

  return (
    <Grid container={true} direction="column" xs={12}>
      <Typography level="body-sm">
        <strong>API name:</strong> {apiName}
      </Typography>
      <Typography level="body-sm">
        <strong>Domain URL:</strong> {domainUrl}
      </Typography>
      <Typography level="body-sm">
        <strong>Product type:</strong>{" "}
        {SalesforceProductTypeMap.get(productType)}
      </Typography>
    </Grid>
  );
};

SalesforceConnectionConfiguration.displayName =
  "SalesforceConnectionConfiguration";

export default memo(SalesforceConnectionConfiguration);
