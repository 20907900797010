import {
  useDraftSyntheticNodeIncludeStatisticsQuery,
  usePublishedSyntheticNodeIncludeStatisticsQuery,
} from "@decentriq/graphql/dist/hooks";
import {
  type DraftSyntheticNode,
  type PublishedSyntheticNode,
} from "@decentriq/graphql/dist/types";
import {
  useComputeNodesVars,
  useDataRoom,
  usePublishedDataRoom,
  useRequest,
} from "contexts";
import {
  mapDraftDataRoomErrorToSnackbar,
  mapErrorToGeneralSnackbar,
  useDataRoomSnackbar,
} from "hooks";

const useSyntheticNodeIncludeStatistics = (
  computeNodeId: string
): { includeReportWithStats: boolean | undefined } => {
  const { enqueueSnackbar } = useDataRoomSnackbar();
  const { isPublished } = useDataRoom();
  const { dataRoomId: dcrHash, driverAttestationHash } = usePublishedDataRoom();
  const { commitId } = useRequest();
  const { executionContext } = useComputeNodesVars();
  const shouldUseDraft =
    !isPublished ||
    executionContext === "development" ||
    executionContext === "requests";
  // draft & development
  const { data: draftData } = useDraftSyntheticNodeIncludeStatisticsQuery({
    fetchPolicy: "cache-only",
    onError: (error) => {
      enqueueSnackbar(
        ...mapDraftDataRoomErrorToSnackbar(
          error,
          "The computation could not be retrieved. Please try again by refreshing the page."
        )
      );
    },
    skip: !shouldUseDraft,
    variables: { computeNodeId },
  });
  // published
  const { data: publishedData } =
    usePublishedSyntheticNodeIncludeStatisticsQuery({
      fetchPolicy: "cache-only",
      onError: (error) => {
        enqueueSnackbar(
          ...mapErrorToGeneralSnackbar(
            error,
            "The computation could not be retrieved. Please try again by refreshing the page."
          )
        );
      },
      skip: shouldUseDraft,
      variables: { commitId, computeNodeId, dcrHash, driverAttestationHash },
    });
  return {
    includeReportWithStats:
      (draftData?.draftNode as DraftSyntheticNode)?.includeReportWithStats ||
      (publishedData?.publishedNode as PublishedSyntheticNode)
        ?.includeReportWithStats,
  };
};

export default useSyntheticNodeIncludeStatistics;
