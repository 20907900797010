export enum UserKeychainStatus {
  Locked = "LOCKED",
  Unlocked = "UNLOCKED",
  Unset = "UNSET",
}

export interface ResetKeychainPayload {
  error?: KeychainOperationError;
}

export interface ChangeKeychainPasswordPayload {
  error?: KeychainOperationError;
}

export enum KeychainStatus {
  initial,
  loading,
  unset,
  locked,
  unlocked,
  error,
  unknown,
}

export interface KeychainItem {
  id: string;
  kind: KeychainItemKind;
  value: string;
}

export interface EmptyKeychainPayload {
  error?: KeychainOperationError;
}

export enum KeychainItemKind {
  Dataset = "DATASET",
  DatasetMetadata = "DATASET_METADATA",
  PendingDatasetImport = "PENDING_DATASET_IMPORT",
}

export enum KeychainOperationErrorKind {
  ItemNotFound = "ITEM_NOT_FOUND",
  PermissionDenied = "PERMISSION_DENIED",
  WrongPassword = "WRONG_PASSWORD",
}

export interface KeychainOperationError {
  kind: KeychainOperationErrorKind;
  message?: string;
}

export interface UnlockKeychainPayload {
  error?: KeychainOperationError;
}

export interface Keychain {
  statusOrInitilize(): Promise<UserKeychainStatus>;
  insertItem(item: KeychainItem): Promise<KeychainItem>;
  insertItems(items: KeychainItem[]): Promise<KeychainItem[]>;
  removeItem(item: Omit<KeychainItem, "value">): Promise<boolean>;
  removeItems(items: Omit<KeychainItem, "value">[]): Promise<void>;
  getItem(id: string, kind: KeychainItemKind): Promise<KeychainItem>;
  getItems(): Promise<KeychainItem[]>;
  registerListener(callback: (() => void) | (() => Promise<void>)): () => void;
}
