import { type FetchResult } from "@apollo/client";
import {
  useCreateDraftDataRoomIsFavoriteMutation,
  useDraftDataRoomIsFavoriteQuery,
  useUpdateDraftDataRoomIsFavoriteMutation,
} from "@decentriq/graphql/dist/hooks";
import {
  type CreateDraftDataRoomIsFavoriteMutation,
  DataRoomsListDocument,
  type UpdateDraftDataRoomIsFavoriteMutation,
} from "@decentriq/graphql/dist/types";

interface useDraftDataRoomIsFavoriteArgs {
  id: string;
  skip?: boolean;
}

interface useDraftDataRoomIsFavoriteResult {
  draftDataRoomIsFavorite: boolean;
  draftDataRoomIsFavoriteLoading: boolean;
  setDraftDataRoomIsFavorite: (
    isFavorite: boolean
  ) =>
    | Promise<
        FetchResult<
          UpdateDraftDataRoomIsFavoriteMutation,
          Record<string, any>,
          Record<string, any>
        >
      >
    | Promise<
        FetchResult<
          CreateDraftDataRoomIsFavoriteMutation,
          Record<string, any>,
          Record<string, any>
        >
      >;
  setDraftDataRoomIsFavoriteLoading: boolean;
}

const useDraftDataRoomIsFavorite = ({
  id: dataRoomId,
  skip = false,
}: useDraftDataRoomIsFavoriteArgs): useDraftDataRoomIsFavoriteResult => {
  const { data, loading: isFavoriteLoading } = useDraftDataRoomIsFavoriteQuery({
    skip,
    variables: { id: dataRoomId },
  });
  const { draftDataRoom } = data || {};
  const { userSettings } = draftDataRoom || {};
  const { id: userSettingsId, isFavorite = false } = userSettings || {};
  const [
    createDraftDataRoomIsFavoriteMutation,
    { loading: createDraftDataRoomIsFavoriteLoading },
  ] = useCreateDraftDataRoomIsFavoriteMutation();
  const [
    updateDraftDataRoomIsFavoriteMutation,
    { loading: updateDraftDataRoomIsFavoriteLoading },
  ] = useUpdateDraftDataRoomIsFavoriteMutation();
  const setIsFavorite = (isFavorite: boolean) => {
    return userSettingsId
      ? updateDraftDataRoomIsFavoriteMutation({
          refetchQueries: [{ query: DataRoomsListDocument }],
          variables: { id: userSettingsId, isFavorite },
        })
      : createDraftDataRoomIsFavoriteMutation({
          refetchQueries: [{ query: DataRoomsListDocument }],
          variables: { draftDataRoomId: dataRoomId, isFavorite },
        });
  };
  const setIsFavoriteLoading = userSettingsId
    ? updateDraftDataRoomIsFavoriteLoading
    : createDraftDataRoomIsFavoriteLoading;
  return {
    draftDataRoomIsFavorite: isFavorite,
    draftDataRoomIsFavoriteLoading: isFavoriteLoading,
    setDraftDataRoomIsFavorite: setIsFavorite,
    setDraftDataRoomIsFavoriteLoading: setIsFavoriteLoading,
  };
};

export default useDraftDataRoomIsFavorite;
