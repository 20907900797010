import { faCopy } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Stack } from "@mui/joy";
import { Tooltip, type TooltipProps } from "@mui/joy";
import capitalize from "lodash/capitalize";
import { useSnackbar } from "notistack";
import { memo, type ReactNode } from "react";
import { useCopyToClipboard } from "hooks";

interface NodeTooltipProps {
  children: ReactNode;
  value: string;
  nodeType: string;
  sx?: React.CSSProperties;
  placement?: TooltipProps["placement"];
}

const NodeTooltip = memo<NodeTooltipProps>(
  ({ children, sx = {}, value, nodeType, placement = "top" }) => {
    const { enqueueSnackbar } = useSnackbar();
    const [, copyToClipboard] = useCopyToClipboard();
    return (
      <Tooltip
        enterDelay={1000}
        leaveDelay={0}
        placement={placement}
        title={
          <Box
            onClick={(event) => {
              event.stopPropagation();
              copyToClipboard(value);
              enqueueSnackbar(
                `${capitalize(nodeType)} node ID is copied to clipboard`
              );
            }}
          >
            <span>ID: {value}</span>
            <FontAwesomeIcon
              fixedWidth={true}
              icon={faCopy}
              style={{ marginLeft: "8px" }}
            />
          </Box>
        }
      >
        <Stack direction="row">{children}</Stack>
      </Tooltip>
    );
  }
);
NodeTooltip.displayName = "NodeTooltip";

export default NodeTooltip;
