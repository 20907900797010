// TODO: Fix joy migration
import { faXmark } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert, type Theme } from "@mui/material";
import { useLocalStorageState } from "ahooks";
import { useCallback, useState } from "react";
import { makeStyles } from "tss-react/mui";
import { useAppConfiguration, useFeatureFlag } from "utils/featureFlags";

const useNotificationBannerStyles = makeStyles()((theme: Theme) => ({
  action: {
    paddingTop: "2px",
  },
  message: {
    "& a": {
      "&:visited": {
        color: "inherit",
      },
      color: "inherit",
    },
    color: "white",
    textAlign: "center",
    width: "100%",
  },
  root: {
    background: "rgb(13, 21, 53)",
  },
}));

const NotificationBanner = () => {
  const { classes: notificationBannerClasses } = useNotificationBannerStyles();
  const isNotificationBannerEnabled = useFeatureFlag("notification_banner");
  const [localNotificationBannerRevision, setLocalNotificationBannerRevision] =
    useLocalStorageState<number | undefined>(
      "decentriq.com/notification-banner-revision"
    );

  const {
    content: notificationBannerContent,
    revision: notificationBannerRevision,
    dismissible: notificationBannerDismissable,
  } = useAppConfiguration("notification_banner_values", {
    content: "",
    dismissible: false,
    revision: 0,
  });
  const [isNotificationBannerClosed, setIsNotificationBannerClosed] =
    useState<boolean>(false);
  const isNotificationBannerShown =
    isNotificationBannerEnabled &&
    !!notificationBannerRevision &&
    notificationBannerRevision !== localNotificationBannerRevision &&
    !isNotificationBannerClosed;

  const handleNotificationBannerClose = useCallback(() => {
    setIsNotificationBannerClosed(true);
    setLocalNotificationBannerRevision(notificationBannerRevision);
  }, [
    setIsNotificationBannerClosed,
    setLocalNotificationBannerRevision,
    notificationBannerRevision,
  ]);
  return isNotificationBannerShown ? (
    <Alert
      classes={notificationBannerClasses}
      icon={false}
      onClose={
        notificationBannerDismissable
          ? handleNotificationBannerClose
          : undefined
      }
      severity="info"
      slotProps={{ closeButton: { size: "small", sx: { color: "white" } } }}
      slots={{
        closeIcon: () => <FontAwesomeIcon fixedWidth={true} icon={faXmark} />,
      }}
      square={true}
    >
      <span
        dangerouslySetInnerHTML={{ __html: notificationBannerContent }}
      ></span>
    </Alert>
  ) : null;
};

export default NotificationBanner;
