import { Stack } from "@mui/joy";
import { memo } from "react";
import {
  ParticipantsConstructor,
  ParticipantsInteractivitySettings,
} from "components";
import { useDataRoom } from "contexts";

const DataRoomParticipants: React.FC = memo(() => {
  const { dataRoomId } = useDataRoom();
  return (
    <Stack>
      <ParticipantsInteractivitySettings id={dataRoomId} />
      <ParticipantsConstructor id={dataRoomId} />
    </Stack>
  );
});

export default DataRoomParticipants;
