import { type VariantType } from "notistack";
import { type EnqueueSnackbarOptions } from "hooks";
import { getEffectiveErrorMessage } from "utils";

const mapDraftDataRoomErrorToSnackbar = (
  originalError: unknown,
  message: string
): [string, EnqueueSnackbarOptions<VariantType> | undefined] => {
  return [
    message,
    {
      context: getEffectiveErrorMessage(originalError),
      persist: true,
      variant: "error",
    },
  ];
};

export default mapDraftDataRoomErrorToSnackbar;
