import { faBan } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Grid, Typography } from "@mui/joy";
import { memo } from "react";
import { EmptyStateContainer } from "features/mediaDataRoom/components";
import AdvertiserAudienceGeneratorHeader from "../AdvertiserAudienceGeneratorStep/AdvertiserAudienceGeneratorHeader";

const AdvertiserAudienceGeneratorEmptyState = memo(() => {
  return (
    <Grid container={true} rowSpacing={6}>
      <Grid xs={7}>
        <AdvertiserAudienceGeneratorHeader />
      </Grid>
      <Grid xs={12}>
        <EmptyStateContainer>
          <FontAwesomeIcon fixedWidth={true} icon={faBan} size="2x" />
          <Typography level="body-md" mt={1}>
            There are not enough individuals in the overlap.
          </Typography>
          <Typography level="body-md">
            Please check if the Matching ID is compatible.
          </Typography>
        </EmptyStateContainer>
      </Grid>
    </Grid>
  );
});

AdvertiserAudienceGeneratorEmptyState.displayName =
  "AdvertiserAudienceGeneratorEmptyState";

export default AdvertiserAudienceGeneratorEmptyState;
