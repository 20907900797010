import {
  useDraftComputeNodeNameQuery,
  usePublishedComputeNodeNameQuery,
} from "@decentriq/graphql/dist/hooks";
import { usePublishedDataRoom, useRequest, useRequestsVars } from "contexts";
import {
  mapDraftDataRoomErrorToSnackbar,
  mapErrorToGeneralSnackbar,
  useDataRoomSnackbar,
} from "hooks";

const useRequestName = (computeNodeId: string) => {
  const { enqueueSnackbar } = useDataRoomSnackbar();
  const { context } = useRequestsVars();
  const { dataRoomId: dcrHash, driverAttestationHash } = usePublishedDataRoom();
  const { commitId } = useRequest();
  const shouldUseDraft = context === "draft";
  const { data: draftData } = useDraftComputeNodeNameQuery({
    onError: (error) => {
      enqueueSnackbar(
        ...mapDraftDataRoomErrorToSnackbar(
          error,
          "The computation could not be retrieved."
        )
      );
    },
    skip: !shouldUseDraft,
    variables: { computeNodeId },
  });
  const { data: publishedData } = usePublishedComputeNodeNameQuery({
    fetchPolicy: "cache-only",
    onError: (error) => {
      enqueueSnackbar(
        ...mapErrorToGeneralSnackbar(
          error,
          "The computation could not be retrieved."
        )
      );
    },
    skip: shouldUseDraft,
    variables: { commitId, computeNodeId, dcrHash, driverAttestationHash },
  });
  return {
    __typename: (draftData?.draftNode || publishedData?.publishedNode)
      ?.__typename,
    name: (draftData?.draftNode || publishedData?.publishedNode)?.name,
  };
};

export default useRequestName;
