import { testIds } from "@decentriq/utils";
import {
  faCheck,
  faFileCheck,
  faFolderOpen,
  faInfoCircle,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Modal,
  ModalDialog,
  Radio,
  RadioGroup,
  type RadioProps,
  Stack,
  Tooltip,
} from "@mui/joy";
import { format, isValid as isDateValid } from "date-fns";
import { type ChangeEvent, useEffect, useState } from "react";
import {
  Controller,
  FormProvider,
  useForm,
  useFormState,
} from "react-hook-form";
import { z } from "zod";
import { CommonSnackbarOrigin, useGeneralSnackbar } from "hooks";
import { getDcrProperties } from "utils/apicore";

const radioSlotProps: RadioProps["slotProps"] = {
  label: {
    sx: { cursor: "pointer", userSelect: "none", zIndex: 1 },
  },
  radio: (ownerState) => ({
    sx: (theme) => ({
      ...(ownerState.checked && theme.variants.solid.primary),
      "&:hover": {
        ...(ownerState.checked && theme.variants.solidHover.primary),
      },
    }),
  }),
};

const CONFIGURATION_SOURCE_NONE = "none";
const CONFIGURATION_SOURCE_IMPORT = "import";
const CONFIGURATION_SOURCE_CLONE = "clone";
const CONFIGURATION_SOURCE_TEMPLATE = "template";
const IS_FEATURE_CONFIGURATION_SOURCE_NONE_ENABLED = true;
const IS_FEATURE_CONFIGURATION_SOURCE_IMPORT_ENABLED = true;
const IS_FEATURE_CONFIGURATION_SOURCE_CLONE_ENABLED = false;
const IS_FEATURE_CONFIGURATION_SOURCE_TEMPLATE_ENABLED = false;
const IS_DETAILED_IMPORT_FILE_PREVIEW = false;

const schema = z.object({
  name: z.string().min(1, "Name is required"),
});

type SchemaType = z.infer<typeof schema>;

interface DataScienceDataRoomCreateDialogProps {
  open: boolean;
  initialConfigurationSource?: string;
  loading: boolean;
  onCancel: () => void;
  onConfirm: (
    config: {
      name: string;
      description?: string;
      configuration?: any;
    },
    onComplete: () => void
  ) => void;
  restartCreation: () => void;
}

const DataScienceDataRoomCreateDialog: React.FC<
  DataScienceDataRoomCreateDialogProps
> = ({
  initialConfigurationSource = CONFIGURATION_SOURCE_NONE,
  open,
  loading,
  onCancel,
  onConfirm,
  restartCreation,
}) => {
  const [configuration, setConfiguration] = useState();
  const [configurationSource, setConfigurationSource] = useState(
    initialConfigurationSource
  );
  const { enqueueSnackbar } = useGeneralSnackbar({
    origin: CommonSnackbarOrigin.DASHBOARD,
  });
  const form = useForm<SchemaType>({
    defaultValues: {
      name: "",
    },
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: zodResolver(schema),
  });
  const { handleSubmit, control, reset, watch, setValue } = form;
  // Name that is entered manually to the input
  const dcrName = watch("name");
  const { isSubmitting } = useFormState({ control });
  // TODO: use a hidden input with React ref instead of programatically creating one
  const [importFile, setImportFile] = useState();
  const { lastModifiedDate, name, size, type } = importFile || {};
  const selectFile = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = ".json,application/json";
    input.onchange = (event) => {
      const file = event.target.files[0];
      setImportFile(file);
      event.target.value = "";
    };
    input.click();
  };
  const changeFile = (event) => {
    event.stopPropagation();
    selectFile();
  };
  const changeConfigurationSource = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (
      !IS_DETAILED_IMPORT_FILE_PREVIEW &&
      value === CONFIGURATION_SOURCE_IMPORT
    ) {
      selectFile();
      return;
    }
    if (value === CONFIGURATION_SOURCE_NONE && configuration) {
      setConfiguration(undefined);
      setImportFile(undefined);
      // Reset should be only when changing from import to scratch
      reset({ name: "" });
    }
    setConfigurationSource(event.target.value);
  };
  useEffect(() => {
    setConfigurationSource(initialConfigurationSource);
  }, [initialConfigurationSource]);
  useEffect(() => {
    if (importFile) {
      const reader = new FileReader();
      reader.onload = function (event) {
        try {
          const content = JSON.parse(event.target.result);
          if (!IS_DETAILED_IMPORT_FILE_PREVIEW) {
            setConfigurationSource(CONFIGURATION_SOURCE_IMPORT);
          }
          const { title: dataRoomTitle } = getDcrProperties(
            content.dataScienceDataRoom
          );
          if (!dcrName) {
            setValue("name", dataRoomTitle);
          }
          setConfiguration(content);
        } catch (error) {
          enqueueSnackbar("Failed to import configuration", {
            context: error?.message,
            persist: true,
            variant: "error",
          });
        }
      };
      reader.readAsText(importFile);
    }
  }, [importFile]);
  useEffect(() => {
    if (open) {
      setConfigurationSource(initialConfigurationSource);
    } else {
      setConfiguration(undefined);
      setImportFile(undefined);
    }
  }, [open, initialConfigurationSource]);
  const onSubmit = ({ name }: SchemaType) => {
    onConfirm({ configuration, description: undefined, name }, () =>
      reset({ name: "" })
    );
  };
  return (
    <FormProvider {...form}>
      <Modal onClose={!loading ? onCancel : undefined} open={open}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalDialog>
            <DialogTitle>
              Start drafting an Advanced Analytics Clean Room
            </DialogTitle>
            <Divider />
            <DialogContent>
              <Stack>
                <Controller
                  control={control}
                  name="name"
                  render={({ field, fieldState }) => {
                    const { error } = fieldState;
                    return (
                      <FormControl
                        disabled={field.disabled}
                        error={Boolean(error)}
                        required={true}
                      >
                        <FormLabel>Name</FormLabel>
                        <Input
                          {...field}
                          autoComplete="off"
                          data-testid={
                            testIds.dataScienceDataRoom
                              .dataScienceDataRoomCreateDialog.nameInput
                          }
                          placeholder={`e.g. "Collaborative analytics"`}
                        />
                        <FormHelperText>{error?.message}</FormHelperText>
                      </FormControl>
                    );
                  }}
                />
                <FormControl>
                  <RadioGroup
                    onChange={changeConfigurationSource}
                    value={configurationSource}
                  >
                    {IS_FEATURE_CONFIGURATION_SOURCE_NONE_ENABLED ? (
                      <Radio
                        label="Start from scratch"
                        slotProps={radioSlotProps}
                        value={CONFIGURATION_SOURCE_NONE}
                      />
                    ) : null}
                    {IS_FEATURE_CONFIGURATION_SOURCE_IMPORT_ENABLED ? (
                      <Radio
                        data-testid={
                          testIds.dataScienceDataRoom
                            .dataScienceDataRoomCreateDialog.importConfig
                        }
                        label={
                          <span>
                            Import existing data clean room configuration
                            <span
                              style={{
                                verticalAlign: "middle",
                                whiteSpace: "nowrap",
                              }}
                            >
                              &nbsp;
                              <Tooltip
                                sx={{ maxWidth: 300 }}
                                title="Please choose a data clean room configuration JSON file. You can export a valid data clean room configuration from any existing data clean room."
                              >
                                <FontAwesomeIcon icon={faInfoCircle} />
                              </Tooltip>
                            </span>
                            {importFile ? (
                              <Tooltip
                                disableHoverListener={
                                  configurationSource !==
                                  CONFIGURATION_SOURCE_IMPORT
                                }
                                title={
                                  <div>
                                    <div>
                                      {name} will be used as the start
                                      configuration
                                    </div>
                                    <div
                                      style={{
                                        opacity: 0.75,
                                      }}
                                    >
                                      ({type}, {size} bytes,
                                      {isDateValid(lastModifiedDate)
                                        ? ` last modified on ${format(
                                            lastModifiedDate,
                                            "PPPP'\nat 'pppp"
                                          )}`
                                        : null}
                                      )
                                    </div>
                                  </div>
                                }
                              >
                                <div
                                  onClick={changeFile}
                                  style={{ marginTop: "0.5rem" }}
                                >
                                  <FontAwesomeIcon
                                    icon={faFileCheck}
                                    style={{ marginRight: "4px" }}
                                  />
                                  {name}
                                </div>
                              </Tooltip>
                            ) : IS_DETAILED_IMPORT_FILE_PREVIEW ? (
                              <div onClick={changeFile}>
                                <FontAwesomeIcon
                                  icon={faFolderOpen}
                                  style={{ marginRight: "4px" }}
                                />
                                Select file
                              </div>
                            ) : undefined}
                          </span>
                        }
                        slotProps={radioSlotProps}
                        value={CONFIGURATION_SOURCE_IMPORT}
                      />
                    ) : null}
                    {IS_FEATURE_CONFIGURATION_SOURCE_CLONE_ENABLED ? (
                      <Radio
                        disabled={true}
                        label="Clone data clean room"
                        value={CONFIGURATION_SOURCE_CLONE}
                      />
                    ) : null}
                    {IS_FEATURE_CONFIGURATION_SOURCE_TEMPLATE_ENABLED ? (
                      <Radio
                        disabled={true}
                        label="Use template"
                        value={CONFIGURATION_SOURCE_TEMPLATE}
                      />
                    ) : null}
                  </RadioGroup>
                </FormControl>
              </Stack>
            </DialogContent>
            <Divider />
            <DialogActions>
              <Button onClick={restartCreation} type="reset">
                Back
              </Button>
              <Button
                color="primary"
                data-testid={
                  testIds.dataScienceDataRoom.dataScienceDataRoomCreateDialog
                    .createButton
                }
                disabled={!dcrName || isSubmitting}
                loading={loading}
                loadingPosition="start"
                startDecorator={<FontAwesomeIcon icon={faCheck} />}
                type="submit"
                variant="solid"
              >
                Create
              </Button>
            </DialogActions>
          </ModalDialog>
        </form>
      </Modal>
    </FormProvider>
  );
};

export default DataScienceDataRoomCreateDialog;
