import { faKey } from "@fortawesome/pro-light-svg-icons";
import { memo } from "react";
import { SidebarMenuItem } from "features/sidebar";

const KeychainSidebarMenuItem = () => (
  <SidebarMenuItem
    data-testid="sidebar_keychain"
    icon={faKey}
    label="Keychain"
    to="/keychain"
    tooltipTitle="Keychain"
  />
);

KeychainSidebarMenuItem.displayName = "KeychainSidebarMenuItem";

export default memo(KeychainSidebarMenuItem);
