import { faExclamationTriangle as fatExclamationTriangle } from "@fortawesome/pro-thin-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Modal,
  ModalDialog,
  Typography,
} from "@mui/joy";
import { useCallback, useState } from "react";

interface DeleteConfirmationDialogProps {
  open: boolean;
  onCancel: () => void;
  onDelete: () => Promise<void>;
}

const DeleteConfirmationDialog: React.FC<DeleteConfirmationDialogProps> = ({
  open,
  onCancel,
  onDelete,
}) => {
  const [loading, setLoading] = useState(false);
  const handleDelete = useCallback(async () => {
    try {
      setLoading(true);
      await onDelete();
      onCancel();
    } finally {
      setLoading(false);
    }
  }, [setLoading, onDelete, onCancel]);
  return (
    <Modal onClose={loading ? undefined : onCancel} open={open}>
      <ModalDialog color="danger" role="alertdialog">
        <DialogTitle>
          <FontAwesomeIcon
            fixedWidth={true}
            icon={fatExclamationTriangle}
            size="4x"
          />
          <Typography>
            Are you sure you want to delete the advertiser audience data?
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Typography
            level="body-sm"
            sx={{ textWrap: "balance" }}
            textAlign="center"
          >
            This operation will delete all insights and audiences. Audiences
            which have previously been exported from the DCR will remain
            available.
          </Typography>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button disabled={loading} onClick={onCancel}>
            Cancel
          </Button>
          <Button
            color="danger"
            disabled={loading}
            loading={loading}
            onClick={handleDelete}
            startDecorator={<FontAwesomeIcon icon={fatExclamationTriangle} />}
            variant="solid"
          >
            Delete
          </Button>
        </DialogActions>
      </ModalDialog>
    </Modal>
  );
};

DeleteConfirmationDialog.displayName = "DeleteConfirmationDialog";

export default DeleteConfirmationDialog;
