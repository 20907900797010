import { createContext, useContext } from "react";
import { ComputeNodeConstructorMode } from "features";

export const ComputeNodesVarsContext = createContext({
  closeEditorDialog: () => {},
  closeSdgQualityReportDialog: () => {},
  collapse: (computeNodeId: string): void => {},
  collapseConfig: (computeNodeId: string): void => {},
  executionContext: "committed" as
    | "committed"
    | "development"
    | "requests"
    | "submitted_requests",
  expand: (computeNodeId: string): void => {},
  expandConfig: (computeNodeId: string): void => {},
  expandedComputeNodes: [] as string[],
  hasComputeNodeActions: false,
  hasComputeNodeParticipants: false,
  isEditorDialogOpened: (computeNodeId: string): boolean => false,
  isExpanded: (computeNodeId: string): boolean => false,
  isExpandedConfig: (computeNodeId: string): boolean => false,
  isSdgQualityReportDialogOpened: (computeNodeId: string): boolean => false,
  mode: ComputeNodeConstructorMode.ACTION,
  openEditorDialog: (computeNodeId: string): void => {},
  openSdgQualityReportDialog: (computeNodeId: string): void => {},
  permittedOnly: false,
  readOnly: false,
  toggle: (computeNodeId: string): void => {},
  toggleConfig: (computeNodeId: string): void => {},
});

export const ComputeNodesVarsProvider = ComputeNodesVarsContext.Provider;

export const useComputeNodesVars = () => useContext(ComputeNodesVarsContext);

export default useComputeNodesVars;
