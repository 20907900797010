import { faXmark } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Modal,
  ModalDialog,
} from "@mui/joy";
import { memo, useCallback } from "react";
import { MediaDataRoomSummary } from "features/legacyMediaDataRoom";
import { LookalikeMediaDataRoomSummary } from "features/mediaDataRoomV2";
import { DataRoomTypeNames } from "models";

interface MediaDataRoomViewConfigurationDialogProps {
  id: string;
  [key: string]: any;
  typename: DataRoomTypeNames;
  driverAttestationHash?: string;
}

const MediaDataRoomViewConfigurationDialog: React.FC<MediaDataRoomViewConfigurationDialogProps> =
  memo(({ id, open, onCancel, typename, driverAttestationHash }) => {
    const cancelOnClick = useCallback(() => {
      onCancel();
    }, [onCancel]);
    const cancelLabel = "Close";
    const cancelProps = {
      color: "neutral" as "neutral",
      onClick: cancelOnClick,
      variant: "plain" as "plain",
    };
    return (
      <Modal open={open}>
        <ModalDialog>
          <DialogTitle
            sx={{
              alignItems: "center",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <span>
              {typename === DataRoomTypeNames.PublishedLookalikeMediaDataRoom &&
                "Lookalike Clean Room configuration"}
              {typename === DataRoomTypeNames.PublishedMediaDataRoom &&
                "Top-Affinity & Remarketing Clean Room configuration"}
            </span>
            <IconButton onClick={onCancel}>
              <FontAwesomeIcon fixedWidth={true} icon={faXmark} />
            </IconButton>
          </DialogTitle>
          <Divider />
          <DialogContent>
            <Box p={1}>
              {typename === DataRoomTypeNames.PublishedMediaDataRoom && (
                <MediaDataRoomSummary id={id} open={open} />
              )}
              {typename ===
                DataRoomTypeNames.PublishedLookalikeMediaDataRoom && (
                <LookalikeMediaDataRoomSummary
                  driverAttestationHash={driverAttestationHash!}
                  id={id}
                  open={open}
                />
              )}
            </Box>
            <Box
              sx={{
                borderColor: "divider",
                borderTop: 1,
                display: "flex",
                justifyContent: "space-between",
                mt: "auto",
                p: 1,
              }}
            >
              <Button {...cancelProps}>{cancelLabel}</Button>
            </Box>
          </DialogContent>
        </ModalDialog>
      </Modal>
    );
  });

export default MediaDataRoomViewConfigurationDialog;
