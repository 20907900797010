import { type TypedDocumentNode, useQuery } from "@apollo/client";
import { DqLoader, DqTable } from "@decentriq/components";
import {
  type AdminPortalOrganizationUsersQuery,
  type AdminPortalOrganizationUsersQueryVariables,
  UsersDocument,
  type UsersQuery,
  type UsersQueryVariables,
} from "@decentriq/graphql/dist/types";
import { Avatar, Chip, Link, Typography } from "@mui/joy";
import { format } from "date-fns";
import get from "lodash/get";
import { type MRT_ColumnDef } from "material-react-table";
import { Fragment, memo, useMemo, useState } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { UserDetailsDialog, UsersAddButton } from "components";
import { useUserRole } from "hooks";
import { userRolePresentation } from "models";

interface UsersListProps {
  variables?: UsersQueryVariables | AdminPortalOrganizationUsersQueryVariables;
  query?: TypedDocumentNode<
    UsersQuery | AdminPortalOrganizationUsersQuery,
    UsersQueryVariables | AdminPortalOrganizationUsersQueryVariables
  >;
  dataKey?: string;
}

type User =
  | UsersQuery["users"]["nodes"][number]
  | AdminPortalOrganizationUsersQuery["organization"]["users"]["nodes"][number];
interface UserWithOrganization extends Omit<User, "organization"> {
  organizationName: string;
  organizationId: string;
}

const UsersList: React.FC<UsersListProps> = ({
  variables,
  query = UsersDocument,
  dataKey = "users",
}) => {
  const [selectedUserId, selectEditUser] = useState<string | null>(null);
  const { data: usersData, loading } = useQuery<UsersQuery>(query, {
    variables: variables as UsersQueryVariables,
  });
  const { usersList, totalCount } = useMemo<{
    usersList: UserWithOrganization[];
    totalCount: number;
  }>(() => {
    if (!usersData) {
      return {
        totalCount: 0,
        usersList: [],
      };
    }
    const usersList: UserWithOrganization[] = get(
      usersData!,
      `${dataKey}.nodes`,
      [] as User[]
    ).map(({ organization, ...user }: User) => ({
      ...user,
      organizationId: organization?.id || "",
      organizationName: organization?.name || "",
    })) as UserWithOrganization[];
    return {
      totalCount: usersList.length,
      usersList,
    };
  }, [usersData, dataKey]);
  const { isSuperAdminReadOnly, isDecentriqAdmin } = useUserRole();

  const { pathname } = useLocation();
  const isUsersPage = pathname.includes("/admin/users");
  // Additional column Organization is added and visible only for Decentriq users on Users page
  const withOrganizationColumn = isDecentriqAdmin && isUsersPage;
  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          padding: "2.5rem",
          width: "100%",
        }}
      >
        <DqLoader />
      </div>
    );
  }
  type UserForColumnDef = Omit<UserWithOrganization, "__typename">;
  const usersListColumns: MRT_ColumnDef<UserForColumnDef>[] = [
    {
      Cell: ({ cell, row }) => {
        const avatarImage = cell.getValue();
        return (
          <Avatar
            src={avatarImage ? `data:image;base64,${avatarImage}` : undefined}
          >
            {row?.original?.email?.substring(0, 2)?.toUpperCase() || ""}
          </Avatar>
        );
      },
      accessorKey: "logo",
      header: "",
      id: "avatar",
      size: 10,
    },
    {
      Cell: ({ cell, row }) => {
        const userEmail = cell.getValue<string>();
        return (
          <Typography
            component="div"
            endDecorator={
              isDecentriqAdmin && row?.original?.isDemoUser ? (
                <Chip>Demo</Chip>
              ) : undefined
            }
            level="inherit"
            noWrap={true}
            textColor="inherit"
          >
            <Typography component="div" noWrap={true}>
              {userEmail}
            </Typography>
          </Typography>
        );
      },
      Footer: <div>Total: {totalCount}</div>,
      accessorKey: "email",
      header: "Email",
      id: "email",
      minSize: 350,
    },
    {
      accessorFn: (rowData) => userRolePresentation.get(rowData?.userRole),
      header: "Role",
      id: "role",
    },
    ...(withOrganizationColumn
      ? ([
          {
            Cell: ({ cell, row }) => (
              <Link
                color="neutral"
                component={RouterLink}
                level="inherit"
                textColor="inherit"
                to={`/admin/organizations/${row?.original?.organizationId}`}
                underline="hover"
              >
                {cell.getValue<string>()}
              </Link>
            ),
            accessorKey: "organizationName",
            header: "Organization",
            id: "organization",
          },
        ] as MRT_ColumnDef<UserForColumnDef>[])
      : []),
    ...(isDecentriqAdmin
      ? ([
          {
            Cell: ({ row }) => {
              const isMigrated =
                row?.original?.migrationCompletedAt !== null ||
                row?.original?.needsMigration === false;
              if (isMigrated) {
                return <Chip color="success">Migrated</Chip>;
              }
              return null;
            },
            header: "Migration status",
            id: "migrationStatus",
          },
        ] as MRT_ColumnDef<UserForColumnDef>[])
      : []),
    {
      Cell: ({ cell }) => {
        const createdAt = cell.getValue<string>();
        return createdAt
          ? format(new Date(createdAt), "dd.MM.yyy, HH:mm")
          : "—";
      },
      accessorKey: "createdAt",
      header: "Created at",
      id: "createdAt",
    },
  ];
  return (
    <Fragment>
      <DqTable
        columns={usersListColumns}
        data={usersList}
        enableGlobalFilter={true}
        enableSorting={true}
        enableTopToolbar={true}
        initialState={{
          showGlobalFilter: true,
        }}
        localization={{
          noRecordsToDisplay: "No users found",
        }}
        muiSearchTextFieldProps={{
          placeholder: "Search users",
        }}
        muiTableBodyRowProps={({ row }) => ({
          onClick: () => selectEditUser(row?.original?.id),
          sx: {
            "& > .MuiTableCell-root:first-child": { pl: 2 },
            "& > .MuiTableCell-root:last-child": { pr: 2 },
            cursor: "pointer",
          },
        })}
        muiTableHeadRowProps={{
          sx: {
            "& > .MuiTableCell-root:first-child": { pl: 2 },
            "& > .MuiTableCell-root:last-child": {
              "& .Mui-TableHeadCell-ResizeHandle-Wrapper": {
                right: "-1rem",
              },
              pr: 2,
            },
          },
        }}
        muiTablePaperProps={{
          sx: {
            display: "flex",
            flex: 1,
            flexDirection: "column",
            height: "100%",
            overflow: "hidden",
            width: "100%",
          },
        }}
        renderTopToolbarCustomActions={() => {
          return !isUsersPage && !isSuperAdminReadOnly ? (
            <UsersAddButton />
          ) : null;
        }}
      />
      {selectedUserId ? (
        <UserDetailsDialog
          onClose={() => selectEditUser(null)}
          open={!!selectedUserId}
          userId={selectedUserId!}
        />
      ) : null}
    </Fragment>
  );
};

UsersList.displayName = "UsersList";
export default memo(UsersList);
