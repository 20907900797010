import { Button } from "@mui/joy";
import { useBoolean } from "ahooks";
import { memo, useCallback } from "react";
import { type DataRoomErrorReportContextValue } from "contexts";
import DataRoomErrorReportDialog from "./DataRoomErrorReportDialog";

interface DataRoomErrorReportButtonProps
  extends DataRoomErrorReportContextValue {
  dataRoomId: string;
  error: string;
  details: string;
  closeSnackbar: () => void;
}

const DataRoomErrorReportButton: React.FC<DataRoomErrorReportButtonProps> = ({
  dataRoomId,
  error,
  details,
  closeSnackbar,
  ...contextValue
}) => {
  const [isDialogOpen, { setFalse: closeDialog, setTrue: openDialog }] =
    useBoolean(false);
  const handleFinish = useCallback(() => {
    closeSnackbar();
    closeDialog();
  }, [closeDialog, closeSnackbar]);
  return (
    <>
      <Button
        onClick={openDialog}
        size="sm"
        sx={{
          "--Button-minHeight": "24px",
          borderRadius: "2px",
          lineHeight: 1,
          paddingInline: "0.5rem",
        }}
      >
        Report issue to Decentriq
      </Button>
      <DataRoomErrorReportDialog
        dataRoomId={dataRoomId}
        details={details}
        error={error}
        onCancel={closeDialog}
        onFinish={handleFinish}
        open={isDialogOpen}
        {...contextValue}
      />
    </>
  );
};

DataRoomErrorReportButton.displayName = "DataRoomErrorReportButton";

export default memo(DataRoomErrorReportButton);
