import { useAuth0 } from "@auth0/auth0-react";
import { usePublishedMediaInsightsDcrActionsQuery } from "@decentriq/graphql/dist/hooks";
import {
  faBan,
  faCopy,
  faEye,
  faEyeSlash,
  faGaugeMax,
  faLink,
  faListCheck,
  faStar,
} from "@fortawesome/pro-light-svg-icons";
import { faStar as fasStar } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton } from "@mui/joy";
import { useBoolean } from "ahooks";
import { format, isValid, parseISO } from "date-fns";
import { memo, useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Actions, DataRoomStopDialog } from "components";
import { DataRoomActionTypes } from "features/dataRoom";
import MediaDataRoomViewConfigurationDialog from "features/mediaDataRoom/components/MediaDataRoomViewConfigurationDialog";
import PerformanceReportDownloadDialog from "features/mediaDataRoom/components/PerformanceReportDownloadDialog/PerformanceReportDownloadDialog";
import {
  MediaDataRoomContext,
  MediaDataRoomInsightsDataContext,
} from "features/mediaDataRoom/contexts";
import {
  usePublishedDataRoomCopyLink,
  usePublishedDataRoomIsHidden,
  usePublishedDataRoomStop,
} from "features/publishedDataRoom";
import { usePublishedDataRoomIsFavorite } from "features/publishedDataRoom/components/PublishedDataRoomActions/hooks";
import {
  mapErrorToGeneralSnackbar,
  mapMediaDataRoomErrorToSnackbar,
  useCopyToClipboard,
  useDataRoomSnackbar,
  useSafeContext,
} from "hooks";
import { DataRoomTypeNames } from "models";

export enum MediaDataRoomActionTypes {
  ViewConfiguration,
  PerformanceReport,
}

interface MediaDataRoomActionsProps {
  id: string;
  withPerformanceReport?: boolean;
  [key: string]: any;
}

// Extend this array with other users if needed
const PERFORMANCE_REPORT_ALLOWED_USERS = ["model.quality@decentriq.com"];

const MediaDataRoomActions: React.FC<MediaDataRoomActionsProps> = memo(
  ({
    id,
    withPerformanceReport,
    actions: actionsFunc = (actions: any) => actions,
    inline = false,
    moreIcon,
  }) => {
    const { user: { email: currentUserEmail = "" } = {} } = useAuth0();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useDataRoomSnackbar();
    const [
      isMediaDataRoomViewConfigurationDialogOpen,
      {
        setTrue: openMediaDataRoomViewConfigurationDialog,
        setFalse: closeMediaDataRoomViewConfigurationDialog,
      },
    ] = useBoolean(false);
    const [
      isPerformanceReportDownloadDialogOpen,
      {
        setTrue: openPerformanceReportDownloadDialog,
        setFalse: closePerformanceReportDownloadDialog,
      },
    ] = useBoolean(false);
    const { data, loading: isDataRoomLoading } =
      usePublishedMediaInsightsDcrActionsQuery({
        variables: {
          id,
        },
      });
    const createdAt = data?.publishedMediaInsightsDcr?.createdAt;
    const name = data?.publishedMediaInsightsDcr?.name;
    const owner = data?.publishedMediaInsightsDcr?.owner;
    const isStopped = data?.publishedMediaInsightsDcr?.isStopped;

    const publishedMediaDcr = useSafeContext(MediaDataRoomContext, true);
    const mediaDcrData = useSafeContext(MediaDataRoomInsightsDataContext, true);

    const hideStopButton = useMemo(() => {
      return isStopped || !publishedMediaDcr || publishedMediaDcr.isObserver;
    }, [isStopped, publishedMediaDcr]);

    const hidePerformanceReportButton = useMemo(() => {
      return (
        !withPerformanceReport ||
        !PERFORMANCE_REPORT_ALLOWED_USERS.includes(currentUserEmail) ||
        !mediaDcrData?.datasets?.data?.hasRequiredData ||
        isStopped ||
        !publishedMediaDcr?.supportedFeatures?.performanceReportEnabled ||
        !publishedMediaDcr?.features?.lookalike
      );
    }, [
      currentUserEmail,
      withPerformanceReport,
      mediaDcrData?.datasets?.data?.hasRequiredData,
      isStopped,
      publishedMediaDcr?.supportedFeatures?.performanceReportEnabled,
      publishedMediaDcr?.features?.lookalike,
    ]);

    const publishedAtDate = parseISO(createdAt);

    // Copy link to data clean room
    const { publishedDataRoomCopyLink } = usePublishedDataRoomCopyLink({
      __typename: DataRoomTypeNames.PublishedMediaInsightsDcr,
      id,
    });

    const dataRoomCopyLink = useCallback(
      () =>
        publishedDataRoomCopyLink()
          .then((wasCopied) => {
            if (wasCopied) {
              enqueueSnackbar("Data clean room link copied to the clipboard.");
            }
          })
          .catch((error) => {
            enqueueSnackbar(
              ...mapMediaDataRoomErrorToSnackbar(
                error,
                "Data clean room link could not be generated."
              )
            );
          }),
      [publishedDataRoomCopyLink, enqueueSnackbar]
    );
    // Favorite/unfavorite data clean room
    const {
      publishedDataRoomIsFavorite: isFavorite,
      publishedDataRoomIsFavoriteLoading: isDataRoomIsFavoriteLoading,
      setPublishedDataRoomIsFavorite: setIsFavorite,
      setPublishedDataRoomIsFavoriteLoading: isSetDataRoomIsFavoriteLoading,
    } = usePublishedDataRoomIsFavorite({
      __typename: DataRoomTypeNames.PublishedMediaInsightsDcr,
      id,
      skip: isDataRoomLoading,
    });
    const toggleDataRoomIsFavorite = () =>
      setIsFavorite(!isFavorite)
        .then(() => {
          enqueueSnackbar(
            `Data clean room was ${
              !isFavorite ? "added to" : "removed from"
            } Favorites`
          );
        })
        .catch((error) => {
          enqueueSnackbar(
            ...mapErrorToGeneralSnackbar(
              error,
              `Data clean room could not be ${
                !isFavorite ? "added to" : "removed from"
              } Favorites`
            )
          );
        });
    // Archive/restore (hide/unhide) data clean room
    const {
      isHidden,
      loading: isDataRoomIsHiddenLoading,
      setIsHidden,
      setting: isSetDataRoomIsHiddenLoading,
    } = usePublishedDataRoomIsHidden({
      __typename: DataRoomTypeNames.PublishedMediaInsightsDcr,
      id,
      skip: isDataRoomLoading,
    });

    const toggleDataRoomIsHidden = useCallback(
      () =>
        setIsHidden(!isHidden)
          .then(() => {
            if (!isHidden) {
              navigate("/datarooms");
            }
            enqueueSnackbar(
              `Data clean room was ${!isHidden ? "hidden" : "unhidden"}`
            );
          })
          .catch((error) => {
            enqueueSnackbar(
              ...mapMediaDataRoomErrorToSnackbar(
                error,
                `Can't ${!isHidden ? "hide" : "unhide"} data clean room`
              )
            );
          }),
      [navigate, enqueueSnackbar, isHidden, setIsHidden]
    );
    // Stop data clean room
    const [
      isDataRoomStopDialogOpen,
      { setTrue: openDataRoomStopDialog, setFalse: closeDataRoomStopDialog },
    ] = useBoolean(false);
    const { isDataRoomStopLoading, stopDataRoomMutation } =
      usePublishedDataRoomStop({
        __typename: DataRoomTypeNames.PublishedMediaInsightsDcr,
        dataRoomId: id,
      });
    const dataRoomStop = useCallback(() => {
      stopDataRoomMutation()
        .then(() => {
          enqueueSnackbar("Data clean room was stopped");
        })
        .catch((error) => {
          enqueueSnackbar(
            ...mapMediaDataRoomErrorToSnackbar(
              error,
              "Can't stop data clean room"
            )
          );
        })
        .finally(() => closeDataRoomStopDialog());
    }, [closeDataRoomStopDialog, enqueueSnackbar, stopDataRoomMutation]);
    // Copy ID
    const [, copyIdToClipboard] = useCopyToClipboard();
    const handleCopyIdToClipboard = useCallback(
      (hash: string, message: string) => {
        if (hash) {
          copyIdToClipboard(hash);
          enqueueSnackbar(message);
        }
      },
      [copyIdToClipboard, enqueueSnackbar]
    );
    const actions = actionsFunc({
      buttons: [
        {
          component: IconButton,
          disabled: isDataRoomLoading || isDataRoomIsFavoriteLoading,
          hidden: false,
          icon: isFavorite ? fasStar : faStar,
          loading:
            isDataRoomIsFavoriteLoading || isSetDataRoomIsFavoriteLoading,
          onClick: toggleDataRoomIsFavorite,
          square: true,
          tooltipPlacement: "bottom-end",
          tooltipTitle: isFavorite
            ? "Remove from Favorites"
            : "Add to Favorites",
          type: DataRoomActionTypes.ToggleFavorite,
        },
        {
          component: IconButton,
          disabled: isDataRoomLoading || isDataRoomIsHiddenLoading,
          hidden: !isHidden,
          hover: {
            icon: faEye,
            isRed: false,
            onClick: toggleDataRoomIsHidden,
            tooltipTitle: "Unhide",
          },
          icon: faEyeSlash,
          isRed: false,
          loading: isDataRoomIsHiddenLoading || isSetDataRoomIsHiddenLoading,
          onClick: toggleDataRoomIsHidden,
          square: true,
          tooltipPlacement: "bottom-end",
          tooltipTitle: "Hidden",
          type: DataRoomActionTypes.RestoreOrDelete,
        },
      ],
      menuLists: [
        [
          {
            disabled: isDataRoomLoading || isDataRoomIsFavoriteLoading,
            hidden: false,
            icon: isFavorite ? fasStar : faStar,
            loading:
              isDataRoomIsFavoriteLoading || isSetDataRoomIsFavoriteLoading,
            name: isFavorite ? "Remove from Favorites" : "Add to Favorites",
            onClick: toggleDataRoomIsFavorite,
            type: DataRoomActionTypes.ToggleFavorite,
          },
          {
            disabled: false,
            icon: faLink,
            name: "Copy link",
            onClick: dataRoomCopyLink,
            type: DataRoomActionTypes.CopyLink,
          },
          {
            disabled: false,
            icon: faListCheck,
            isRed: false,
            name: "View configuration",
            onClick: openMediaDataRoomViewConfigurationDialog,
            tooltipTitle: "View configuration",
            type: MediaDataRoomActionTypes.ViewConfiguration,
          },
          {
            disabled: isDataRoomLoading || isDataRoomIsHiddenLoading,
            icon: isHidden ? faEye : faEyeSlash,
            isRed: false,
            loading: isDataRoomIsHiddenLoading || isSetDataRoomIsHiddenLoading,
            name: isHidden ? "Unhide" : "Hide",
            onClick: toggleDataRoomIsHidden,
            type: DataRoomActionTypes.HideRestoreOrDelete,
          },
          {
            hidden: hidePerformanceReportButton,
            icon: faGaugeMax,
            name: "Performance report",
            onClick: openPerformanceReportDownloadDialog,
            type: MediaDataRoomActionTypes.PerformanceReport,
          },
          {
            disabled: false,
            hidden: hideStopButton,
            icon: faBan,
            isRed: true,
            loading: false,
            name: "Stop data clean room",
            onClick: openDataRoomStopDialog,
            type: DataRoomActionTypes.Stop,
          },
        ],
        [
          {
            name: (
              <div
                style={{
                  fontSize: "0.8125rem",
                  lineHeight: "1.1875rem",
                  opacity: 0.75,
                  overflow: "auto",
                  whiteSpace: "pre",
                }}
              >
                {isValid(publishedAtDate) ? (
                  <div>
                    <div style={{ marginTop: 4 }}>
                      <strong>Published by</strong>
                    </div>
                    <div style={{ fontWeight: 500 }}>{owner?.email}</div>
                    <div>
                      on {format(publishedAtDate, "dd-MM-yyyy HH:mm:ss")}
                    </div>
                  </div>
                ) : null}
                {/* TODO: refactor this to extract common components into shared */}
                {id ? (
                  <>
                    <div style={{ marginTop: 4 }}>
                      <strong>Data clean room ID</strong>
                    </div>
                    <div
                      style={{
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        style={{
                          maxWidth: 100,
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                        title={id}
                      >
                        {id}
                      </div>
                      <div
                        onClick={() =>
                          handleCopyIdToClipboard(
                            id!,
                            "Data clean room ID copied to clipboard"
                          )
                        }
                        style={{
                          alignItems: "center",
                          cursor: "pointer",
                          display: "flex",
                        }}
                      >
                        <FontAwesomeIcon
                          fixedWidth={true}
                          icon={faCopy}
                          style={{
                            marginRight: "0.25rem",
                          }}
                        />{" "}
                        Copy
                      </div>
                    </div>
                  </>
                ) : null}
              </div>
            ),
            type: DataRoomActionTypes.Details,
          },
        ],
      ],
    });
    return (
      <Actions
        actions={actions}
        inline={inline}
        moreIcon={moreIcon}
        moreTooltipTitle=""
      >
        <MediaDataRoomViewConfigurationDialog
          dataRoomId={id}
          onCancel={closeMediaDataRoomViewConfigurationDialog}
          open={isMediaDataRoomViewConfigurationDialogOpen}
        />

        <DataRoomStopDialog
          loading={isDataRoomStopLoading}
          name={name}
          onCancel={closeDataRoomStopDialog}
          onConfirm={dataRoomStop}
          open={isDataRoomStopDialogOpen}
        />

        {withPerformanceReport && (
          <PerformanceReportDownloadDialog
            dataRoomId={id}
            onCancel={closePerformanceReportDownloadDialog}
            open={isPerformanceReportDownloadDialogOpen}
          />
        )}
      </Actions>
    );
  }
);

export default MediaDataRoomActions;
