import { ClientApiError } from "@decentriq/core";
import { useQuery } from "@tanstack/react-query";
import { type media_insights_request } from "ddc";
import { loadAsync } from "jszip";
import { type SnackbarKey } from "notistack";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useApiCore } from "contexts";
import { mapMediaDataRoomErrorToSnackbar, useDataRoomSnackbar } from "hooks";
import { type ActivatedAudience } from "wrappers/ApolloWrapper/resolvers/LookalikeMediaMutations";
import { useMediaInsightsDcrData } from "../../contexts/MediaInsightsDcrContext/MediaInsightsDcrContext";
import { type Audience } from "../../models";

interface MediaDataRoomPublishedAudiencesHookPayload {
  dataRoomId: string;
  driverAttestationHash: string;
  skip: boolean;
  hasRequiredData: boolean;
}
interface ActivatedAudiencesConfigWrapper {
  advertiser_manifest_hash: string;
  activated_audiences: ActivatedAudience[];
}
interface MediaDataRoomPublishedAudiencesHookResult {
  error?: string;
  loading: boolean;
  audiences: Audience[];
  retry: () => Promise<void>;
}

const useMediaDataRoomPublishedAudiences = ({
  dataRoomId,
  driverAttestationHash,
  skip,
  hasRequiredData,
}: MediaDataRoomPublishedAudiencesHookPayload): MediaDataRoomPublishedAudiencesHookResult => {
  const { enqueueSnackbar } = useDataRoomSnackbar();
  const { client, sessionManager } = useApiCore();
  const setErrorSnackbarId = useState<SnackbarKey | undefined>()[1];

  const { advertiserDatasetHash } = useMediaInsightsDcrData();

  const publishedAudiencesQuery = useQuery({
    enabled:
      !skip && !!dataRoomId && !!driverAttestationHash && hasRequiredData,
    queryFn: async () => {
      const [scopeId, session] = await Promise.all([
        client.ensureDcrDataScope(dataRoomId),
        sessionManager.get({
          driverAttestationHash,
        }),
      ]);
      if (
        advertiserDatasetHash === null ||
        advertiserDatasetHash === undefined
      ) {
        throw new Error("Missing advertiser dataset hash");
      }
      const request: media_insights_request.MediaInsightsRequest = {
        getAudiencesForPublisher: {
          dataRoomIdHex: dataRoomId,
          scopeIdHex: scopeId,
        },
      };
      const response = await session.sendMediaInsightsRequest(request);
      if (!("getAudiencesForPublisher" in response)) {
        throw new Error("Expected getAudiencesForPublisher response");
      }
      const computeNodeName = response.getAudiencesForPublisher.computeNodeName;
      const jobIdHex = response.getAudiencesForPublisher.jobIdHex;
      const result = await session.getComputationResult(
        { computeNodeId: computeNodeName, jobId: jobIdHex },
        { interval: 1 }
      );
      const zip = await loadAsync(result);
      const activatedAudiencesFile = zip.file("activated_audiences.json");
      if (activatedAudiencesFile === null) {
        throw new Error("activated_audiences.json not found in zip");
      }
      // TODO zod schema?
      const activatedAudiences: ActivatedAudiencesConfigWrapper = JSON.parse(
        await activatedAudiencesFile.async("string")
      );
      if (
        activatedAudiences.advertiser_manifest_hash !== advertiserDatasetHash
      ) {
        return [];
      } else {
        return activatedAudiences.activated_audiences.map(
          ({ audience_type, reach, is_published, activation_type }) => ({
            activationType: activation_type,
            audienceType: audience_type,
            id: `${audience_type}-${reach}`,
            published: is_published,
            reach,
          })
        );
      }
    },
    // Only keyed by the advertiserdataset hash, could add publisher dataset hash as required
    queryKey: ["mi-dcr-published-audiences", dataRoomId, advertiserDatasetHash],
  });

  useEffect(() => {
    if (publishedAudiencesQuery.error) {
      setErrorSnackbarId(
        enqueueSnackbar(
          ...mapMediaDataRoomErrorToSnackbar(
            publishedAudiencesQuery.error,
            "Unable to fetch audiences"
          )
        )
      );
    } else {
      setErrorSnackbarId((snackbarId) => {
        if (snackbarId) {
          return undefined;
        }
        return snackbarId;
      });
    }
  }, [enqueueSnackbar, publishedAudiencesQuery.error, setErrorSnackbarId]);

  return {
    audiences: useMemo(
      () => publishedAudiencesQuery.data ?? [],
      [publishedAudiencesQuery.data]
    ),
    error: useMemo(() => {
      const { error } = publishedAudiencesQuery;
      if (error) {
        if (error instanceof ClientApiError) {
          return error.message;
        }
        return error.toString();
      }
    }, [publishedAudiencesQuery]),
    loading:
      publishedAudiencesQuery.isLoading || publishedAudiencesQuery.isFetching,
    retry: useCallback(async () => {
      await publishedAudiencesQuery.refetch();
    }, [publishedAudiencesQuery]),
  };
};

export default useMediaDataRoomPublishedAudiences;
