export interface ChainedPromiseResult<R> {
  isSuccess?: boolean;
  result: R | null;
  error?: unknown;
}

export type ChainedPromisesOutcome<R> = {
  isSuccess: boolean;
  results: ChainedPromiseResult<R>[];
  firstError?: unknown;
};

export const chainPromises = <P, R = unknown>(
  payloads: P[],
  promiseCreator: (payload: P, index: number) => Promise<R>,
  shouldCancelOnError = true
) =>
  new Promise<ChainedPromisesOutcome<R>>((res, rej) => {
    const results: ChainedPromiseResult<R>[] = [];
    let isRejected = false;
    payloads
      .reduce(
        (acc, v, i) =>
          acc.then(() =>
            !isRejected
              ? promiseCreator(v, i)
                  .then((r) => {
                    results[i] = {
                      isSuccess: true,
                      result: r,
                    };
                  })
                  .catch((error) => {
                    results[i] = {
                      error,
                      isSuccess: false,
                      result: null,
                    };
                    if (shouldCancelOnError) {
                      isRejected = true;
                      const outcome: ChainedPromisesOutcome<R> = {
                        firstError: error,
                        isSuccess: false,
                        results,
                      };
                      rej(outcome);
                    }
                  })
              : Promise.resolve()
          ),
        Promise.resolve()
      )
      .then(() => {
        const numberOfSuccessResults = results.filter(
          (r) => r.isSuccess
        ).length;
        const isSuccess = payloads.length === numberOfSuccessResults;
        const outcome: ChainedPromisesOutcome<R> = {
          firstError: isSuccess
            ? undefined
            : results.find((r) => !r.isSuccess)?.error,
          isSuccess,
          results,
        };
        res(outcome);
      });
  });

export const executePromiseSafely = async <T = unknown>(
  promiseCreator: () => Promise<T>
): Promise<[boolean, T]> => {
  try {
    const result = await promiseCreator();
    return [true, result];
  } catch (error) {
    return [false, error];
  }
};
