import { createContext, useContext } from "react";
import { type MediaDataRoomJobHookResult } from "features/mediaDataRoom/hooks";
import {
  type RulesBasedAudienceRulesTree,
  type RulesBasedAudienceSeedOption,
} from "../models";

export interface RulesBasedAdvertiserAudienceGeneratorContextValue {
  rulesTree: RulesBasedAudienceRulesTree | undefined;
  onRulesTreeChanged: (rulesTree: RulesBasedAudienceRulesTree) => void;
  audienceName: string;
  seedOptions: MediaDataRoomJobHookResult<RulesBasedAudienceSeedOption[]>;
  isConfigurationDataLoading: boolean;
  setAudienceName: (audienceName: string) => void;
  estimatedAudienceSize: number | null;
  isGenerationProcessing: boolean;
  generateAudience: () => void;
  estimateAudienceSize: () => Promise<void>;
  validateRulesTree: () => boolean;
  isEstimatingAudienceSize: boolean;
}

export const RulesBasedAdvertiserAudienceGeneratorContext =
  createContext<RulesBasedAdvertiserAudienceGeneratorContextValue>({
    audienceName: "",
    estimateAudienceSize: async () => {},
    estimatedAudienceSize: null,
    generateAudience: () => {},
    isConfigurationDataLoading: false,
    isEstimatingAudienceSize: false,
    isGenerationProcessing: false,
    onRulesTreeChanged: () => {},
    rulesTree: undefined,
    seedOptions: {
      computeResults: [],
      error: undefined,
      loading: false,
      retry: async () => {},
      setCacheData: () => {},
      status: "COMPLETED",
    },
    setAudienceName: () => {},
    validateRulesTree: () => true,
  });

export const RulesBasedAdvertiserAudienceGeneratorProvider =
  RulesBasedAdvertiserAudienceGeneratorContext.Provider;

export const useRulesBasedAdvertiserAudienceGenerator = () =>
  useContext(RulesBasedAdvertiserAudienceGeneratorContext);
