import {
  DialogContent,
  DialogTitle,
  Divider,
  Modal,
  ModalDialog,
} from "@mui/joy";
import { DataLabsWrapper } from "features/dataLabs";
import DataLabConnectionTable from "./DataLabConnectionTable";

interface DataLabConnectDialogProps {
  dataRoomId: string;
  open: boolean;
  onCancel: () => void;
  retrieveDatasets: () => Promise<void>;
}

const DataLabConnectDialog: React.FC<DataLabConnectDialogProps> = ({
  dataRoomId,
  open,
  onCancel,
  retrieveDatasets,
}) => {
  return (
    <Modal onClose={onCancel} open={open}>
      <ModalDialog>
        <DialogTitle>Provision datalab</DialogTitle>
        <Divider />
        <DialogContent>
          <DataLabsWrapper>
            <DataLabConnectionTable
              dataRoomId={dataRoomId}
              onCancel={onCancel}
              retrieveDatasets={retrieveDatasets}
            />
          </DataLabsWrapper>
        </DialogContent>
      </ModalDialog>
    </Modal>
  );
};

DataLabConnectDialog.displayName = "DataLabConnectDialog";

export default DataLabConnectDialog;
