import {
  faEllipsisV as falEllipsisV,
  faFileExport as falFileExport,
  faShare as falShare,
  faTrashCan as falTrashCan,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Dropdown,
  IconButton,
  ListItemDecorator,
  Menu,
  MenuButton,
  MenuItem,
} from "@mui/joy";
import { Fragment, memo, useCallback, useState } from "react";
import { DatasetDelete, DatasetExport } from "features/datasets/containers";
import { DatasetPermissionConsumer } from "features/datasets/contexts";
import { DatasetPermissionWrapper } from "features/datasets/wrappers";

interface DatasetActionsMenuProps {
  manifestHash: string;
}

const DatasetActionsMenu = memo<DatasetActionsMenuProps>(({ manifestHash }) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleOpenChange = useCallback(
    (event: React.SyntheticEvent | null, isOpen: boolean) => {
      setIsOpen(isOpen);
    },
    [setIsOpen]
  );
  return (
    <DatasetDelete
      manifestHash={manifestHash}
      renderTrigger={(openDeleteDialog) => (
        <DatasetExport
          manifestHash={manifestHash}
          renderTrigger={(openExportDialog) => (
            <Dropdown onOpenChange={handleOpenChange} open={isOpen}>
              <MenuButton
                endDecorator={null}
                slotProps={{
                  root: { color: "neutral" },
                }}
                slots={{ root: IconButton }}
              >
                <FontAwesomeIcon icon={falEllipsisV} />
              </MenuButton>
              <DatasetPermissionWrapper
                manifestHash={manifestHash}
                skipFetching={!isOpen}
              >
                <Menu placement="bottom-end">
                  <DatasetPermissionConsumer>
                    {(contextValue) => {
                      const {
                        openShareDialog,
                        canDeleteDatasetOrDefault,
                        canExportDatasetOrDefault,
                        canShareDatasetOrDefault,
                      } = contextValue!;
                      const canExportDataset = canExportDatasetOrDefault(true);
                      const canShareDataset = canShareDatasetOrDefault(false);
                      const canDeleteDataset = canDeleteDatasetOrDefault(true);
                      return (
                        <Fragment>
                          {canExportDataset && (
                            <MenuItem onClick={openExportDialog}>
                              <ListItemDecorator>
                                <FontAwesomeIcon icon={falFileExport} />
                              </ListItemDecorator>
                              Export dataset
                            </MenuItem>
                          )}
                          {canShareDataset && (
                            <MenuItem onClick={openShareDialog}>
                              <ListItemDecorator>
                                <FontAwesomeIcon icon={falShare} />
                              </ListItemDecorator>
                              Share dataset
                            </MenuItem>
                          )}
                          {canDeleteDataset && (
                            <MenuItem color="danger" onClick={openDeleteDialog}>
                              <ListItemDecorator>
                                <FontAwesomeIcon icon={falTrashCan} />
                              </ListItemDecorator>
                              Delete dataset
                            </MenuItem>
                          )}
                        </Fragment>
                      );
                    }}
                  </DatasetPermissionConsumer>
                </Menu>
              </DatasetPermissionWrapper>
            </Dropdown>
          )}
        />
      )}
    />
  );
});

DatasetActionsMenu.displayName = "DatasetActionsMenu";

export default DatasetActionsMenu;
