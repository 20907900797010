import { createContext, useContext } from "react";

export interface DataLabErrorReportContextValue {
  dataLabId: string;
  getConfiguration: () => Promise<string>;
}

const DataLabErrorReportContext = createContext<DataLabErrorReportContextValue>(
  {
    dataLabId: "__default__",
    getConfiguration: async () => "",
  }
);

export const DataLabErrorReportProvider = DataLabErrorReportContext.Provider;

export const useDataLabErrorReport = () =>
  useContext(DataLabErrorReportContext);
