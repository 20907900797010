import { Box, styled } from "@mui/joy";

const AudienceGeneratorStepsWrapper = styled(Box)(({ theme }) => ({
  height: `calc(100% + ${theme.spacing(8)})`,
  margin: theme.spacing(-4),
  overflowY: "auto",
  padding: theme.spacing(4),
  paddingBottom: theme.spacing(13),
  position: "relative",
}));

export default AudienceGeneratorStepsWrapper;
