import { useOrganizationDomainsQuery } from "@decentriq/graphql/dist/hooks";

// Checks whether company with specified domains is Decentriq
const useIsDecentriqOrganization = (organizationId: string) => {
  const { data } = useOrganizationDomainsQuery({
    skip: !organizationId,
    variables: { organizationId },
  });

  const organizationDomains = data?.organization?.domains;

  const isDecentriqOrganization = organizationDomains?.some(
    (domain) => domain === "decentriq.ch" || domain === "decentriq.com"
  );

  return isDecentriqOrganization;
};

export default useIsDecentriqOrganization;
