import { memo } from "react";
import {
  MediaDataRoomCreationStep,
  useCreationWizardStepper,
} from "features/mediaDataRoom/contexts";
import {
  CollaborationConfigurationStep,
  CollaborationRequestStep,
  SelectDataPartnerStep,
  SelectDataSourceStep,
  SelectOrganizationRoleStep,
  SelectPublisherStep,
} from "./components";

const MediaDataRoomCreationWizard: React.FC = () => {
  const { activeStep } = useCreationWizardStepper();
  return (
    <>
      {activeStep === MediaDataRoomCreationStep.SELECT_ORGANIZATION_ROLE && (
        <SelectOrganizationRoleStep />
      )}
      {activeStep === MediaDataRoomCreationStep.SELECT_PUBLISHER && (
        <SelectPublisherStep />
      )}
      {activeStep === MediaDataRoomCreationStep.SELECT_DATA_SOURCE && (
        <SelectDataSourceStep />
      )}
      {activeStep === MediaDataRoomCreationStep.SELECT_DATA_PARTNER && (
        <SelectDataPartnerStep />
      )}
      {activeStep === MediaDataRoomCreationStep.COLLABORATION_CONFIGURATION && (
        <CollaborationConfigurationStep />
      )}
      {activeStep ===
        MediaDataRoomCreationStep.COLLABORATION_REQUEST_TO_DATA_PARTNER && (
        <CollaborationRequestStep />
      )}
      {activeStep ===
        MediaDataRoomCreationStep.COLLABORATION_REQUEST_TO_PUBLISHER && (
        <CollaborationRequestStep />
      )}
    </>
  );
};
MediaDataRoomCreationWizard.displayName = "MediaDataRoomCreationWizard";

export default memo(MediaDataRoomCreationWizard);
