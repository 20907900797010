import { useContext } from "react";

interface SafeContextHook {
  <V>(context: React.Context<V | null>, skipContextCheck?: false): V;
  <V>(context: React.Context<V | null>, skipContextCheck: true): V | null;
}

const useSafeContext: SafeContextHook = (context, skipContextCheck) => {
  const contextValue = useContext(context);
  if (contextValue === null && !skipContextCheck) {
    throw new Error(
      `${context.displayName || "Unknown context"} is not provided. This hook needs to be used under Provider`
    );
  }
  return contextValue;
};

export default useSafeContext;
