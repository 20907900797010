import { testIds } from "@decentriq/utils";
import { faCaretDown, faCaretRight } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton, Tooltip } from "@mui/joy";
import { memo, useCallback } from "react";
import { useComputeNodesVars } from "contexts";

interface ComputeNodeExpandButtonProps {
  computeNodeId: string;
  className?: string;
}

const ComputeNodeExpandButton: React.FC<ComputeNodeExpandButtonProps> = memo(
  ({ computeNodeId }) => {
    const { isExpanded, toggle } = useComputeNodesVars();
    const expandComputeNode = useCallback(
      (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        toggle(computeNodeId);
      },
      [computeNodeId, toggle]
    );
    return (
      <Tooltip title={isExpanded(computeNodeId) ? "Collapse" : "Expand"}>
        <IconButton
          data-testid={testIds.computeNode.computeNodeCreator.toggleEditor}
          onClick={expandComputeNode}
        >
          <FontAwesomeIcon
            fixedWidth={true}
            icon={isExpanded(computeNodeId) ? faCaretDown : faCaretRight}
          />
        </IconButton>
      </Tooltip>
    );
  }
);
ComputeNodeExpandButton.displayName = "ComputeNodeExpandButton";

export default ComputeNodeExpandButton;
