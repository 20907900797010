import { useValidateDataRoomRequestMutation } from "@decentriq/graphql/dist/hooks";
import { useCallback } from "react";
import { usePublishedDataRoom } from "contexts";
import { mapDataRoomErrorToSnackbar, useDataRoomSnackbar } from "hooks";

const useValidateRequest = (requestId: string) => {
  const { enqueueSnackbar } = useDataRoomSnackbar();
  const { dataRoomId: dcrHash, driverAttestationHash } = usePublishedDataRoom();
  const [validateRequestMutation, { loading }] =
    useValidateDataRoomRequestMutation({
      onError: (error) => {
        enqueueSnackbar(
          ...mapDataRoomErrorToSnackbar(
            error,
            `The computation could not be run.`
          )
        );
      },
      variables: {
        input: {
          autoFetching: true,
          dcrHash,
          driverAttestationHash,
          id: requestId,
        },
      },
    });
  const validateRequest = useCallback(
    async () => validateRequestMutation(),
    [validateRequestMutation]
  );
  return {
    loading,
    validateRequest,
  };
};

export default useValidateRequest;
