import { WorkerTypeShortName } from "@decentriq/graphql/dist/types";
import { faTriangleExclamation } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Chip, Stack, Tooltip } from "@mui/joy";
import { Fragment, memo } from "react";
import { NodeTooltip } from "components";
import { useComputeNodesVars, useDataRoom } from "contexts";
import { ComputeNodeActions } from "features/computeNode";
import useComputeNodeType from "../ComputeNodeType/useComputeNodeType";
import {
  ComputeNodeNameEditor,
  ComputeNodeParticipants,
  ComputeNodeType,
} from "../";

interface ComputeNodeToolbarProps {
  computeNodeId: string;
}

const ComputeNodeToolbar: React.FC<ComputeNodeToolbarProps> = memo(
  ({ computeNodeId }) => {
    const {
      hasComputeNodeActions,
      hasComputeNodeParticipants,
      executionContext,
      readOnly,
    } = useComputeNodesVars();
    const { isPublished } = useDataRoom();
    const isInteractivity =
      executionContext === "development" || executionContext === "requests";
    const computeNodeWithType = useComputeNodeType(computeNodeId);
    const tooltip =
      computeNodeWithType?.computationType === WorkerTypeShortName.Python ||
      computeNodeWithType?.computationType === WorkerTypeShortName.R ||
      computeNodeWithType?.computationType === WorkerTypeShortName.Synthetic ||
      computeNodeWithType?.computationType === WorkerTypeShortName.Sqlite
        ? `${computeNodeId}_container`
        : computeNodeId;
    return (
      <Fragment>
        <Stack alignItems="center" direction="row" sx={{ flex: 1, order: 2 }}>
          {isPublished && !isInteractivity ? (
            <NodeTooltip nodeType="compute" value={tooltip}>
              <ComputeNodeType computeNodeId={computeNodeId} />
              <span onClick={(event) => event.stopPropagation()}>
                <ComputeNodeNameEditor computeNodeId={computeNodeId} />
              </span>
            </NodeTooltip>
          ) : (
            <Fragment>
              <ComputeNodeType computeNodeId={computeNodeId} />
              <span onClick={(event) => event.stopPropagation()}>
                <ComputeNodeNameEditor computeNodeId={computeNodeId} />
              </span>
            </Fragment>
          )}
        </Stack>
        <Stack alignItems="center" direction="row" sx={{ order: 3 }}>
          {hasComputeNodeParticipants ? (
            <ComputeNodeParticipants
              computeNodeId={computeNodeId}
              readOnly={readOnly}
            />
          ) : null}
          {computeNodeWithType?.usesCustomPythonEnv && (
            <Tooltip
              placement="top"
              title="This data clean room was created with a custom Python environment. As this feature is not yet supported in the UI, ask the creator of the data clean room to know which packages were installed."
            >
              <Chip
                color="warning"
                startDecorator={
                  <FontAwesomeIcon icon={faTriangleExclamation} />
                }
                variant="outlined"
              >
                Custom Python environment
              </Chip>
            </Tooltip>
          )}
          {hasComputeNodeActions ? (
            <ComputeNodeActions computeNodeId={computeNodeId} />
          ) : null}
        </Stack>
      </Fragment>
    );
  }
);
ComputeNodeToolbar.displayName = "ComputeNodeToolbar";

export default ComputeNodeToolbar;
