import {
  type MutationFunction,
  type MutationFunctionOptions,
} from "@apollo/client";
import {
  type MatchNodeCreateMutationOptions,
  type PostNodeCreateMutationOptions,
  type PreviewNodeCreateMutationOptions,
  type S3NodeCreateMutationOptions,
  type ScriptingNodeCreateMutationOptions,
  type SqliteNodeCreateMutationOptions,
  type SqlNodeCreateMutationOptions,
  type SyntheticNodeCreateMutationOptions,
  useMatchNodeCreateMutation,
  usePostNodeCreateMutation,
  usePreviewNodeCreateMutation,
  useS3NodeCreateMutation,
  useScriptingNodeCreateMutation,
  useSqliteNodeCreateMutation,
  useSqlNodeCreateMutation,
  useSyntheticNodeCreateMutation,
} from "@decentriq/graphql/dist/hooks";
import {
  type CreateDraftScriptingNodeInput,
  ScriptingLanguage,
  WorkerTypeShortName,
} from "@decentriq/graphql/dist/types";
import { useCallback } from "react";
import { DEFAULT_SCRIPT } from "models";

type ComputeNodeCreateMutationOptions =
  | MatchNodeCreateMutationOptions
  | PostNodeCreateMutationOptions
  | PreviewNodeCreateMutationOptions
  | ScriptingNodeCreateMutationOptions
  | SqlNodeCreateMutationOptions
  | SqliteNodeCreateMutationOptions
  | SyntheticNodeCreateMutationOptions
  | S3NodeCreateMutationOptions;

const scriptingComputeBaseInput = {
  initialScript: {
    isMainScript: true,
    name: "Main Script",
  },
  // By default, output folder should be /output and the logic of its changing highly unlikely will be available on th UI
  output: "/output",
};

const useComputeNodeCreateMutation = (
  mutationOptions: ComputeNodeCreateMutationOptions = {}
) => {
  const [joinComputeNodeCreateMutation] = useMatchNodeCreateMutation(
    mutationOptions as MatchNodeCreateMutationOptions
  );
  const [sqlComputeNodeCreateMutation] = useSqlNodeCreateMutation(
    mutationOptions as SqlNodeCreateMutationOptions
  );
  const [sqliteComputeNodeCreateMutation] = useSqliteNodeCreateMutation(
    mutationOptions as SqliteNodeCreateMutationOptions
  );
  const [postComputeNodeCreateMutation] = usePostNodeCreateMutation(
    mutationOptions as PostNodeCreateMutationOptions
  );
  const [previewComputeNodeCreateMutation] = usePreviewNodeCreateMutation(
    mutationOptions as PreviewNodeCreateMutationOptions
  );
  const [scriptingComputeNodeCreateMutation] = useScriptingNodeCreateMutation(
    mutationOptions as ScriptingNodeCreateMutationOptions
  );
  const [s3ComputeNodeCreateMutation] = useS3NodeCreateMutation(
    mutationOptions as S3NodeCreateMutationOptions
  );
  const [syntheticComputeNodeCreateMutation] = useSyntheticNodeCreateMutation(
    mutationOptions as SyntheticNodeCreateMutationOptions
  );

  const createComputeNode = useCallback(
    (
      computationType: WorkerTypeShortName,
      mutationInput: MutationFunctionOptions
    ) => {
      let mutationFunc;
      switch (computationType) {
        case WorkerTypeShortName.Match:
          mutationFunc = joinComputeNodeCreateMutation;
          break;
        case WorkerTypeShortName.Sql:
          mutationFunc = sqlComputeNodeCreateMutation;
          break;
        case WorkerTypeShortName.Sqlite:
          mutationFunc = sqliteComputeNodeCreateMutation;
          break;
        case WorkerTypeShortName.Post:
          mutationFunc = postComputeNodeCreateMutation;
          break;
        case WorkerTypeShortName.Preview:
          mutationFunc = previewComputeNodeCreateMutation;
          break;
        case WorkerTypeShortName.Python:
          mutationFunc = ((options) =>
            scriptingComputeNodeCreateMutation({
              ...options,
              variables: {
                ...options?.variables,
                input: {
                  ...((options?.variables?.input ||
                    {}) as CreateDraftScriptingNodeInput),
                  ...{
                    ...scriptingComputeBaseInput,
                    initialScript: {
                      ...scriptingComputeBaseInput.initialScript,
                      content: DEFAULT_SCRIPT.get(ScriptingLanguage.Python),
                    },
                  },
                  scriptingLanguage: ScriptingLanguage.Python,
                },
              },
            })) as MutationFunction;
          break;
        case WorkerTypeShortName.R:
          mutationFunc = ((options) =>
            scriptingComputeNodeCreateMutation({
              ...options,
              variables: {
                ...options?.variables,
                input: {
                  ...((options?.variables?.input ||
                    {}) as CreateDraftScriptingNodeInput),
                  ...{
                    ...scriptingComputeBaseInput,
                    initialScript: {
                      ...scriptingComputeBaseInput.initialScript,
                      content: DEFAULT_SCRIPT.get(ScriptingLanguage.R),
                    },
                  },
                  scriptingLanguage: ScriptingLanguage.R,
                },
              },
            })) as MutationFunction;
          break;
        case WorkerTypeShortName.S3:
          mutationFunc = s3ComputeNodeCreateMutation;
          break;
        case WorkerTypeShortName.Synthetic:
          mutationFunc = syntheticComputeNodeCreateMutation;
          break;
        default:
          throw new Error(
            `Unknown computation type ${computationType} for createComputeNodeMutation`
          );
      }
      return (mutationFunc as MutationFunction)(mutationInput);
    },
    [
      joinComputeNodeCreateMutation,
      postComputeNodeCreateMutation,
      previewComputeNodeCreateMutation,
      s3ComputeNodeCreateMutation,
      scriptingComputeNodeCreateMutation,
      sqlComputeNodeCreateMutation,
      sqliteComputeNodeCreateMutation,
      syntheticComputeNodeCreateMutation,
    ]
  );
  return createComputeNode;
};

export default useComputeNodeCreateMutation;
