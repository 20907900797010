import { type Session } from "@decentriq/core";
import {
  type ab_media_request as ddcAbMediaRequest,
  type ab_media_response as ddcMediaResponse,
} from "ddc";
import type JSZip from "jszip";
import { type PublishedDatasetsHashes } from "features/mediaDataRoom/models";
import { camelCaseToSnakeCase } from "utils";

// TODO: we probably want to move this to a shared location, but keeping it here for now
type SmooshedObjectUnion<T> = {
  [K in T extends infer P ? keyof P : never]: T extends infer P
    ? K extends keyof P
      ? P[K]
      : never
    : never;
};

type AbMediaRequest = SmooshedObjectUnion<ddcAbMediaRequest.AbMediaRequest>;
type AbMediaRequestKeys = keyof AbMediaRequest;
type AbMediaResponse = SmooshedObjectUnion<ddcMediaResponse.AbMediaResponse>;
type AbMediaResponseKeys = keyof AbMediaResponse;

export type MediaDataRoomRequestKey = Extract<
  AbMediaRequestKeys | AbMediaResponseKeys,
  | "retrievePublishedDatasets"
  | "retrieveDataRoom"
  | "computeInsights"
  | "computeOverlapStatistics"
  | "getLookalikeAudienceStatistics"
  | "ingestAudiencesReport"
  | "retrieveModelQualityReport"
  | "getAudiencesForAdvertiser"
  | "getAudiencesForPublisher"
  | "publishAudiencesJson"
  | "getAudienceUserListForAdvertiserLal"
  | "getAudienceUserListForPublisherLal"
  | "getAudienceUserListForAdvertiser"
  | "getAudienceUserListForPublisher"
  | "publishAudiencesDataset"
  | "unpublishAudiencesDataset"
  | "publishMatchingDataset"
  | "publishSegmentsDataset"
  | "publishDemographicsDataset"
  | "publishEmbeddingsDataset"
  | "unpublishMatchingDataset"
  | "unpublishSegmentsDataset"
  | "unpublishDemographicsDataset"
  | "unpublishEmbeddingsDataset"
  | "getDataAttributes"
  | "estimateAudienceSizeForAdvertiserLal"
  | "estimateAudienceSizeForAdvertiser"
  | "estimateAudienceSizeForPublisher"
  | "estimateAudienceSizeForPublisherLal"
  | "unpublishAudiencesJson"
>;

export type AbMediaRequestValueOfKey<K extends MediaDataRoomRequestKey> =
  AbMediaRequest extends Record<K, infer V> ? V : never;

export type MediaDataRoomJobResultTransform<T> = (zip: JSZip) => Promise<T>;

export type AbMediaResponseValueOfKey<K extends MediaDataRoomRequestKey> =
  AbMediaResponse extends Record<K, infer V> ? V : never;

interface JobInputOptions<K extends MediaDataRoomRequestKey> {
  key: K;
  dataRoomId: string;
  driverAttestationHash: string;
  publishedDatasetsHashes: PublishedDatasetsHashes | null;
  version: string;
  dcrType: string;
  resourceId: string | null;
  subKey: string | null;
  jobIdHex: string | null;
  computeNodeName: string | null;
  skipCaching: boolean;
  omitAudiencesHash: boolean;
}

export class MediaDataRoomJobInput<K extends MediaDataRoomRequestKey> {
  private constructor(private readonly options: JobInputOptions<K>) {}

  /**
   * This method is the only way to create a new instance of the `MediaDataRoomJobInput`
   * @param key `AbMediaRequest` key; name of the request to be sent
   * @param dataRoomId data room ID
   * @param driverAttestationHash driver attestation hash
   * @param publishedDatasetsHashes instance of the `PublishedDatasetsHashes` or `null` if not needed. Hashes from this class are used to create the query & job keys
   * @returns new instance of the `MediaDataRoomJobInput`
   */
  public static create<K extends MediaDataRoomRequestKey>(
    key: K,
    dataRoomId: string,
    driverAttestationHash: string,
    publishedDatasetsHashes: PublishedDatasetsHashes | null = null
  ): MediaDataRoomJobInput<K> {
    return new MediaDataRoomJobInput<K>({
      computeNodeName: null,
      dataRoomId,
      dcrType: "abMediaDataRoom",
      driverAttestationHash,
      jobIdHex: null,
      key,
      omitAudiencesHash: false,
      publishedDatasetsHashes,
      resourceId: null,
      skipCaching: false,
      subKey: null,
      version: "v0",
    });
  }

  /**
   * This method is used to build a query prefix which only includes version, data room ID and driver attestation hash and type of the media data room
   * @param dataRoomId data room ID
   * @param driverAttestationHash driver attestation hash
   * @returns array of strings which are used as a prefix for the query key
   */
  public static buildQueryPrefix({
    dataRoomId,
    driverAttestationHash,
  }: {
    dataRoomId: string;
    driverAttestationHash: string;
  }): string[] {
    return [
      "v0",
      "abMediaDataRoom",
      dataRoomId,
      "driverAttestationHash",
      driverAttestationHash,
    ];
  }

  /**
   * Returns `true` if the computations for this input can be cached
   */
  get canBeCached() {
    return this.options.publishedDatasetsHashes?.datasetsHash !== null;
  }

  /**
   * Returns `true` if caching of the computations for this input must be skipped
   */
  get skipCaching() {
    return this.options.skipCaching;
  }

  /**
   * Returns `key` for this input
   */
  get key() {
    return this.options.key;
  }

  /**
   * Returns `dataRoomId` for this input
   */
  get dataRoomId() {
    return this.options.dataRoomId;
  }

  /**
   * Returns `driverAttestationHash` for this input
   */
  get driverAttestationHash() {
    return this.options.driverAttestationHash;
  }

  /**
   * As rest of the required fields for caching are passed during the creation of the input,
   * only the hash of the published datasets is needed to be checked whether or not query key got changed
   */
  get queryChangingKey() {
    return this.options.publishedDatasetsHashes?.datasetsHash ?? null;
  }

  /**
   * This method is used to construct an input with a new resource ID
   * @param resourceId an unique identifier of the entity for which this input is created
   * @returns new instance of the `MediaDataRoomJobInput` with passed resource ID
   */
  public withResourceId(resourceId: string): MediaDataRoomJobInput<K> {
    return this.cloneWith({ resourceId });
  }

  /**
   * This method is used to construct an input with a new subkey
   * @param subKey an additional identifier to the `key` provided during creation of the input
   * and adds extra layer splitting under same `key`
   * @returns new instance of the `MediaDataRoomJobInput` with passed subkey
   */
  public withSubkey(subKey: string): MediaDataRoomJobInput<K> {
    return this.cloneWith({ subKey });
  }

  /**
   * This method is used to construct an input with a job status subkey
   * @returns new instance of the `MediaDataRoomJobInput` with `jobStatus` subkey
   */
  public withJobStatusSubkey(): MediaDataRoomJobInput<K> {
    return this.withSubkey("jobStatus");
  }

  /**
   * This method is used to construct an input with an existing job subkey
   * @returns new instance of the `MediaDataRoomJobInput` with `existingJob` subkey
   */
  public withExistingJobSubkey(): MediaDataRoomJobInput<K> {
    return this.withSubkey("existingJob");
  }

  /**
   * This method is used to construct an input with an fetch results subkey
   * @returns new instance of the `MediaDataRoomJobInput` with `fetchResults` subkey
   */
  public withFetchResultsSubkey(): MediaDataRoomJobInput<K> {
    return this.withSubkey("fetchResults");
  }

  /**
   * This method is used to construct an input with a new job identifiers which helps to attach input to specific job
   * @param job `jobIdHex` and `computeNodeName` which are used to identify the job
   * @returns new instance of the `MediaDataRoomJobInput` with passed job
   */
  public withJob(
    job: { jobIdHex: string; computeNodeName: string } | null | undefined
  ): MediaDataRoomJobInput<K> {
    return this.cloneWith({
      computeNodeName: job?.computeNodeName ?? null,
      jobIdHex: job?.jobIdHex ?? null,
    });
  }

  /**
   * This method is used to construct an input computation of which shouldn't be cached
   * @returns new instance of the `MediaDataRoomJobInput`
   */
  public withoutCaching(): MediaDataRoomJobInput<K> {
    return this.cloneWith({ skipCaching: true });
  }

  /**
   * This method builds a query key using identifiers attached to this input, only identifier which is not included in the query key is `audiencesHash`
   * which is not important of the query key and is used only for job key and cache identification in the remote cache storage
   * @returns array of strings or `null` values
   */
  public buildQueryKey(): (string | null)[] {
    return this.extendedBuildQueryKey({ omitAudiencesHash: true });
  }

  /**
   * This method builds a job key using identifiers attached to this input. Job key is used for cache identification in the remote cache storage
   * @returns string
   */
  public buildJobKey(): string {
    return this.extendedBuildQueryKey({ omitAudiencesHash: false }).join("/");
  }

  public buildJobType(): string {
    return camelCaseToSnakeCase(
      [
        this.options.version,
        this.options.dcrType,
        this.options.key as string,
        ...(this.options.resourceId ? [this.options.resourceId] : []),
      ].join("_")
    );
  }

  private cloneWith(
    updates: Partial<JobInputOptions<K>>
  ): MediaDataRoomJobInput<K> {
    return new MediaDataRoomJobInput<K>({
      ...this.options,
      ...updates,
    });
  }

  private extendedBuildQueryKey({
    omitAudiencesHash,
  }: {
    omitAudiencesHash: boolean;
  }): (string | null)[] {
    return [
      this.options.version,
      this.options.dcrType,
      this.options.dataRoomId,
      "driverAttestationHash",
      this.options.driverAttestationHash,
      ...(this.options.publishedDatasetsHashes?.datasetsHash
        ? [
            "publishedDatasetsHash",
            this.options.publishedDatasetsHashes?.datasetsHash,
          ]
        : []),
      ...(this.options.publishedDatasetsHashes?.audiencesDatasetHash &&
      !(omitAudiencesHash || this.options.omitAudiencesHash)
        ? [
            "audiencesDatasetHash",
            this.options.publishedDatasetsHashes?.audiencesDatasetHash,
          ]
        : []),
      this.options.key as string | null,
      ...(this.options.resourceId ? [this.options.resourceId] : []),
      ...(this.options.jobIdHex ? ["jobId", this.options.jobIdHex] : []),
      ...(this.options.computeNodeName
        ? ["computeNodeName", this.options.computeNodeName]
        : []),
      ...(this.options.subKey ? [this.options.subKey] : []),
    ];
  }
}

export interface MediaDataRoomJobHookPayload<
  T,
  K extends MediaDataRoomRequestKey,
> {
  input: MediaDataRoomJobInput<K>;
  /** @param requestCreator function which provides `dataRoomIdHex`, `scopeIdHex` and needs to return body of the request for the given key */
  requestCreator: (
    dataRoomIdHex: string,
    scopeIdHex: string
  ) => AbMediaRequestValueOfKey<K>;
  /** @param session optional `Session`, if not provided, it will be fetched */
  session?: Session;
  /** @param skip if true, the job result fetching will be skipped */
  skip: boolean;
  /** @param transform function to transform the job result, it provides the zip file in `JSZip` format */
  transform: MediaDataRoomJobResultTransform<T>;
}

export interface MediaDataRoomJobHookResult<T> {
  loading: boolean;
  computeResults?: T;
  error: string | undefined;
  retry: () => Promise<void>;
  setCacheData: (data: T | undefined) => void;
  status: "COMPUTING" | "FETCHING" | "COMPLETED";
}
