// TODO: Fix joy migration
import { faExclamationTriangle } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Modal,
  ModalDialog,
  Typography,
} from "@mui/joy";
import { useCallback, useState } from "react";

interface DeleteConfirmationDialogProps {
  open: boolean;
  onCancel: () => void;
  onDelete: () => Promise<void>;
}

const DeleteConfirmationDialog: React.FC<DeleteConfirmationDialogProps> = ({
  open,
  onCancel,
  onDelete,
}) => {
  const [loading, setLoading] = useState(false);
  const handleDelete = useCallback(async () => {
    try {
      setLoading(true);
      await onDelete();
      onCancel();
    } finally {
      setLoading(false);
    }
  }, [setLoading, onDelete, onCancel]);
  return (
    <Modal open={open}>
      <ModalDialog role="alertdialog">
        <DialogTitle>
          <Box sx={{ color: "error.main", mb: 1, textAlign: "center" }}>
            <FontAwesomeIcon
              fixedWidth={true}
              icon={faExclamationTriangle}
              size="4x"
            />
          </Box>
          <Typography
            align="center"
            level="title-md"
            sx={{ textWrap: "balance" }}
          >
            <strong>
              Are you sure you want to delete the advertiser audience data?
            </strong>
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Typography
            align="center"
            level="body-sm"
            sx={{ textWrap: "balance" }}
          >
            This operation will delete all insights and audiences. Audiences
            which have previously been exported from the DCR will remain
            available.
          </Typography>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button disabled={loading} onClick={onCancel}>
            Cancel
          </Button>
          <Button
            color="danger"
            disabled={loading}
            loading={loading}
            onClick={handleDelete}
            startDecorator={<FontAwesomeIcon icon={faExclamationTriangle} />}
            variant="solid"
          >
            Delete
          </Button>
        </DialogActions>
      </ModalDialog>
    </Modal>
  );
};

DeleteConfirmationDialog.displayName = "DeleteConfirmationDialog";

export default DeleteConfirmationDialog;
