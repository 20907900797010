import { Box, styled } from "@mui/joy";
import { useOrganizationPreferences, useUserRole } from "hooks";
import {
  AdminPortalSidebarMenuItem,
  DataPartnerPortalMenuItem,
  DataRoomsListSidebarMenuItem,
  DatasetsSidebarMenuItem,
  DocumentationSidebarMenuItem,
  KeychainSidebarMenuItem,
  NewDataRoomSidebarMenuItem,
  OrganizationLogoSidebarItem,
  PublisherPortalMenuItem,
  ToggleSidebarButton,
} from "./components";

const ToggleSidebarButtonWrapper = styled(Box)(
  ({ theme: { palette, spacing } }) => ({
    alignItems: "center",
    background: palette.common.white,
    bottom: 0,
    display: "flex",
    flexDirection: "column",
    marginTop: "auto",
    padding: spacing(2, 0),
    // In order to make the area outside this box scrollable and this box attached to the bottom,
    // the components above in JSX should be wrapped into additional Box,
    // which would violate Joy's principles of nested components (List > ListItem) hence breaking the layout and animations completely
    // Considering all the above, this solution is not perfect but the easiest and working
    position: "sticky",
    width: "100%",
  })
);

const SidebarContent: React.FC = () => {
  const {
    isSubscribedState,
    isSuspendedState,
    hasPublisherFeatures,
    hasDataPartnerFeatures,
  } = useOrganizationPreferences();
  const { isSuperAdmin, isOrganizationAdmin, isSuperAdminReadOnly } =
    useUserRole();

  return (
    <>
      <NewDataRoomSidebarMenuItem />
      <DataRoomsListSidebarMenuItem />
      {hasPublisherFeatures && <PublisherPortalMenuItem />}
      {hasDataPartnerFeatures && <DataPartnerPortalMenuItem />}
      <DatasetsSidebarMenuItem />
      <KeychainSidebarMenuItem />
      {(isSuperAdmin || isOrganizationAdmin || isSuperAdminReadOnly) &&
        (isSubscribedState || isSuspendedState) && (
          <AdminPortalSidebarMenuItem />
        )}
      <DocumentationSidebarMenuItem />
      <ToggleSidebarButtonWrapper>
        <OrganizationLogoSidebarItem />
        <ToggleSidebarButton />
      </ToggleSidebarButtonWrapper>
    </>
  );
};

export default SidebarContent;
