import { type PublishedMediaDataRoomQuery } from "@decentriq/graphql/dist/types";
import { memo } from "react";
import MediaDataRoomMainbar from "../components/MediaDataRoomMainbar/MediaDataRoomMainbar";
import { MediaInsightsDcrWrapper } from "../contexts/MediaInsightsDcrContext/MediaInsightsDcrContext";
import MediaDataRoomActiveTab from "./MediaDataRoomActiveTab";
import MediaDataRoomErrorReport from "./MediaDataRoomErrorReport";

interface MediaDataRoomProps {
  data: PublishedMediaDataRoomQuery["publishedMediaDataRoom"];
}

const MediaDataRoom: React.FC<MediaDataRoomProps> = memo(({ data }) => {
  return (
    <MediaDataRoomErrorReport
      createdAt={data.createdAt}
      dataRoomId={data.id}
      driverAttestationHash={data.driverAttestationHash}
    >
      <MediaInsightsDcrWrapper
        dataRoomId={data.id}
        driverAttestationHash={data.driverAttestationHash}
        enableExtendedLookalikeQualityStatistics={
          data.enableExtendedLookalikeQualityStatistics ?? false
        }
        isDeactivated={Boolean(data.deactivatedAt)}
        isStopped={data.isStopped}
      >
        <MediaDataRoomMainbar id={data.id} />
        <MediaDataRoomActiveTab />
      </MediaInsightsDcrWrapper>
    </MediaDataRoomErrorReport>
  );
});

export default MediaDataRoom;
