import { InlineEditor, type InlineEditorProps } from "@decentriq/components";
import { useSetDataRoomNameMutation } from "@decentriq/graphql/dist/hooks";
import { memo, useCallback } from "react";
import { useDataRoom } from "contexts";
import { mapDraftDataRoomErrorToSnackbar, useDataRoomSnackbar } from "hooks";
import useDataRoomName from "./useDataRoomName";

interface DataRoomNameEditorProps extends Partial<InlineEditorProps> {
  dataRoomId: string;
}

const DataRoomNameEditor: React.FC<DataRoomNameEditorProps> = memo(
  ({ dataRoomId, ...props }) => {
    const { enqueueSnackbar } = useDataRoomSnackbar();
    const { name, isLoading } = useDataRoomName();
    const { isPublished } = useDataRoom();
    const [setDataRoomNameMutation] = useSetDataRoomNameMutation();
    const updateDataRoomName = useCallback(
      async (name: string) => {
        try {
          await setDataRoomNameMutation({
            variables: {
              input: {
                id: dataRoomId,
                title: name,
              },
            },
          });
        } catch (error) {
          enqueueSnackbar(
            ...mapDraftDataRoomErrorToSnackbar(
              error,
              "Data clean room could not be renamed."
            )
          );
        }
      },
      [enqueueSnackbar, dataRoomId, setDataRoomNameMutation]
    );
    return (
      <InlineEditor
        cancelEditingButtonEnabled={false}
        onChange={updateDataRoomName}
        placeholder={isLoading ? "Loading data clean room…" : "Name"}
        readOnly={isPublished}
        saveEditingButtonEnabled={false}
        saveEditingOnClickAway={true}
        sx={{
          "& .contentWrapper": {
            alignItems: "center",
          },
          fontSize: "20px",
          fontWeight: 600,
        }}
        validate={(value: string) =>
          !value
            ? "Data clean room name must be set"
            : value.length > 100
              ? "Data clean room name must be less than 100 characters"
              : null
        }
        value={name}
        {...props}
      />
    );
  }
);
DataRoomNameEditor.displayName = "DataRoomNameEditor";

export default DataRoomNameEditor;
