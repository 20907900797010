// TODO: Fix joy migration
import { faExclamationTriangle } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Modal,
  ModalDialog,
  Typography,
} from "@mui/joy";
import { useCallback, useState } from "react";
import { useDataLabContext, useDataLabSnackbar } from "features/dataLabs";
import { getEffectiveErrorMessage } from "utils";

interface DataLabDeleteDialogProps {
  id: string;
  open: boolean;
  onCancel?: () => void;
}

const DataLabDeleteDialog: React.FC<DataLabDeleteDialogProps> = ({
  id,
  onCancel,
  open,
}) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const { enqueueSnackbar } = useDataLabSnackbar();
  const { deleteDataLab } = useDataLabContext();
  const handleDataLabDeleting = useCallback(async () => {
    setIsDeleting(true);
    try {
      await deleteDataLab();
    } catch (error) {
      enqueueSnackbar(`Datalab could not be deleted.`, {
        context: getEffectiveErrorMessage(error),
        persist: true,
        variant: "error",
      });
      setIsDeleting(false);
    }
  }, [deleteDataLab, setIsDeleting, enqueueSnackbar]);
  return (
    <Modal onClose={isDeleting ? undefined : onCancel} open={open}>
      <ModalDialog role="alertdialog">
        <DialogTitle>
          <Box sx={{ color: "error.main", mb: 1, textAlign: "center" }}>
            <FontAwesomeIcon
              fixedWidth={true}
              icon={faExclamationTriangle}
              size="4x"
            />
          </Box>
          <Typography
            align="center"
            level="title-md"
            sx={{ textWrap: "balance" }}
          >
            <strong>Are you sure you want to delete this datalab?</strong>
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Typography
            align="center"
            level="body-sm"
            sx={{ textWrap: "balance" }}
          >
            You will not be able anymore to use it in the Media DCR.
          </Typography>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button disabled={isDeleting} onClick={onCancel}>
            Cancel
          </Button>
          <Button
            color="danger"
            loading={isDeleting}
            loadingPosition="start"
            onClick={handleDataLabDeleting}
            startDecorator={<FontAwesomeIcon icon={faExclamationTriangle} />}
            variant="solid"
          >
            Delete
          </Button>
        </DialogActions>
      </ModalDialog>
    </Modal>
  );
};

DataLabDeleteDialog.displayName = "DataLabDeleteDialog";

export default DataLabDeleteDialog;
