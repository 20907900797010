import { ColumnDataType } from "@decentriq/graphql/dist/types";
import {
  faBullhorn,
  faChartSimple,
  faUsersRays,
  type IconDefinition,
} from "@fortawesome/pro-light-svg-icons";

export enum DataRoomTypeNames {
  DraftDataRoom = "DraftDataRoom",
  PublishedDataRoom = "PublishedDataRoom",
  PublishedMediaDataRoom = "PublishedMediaDataRoom",
  PublishedLookalikeMediaDataRoom = "PublishedLookalikeMediaDataRoom",
  PublishedMediaInsightsDcr = "PublishedMediaInsightsDcr",
}

export const dataRoomTypePrefix: Record<DataRoomTypeNames, string> = {
  [DataRoomTypeNames.DraftDataRoom]: "d",
  [DataRoomTypeNames.PublishedDataRoom]: "p",
  [DataRoomTypeNames.PublishedMediaDataRoom]: "m",
  [DataRoomTypeNames.PublishedLookalikeMediaDataRoom]: "lm",
  [DataRoomTypeNames.PublishedMediaInsightsDcr]: "mi",
};

export const dataRoomPrefixType: Record<string, DataRoomTypeNames> = {
  d: DataRoomTypeNames.DraftDataRoom,
  lm: DataRoomTypeNames.PublishedLookalikeMediaDataRoom,
  m: DataRoomTypeNames.PublishedMediaDataRoom,
  mi: DataRoomTypeNames.PublishedMediaInsightsDcr,
  p: DataRoomTypeNames.PublishedDataRoom,
};

export const dataRoomPathBuilder = (
  id: string,
  type: DataRoomTypeNames
): string => `/datarooms/${dataRoomTypePrefix[type]}/${id}`;

export const tableColumnDataTypePresentation = new Map<ColumnDataType, string>([
  [ColumnDataType.Int, "Number (whole)"],
  [ColumnDataType.Text, "String"],
  [ColumnDataType.Float, "Number (decimal)"],
]);

export enum DataRoomType {
  Media = "Media",
  DataScience = "DataScience",
  LookalikeMedia = "LookalikeMedia",
  MediaInsights = "MediaInsights",
  Measurement = "Measurement",
}

export enum RawMediaDataRoomFeatures {
  /**
   * @deprecated This feature is deprecated for new DCRs after inroduciton of the `RuleBasedAudiences` feature
   */
  LegacyLookalike = "ENABLE_LOOKALIKE",
  /**
   * @deprecated This feature is deprecated for new DCRs after inroduciton of the `RuleBasedAudiences` feature
   */
  LegacyRetargeting = "ENABLE_RETARGETING",
  Lookalike = "ENABLE_LOOKALIKE_AUDIENCES",
  Remarketing = "ENABLE_REMARKETING",
  Insights = "ENABLE_INSIGHTS",
  /**
   * @deprecated This feature is deprecated for new DCRs after inroduciton of the `RuleBasedAudiences` feature
   */
  ExclusionTargeting = "ENABLE_EXCLUSION_TARGETING",
  RuleBasedAudiences = "ENABLE_RULE_BASED_AUDIENCES",
}

export enum CollaborationTypes {
  Lookalike = "Lookalike audiences",
  Remarketing = "Remarketing",
  Insights = "Insights",
  Measurements = "Measurement",
  /**
   * @deprecated This collaboration type is deprecated for new DCRs after inroduciton of the `RuleBasedAudiences` collaboration type
   */
  ExclusionTargeting = "Exclusion targeting",
  RuleBasedAudiences = "Rule-based audiences",
}

export const mediaDataRoomCollaborationTypes = [
  CollaborationTypes.Insights,
  CollaborationTypes.Remarketing,
  CollaborationTypes.Lookalike,
  CollaborationTypes.RuleBasedAudiences,
];

export const RawMediaDataRoomFeaturesToMediaDataRoomFeatures: Record<
  RawMediaDataRoomFeatures,
  CollaborationTypes
> = {
  [RawMediaDataRoomFeatures.LegacyLookalike]: CollaborationTypes.Lookalike,
  [RawMediaDataRoomFeatures.LegacyRetargeting]: CollaborationTypes.Remarketing,
  [RawMediaDataRoomFeatures.Lookalike]: CollaborationTypes.Lookalike,
  [RawMediaDataRoomFeatures.Remarketing]: CollaborationTypes.Remarketing,
  [RawMediaDataRoomFeatures.Insights]: CollaborationTypes.Insights,
  [RawMediaDataRoomFeatures.ExclusionTargeting]:
    CollaborationTypes.ExclusionTargeting,
  [RawMediaDataRoomFeatures.RuleBasedAudiences]:
    CollaborationTypes.RuleBasedAudiences,
};

export const getDataRoomTypeFromTypename: Record<
  DataRoomTypeNames,
  DataRoomType
> = {
  [DataRoomTypeNames.DraftDataRoom]: DataRoomType.DataScience,
  [DataRoomTypeNames.PublishedDataRoom]: DataRoomType.DataScience,
  [DataRoomTypeNames.PublishedMediaDataRoom]: DataRoomType.Media,
  [DataRoomTypeNames.PublishedLookalikeMediaDataRoom]:
    DataRoomType.LookalikeMedia,
  [DataRoomTypeNames.PublishedMediaInsightsDcr]: DataRoomType.MediaInsights,
};

export const dataRoomTypePresentationMap: Record<DataRoomType, string> = {
  [DataRoomType.DataScience]: "Advanced Analytics",
  [DataRoomType.LookalikeMedia]: "Lookalike Audience",
  [DataRoomType.Media]: "Top-Affinity & Retargeting",
  [DataRoomType.MediaInsights]: "Media",
  [DataRoomType.Measurement]: "Measurement",
};

export const dataRoomTypeCardPresentation: Record<
  DataRoomType,
  { icon: IconDefinition; title: string; description: string }
> = {
  [DataRoomType.DataScience]: {
    description: "Data Science",
    icon: faChartSimple,
    title: dataRoomTypePresentationMap[DataRoomType.DataScience],
  },
  [DataRoomType.LookalikeMedia]: {
    description: "Insights & Activation",
    icon: faUsersRays,
    title: dataRoomTypePresentationMap[DataRoomType.LookalikeMedia],
  },
  [DataRoomType.Media]: {
    description: "Insights & Activation",
    icon: faUsersRays,
    title: dataRoomTypePresentationMap[DataRoomType.Media],
  },
  [DataRoomType.MediaInsights]: {
    description: "Insights & Activation",
    icon: faUsersRays,
    title: dataRoomTypePresentationMap[DataRoomType.MediaInsights],
  },
  [DataRoomType.Measurement]: {
    description: "Measurement",
    icon: faBullhorn,
    title: dataRoomTypePresentationMap[DataRoomType.Measurement],
  },
};

export enum PublishedDataRoomTab {
  overview = "overview",
  requests = "requests",
  development = "development",
  audit = "audit",
}

export const publishedDataRoomTabs = [
  PublishedDataRoomTab.overview,
  PublishedDataRoomTab.requests,
  PublishedDataRoomTab.development,
  PublishedDataRoomTab.audit,
];

export enum DraftDataRoomTab {
  data = "data",
  computations = "computations",
  permissions = "permissions",
  overview = "overview",
}

export const draftDataRoomTabs = [
  DraftDataRoomTab.data,
  DraftDataRoomTab.computations,
  DraftDataRoomTab.permissions,
  DraftDataRoomTab.overview,
];
