import { faFileImport } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@mui/joy";
import { memo } from "react";
import { DatasetImportDialog } from "features/datasets";

const DatasetExportButton: React.FC = memo(() => (
  <DatasetImportDialog
    renderTrigger={(open) => (
      <Button
        color="primary"
        onClick={open}
        startDecorator={
          <FontAwesomeIcon fixedWidth={true} icon={faFileImport} />
        }
        variant="solid"
      >
        Import dataset
      </Button>
    )}
  />
));
DatasetExportButton.displayName = "DatasetExportButton";

export default DatasetExportButton;
