import { faTriangleExclamation } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Grid, Typography } from "@mui/joy";
import { memo } from "react";
import { EmptyStateContainer } from "features/mediaDataRoom/components";
import AdvertiserAudienceGeneratorHeader from "../AdvertiserAudienceGeneratorStep/AdvertiserAudienceGeneratorHeader";

interface AdvertiserAudienceGeneratorErrorStateProps {
  message?: string;
  RetryButton?: React.ReactNode;
}

const AdvertiserAudienceGeneratorErrorState =
  memo<AdvertiserAudienceGeneratorErrorStateProps>(
    ({ message, RetryButton }) => {
      return (
        <Grid container={true} rowSpacing={6}>
          <Grid xs={7}>
            <AdvertiserAudienceGeneratorHeader />
          </Grid>
          <Grid xs={12}>
            <EmptyStateContainer gap={2}>
              <FontAwesomeIcon
                fixedWidth={true}
                icon={faTriangleExclamation}
                size="2x"
              />
              <Typography>
                {message || "Audience data could not be retrieved"}
              </Typography>
              {RetryButton && RetryButton}
            </EmptyStateContainer>
          </Grid>
        </Grid>
      );
    }
  );

AdvertiserAudienceGeneratorErrorState.displayName =
  "AdvertiserAudienceGeneratorErrorState";

export default AdvertiserAudienceGeneratorErrorState;
