import { difference, orderBy } from "lodash";
import { useMemo } from "react";
import { useFormContext } from "react-hook-form";
import {
  getAggregationPresentation,
  type MediaDataRoomInsightsFormVisualizeValue,
  type OverlapInsightsHookReturn,
  OverlapInsightsVisualizeDirection,
  useAudienceAggregationIdByName,
  useSelectedAudienceAggregations,
} from "features/MediaInsightsDcr";

type OverlapInsightsHookParams = {
  aggregation?: string[];
  audienceType?: string;
  visualize?: MediaDataRoomInsightsFormVisualizeValue;
};

// Hook that takes all available overlap data and transforms into
// more comfortable data structure based on selected filtering values
const useMediaDataRoomOverlapInsights = ({
  audienceType,
  aggregation = [],
  visualize,
}: OverlapInsightsHookParams = {}): OverlapInsightsHookReturn => {
  const { watch } = useFormContext();
  const insightsFormValues = watch();

  const { audienceType: selectedAudienceType = audienceType } =
    insightsFormValues;

  // Get id of passed aggregation based on its name
  const aggregationId = useAudienceAggregationIdByName({
    aggregationNames: aggregation,
    audienceType: selectedAudienceType,
  });

  // The hook can operate with both form context values and passed as params values, so define here which one is used
  const {
    aggregation: selectedAggregation = aggregationId,
    visualize: selectedVisualize = visualize,
  } = insightsFormValues;

  const aggregations = useSelectedAudienceAggregations(selectedAudienceType);

  const {
    aggregationData,
    isEmpty,
    possibleValues,
    suppressedValues,
    hasSuppressedValues,
    hiddenValues,
    hasHiddenValues,
  } = useMemo(() => {
    const {
      aggregation = [],
      rows = [],
      columns = [],
    } = aggregations.find(({ id }) => id === selectedAggregation) || {};

    const shareInOverlapDataIndex = columns.indexOf("share_in_overlap");
    const affinityRatioDataIndex = columns.indexOf("affinity_ratio");
    const shareInAddressableAudienceDataIndex = columns.indexOf(
      "share_in_addressable_audience"
    );
    const addressableAudienceSizeDataIndex = columns.indexOf(
      "addressable_audience_size"
    );
    const countUsersOverlapDataIndex = columns.indexOf("count_users_overlap");
    const interestDataIndex = columns.indexOf("interest");
    const ageDataIndex = columns.indexOf("age");
    const genderDataIndex = columns.indexOf("gender");

    const orderByColumnIndex = columns?.indexOf(selectedVisualize?.orderBy);
    // Items should always be ordered by descending, by column, selected in the By selector (orderBy value)
    // Amount of items (limit) define how many items are shown, so slice is used
    // Value of selectedVisualize.direction defines whether TOP or BOTTOM {selectedVisualize.limit} rows selected
    const selectedRows = orderBy(rows, (row) => row[orderByColumnIndex], [
      "desc",
    ]);
    const slicedRows = selectedVisualize
      ? selectedVisualize.direction === OverlapInsightsVisualizeDirection.top
        ? selectedRows.slice(0, selectedVisualize?.limit)
        : selectedRows.slice(selectedRows.length - selectedVisualize?.limit)
      : selectedRows;

    // Aggregation for the rows that will be shown on the UI
    const aggregationData = slicedRows?.map((dataRow) => ({
      addressableAudienceSize: dataRow[addressableAudienceSizeDataIndex],
      affinityRatio: dataRow[affinityRatioDataIndex],
      age: dataRow[ageDataIndex],
      aggregationName: dataRow?.slice(0, aggregation.length)?.join(", "),
      countUsersOverlap: dataRow[countUsersOverlapDataIndex],
      gender: dataRow[genderDataIndex],
      interest: getAggregationPresentation(dataRow[interestDataIndex]),
      shareInAddressableAudience: dataRow[shareInAddressableAudienceDataIndex],
      shareInOverlap: dataRow[shareInOverlapDataIndex],
    }));

    // TODO: Rewrite it in order not to duplicate the lines of aggregationData
    // All rows aggregation, needed for selecting hidden values
    const allAggregationData = rows?.map((dataRow) => ({
      addressableAudienceSize: dataRow[addressableAudienceSizeDataIndex],
      affinityRatio: dataRow[affinityRatioDataIndex],
      age: dataRow[ageDataIndex],
      aggregationName: dataRow?.slice(0, aggregation.length)?.join(", "),
      countUsersOverlap: dataRow[countUsersOverlapDataIndex],
      gender: dataRow[genderDataIndex],
      interest: getAggregationPresentation(dataRow[interestDataIndex]),
      shareInAddressableAudience: dataRow[shareInAddressableAudienceDataIndex],
      shareInOverlap: dataRow[shareInOverlapDataIndex],
    }));

    // Generate possible values array based on all selected aggregations
    const possibleValues: string[] = aggregation.reduce<string[]>(
      (values, { possible_values }) => {
        const filteredPossibleValues = possible_values.filter(
          (value) => ![undefined, null, "N/A", "O"].includes(value)
        );
        if (values.length === 0) {
          return filteredPossibleValues;
        } else {
          return values.flatMap((value) =>
            filteredPossibleValues.map((item) => `${value}, ${item}`)
          );
        }
      },
      []
    );

    // Define suppressed items
    const suppressedValues: string[] = orderBy(
      difference(
        possibleValues,
        allAggregationData.map(({ aggregationName }) => aggregationName)
      )
    );

    // If rows.length > slicedRows.length -> a part of the items is hidden on the charts
    // The following logic is needed to show those items in the tooltip alongside suppressedValues
    const hiddenValues = orderBy(
      difference(
        allAggregationData.map(({ aggregationName }) => aggregationName),
        aggregationData.map(({ aggregationName }) => aggregationName)
      )
    );

    const isEmpty =
      !slicedRows.length ||
      aggregationData.every(({ shareInOverlap }) => shareInOverlap === 0);

    return {
      aggregationData,
      hasHiddenValues: !!hiddenValues.length,
      hasSuppressedValues: !!suppressedValues.length,
      hiddenValues,
      isEmpty,
      possibleValues,
      suppressedValues,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    aggregations.length,
    selectedAggregation,
    selectedVisualize?.limit,
    selectedVisualize?.direction,
    selectedVisualize?.orderBy,
  ]);

  return {
    aggregationData,
    hasHiddenValues,
    hasSuppressedValues,
    hiddenValues,
    isEmptyAggregation: isEmpty,
    possibleValues,
    suppressedValues,
  };
};

export default useMediaDataRoomOverlapInsights;
