import { Navigate, useResolvedPath, useRoutes } from "react-router-dom";
import { OrganizationAdmin, OrganizationPageTabs } from "features/admin";

const OrganizationPage = () => {
  const resolvedPath = useResolvedPath("");
  const url = resolvedPath.pathname;

  const element = useRoutes([
    {
      element: (
        <OrganizationAdmin
          activeTab={OrganizationPageTabs.SETTINGS}
          baseUrl={url}
        />
      ),
      path: OrganizationPageTabs.SETTINGS,
    },
    {
      element: (
        <OrganizationAdmin
          activeTab={OrganizationPageTabs.USERS}
          baseUrl={url}
        />
      ),
      path: OrganizationPageTabs.USERS,
    },
    {
      element: (
        <OrganizationAdmin
          activeTab={OrganizationPageTabs.DATAROOMS}
          baseUrl={url}
        />
      ),
      path: OrganizationPageTabs.DATAROOMS,
    },
    {
      element: (
        <OrganizationAdmin
          activeTab={OrganizationPageTabs.COLLABORATING_ORGANIZATIONS}
          baseUrl={url}
        />
      ),
      path: OrganizationPageTabs.COLLABORATING_ORGANIZATIONS,
    },
    {
      element: (
        <OrganizationAdmin
          activeTab={OrganizationPageTabs.EXTERNAL_INVITATIONS}
          baseUrl={url}
        />
      ),
      path: OrganizationPageTabs.EXTERNAL_INVITATIONS,
    },
    {
      element: (
        <OrganizationAdmin
          activeTab={OrganizationPageTabs.PUBLISHER_CONFIGURATIONS}
          baseUrl={url}
        />
      ),
      path: OrganizationPageTabs.PUBLISHER_CONFIGURATIONS,
    },
    {
      element: (
        <OrganizationAdmin
          activeTab={OrganizationPageTabs.DATA_PARTNER_CONFIGURATIONS}
          baseUrl={url}
        />
      ),
      path: OrganizationPageTabs.DATA_PARTNER_CONFIGURATIONS,
    },
    {
      element: <Navigate to={`${url}/${OrganizationPageTabs.SETTINGS}`} />,
      path: "/",
    },
    {
      element: <Navigate to={`${url}/${OrganizationPageTabs.SETTINGS}`} />,
      path: "*",
    },
  ]);
  return element;
};

OrganizationPage.displayName = "OrganizationPage";

export default OrganizationPage;
