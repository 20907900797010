import { memo } from "react";
import CreationWizardConfigurationWrapper, {
  type CreationWizardConfigurationWrapperProps,
} from "features/mediaDataRoom/contexts/MediaDataRoomCreationWizardContext/CreationWizardConfigurationContext";
import CreationWizardDataPartnerWrapper from "features/mediaDataRoom/contexts/MediaDataRoomCreationWizardContext/CreationWizardDataPartnerContext";
import CreationWizardPublisherWrapper from "features/mediaDataRoom/contexts/MediaDataRoomCreationWizardContext/CreationWizardPublisherContext";
import CreationWizardStepperWrapper from "features/mediaDataRoom/contexts/MediaDataRoomCreationWizardContext/CreationWIzardStepperContext";

export interface MediaDataRoomCreationWizardWrapperProps
  extends CreationWizardConfigurationWrapperProps {
  restartCreation: () => void;
}

const MediaDataRoomCreationWizardWrapper =
  memo<MediaDataRoomCreationWizardWrapperProps>(
    ({ children, restartCreation, ...configurationWrapperProps }) => {
      return (
        <CreationWizardStepperWrapper restartCreation={restartCreation}>
          <CreationWizardPublisherWrapper>
            <CreationWizardDataPartnerWrapper>
              <CreationWizardConfigurationWrapper
                {...configurationWrapperProps}
              >
                {children}
              </CreationWizardConfigurationWrapper>
            </CreationWizardDataPartnerWrapper>
          </CreationWizardPublisherWrapper>
        </CreationWizardStepperWrapper>
      );
    }
  );

MediaDataRoomCreationWizardWrapper.displayName =
  "MediaDataRoomCreationWizardWrapper";

export default MediaDataRoomCreationWizardWrapper;
