import {
  type DraftMatchNode,
  type DraftPostNode,
  type DraftPreviewNode,
  type DraftS3SinkNode,
  type DraftScriptingNode,
  type DraftSqlNode,
  type DraftSyntheticNode,
  type PublishedMatchNode,
  type PublishedPostNode,
  type PublishedPreviewNode,
  type PublishedS3SinkNode,
  type PublishedScriptingNode,
  type PublishedSqliteNode,
  type PublishedSqlNode,
  type PublishedSyntheticNode,
  WorkerTypeShortName,
} from "@decentriq/graphql/dist/types";

export const DraftComputeNodeTypeNames = {
  DraftMatchNode: "DraftMatchNode",
  DraftPostNode: "DraftPostNode",
  DraftPreviewNode: "DraftPreviewNode",
  DraftS3SinkNode: "DraftS3SinkNode",
  DraftScriptingNode: "DraftScriptingNode",
  DraftSqlNode: "DraftSqlNode",
  DraftSqliteNode: "DraftSqliteNode",
  DraftSyntheticNode: "DraftSyntheticNode",
} as const;

export type DraftComputeNodeTypes =
  | DraftMatchNode
  | DraftPostNode
  | DraftPreviewNode
  | DraftS3SinkNode
  | DraftSyntheticNode
  | DraftSqlNode
  | DraftScriptingNode;

export type PublishedComputeNodeTypes =
  | PublishedMatchNode
  | PublishedPostNode
  | PublishedPreviewNode
  | PublishedS3SinkNode
  | PublishedSyntheticNode
  | PublishedSqlNode
  | PublishedSqliteNode
  | PublishedScriptingNode;

export const PublishedComputeNodeTypeNames = {
  PublishedMatchNode: "PublishedMatchNode",
  PublishedPostNode: "PublishedPostNode",
  // Not sure if this should be renamed to PublishedPreviewNode
  PublishedPreviewNode: "PublishedPreviewNode",
  PublishedS3SinkNode: "PublishedS3SinkNode",
  PublishedScriptingNode: "PublishedScriptingNode",
  PublishedSqlNode: "PublishedSqlNode",
  PublishedSqliteNode: "PublishedSqliteNode",
  PublishedSyntheticNode: "PublishedSyntheticNode",
} as const;

export const ComputeNodeTypeNames = {
  ...DraftComputeNodeTypeNames,
  ...PublishedComputeNodeTypeNames,
} as const;

export type PublishedComputeNodeTypeNames =
  keyof typeof PublishedComputeNodeTypeNames;
export type ComputeNodeTypeNames = keyof typeof ComputeNodeTypeNames;

export const workerTypeShortNameToPublishedComputeNodeTypeName = new Map<
  WorkerTypeShortName,
  PublishedComputeNodeTypeNames
>([
  [WorkerTypeShortName.Match, PublishedComputeNodeTypeNames.PublishedMatchNode],
  [WorkerTypeShortName.Post, PublishedComputeNodeTypeNames.PublishedPostNode],
  [
    WorkerTypeShortName.Preview,
    PublishedComputeNodeTypeNames.PublishedPreviewNode,
  ],
  [WorkerTypeShortName.S3, PublishedComputeNodeTypeNames.PublishedS3SinkNode],
  [
    WorkerTypeShortName.Python,
    PublishedComputeNodeTypeNames.PublishedScriptingNode,
  ],
  [WorkerTypeShortName.R, PublishedComputeNodeTypeNames.PublishedScriptingNode],
  [WorkerTypeShortName.Sql, PublishedComputeNodeTypeNames.PublishedSqlNode],
  [
    WorkerTypeShortName.Sqlite,
    PublishedComputeNodeTypeNames.PublishedSqliteNode,
  ],
  [
    WorkerTypeShortName.Synthetic,
    PublishedComputeNodeTypeNames.PublishedSyntheticNode,
  ],
]);

export const workerTypeShortNameToDraftComputeNodeTypeName = new Map<
  WorkerTypeShortName,
  keyof typeof DraftComputeNodeTypeNames
>([
  [WorkerTypeShortName.Match, DraftComputeNodeTypeNames.DraftMatchNode],
  [WorkerTypeShortName.Post, DraftComputeNodeTypeNames.DraftPostNode],
  [WorkerTypeShortName.Preview, DraftComputeNodeTypeNames.DraftPreviewNode],
  [WorkerTypeShortName.S3, DraftComputeNodeTypeNames.DraftS3SinkNode],
  [WorkerTypeShortName.Python, DraftComputeNodeTypeNames.DraftScriptingNode],
  [WorkerTypeShortName.R, DraftComputeNodeTypeNames.DraftScriptingNode],
  [WorkerTypeShortName.Sql, DraftComputeNodeTypeNames.DraftSqlNode],
  [WorkerTypeShortName.Sqlite, DraftComputeNodeTypeNames.DraftSqliteNode],
  [WorkerTypeShortName.Synthetic, DraftComputeNodeTypeNames.DraftSyntheticNode],
]);
