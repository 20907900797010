import { Box, Grid } from "@mui/joy";
import { Fragment, memo } from "react";
import {
  MediaDataRoomInsightsAdvertiserAudienceSelector,
  OverlapStatistics,
  useMediaDataRoom,
  useMediaDataRoomInsightsData,
} from "features/mediaDataRoom";
import {
  AffinityRatioAgeBarChart,
  AffinityRatioSegmentBarChart,
  GenderDonutChart,
} from "./components";

const MediaDataRoomInsightsBasicView = memo(() => {
  const {
    publishedDatasetsHashes: {
      publisherDatasetsHashes: { demographicsDatasetHash },
    },
    overlapStatistics: { status: overlapStatisticsStatus },
  } = useMediaDataRoomInsightsData();
  const {
    supportedFeatures: { hideAbsoluteValues = false },
  } = useMediaDataRoom();

  return (
    <Fragment>
      <Grid container={true} flex={1} flexDirection="column" mb={1}>
        <Grid mb={1} md={4} xs={12}>
          <MediaDataRoomInsightsAdvertiserAudienceSelector />
        </Grid>
        {(demographicsDatasetHash || !hideAbsoluteValues) && (
          <Grid columnSpacing={4} container={true} flex={1} xs={12}>
            {demographicsDatasetHash && (
              <Grid
                maxHeight={500}
                md={hideAbsoluteValues ? 12 : 6}
                minHeight={{ xl: 300, xs: 220 }}
                xs={12}
              >
                <GenderDonutChart />
              </Grid>
            )}
            {!hideAbsoluteValues && (
              <Grid md={demographicsDatasetHash ? 6 : 12} xs={12}>
                <Box
                  alignItems="center"
                  display="flex"
                  height={
                    overlapStatisticsStatus !== "COMPLETED"
                      ? "100%"
                      : "fit-content"
                  }
                  justifyContent="center"
                >
                  <OverlapStatistics />
                </Box>
              </Grid>
            )}
          </Grid>
        )}
      </Grid>
      <Grid columnSpacing={4} container={true} flex={1}>
        <Grid md={demographicsDatasetHash ? 6 : 12} minHeight={200} xs={12}>
          <AffinityRatioSegmentBarChart
            aggregation={["interest"]}
            segmentsThreshold={12}
          />
        </Grid>
        {demographicsDatasetHash && (
          <Grid md={6} minHeight={180} mt={{ md: 0, xs: 4 }} xs={12}>
            <AffinityRatioAgeBarChart />
          </Grid>
        )}
      </Grid>
    </Fragment>
  );
});

MediaDataRoomInsightsBasicView.displayName = "MediaDataRoomInsightsBasicView";

export default MediaDataRoomInsightsBasicView;
