import { useDataConnectorJobQuery } from "@decentriq/graphql/dist/hooks";

type ConnectionTooltipData = {
  storageAccount: string;
  blobName: string;
  storageContainer: string;
};

const useAzureConnectionConfigurationData = (
  connectionId: string
): ConnectionTooltipData => {
  const { data: { dataConnectorJob } = {} } = useDataConnectorJobQuery({
    skip: !connectionId,
    variables: { id: connectionId },
  });

  const config = dataConnectorJob?.config;

  return {
    blobName: config?.blobName,
    storageAccount: config?.storageAccount,
    storageContainer: config?.storageContainer,
  };
};

export default useAzureConnectionConfigurationData;
