import { Button } from "@mui/joy";
import { useBoolean } from "ahooks";
import { memo, type ReactNode, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  useAdvertiserAudiences,
  useMediaDataRoom,
  useMediaDataRoomInsightsData,
} from "features/mediaDataRoom/contexts";
import {
  type LookalikeAudienceStatistics,
  useLookalikeAudienceStatistics,
} from "features/mediaDataRoom/hooks";
import {
  type AdvertiserAudience,
  MediaDataRoomTab,
  type RuleBasedAudience,
} from "features/mediaDataRoom/models";
import { useDataRoomSnackbar, useOrganizationPreferences } from "hooks";
import { dataRoomPathBuilder, DataRoomTypeNames } from "models";
import {
  AdvertiserAudienceGeneratorEmptyState,
  AdvertiserAudienceGeneratorErrorState,
  AdvertiserAudienceGeneratorLoadingState,
  AdvertiserAudienceGeneratorStep,
  useAdvertiserAudienceGenerator,
} from "../../AdvertiserAudienceGenerator";
import {
  LookalikeAdvertiserAudienceGeneratorConfigurationStep,
  LookalikeAdvertiserAudienceGeneratorSummaryStep,
} from "../components";
import { LookalikeAdvertiserAudienceGeneratorProvider } from "../contexts/LookalikeAdvertiserAudienceGeneratorContext";

const LookalikeAdvertiserAudienceGenerator = memo(() => {
  const { session } = useMediaDataRoomInsightsData();
  const { dataRoomId, isDeactivated, enableExtendedLookalikeStatistics } =
    useMediaDataRoom();
  const { allowExcludingSeedAudience: showExcludeSeedAudienceCheckbox } =
    useOrganizationPreferences();
  const { currentStep } = useAdvertiserAudienceGenerator();
  const [reach, setReach] = useState<number>(1);
  const [excludeSeedAudience, setExcludeSeedAudience] =
    useState<boolean>(false);
  const [selectedSeedAudience, setSelectedSeedAudience] = useState<
    RuleBasedAudience | AdvertiserAudience | null
  >(null);
  const [audienceName, setAudienceName] = useState<string>("");
  const navigate = useNavigate();
  const { enqueueSnackbar } = useDataRoomSnackbar();
  // Advertiser Audience Quality Drawer state
  const [
    isAdvertiserAudienceQualityDrawerOpen,
    {
      setTrue: openAdvertiserAudienceQualityDrawer,
      setFalse: closeAdvertiserAudienceQualityDrawer,
    },
  ] = useBoolean(false);
  const {
    saveAudience,
    isSavingAudience: generateLookalikeAudienceLoading,
    audiences: {
      computeResults: allAdvertiserAudiences = [],
      ...audiencesState
    },
  } = useAdvertiserAudiences();

  // Lookalike audiences need to be filtered out in order to exclude depending lookalike audiences
  const availableSeedAudiences = allAdvertiserAudiences.filter(
    ({ id }, index, allAudiences) =>
      !session?.compiler.abMedia.doesAudienceDependOnLookalikeAudience(
        id,
        allAudiences
      )
  ) as (RuleBasedAudience | AdvertiserAudience)[];

  const selectedAudienceStatistics: LookalikeAudienceStatistics =
    useLookalikeAudienceStatistics({
      audienceId: selectedSeedAudience?.id,
      audienceReach: reach,
      audiences: allAdvertiserAudiences,
    });

  // Set default value for the new audience
  useEffect(() => {
    setAudienceName(`${selectedSeedAudience?.mutable.name} - ${reach}%`);
  }, [reach, currentStep, selectedSeedAudience?.mutable.name]);

  // Set default value for audienceType selector
  useEffect(() => {
    if (availableSeedAudiences.length && !selectedSeedAudience) {
      setSelectedSeedAudience(availableSeedAudiences[0]);
    }
  }, [availableSeedAudiences, selectedSeedAudience]);

  // If current reach is amongst the values of the new type, keep it, otherwise select the smallest
  useEffect(() => {
    setReach((currentReach) => {
      return (
        selectedAudienceStatistics.rawStatistics?.filtered_audiences || []
      ).some(({ reach }) => reach === currentReach)
        ? currentReach
        : 1;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSeedAudience, setReach]);

  const onGenerateLookalikeAudienceSuccess = useCallback(() => {
    enqueueSnackbar("Audiences successfully updated");
    // When an Audience is generated, a user should be redirected to the Audience list
    // with small timeout so notification can be easily read
    const generateLookalikeAudienceTimeout = setTimeout(() => {
      const dataRoomBasePath = dataRoomPathBuilder(
        dataRoomId!,
        DataRoomTypeNames.PublishedMediaInsightsDcr
      );
      navigate(`${dataRoomBasePath}/${MediaDataRoomTab.activation}`);
    }, 1200);
    return () => clearTimeout(generateLookalikeAudienceTimeout);
  }, [enqueueSnackbar, navigate, dataRoomId]);
  const generateLookalikeAudience = useCallback(() => {
    if (!selectedSeedAudience?.id) {
      enqueueSnackbar("Please select seed audience", {
        variant: "info",
      });
      return;
    }
    if (!audienceName.trim()) {
      enqueueSnackbar("Please provide a name for the audience", {
        variant: "info",
      });
      return;
    }
    saveAudience({
      audience: {
        exclude_seed_audience: excludeSeedAudience,
        id: crypto.randomUUID(),
        kind: "lookalike",
        mutable: {
          created_at: new Date().toISOString(),
          name: audienceName,
          status: "ready",
        },
        reach,
        source_ref: selectedSeedAudience.id!,
      },
      excludeSeedAudience,
      onSuccess: onGenerateLookalikeAudienceSuccess,
    });
  }, [
    audienceName,
    selectedSeedAudience?.id,
    saveAudience,
    excludeSeedAudience,
    reach,
    onGenerateLookalikeAudienceSuccess,
    enqueueSnackbar,
  ]);
  const [retryInProgress, setRetryInProgress] = useState(false);
  const retryGeneration = useCallback(async () => {
    if (isDeactivated) return;
    setRetryInProgress(true);
    try {
      await audiencesState.retry();
    } finally {
      setRetryInProgress(false);
    }
  }, [audiencesState, isDeactivated]);
  if (audiencesState.loading) {
    return <AdvertiserAudienceGeneratorLoadingState />;
  }
  if (audiencesState.error) {
    return (
      <AdvertiserAudienceGeneratorErrorState
        RetryButton={
          !isDeactivated && (
            <Button
              loading={retryInProgress}
              onClick={retryGeneration}
              variant="plain"
            >
              Retry
            </Button>
          )
        }
      />
    );
  }
  if (!allAdvertiserAudiences?.length) {
    return <AdvertiserAudienceGeneratorEmptyState />;
  }
  let step: ReactNode = null;
  switch (currentStep) {
    case AdvertiserAudienceGeneratorStep.CONFIGURATION:
      step = <LookalikeAdvertiserAudienceGeneratorConfigurationStep />;
      break;
    case AdvertiserAudienceGeneratorStep.SUMMARY:
      step = <LookalikeAdvertiserAudienceGeneratorSummaryStep />;
      break;
  }
  return (
    <LookalikeAdvertiserAudienceGeneratorProvider
      value={{
        audienceName,
        availableSeedAudiences: {
          computeResults: availableSeedAudiences,
          ...audiencesState,
        },
        closeAdvertiserAudienceQualityDrawer,
        enableExtendedLookalikeStatistics,
        excludeSeedAudience,
        generateLookalikeAudience,
        generateLookalikeAudienceLoading,
        isAdvertiserAudienceQualityDrawerOpen,
        openAdvertiserAudienceQualityDrawer,
        reach,
        selectedAudienceStatistics,
        selectedSeedAudience,
        setAudienceName,
        setExcludeSeedAudience,
        setReach,
        setSelectedSeedAudience,
        showExcludeSeedAudienceCheckbox,
      }}
    >
      {step}
    </LookalikeAdvertiserAudienceGeneratorProvider>
  );
});

LookalikeAdvertiserAudienceGenerator.displayName =
  "LookalikeAdvertiserAudienceGenerator";

export default LookalikeAdvertiserAudienceGenerator;
