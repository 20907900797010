import { testIds } from "@decentriq/utils";
import { faChartSimple } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@mui/joy";
import { useBoolean } from "ahooks";
import { Fragment, useCallback } from "react";
import {
  DataLabValidationConfirmationDialog,
  useDataLabContext,
} from "features/dataLabs";

const DataLabValidationButton: React.FC = () => {
  const {
    computeStatistics,
    statisticsLoading,
    dataLab: { data },
  } = useDataLabContext();
  const {
    usersDataset,
    embeddingsDataset,
    requireEmbeddingsDataset,
    demographicsDataset,
    requireDemographicsDataset,
    requireSegmentsDataset,
    segmentsDataset,
  } = data!;
  const [
    isDataLabValidationConfirmationDialogOpen,
    {
      setTrue: openDataLabValidationConfirmationDialog,
      setFalse: closeDataLabValidationConfirmationDialog,
    },
  ] = useBoolean(false);
  const isDataProvisioned: boolean =
    Boolean(usersDataset) &&
    (Boolean(embeddingsDataset) || !requireEmbeddingsDataset) &&
    (Boolean(demographicsDataset) || !requireDemographicsDataset) &&
    (Boolean(segmentsDataset) || !requireSegmentsDataset);
  const isValidationButtonEnabled: boolean =
    isDataProvisioned && !statisticsLoading;

  const handleComputeStatistics = useCallback(() => {
    if (!data?.statistics) {
      openDataLabValidationConfirmationDialog();
    }
    computeStatistics();
  }, [
    computeStatistics,
    data?.statistics,
    openDataLabValidationConfirmationDialog,
  ]);
  return (
    <Fragment>
      <Button
        color="primary"
        data-testid={testIds.dataLabs.dataLabValidation.dataLabValidationButton}
        disabled={!isValidationButtonEnabled}
        loading={statisticsLoading}
        loadingPosition="start"
        onClick={handleComputeStatistics}
        startDecorator={
          <FontAwesomeIcon fixedWidth={true} icon={faChartSimple} />
        }
        variant={isDataProvisioned ? "solid" : "plain"}
      >
        {isDataProvisioned ? "Validate datalab" : "Upload data to validate"}
      </Button>
      <DataLabValidationConfirmationDialog
        onClose={closeDataLabValidationConfirmationDialog}
        open={isDataLabValidationConfirmationDialogOpen}
      />
    </Fragment>
  );
};

export default DataLabValidationButton;
