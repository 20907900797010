import { useApolloClient } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import { useDeleteAccountMutation } from "@decentriq/graphql/dist/hooks";
import { useState } from "react";
import { localStorageKeychainKeyId } from "services/keychain";
import { useApiCore } from "contexts";
import { CommonSnackbarOrigin, useGeneralSnackbar, useUserRole } from "hooks";
import { getEffectiveErrorMessage } from "utils";

type UseAccountDeleteReturn = [() => Promise<void>, boolean];

const useAccountDelete = (onSuccess: () => void): UseAccountDeleteReturn => {
  const { client, reset } = useApiCore();
  const { enqueueSnackbar } = useGeneralSnackbar({
    origin: CommonSnackbarOrigin.DASHBOARD,
  });
  const [inProgress, setInProgress] = useState(false);
  const apolloClient = useApolloClient();
  const { logout } = useAuth0();
  const { userId } = useUserRole();
  const signOut = () => {
    apolloClient.clearStore();
    localStorage.removeItem(localStorageKeychainKeyId);
    reset();
    logout({
      logoutParams: { federated: true, returnTo: window.location.origin },
    });
  };
  const [deleteAccountMutation] = useDeleteAccountMutation({
    variables: { id: userId! },
  });
  async function deleteAccount() {
    setInProgress(true);
    try {
      const apiTokens = await client.getAllApiTokens();
      await Promise.all(
        apiTokens.map((apiToken) => client.revokeApiToken(apiToken.id))
      );
      await deleteAccountMutation();
      setInProgress(false);
      onSuccess();
      signOut();
    } catch (error) {
      setInProgress(false);
      enqueueSnackbar("Account could not be deleted.", {
        context: getEffectiveErrorMessage(error),
        persist: true,
        variant: "error",
      });
    }
  }
  return [deleteAccount, inProgress];
};

export default useAccountDelete;
