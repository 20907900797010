import { Typography } from "@mui/joy";
import TimeAgo from "react-timeago";
import { formatSize } from "features/datasets";
import { useTimeFormatter } from "hooks";

interface JobLastRunLabelProps {
  runAt?: string;
  isJobCompleted?: boolean;
  resultSize?: number | null;
}

const JobLastRunLabel: React.FC<JobLastRunLabelProps> = ({
  runAt,
  isJobCompleted,
  resultSize,
}) => {
  const timeFormatter = useTimeFormatter();
  return runAt && isJobCompleted ? (
    <Typography level="body-sm" noWrap={true}>
      Last run <TimeAgo date={runAt} formatter={timeFormatter} />
      {typeof resultSize === "number" ? (
        <span>. Result: {formatSize(resultSize)}</span>
      ) : null}
    </Typography>
  ) : null;
};

export default JobLastRunLabel;
