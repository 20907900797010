import { useApolloClient } from "@apollo/client";
import {
  ComputeJobAutoFetching,
  PreviewComputeNodeResultDocument,
} from "@decentriq/graphql/dist/types";
import { useCallback, useState } from "react";
import { useDataRoom, usePublishedDataRoom } from "contexts";

interface ComputeNodePreviewHookArgs {
  jobId: string;
}

const useComputeNodePreview = ({ jobId }: ComputeNodePreviewHookArgs) => {
  const { isPublished, allowTestMode } = useDataRoom();
  const { testing } = usePublishedDataRoom();
  const forTestJob = isPublished ? testing : allowTestMode;
  const [loading, setLoading] = useState(false);
  const client = useApolloClient();
  const previewResult = useCallback(
    () =>
      new Promise<void>((res, rej) => {
        setLoading(true);
        client.writeQuery({
          data: {
            computeJob: {
              __typename: "ComputeJob",
              autoFetching: forTestJob
                ? ComputeJobAutoFetching.TestResult
                : ComputeJobAutoFetching.Result,
              id: jobId,
            },
          },
          query: PreviewComputeNodeResultDocument,
        });
        res();
        setLoading(false);
      }),
    [client, jobId, forTestJob]
  );
  return {
    loading,
    previewResult,
  };
};

export default useComputeNodePreview;
