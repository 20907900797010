import { type OwnOrganizationUserEmailsQuery } from "@decentriq/graphql/dist/types";
import { zodResolver } from "@hookform/resolvers/zod";
import { type secret_store as ddcSecretStore } from "ddc";
import { z } from "zod";

export type DatasetPermission =
  ddcSecretStore.SecretStoreEntryState["acl"]["users"][number];

export type DatasetPermissionRole = DatasetPermission["role"];

export const datasetPermissionRolePresentation: Record<
  DatasetPermissionRole,
  string
> = {
  Owner: "Owner",
  User: "User",
};

export const datasetPermissionRoleOptions: DatasetPermissionRole[] = [
  "Owner",
  "User",
];

const datasetPermissionSchema = z.object({
  id: z.string().email(),
  role: z.union([z.literal("User"), z.literal("Owner")]),
});

export type DatasetPermissionFormValues = z.infer<
  typeof datasetPermissionSchema
>;

export const createShareDatasetValidationResolver = (
  organisationUsers: Exclude<
    OwnOrganizationUserEmailsQuery["myself"]["organization"],
    null | undefined
  >["users"]["nodes"],
  permissions: DatasetPermission[],
  isEdit: boolean = false
) => {
  const schema = datasetPermissionSchema
    .refine(({ id }) => organisationUsers.some(({ email }) => email === id), {
      message:
        "This user is not part of your organization. You can only share with your organization's users.",
      path: ["id"],
    })
    .refine(
      ({ id }) =>
        isEdit ||
        !permissions.some(({ id: permissionEmail }) => permissionEmail === id),
      {
        message:
          "This users email already has access to the dataset. You can only share with a user once.",
        path: ["id"],
      }
    )
    .refine(
      ({ id }) =>
        isEdit ||
        organisationUsers.some(
          ({ email, migrationCompletedAt, needsMigration }) =>
            email === id && (Boolean(migrationCompletedAt) || !needsMigration)
        ),
      {
        message:
          "You can only share with your organization's users who also migrated to new authentication and secrets management systems.",
        path: ["id"],
      }
    );
  return zodResolver(schema);
};
