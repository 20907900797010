import { faTriangleExclamation } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Grid, Typography } from "@mui/joy";
import { memo, useCallback, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import {
  EmptyStateContainer,
  MediaDataRoomInsightsAdvertiserAudienceSelector,
  OverlapStatistics,
  useMediaInsightsDcrData,
} from "features/MediaInsightsDcr";

const MediaDataroomDataOverlap: React.FC = () => {
  const form = useForm<{ audienceType: string }>({
    defaultValues: { audienceType: "" },
    mode: "onChange",
  });

  const {
    overlapStatistics: { error: overlapStatisticsError, retry },
  } = useMediaInsightsDcrData();

  const [retryInProgress, setRetryInProgress] = useState(false);
  const retryOverlapStatistics = useCallback(async () => {
    setRetryInProgress(true);
    try {
      await retry();
    } finally {
      setRetryInProgress(false);
    }
  }, [retry]);

  if (overlapStatisticsError) {
    return (
      <EmptyStateContainer gap={2}>
        <FontAwesomeIcon
          fixedWidth={true}
          icon={faTriangleExclamation}
          size="2x"
        />
        <Typography>Overlap could not be retrieved.</Typography>
        <Button
          loading={retryInProgress}
          onClick={retryOverlapStatistics}
          variant="plain"
        >
          Retry
        </Button>
      </EmptyStateContainer>
    );
  }

  return (
    <Grid container={true} gap={1} md={7} mt={2} xs={12}>
      <FormProvider {...form}>
        <Grid md={7} xs={12}>
          <MediaDataRoomInsightsAdvertiserAudienceSelector />
        </Grid>
        <Grid xs={12}>
          <OverlapStatistics />
        </Grid>
      </FormProvider>
    </Grid>
  );
};

MediaDataroomDataOverlap.displayName = "MediaDataroomDataOverlap";

export default memo(MediaDataroomDataOverlap);
