import { DqAudienceSummary } from "@decentriq/components";
import { faFloppyDisk } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, FormControl, FormLabel, Grid, Input } from "@mui/joy";
import { LoadingButton } from "@mui/lab";
import { memo } from "react";
import { EstimatedAudienceSize } from "features/mediaDataRoom/components";
import {
  AdvertiserAudienceGeneratorHeader,
  AudienceGeneratorStepActionsWrapper,
  AudienceGeneratorStepSectionDescription,
  AudienceGeneratorStepSectionTitle,
  useAdvertiserAudienceGenerator,
} from "features/mediaDataRoom/containers/AdvertiserAudienceGenerator";
import { useMediaDataRoom } from "features/mediaDataRoom/contexts";
import { useRulesBasedAdvertiserAudienceGenerator } from "../../contexts/RulesBasedAdvertiserAudienceGeneratorContext";

const RulesBasedAdvertiserAudienceGeneratorSummaryStep = memo(() => {
  const { supportedFeatures } = useMediaDataRoom();
  const { handleBackStep } = useAdvertiserAudienceGenerator();
  const {
    audienceName,
    estimatedAudienceSize,
    isEstimatingAudienceSize,
    setAudienceName,
    generateAudience,
    rulesTree,
    isGenerationProcessing,
  } = useRulesBasedAdvertiserAudienceGenerator();
  return (
    <>
      <Grid container={true} md={5} xs={12}>
        <Grid mb={2} xs={12}>
          <Box mb={3}>
            <AdvertiserAudienceGeneratorHeader />
          </Box>
          <AudienceGeneratorStepSectionTitle>
            1. Name your audience
          </AudienceGeneratorStepSectionTitle>
          <AudienceGeneratorStepSectionDescription>
            Provide a name that reflects your audience's purpose or focus.
          </AudienceGeneratorStepSectionDescription>
        </Grid>
        <Grid mb={2} xs={12}>
          <FormControl>
            <FormLabel>Audience name</FormLabel>
            <Input
              autoComplete="off"
              fullWidth={true}
              onChange={(event) => setAudienceName(event.target.value)}
              placeholder="e.g. 'New audience'"
              value={audienceName}
            />
          </FormControl>
        </Grid>
        {!supportedFeatures.hideAbsoluteValues && (
          <>
            <Grid mb={2} xs={12}>
              <AudienceGeneratorStepSectionTitle>
                2. Estimated audience size
              </AudienceGeneratorStepSectionTitle>
              <AudienceGeneratorStepSectionDescription>
                This section displays the estimated size of your new audience.
              </AudienceGeneratorStepSectionDescription>
            </Grid>
            <Grid mb={1} xs={12}>
              <EstimatedAudienceSize
                estimatedAudienceSize={estimatedAudienceSize!}
                loading={isEstimatingAudienceSize}
              />
            </Grid>
          </>
        )}
        <Grid mb={2} mt={2} xs={12}>
          <AudienceGeneratorStepSectionTitle>
            {supportedFeatures.hideAbsoluteValues ? 2 : 3}. Audience summary
          </AudienceGeneratorStepSectionTitle>
          <AudienceGeneratorStepSectionDescription>
            Details of your audience configuration below:
          </AudienceGeneratorStepSectionDescription>
        </Grid>
        <Grid container={true} mb={1} xs={12}>
          <DqAudienceSummary audienceTree={rulesTree!} />
        </Grid>
      </Grid>
      <AudienceGeneratorStepActionsWrapper>
        <Button onClick={handleBackStep} sx={{ mr: 1 }}>
          Back
        </Button>
        <LoadingButton
          color="primary"
          loading={isGenerationProcessing}
          loadingPosition="start"
          onClick={generateAudience}
          startIcon={<FontAwesomeIcon icon={faFloppyDisk} />}
          sx={{ height: "fit-content" }}
          variant="contained"
        >
          Save audience
        </LoadingButton>
      </AudienceGeneratorStepActionsWrapper>
    </>
  );
});

RulesBasedAdvertiserAudienceGeneratorSummaryStep.displayName =
  "RulesBasedAdvertiserAudienceGeneratorSummaryStep";

export default RulesBasedAdvertiserAudienceGeneratorSummaryStep;
