import { Dropdown, Menu, MenuButton, Tooltip } from "@mui/joy";
import { memo } from "react";
import { type Audience } from "features/mediaDataRoom";
import { useAudiences } from "features/mediaDataRoom/wrappers";
import DownloadAudienceMenuItem from "./DownloadAudienceMenuItem";
import ExportAudienceAsDataset from "./ExportAudienceAsDataset";

interface ExportAudienceButtonProps {
  audience: Audience;
  menuPlacement?: "bottom" | "top";
}

const ExportAudienceButton: React.FC<ExportAudienceButtonProps> = memo(
  ({ audience, menuPlacement = "bottom" }) => {
    const { isExportingAudience } = useAudiences();
    return (
      <Dropdown>
        <Tooltip title="Export audience with the list of activation IDs">
          <MenuButton
            color="primary"
            loading={isExportingAudience[audience.id]}
            loadingPosition="start"
            variant="plain"
          >
            Export
          </MenuButton>
        </Tooltip>
        <Menu
          placement={menuPlacement === "bottom" ? "bottom-end" : "top-end"}
          sx={{
            "--ListItemDecorator-size": "1.625rem",
            // TODO: wtf is this var name and magic number; Joy brings joy
            "--joy-zIndex-popup": 1200,
          }}
        >
          <ExportAudienceAsDataset audience={audience} />
          <DownloadAudienceMenuItem audience={audience} />
        </Menu>
      </Dropdown>
    );
  }
);

ExportAudienceButton.displayName = "ExportAudienceButton";

export default ExportAudienceButton;
