import { createContext, useContext } from "react";

export const PermissionsVarsContext = createContext({
  canEditPermissions: true,
});

export const PermissionsVarsProvider = PermissionsVarsContext.Provider;

export const usePermissionsVars = () => useContext(PermissionsVarsContext);

export default usePermissionsVars;
