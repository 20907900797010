import { data_science } from "@decentriq/core";
import {
  type MutationUnpublishDatasetArgs,
  type UnpublishDatasetPayload,
} from "@decentriq/graphql/dist/types";
import { type ApiCoreContextValue } from "contexts";
import { type LocalResolverContext } from "../../../models";

export const makeUnpublishDatasetResolver =
  (
    client: ApiCoreContextValue["client"],
    sessionManager: ApiCoreContextValue["sessionManager"],
    store: ApiCoreContextValue["store"]
  ) =>
  async (
    _obj: null,
    args: MutationUnpublishDatasetArgs,
    context: LocalResolverContext,
    _info: any
  ): Promise<UnpublishDatasetPayload> => {
    const { dcrHash, driverAttestationHash, dataNodeId } = args.input;
    const sdkSession = await sessionManager.get({
      driverAttestationHash,
    });
    const dataScienceDataRoom =
      await sdkSession.retrieveDataScienceDataRoom(dcrHash);
    const wrapper = data_science.createDataScienceDataRoomWrapper(
      dcrHash,
      dataScienceDataRoom!,
      sdkSession
    );
    await wrapper.unpublishDataset(dataNodeId!);
    return {
      id: dataNodeId!,
    };
  };
