// TODO: Fix joy migration
import { faInfoCircle, faXmark } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Checkbox,
  FormControl,
  FormHelperText,
  FormLabel,
  IconButton,
  Option,
  Select,
  type SelectOption,
  type SelectStaticProps,
  Tooltip,
} from "@mui/joy";
import { memo, useCallback, useRef } from "react";
import {
  Controller,
  type FieldValues,
  type Path,
  useFormContext,
} from "react-hook-form";
import { type OrganizationUserEmail } from "../../hooks/useOrganizationUserEmails/useOrganizationUserEmails";

interface ConfigurationFormParticipantsFieldProps<F extends FieldValues> {
  fieldName: Path<F>;
  organizationUserEmails: OrganizationUserEmail[];
  tooltip?: string;
  title: string;
  testId?: string;
  optionTestId?: string;
}

type ConfigurationFormParticipantsFieldComponent = <F extends FieldValues>(
  props: ConfigurationFormParticipantsFieldProps<F>
) => React.ReactNode;

const ConfigurationFormParticipantsField = memo(
  <F extends FieldValues>({
    fieldName,
    organizationUserEmails,
    tooltip,
    title,
    testId,
    optionTestId,
  }: ConfigurationFormParticipantsFieldProps<F>) => {
    const action: SelectStaticProps["action"] = useRef(null);
    const { control, setValue } = useFormContext<F>();
    const handleRenderValue = useCallback(
      (selectedEmailOptions: SelectOption<never>[]) => {
        if (selectedEmailOptions.length === 1) {
          return selectedEmailOptions[0].value;
        }
        return (
          <Tooltip
            placement="top"
            title={selectedEmailOptions.map(({ value }) => value).join(", ")}
          >
            <span>{`${selectedEmailOptions.length} participants`}</span>
          </Tooltip>
        );
      },
      []
    );
    return (
      <Controller
        control={control}
        name={fieldName}
        render={({ field, fieldState }) => {
          const { error } = fieldState;
          return (
            <FormControl
              disabled={field.disabled}
              error={Boolean(error)}
              required={true}
            >
              <FormLabel>
                {title}
                {tooltip ? (
                  <Box ml={0.5}>
                    <Tooltip placement="top" title={tooltip}>
                      <FontAwesomeIcon icon={faInfoCircle} />
                    </Tooltip>
                  </Box>
                ) : null}
              </FormLabel>
              <Select
                action={action}
                multiple={true}
                placeholder="No participants selected"
                renderValue={handleRenderValue}
                {...(field.value &&
                  field.value.length > 0 && {
                    endDecorator: (
                      <IconButton
                        onClick={() => {
                          setValue(fieldName, []);
                          action.current?.focusVisible();
                        }}
                        onMouseDown={(event) => event.stopPropagation()}
                        size="md"
                        sx={{ "--IconButton-size": "1.75rem" }}
                      >
                        <FontAwesomeIcon fixedWidth={true} icon={faXmark} />
                      </IconButton>
                    ),
                    indicator: null,
                  })}
                {...field}
                data-testid={testId}
                onChange={(event, value) => field.onChange(value)}
              >
                {organizationUserEmails.map(({ userId, email }) => (
                  <Option
                    data-testid={`${optionTestId}${email.toLowerCase()}`}
                    key={userId}
                    value={email}
                  >
                    <FormControl>
                      <Checkbox checked={field.value.includes(email)} />
                    </FormControl>
                    {email}
                  </Option>
                ))}
              </Select>
              <FormHelperText>{error?.message}</FormHelperText>
            </FormControl>
          );
        }}
      />
    );
  }
);
ConfigurationFormParticipantsField.displayName =
  "ConfigurationFormParticipantsField";

export default ConfigurationFormParticipantsField as ConfigurationFormParticipantsFieldComponent;
