import { DqLoader } from "@decentriq/components";
import { faBan, faTriangleExclamation } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert, Box, Button, Typography } from "@mui/joy";
import { useCallback, useMemo, useState } from "react";
import {
  MediaDataRoomAudiencesTable,
  useAdvertiserAudiences,
  useMediaInsightsDcrData,
  usePublishedMediaInsightsDcr,
} from "features/MediaInsightsDcr";
import EmptyStateContainer from "../EmptyStateContainer/EmptyStateContainer";
import MediaDataRoomActivationSummary from "./MediaDataRoomActivationSummary";
import useMediaDataRoomPublishedAudiences from "./useMediaDataRoomPublishedAudiences";

const MediaDataRoomActivation: React.FC = () => {
  const {
    dataRoomId,
    isPublisher,
    isAdvertiser,
    driverAttestationHash,
    isDeactivated,
  } = usePublishedMediaInsightsDcr();
  const {
    availableAudiences: {
      status: availableAudiencesStatus,
      error: availableAudiencesError,
      retry: retryAvailableAudiences,
    },
    datasetsLoading: dataRoomDataLoading,
    hasRequiredData,
    isPublisherAudienceBeingUpdated,
  } = useMediaInsightsDcrData();
  const [retryInProgress, setRetryInProgress] = useState(false);
  const {
    activatedAudiences,
    viewAudiencesLoading: advertiserAudiencesLoading,
    viewAudiencesError: advertiserAudiencesError,
    retryViewAudiences: retryAdvertiserAudiences,
  } = useAdvertiserAudiences();
  const {
    audiences: publisherAudiences,
    loading: publisherAudiencesLoading,
    error: publisherAudiencesError,
    retry: retryPublisherAudiences,
  } = useMediaDataRoomPublishedAudiences({
    dataRoomId,
    driverAttestationHash,
    hasRequiredData,
    skip: useMemo(
      () => isDeactivated || !isPublisher,
      [isDeactivated, isPublisher]
    ),
  });
  const audiences = useMemo(() => {
    return isPublisher ? publisherAudiences : activatedAudiences;
  }, [isPublisher, publisherAudiences, activatedAudiences]);
  const loading = useMemo(() => {
    return (
      dataRoomDataLoading ||
      (isAdvertiser
        ? advertiserAudiencesLoading || availableAudiencesStatus !== "COMPLETED"
        : publisherAudiencesLoading)
    );
  }, [
    dataRoomDataLoading,
    isAdvertiser,
    advertiserAudiencesLoading,
    availableAudiencesStatus,
    publisherAudiencesLoading,
  ]);
  const retryComputations = useCallback(async () => {
    setRetryInProgress(true);
    try {
      if (isAdvertiser) {
        const retryPromises = [];
        if (advertiserAudiencesError) {
          retryPromises.push(retryAdvertiserAudiences());
        }
        if (availableAudiencesError) {
          retryPromises.push(retryAvailableAudiences());
        }
        await Promise.all(retryPromises);
      } else {
        await retryPublisherAudiences();
      }
    } finally {
      setRetryInProgress(false);
    }
  }, [
    advertiserAudiencesError,
    availableAudiencesError,
    isAdvertiser,
    retryAdvertiserAudiences,
    retryAvailableAudiences,
    retryPublisherAudiences,
  ]);

  if (isPublisherAudienceBeingUpdated) {
    return (
      <EmptyStateContainer>
        <FontAwesomeIcon fixedWidth={true} icon={faBan} size="2x" />
        <Typography level="body-sm" mt={1}>
          Publisher audience is being updated.
        </Typography>
        <Typography level="body-sm">
          {isPublisher
            ? "Please provision a datalab again."
            : "Activation will be available again soon."}
        </Typography>
      </EmptyStateContainer>
    );
  }
  if (!dataRoomDataLoading && !hasRequiredData) {
    return <Alert>Waiting for both parties to provision data.</Alert>;
  }

  if (
    publisherAudiencesError ||
    advertiserAudiencesError ||
    availableAudiencesError
  ) {
    return (
      <EmptyStateContainer gap={2}>
        <FontAwesomeIcon
          fixedWidth={true}
          icon={faTriangleExclamation}
          size="2x"
        />
        <Typography>Audiences could not be retrieved</Typography>
        {!isDeactivated && (
          <Button
            loading={retryInProgress}
            onClick={retryComputations}
            sx={{ marginTop: 2 }}
            variant="plain"
          >
            Retry
          </Button>
        )}
      </EmptyStateContainer>
    );
  }

  if (loading) {
    return (
      <EmptyStateContainer>
        <DqLoader
          label={
            isAdvertiser
              ? availableAudiencesStatus === "COMPUTING"
                ? "Computing lookalike models"
                : availableAudiencesStatus === "FETCHING"
                  ? "Retrieving audiences..."
                  : ""
              : isPublisher
                ? "Loading audiences..."
                : ""
          }
        />
      </EmptyStateContainer>
    );
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <MediaDataRoomActivationSummary />
      <MediaDataRoomAudiencesTable audiences={audiences} />
    </Box>
  );
};

MediaDataRoomActivation.displayName = "MediaDataRoomActivation";

export default MediaDataRoomActivation;
