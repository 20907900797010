import { useDataConnectorJobQuery } from "@decentriq/graphql/dist/hooks";
import { DataTargetType } from "@decentriq/graphql/dist/types";
import { Box, Grid, Typography } from "@mui/joy";
import { memo } from "react";
import {
  ExternalConnectionsIcon,
  ExternalConnectionsIconSize,
} from "features/datasets";
import { dataTargetTypePresentation } from "features/datasets/models";

type PermutiveConnectionConfigurationProps = {
  connectionId: string;
};

const PermutiveConnectionConfiguration: React.FC<
  PermutiveConnectionConfigurationProps
> = ({ connectionId }) => {
  const {
    data: { dataConnectorJob: { config: datasetExportConfig = {} } = {} } = {},
  } = useDataConnectorJobQuery({
    variables: { id: connectionId },
  });
  const { importId, segmentCode, segmentName, s3Provider } =
    datasetExportConfig || {};

  return (
    <Grid container={true} direction="column" xs={12}>
      <Typography level="body-sm">
        <strong>Import ID:</strong> {importId}
      </Typography>
      <Typography level="body-sm">
        <strong>Segment name:</strong> {segmentName}
      </Typography>
      <Typography level="body-sm">
        <strong>Segment code:</strong> {segmentCode}
      </Typography>
      <Typography
        level="body-sm"
        sx={{ alignItems: "center", display: "inline-flex" }}
      >
        <strong>Provider:</strong>
        <Box sx={{ alignItems: "center", display: "flex", ml: 0.5 }}>
          <ExternalConnectionsIcon
            connectionType={
              s3Provider ? DataTargetType.S3 : DataTargetType.GoogleCloudStorage
            }
            size={ExternalConnectionsIconSize.xs}
          />
          <Typography level="body-sm" sx={{ marginLeft: "4px" }}>
            {dataTargetTypePresentation.get(
              s3Provider ? DataTargetType.S3 : DataTargetType.GoogleCloudStorage
            )}
          </Typography>
        </Box>
      </Typography>
    </Grid>
  );
};

PermutiveConnectionConfiguration.displayName =
  "PermutiveConnectionConfiguration";

export default memo(PermutiveConnectionConfiguration);
