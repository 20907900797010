import { faChartSimple } from "@fortawesome/pro-light-svg-icons";
import { Box, useTheme } from "@mui/joy";
import { Bar } from "@nivo/bar";
import { groupBy, orderBy, uniqBy } from "lodash";
import { memo, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { AutoSizer } from "react-virtualized";
import { EmptyData } from "components";
import {
  type AggregationDataModel,
  MediaDataRoomInsightsChartValueKeySelector,
  SuppressedSegmentsInformation,
  useOverlapInsightsAggregation,
} from "features/mediaDataRoom";

const GenderBarChart: React.FC = () => {
  const { watch } = useFormContext();
  const chartValueKey = watch("genderChartValueKey");

  const { palette } = useTheme();

  const {
    aggregationData: genderAgeAggregationData,
    hasSuppressedValues,
    possibleValues,
    suppressedValues,
    hiddenValues: genderAgeHiddenAggregations,
    hasHiddenValues,
  } = useOverlapInsightsAggregation({ aggregation: ["gender", "age"] });

  // Grouping is applied in order to select 2 genders with highest number of rows
  // All the rest is added to the hiddenValues array
  const [visibleGendersData, hiddenGendersData] = orderBy(
    groupBy(genderAgeAggregationData, "gender"),
    (array) => array.length,
    "desc"
  ).reduce<AggregationDataModel[][]>(
    (values, currentGenderDataArray, index) => {
      const [visibleGenders, hiddenGenders] = values;
      return index < 2
        ? [[...visibleGenders, ...currentGenderDataArray], hiddenGenders]
        : [visibleGenders, [...hiddenGenders, ...currentGenderDataArray]];
    },
    [[], []]
  );

  // To render opposite values bar chart, each age group must have a value for each gender
  // So in that case, age groups that don't have this opposite value are hidden
  const [genderAgeChartValues, hiddenGenderAgeValues] = useMemo<
    AggregationDataModel[][]
  >(
    () =>
      Object.values(groupBy(visibleGendersData, "age")).reduce<any>(
        (data, currentAggregation) => {
          const [ageGroupData, hiddenAgeGroups] = data;
          return currentAggregation.length === 2
            ? [[...ageGroupData, currentAggregation], hiddenAgeGroups]
            : [ageGroupData, [...hiddenAgeGroups, currentAggregation]];
        },
        [[], []]
      ),
    [visibleGendersData]
  );

  const chartData = genderAgeChartValues.map(([gender1Data, gender2Data]) => ({
    [gender1Data?.gender]: gender1Data[chartValueKey],
    [gender2Data?.gender]: gender2Data[chartValueKey] * -1,
    // As gender pair is grouped, age and aggregationName values are same in both
    age: gender1Data?.age,
    id: gender1Data?.aggregationName,
    label: gender1Data?.aggregationName,
  }));

  const chartDataKeys: string[] = uniqBy(visibleGendersData, "gender").map(
    ({ gender }) => gender!
  );

  const hiddenValues = useMemo<string[]>(
    () =>
      [
        ...genderAgeHiddenAggregations,
        ...hiddenGendersData,
        ...hiddenGenderAgeValues,
      ].map(({ aggregationName }) => aggregationName),
    [genderAgeHiddenAggregations, hiddenGenderAgeValues, hiddenGendersData]
  );

  return (
    <Box
      display="flex"
      flex={1}
      flexDirection="column"
      height="100%"
      width="100%"
    >
      <MediaDataRoomInsightsChartValueKeySelector
        suppressedSegmentsInfoComponent={
          <SuppressedSegmentsInformation
            hiddenValues={hiddenValues}
            possibleValues={possibleValues}
            suppressedValues={suppressedValues}
          />
        }
        title="Age vs. Gender"
        withSuppressedSegments={hasSuppressedValues || hasHiddenValues}
      />
      <Box display="flex" flex={1} flexDirection="column">
        {chartData.length ? (
          <AutoSizer>
            {({ width, height }) => (
              <Bar
                animate={true}
                axisBottom={{
                  format: (value) => (value < 0 ? -value : value),
                }}
                colors={[palette.chart["300"], palette.chart["500"]]}
                data={chartData}
                enableGridX={true}
                enableGridY={false}
                enableLabel={false}
                groupMode="stacked"
                height={height}
                indexBy="age"
                keys={chartDataKeys}
                layout="horizontal"
                legends={[
                  {
                    anchor: "top",
                    dataFrom: "keys",
                    direction: "row",
                    itemHeight: 15,
                    itemWidth: 40,
                    itemsSpacing: 2,
                    symbolSize: 15,
                    translateY: -30,
                  },
                ]}
                margin={{
                  bottom: 24,
                  left: 48,
                  top: 40,
                }}
                valueFormat={(value) => (value < 0 ? -value : value).toString()}
                width={width}
              />
            )}
          </AutoSizer>
        ) : (
          <EmptyData
            icon={faChartSimple}
            secondaryText="No chart data available"
          />
        )}
      </Box>
    </Box>
  );
};

GenderBarChart.displayName = "GenderBarChart";

export default memo(GenderBarChart);
