import { differenceInSeconds, format, isValid } from "date-fns";
import { type FC, memo } from "react";
import { TimeAgoFormatted } from "components";

type AudienceCreationDateProps = {
  createdAt: unknown;
};

const AudienceCreationDate: FC<AudienceCreationDateProps> = memo(
  ({ createdAt }) => {
    if (!createdAt || !isValid(new Date(createdAt as string))) {
      return "N/A";
    }
    const now = new Date();
    const createdAtDate = new Date(createdAt as string);
    const isOlderThanAMinute = differenceInSeconds(now, createdAtDate) > 59;
    return isOlderThanAMinute ? (
      format(createdAtDate, "dd/MM/yyyy HH:mm")
    ) : (
      <TimeAgoFormatted date={createdAt as string} />
    );
  }
);

AudienceCreationDate.displayName = "AudienceCreationDate";
export default AudienceCreationDate;
