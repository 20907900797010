import { testIds } from "@decentriq/utils";
import {
  faBullhorn,
  faDatabase,
  faNewspaper,
} from "@fortawesome/pro-light-svg-icons";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Typography,
} from "@mui/joy";
import { memo, useState } from "react";
import { MediaDataRoomOrganizationRole } from "features/mediaDataRoom";
import { useCreationWizardStepper } from "features/mediaDataRoom/contexts";
import { useOrganizationPreferences } from "hooks";
import SelectOrganizationRoleButton from "./SelectOrganizationRoleButton";

const SelectOrganizationRoleStep: React.FC = () => {
  const {
    numberOfSupportedMediaRoles,
    hasAdvertiserFeatures,
    hasPublisherFeatures,
    hasDataPartnerFeatures,
  } = useOrganizationPreferences();
  const { setOrganizationRole: submitOrganizationRole, handleBackStep } =
    useCreationWizardStepper();
  const [organizationRole, setOrganizationRole] =
    useState<MediaDataRoomOrganizationRole | null>(null);
  const columnWidth = 12 / numberOfSupportedMediaRoles;
  return (
    <>
      <DialogTitle>Create new media clean room</DialogTitle>
      <Divider />
      <DialogContent>
        <Box display="flex" flexDirection="column" width="100%">
          <Typography fontWeight="fontWeightSemiBold" level="body-md" mb={0.5}>
            Organization role
          </Typography>
          <Typography level="body-sm" mb={1}>
            Select in which role you want to create the data clean room
          </Typography>
          <Grid columnSpacing={2} container={true}>
            {hasAdvertiserFeatures && (
              <Grid xs={columnWidth}>
                <SelectOrganizationRoleButton
                  icon={faBullhorn}
                  onSelect={setOrganizationRole}
                  role={MediaDataRoomOrganizationRole.ADVERTISER}
                  selected={
                    organizationRole ===
                    MediaDataRoomOrganizationRole.ADVERTISER
                  }
                  testId={
                    testIds.mediaInsightsDcr
                      .mediaInsightDcrCreateSelectOrganizationRole.advertiser
                  }
                />
              </Grid>
            )}
            {hasPublisherFeatures && (
              <Grid xs={columnWidth}>
                <SelectOrganizationRoleButton
                  icon={faNewspaper}
                  onSelect={setOrganizationRole}
                  role={MediaDataRoomOrganizationRole.PUBLISHER}
                  selected={
                    organizationRole === MediaDataRoomOrganizationRole.PUBLISHER
                  }
                  testId={
                    testIds.mediaInsightsDcr
                      .mediaInsightDcrCreateSelectOrganizationRole.publisher
                  }
                />
              </Grid>
            )}
            {hasDataPartnerFeatures && (
              <Grid xs={columnWidth}>
                <SelectOrganizationRoleButton
                  icon={faDatabase}
                  onSelect={setOrganizationRole}
                  role={MediaDataRoomOrganizationRole.DATA_PARTNER}
                  selected={
                    organizationRole ===
                    MediaDataRoomOrganizationRole.DATA_PARTNER
                  }
                  testId={
                    testIds.mediaInsightsDcr
                      .mediaInsightDcrCreateSelectOrganizationRole.dataPartner
                  }
                />
              </Grid>
            )}
          </Grid>
        </Box>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={handleBackStep}>Back</Button>
        <Button
          color="primary"
          disabled={!organizationRole}
          onClick={() => {
            if (!organizationRole) {
              return;
            }
            submitOrganizationRole(organizationRole);
          }}
          variant="solid"
        >
          Continue
        </Button>
      </DialogActions>
    </>
  );
};

SelectOrganizationRoleStep.displayName = "SelectOrganizationRoleStep";

export default memo(SelectOrganizationRoleStep);
