import { createContext, useContext } from "react";

export interface DataRoomContextValue {
  dataRoomId: string;
  isPublished: boolean;
  allowTestMode: boolean;
}

export const DataRoomContext = createContext<DataRoomContextValue>({
  allowTestMode: true,
  dataRoomId: "__default__",
  isPublished: false,
});

export const DataRoomProvider = DataRoomContext.Provider;

export const useDataRoom = () => useContext(DataRoomContext);
