import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Modal,
  ModalDialog,
} from "@mui/joy";
import { DataLabDetails, useDataLabContext } from "features/dataLabs";

interface DataLabDetailsDialogProps {
  id: string;
  open: boolean;
  onCancel?: () => void;
}

const DataLabDetailsDialog: React.FC<DataLabDetailsDialogProps> = ({
  id,
  open,
  onCancel,
}) => {
  const {
    dataLab: { data },
  } = useDataLabContext();
  const name = data?.name;
  return (
    <Modal onClose={onCancel} open={open}>
      <ModalDialog>
        <DialogTitle>{name}</DialogTitle>
        <Divider />
        <DialogContent>
          <DataLabDetails id={id} />
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button onClick={onCancel}>Close</Button>
        </DialogActions>
      </ModalDialog>
    </Modal>
  );
};

DataLabDetailsDialog.displayName = "DataLabDetailsDialog";

export default DataLabDetailsDialog;
