import { DqCard, DqCardContent } from "@decentriq/components";
import { type IconDefinition } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Typography } from "@mui/joy";
import { memo } from "react";
import { type MediaDataRoomOrganizationRole } from "features/mediaDataRoom/contexts";

interface SelectOrganizationRoleButtonProps {
  testId: string;
  icon: IconDefinition;
  role: MediaDataRoomOrganizationRole;
  selected: boolean;
  onSelect: (role: MediaDataRoomOrganizationRole) => void;
}

const SelectOrganizationRoleButton: React.FC<
  SelectOrganizationRoleButtonProps
> = ({ testId, icon, role, selected, onSelect }) => {
  return (
    <DqCard
      data-testid={testId}
      onSelect={() => onSelect(role)}
      selected={selected}
      sx={{ height: "100%" }}
    >
      <DqCardContent>
        <FontAwesomeIcon icon={icon} size="3x" />
        <Typography mb={0.5} mt={1} sx={{ fontWeight: 600 }} textAlign="center">
          {role}
        </Typography>
      </DqCardContent>
    </DqCard>
  );
};

SelectOrganizationRoleButton.displayName = "SelectOrganizationRoleButton";

export default memo(SelectOrganizationRoleButton);
