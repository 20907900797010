import {
  type ApolloClient,
  type NormalizedCacheObject,
  useApolloClient,
} from "@apollo/client";
import {
  useDraftDataRoomTestPublicationsLazyQuery,
  usePublishedDataRoomDataNodesLazyQuery,
} from "@decentriq/graphql/dist/hooks";
import {
  type DraftRawLeafNode,
  type DraftTableLeafNode,
  type TestDataset,
} from "@decentriq/graphql/dist/types";
import { Key } from "@decentriq/utils";
import { useCallback } from "react";
import { type KeychainItem, KeychainItemKind } from "services/keychain";
import { useApiCore, useDataRoom, useKeychain } from "contexts";
import {
  type DataNodeTypeNames,
  DraftDataNodeTypeNames,
  PublishedDataNodeTypeNames,
} from "models";
import { logError } from "utils";
import { hashedIdAsArray, retrieveTestPublishedDatasets } from "utils/apicore";

const useTestDatasets = (): (() => Promise<TestDataset[]>) => {
  const { isPublished, dataRoomId } = useDataRoom();
  const apolloCLient = useApolloClient();
  const keychain = useKeychain();
  const { store } = useApiCore();
  const [fetchDraft] = useDraftDataRoomTestPublicationsLazyQuery({
    variables: { dataRoomId },
  });
  const [fetchPublished] = usePublishedDataRoomDataNodesLazyQuery({
    variables: { dataRoomId },
  });
  return useCallback(async () => {
    try {
      const testDatasets: TestDataset[] = [];
      let testDatasetsWithoutKey: Omit<TestDataset, "encryptionKey">[] = [];
      if (isPublished) {
        testDatasetsWithoutKey = await fetchPublished().then(({ data }) => {
          if (!data) {
            return [];
          }
          return retrieveTestPublishedDatasets({
            client: apolloCLient as ApolloClient<NormalizedCacheObject>,
            dataRoomId,
            nodes:
              data?.publishedDataRoom?.publishedNodes
                ?.filter(
                  ({ __typename }) =>
                    __typename ===
                      PublishedDataNodeTypeNames.PublishedRawLeafNode ||
                    __typename ===
                      PublishedDataNodeTypeNames.PublishedTableLeafNode
                )
                .map(({ id, __typename }) => ({
                  __typename: __typename as DataNodeTypeNames,
                  id,
                })) || [],
          }).then((dict) =>
            [...dict]
              .filter(([_, value]) => !!value)
              .map(([key, value]) => ({
                leafNodeId: value!.leafId,
                manifestHash: value!.datasetHash,
              }))
          );
        });
      } else {
        testDatasetsWithoutKey = await fetchDraft().then(({ data }) => {
          if (!data) {
            return [];
          }
          return data.draftDataRoom.draftNodes.nodes
            .filter((node) => {
              if (
                node.__typename === DraftDataNodeTypeNames.DraftRawLeafNode ||
                node.__typename === DraftDataNodeTypeNames.DraftTableLeafNode
              ) {
                return (node as DraftRawLeafNode | DraftTableLeafNode)
                  .testModePublication;
              }
              return false;
            })
            .map((node) => {
              const {
                id: leafNodeId,
                testModePublication,
                __typename,
              } = node as DraftRawLeafNode | DraftTableLeafNode;
              return {
                leafNodeId: `${leafNodeId}${
                  __typename === "DraftRawLeafNode" ? "" : "_leaf"
                }`,
                manifestHash: testModePublication!.dataset.manifestHash,
              };
            });
        });
      }
      for (const node of testDatasetsWithoutKey) {
        const keychainItem: KeychainItem | null = await keychain
          .getItem(node.manifestHash, KeychainItemKind.Dataset)
          .catch((error) => {
            logError(error);
            return null;
          });
        if (!keychainItem) {
          return testDatasets;
        }
        const encryptionKey = store.push(
          new Key(hashedIdAsArray(keychainItem.value))
        );
        testDatasets.push({ ...node, encryptionKey });
      }
      return testDatasets;
    } catch (error) {
      logError(error);
      return [];
    }
  }, [
    apolloCLient,
    dataRoomId,
    fetchDraft,
    fetchPublished,
    isPublished,
    keychain,
    store,
  ]);
};

export default useTestDatasets;
