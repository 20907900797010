import { testIds } from "@decentriq/utils";
import { faNewspaper } from "@fortawesome/pro-light-svg-icons";
import { memo } from "react";
import { SidebarMenuItem } from "features/sidebar";

const PublisherPortalMenuItem = () => (
  <SidebarMenuItem
    data-testid={testIds.sidebar.sidebarMenuItems.publisherPortalButton}
    icon={faNewspaper}
    innerMenuItems={[
      { label: "DCRs", to: "/publisherportal/datarooms" },
      { label: "Datalabs", to: "/publisherportal/datalabs" },
      {
        label: "Configuration",
        to: "/publisherportal/publisher-configurations",
      },
    ]}
    label="Publisher Portal"
    to="/publisherportal"
    tooltipTitle="Publisher Portal"
  />
);

PublisherPortalMenuItem.displayName = "PublisherPortalMenuItem";

export default memo(PublisherPortalMenuItem);
