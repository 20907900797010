import { difference, orderBy } from "lodash";
import { useFormContext } from "react-hook-form";
import {
  type InsightsDetailedViewFormValues,
  type OverlapInsightsHookReturn,
  useMediaDataRoomOverlapInsights,
} from "features/MediaInsightsDcr";

const useAffinityRatioSegmentDemographicsData =
  (): OverlapInsightsHookReturn => {
    const { watch } = useFormContext<InsightsDetailedViewFormValues>();
    // Get data from the form context
    const selectedDataSortDirection = watch("dataSortDirection");

    const {
      aggregationData: segmentGenderAggregationData,
      isEmptyAggregation: isSegmentGenderAggregationEmpty,
      hasSuppressedValues: hasSegmentGenderSuppressedValues,
      possibleValues: segmentGenderPossibleValues,
      suppressedValues: segmentGenderSuppressedValues,
    } = useMediaDataRoomOverlapInsights({
      aggregation: ["interest", "gender"],
    });
    const {
      aggregationData: segmentAgeAggregationData,
      isEmptyAggregation: isSegmentAgeAggregationEmpty,
      hasSuppressedValues: hasSegmentAgeSuppressedValues,
      possibleValues: segmentAgePossibleValues,
      suppressedValues: segmentAgeSuppressedValues,
    } = useMediaDataRoomOverlapInsights({
      aggregation: ["interest", "age"],
    });

    const affinityRatioSegmentDemographicsData = orderBy(
      [...segmentGenderAggregationData, ...segmentAgeAggregationData],
      ["affinityRatio"],
      [selectedDataSortDirection]
    );

    const affinityRatioSegmentDemographicsHiddenValues = orderBy(
      difference(
        affinityRatioSegmentDemographicsData.map(
          ({ aggregationName }) => aggregationName
        ),
        affinityRatioSegmentDemographicsData
          .map(({ aggregationName }) => aggregationName)
          .slice(0, 10)
      )
    );

    const suppressedValues = orderBy([
      ...segmentGenderSuppressedValues,
      ...segmentAgeSuppressedValues,
    ]);

    return {
      aggregationData: affinityRatioSegmentDemographicsData.slice(0, 10),
      hasHiddenValues: !!affinityRatioSegmentDemographicsHiddenValues.length,
      hasSuppressedValues:
        hasSegmentGenderSuppressedValues || hasSegmentAgeSuppressedValues,
      hiddenValues: affinityRatioSegmentDemographicsHiddenValues,
      isEmptyAggregation:
        isSegmentGenderAggregationEmpty && isSegmentAgeAggregationEmpty,
      possibleValues: [
        ...segmentGenderPossibleValues,
        ...segmentAgePossibleValues,
      ],
      suppressedValues,
    };
  };

export default useAffinityRatioSegmentDemographicsData;
