import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Modal,
  ModalDialog,
  Typography,
  useTheme,
} from "@mui/joy";
import { memo } from "react";
import MediaDataRoomSummary from "./MediaDataRoomSummary";

interface MediaDataRoomViewConfigurationDialogProps {
  open: boolean;
  onCancel: () => void;
}

const MediaDataRoomViewConfigurationDialog: React.FC<MediaDataRoomViewConfigurationDialogProps> =
  memo(({ open, onCancel }) => {
    const { breakpoints } = useTheme();
    return (
      <Modal open={open}>
        <ModalDialog minWidth={breakpoints.values.md}>
          <DialogTitle
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              display: "flex",
              justifyContent: "space-between",
              lineHeight: "1.5rem",
              p: 1,
            }}
          >
            <Typography sx={{ fontSize: "20px", fontWeight: 500 }}>
              Media data clean room configuration
            </Typography>
            <IconButton onClick={onCancel}>
              <FontAwesomeIcon fixedWidth={true} icon={faTimes} />
            </IconButton>
          </DialogTitle>
          <Divider />
          <DialogContent>
            <Box p={1}>
              <MediaDataRoomSummary />
            </Box>
            <Box
              sx={{
                borderColor: "divider",
                borderTop: 1,
                display: "flex",
                justifyContent: "space-between",
                mt: "auto",
                p: 1,
              }}
            >
              <Button color="neutral" onClick={onCancel} variant="plain">
                Close
              </Button>
            </Box>
          </DialogContent>
        </ModalDialog>
      </Modal>
    );
  });

export default MediaDataRoomViewConfigurationDialog;
