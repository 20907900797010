import {
  DraftComputeNodeNameDocument,
  type DraftComputeNodeNameQuery,
  PublishedNodeTypenameDocument,
  type PublishedNodeTypenameQuery,
} from "@decentriq/graphql/dist/types";
import { type LocalResolverContext } from "../../models";

const getNodeById = (
  context: LocalResolverContext,
  nodeId: string,
  mutationArgs: any
):
  | PublishedNodeTypenameQuery["publishedNode"]
  | DraftComputeNodeNameQuery["draftNode"]
  | null => {
  const { dcrHash, driverAttestationHash, commitId } = mutationArgs.input;
  return (
    context.cache.readQuery<PublishedNodeTypenameQuery>({
      query: PublishedNodeTypenameDocument,
      variables: {
        commitId: commitId || null,
        dcrHash,
        driverAttestationHash,
        id: nodeId,
      },
    })?.publishedNode ||
    context.cache.readQuery<DraftComputeNodeNameQuery>({
      query: DraftComputeNodeNameDocument,
      variables: { computeNodeId: nodeId },
    })?.draftNode ||
    null
  );
};

export default getNodeById;
