import { useAuth0 } from "@auth0/auth0-react";
import { useDataRoomRequestTempApproversQuery } from "@decentriq/graphql/dist/hooks";
import { faInfoCircle } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Alert,
  Avatar,
  FormControl,
  FormLabel,
  List,
  ListItem,
  ListItemDecorator,
  Tooltip,
  Typography,
} from "@mui/joy";
import { memo } from "react";

interface RequestApproversProps {
  requestId: string;
}

const RequestApprovers: React.FC<RequestApproversProps> = memo(
  ({ requestId }) => {
    const { user } = useAuth0();
    const currentUserEmail = user?.email;
    const { data } = useDataRoomRequestTempApproversQuery({
      variables: {
        id: requestId,
      },
    });
    const approvers = data?.dataRoomRequest?.tempApprovers;
    return (
      <FormControl>
        <FormLabel>
          <Typography
            endDecorator={
              <Tooltip
                placement="top"
                sx={{ maxWidth: 300 }}
                title="All participants with permissions to provision datasets to dependencies of this computation are required to approve the request."
              >
                <FontAwesomeIcon icon={faInfoCircle} />
              </Tooltip>
            }
            level="inherit"
          >
            Affected data owners
          </Typography>
        </FormLabel>
        {approvers?.length ? (
          <List>
            {approvers.map((approver) => (
              <ListItem key={approver}>
                <ListItemDecorator>
                  <Avatar size="sm">
                    {approver.substring(0, 2).toUpperCase()}
                  </Avatar>
                </ListItemDecorator>
                <Typography
                  endDecorator={
                    approver === currentUserEmail && <strong>(You)</strong>
                  }
                  level="inherit"
                >
                  {approver}
                </Typography>
              </ListItem>
            ))}
          </List>
        ) : (
          <Alert>
            {approvers
              ? "No dependencies yet"
              : "Please validate the computation to determine the affected data owners."}
          </Alert>
        )}
      </FormControl>
    );
  }
);
RequestApprovers.displayName = "RequestApprovers";

export default RequestApprovers;
