import { createContext, type ReactNode, useContext } from "react";
import useLookalikeMediaDataRoomDataQuery, {
  type UseLookalikeMediaDataRoomDataQueryResult,
} from "features/mediaDataRoomV2/containers/MediaDataRoom/hooks/useLookalikeMediaDataRoomDataQuery/useLookalikeMediaDataRoomDataQuery";
import usePublishedMediaDataRoomQuery, {
  type PublishedLookalikeMediaDataRoomQueryHookResult,
} from "features/mediaDataRoomV2/containers/MediaDataRoom/hooks/usePublishedMediaDataRoomQuery/usePublishedMediaDataRoomQuery";
import useLookalikeMediaDataRoomStateQuery, {
  type UseLookalikeMediaDataRoomStateQueryResult,
} from "features/mediaDataRoomV2/containers/MediaDataRoom/useLookalikeMediaDataRoomStateQuery/useLookalikeMediaDataRoomStateQuery";

interface MediaInsightsDcrContextValue {
  publishedMediaDataRoom: PublishedLookalikeMediaDataRoomQueryHookResult;
  lookalikeMediaDataRoomData: UseLookalikeMediaDataRoomDataQueryResult;
  state: UseLookalikeMediaDataRoomStateQueryResult;
}

const MediaInsightsDcrContext = createContext<MediaInsightsDcrContextValue>({
  lookalikeMediaDataRoomData: {} as UseLookalikeMediaDataRoomDataQueryResult,
  // TODO fix this!
  publishedMediaDataRoom: {} as PublishedLookalikeMediaDataRoomQueryHookResult,
  state: {} as UseLookalikeMediaDataRoomStateQueryResult,
});

interface MediaInsightsDcrWrapperProps {
  // TODO @matyasfodor: make sure these props are not drilled through like this.
  isDeactivated: boolean;
  isStopped: boolean;
  dataRoomId: string;
  driverAttestationHash: string;
  children: ReactNode;
}

export const MediaInsightsDcrWrapper: React.FC<
  MediaInsightsDcrWrapperProps
> = ({
  children,
  dataRoomId,
  driverAttestationHash,
  isDeactivated,
  isStopped,
}) => {
  const publishedMediaDataRoom = usePublishedMediaDataRoomQuery({
    dataRoomId,
    driverAttestationHash,
    isDeactivated,
    isStopped,
  });
  const lookalikeMediaDataRoomData = useLookalikeMediaDataRoomDataQuery({
    publishedMediaDataRoom,
  });
  const state = useLookalikeMediaDataRoomStateQuery({
    dataRoomId,
    driverAttestationHash,
  });
  return (
    <MediaInsightsDcrContext.Provider
      value={{
        lookalikeMediaDataRoomData,
        publishedMediaDataRoom,
        state,
      }}
    >
      {children}
    </MediaInsightsDcrContext.Provider>
  );
};

export const useMediaInsightsDcr = () => useContext(MediaInsightsDcrContext);
export const usePublishedLookalikeMediaDataRoom = () =>
  useMediaInsightsDcr().publishedMediaDataRoom;
export const useLookalikeMediaDataRoomData = () =>
  useMediaInsightsDcr().lookalikeMediaDataRoomData;
export const useLookalikeMediaDataRoomState = () => useMediaInsightsDcr().state;
