import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { DraftDataRoom } from "containers";
import { DataRoomErrorReportProvider, DataRoomProvider } from "contexts";

const DraftDataRoomPage = () => {
  const { dataRoomId } = useParams();
  const contextValue = useMemo(
    () => ({
      allowTestMode: true,
      dataRoomId: dataRoomId || "__default__",
      isPublished: false,
    }),
    [dataRoomId]
  );
  const errorContextValue = useMemo(
    () => ({ dataRoomId: dataRoomId || "__default__" }),
    [dataRoomId]
  );
  return (
    <DataRoomProvider value={contextValue}>
      <DataRoomErrorReportProvider value={errorContextValue}>
        <DraftDataRoom dataRoomId={dataRoomId!} />
      </DataRoomErrorReportProvider>
    </DataRoomProvider>
  );
};

export default DraftDataRoomPage;
