import { useCallback, useState } from "react";

type EntityId = string | number;

export interface NormalizedStateHookOptions<E> {
  initialValues: E[];
  id: (entity: E) => EntityId;
}

type NormalizedStateAction<E> = (entity: E) => void;
type NormalizedStatePartialAction<E> = (entity: Partial<E>) => void;
type NormalizedStateRemoveAction = (id: EntityId) => void;
type NormalizedStateResetAction<E> = (entities?: E[]) => void;

interface NormalizedStateHookResult<E> {
  state: NormalizedState<E>;
  entities: E[];
  byId: NormalizedState<E>["byId"];
  add: NormalizedStateAction<E>;
  update: NormalizedStatePartialAction<E>;
  addOrUpdate: NormalizedStatePartialAction<E>;
  remove: NormalizedStateRemoveAction;
  reset: NormalizedStateResetAction<E>;
}

export interface NormalizedState<E> {
  byId: {
    [key in EntityId]: E;
  };
  all: EntityId[];
}

const normalize = <E>(
  entities: E[],
  options: NormalizedStateHookOptions<E>
): NormalizedState<E> =>
  entities.reduce(
    (acc, v) => {
      const id = options.id(v);
      const nextAcc: NormalizedState<E> = {
        all: [...acc.all, id],
        byId: {
          ...acc.byId,
          [id]: v,
        },
      };
      return nextAcc;
    },
    { all: [], byId: {} } as NormalizedState<E>
  );

export const useNormalizedState = <E>(
  options: NormalizedStateHookOptions<E>
): NormalizedStateHookResult<E> => {
  const [state, setState] = useState<NormalizedState<E>>(
    normalize(options.initialValues, options)
  );
  const add: NormalizedStateAction<E> = useCallback(
    (entity: E) =>
      setState((state) => ({
        all: [...state.all, options.id(entity)],
        byId: {
          ...state.byId,
          [options.id(entity)]: entity,
        },
      })),
    [setState, options]
  );
  const update: NormalizedStatePartialAction<E> = useCallback(
    (entity: Partial<E>) => {
      const id = options.id(entity as E);
      if (!id) throw Error("Entity id must be provided");
      setState((state) => ({
        ...state,
        byId: {
          ...state.byId,
          [id]: {
            ...state.byId[id],
            ...entity,
          },
        },
      }));
    },
    [setState, options]
  );
  const remove: NormalizedStateRemoveAction = useCallback(
    (id: EntityId) =>
      setState((state) => {
        const byId: NormalizedState<E>["byId"] = {};
        const all = state.all.filter((i) => {
          const shouldStay = i !== id;
          if (shouldStay) {
            byId[i] = state.byId[i];
          }
          return shouldStay;
        });
        return {
          all,
          byId,
        };
      }),
    [setState]
  );
  const addOrUpdate: NormalizedStatePartialAction<E> = useCallback(
    (entity: Partial<E>) => {
      const id = options.id(entity as E);
      if (!id) throw Error("Entity id must be provided");
      if (state.byId[id]) {
        update(entity);
        return;
      }
      add(entity as E);
    },
    [options, add, update, state.byId]
  );
  const reset: NormalizedStateResetAction<E> = useCallback(
    (entities?: E[]) => {
      setState(entities ? normalize(entities, options) : { all: [], byId: {} });
    },
    [options, setState]
  );
  return {
    add,
    addOrUpdate,
    byId: state.byId,
    entities: state.all.map((id) => state.byId[id]),
    remove,
    reset,
    state,
    update,
  };
};
