import { faPlus } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormControl, FormHelperText, Input } from "@mui/joy";
import {
  type ChangeEventHandler,
  type KeyboardEventHandler,
  memo,
  useCallback,
  useMemo,
  useState,
} from "react";
import { type ApiToken } from "features/accessTokens/models";

interface AccessTokensConstructorFormProps {
  tokens: ApiToken[];
  onCreateApiToken: (tokenName: string) => Promise<string>;
}

const AccessTokensConstructorForm = memo<AccessTokensConstructorFormProps>(
  ({ tokens, onCreateApiToken }) => {
    const tokenNames = tokens.map((token) => token.name);
    const handleAddToken = useCallback(
      (tokenName: string) => {
        onCreateApiToken(tokenName);
      },
      [onCreateApiToken]
    );
    const [value, setValue] = useState("");
    const error = useMemo(() => {
      return value.trim().length > 0
        ? tokenNames.includes(value)
          ? "Token name must be unique"
          : undefined
        : undefined;
    }, [tokenNames, value]);
    const onChange = useCallback<
      ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>
    >((event) => {
      setValue(event.target.value);
    }, []);
    const onKeyDown = useCallback<
      KeyboardEventHandler<HTMLTextAreaElement | HTMLInputElement>
    >(
      (event) => {
        if (event.key === "Enter" && value.length > 0 && !error) {
          handleAddToken(value);
          setValue("");
        }
      },
      [error, handleAddToken, value]
    );
    return (
      <FormControl error={Boolean(error)}>
        <Input
          autoFocus={true}
          data-testid="new_access_token_btn"
          onChange={onChange}
          onKeyDown={onKeyDown}
          placeholder="Add new access token"
          startDecorator={<FontAwesomeIcon icon={faPlus} />}
          value={value}
        />
        <FormHelperText>{error}</FormHelperText>
      </FormControl>
    );
  }
);

AccessTokensConstructorForm.displayName = "AccessTokensConstructorForm";

export default AccessTokensConstructorForm;
