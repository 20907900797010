import { type VersionedSchema } from "@decentriq/components/dist/components/DatasetUploader/types";
import {
  ColumnDataType,
  TableColumnFormatType,
  TableColumnHashingAlgorithm,
} from "@decentriq/graphql/dist/types";
import { z } from "zod";
import { dataRoomTableFormatTypePresentation } from "features/dataNodes/components/DataNodeConstructor/DataNodeConstructorModels";
import { dataRoomTablePrimitiveTypePresentation } from "models";

export const datasetValidationSchemaV1 = z.object({
  schema: z.array(
    z.object({
      name: z.string(),
      nullable: z.boolean(),
      primitiveType: z.nativeEnum(ColumnDataType),
    })
  ),
});

export const datasetValidationSchemaV2 = z.object({
  columns: z.array(
    z
      .object({
        formatType: z.nativeEnum(TableColumnFormatType),
        hashWith: z.nativeEnum(TableColumnHashingAlgorithm).nullable(),
        name: z.string(),
        nullable: z.boolean(),
      })
      .partial({
        hashWith: true,
      })
  ),
  version: z.literal("2"),
});

export type DatasetSchemaV1 = z.infer<typeof datasetValidationSchemaV1>;

export type DatasetSchemaV2 = z.infer<typeof datasetValidationSchemaV2>;

export type DatasetSchema = DatasetSchemaV1 | DatasetSchemaV2;

export interface UnifiedSchemaPresentation {
  columns: {
    name: string;
    type: string;
    nullable: boolean;
    hashed: boolean | null;
  }[];
}

export const mapSchemaToUnifiedSchemaPresentation = (
  schema: DatasetSchema
): UnifiedSchemaPresentation => {
  if ("schema" in schema) {
    return {
      columns: schema.schema.map(({ name, nullable, primitiveType }) => ({
        hashed: null,
        name,
        nullable,
        type: dataRoomTablePrimitiveTypePresentation.get(primitiveType)!,
      })),
    };
  }
  return {
    columns: schema.columns.map(({ name, nullable, formatType, hashWith }) => ({
      hashed: Boolean(hashWith),
      name,
      nullable,
      type: dataRoomTableFormatTypePresentation.get(formatType)!,
    })),
  };
};

export const validateDatasetSchema = (rawSchema: object): DatasetSchema => {
  if ("schema" in rawSchema) {
    return datasetValidationSchemaV1.parse(rawSchema);
  }
  return datasetValidationSchemaV2.parse(rawSchema);
};

export const mapVersionedSchemaToDatasetSchema = (
  versionedSchema: VersionedSchema
): DatasetSchema | undefined => {
  if (versionedSchema.version === "2") {
    return {
      columns: versionedSchema.columns.map(
        ({ formatType, hashWith, name, nullable }) => ({
          formatType,
          hashWith,
          name,
          nullable,
        })
      ),
      version: "2",
    };
  }
};
