import { createContext, useContext } from "react";

interface RequestVars {
  checkRequestSelection: (requestId: string) => boolean;
  toggleRequest: (requestId: string) => void;
  selectRequest: (requestId: string) => void;
  unSelectAllRequests: () => void;
  context: "draft" | "submitted";
}

export const RequestVarsContext = createContext<RequestVars>({
  checkRequestSelection: () => false,
  context: "draft",
  selectRequest: () => {},
  toggleRequest: () => {},
  unSelectAllRequests: () => {},
});

export const RequestsVarsProvider = RequestVarsContext.Provider;

export const useRequestsVars = () => useContext(RequestVarsContext);

export default useRequestsVars;
