// @ts-nocheck
import { Box } from "@mui/joy";
import { AppBar, type Theme, Toolbar } from "@mui/material";
import { useMatch } from "react-router-dom";
import { makeStyles } from "tss-react/mui";
import {
  DataRoomDescriptionEditor,
  DataRoomNameEditor,
  DataRoomNameEditorEndAdornment,
} from "containers";
import DataRoomBrandingLogo from "containers/DataRoomBrandingLogo/DataRoomBrandingLogo";
import { useDataRoom } from "contexts";
import { DataRoomActions } from "features";
import EnclaveSpecActions from "features/publishedDataRoom/components/PublishedDataRoomActions/EnclaveSpecActions/EnclaveSpecActions";
import TestModeSwitch from "features/publishedDataRoom/components/PublishedDataRoomActions/TestModeSwitch/TestModeSwitch";
import { dataRoomPrefixType } from "models";

export const useToolbarStyles = makeStyles()((theme: Theme) => ({
  menuLeftWrapper: {
    alignItems: "center",
    display: "flex",
    flex: 1,
    flexDirection: "row",
    marginRight: theme.spacing(0.5),
    maxWidth: "100%",
    overflow: "hidden",
  },
  nameEditorWrapper: {
    "&:not(:first-child)": {
      marginLeft: theme.spacing(0.5),
    },
    "&:not(:last-child)": {
      marginRight: theme.spacing(0.5),
    },
    alignItems: "center",
    display: "flex",
    lineHeight: "24px",
    maxWidth: "100%",
    padding: theme.spacing(0.5),
  },
  toolbar: {
    display: "grid",
    gridAutoFlow: "column",
    gridTemplateColumns: "minmax(0, 1fr) auto",
    justifyContent: "space-between",
    margin: theme.spacing(1.25, 2),
    minHeight: "32px !important",
  },
}));

const Mainbar = () => {
  const { classes: toolbarClasses } = useToolbarStyles();
  const { isPublished } = useDataRoom();
  const { toolbar, menuLeftWrapper, nameEditorWrapper } = toolbarClasses;
  const { params } =
    useMatch({
      end: false,
      path: "/datarooms/:type/:dataRoomId",
    }) || {};
  const { type, dataRoomId } = params || {};
  const __typename = dataRoomPrefixType[type];
  const actions = ({ buttons, menuLists }) => ({
    buttons,
    menuLists,
  });
  return (
    <AppBar color="transparent" elevation={0} position="static">
      <Box sx={(theme) => ({ pb: theme.spacing(2) })}>
        <Toolbar className={toolbar} disableGutters={true}>
          <div className={menuLeftWrapper}>
            <div className={nameEditorWrapper}>
              <DataRoomNameEditor
                dataRoomId={dataRoomId}
                endAdornment={
                  <DataRoomNameEditorEndAdornment
                    __typename={__typename}
                    id={dataRoomId}
                  />
                }
              />
            </div>
            {isPublished && <DataRoomBrandingLogo id={dataRoomId} />}
          </div>
          <Box>
            <Box sx={{ display: "flex" }}>
              {isPublished && <TestModeSwitch />}
              {isPublished && <EnclaveSpecActions />}
              <DataRoomActions
                __typename={__typename}
                actions={actions}
                id={dataRoomId}
                inline={false}
              />
            </Box>
          </Box>
        </Toolbar>
        <DataRoomDescriptionEditor dataRoomId={dataRoomId} />
      </Box>
    </AppBar>
  );
};

export default Mainbar;
