import { useEffect, useState } from "react";
import { OverlapInsightsExecutionContext } from "features/mediaDataRoom";
import {
  useMediaDataRoomInsightsData,
  useMediaDataRoomOverlapInsights,
} from "features/mediaDataRoom/contexts";
import {
  type ModelledAggregationsCollection,
  type SegmentAggregationsCollection,
} from "wrappers/ApolloWrapper/resolvers/LookalikeMediaMutations";

const useSelectedAudienceAggregations = (
  selectedAudienceType: string
): SegmentAggregationsCollection[] => {
  const [audienceTypeAggregations, setAudienceTypeAggregations] = useState<
    SegmentAggregationsCollection[] | ModelledAggregationsCollection[]
  >([]);
  const {
    executionContext,
    reach: activationAudienceReach,
    audienceType: activationAudienceType,
  } = useMediaDataRoomOverlapInsights();
  const {
    insights: { computeResults: { insights: overlapInsights = [] } = {} },
  } = useMediaDataRoomInsightsData();

  useEffect(() => {
    const { aggregations = [] } =
      overlapInsights.find(({ audience_type, reach }) =>
        executionContext !== OverlapInsightsExecutionContext.insightsTab &&
        activationAudienceType
          ? audience_type === activationAudienceType &&
            reach === activationAudienceReach
          : audience_type === selectedAudienceType
      ) || {};
    setAudienceTypeAggregations(aggregations || []);
  }, [
    activationAudienceReach,
    activationAudienceType,
    executionContext,
    overlapInsights,
    selectedAudienceType,
  ]);

  return audienceTypeAggregations || [];
};

export default useSelectedAudienceAggregations;
