import { createContext, useContext } from "react";

export const DevComputeNodeContext = createContext<{
  playgroundId: string;
}>({
  playgroundId: "__default__",
});

export const DevComputeNodeProvider = DevComputeNodeContext.Provider;

export const useDevComputeNode = () => useContext(DevComputeNodeContext);
