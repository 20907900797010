import { createContext, useContext } from "react";

export interface DatasetPopupConfigContextValue {
  disableDelete?: boolean;
  disableExport?: boolean;
}

export const DatasetPopupConfigContext =
  createContext<DatasetPopupConfigContextValue>({
    disableDelete: false,
    disableExport: false,
  });

export const DatasetPopupConfigProvider = DatasetPopupConfigContext.Provider;

export const useDatasetPopupConfig = () =>
  useContext(DatasetPopupConfigContext);
