import { Box } from "@mui/joy";
import React, { memo } from "react";
import {
  DataLabCreateButton,
  DataLabsList,
  DataLabsWrapper,
} from "features/dataLabs";

const DataLabs: React.FC = () => {
  return (
    <DataLabsWrapper>
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          justifyContent: "flex-end",
          pt: 2,
          px: 2,
        }}
      >
        <DataLabCreateButton />
      </Box>
      <DataLabsList />
    </DataLabsWrapper>
  );
};

export default memo(DataLabs);
