import { faDatabase } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, ListItemDecorator, MenuItem } from "@mui/joy";
import { useSnackbar } from "notistack";
import { memo, type SetStateAction, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { type ActivationType, type Audience } from "features/MediaInsightsDcr";
import { useStoreAsDataset } from "./useStoreAsDataset";

type SaveAsDatasetMenuItemProps = {
  activationType: ActivationType;
  audienceType: string;
  reach: number;
  activatedAudiences: Audience[];
  setExportAudienceLoading: (isLoading: SetStateAction<boolean>) => void;
};

const SaveAsDatasetMenuItem: React.FC<SaveAsDatasetMenuItemProps> = ({
  activationType,
  activatedAudiences,
  audienceType,
  reach,
  setExportAudienceLoading,
}) => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { saveAsDataSet } = useStoreAsDataset({
    activatedAudiences,
    activationType,
    audienceType,
    reach,
  });
  const savePublisherAudienceAsDataset = useCallback(async () => {
    setExportAudienceLoading(true);
    try {
      await saveAsDataSet();
      enqueueSnackbar({
        action: (
          <Button onClick={() => navigate("/datasets/external")}>
            Go to Datasets
          </Button>
        ),
        message: `${audienceType} result is being stored. Please check the status in the 'Datasets' page.`,
      });
    } finally {
      setExportAudienceLoading(false);
    }
  }, [
    audienceType,
    enqueueSnackbar,
    navigate,
    saveAsDataSet,
    setExportAudienceLoading,
  ]);
  return (
    <MenuItem onClick={savePublisherAudienceAsDataset}>
      <ListItemDecorator>
        <FontAwesomeIcon fixedWidth={true} icon={faDatabase} />
      </ListItemDecorator>
      Store as dataset
    </MenuItem>
  );
};
SaveAsDatasetMenuItem.displayName = "SaveAsDatasetMenuItem";

export default memo(SaveAsDatasetMenuItem);
