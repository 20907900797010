import { PASSWORD_REGEXP } from "constants/index";
import { useCreateDemoUserMutation } from "@decentriq/graphql/dist/hooks";
import { UserFragment, UserRole } from "@decentriq/graphql/dist/types";
import { CreateDemoUserInputSchema } from "@decentriq/graphql/dist/validation";
import { faEye, faEyeSlash } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  FormControl,
  FormHelperText,
  FormLabel,
  IconButton,
  Input,
  Stack,
} from "@mui/joy";
import isEmpty from "lodash/isEmpty";
import { memo, useCallback, useEffect, useState } from "react";
import { Controller, useForm, useFormState } from "react-hook-form";
import { z } from "zod";
import { AdminDialog, ImageUploadButton } from "components";
import { CommonSnackbarOrigin, useGeneralSnackbar, useUserRole } from "hooks";

type AddDemoUserDialogProps = {
  organizationId: string;
  open: boolean;
  onCancel: () => void;
};

const validationSchema = CreateDemoUserInputSchema();

const addDemoUserValidationSchema = validationSchema
  .extend({
    email: (validationSchema.shape.email as z.ZodString).email(),
    password: (validationSchema.shape.password as z.ZodString).regex(
      PASSWORD_REGEXP,
      "Password must contain at least 8 characters, including uppercase, lowercase and numbers."
    ),
    repeatPassword: z.string(),
  })
  .refine(({ password, repeatPassword }) => password === repeatPassword, {
    message: "Passwords don't match",
    path: ["repeatPassword"],
  });

const AddDemoUserDialog: React.FC<AddDemoUserDialogProps> = ({
  organizationId,
  open,
  onCancel,
}) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showRepeatPassword, setShowRepeatPassword] = useState<boolean>(false);

  const { isSuperAdminReadOnly } = useUserRole();

  const { handleSubmit, control, reset, watch, setValue, ...restForm } =
    useForm({
      defaultValues: {
        email: "",
        logo: "",
        password: "",
        repeatPassword: "",
        userRole: UserRole.NormalUser,
      },
      mode: "onChange",
      reValidateMode: "onChange",
      resolver: zodResolver(addDemoUserValidationSchema),
    });
  const { isValid } = useFormState({ control });
  const logo = watch("logo");

  const { enqueueSnackbar } = useGeneralSnackbar({
    origin: CommonSnackbarOrigin.ADMIN,
  });

  const [createDemoUser, { loading }] = useCreateDemoUserMutation({
    onCompleted: () => {
      enqueueSnackbar(`Demo user has been successfully created.`);
      onCancel();
    },
    onError: (error) =>
      enqueueSnackbar(`Demo user could not be created.`, {
        context: error?.message,
        persist: true,
        variant: "error",
      }),
    update: (cache, { data }) => {
      cache.modify({
        fields: {
          users: (existing = {}) => {
            const userRef = cache.writeFragment({
              data: data?.organization?.createDemoUser?.record,
              fragment: UserFragment,
            });
            return {
              ...existing,
              nodes: [userRef, ...(existing?.nodes || [])],
              totalCount: Math.max((existing?.totalCount || 0) + 1, 0),
            };
          },
        },
        id: cache.identify({
          __typename: "Organization",
          id: organizationId,
        }),
      });
    },
  });

  const handleCreateDemoUser = useCallback(() => {
    const { email, password, logo, userRole } = restForm.getValues();
    createDemoUser({
      variables: {
        input: {
          email,
          logo,
          password,
          userRole,
        },
      },
    });
  }, [createDemoUser, restForm]);

  useEffect(() => {
    // Clean fields whenever modal is closed
    reset();
  }, [open, reset]);

  const handleShowPassword = useCallback(
    () => setShowPassword(!showPassword),
    [showPassword]
  );
  const handleShowRepeatPassword = useCallback(
    () => setShowRepeatPassword(!showRepeatPassword),
    [showRepeatPassword]
  );

  return (
    <AdminDialog
      disabled={!isValid}
      loading={loading}
      onClose={onCancel}
      onConfirm={handleSubmit(handleCreateDemoUser)}
      open={open}
      title="Create demo user"
    >
      <form>
        <Stack>
          <Controller
            control={control}
            name="logo"
            render={({ field: { onChange } }) => {
              return (
                <FormControl>
                  <FormLabel>Logo</FormLabel>
                  <Stack alignItems="center">
                    {logo && (
                      <img
                        alt="Add user logo"
                        src={`data:image;base64,${logo}`}
                        style={{ height: 70, width: 70 }}
                      />
                    )}
                    <ImageUploadButton
                      disabled={isSuperAdminReadOnly}
                      onImageSelected={onChange}
                    />
                  </Stack>
                </FormControl>
              );
            }}
          />
          <Controller
            control={control}
            name="email"
            render={({ field, formState }) => {
              const { errors } = formState;
              return (
                <FormControl error={!isEmpty(errors["email"])}>
                  <FormLabel>Email</FormLabel>
                  <Input placeholder="Email" {...field} />
                  <FormHelperText>{errors["email"]?.message}</FormHelperText>
                </FormControl>
              );
            }}
          />
          <Controller
            control={control}
            name="password"
            render={({ field, formState }) => {
              const { errors } = formState;
              return (
                <FormControl error={!isEmpty(errors["password"])}>
                  <FormLabel>Password</FormLabel>
                  <Input
                    endDecorator={
                      <IconButton onClick={handleShowPassword}>
                        <FontAwesomeIcon
                          fixedWidth={true}
                          icon={showPassword ? faEyeSlash : faEye}
                        />
                      </IconButton>
                    }
                    placeholder="Password"
                    type={showPassword ? "text" : "password"}
                    {...field}
                  />
                  <FormHelperText>{errors["password"]?.message}</FormHelperText>
                </FormControl>
              );
            }}
          />
          <Controller
            control={control}
            name="repeatPassword"
            render={({ field, formState }) => {
              const { errors } = formState;
              return (
                <FormControl error={!isEmpty(errors["repeatPassword"])}>
                  <FormLabel>Repeat password</FormLabel>
                  <Input
                    endDecorator={
                      <IconButton onClick={handleShowRepeatPassword}>
                        <FontAwesomeIcon
                          fixedWidth={true}
                          icon={showRepeatPassword ? faEyeSlash : faEye}
                        />
                      </IconButton>
                    }
                    placeholder="Repeat password"
                    type={showRepeatPassword ? "text" : "password"}
                    {...field}
                  />
                  <FormHelperText>
                    {errors["repeatPassword"]?.message}
                  </FormHelperText>
                </FormControl>
              );
            }}
          />
        </Stack>
      </form>
    </AdminDialog>
  );
};

AddDemoUserDialog.displayName = "AddDemoUserDialog";

export default memo(AddDemoUserDialog);
