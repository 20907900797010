import { type media_insights_request } from "@decentriq/core";
import { useMutation } from "@tanstack/react-query";
import { loadAsync } from "jszip";
import { useApiCore } from "contexts";

interface ModelPerformanceReportGenerationHookPayload {
  dataRoomId: string;
  driverAttestationHash: string;
}

const useModelPerformanceReportGeneration = ({
  driverAttestationHash,
  dataRoomId,
}: ModelPerformanceReportGenerationHookPayload) => {
  const { sessionManager, client } = useApiCore();
  return useMutation<string | null, Error>({
    mutationFn: async () => {
      const session = await sessionManager.get({ driverAttestationHash });
      const scopeId = await client.ensureDcrDataScope(dataRoomId);
      const request: media_insights_request.MediaInsightsRequest = {
        retrieveModelQualityReport: {
          dataRoomIdHex: dataRoomId,
          scopeIdHex: scopeId,
        },
      };
      const response = await session.sendMediaInsightsRequest(request);
      let report: string | null = null;
      if ("retrieveModelQualityReport" in response) {
        const computeNodeName =
          response.retrieveModelQualityReport.computeNodeName;
        const jobIdHex = response.retrieveModelQualityReport.jobIdHex;
        const result = await session.getComputationResult(
          { computeNodeId: computeNodeName, jobId: jobIdHex },
          { interval: 1 }
        );
        const zip = await loadAsync(result);
        const modelQualityReport = zip.file("model_quality.json");
        if (modelQualityReport === null) {
          throw new Error("model_quality.json not found in zip");
        }
        report = JSON.parse(await modelQualityReport.async("string"));
      } else {
        throw new Error("Can' find retrieveModelQualityReport in response");
      }
      if (!report) {
        throw new Error("Model quality report is empty");
      }
      return report;
    },
  });
};

export default useModelPerformanceReportGeneration;
