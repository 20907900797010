import { faArrowUpRightFromSquare } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton } from "@mui/joy";
import { useBoolean } from "ahooks";
import { memo } from "react";
import { DatasetPopup } from "features/datasets";

interface PreviewDatasetButtonProps {
  manifestHash: string;
}

const PreviewDatasetButton = memo<PreviewDatasetButtonProps>(
  ({ manifestHash }) => {
    const [
      isDatasetPopupOpen,
      { setTrue: openDatasetPopup, setFalse: closeDatasetPopup },
    ] = useBoolean(false);
    return (
      <>
        <IconButton onClick={openDatasetPopup}>
          <FontAwesomeIcon fixedWidth={true} icon={faArrowUpRightFromSquare} />
        </IconButton>
        <DatasetPopup
          manifestHash={manifestHash}
          onDismiss={closeDatasetPopup}
          open={isDatasetPopupOpen}
        />
      </>
    );
  }
);

PreviewDatasetButton.displayName = "PreviewDatasetButton";

export default PreviewDatasetButton;
