import { configuration } from "./configuration/configuration";

/* eslint-disable */
export const logError: (message?: any, ...segments: any[]) => void =
  console.error;

export const logInfo: (...info: any[]) => void = console.info;

export const logWarning: (message?: any, ...segments: any[]) => void =
  console.warn;

export const logDebug: (message?: any, ...segments: any[]) => void = (
  ...args
) => {
  if (configuration.enableDebugLogs) {
    console.debug(...args);
  }
};
