// TODO: Fix joy migration
import { faExclamationTriangle } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Modal,
  ModalDialog,
  Typography,
} from "@mui/joy";
import { memo } from "react";

interface ConfigurationDeleteDialogProps {
  title: string;
  description: string;
  open: boolean;
  loading: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  confirmationTestId?: string;
}

const ConfigurationDeleteDialog: React.FC<ConfigurationDeleteDialogProps> = ({
  open,
  loading,
  onCancel,
  onConfirm,
  title,
  description,
  confirmationTestId,
}) => {
  return (
    <Modal onClose={!loading ? onCancel : () => {}} open={open}>
      <ModalDialog role="alertdialog">
        <DialogTitle>
          <Box sx={{ color: "error.main", mb: 1, textAlign: "center" }}>
            <FontAwesomeIcon
              fixedWidth={true}
              icon={faExclamationTriangle}
              size="4x"
            />
          </Box>
          <Typography
            align="center"
            level="title-md"
            sx={{ textWrap: "balance" }}
          >
            <strong>{title}</strong>
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Typography
            align="center"
            level="body-sm"
            sx={{ textWrap: "balance" }}
          >
            {description}
          </Typography>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button disabled={loading} onClick={onCancel}>
            Cancel
          </Button>
          <Button
            color="danger"
            data-testid={confirmationTestId}
            loading={loading}
            loadingPosition="start"
            onClick={onConfirm}
            startDecorator={<FontAwesomeIcon icon={faExclamationTriangle} />}
            variant="solid"
          >
            Delete
          </Button>
        </DialogActions>
      </ModalDialog>
    </Modal>
  );
};

ConfigurationDeleteDialog.displayName = "ConfigurationDeleteDialog";

export default memo(ConfigurationDeleteDialog);
