import { createContext } from "react";
import { useSafeContext } from "hooks";
import { type Keychain } from "../../services/keychain";

export type KeychainContextValue = Keychain;

export const KeychainContext = createContext<KeychainContextValue | null>(null);

export const KeychainProvider = KeychainContext.Provider;

KeychainContext.displayName = "KeychainContext";

export const useKeychain = () => useSafeContext(KeychainContext);
