import { Box, Stack, Typography } from "@mui/joy";
import { memo } from "react";
import { EmptyData } from "components";
import {
  DataLabDownloadValidationReportButton,
  DataLabStatistics,
  DataLabValidationAlerts,
  DataLabValidationButton,
  useDataLabContext,
} from "features/dataLabs";

const DataLabQualityStatistics: React.FC = () => {
  const {
    dataLab: { data: dataLab },
  } = useDataLabContext();
  return (
    <Stack mt={1}>
      <Box alignItems="center" display="flex" justifyContent="space-between">
        <Typography level="title-md">Data quality statistics</Typography>
        <Box display="flex">
          <DataLabDownloadValidationReportButton />
          <DataLabValidationButton />
        </Box>
      </Box>
      <DataLabValidationAlerts />
      {dataLab?.statistics ? (
        <DataLabStatistics />
      ) : (
        <EmptyData
          iconSize="3x"
          primaryText="There are no quality statistics computed yet"
          secondaryText="Wait while we validate the datalab"
        />
      )}
    </Stack>
  );
};

DataLabQualityStatistics.displayName = "DataLabQualityStatistics";

export default memo(DataLabQualityStatistics);
