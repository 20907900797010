import {
  useDraftComputeNodeParticipantsQuery,
  usePublishedComputeNodeParticipantsQuery,
} from "@decentriq/graphql/dist/hooks";
import {
  type DraftAnalystPermission,
  type PublishedComputationNode,
} from "@decentriq/graphql/dist/types";
import {
  useComputeNodesVars,
  useDataRoom,
  usePublishedDataRoom,
  useRequest,
} from "contexts";
import {
  mapDraftDataRoomErrorToSnackbar,
  mapErrorToGeneralSnackbar,
  useDataRoomSnackbar,
} from "hooks";

const useComputeNodeParticipants = (
  computeNodeId: string
): { participants: { name: string }[]; loading: boolean } => {
  const { enqueueSnackbar } = useDataRoomSnackbar();
  const { isPublished } = useDataRoom();
  const { dataRoomId: dcrHash, driverAttestationHash } = usePublishedDataRoom();
  const { commitId } = useRequest();
  const { executionContext } = useComputeNodesVars();
  const shouldUseDraft =
    !isPublished ||
    executionContext === "development" ||
    executionContext === "requests";
  // draft & development
  const { data: draftData, loading: draftDataLoading } =
    useDraftComputeNodeParticipantsQuery({
      fetchPolicy: "cache-only",
      onError: (error) => {
        enqueueSnackbar(
          ...mapDraftDataRoomErrorToSnackbar(
            error,
            "The computation could not be retrieved. Please try again by refreshing the page."
          )
        );
      },
      skip: !shouldUseDraft,
      variables: { computeNodeId },
    });
  // published
  const { data: publishedData, loading: publishedDataLoading } =
    usePublishedComputeNodeParticipantsQuery({
      fetchPolicy: "cache-only",
      onError: (error) => {
        enqueueSnackbar(
          ...mapErrorToGeneralSnackbar(
            error,
            "The computation could not be retrieved. Please try again by refreshing the page."
          )
        );
      },
      skip: shouldUseDraft,
      variables: { commitId, computeNodeId, dcrHash, driverAttestationHash },
    });
  return {
    loading: draftDataLoading || publishedDataLoading,
    participants:
      draftData?.draftNode?.permissions?.nodes?.map((n) => ({
        name: (n as DraftAnalystPermission).participant.userEmail,
      })) ||
      (
        publishedData?.publishedNode as PublishedComputationNode
      )?.permissions?.map((n) => ({
        name: n.participant.userEmail,
      })) ||
      [],
  };
};

export default useComputeNodeParticipants;
