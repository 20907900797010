import { EMAIL_REGEXP } from "constants/index";
import { useDraftParticipantUpdateUserEmailMutation } from "@decentriq/graphql/dist/hooks";
import {
  type DraftParticipant,
  type PublishedParticipant,
} from "@decentriq/graphql/dist/types";
import { faExclamation as fasExclamation } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton, Input, Tooltip } from "@mui/joy";
import {
  type ChangeEvent,
  type KeyboardEvent,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDataRoomParticipants } from "components";
import { usePermissionsVars } from "contexts";
import { mapDraftDataRoomErrorToSnackbar, useDataRoomSnackbar } from "hooks";

interface ParticipantEmailFieldProps {
  participant: DraftParticipant | PublishedParticipant;
}

const ParticipantEmailField: React.FC<ParticipantEmailFieldProps> = memo(
  ({
    participant: { id: participantId = undefined, userEmail = undefined } = {},
  }): JSX.Element => {
    const { enqueueSnackbar } = useDataRoomSnackbar();
    const { canEditPermissions } = usePermissionsVars();
    const { participants = [] } = useDataRoomParticipants();
    const { participantsEmails, participantEmail } = useMemo(
      () => ({
        participantEmail: userEmail,
        participantsEmails:
          participants.map(({ userEmail }) => userEmail) || [],
      }),
      [participants, userEmail]
    );
    const [draftParticipantUpdateUserEmailMutation] =
      useDraftParticipantUpdateUserEmailMutation({
        onError: (error) => {
          enqueueSnackbar(
            ...mapDraftDataRoomErrorToSnackbar(
              error,
              "Participant email could not be updated."
            )
          );
        },
      });
    const inputRef = useRef<HTMLInputElement>(null);
    const [value, setValue] = useState<string>(participantEmail || "");
    const [valueError, setValueError] = useState<string | null>(null);
    const onBlur = useCallback(() => {
      setValue(participantEmail || "");
      setValueError(null);
    }, [participantEmail]);
    const onChange = useCallback(
      (event: ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value);
        setValueError(() => {
          const normalizedEmail = event.target.value.trim().toLowerCase();
          if (
            participantsEmails.some(
              (email) => email.toLowerCase().trim() === normalizedEmail
            ) &&
            normalizedEmail !== participantEmail
          ) {
            return "Participant email must be unique";
          }
          if (!EMAIL_REGEXP.test(normalizedEmail)) {
            return "Invalid participant email";
          }
          return null;
        });
      },
      [participantEmail, participantsEmails]
    );
    const onKeyDown = useCallback(
      (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Enter") {
          if (!valueError) {
            const normalizedEmail = value.trim().toLowerCase();
            draftParticipantUpdateUserEmailMutation({
              update: (cache) => {
                cache.gc();
              },
              variables: {
                id: participantId || "",
                participantEmail: normalizedEmail,
              },
            });
            inputRef.current?.blur();
          }
        } else if (event.key === "Escape") {
          setValue(participantEmail || "");
          setValueError(null);
          inputRef.current?.blur();
        }
      },
      [
        draftParticipantUpdateUserEmailMutation,
        participantEmail,
        participantId,
        valueError,
        value,
      ]
    );
    useEffect(() => {
      setValue(participantEmail || "");
    }, [participantEmail]);
    return (
      <Input
        endDecorator={
          valueError ? (
            <Tooltip color="danger" title={valueError} variant="solid">
              <IconButton
                color="danger"
                component="div"
                sx={{ cursor: "unset", minWidth: "auto" }}
              >
                <FontAwesomeIcon icon={fasExclamation} />
              </IconButton>
            </Tooltip>
          ) : undefined
        }
        error={Boolean(valueError)}
        fullWidth={true}
        onBlur={onBlur}
        onChange={onChange}
        onKeyDown={onKeyDown}
        placeholder="Participant email"
        readOnly={!canEditPermissions}
        slotProps={{ input: { ref: inputRef } }}
        sx={{
          "&:not(.Mui-focused)": {
            backgroundColor: "transparent",
          },
        }}
        value={value}
        variant="plain"
      />
    );
  }
);
ParticipantEmailField.displayName = "ParticipantEmailField";

export default ParticipantEmailField;
