import { useSnackbar } from "notistack";
import { useCallback } from "react";
import {
  type EnqueueSnackbar,
  type SnackbarHookResult,
  wrapSnackbarAction,
} from "hooks";
import DataLabErrorReportButton from "../components/DataLabErrorReportButton/DataLabErrorReportButton";
import { useDataLabErrorReport } from "../components/DataLabErrorReportButton/DataLabErrorReportProvider";

interface DataLabSnackbarHookPayload {
  withReportOnError?: boolean;
}

const useDataLabSnackbar = (
  payload?: DataLabSnackbarHookPayload
): SnackbarHookResult => {
  const withReportOnError = Boolean(payload?.withReportOnError ?? true);
  const { dataLabId, getConfiguration } = useDataLabErrorReport();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const handleEnqueueSnackbar: EnqueueSnackbar = useCallback(
    (message, options) => {
      const action = wrapSnackbarAction({
        action: options?.action,
        suffixAction: (closeSnackbar) => (
          <DataLabErrorReportButton
            closeSnackbar={closeSnackbar}
            dataLabId={dataLabId}
            details={options?.context || ""}
            error={message}
            getConfiguration={getConfiguration}
          />
        ),
        variant: options?.variant,
        withReportOnError,
      });
      const effectiveOptions: typeof options =
        typeof options === "object"
          ? {
              // @ts-ignore
              ...options,
              ...action,
            }
          : { ...action };
      return enqueueSnackbar(message, effectiveOptions);
    },
    [enqueueSnackbar, withReportOnError, getConfiguration, dataLabId]
  );
  return {
    closeSnackbar,
    enqueueSnackbar: handleEnqueueSnackbar,
  };
};

export default useDataLabSnackbar;
