// TODO: Fix joy migration
import {
  faExclamationTriangle,
  faEye,
  faEyeSlash,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  IconButton,
  Input,
  Modal,
  ModalDialog,
  Stack,
  Typography,
} from "@mui/joy";
import { memo, useCallback, useEffect, useState } from "react";
import * as yup from "yup";
import { useKeychainSetup } from "wrappers";

const validationSchema = yup.object({
  password: yup.string().trim().required().defined(),
});

type EmptyKeychainFormValues = yup.InferType<typeof validationSchema>;

interface EmptyKeychainDialogProps {
  onCancel: () => void;
  open: boolean;
}

const EmptyKeychainDialog: React.FC<EmptyKeychainDialogProps> = ({
  open,
  onCancel,
}) => {
  const [emptying, setEmptying] = useState(false);
  const { emptyKeychain } = useKeychainSetup();
  const [password, setPassword] = useState<string | undefined>();
  const [showPassword, setShowPassword] = useState(false);
  const toggleShowPassword = () => setShowPassword(!showPassword);
  const [validationErrors, setValidationErrors] = useState<
    string[] | undefined
  >(undefined);
  const isValid = validationErrors === undefined;
  const validate = useCallback(
    async (values: EmptyKeychainFormValues): Promise<boolean> => {
      try {
        await validationSchema.validate(values);
        setValidationErrors(undefined);
        return true;
      } catch (error) {
        setValidationErrors((error as yup.ValidationError).errors);
        return false;
      }
    },
    [setValidationErrors]
  );
  useEffect(() => {
    validate({ password });
  }, [password, validate]);
  const handleSubmit = useCallback(
    async (password: string | undefined) => {
      setEmptying(true);
      const isValid = await validate({
        password: password || "",
      });
      if (isValid) {
        const emptied = await emptyKeychain(password!.trim());
        if (emptied) {
          onCancel();
        }
      }
      setEmptying(false);
    },
    [emptyKeychain, setEmptying, validate, onCancel]
  );
  return (
    <Modal onClose={emptying ? undefined : onCancel} open={open}>
      <ModalDialog
        color="danger"
        invertedColors={true}
        role="alertdialog"
        variant="solid"
      >
        <DialogTitle>
          <FontAwesomeIcon
            icon={faExclamationTriangle}
            size="4x"
            swapOpacity={false}
          />
          Are you sure you want to empty your Keychain?
        </DialogTitle>
        <DialogContent>
          <form
            onSubmit={(event) => {
              event.preventDefault();
              handleSubmit(password);
            }}
          >
            <Stack>
              <Stack spacing={0.5}>
                <Typography sx={{ textWrap: "balance" }} textAlign="center">
                  All stored keys will be deleted from your Keychain. You will
                  no longer be able to reprovision any of your existing
                  datasests to other data clean rooms. Datasets will remain
                  provisioned to all data clean rooms. No data will be lost.
                </Typography>
              </Stack>
              <FormControl
                sx={{ flexDirection: "row", justifyContent: "center" }}
              >
                <Input
                  endDecorator={
                    <IconButton onClick={toggleShowPassword}>
                      {showPassword ? (
                        <FontAwesomeIcon fixedWidth={true} icon={faEyeSlash} />
                      ) : (
                        <FontAwesomeIcon fixedWidth={true} icon={faEye} />
                      )}
                    </IconButton>
                  }
                  onChange={(event) => setPassword(event.target.value)}
                  placeholder="Keychain password"
                  type={showPassword ? "text" : "password"}
                  value={password || ""}
                />
              </FormControl>
            </Stack>
          </form>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button disabled={emptying} onClick={onCancel}>
            Cancel
          </Button>
          <Button
            color="primary"
            disabled={!isValid}
            loading={emptying}
            loadingPosition="start"
            onClick={() => handleSubmit(password)}
            startDecorator={<FontAwesomeIcon icon={faExclamationTriangle} />}
            variant="solid"
          >
            Empty Keychain
          </Button>
        </DialogActions>
      </ModalDialog>
    </Modal>
  );
};
EmptyKeychainDialog.displayName = "EmptyKeychainDialog";

export default memo(EmptyKeychainDialog);
