import { type ApolloClient, type NormalizedCacheObject } from "@apollo/client";
import {
  CompleteDataRoomRequestDocument,
  type CompleteDataRoomRequestQuery,
  type CompleteDataRoomRequestQueryVariables,
  type DraftNode,
} from "@decentriq/graphql/dist/types";

export async function getDataRoomRequestCommitData(
  dataRoomRequestId: string,
  apolloClient: ApolloClient<NormalizedCacheObject>
): Promise<{
  draftNode: DraftNode;
  analysts: Array<string>;
}> {
  const completeDataRoomRequest = await apolloClient.query<
    CompleteDataRoomRequestQuery,
    CompleteDataRoomRequestQueryVariables
  >({
    fetchPolicy: "network-only",
    query: CompleteDataRoomRequestDocument,
    variables: {
      id: dataRoomRequestId,
    },
  });
  const draftNode = completeDataRoomRequest.data.dataRoomRequest.draftNode;
  const participants =
    completeDataRoomRequest.data.dataRoomRequest.participants;
  const analysts = participants.nodes.map((participant) => {
    if (participant.permissions.nodes.length !== 1) {
      throw new Error("Participant has wrong number of permissions");
    }
    const permission = participant.permissions.nodes[0];
    if (permission.__typename !== "DraftAnalystPermission") {
      throw new Error("Participant has wrong permission type");
    }
    if (permission.node.id !== draftNode.id) {
      throw new Error("Participant analyst permission has incorrect node id");
    }
    return participant.userEmail;
  });

  return { analysts, draftNode };
}
