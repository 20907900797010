import { ApolloError } from "@apollo/client";
import { exceptions } from "@decentriq/utils";
import { type VariantType } from "notistack";
import { type EnqueueSnackbarOptions } from "hooks";
import { getEffectiveErrorMessage } from "utils";

const mapMediaDataRoomErrorToSnackbar = (
  originalError: unknown,
  message: string
): [string, EnqueueSnackbarOptions<VariantType> | undefined] => {
  const error =
    originalError instanceof ApolloError
      ? originalError.networkError || new Error(originalError.message)
      : originalError;

  if (error instanceof exceptions.DataRoomComputationOutOfMemoryError) {
    return [
      "The computation could not be run due to an out of memory error. Please check the dataset size and possibly the executed code. If all is as expected, please report the issue to Decentriq using the button to the right.",
      {
        context: error.message,
        persist: true,
        variant: "error",
      },
    ];
  }

  return [
    "An error occurred. Please refresh your browser and try again. If the error persists, please report it to Decentriq using the button to the right.",
    {
      context: `${message}. ${getEffectiveErrorMessage(originalError)}`,
      persist: true,
      variant: "error",
    },
  ];
};

export default mapMediaDataRoomErrorToSnackbar;
