import { createContext } from "react";
import { useSafeContext } from "hooks";

export interface EnclaveToken {
  email: string;
  token: string;
}

export interface EnclaveTokenContextValue {
  enclaveToken: EnclaveToken;
}

const EnclaveTokenContext = createContext<EnclaveTokenContextValue | null>(
  null
);

EnclaveTokenContext.displayName = "EnclaveTokenContext";

export const useEnclaveToken = () => useSafeContext(EnclaveTokenContext);

export const EnclaveTokenProvider = EnclaveTokenContext.Provider;
