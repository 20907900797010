import { DataSourceType } from "@decentriq/graphql/dist/types";
import {
  createContext,
  type PropsWithChildren,
  type SetStateAction,
  useContext,
  useState,
} from "react";

export const DatasetImportContext = createContext<{
  selectedDataSource: DataSourceType;
  setSelectedDataSource: (
    selectedDataSource: SetStateAction<DataSourceType>
  ) => void;
  // After submitting a form, datasetImportId is returned, so dataset import data (incl. status) can be fetched via datasetImport query
  datasetImportId: string | null;
  setDatasetImportId: (datasetImportId: SetStateAction<string | null>) => void;
  setIsImportCreated: (isImportCreated: SetStateAction<boolean>) => void;
  isImportCreated: boolean;
  setDatasetImportError: (error: string) => void;
  datasetImportError: string | null;
}>({
  datasetImportError: "",
  datasetImportId: null,
  // Returns true if the first step of external import is completed (creating import itself)
  isImportCreated: false,
  selectedDataSource: DataSourceType.S3,
  setDatasetImportError: () => {},
  setDatasetImportId: () => {},
  setIsImportCreated: () => {},
  setSelectedDataSource: () => {},
});

export const useDatasetImport = () => useContext(DatasetImportContext);

export const DatasetImportProvider: React.FC<
  PropsWithChildren<{ defaultSelectedDataSource?: DataSourceType }>
> = ({ children, defaultSelectedDataSource }) => {
  // After submitting a form, datasetImportId is returned, so dataset import data (incl. status)
  // can be fetched via datasetImport query on the IMPORT_STATUS step
  const [datasetImportId, setDatasetImportId] = useState<string | null>(null);
  const [selectedDataSource, setSelectedDataSource] = useState<DataSourceType>(
    defaultSelectedDataSource || DataSourceType.S3
  );
  // Returns true if the first step of external import is completed (creating import itself)
  const [isImportCreated, setIsImportCreated] = useState<boolean>(false);
  // Errors that come from the local resolver
  const [datasetImportError, setDatasetImportError] = useState<string | null>(
    null
  );
  // TODO: Reset in case of error or going back (for export too)
  // setDatasetImportId(null);
  // setIsImportCreated(false);
  return (
    <DatasetImportContext.Provider
      value={{
        datasetImportError,
        datasetImportId,
        isImportCreated,
        selectedDataSource,
        setDatasetImportError,
        setDatasetImportId,
        setIsImportCreated,
        setSelectedDataSource,
      }}
    >
      {children}
    </DatasetImportContext.Provider>
  );
};
