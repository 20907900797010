import { InfoTooltip } from "@decentriq/components";
import { Box, Checkbox } from "@mui/joy";
import { memo } from "react";
import { useLookalikeAdvertiserAudienceGenerator } from "../../contexts/LookalikeAdvertiserAudienceGeneratorContext";
import ExcludeSeedAudienceInfoDialog from "../ExcludeSeedAudienceInfoDialog/ExcludeSeedAudienceInfoDialog";

const ExcludeSeedAudienceCheckbox: React.FC = () => {
  const {
    setExcludeSeedAudience,
    activatedLookalikeAudiences,
    excludeSeedAudience,
  } = useLookalikeAdvertiserAudienceGenerator();
  return (
    <>
      <Box mt={4} width="fit-content">
        <Checkbox
          checked={excludeSeedAudience}
          disabled={!!activatedLookalikeAudiences.length}
          label="Exclude seed audience from your new audience"
          onChange={(event) => setExcludeSeedAudience(event.target.checked)}
          sx={{ alignItems: "center", ml: 0, width: "fit-content" }}
        />
        <InfoTooltip tooltip="Checking this box will remove any individuals in your data from the generated audience. For privacy reasons, all audiences shared with a publisher must be of the same type (excluded / included)." />
      </Box>
      <ExcludeSeedAudienceInfoDialog />
    </>
  );
};

ExcludeSeedAudienceCheckbox.displayName = "ExcludeSeedAudienceCheckbox";

export default memo(ExcludeSeedAudienceCheckbox);
