import { DqLoader } from "@decentriq/components";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Modal,
  ModalDialog,
  Stack,
} from "@mui/joy";
import { JsonEditorField } from "components";

interface AdvertiserValidationReportDialogProps {
  data?: string;
  loading: boolean;
  onClose: () => void;
  open: boolean;
}

const AdvertiserValidationReportDialog: React.FC<
  AdvertiserValidationReportDialogProps
> = ({ data, loading, open, onClose }) => {
  const formattedValue =
    data && typeof data === "string"
      ? JSON.stringify(JSON.parse(data), null, 2)
      : data;
  return (
    <Modal onClose={onClose} open={open}>
      <ModalDialog>
        <DialogTitle>
          Validation report for Advertiser audience table
        </DialogTitle>
        <Divider />
        <DialogContent>
          {loading ? (
            <Stack alignItems="center" p={3}>
              <DqLoader />
            </Stack>
          ) : (
            <JsonEditorField
              editorOptions={{
                lineNumbers: "off",
                readOnly: true,
                resizable: false,
              }}
              height={400}
              value={formattedValue}
            />
          )}
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button onClick={onClose}>Close</Button>
        </DialogActions>
      </ModalDialog>
    </Modal>
  );
};

AdvertiserValidationReportDialog.displayName =
  "AdvertiserValidationReportDialog";
export default AdvertiserValidationReportDialog;
