import {
  useDraftScriptingComputeNodeQuery,
  usePublishedComputeNodeTypeLazyQuery,
} from "@decentriq/graphql/dist/hooks";
import { useEffect, useMemo, useState } from "react";
import { usePublishedDataRoom } from "contexts";

interface UseDependsOnPreviewNodeArgs {
  computeNodeId: string;
}

const useDependsOnPreviewNode = ({
  computeNodeId,
}: UseDependsOnPreviewNodeArgs) => {
  const [dependsOnPreviewNode, setDependsOnPreviewNode] =
    useState<boolean>(false);
  const { data: computeNode } = useDraftScriptingComputeNodeQuery({
    variables: { computeNodeId },
  });

  const [publishedComputeNodeTypeQuery] =
    usePublishedComputeNodeTypeLazyQuery();

  const publishedDependencyIds: string[] = useMemo(() => {
    const draftNode = computeNode?.draftNode;
    if (draftNode?.__typename !== "DraftScriptingNode") {
      return [];
    }
    return draftNode.dependencies.nodes.reduce<string[]>(
      (acc, dependencyNode) => {
        if (dependencyNode.__typename === "PublishedNodeConnection") {
          acc.push(dependencyNode.computeNodeId);
        }
        return acc;
      },
      []
    );
  }, [computeNode?.draftNode]);

  const { dataRoomId: dcrHash, driverAttestationHash } = usePublishedDataRoom();

  // TODO @matyasfodor this could use react-query, much simpler approach.
  // On the backend side: it'd be great to create a list resolver for this.
  useEffect(() => {
    const fetchNodeTypes = async (publishedDependencyIds: string[]) => {
      const typeNames = await Promise.all(
        publishedDependencyIds.map(async (publishedDependencyId) => {
          const computeNode = await publishedComputeNodeTypeQuery({
            variables: {
              commitId: null,
              computeNodeId: publishedDependencyId,
              dcrHash,
              driverAttestationHash,
            },
          });
          return computeNode.data?.publishedNode.__typename;
        })
      );
      setDependsOnPreviewNode(
        typeNames?.includes("PublishedPreviewNode") ?? false
      );
    };
    fetchNodeTypes(publishedDependencyIds);
  }, [
    dcrHash,
    driverAttestationHash,
    publishedComputeNodeTypeQuery,
    publishedDependencyIds,
  ]);

  return { dependsOnPreviewNode };
};

export default useDependsOnPreviewNode;
