import { makeReference, useApolloClient } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import {
  LocalDataSourceType,
  UnboundDatasetUploadDialog,
} from "@decentriq/components";
import { type OnAfterUploadCallback } from "@decentriq/components/dist/components/DatasetUploader/containers";
import { type DatasetUploaderProps } from "@decentriq/components/dist/components/DatasetUploader/DatasetUploader";
import { type VersionedSchema } from "@decentriq/components/dist/components/DatasetUploader/types";
import { UserToken } from "@decentriq/core/dist/api";
import { useDatasetByHashLazyQuery } from "@decentriq/graphql/dist/hooks";
import { type InitArguments } from "@decentriq/uploader";
import { exceptions, type Key, LabeledPromise } from "@decentriq/utils";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { type KeychainItem, KeychainItemKind } from "services/keychain";
import { useConfiguration, useKeychain } from "contexts";
import {
  fakeGeneratingANewEncryptionKey,
  fakeSettingUpEndToEndEncryption,
} from "features/datasets/utils";
import { CommonSnackbarOrigin, useReportError } from "hooks";
import { delay, logError, logInfo } from "utils";
import { idAsHash } from "utils/apicore";
import { mapVersionedSchemaToDatasetSchema } from "utils/dataset";
import { useEnclaveToken } from "wrappers";

interface DatasetImportLocalDialogProps {
  dataType: LocalDataSourceType | null;
  onClose?: () => void;
  open: boolean;
}

const DatasetImportLocalDialog = memo<DatasetImportLocalDialogProps>(
  ({ open, onClose, dataType }) => {
    const apolloClient = useApolloClient();
    const keychain = useKeychain();
    const [fetchDataset] = useDatasetByHashLazyQuery({
      fetchPolicy: "network-only",
    });
    const reportError = useReportError();
    const { user, getAccessTokenSilently } = useAuth0();
    const { configuration } = useConfiguration();
    const { diswaHost, diswaPort, diswaUseTls } = configuration;
    const handleKeychainItemIngestion = useCallback(
      async (
        key: Key,
        manifestHash: string,
        schema: VersionedSchema | undefined | null
      ) => {
        try {
          const datasetManifestHashKeychainItem: KeychainItem = {
            id: manifestHash,
            kind: KeychainItemKind.Dataset,
            value: idAsHash(key.material)!,
          };
          const itemsToInsert: KeychainItem[] = [
            datasetManifestHashKeychainItem,
          ];
          if (schema) {
            const metadataKeychainItem: KeychainItem = {
              id: manifestHash,
              kind: KeychainItemKind.DatasetMetadata,
              value: JSON.stringify(mapVersionedSchemaToDatasetSchema(schema)),
            };
            itemsToInsert.push(metadataKeychainItem);
          }
          try {
            await keychain.insertItems(itemsToInsert);
          } catch (error) {
            logInfo(error);
          }
          const dataset = await fetchDataset({
            variables: { manifestHash },
          });
          const datasetRef = makeReference(
            apolloClient.cache.identify({
              __typename: "Dataset",
              id: dataset.data!.datasetByManifestHash.id,
            })!
          );
          apolloClient.cache.modify({
            fields: {
              datasets: (existing) => ({
                nodes: [...(existing?.nodes || []), datasetRef],
              }),
            },
          });
        } catch (error) {
          logError(error);
          throw error;
        }
      },
      [fetchDataset, apolloClient, keychain]
    );
    const importDataset = useCallback<OnAfterUploadCallback>(
      (uploadArguments, uploadResult, schema) => {
        const promise = new LabeledPromise(async (resolve, reject) => {
          try {
            await Promise.all([
              handleKeychainItemIngestion(
                uploadArguments.key,
                uploadResult.manifestHash,
                schema
              ),
              delay(500),
            ]);
            resolve(null);
          } catch (error) {
            reject(error);
          }
        });
        promise.pendingLabel = `Finalizing import`;
        promise.fulfilledLabel = `Dataset successfully imported`;
        promise.rejectedLabel = `Dataset importing failed`;
        return promise;
      },
      [handleKeychainItemIngestion]
    );
    const [initArguments, setInitArguments] = useState<
      InitArguments | undefined
    >(undefined);
    const handleError = useCallback(
      (error: Error) => {
        if (
          error instanceof exceptions.DatasetValidationError &&
          error.hasReport
        ) {
          return;
        }
        reportError(
          {
            details: error.message,
            errorContext: [
              {
                content: dataType || "unknown",
                name: "dataType",
              },
            ],
            origin: CommonSnackbarOrigin.DATASET_PORTAL,
          },
          { silent: true }
        );
      },
      [reportError, dataType]
    );
    const { enclaveToken } = useEnclaveToken();
    useEffect(() => {
      (async () => {
        if (user?.email) {
          // TODO: Ask for not migrated case https://github.com/decentriq/delta/pull/3326/files#diff-642ade60da27d766f1005d51f52a8284546f62e2f74f8ae3060b8c01abbb89e1
          const platformToken = await getAccessTokenSilently();
          const token = new UserToken(platformToken, enclaveToken.token);
          setInitArguments({
            host: diswaHost,
            port: diswaPort,
            token: token,
            useTls: diswaUseTls,
            userEmail: user?.email,
          });
        }
      })();
    }, [
      diswaHost,
      diswaPort,
      diswaUseTls,
      enclaveToken,
      getAccessTokenSilently,
      user?.email,
    ]);
    const deferredSchema = dataType === LocalDataSourceType.Table;
    const DatasetUploaderPropsMemo = useMemo<DatasetUploaderProps>(
      () => ({
        UploadButtonChildren: "Encrypt & Provision",
        UploadButtonProps: {
          color: "primary",
          variant: "contained",
        },
        initArguments: initArguments!,
        onAfterUpload: [importDataset],
        onBeforeUpload: [
          fakeSettingUpEndToEndEncryption,
          fakeGeneratingANewEncryptionKey,
        ],
        schema: deferredSchema ? undefined : null,
      }),
      [initArguments, importDataset, deferredSchema]
    );
    return initArguments ? (
      <UnboundDatasetUploadDialog
        DatasetUploaderProps={DatasetUploaderPropsMemo}
        DialogTitleChildren="Import dataset"
        DialogTitleProps={{
          sx: { display: "flex", justifyContent: "space-between" },
        }}
        onClose={() => onClose?.()}
        onError={handleError}
        open={open}
      />
    ) : null;
  }
);
DatasetImportLocalDialog.displayName = "DatasetImportLocalDialog";

export default DatasetImportLocalDialog;
