import pickBy from "lodash/pickBy";
import { useCallback, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import {
  type EffectiveSearchParamsContextValue,
  EffectiveSearchParamsProvider,
} from "contexts";

const EffectiveSearchParamsWrapper = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [effectiveSearchParams, setEffectiveSearchParams] =
    useState<URLSearchParams>(searchParams);
  const handleSetSearchParams = useCallback(
    (
      params: Record<string, string | null>,
      options?: { fullReplace: boolean }
    ) =>
      setEffectiveSearchParams((currentParams) => {
        let effectiveParams = new URLSearchParams(
          pickBy(params, (value) => value != null) as Record<string, string>
        );
        if (!options?.fullReplace) {
          Object.entries(params).forEach(([key, value]) => {
            if (value == null) {
              currentParams.delete(key);
            } else {
              currentParams.set(key, value);
            }
          });
          effectiveParams = currentParams;
        }
        setSearchParams(effectiveParams);
        return effectiveParams;
      }),
    [setEffectiveSearchParams, setSearchParams]
  );
  const value = useMemo<EffectiveSearchParamsContextValue>(
    () => ({
      searchParams: effectiveSearchParams,
      setSearchParams: handleSetSearchParams,
    }),
    [effectiveSearchParams, handleSetSearchParams]
  );
  return (
    <EffectiveSearchParamsProvider value={value}>
      {children}
    </EffectiveSearchParamsProvider>
  );
};

export default EffectiveSearchParamsWrapper;
