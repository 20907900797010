import { DqDatasetSyncStatus, SyncDirectionType } from "@decentriq/components";
import {
  DataImportExportStatus,
  type DataSourceType,
} from "@decentriq/graphql/dist/types";
import { Button, DialogActions, Divider, ModalDialog } from "@mui/joy";
import { memo } from "react";
import { useWizard } from "react-use-wizard";
import { useDatasetImport } from "../../contexts";
import { useDatasetImportResult } from "./hooks";

interface ShowStatusStepProps {
  onClose?: () => void;
  syncType: DataSourceType;
}

const ShowStatusStep: React.FC<ShowStatusStepProps> = memo(
  ({ syncType, onClose }) => {
    const { previousStep } = useWizard();
    const { datasetImportId, isImportCreated, datasetImportError } =
      useDatasetImport();
    const { status = DataImportExportStatus.Pending, result } =
      useDatasetImportResult(datasetImportId as string);
    return (
      <ModalDialog>
        <DqDatasetSyncStatus
          error={datasetImportError}
          isImportExportCreated={isImportCreated}
          result={result}
          status={status}
          syncDirection={SyncDirectionType.IMPORT}
          syncType={syncType}
        />
        <Divider />
        <DialogActions>
          {status === DataImportExportStatus.Failed && (
            <Button onClick={previousStep}>Back</Button>
          )}
          <Button
            color="neutral"
            disabled={
              !(
                datasetImportError ||
                isImportCreated ||
                status === DataImportExportStatus.Failed
              )
            }
            onClick={() => onClose?.()}
            variant="plain"
          >
            Close
          </Button>
        </DialogActions>
      </ModalDialog>
    );
  }
);
ShowStatusStep.displayName = "ShowStatusStep";

export default ShowStatusStep;
