import { faAnglesLeft, faAnglesRight } from "@fortawesome/pro-light-svg-icons";
import { memo } from "react";
import { SidebarMenuItem, useSidebar } from "features/sidebar";

const ToggleSidebarButton = memo(() => {
  const { toggleSidebar, isSidebarExpanded } = useSidebar();
  return (
    <SidebarMenuItem
      icon={isSidebarExpanded ? faAnglesLeft : faAnglesRight}
      label="Close sidebar"
      onClick={toggleSidebar}
      tooltipTitle="Open sidebar"
    />
  );
});

ToggleSidebarButton.displayName = "ToggleSidebarButton";

export default ToggleSidebarButton;
