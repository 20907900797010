import { createContext, useContext } from "react";

export const RequestContext = createContext<{
  computeNodeId: string;
  commitId: string | undefined;
  requestId: string;
}>({
  commitId: undefined,
  computeNodeId: "__default__",
  requestId: "__default__",
});

export const RequestProvider = RequestContext.Provider;

export const useRequest = () => useContext(RequestContext);
