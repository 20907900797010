import { type FetchResult } from "@apollo/client";
import {
  type ColumnDataType,
  type CreateDraftTableLeafNodeColumnMutation,
  type DeleteDraftRawLeafNodeMutation,
  type DeleteDraftTableLeafNodeColumnMutation,
  type DeleteDraftTableLeafNodeMutation,
  type TableColumnFormatType,
  type UpdateDraftTableLeafNodeColumnMutation,
  type UpdateDraftTableLeafNodeMutation,
} from "@decentriq/graphql/dist/types";
import React, { createContext, useContext, useMemo } from "react";
import {
  type DataRoomData,
  type DataRoomDataDefinition,
  type DataRoomDataTable,
  type DataRoomTableColumnDefinition,
  type DataType,
} from "models";
import { useRawNodeAcitons } from "./useRawNodeActions";
import { useTableNodeAcitons } from "./useTableNodeActions";

interface DataNodesActionsResult {
  handleCreate: (node: DataRoomDataDefinition) => Promise<string | undefined>;
  handleDelete: (
    node: DataRoomData
  ) => Promise<
    | FetchResult<DeleteDraftRawLeafNodeMutation>
    | FetchResult<DeleteDraftTableLeafNodeMutation>
    | undefined
  >;
  handleNameUpdate: (node: {
    id: string;
    name: string;
    dataType: DataType;
  }) => void;
  handleConstraintUpdate: (node: {
    id: string;
    isRequired: boolean;
    dataType: DataType;
  }) => Promise<void>;
  handleDuplicate: (node: DataRoomData) => void;
  handleTableColumnCreate: (
    nodeId: string,
    column: DataRoomTableColumnDefinition
  ) => Promise<FetchResult<CreateDraftTableLeafNodeColumnMutation> | undefined>;
  handleTableColumnsOrderUpdate: (node: {
    id: string;
    columnsOrder: string[];
  }) => Promise<FetchResult<UpdateDraftTableLeafNodeMutation> | undefined>;
  handleTableColumnDelete: (
    columnId: string
  ) => Promise<FetchResult<DeleteDraftTableLeafNodeColumnMutation> | undefined>;
  handleTableColumnNameUpdate: (id: string, name: string) => void;
  handleTableColumnDataTypeUpdate: (
    id: string,
    primitiveType: ColumnDataType,
    formatType?: TableColumnFormatType
  ) => Promise<FetchResult<UpdateDraftTableLeafNodeColumnMutation> | undefined>;
  handleTableColumnHashWithUpdate: (
    id: string,
    hashWith: boolean
  ) => Promise<FetchResult<UpdateDraftTableLeafNodeColumnMutation> | undefined>;
  handleTableColumnNullableUpdate: (
    id: string,
    nullable: boolean
  ) => Promise<FetchResult<UpdateDraftTableLeafNodeColumnMutation> | undefined>;
}

export const DataRoomActionsContext = createContext<DataNodesActionsResult>({
  handleConstraintUpdate: () => Promise.resolve(),
  handleCreate: async () => {
    return undefined;
  },
  handleDelete: async () => {
    return undefined;
  },
  handleDuplicate: () => {},
  handleNameUpdate: () => {},
  handleTableColumnCreate: async () => {
    return undefined;
  },
  handleTableColumnDataTypeUpdate: async () => {
    return undefined;
  },
  handleTableColumnDelete: async () => {
    return undefined;
  },
  handleTableColumnHashWithUpdate: async () => {
    return undefined;
  },
  handleTableColumnNameUpdate: () => {},
  handleTableColumnNullableUpdate: async () => {
    return undefined;
  },
  handleTableColumnsOrderUpdate: async () => {
    return undefined;
  },
});

export const DataRoomActionsProvider = DataRoomActionsContext.Provider;
export const useDataNodeActions = () => useContext(DataRoomActionsContext);

interface DataRoomActionsWrapperProps {
  dataRoomId: string;
  children: React.ReactNode;
}

export const DataRoomActionsWrapper: React.FC<DataRoomActionsWrapperProps> = ({
  dataRoomId,
  children,
}) => {
  const tableNodeActions = useTableNodeAcitons({ dataRoomId });
  const rawNodeActions = useRawNodeAcitons({ dataRoomId });
  const actions: DataNodesActionsResult = useMemo(() => {
    const {
      handleColumnCreate: handleTableColumnCreate,
      handleColumnDelete: handleTableColumnDelete,
      handleColumnDataTypeUpdate: handleTableColumnDataTypeUpdate,
      handleColumnNameUpdate: handleTableColumnNameUpdate,
      handleColumnNullableUpdate: handleTableColumnNullableUpdate,
      handleColumnsOrderUpdate: handleTableColumnsOrderUpdate,
      handleColumnHashWithUpdate: handleTableColumnHashWithUpdate,
    } = tableNodeActions;
    return {
      handleConstraintUpdate: ({ dataType, ...node }) =>
        dataType === "unstructured"
          ? rawNodeActions.handleUpdateConstraint(node)
          : tableNodeActions.handleConstraintUpdate(node),
      handleCreate: (node) =>
        node.dataType === "unstructured"
          ? rawNodeActions.handleCreate(node)
          : tableNodeActions.handleCreate(node),
      handleDelete: (node) =>
        node.dataType === "unstructured"
          ? rawNodeActions.handleDelete(node)
          : tableNodeActions.handleDelete(node),
      handleDuplicate: (node) =>
        node.dataType === "unstructured"
          ? rawNodeActions.handleDuplicate(node)
          : tableNodeActions.handleDuplicate(node as DataRoomDataTable),
      handleNameUpdate: ({ dataType, ...node }) =>
        dataType === "unstructured"
          ? rawNodeActions.handleUpdateName(node)
          : tableNodeActions.handleNameUpdate(node),
      handleTableColumnCreate,
      handleTableColumnDataTypeUpdate,
      handleTableColumnDelete,
      handleTableColumnHashWithUpdate,
      handleTableColumnNameUpdate,
      handleTableColumnNullableUpdate,
      handleTableColumnsOrderUpdate,
    } as DataNodesActionsResult;
  }, [tableNodeActions, rawNodeActions]);
  return (
    <DataRoomActionsProvider value={actions}>
      {children}
    </DataRoomActionsProvider>
  );
};
