import { Box, Chip } from "@mui/joy";
import { useCallback } from "react";
import { useCreationWizardPublisher } from "features/mediaDataRoom/contexts";

const SelectPublisherStepMarketFilters: React.FC = () => {
  const { advertiserMarketFilters, updateAdvertiserMarketFilters } =
    useCreationWizardPublisher();
  const handleClick = useCallback(
    (marketId: string) =>
      updateAdvertiserMarketFilters(
        advertiserMarketFilters
          .map((filter) =>
            filter.id === marketId
              ? {
                  ...filter,
                  selected: !filter.selected,
                }
              : filter
          )
          .filter(({ selected }) => selected)
          .map(({ id }) => id)
      ),
    [advertiserMarketFilters, updateAdvertiserMarketFilters]
  );
  const handleAllClick = useCallback(
    () => updateAdvertiserMarketFilters([]),
    [updateAdvertiserMarketFilters]
  );
  if (!advertiserMarketFilters.length) {
    return null;
  }
  const areAllMarketsAllowed = advertiserMarketFilters.every(
    ({ selected }) => !selected
  );
  return (
    <Box mb={2}>
      <Chip
        key={0}
        onClick={handleAllClick}
        sx={{ mr: 1 }}
        variant={areAllMarketsAllowed ? "solid" : "outlined"}
      >
        All
      </Chip>
      {advertiserMarketFilters.map(({ id, name, selected }) => (
        <Chip
          key={id}
          onClick={(event) => handleClick(id)}
          sx={{ mr: 1 }}
          variant={selected ? "solid" : "outlined"}
        >{`${name.toUpperCase()} (${id})`}</Chip>
      ))}
    </Box>
  );
};

SelectPublisherStepMarketFilters.displayName =
  "SelectPublisherStepMarketFilters";

export default SelectPublisherStepMarketFilters;
