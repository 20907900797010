import { EMAIL_REGEXP } from "constants/index";
import { useDraftParticipantCreateMutation } from "@decentriq/graphql/dist/hooks";
import { DraftParticipantUserEmailFragment } from "@decentriq/graphql/dist/types";
import { testIds } from "@decentriq/utils";
import { faPlus } from "@fortawesome/pro-light-svg-icons";
import { faExclamation as fasExclamation } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton, Input, Tooltip } from "@mui/joy";
import {
  type ChangeEvent,
  type KeyboardEvent,
  memo,
  useCallback,
  useMemo,
  useState,
} from "react";
import { mapDraftDataRoomErrorToSnackbar, useDataRoomSnackbar } from "hooks";

interface AddParticipantFieldProps {
  idForDataRoom: string;
  emails: string[];
}

const AddParticipantField: React.FC<AddParticipantFieldProps> = memo(
  ({ idForDataRoom, emails }): JSX.Element => {
    const { enqueueSnackbar } = useDataRoomSnackbar();
    const [draftParticipantCreateMutation] = useDraftParticipantCreateMutation({
      onError: (error) => {
        enqueueSnackbar(
          ...mapDraftDataRoomErrorToSnackbar(
            error,
            `Participant could not be added.`
          )
        );
      },
    });
    const handleCreateParticipant = useCallback(
      (userEmail: string) => {
        if (!EMAIL_REGEXP.test(userEmail)) {
          enqueueSnackbar("Invalid participant email.", { variant: "error" });
          return;
        }
        const normalizedEmail = userEmail.toLowerCase();
        draftParticipantCreateMutation({
          update: (cache, { data }) => {
            cache.modify({
              fields: {
                participants: (existing = {}) => {
                  const dataRoomParticipantRef = cache.writeFragment({
                    data: data?.draftParticipant?.createForDraftDataRoom,
                    fragment: DraftParticipantUserEmailFragment,
                  });
                  return {
                    ...existing,
                    nodes: [dataRoomParticipantRef, ...(existing?.nodes || [])],
                  };
                },
              },
              id: cache.identify({
                __typename: "DraftDataRoom",
                id: idForDataRoom,
              }),
            });
          },
          variables: {
            draftDataRoomId: idForDataRoom,
            userEmail: normalizedEmail,
          },
        });
      },
      [draftParticipantCreateMutation, idForDataRoom, enqueueSnackbar]
    );
    const [value, setValue] = useState("");
    const error = useMemo(() => {
      return value.trim().length > 0
        ? emails.some(
            (e) => e.toLowerCase().trim() === value.toLowerCase().trim()
          )
          ? "Participant email must be unique"
          : !EMAIL_REGEXP.test(value)
            ? "Invalid participant email"
            : undefined
        : undefined;
    }, [emails, value]);
    const onChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
      setValue(event.target.value);
    }, []);
    const onKeyDown = useCallback(
      (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Enter" && value.length > 0 && !error) {
          handleCreateParticipant(value);
          setValue("");
        }
      },
      [error, handleCreateParticipant, value]
    );
    return (
      <Input
        data-testid={testIds.dataroom.addParticipants.newParticipant}
        endDecorator={
          error ? (
            <Tooltip color="danger" title={error} variant="solid">
              <IconButton
                color="danger"
                component="div"
                sx={{ cursor: "unset", minWidth: "auto" }}
              >
                <FontAwesomeIcon icon={fasExclamation} />
              </IconButton>
            </Tooltip>
          ) : undefined
        }
        error={Boolean(error)}
        fullWidth={true}
        onChange={onChange}
        onKeyDown={onKeyDown}
        placeholder="Add new participant"
        startDecorator={<FontAwesomeIcon fixedWidth={true} icon={faPlus} />}
        sx={{
          "&:not(.Mui-focused)": {
            backgroundColor: "transparent",
          },
        }}
        value={value}
        variant="plain"
      />
    );
  }
);
AddParticipantField.displayName = "AddParticipantField";

export default AddParticipantField;
