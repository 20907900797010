import { SANITIZE_IDENTIFIER_INPUT } from "constants/index";

export const isValidIdentifier = (value?: string): boolean =>
  !SANITIZE_IDENTIFIER_INPUT ||
  (value !== undefined
    ? new RegExp(`^([a-zA-Z]|[a-zA-Z][a-zA-Z0-9_ ]*[a-zA-Z0-9])$`, "gm").test(
        value
      )
    : false);

export const sanitizeIdentifier = (value: string) =>
  value?.replaceAll(new RegExp(`'|"`, "gmi"), "")?.trim() || "";

export const passwordRegExp = new RegExp(
  `^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)[\\d\\w\\W]{10,}$`
);
