import { useAuth0 } from "@auth0/auth0-react";
import { ThemeContainer } from "@decentriq/components";
import { type ReprovisionArguments } from "@decentriq/components/dist/components/DatasetReprovisioner/DatasetReprovisioner";
import { data_science, proto } from "@decentriq/core";
import type {
  UploadArguments,
  UploadedDatasetResult,
  ValidationReport,
} from "@decentriq/uploader";
import { exceptions, ProgressPromise } from "@decentriq/utils";
import { faDownload } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button } from "@mui/joy";
import { List, ListItem, ListItemText } from "@mui/material";
import saveAs from "file-saver";
import { useCallback } from "react";
import { useApiCore } from "contexts";
import { type DataRoomTableColumn } from "models";
import { getEffectiveErrorMessage } from "utils";
import {
  buildDataScienceDataRoom,
  getLatestEnclaveSpecsPerType,
} from "utils/apicore";

const useDataNodeValidate = ({
  id: dataNodeId,
  name,
  columns,
  columnsOrder,
  uniqueColumnIds,
}: {
  id: string;
  name: string;
  columns?: DataRoomTableColumn[];
  columnsOrder?: string[];
  uniqueColumnIds?: string[][];
}) => {
  const { client, sessionManager } = useApiCore();
  const { user = {} } = useAuth0();
  const { email: userEmail = "" } = user || {};
  const validateDataset = useCallback(
    (
      args: UploadArguments | ReprovisionArguments,
      result?: UploadedDatasetResult
    ) => {
      // NOTE: Each process might define its own callback signatures. Since this function is used as a link in different chains it has to obtain the args accordingly
      const key =
        (args as UploadArguments)?.key ||
        (args as ReprovisionArguments)?.uploadArguments?.key;
      const manifestHash =
        (result as UploadedDatasetResult)?.manifestHash ||
        (args as ReprovisionArguments)?.uploadResult?.manifestHash;
      if (!columns || !columns.length) {
        return Promise.resolve(undefined);
      }
      const createValidationReport = (validationReport: ValidationReport) => {
        const { report } = validationReport || {};
        const { columns, schema, table, uniqueness } = report || {};
        return (
          <ThemeContainer>
            <Box
              display="flex"
              flexDirection="column"
              gap="0.5rem"
              justifyContent="center"
            >
              <List>
                <ListItem
                  sx={{
                    alignItems: "baseline",
                    gap: "1rem",
                    justifyContent: "space-between",
                  }}
                >
                  <ListItemText
                    primary="Columns validation"
                    primaryTypographyProps={{ variant: "body2" }}
                  />
                  <ListItemText
                    primary={
                      columns?.some(({ numErrorsTotal }) => numErrorsTotal > 0)
                        ? "Failed"
                        : "Passed"
                    }
                    primaryTypographyProps={{ variant: "body2" }}
                    sx={{
                      color: columns?.some(
                        ({ numErrorsTotal }) => numErrorsTotal > 0
                      )
                        ? "error.main"
                        : "success.main",
                      flex: "0 0 auto",
                    }}
                  />
                </ListItem>
                <ListItem
                  sx={{
                    alignItems: "baseline",
                    gap: "1rem",
                    justifyContent: "space-between",
                  }}
                >
                  <ListItemText
                    primary="Schema validation"
                    primaryTypographyProps={{ variant: "body2" }}
                  />
                  <ListItemText
                    primary={schema.numErrorsTotal === 0 ? "Passed" : "Failed"}
                    primaryTypographyProps={{ variant: "body2" }}
                    sx={{
                      color:
                        schema.numErrorsTotal === 0
                          ? "success.main"
                          : "error.main",
                      flex: "0 0 auto",
                    }}
                  />
                </ListItem>
                <ListItem
                  sx={{
                    alignItems: "baseline",
                    gap: "1rem",
                    justifyContent: "space-between",
                  }}
                >
                  <ListItemText
                    primary="Table validation"
                    primaryTypographyProps={{ variant: "body2" }}
                  />
                  <ListItemText
                    primary={table.numErrorsTotal === 0 ? "Passed" : "Failed"}
                    primaryTypographyProps={{ variant: "body2" }}
                    sx={{
                      color:
                        table.numErrorsTotal === 0
                          ? "success.main"
                          : "error.main",
                      flex: "0 0 auto",
                    }}
                  />
                </ListItem>
                {uniqueness ? (
                  <ListItem
                    sx={{
                      alignItems: "baseline",
                      gap: "1rem",
                      justifyContent: "space-between",
                    }}
                  >
                    <ListItemText
                      primary="Uniqueness validation"
                      primaryTypographyProps={{ variant: "body2" }}
                    />
                    <ListItemText
                      primary={
                        uniqueness.numErrorsTotal === 0 ? "Passed" : "Failed"
                      }
                      primaryTypographyProps={{ variant: "body2" }}
                      sx={{
                        color:
                          uniqueness.numErrorsTotal === 0
                            ? "success.main"
                            : "error.main",
                        flex: "0 0 auto",
                      }}
                    />
                  </ListItem>
                ) : null}
              </List>
              {columns?.some(({ numErrorsTotal }) => numErrorsTotal > 0) ||
              schema.numErrorsTotal > 0 ||
              table.numErrorsTotal > 0 ||
              (uniqueness && uniqueness.numErrorsTotal > 0) ? (
                <Button
                  onClick={() => {
                    const file = new File(
                      [JSON.stringify(validationReport, null, 2)],
                      "validation-report.json",
                      { type: "application/octet-stream;charset=utf-8" }
                    );
                    saveAs(file);
                  }}
                  startDecorator={<FontAwesomeIcon icon={faDownload} />}
                  sx={{ alignSelf: "center" }}
                >
                  Download full report
                </Button>
              ) : null}
            </Box>
          </ThemeContainer>
        );
      };
      const promise = new ProgressPromise(async (resolve, reject, progress) => {
        try {
          progress(0.1);
          const rootCertificatePem = await client.decentriqCaRootCertificate();
          progress(0.2);
          const enclaveSpecifications = await client.getEnclaveSpecifications();
          progress(0.3);
          const latestEnclaveSpecifications = getLatestEnclaveSpecsPerType(
            enclaveSpecifications
          );
          progress(0.4);
          const dataScienceDcr = buildDataScienceDataRoom(
            {
              __typename: "DraftDataRoom",
              computeNodesOrder: [],
              dataNodesOrder: [dataNodeId],
              description: "",
              draftNodes: {
                __typename: "DraftNodeCollection",
                nodes: [
                  {
                    __typename: "DraftTableLeafNode",
                    allowEmpty: true,
                    columns: {
                      __typename: "DraftTableLeafNodeColumnCollection",
                      nodes: columns.map(
                        ({
                          id,
                          name,
                          primitiveType,
                          formatType,
                          nullable,
                          hashWith,
                        }) => ({
                          __typename: "DraftTableLeafNodeColumn",
                          dataType: primitiveType!,
                          formatType,
                          hashWith,
                          id,
                          isNullable: nullable,
                          name,
                        })
                      ),
                    },
                    columnsOrder: columnsOrder!,
                    createdAt: new Date().toISOString(),
                    id: dataNodeId,
                    isRequired: true,
                    name,
                    uniqueColumnIds: uniqueColumnIds || [],
                    updatedAt: new Date().toISOString(),
                  },
                ],
              },
              enableDevelopment: false,
              enableInteractivity: false,
              id: Date.now().toString(),
              owner: {
                __typename: "User",
                email: userEmail,
                id: userEmail,
              },
              participants: {
                __typename: "DraftParticipantCollection",
                nodes: [
                  {
                    __typename: "DraftParticipant",
                    id: userEmail,
                    permissions: {
                      __typename: "DraftParticipantPermissionCollection",
                      nodes: [
                        {
                          __typename: "DraftDataOwnerPermission",
                          node: {
                            __typename: "DraftTableLeafNode",
                            id: dataNodeId,
                          },
                        },
                      ],
                    },
                    userEmail,
                  },
                ],
              },
              showOrganizationLogo: false,
              title: `Dataset validation ${Date.now()} ${name}`,
            },
            rootCertificatePem,
            latestEnclaveSpecifications,
            true
          );
          progress(0.5);
          const session = await sessionManager.get();
          progress(0.6);
          const tempDataRoomId = await session.publishDataScienceDataRoom(
            dataScienceDcr,
            {
              dataRoomPurpose: proto.metering.CreateDcrPurpose.VALIDATION,
            }
          );
          progress(0.7);
          const dataRoomWrapper = data_science.createDataScienceDataRoomWrapper(
            tempDataRoomId,
            dataScienceDcr,
            session
          );
          await dataRoomWrapper.publishDataset(manifestHash, dataNodeId, key);
          progress(0.8);
          const validationReport =
            await dataRoomWrapper.validateTableNode(dataNodeId);
          progress(0.9);
          const { report } = validationReport || {};
          const { outcome } = report || {};
          if (outcome === "FAILED") {
            reject(
              new exceptions.DatasetValidationError(
                "Validation failed",
                createValidationReport(validationReport)
              )
            );
          }
          progress(1.0);
          resolve(createValidationReport(validationReport));
        } catch (error) {
          reject(
            new exceptions.DatasetValidationError(
              getEffectiveErrorMessage(error, JSON.stringify(error))
            )
          );
        }
      });
      promise.pendingLabel = "Validating dataset…";
      promise.fulfilledLabel = "Dataset validated successfully";
      promise.rejectedLabel = "Dataset validation failed";
      return promise;
    },
    [
      client,
      dataNodeId,
      sessionManager,
      columns,
      columnsOrder,
      uniqueColumnIds,
      name,
      userEmail,
    ]
  );
  return validateDataset;
};

export default useDataNodeValidate;
