import { faQuestionCircle } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton } from "@mui/joy";
import { useBoolean } from "ahooks";
import { memo } from "react";
import { SupportDialog } from "components";

const ContactUsButton = memo(() => {
  const [
    isSupportDialogOpen,
    { setFalse: closeSupportDialog, setTrue: openSupportDialog },
  ] = useBoolean(false);
  return (
    <>
      <IconButton onClick={openSupportDialog}>
        <FontAwesomeIcon icon={faQuestionCircle} size="lg" />
      </IconButton>
      <SupportDialog onClose={closeSupportDialog} open={isSupportDialogOpen} />
    </>
  );
});

ContactUsButton.displayName = "ContactUsButton";

export default ContactUsButton;
