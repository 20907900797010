import {
  useDownloadComputeNodeResultAsCsvMutation,
  useDownloadComputeNodeResultAsZipMutation,
} from "@decentriq/graphql/dist/hooks";
import {
  type Exact,
  type RetrieveComputeJobResultFileInput,
} from "@decentriq/graphql/dist/types";
import saveAs from "file-saver";
import { useCallback } from "react";
import { useApiCore, usePublishedDataRoom } from "contexts";
import { mapErrorToGeneralSnackbar, useDataRoomSnackbar } from "hooks";
import { type ComputeNodeTypeNames } from "models";

interface ComputeNodeDownloadHookArgs {
  computeNodeId: string;
  jobId: string;
  name: string;
  computationType?: ComputeNodeTypeNames;
  dataRoomHash?: string;
  driverAttestationHash: string;
}

const useComputeNodeDownload = ({
  computeNodeId,
  jobId,
  name,
  computationType,
  ...args
}: ComputeNodeDownloadHookArgs) => {
  const { enqueueSnackbar } = useDataRoomSnackbar();
  const { store } = useApiCore();
  const publishedDataRoom = usePublishedDataRoom();
  const driverAttestationHash =
    args.driverAttestationHash || publishedDataRoom.driverAttestationHash;
  const dcrHash = args.dataRoomHash || publishedDataRoom.dcrHash;
  const [downloadAsCsv, { loading: downloadingCsv }] =
    useDownloadComputeNodeResultAsCsvMutation({
      onError: (error) => {
        enqueueSnackbar(
          ...mapErrorToGeneralSnackbar(
            error,
            `The result of this computation could not be downloaded.`
          )
        );
      },
    });
  const [downloadAsZip, { loading: downloadingZip }] =
    useDownloadComputeNodeResultAsZipMutation({
      onError: (error) => {
        enqueueSnackbar(
          ...mapErrorToGeneralSnackbar(
            error,
            `The result of this computation could not be downloaded.`
          )
        );
      },
    });
  const downloadResult = useCallback(async () => {
    if (!computationType) {
      return;
    }
    const variables: Exact<{ input: RetrieveComputeJobResultFileInput }> = {
      input: {
        computeNodeId,
        dcrHash,
        driverAttestationHash,
        jobId,
        resultFileName: name,
      },
    };
    let result;
    if (
      computationType === "DraftScriptingNode" ||
      computationType === "PublishedScriptingNode" ||
      computationType === "DraftMatchNode" ||
      computationType === "PublishedMatchNode"
    ) {
      const { data } = await downloadAsZip({
        variables,
      });
      result = data?.retrieveComputeJobResultFileAsZip?.resultZip;
    } else {
      const { data } = await downloadAsCsv({
        variables,
      });
      result = data?.retrieveComputeJobResultFileAsCsv?.resultCsv;
    }
    if (result) {
      saveAs(store.pop<File>(result)!);
    }
  }, [
    computationType,
    computeNodeId,
    dcrHash,
    driverAttestationHash,
    name,
    jobId,
    downloadAsCsv,
    downloadAsZip,
    store,
  ]);
  return {
    downloadResult,
    loading: downloadingCsv || downloadingZip,
  };
};

export default useComputeNodeDownload;
