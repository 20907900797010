import { useDataLabLazyQuery } from "@decentriq/graphql/dist/hooks";
import { memo, type ReactNode, useCallback } from "react";
import { logError } from "utils";
import { DataLabErrorReportProvider } from "../DataLabErrorReportButton/DataLabErrorReportProvider";

interface DataLabErrorReportProps {
  id: string;
  children: ReactNode;
}

const DataLabErrorReport: React.FC<DataLabErrorReportProps> = ({
  children,
  id,
}) => {
  const [fetchDataLab] = useDataLabLazyQuery({ variables: { id } });
  const getConfiguration = useCallback(async () => {
    try {
      const dataLabData = (await fetchDataLab()).data?.dataLab;
      if (!dataLabData) {
        return "";
      }
      const {
        matchingIdFormat,
        matchingIdHashingAlgorithm,
        requireDemographicsDataset,
        requireEmbeddingsDataset,
        createdAt,
        updatedAt,
        numEmbeddings,
      } = dataLabData;
      return JSON.stringify(
        {
          createdAt,
          matchingIdFormat,
          matchingIdHashingAlgorithm,
          numEmbeddings,
          requireDemographicsDataset,
          requireEmbeddingsDataset,
          updatedAt,
        },
        null,
        2
      );
    } catch (error) {
      logError(error);
      return "";
    }
  }, [fetchDataLab]);
  return (
    <DataLabErrorReportProvider
      value={{
        dataLabId: id,
        getConfiguration,
      }}
    >
      {children}
    </DataLabErrorReportProvider>
  );
};

DataLabErrorReport.displayName = "DataLabErrorReport";

export default memo(DataLabErrorReport);
