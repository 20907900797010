import { memo } from "react";
import TimeAgo, { type ReactTimeagoProps } from "react-timeago";
import { useTimeFormatter } from "hooks";

export interface TimeAgoFormattedProps
  extends Omit<ReactTimeagoProps<React.ElementType>, "formatter"> {
  style?: React.CSSProperties;
}

const TimeAgoFormatted: React.FC<TimeAgoFormattedProps> = memo(
  ({ date, style }) => {
    const formatter = useTimeFormatter();
    return <TimeAgo date={date} formatter={formatter} style={style} />;
  }
);
TimeAgoFormatted.displayName = "TimeAgoFormatted";

export default TimeAgoFormatted;
