import { data_science } from "@decentriq/core";
import { type ApiCoreContextValue } from "contexts";
import { type LocalResolverContext } from "../../../models";

export const makeRetrievePublishedDatasetsResolver =
  (
    client: ApiCoreContextValue["client"],
    sessionManager: ApiCoreContextValue["sessionManager"],
    store: ApiCoreContextValue["store"]
  ) =>
  async (_obj: null, args: any, context: LocalResolverContext, _info: any) => {
    const { dcrHash, driverAttestationHash } = args.input;
    const sdkSession = await sessionManager.get({
      driverAttestationHash,
    });
    const dataScienceDataRoom =
      await sdkSession.retrieveDataScienceDataRoom(dcrHash);
    const wrapper = data_science.createDataScienceDataRoomWrapper(
      dcrHash,
      dataScienceDataRoom!,
      sdkSession
    );
    const publishedDatasets = await wrapper.retrievePublishedDatasets();
    // TODO: map to correct type here
    return publishedDatasets;
  };
