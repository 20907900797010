import { DataTargetType } from "@decentriq/graphql/dist/types";
import { useState } from "react";
import { createContext, type SetStateAction, useContext } from "react";

export const DatasetExportContext = createContext<{
  selectedDataTarget: DataTargetType;
  setSelectedDataTarget: (
    selectedDataTarget: SetStateAction<DataTargetType>
  ) => void;
  // After submitting a form, datasetExportId is returned, so dataset export data (incl. status) can be fetched via datasetExport query
  datasetExportId: string | null;
  setDatasetExportId: (datasetExportId: SetStateAction<string | null>) => void;
  setIsExportCreated: (isExportCreated: SetStateAction<boolean>) => void;
  isExportCreated: boolean;
  manifestHash: string;
  setDatasetExportError: (error: string) => void;
  datasetExportError: string | null;
}>({
  datasetExportError: "",
  datasetExportId: null,
  // Returns true if the first step of export is completed (creating export itself)
  isExportCreated: false,
  manifestHash: "",
  selectedDataTarget: DataTargetType.S3,
  setDatasetExportError: () => {},
  setDatasetExportId: () => {},
  setIsExportCreated: () => {},
  setSelectedDataTarget: () => {},
});

export const useDatasetExport = () => useContext(DatasetExportContext);

export interface DatasetExportProviderProps {
  children: React.ReactNode;
  manifestHash: string;
}

export const DatasetExportProvider: React.FC<DatasetExportProviderProps> = ({
  children,
  manifestHash,
}) => {
  // After submitting a form, datasetExportId is returned, so dataset export data (incl. status)
  // can be fetched via datasetExport query on the EXPORT_STATUS step
  const [datasetExportId, setDatasetExportId] = useState<string | null>(null);
  const [selectedDataTarget, setSelectedDataTarget] = useState<DataTargetType>(
    DataTargetType.S3
  );
  // Returns true if the first step of external export is completed (creating export itself)
  const [isExportCreated, setIsExportCreated] = useState<boolean>(false);
  // Errors that come from the local resolver
  const [datasetExportError, setDatasetExportError] = useState<string | null>(
    null
  );
  return (
    <DatasetExportContext.Provider
      value={{
        datasetExportError,
        datasetExportId,
        isExportCreated,
        manifestHash,
        selectedDataTarget,
        setDatasetExportError,
        setDatasetExportId,
        setIsExportCreated,
        setSelectedDataTarget,
      }}
    >
      {children}
    </DatasetExportContext.Provider>
  );
};
