import { memo } from "react";
import { CollaboratingOrganizationsList } from "components";

type CollaboratingOrganizationsProps = {
  organizationId: string;
};

const CollaboratingOrganizations: React.FC<CollaboratingOrganizationsProps> = ({
  organizationId,
}) => {
  return <CollaboratingOrganizationsList organizationId={organizationId} />;
};
CollaboratingOrganizations.displayName = "CollaboratingOrganizations";

export default memo(CollaboratingOrganizations);
