import { memo } from "react";
import { OrganizationDataRoomsList } from "components";

type OrganizationDataRoomsProps = {
  organizationId: string;
};

const OrganizationDataRooms: React.FC<OrganizationDataRoomsProps> = ({
  organizationId,
}) => {
  return <OrganizationDataRoomsList organizationId={organizationId} />;
};

OrganizationDataRooms.displayName = "OrganizationDataRooms";

export default memo(OrganizationDataRooms);
