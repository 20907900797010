// @ts-nocheck
import { useEffect, useMemo, useState } from "react";

const defaultItems = [];
const defaultSelectedItems = [];

const useSortedSelections = (
  items = defaultItems,
  selectedItems = defaultSelectedItems
) => {
  const [all, setAll] = useState(items);
  const [selected, setSelected] = useState(selectedItems);
  // Update all based on props
  useEffect(() => setAll(items), [items]);
  // Update selected based on props
  useEffect(() => setSelected(selectedItems), [selectedItems]);
  // Update selected based on all
  useEffect(
    () =>
      setSelected((selected) => all.filter((item) => selected.includes(item))),
    [all]
  );
  // Actions
  const selectedSet = useMemo(() => new Set(selected), [selected]);
  const singleActions = useMemo(() => {
    const isSelected = (item) => selectedSet.has(item);
    const select = (item) => {
      selectedSet.add(item);
      return setSelected(Array.from(selectedSet));
    };
    const unSelect = (item) => {
      selectedSet.delete(item);
      return setSelected(Array.from(selectedSet));
    };
    const toggle = (item) => {
      if (isSelected(item)) {
        unSelect(item);
      } else {
        select(item);
      }
    };
    return { isSelected, select, toggle, unSelect };
  }, [selectedSet]);
  const someActions = useMemo(() => {
    const areSelected = (items) => items.every((item) => selectedSet.has(item));
    const selectSome = (items) => {
      items.forEach((o) => {
        selectedSet.add(o);
      });
      return setSelected(Array.from(selectedSet));
    };
    const unSelectSome = (items) => {
      items.forEach((o) => {
        selectedSet.delete(o);
      });
      return setSelected(Array.from(selectedSet));
    };
    const toggleSome = (items) => {
      if (areSelected(items)) {
        unSelectSome(items);
      } else {
        selectSome(items);
      }
    };
    return {
      areSelected,
      selectSome,
      toggleSome,
      unSelectSome,
    };
  }, [selectedSet]);
  const allActions = useMemo(() => {
    const selectAll = () => {
      all.forEach((o) => {
        selectedSet.add(o);
      });
      setSelected(Array.from(selectedSet));
    };
    const unSelectAll = () => {
      all.forEach((o) => {
        selectedSet.delete(o);
      });
      setSelected(Array.from(selectedSet));
    };
    const noneSelected = all.every((o) => !selectedSet.has(o));
    const allSelected = all.every((o) => selectedSet.has(o)) && !noneSelected;
    const partiallySelected = !noneSelected && !allSelected;
    const toggleAll = () => (allSelected ? unSelectAll() : selectAll());
    return {
      allSelected,
      noneSelected,
      partiallySelected,
      selectAll,
      toggleAll,
      unSelectAll,
    };
  }, [selectedSet, all]);
  return {
    all,
    selected,
    setAll,
    setSelected,
    ...singleActions,
    ...someActions,
    ...allActions,
  };
};

useSortedSelections.displayName = "useSortedSelections";

export default useSortedSelections;
