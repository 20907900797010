// TODO: Fix joy migration
import { useDeactivateDataRoomMutation } from "@decentriq/graphql/dist/hooks";
import { faExclamationTriangle } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Modal,
  ModalDialog,
  Typography,
} from "@mui/joy";
import { memo } from "react";
import { mapErrorToGeneralSnackbar, useDataRoomSnackbar } from "hooks";

type DeactivateDataRoomDialogProps = {
  dataRoomId: string;
  open: boolean;
  onCancel: () => void;
  closeDataRoomDetailsDialog: () => void;
};

const DeactivateDataRoomDialog: React.FC<DeactivateDataRoomDialogProps> = ({
  dataRoomId,
  open,
  onCancel,
  closeDataRoomDetailsDialog,
}) => {
  const { enqueueSnackbar } = useDataRoomSnackbar();
  const [deactivateDataRoom, { loading: isDeactivateDataRoomLoading }] =
    useDeactivateDataRoomMutation({
      onCompleted: () => {
        enqueueSnackbar("Data clean room has been successfully deactivated.");
        closeDataRoomDetailsDialog();
        onCancel();
      },
      onError: (error) => {
        enqueueSnackbar(
          ...mapErrorToGeneralSnackbar(
            error,
            "Data clean room could not be deactivated."
          )
        );
        onCancel();
      },
      update: (cache, { data }) => {
        cache.modify({
          fields: {
            deactivatedAt: () =>
              data?.publishedDataRoom?.deactivate?.deactivatedAt,
            isActive: () => data?.publishedDataRoom?.deactivate?.isActive,
          },
          id: cache.identify({
            __typename: "PublishedDataRoom",
            id: dataRoomId,
          }),
        });
      },
      variables: { dataRoomId },
    });

  return (
    <Modal
      onClose={!isDeactivateDataRoomLoading ? onCancel : undefined}
      open={open}
    >
      <ModalDialog role="alertdialog">
        <DialogTitle>
          <Box sx={{ color: "error.main", mb: 1, textAlign: "center" }}>
            <FontAwesomeIcon
              fixedWidth={true}
              icon={faExclamationTriangle}
              size="4x"
            />
          </Box>
          <Typography
            align="center"
            level="title-md"
            sx={{ textWrap: "balance" }}
          >
            <strong>
              Are you sure you want to deactivate this data clean room?
            </strong>
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Typography
            align="center"
            level="body-sm"
            sx={{ textWrap: "balance" }}
          >
            This is an irreversible action. Participants will not be able to
            provision data or run computations in this DCR.
          </Typography>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button disabled={isDeactivateDataRoomLoading} onClick={onCancel}>
            Cancel
          </Button>
          <Button
            color="danger"
            loading={isDeactivateDataRoomLoading}
            loadingPosition="start"
            onClick={() => deactivateDataRoom()}
            startDecorator={<FontAwesomeIcon icon={faExclamationTriangle} />}
            variant="solid"
          >
            Deactivate DCR
          </Button>
        </DialogActions>
      </ModalDialog>
    </Modal>
  );
};

DeactivateDataRoomDialog.displayName = "DeactivateDataRoomDialog";

export default memo(DeactivateDataRoomDialog);
