import { createContext, useContext } from "react";

const TreeContext = createContext({
  data: undefined,
  path: undefined,
  setPath: () => {},
});

export const TreeProvider = TreeContext.Provider;

export const useTree = () => useContext(TreeContext);

export default useTree;
