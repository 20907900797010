import { DqLoader, InfoTooltip } from "@decentriq/components";
import { faDownload } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Grid, IconButton, Tooltip, Typography, useTheme } from "@mui/joy";
import { grey } from "@mui/material/colors";
import { ResponsiveBar } from "@nivo/bar";
import format from "date-fns/format";
import { saveAs } from "file-saver";
import { memo, useCallback, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import {
  useMediaDataRoom,
  useMediaDataRoomInsightsData,
} from "features/mediaDataRoom/contexts";
import { type InsightsBasicViewFormValues } from "features/mediaDataRoom/models";
import {
  formatNumber,
  replaceDecentriqOrgName,
} from "features/mediaDataRoom/utils";
import useOverlapStatistics from "./useOverlapStatistics";

const OverlapStatistics = memo<{ withSpinner?: boolean }>(
  ({ withSpinner = true }) => {
    const { palette } = useTheme();
    const {
      overlapStatistics: {
        computeResults: {
          statistics: allOverlapInsightsStatistics = [],
          isEmpty: isEmptyOverlapStatistics,
        } = {},
        status: overlapStatisticsStatus,
      },
      publishedDatasetsHashes: { hasRequiredData },
    } = useMediaDataRoomInsightsData();
    const { watch } = useFormContext<InsightsBasicViewFormValues>();
    const selectedAudienceType = watch("audienceType");
    const { advertiserOrganization, publisherOrganization } =
      useMediaDataRoom();
    const { overlapStatistics, overlapPercentage } =
      useOverlapStatistics(selectedAudienceType);
    const exportInsightsStatistics = useCallback(() => {
      const fileContent = allOverlapInsightsStatistics.reduce(
        (csv, { audienceType, overlapSize, advertiserSize }) => {
          const matchRate = ((overlapSize / advertiserSize) * 100).toFixed(1);
          return `${csv}\n${audienceType},${overlapSize},${advertiserSize},${matchRate}%`;
        },
        "Advertiser audience,Individuals in the overlap,Total customers in audience,Match Rate"
      );
      const fileName = `Basic_overlap_${publisherOrganization?.name}_${advertiserOrganization?.name}_${format(
        new Date(),
        "dd_MM_yyyy HH_mm"
      )}.csv`;
      const file = new File([fileContent], fileName, {
        type: "application/octet-stream;charset=utf-8",
      });
      saveAs(file);
    }, [
      advertiserOrganization?.name,
      allOverlapInsightsStatistics,
      publisherOrganization?.name,
    ]);
    const barData = useMemo(
      () => [
        {
          key: "total",
          value: overlapStatistics.total || 0,
        },
        {
          key: "overlap",
          value: overlapStatistics.overlap,
        },
      ],
      [overlapStatistics.total, overlapStatistics.overlap]
    );
    if (
      withSpinner &&
      hasRequiredData &&
      overlapStatisticsStatus !== "COMPLETED"
    ) {
      return <DqLoader label="Computing overlap..." />;
    }
    if (
      (!withSpinner && overlapStatisticsStatus !== "COMPLETED") ||
      isEmptyOverlapStatistics ||
      !allOverlapInsightsStatistics.some(({ overlapSize }) => overlapSize)
    ) {
      return null;
    }
    return (
      <Grid container={true} xs={12}>
        <Grid xs={12}>
          <Box alignItems="center" display="flex">
            <Typography fontWeight={600} mr={1}>
              Overlap & advertiser audience
            </Typography>
            <Tooltip
              placement="top-start"
              title="Export match rate of all audiences to CSV"
            >
              <IconButton
                onClick={exportInsightsStatistics}
                sx={{ padding: 0.5 }}
              >
                <FontAwesomeIcon fixedWidth={true} icon={faDownload} />
              </IconButton>
            </Tooltip>
          </Box>
        </Grid>
        <Grid alignSelf="baseline" container={true} xs={12}>
          <Grid xs={11}>
            <Typography sx={{ color: grey[700], fontWeight: 500 }}>
              There are{" "}
              <span style={{ fontWeight: 700 }}>
                {formatNumber(overlapStatistics?.overlap)}
              </span>{" "}
              individuals in the overlap. This is a match rate of{" "}
              <span style={{ fontWeight: 700 }}>
                {(isNaN(parseFloat(overlapPercentage)) ??
                overlapStatistics?.overlap ??
                overlapStatistics?.total)
                  ? "Calculating the "
                  : overlapPercentage}
              </span>
              %
              <InfoTooltip
                tooltip="We add a small amount of noise to the counts of matched users to protect individual privacy. We sample from the Laplace Mechanism, with a scale factor of 10. This means that there is a 63% chance of adding noise between -10 and 10 to any given count of users. The chance of adding more noise falls off rapidly, there is less than a 2% chance that the noise is larger than 40."
                top="2px"
              />
            </Typography>
          </Grid>
          <Box sx={{ height: "48px", margin: "10px 0", width: "100%" }}>
            <ResponsiveBar
              animate={true}
              colors={(k) => {
                if (k.indexValue === "total") {
                  return palette.chart["300"];
                }
                return palette.chart["500"];
              }}
              data={barData}
              enableGridY={false}
              enableLabel={false}
              groupMode="grouped"
              indexBy="key"
              isInteractive={false}
              layout="horizontal"
              maxValue={overlapStatistics?.total}
            />
          </Box>
          <Box sx={{ display: "flex" }}>
            <Typography sx={{ color: grey[700], fontWeight: 500 }}>
              There are{" "}
              <span style={{ fontWeight: 700 }}>
                {formatNumber(overlapStatistics?.total)}
              </span>{" "}
              customers in{" "}
              <span style={{ fontWeight: 700 }}>
                {replaceDecentriqOrgName(
                  advertiserOrganization?.name,
                  "Advertiser"
                )}
                's
              </span>{" "}
              <b>{selectedAudienceType}</b> audience.
            </Typography>
          </Box>
        </Grid>
      </Grid>
    );
  }
);

OverlapStatistics.displayName = "OverlapStatistics";

export default OverlapStatistics;
