import { Box, Chip, Grid, Stack, Typography } from "@mui/joy";
import { memo } from "react";
import { useDataPartnerPortal } from "features/dataPartnerPortal/contexts";
import { OrganizationTile } from "features/mediaPortalShared";

const DataPartnerDisplayConfigurationPresentation = memo(() => {
  const { displayConfiguration } = useDataPartnerPortal();
  if (!displayConfiguration) {
    return null;
  }
  const { name, collaborationRequestUsers, description, logo, marketIds } =
    displayConfiguration;
  return (
    <Stack direction="row" spacing={2}>
      <Box sx={{ width: 250 }}>
        <OrganizationTile
          imageSx={{ maxHeight: "50px", p: 0, pb: 1 }}
          logo={logo as string | undefined}
          marketIds={marketIds}
          name={name}
          sx={{ height: "160px", width: "250px" }}
        />
      </Box>
      <Grid container={true} spacing={2}>
        <Grid md={6} xs={12}>
          <Stack>
            <Typography level="title-sm">Description</Typography>
            <Typography level="body-md" sx={{ wordBreak: "break-all" }}>
              {description}
            </Typography>
          </Stack>
        </Grid>
        <Grid md={6} xs={12}>
          <Stack>
            <Typography level="title-sm">Contact request email</Typography>
            <Stack direction="row" flexWrap="wrap">
              {collaborationRequestUsers.map((email) => (
                <Chip key={email} variant="outlined">
                  {email}
                </Chip>
              ))}
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  );
});
DataPartnerDisplayConfigurationPresentation.displayName =
  "DataPartnerDisplayConfigurationPresentation";

export default DataPartnerDisplayConfigurationPresentation;
