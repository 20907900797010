import { DqCard, DqCardContent } from "@decentriq/components";
import { type IconDefinition } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Typography } from "@mui/joy";
import { memo } from "react";

interface SelectDataSourceTypeButtonProps {
  testId?: string;
  title: string;
  icon: IconDefinition;
  selected?: boolean;
  onClick: () => void;
}

const SelectDataSourceTypeButton: React.FC<SelectDataSourceTypeButtonProps> = ({
  testId,
  icon,
  title,
  onClick,
  selected,
}) => (
  <DqCard data-testid={testId} onSelect={onClick} selected={selected}>
    <DqCardContent>
      <FontAwesomeIcon icon={icon} size="3x" />
      <Typography mb={0.5} mt={1} sx={{ fontWeight: 600 }}>
        {title}
      </Typography>
    </DqCardContent>
  </DqCard>
);

SelectDataSourceTypeButton.displayName = "SelectDataSourceTypeButton";

export default memo(SelectDataSourceTypeButton);
