import { createContext, useContext } from "react";

/// The purpose of this context is to share URL params state across the app, to easily add, update, remove params
export interface EffectiveSearchParamsContextValue {
  searchParams: URLSearchParams;

  /// This method provides a way to add, update, remove (if value passed as `null`)
  /// Set will be done on the existing state, unless `fullReplace` provided
  setSearchParams: (
    params: Record<string, string | null>,
    options?: { fullReplace: boolean }
  ) => void;
}

export const EffectiveSearchParamsContext =
  createContext<EffectiveSearchParamsContextValue>({
    searchParams: new URLSearchParams(),
    setSearchParams: () => {},
  });

export const EffectiveSearchParamsProvider =
  EffectiveSearchParamsContext.Provider;

export const useEffectiveSearchParams = () =>
  useContext(EffectiveSearchParamsContext);
