import { DqCard, DqCardContent, InfoTooltip } from "@decentriq/components";
import { Link, Typography } from "@mui/joy";
import { isNil } from "lodash";
import { memo } from "react";
import AudienceQualityStatisticsLoading from "./AudienceQualityStatisticsLoading";

type QualityScoreProps = {
  qualityScore: number | null | undefined;
  loading?: boolean;
};

const QualityScore: React.FC<QualityScoreProps> = ({
  qualityScore,
  loading,
}) => {
  return (
    <DqCard>
      <DqCardContent>
        <Typography
          display="inline-flex"
          gutterBottom={true}
          level="body-sm"
          textColor="inherit"
        >
          Audience quality score
          <InfoTooltip
            tooltip={
              <Typography>
                The audience quality score shows how{" "}
                <Link
                  href="https://en.wikipedia.org/wiki/Precision_and_recall"
                  rel="noreferrer"
                  target="_blank"
                >
                  precise
                </Link>{" "}
                this lookalike audience is. It is measured with a{" "}
                <Link
                  href="https://en.wikipedia.org/wiki/Cross-validation_(statistics)"
                  rel="noreferrer"
                  target="_blank"
                >
                  holdout
                </Link>{" "}
                experiement using existing customers from the seed audience. The
                higher the fraction of existing customers in the audience are,
                the higher the quality score. In general, larger audiences will
                be less precise, but it is not concerning to see lower audience
                quality numbers for small audiences, because the purpose of the
                lookalike it find people that are similar to existing customers,
                not identify existing customers.
              </Typography>
            }
          />
        </Typography>
        {loading ? (
          <AudienceQualityStatisticsLoading />
        ) : (
          <Typography fontWeight={500} level="h1">
            {!isNil(qualityScore) ? `${qualityScore}/10` : "-"}
          </Typography>
        )}
      </DqCardContent>
    </DqCard>
  );
};

QualityScore.displayName = "QualityScore";

export default memo(QualityScore);
