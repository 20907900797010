import { useRequestComputeNodeActionsQuery } from "@decentriq/graphql/dist/hooks";
import { ComputeJobStatus } from "@decentriq/graphql/dist/types";
import {
  faArrowUpRightAndArrowDownLeftFromCenter,
  faListCheck,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@mui/joy";
import { grey } from "@mui/material/colors";
import { useEffect } from "react";
import { Actions } from "components";
import {
  useComputeNodesVars,
  usePublishedDataRoom,
  useRequest,
} from "contexts";
import { useJobPolling } from "features/computeNode/hooks";
import { type DraftComputeNodeTypes } from "models";
import useValidateRequest from "../hooks/useValidateRequest/useValidateRequest";

interface RequestComputeNodeActionsProps {
  computeNodeId: string;
}

const RequestComputeNodeActions: React.FC<RequestComputeNodeActionsProps> = ({
  computeNodeId,
}) => {
  const { requestId } = useRequest();
  const { openEditorDialog } = useComputeNodesVars();
  const { isStopped, isDeactivated } = usePublishedDataRoom();
  const { data: actionsData, loading: actionsDataLoading } =
    useRequestComputeNodeActionsQuery({
      variables: {
        requestId,
      },
    });
  const { validateRequest, loading: validating } =
    useValidateRequest(requestId);
  const job = (
    actionsData?.dataRoomRequest?.draftNode as DraftComputeNodeTypes | undefined
  )?.job;
  const status = job?.status;
  const isJobRunning = status === ComputeJobStatus.Running;
  const isComputeNodeRunning = validating || actionsDataLoading || isJobRunning;
  const { startJobPolling } = useJobPolling({
    autoFetching: job!?.autoFetching,
    computeNodeId,
    dataRoomHash: job?.dataRoomHash,
    driverAttestationHash: job?.driverAttestationHash,
    enclaveJobId: job!?.enclaveComputeJobId,
    jobId: job!?.id,
  });
  useEffect(() => {
    if (isJobRunning) {
      startJobPolling();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);
  if (actionsDataLoading && !actionsData) {
    return <></>;
  }
  return (
    <Actions
      actions={{
        buttons: [
          {
            icon: faArrowUpRightAndArrowDownLeftFromCenter,
            iconColor: grey[600],
            isRed: false,
            name: "Open in popup",
            onClick: () => openEditorDialog(computeNodeId),
            tooltipTitle: "Open in popup",
            type: "Open in popup",
          },
          {
            component: Button,
            disabled: isComputeNodeRunning,
            hidden: isStopped || isDeactivated,
            icon: <FontAwesomeIcon icon={faListCheck} />,
            loading: isComputeNodeRunning,
            name: "Validate",
            onClick: validateRequest,
            type: "Validate",
          },
        ],
      }}
    />
  );
};

export default RequestComputeNodeActions;
