import { type Theme } from "@mui/material";
import {
  formatDuration,
  intervalToDuration,
  isValid,
  parseISO,
} from "date-fns";
import capitalize from "lodash/capitalize";
import lowerCase from "lodash/lowerCase";
import { memo } from "react";
import { makeStyles } from "tss-react/mui";

const useStatusTooltipTitleStyles = makeStyles()((theme: Theme) => ({
  info: {},
}));

interface StatusTooltipTitleProps {
  status: string;
  statusUpdatedAt: string;
  statusTimePrefix?: string;
  statusTimeSuffix?: string;
}

const StatusTooltipTitle = memo<StatusTooltipTitleProps>(
  ({
    status,
    statusUpdatedAt,
    statusTimePrefix = "",
    statusTimeSuffix = "",
  }) => {
    const { classes: statusTooltipTitleClasses } =
      useStatusTooltipTitleStyles();
    const statusUpdatedAtDate = parseISO(statusUpdatedAt);
    const statusUpdatedToNow = isValid(statusUpdatedAtDate)
      ? formatDuration(
          intervalToDuration({
            end: new Date(),
            start: statusUpdatedAtDate,
          })
        )
      : "";
    return (
      <div>
        <div>{capitalize(lowerCase(status))}</div>
        <div className={statusTooltipTitleClasses.info}>
          {statusTimePrefix}
          {statusUpdatedToNow}
          {statusTimeSuffix}
        </div>
      </div>
    );
  }
);

export default StatusTooltipTitle;
