import { useOrganizationDetailsQuery } from "@decentriq/graphql/dist/hooks";
import { Box, FormLabel, Stack, Typography } from "@mui/joy";
import { format } from "date-fns";
import { memo } from "react";

type OrganizationDetailsProps = {
  organizationId: string;
};

const OrganizationDetails: React.FC<OrganizationDetailsProps> = ({
  organizationId,
}) => {
  const { data: organizationDetailsData } = useOrganizationDetailsQuery({
    variables: {
      organizationId,
    },
  });
  const { createdAt, createdBy, updatedAt } =
    organizationDetailsData?.organization || {};
  return (
    <Stack
      direction="row"
      sx={{ "& > *": { width: "calc(100% / 3 - 8px * 2 / 3)" } }}
    >
      <Typography
        alignItems="flex-start"
        flexDirection="column"
        startDecorator={<FormLabel>Created at</FormLabel>}
      >
        {createdAt ? format(new Date(createdAt), "dd.MM.yyy, HH:mm") : "–"}
      </Typography>
      <Typography
        alignItems="flex-start"
        flexDirection="column"
        startDecorator={<FormLabel>Updated at</FormLabel>}
      >
        {updatedAt ? format(new Date(updatedAt), "dd.MM.yyy, HH:mm") : "–"}
      </Typography>
      <Typography
        alignItems="flex-start"
        flexDirection="column"
        startDecorator={<FormLabel>Created by</FormLabel>}
      >
        {createdBy?.email || "–"}
      </Typography>
    </Stack>
  );
};
OrganizationDetails.displayName = "OrganizationDetails";

export default memo(OrganizationDetails);
