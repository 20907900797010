import { faXmark } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button } from "@mui/joy";
import { memo } from "react";
import {
  Controller,
  type FieldValues,
  type Path,
  useFormContext,
} from "react-hook-form";
import { ImageUploadButton } from "components";

interface DisplayConfigurationLogoFieldProps<F extends FieldValues> {
  fieldName: Path<F>;
  testId?: string;
}

type DisplayConfigurationLogoFieldComponent = <F extends FieldValues>(
  props: DisplayConfigurationLogoFieldProps<F>
) => React.ReactNode;

const DisplayConfigurationLogoField = memo(
  <F extends FieldValues>({
    fieldName,
    testId,
  }: DisplayConfigurationLogoFieldProps<F>) => {
    const { control } = useFormContext<F>();
    return (
      <Controller
        control={control}
        name={fieldName}
        render={({ field }) => (
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
              gap: 1,
            }}
          >
            {field.value && (
              <img
                alt={"display configuration logo"}
                loading="lazy"
                src={`data:image;base64,${field.value}`}
                style={{ height: 125 }}
              />
            )}
            <ImageUploadButton
              disabled={field.disabled}
              imageCropperHookOptions={{
                aspectRatio: 4 / 1,
                canvasSize: { height: 150, width: 600 },
                fillMode: "transparent",
                minImageDimensions: { height: 50, width: 200 },
              }}
              onImageSelected={field.onChange}
              testIdPrefix={testId}
            />
            {field.value && (
              <Button
                disabled={field.disabled}
                onClick={(event) => field.onChange(event, undefined)}
                startDecorator={<FontAwesomeIcon icon={faXmark} />}
                sx={{ height: "fit-content", marginLeft: 2 }}
              >
                Remove logo
              </Button>
            )}
          </Box>
        )}
      />
    );
  }
);
DisplayConfigurationLogoField.displayName = "DisplayConfigurationLogoField";

export default DisplayConfigurationLogoField as DisplayConfigurationLogoFieldComponent;
