import { useCopyToClipboard } from "hooks";

interface useDraftDataRoomCopyLinkArgs {
  id: string;
}

interface useDraftDataRoomCopyLinkResult {
  draftDataRoomCopyLink: () => Promise<boolean>;
}

const useDraftDataRoomCopyLink = ({
  id: dataRoomId,
}: useDraftDataRoomCopyLinkArgs): useDraftDataRoomCopyLinkResult => {
  const [, copyToClipboard] = useCopyToClipboard();
  const draftDataRoomCopyLink = () =>
    copyToClipboard(`${window.location.origin}/datarooms/d/${dataRoomId}`);
  return { draftDataRoomCopyLink };
};

export default useDraftDataRoomCopyLink;
