// TODO: Fix joy migration
import {
  useApolloClient,
  useFragment_experimental as useFragment,
} from "@apollo/client";
import { useDraftDataRoomTestPublicationsQuery } from "@decentriq/graphql/dist/hooks";
import {
  DraftDataRoomPasswordFragment,
  type DraftRawLeafNode,
  type DraftTableLeafNode,
} from "@decentriq/graphql/dist/types";
import { testIds } from "@decentriq/utils";
import {
  faEye as falEye,
  faEyeSlash as falEyeSlash,
  faFileContract as falFileContract,
} from "@fortawesome/pro-light-svg-icons";
import { faInfoCircle as fatInfoCircle } from "@fortawesome/pro-thin-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Alert,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  Modal,
  ModalDialog,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/joy";
import { useEffect, useState } from "react";
import { DraftDataNodeTypeNames } from "models";

const ALLOW_TEST_DATA_TRANSFERING = false;

interface DataRoomPublishDialogProps {
  dataRoomId: string;
  requirePassword: boolean;
  open: boolean;
  loading: boolean;
  onCancel: () => void;
  onConfirm: (keepTestData: boolean) => void;
}

const DataRoomPublishDialog: React.FC<DataRoomPublishDialogProps> = ({
  dataRoomId,
  requirePassword,
  open,
  loading,
  onCancel,
  onConfirm,
}) => {
  const client = useApolloClient();
  const { cache } = client;
  const { data: testPublicationsData } = useDraftDataRoomTestPublicationsQuery({
    skip: !ALLOW_TEST_DATA_TRANSFERING,
    variables: {
      dataRoomId,
    },
  });
  const hasTestPublications =
    testPublicationsData?.draftDataRoom?.draftNodes?.nodes?.some(
      ({ id, __typename, ...rest }) =>
        __typename === DraftDataNodeTypeNames.DraftRawLeafNode ||
        (__typename === DraftDataNodeTypeNames.DraftTableLeafNode &&
          (rest as DraftTableLeafNode | DraftRawLeafNode).testModePublication)
    );
  const { data: dataRoomPasswordData } = useFragment({
    fragment: DraftDataRoomPasswordFragment,
    from: { __typename: "DraftDataRoom", id: dataRoomId },
  });
  const [keepTestData, setKeepTestData] = useState(true);
  const password = dataRoomPasswordData?.password || undefined;
  const setPassword = (password: string) => {
    cache.writeFragment({
      data: { password },
      fragment: DraftDataRoomPasswordFragment,
      id: cache.identify({ __typename: "DraftDataRoom", id: dataRoomId }),
    });
  };
  const [showPassword, setShowPassword] = useState(false);
  const toggleShowPassword = () => setShowPassword(!showPassword);
  const [validationErrors, setValidationErrors] = useState<
    string[] | undefined
  >(undefined);
  const isValid = validationErrors === undefined;
  useEffect(() => {
    if ((password?.length || 0) < 8) {
      return setValidationErrors([
        "Password must contain at least 8 characters.",
      ]);
    }
    setValidationErrors(undefined);
  }, [password]);
  return (
    <Modal open={open}>
      <ModalDialog role="alertdialog">
        <DialogTitle>
          <FontAwesomeIcon fixedWidth={true} icon={fatInfoCircle} size="3x" />
        </DialogTitle>
        <DialogContent>
          <Typography level="body-md">
            <strong>
              This publishes the data clean room and sends invitation emails to
              all participants.
            </strong>
          </Typography>
          {hasTestPublications && (
            <div>
              <Typography>
                Test data was used for drafting the data clean room. Would you
                like to keep it?
                <br />
                Note: test data will only be accesible to you
              </Typography>
              <FormControl style={{ width: "100%" }}>
                <RadioGroup
                  onChange={(e) =>
                    setKeepTestData(e.target.value as unknown as boolean)
                  }
                  value={keepTestData}
                >
                  <Radio
                    label="Yes, keep test data accesible to me"
                    value={true}
                  />
                  <Radio label="No, delete it" value={false} />
                </RadioGroup>
              </FormControl>
            </div>
          )}
          {requirePassword ? (
            <>
              <Divider style={{ margin: "1rem 0" }} />
              <div>
                <Typography textAlign="center">
                  Please set a password to continue.
                </Typography>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    if (isValid) {
                      onConfirm(keepTestData);
                    }
                  }}
                >
                  <FormControl>
                    <FormLabel>Password</FormLabel>
                    <Input
                      endDecorator={
                        <IconButton onClick={toggleShowPassword}>
                          {showPassword ? (
                            <FontAwesomeIcon
                              fixedWidth={true}
                              icon={falEyeSlash}
                            />
                          ) : (
                            <FontAwesomeIcon fixedWidth={true} icon={falEye} />
                          )}
                        </IconButton>
                      }
                      onChange={(event) => setPassword(event.target.value)}
                      type={showPassword ? "text" : "password"}
                      value={password || ""}
                    />
                  </FormControl>
                </form>
                {!isValid ? (
                  <Alert color="danger">
                    <strong>Invalid password:</strong>
                    <ul style={{ margin: 0, paddingInlineStart: "1rem" }}>
                      {validationErrors?.map((validationError, index) => (
                        <li key={index}>{validationError}</li>
                      ))}
                    </ul>
                  </Alert>
                ) : null}
                <Alert color="warning">
                  <strong>Notes:</strong>
                  <ul style={{ margin: 0, paddingInlineStart: "1rem" }}>
                    <li>
                      You need to share the password with the participants
                      before they can interact with this data clean room.
                    </li>
                    <li>
                      If you lose your password, you will no longer be able to
                      access this data clean room.
                    </li>
                  </ul>
                </Alert>
              </div>
            </>
          ) : null}
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button disabled={loading} onClick={onCancel}>
            Go back to editing
          </Button>
          <Button
            color="primary"
            data-testid={testIds.dataroom.dataroomPublishDialog.publishButton}
            loading={loading}
            loadingPosition="start"
            onClick={() => onConfirm(keepTestData)}
            startDecorator={<FontAwesomeIcon icon={falFileContract} />}
            variant="solid"
          >
            Publish data clean room
          </Button>
        </DialogActions>
      </ModalDialog>
    </Modal>
  );
};

export default DataRoomPublishDialog;
