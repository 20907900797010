import { faInfoCircle, faTrashCan } from "@fortawesome/pro-light-svg-icons";
import { Portal } from "@mui/material";
import { useBoolean } from "ahooks";
import { useMemo } from "react";
import { Actions } from "components";
import { DataLabDeleteDialog, DataLabDetailsDialog } from "features/dataLabs";

interface DataLabActionsProps {
  id: string;
}

const DataLabActions: React.FC<DataLabActionsProps> = ({ id }) => {
  const [
    isDeleteDialogOpen,
    { setTrue: openDeleteDialog, setFalse: closeDeleteDialog },
  ] = useBoolean(false);
  const [
    isDetailsDialogOpen,
    { setTrue: openDetailsDialog, setFalse: closeDetailsDialog },
  ] = useBoolean(false);
  const actions = useMemo(
    () => ({
      menuLists: [
        [
          {
            icon: faInfoCircle,
            name: "Details",
            onClick: openDetailsDialog,
            type: "Details",
          },
          {
            icon: faTrashCan,
            isRed: true,
            name: "Delete datalab",
            onClick: openDeleteDialog,
            type: "Delete",
          },
        ],
      ],
    }),
    [openDeleteDialog, openDetailsDialog]
  );
  return (
    <Actions actions={actions}>
      <Portal>
        <DataLabDeleteDialog
          id={id}
          onCancel={closeDeleteDialog}
          open={isDeleteDialogOpen}
        />
        <DataLabDetailsDialog
          id={id}
          onCancel={closeDetailsDialog}
          open={isDetailsDialogOpen}
        />
      </Portal>
    </Actions>
  );
};

DataLabActions.displayName = "DataLabActions";

export default DataLabActions;
