import { type Session } from "@decentriq/core";
import { type ab_media_request as ddcAbMediaRequest } from "ddc";
import { useCallback, useMemo } from "react";
import { useApiCore } from "contexts";
import {
  type AbMediaRequestValueOfKey,
  type AbMediaResponseValueOfKey,
  type MediaDataRoomRequestKey,
} from "./models";

export interface MediaDataRoomRequestHookPayload<
  K extends MediaDataRoomRequestKey,
> {
  /** @param dataRoomId data room id */
  dataRoomId: string;
  /** @param driverAttestationHash driver attestation hash */
  driverAttestationHash: string;
  /** @param key `AbMediaRequest` key; name of the request to be sent */
  key: K;
  /** @param requestCreator optional function which provides `dataRoomIdHex`, `scopeIdHex` and needs to return body of the request for the given key; if not provided, it will need to be provided in the payload of the runner otherwise an error will be thrown */
  requestCreator?: (
    dataRoomIdHex: string,
    scopeIdHex: string
  ) => AbMediaRequestValueOfKey<K>;
}

export interface MediaDataRoomRequestRunner<
  K extends MediaDataRoomRequestKey,
  T,
> {
  (payload?: {
    /**
     *This function is used to create the request body for the given key, it has priority over the `requestCreator` provided in the hook options
     *
     * @param dataRoomIdHex data room id in the hex format
     * @param scopeIdHex data scope id in the hex format
     * @returns body of the request for the given key
     */
    requestCreator?: (
      dataRoomIdHex: string,
      scopeIdHex: string
    ) => AbMediaRequestValueOfKey<K>;
    /**
     *By providing options you can override the session and scope id used during the request execution
     * @param session `Session` object to be used for the request
     * @param scopeId data scope id to be used for the request
     */
    options?: { session?: Session; scopeId?: string };
  }): Promise<T>;
}
export type MediaDataRoomRequestHookResult<
  K extends MediaDataRoomRequestKey,
  T,
> = [
  MediaDataRoomRequestRunner<K, T>,
  () => Promise<Session>,
  () => Promise<string>,
];

/**
 *This hook is used to create a runner function to execute a media data room request, it incapsulates the logic for session and scope id management, building the request itself, verifying the response and returning the response of the request
 *
 * @param key `AbMediaRequest` key; name of the request to be sent
 * @param dataRoomId data room id
 * @param driverAttestationHash driver attestation hash
 * @param requestCreator optional function which provides `dataRoomIdHex`, `scopeIdHex` and needs to return body of the request for the given key; if not provided, it will need to be provided in the payload of the runner otherwise an error will be thrown
 * @returns array with 3 elements: runner function to run the request, getSession function to provide some utility in the context if session is needed and getScopeId function to get scope id
 */
const useMediaDataRoomRequest = <
  K extends MediaDataRoomRequestKey,
  T extends AbMediaResponseValueOfKey<K>,
>({
  key,
  dataRoomId,
  driverAttestationHash,
  requestCreator,
}: MediaDataRoomRequestHookPayload<K>): MediaDataRoomRequestHookResult<
  K,
  T
> => {
  const { client, sessionManager } = useApiCore();
  const getSession = useCallback(
    async (session?: Session) =>
      session ??
      (await sessionManager.get({
        driverAttestationHash,
      })),
    [sessionManager, driverAttestationHash]
  );
  const getScopeId = useCallback(
    async (scopeId?: string) =>
      scopeId ?? (await client.ensureDcrDataScope(dataRoomId)),
    [client, dataRoomId]
  );
  const run = useCallback<MediaDataRoomRequestRunner<K, T>>(
    async (payload) => {
      if (!payload?.requestCreator && !requestCreator) {
        throw new Error(
          `Failed to create request for "${key}". requestCreator is not provided neither in payload nor in hook options`
        );
      }
      const scopeId = await getScopeId(payload?.options?.scopeId);
      const session = await getSession(payload?.options?.session);
      // TODO: investigate why it shows an error here
      const request: ddcAbMediaRequest.AbMediaRequest = {
        [key]: (payload?.requestCreator ?? requestCreator)!(
          dataRoomId,
          scopeId
        ),
      };
      const response = await session.sendAbMediaRequest(request);
      if (!(key in response)) {
        throw new Error(`Expected ${key} response`);
      }
      return response[key as keyof typeof response];
    },
    [dataRoomId, key, requestCreator, getScopeId, getSession]
  );
  return useMemo(
    () => [run, getSession, getScopeId],
    [run, getSession, getScopeId]
  );
};

export default useMediaDataRoomRequest;
