// TODO: Fix joy migration
import { faExclamationTriangle } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Modal,
  ModalDialog,
  Typography,
} from "@mui/joy";
import { memo } from "react";

interface DataRoomHideDialogProps {
  name: string | undefined;
  open: boolean;
  loading: boolean;
  onCancel: () => void;
  onConfirm: () => void;
}

const DataRoomHideDialog: React.FC<DataRoomHideDialogProps> = ({
  name,
  open,
  loading,
  onCancel,
  onConfirm,
}) => {
  return (
    <Modal onClose={!loading ? onCancel : () => {}} open={open}>
      <ModalDialog role="alertdialog">
        <DialogTitle>
          <Box sx={{ color: "error.main", mb: 1, textAlign: "center" }}>
            <FontAwesomeIcon
              fixedWidth={true}
              icon={faExclamationTriangle}
              size="4x"
            />
          </Box>
          <Typography
            align="center"
            level="title-md"
            sx={{ textWrap: "balance" }}
          >
            <strong>Are you sure you want to hide {name}?</strong>
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Typography
            align="center"
            level="body-sm"
            sx={{ textWrap: "balance" }}
          >
            You will be able to unhide the data clean room anytime. Meanwhile
            all data clean room users except you will lose access to the data
            clean room.
          </Typography>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button disabled={loading} onClick={onCancel}>
            Cancel
          </Button>
          <Button
            color="danger"
            loading={loading}
            loadingPosition="start"
            onClick={onConfirm}
            startDecorator={<FontAwesomeIcon icon={faExclamationTriangle} />}
            variant="solid"
          >
            Hide
          </Button>
        </DialogActions>
      </ModalDialog>
    </Modal>
  );
};

DataRoomHideDialog.displayName = "DataRoomHideDialog";

export default memo(DataRoomHideDialog);
