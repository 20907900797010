import { useCallback, useState } from "react";

export const useStorageState = <T extends string | null>(
  storage: Storage,
  key: string
): [T, (value: T) => void] => {
  const [state, setState] = useState<T>(storage.getItem(key) as T);
  const setStateWrapped = useCallback(
    (value: T) => {
      if (value == null) {
        storage.removeItem(key);
      } else {
        storage.setItem(key, value);
      }
      setState(value);
    },
    [key, storage]
  );
  return [state, setStateWrapped];
};

export const clearDecentriqStorage = () => {
  for (const storage of [localStorage, sessionStorage]) {
    const keysToRemove = [];
    for (let i = 0; i < storage.length; i++) {
      const key = storage.key(i);
      if (key && key.startsWith("dq:")) {
        keysToRemove.push(key);
      }
    }
    for (const key of keysToRemove) {
      storage.removeItem(key);
    }
  }
};
