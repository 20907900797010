import { memo } from "react";
import { useDataRoom } from "contexts";
import DraftComputeNodeActions from "./DraftComputeNodeActions/DraftComputeNodeActions";
import PublishedComputeNodeActions from "./PublishedComputeNodeActions/PublishedComputeNodeActions";

interface ComputeNodeActionsProps {
  computeNodeId: string;
}

const ComputeNodeActions: React.FC<ComputeNodeActionsProps> = memo(
  ({ computeNodeId }) => {
    const { isPublished } = useDataRoom();
    return isPublished ? (
      <PublishedComputeNodeActions computeNodeId={computeNodeId} />
    ) : (
      <DraftComputeNodeActions computeNodeId={computeNodeId} />
    );
  }
);

export default ComputeNodeActions;
