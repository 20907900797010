// TODO: Fix joy migration
import { DqLoader, DqTable } from "@decentriq/components";
import { useDataConnectorJobsQuery } from "@decentriq/graphql/dist/hooks";
import {
  type DataConnectorJob,
  DataConnectorJobType,
  DataImportExportStatus,
} from "@decentriq/graphql/dist/types";
import { faFileExport, faFileImport } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Grid, Stack, Typography } from "@mui/joy";
import { red } from "@mui/material/colors";
import { orderBy } from "lodash";
import {
  type MRT_ColumnDef,
  type MRT_RowSelectionState,
} from "material-react-table";
import { useMemo, useState } from "react";
import { EmptyData, TimeAgoFormatted } from "components";
import {
  ExternalConnectionsIcon,
  ExternalConnectionsIconSize,
} from "features/datasets";
import ExternalConnectionDetails, {
  externalConnectionStatusPresentation,
} from "features/datasets/components/ExternalConnectionDetails/ExternalConnectionDetails";
import ExternalConnectionToolbar from "features/datasets/components/ExternalConnectionToolbar/ExternalConnectionToolbar";

const externalsColumnDef: MRT_ColumnDef<DataConnectorJob>[] = [
  {
    Cell: ({ cell, row }) => {
      const name = cell.getValue<string>();
      const isExport = [
        DataConnectorJobType.ExportDataset,
        DataConnectorJobType.ExportDcrJobResult,
      ].includes(row.original.type);
      const connectionType = isExport
        ? row.original.targetType!
        : row.original.sourceType!;
      return (
        <Typography
          component="div"
          endDecorator={
            <ExternalConnectionsIcon
              connectionType={connectionType}
              size={ExternalConnectionsIconSize.xs}
            />
          }
          flex={1}
          fontWeight="500"
          level="inherit"
          noWrap={true}
          startDecorator={
            <FontAwesomeIcon
              fixedWidth={true}
              fontSize="1rem"
              icon={isExport ? faFileExport : faFileImport}
            />
          }
          textColor="inherit"
        >
          <Typography component="span" flex={1} noWrap={true}>
            {name}
          </Typography>
        </Typography>
      );
    },
    accessorKey: "name",
    enableSorting: false,
    header: "Name",
    id: "name",
  },
  {
    Cell: ({ cell, row }) => {
      const status = cell.getValue<DataImportExportStatus>();
      return (
        <Typography
          color={
            status === DataImportExportStatus.Failed ? red[500] : "inherit"
          }
          level="body-sm"
          sx={{ cursor: "pointer" }}
        >
          {status === DataImportExportStatus.Success ? (
            <>
              Completed{" "}
              {!!row.original.finishedAt && (
                <TimeAgoFormatted date={row.original.finishedAt!} />
              )}
            </>
          ) : (
            externalConnectionStatusPresentation.get(status)
          )}
        </Typography>
      );
    },
    accessorKey: "status",
    header: "Status",
    id: "status",
  },
];

const ExternalConnectionsList: React.FC = () => {
  const [sortDir] = useState<"asc" | "desc">("desc");
  const timeFilter = useMemo(
    () => ({
      createdBefore: new Date().toISOString(),
    }),
    []
  );
  const externalsQueryFilter = useMemo(() => ({ ...timeFilter }), [timeFilter]);
  const { data, loading } = useDataConnectorJobsQuery({
    variables: {
      filter: externalsQueryFilter,
    },
  });
  const externals = useMemo(() => {
    const connectionsArray = data?.dataConnectorJobs?.nodes;
    return orderBy(
      connectionsArray,
      ({ createdAt }) => new Date(createdAt),
      sortDir
    );
  }, [data, sortDir]);
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const selectedExternals = Object.keys(rowSelection)
    .filter((key) => rowSelection[key])
    .map((key) => externals.find(({ id }) => id === key));
  const totalCount = externals.length;
  if (loading) {
    return (
      <Stack alignItems="center" height="100%" justifyContent="center">
        <DqLoader size="sm" />
      </Stack>
    );
  }
  if (!loading && !totalCount) {
    return (
      <EmptyData
        primaryText="To add a dataset, click the button in the top-right corner. To download a dataset, go to the Datasets tab, select it, and click Export."
        secondaryText="To export an existing dataset, go to the Datasets tab, select a dataset and press the Export button."
      />
    );
  }
  return (
    <Box
      sx={{
        alignItems: "stretch",
        backgroundColor: "common.white",
        display: "flex",
        flex: 1,
        flexDirection: "column",
        justifyContent: "stretch",
        overflow: "hidden",
      }}
    >
      <Grid
        columnSpacing={0}
        container={true}
        sx={{ flex: 1, overflow: "hidden" }}
      >
        <Grid
          sx={{
            borderColor: "divider",
            borderRightStyle: "solid",
            borderRightWidth: "1px",
            height: "100%",
            overflow: "hidden",
          }}
          xs={5}
        >
          <DqTable
            autoSelectFirstRow={true}
            columns={externalsColumnDef}
            data={externals}
            enableBatchRowSelection={false}
            enableMultiRowSelection={false}
            enableRowSelection={true}
            enableSelectAll={false}
            getRowId={(row) => row.id}
            localization={{
              noRecordsToDisplay: "No imports/exports found",
            }}
            muiTableBodyRowProps={({
              row: { getToggleSelectedHandler, getIsSelected },
            }) => {
              return {
                onClick: getIsSelected()
                  ? undefined
                  : getToggleSelectedHandler(),
                sx: {
                  "& > .MuiTableCell-root:first-child": { pl: 2 },
                  "& > .MuiTableCell-root:last-child": { pr: 2 },
                  cursor: "pointer",
                },
              };
            }}
            muiTableHeadRowProps={{
              sx: {
                "& > .MuiTableCell-root:first-child": { pl: 2 },
                "& > .MuiTableCell-root:last-child": {
                  "& .Mui-TableHeadCell-ResizeHandle-Wrapper": {
                    right: "-1rem",
                  },
                  pr: 2,
                },
              },
            }}
            muiTablePaperProps={{
              sx: {
                display: "flex",
                flex: 1,
                flexDirection: "column",
                overflow: "hidden",
                width: "100%",
              },
            }}
            onRowSelectionChange={setRowSelection}
            state={{
              columnVisibility: { "mrt-row-select": false },
              rowSelection,
            }}
          />
        </Grid>
        {!loading ? (
          <Grid sx={{ height: "100%", overflow: "hidden" }} xs={6}>
            {selectedExternals.map((selectedExternal) =>
              selectedExternal ? (
                <Box
                  key={selectedExternal.id}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 2,
                    height: "100%",
                    overflow: "hidden",
                    p: 2,
                  }}
                >
                  <ExternalConnectionToolbar
                    connectionId={selectedExternal.id}
                  />
                  <ExternalConnectionDetails
                    connectionId={selectedExternal.id}
                  />
                </Box>
              ) : null
            )}
          </Grid>
        ) : null}
      </Grid>
    </Box>
  );
};

export default ExternalConnectionsList;
