import {
  InlineEditor,
  type InlineEditorProps,
  testIfInlineEditorValueIsEmpty,
} from "@decentriq/components";
import { useSetDataRoomDescriptionMutation } from "@decentriq/graphql/dist/hooks";
import { Box } from "@mui/joy";
import { memo, useCallback } from "react";
import { useDataRoom } from "contexts";
import { mapErrorToGeneralSnackbar, useDataRoomSnackbar } from "hooks";
import useDataRoomDescription from "./useDataRoomDescription";

interface DataRoomDescriptionEditorProps extends Partial<InlineEditorProps> {
  dataRoomId: string;
}

const DataRoomDescriptionEditor: React.FC<DataRoomDescriptionEditorProps> =
  memo(({ dataRoomId, ...props }) => {
    const { enqueueSnackbar } = useDataRoomSnackbar();
    const { description } = useDataRoomDescription();
    const { isPublished } = useDataRoom();
    const [setDataRoomDescriptionMutation] =
      useSetDataRoomDescriptionMutation();
    const updateDataRoomDescription = useCallback(
      async (description: string) => {
        try {
          await setDataRoomDescriptionMutation({
            variables: {
              input: {
                description,
                id: dataRoomId,
              },
            },
          });
        } catch (error) {
          enqueueSnackbar(
            ...mapErrorToGeneralSnackbar(
              error,
              "Data clean room description could not be updated."
            )
          );
        }
      },
      [enqueueSnackbar, dataRoomId, setDataRoomDescriptionMutation]
    );
    return (
      <Box
        sx={{
          lineHeight: "1.375rem",
          padding:
            isPublished && testIfInlineEditorValueIsEmpty(description || "")
              ? 0
              : "0.25rem 1.25rem",
        }}
      >
        <InlineEditor
          ContentProps={
            isPublished
              ? {
                  FullHeightToggleButtonWrapperProps: {
                    style: { height: "100%" },
                  },
                }
              : undefined
          }
          PlaceholderProps={{
            style: { color: "rgba(0, 0, 0, 0.54)", fontStyle: "italic" },
          }}
          cancelEditingButtonEnabled={false}
          onChange={updateDataRoomDescription}
          placeholder="Enter a description here"
          readOnly={isPublished}
          saveEditingButtonEnabled={false}
          saveEditingOnClickAway={true}
          value={description}
          withFormatting={true}
          {...props}
        />
      </Box>
    );
  });
DataRoomDescriptionEditor.displayName = "DataRoomDescriptionEditor";

export default DataRoomDescriptionEditor;
