import { faCheck, faClock, faUpload } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, Grid, Typography } from "@mui/joy";
import { grey } from "@mui/material/colors";
import { useCallback, useMemo, useState } from "react";
import DataNodeDataButton from "features/dataNodes/components/DataNodeConstructor/DataNodeDataButton";
import {
  type DatasetDetailsConfigContextValue,
  DatasetDetailsConfigProvider,
  type DatasetPopupConfigContextValue,
  DatasetPopupConfigProvider,
} from "features/datasets/contexts";
import {
  useMediaDataRoom,
  useMediaDataRoomInsightsData,
} from "features/mediaDataRoom/contexts";
import DataNodeItem from "../DataNodeItem";
import DataLabConnectDialog from "./DataLabConnectDialog";
import PublisherDataNodeDeprovisionButton from "./PublisherDataNodeDeprovisionButton";

interface PublisherDataNodeActionsProps {
  isProvisioned: boolean;
}

const PublisherDataNodeActions: React.FC<PublisherDataNodeActionsProps> = ({
  isProvisioned,
}) => {
  const { isPublisher, isDeactivated } = useMediaDataRoom();
  const [openConnectDialog, setOpenConnectDialog] = useState(false);
  const toggleOpenConnectDialog = useCallback(
    () => setOpenConnectDialog((state) => !state),
    [setOpenConnectDialog]
  );
  const canProvision = isPublisher && !isProvisioned && !isDeactivated;
  return (
    <Box
      onClick={(event) => {
        event.stopPropagation();
        event.preventDefault();
      }}
      sx={{ marginRight: "8px" }}
    >
      {canProvision ? (
        <Button
          color="primary"
          onClick={toggleOpenConnectDialog}
          startDecorator={<FontAwesomeIcon fixedWidth={true} icon={faUpload} />}
          style={{ marginLeft: "16px", maxWidth: "260px" }}
          variant="solid"
        >
          Provision datalab
        </Button>
      ) : (
        <Typography
          color={grey[400]}
          component="div"
          fontWeight="bold"
          level="body-sm"
        >
          {isProvisioned ? (
            isPublisher ? (
              <PublisherDataNodeDeprovisionButton />
            ) : (
              <>
                <FontAwesomeIcon
                  fixedWidth={true}
                  icon={faCheck}
                  style={{ marginRight: "4px" }}
                />
                Data provisioned
              </>
            )
          ) : (
            <>
              <FontAwesomeIcon
                fixedWidth={true}
                icon={faClock}
                style={{ marginRight: "4px" }}
              />
              Waiting for data
            </>
          )}
        </Typography>
      )}
      <DataLabConnectDialog
        onCancel={toggleOpenConnectDialog}
        open={openConnectDialog}
      />
    </Box>
  );
};

const PublishedDataNodeContent: React.FC = () => {
  const {
    publishedDatasetsHashes: {
      publisherDatasetsHashes: {
        matchingDatasetHash,
        segmentsDatasetHash,
        demographicsDatasetHash,
        embeddingsDatasetHash,
      },
    },
  } = useMediaDataRoomInsightsData();
  const datasetsWithLabels: { label: string; hash: string | undefined }[] = [
    { hash: matchingDatasetHash || undefined, label: "Users" },
    { hash: segmentsDatasetHash || undefined, label: "Segments" },
    { hash: demographicsDatasetHash || undefined, label: "Demographics" },
    { hash: embeddingsDatasetHash || undefined, label: "Embeddings" },
  ].filter(({ hash }) => Boolean(hash));
  const datasetPopupConfig = useMemo<DatasetPopupConfigContextValue>(
    () => ({ disableDelete: true, disableExport: true }),
    []
  );
  const datasetDetailsConfig = useMemo<DatasetDetailsConfigContextValue>(
    () => ({
      disableDeprovision: true,
      disableProvisionedDataRoomNavigation: true,
    }),
    []
  );
  return (
    <DatasetDetailsConfigProvider value={datasetDetailsConfig}>
      <DatasetPopupConfigProvider value={datasetPopupConfig}>
        <Box sx={{ padding: (theme) => theme.spacing(1, 0) }}>
          <Grid container={true} spacing={2}>
            {datasetsWithLabels.map(({ label, hash }, index) => (
              <Grid key={index} xs={6}>
                <Typography fontWeight={500} level="body-sm">
                  {label}
                </Typography>
                <DataNodeDataButton
                  dataType="table"
                  datasetId={label.toLocaleLowerCase()}
                  datasetManifestHash={hash}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      </DatasetPopupConfigProvider>
    </DatasetDetailsConfigProvider>
  );
};

const PublisherDataNode = () => {
  const {
    publishedDatasetsHashes: { hasPublisherData },
  } = useMediaDataRoomInsightsData();
  const { isPublisher } = useMediaDataRoom();
  const [isCollapsed, setIsCollapsed] = useState(true);
  const toggleCollapse = useCallback(
    () => setIsCollapsed((state) => !state),
    [setIsCollapsed]
  );
  const hasUploadingDescription = hasPublisherData && isPublisher;
  return (
    <DataNodeItem
      actions={<PublisherDataNodeActions isProvisioned={hasPublisherData} />}
      content={hasUploadingDescription ? <PublishedDataNodeContent /> : null}
      isCollapsed={isCollapsed}
      title="Publisher data"
      toggleCollapse={hasUploadingDescription ? toggleCollapse : undefined}
    />
  );
};

PublisherDataNode.displayName = "PublisherDataNode";

export default PublisherDataNode;
