import { useDataRoomAttestationSpecsQuery } from "@decentriq/graphql/dist/hooks";
import {
  faCopy,
  faExclamationTriangle,
  faFileCertificate,
  faShieldKeyhole,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Stack, Tooltip, Typography } from "@mui/joy";
import { Portal } from "@mui/material";
import { useBoolean, useHover } from "ahooks";
import { useSnackbar } from "notistack";
import { memo, useCallback, useRef } from "react";
import { Actions, PublishedAndProtectedDialog } from "components";
import { usePublishedDataRoom } from "contexts";
import { DataRoomActionTypes } from "features/dataRoom";
import { useCopyToClipboard } from "hooks";
import { EnclaveSpecName } from "utils/apicore";

const SPEC_MSG_BY_NAME: { [key in EnclaveSpecName]: string } = {
  [EnclaveSpecName.GCG]: "Enclave version",
  [EnclaveSpecName.SQL]: "SQL worker",
  [EnclaveSpecName.SQLITE]: "SQLite worker",
  [EnclaveSpecName.POST]: "Post worker",
  [EnclaveSpecName.PYTHON]: "Python worker",
  [EnclaveSpecName.R]: "R worker",
  [EnclaveSpecName.SYNTHETIC_DATA]: "Synthetic data worker",
  [EnclaveSpecName.S3]: "S3 worker",
};

function EnclaveSpecActions() {
  const { dataRoomId, driverAttestationHash } = usePublishedDataRoom();
  const { data: { dataRoomAttestationSpecs = [] } = {} } =
    useDataRoomAttestationSpecsQuery({
      variables: {
        dcrHash: dataRoomId,
        driverAttestationHash,
      },
    });
  // Open "Published and protected" dialog
  const [
    isPublishedAndProtectedDialogOpen,
    {
      setTrue: openPublishedAndProtectedDialog,
      setFalse: closePublishedAndProtectedDialog,
    },
  ] = useBoolean(false);
  const ref = useRef(null);
  const isHovering = useHover(ref);
  const { enqueueSnackbar } = useSnackbar();
  const [, hashCopyToClipboard] = useCopyToClipboard();
  const copyHashToClipboard = useCallback(
    (hash: string, message: string) => {
      if (hash) {
        hashCopyToClipboard(hash);
        enqueueSnackbar(
          <Box sx={{ alignItems: "center", display: "flex" }}>
            <FontAwesomeIcon
              fixedWidth={true}
              icon={faCopy}
              style={{ marginRight: "0.5rem" }}
            />
            {message}
          </Box>
        );
      }
    },
    [hashCopyToClipboard, enqueueSnackbar]
  );
  const menuItems = dataRoomAttestationSpecs.map((spec) =>
    makeMenuItem({
      copyHashToClipboard,
      hash: spec.hash,
      insecure: spec.isInsecure,
      name: SPEC_MSG_BY_NAME[spec.name as EnclaveSpecName] ?? "",
      unavailable: spec.isUnavailable,
    })
  );
  const actions = {
    buttons: [],
    menuLists: [
      [
        {
          icon: faFileCertificate,
          name: "More about enclave security",
          onClick: openPublishedAndProtectedDialog,
        },
      ],
      menuItems,
    ],
  };
  const isDcrInsecure = dataRoomAttestationSpecs.some(
    (spec) => spec.isInsecure
  );
  return (
    <Box alignItems="center" display="flex">
      {isDcrInsecure && (
        <Tooltip
          placement="bottom-end"
          title="This data clean room contains untrusted enclave workers. Please use only datasets for test purposes."
        >
          <Box color="red" display="inline" marginRight={1}>
            <FontAwesomeIcon icon={faExclamationTriangle} />
          </Box>
        </Tooltip>
      )}
      <Actions
        actions={actions}
        inline={false}
        moreIcon={isHovering ? faShieldKeyhole : faShieldKeyhole}
        moreTooltipPlacement={"bottom-end"}
        moreTooltipTitle={
          <div>
            <div>Published and protected</div>
            <div>
              This data clean room has been published. The data clean room
              definition cannot be changed anymore and participants can interact
              with it. All data room interactions are directly with the
              confidential computing enclave.
            </div>
          </div>
        }
        ref={ref}
      >
        <Portal>
          <PublishedAndProtectedDialog
            onClose={closePublishedAndProtectedDialog}
            open={isPublishedAndProtectedDialogOpen}
          />
        </Portal>
      </Actions>
    </Box>
  );
}

function makeMenuItem({
  name,
  copyHashToClipboard,
  hash,
  insecure,
  unavailable,
}: {
  name: string;
  copyHashToClipboard: (hash: string, message: string) => void;
  hash: string;
  insecure: boolean;
  unavailable: boolean;
}) {
  return {
    name: (
      <Stack>
        <Stack direction="row" gap={1}>
          {(insecure || unavailable) && (
            <Box color="red" display="inline">
              <FontAwesomeIcon icon={faExclamationTriangle} />
            </Box>
          )}
          <Typography level="body-md">
            <strong>{name}</strong>
          </Typography>
        </Stack>
        <Stack alignContent="center" direction="row" gap={2}>
          <Box
            sx={{
              maxWidth: "250px",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
            title={hash}
          >
            {hash}
          </Box>
          <Box
            onClick={() =>
              copyHashToClipboard(hash!, `${name} copied to clipboard`)
            }
            sx={{
              alignItems: "center",
              cursor: "pointer",
              display: "flex",
            }}
          >
            <FontAwesomeIcon fixedWidth={true} icon={faCopy} />
          </Box>
        </Stack>
      </Stack>
    ),
    type: DataRoomActionTypes.Details,
  };
}

export default memo(EnclaveSpecActions);
