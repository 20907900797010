import { useState } from "react";
import { useApiCore } from "contexts";
import { exportDataScienceDataRoom } from "utils/apicore";

interface usePublishedDataRoomAsJsonArgs {
  id: string;
  driverAttestationHash: string | undefined;
}

interface usePublishedDataRoomAsJsonResult {
  publishedDataRoomAsJson: () => Promise<string | undefined>;
  publishedDataRoomAsJsonLoading: boolean;
}

const usePublishedDataRoomAsJson = ({
  id: dataRoomId,
  driverAttestationHash,
}: usePublishedDataRoomAsJsonArgs): usePublishedDataRoomAsJsonResult => {
  const { sessionManager } = useApiCore();
  const [exporting, setExporting] = useState(false);
  const publishedDataRoomAsJson = async () => {
    setExporting(true);
    try {
      const sdkSession = await sessionManager.get({ driverAttestationHash });
      const publishedDataRoom =
        await sdkSession.retrieveDataScienceDataRoom(dataRoomId);
      if (!publishedDataRoom) {
        throw new Error(
          `Could not retrieve Data Science Data Room "${dataRoomId}"`
        );
      }
      const exportedDataScienceDataRoom = exportDataScienceDataRoom(
        sdkSession.compiler,
        publishedDataRoom
      );
      return JSON.stringify(exportedDataScienceDataRoom, null, 2);
    } finally {
      setExporting(false);
    }
  };
  return {
    publishedDataRoomAsJson,
    publishedDataRoomAsJsonLoading: exporting,
  };
};

export default usePublishedDataRoomAsJson;
