import { createContext, useContext, useMemo } from "react";
import { DataNodeConstructorMode } from "features/dataNodes/models";

interface DataNodeConstructorParams {
  readOnly: boolean;
  withDatasetStatus: boolean;
  withUploading: boolean;
  withDataDeletion: boolean;
}

export const DataNodeConstructorParamsContext =
  createContext<DataNodeConstructorParams>({
    readOnly: false,
    withDataDeletion: false,
    withDatasetStatus: false,
    withUploading: false,
  });

export const DataNodeConstructorParamsProvider =
  DataNodeConstructorParamsContext.Provider;
export const DataNodeConstructorParamsConsumer =
  DataNodeConstructorParamsContext.Consumer;

export const useDataNodeConstructorParams = () =>
  useContext(DataNodeConstructorParamsContext);

interface DataNodeConstructorParamsWrapperProps {
  mode: DataNodeConstructorMode;
  children: React.ReactNode;
}

export const DataNodeConstructorParamsWrapper: React.FC<
  DataNodeConstructorParamsWrapperProps
> = ({ mode, children }) => {
  const params = useMemo<DataNodeConstructorParams>(
    () => ({
      readOnly:
        mode !== DataNodeConstructorMode.EDIT &&
        mode !== DataNodeConstructorMode.EDIT_WITH_TESTING,
      withDataDeletion:
        mode === DataNodeConstructorMode.ACTION ||
        mode === DataNodeConstructorMode.DEGRADE_ACTION ||
        mode === DataNodeConstructorMode.EDIT_WITH_TESTING,
      withDatasetStatus:
        mode === DataNodeConstructorMode.STATUS ||
        mode === DataNodeConstructorMode.ACTION ||
        mode === DataNodeConstructorMode.DEGRADE_ACTION,
      withUploading:
        mode === DataNodeConstructorMode.ACTION ||
        mode === DataNodeConstructorMode.EDIT_WITH_TESTING,
    }),
    [mode]
  );
  return (
    <DataNodeConstructorParamsProvider value={params}>
      {children}
    </DataNodeConstructorParamsProvider>
  );
};
