export enum DataNodeConstructorMode {
  EDIT = "edit",
  EDIT_WITH_TESTING = "edit_with_testing",
  READONLY = "readonly",
  STATUS = "status",
  ACTION = "action",
  DEGRADE_ACTION = "degrade_action",
}

export enum DataNodeIngestionStepStatus {
  None,
  Loading,
  Success,
  Failed,
}
