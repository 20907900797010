import {
  faBan,
  faPen,
  faPowerOff,
  faQuestionCircle,
  faShieldKeyhole,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CircularProgress, Tooltip } from "@mui/joy";
import { type Theme } from "@mui/material";
import { memo } from "react";
import { makeStyles } from "tss-react/mui";
import { StatusTooltipTitle } from "components";

const useStateIconStyles = makeStyles()((theme: Theme) => ({
  icon: {
    fontSize: "1rem",
  },
  iconWrapper: {
    alignItems: "center",
    display: "flex",
    height: theme.spacing(2),
    justifyContent: "center",
    width: theme.spacing(2),
  },
}));

interface StateIconProps {
  status: string;
}

export const StateIcon = memo<StateIconProps>(({ status }) => {
  const { classes: statusIconClasses } = useStateIconStyles();
  switch ((status || "").toUpperCase()) {
    case "BUSY":
      return (
        <span className={statusIconClasses.iconWrapper}>
          <CircularProgress className={statusIconClasses.icon} />
        </span>
      );
    case "PUBLISHED":
      return (
        <span className={statusIconClasses.iconWrapper}>
          <FontAwesomeIcon
            className={statusIconClasses.icon}
            fixedWidth={true}
            icon={faShieldKeyhole}
          />
        </span>
      );
    case "DRAFT":
      return (
        <span className={statusIconClasses.iconWrapper}>
          <FontAwesomeIcon
            className={statusIconClasses.icon}
            fixedWidth={true}
            icon={faPen}
          />
        </span>
      );
    case "STOPPED":
      return (
        <span className={statusIconClasses.iconWrapper}>
          <FontAwesomeIcon
            className={statusIconClasses.icon}
            fixedWidth={true}
            icon={faBan}
          />
        </span>
      );
    case "DEACTIVATED":
      return (
        <span className={statusIconClasses.iconWrapper}>
          <FontAwesomeIcon
            className={statusIconClasses.icon}
            fixedWidth={true}
            icon={faPowerOff}
          />
        </span>
      );
    default:
      return (
        <span className={statusIconClasses.iconWrapper}>
          <FontAwesomeIcon
            className={statusIconClasses.icon}
            fixedWidth={true}
            icon={faQuestionCircle}
          />
        </span>
      );
  }
});

interface StateIconWithTooltipProps {
  status: string;
  statusUpdatedAt?: string;
  statusTimePrefix?: string;
  statusTimeSuffix?: string;
  className?: string;
}

const StateIconWithTooltip = memo<StateIconWithTooltipProps>(
  ({
    status = "N/A",
    statusUpdatedAt,
    statusTimePrefix = "",
    statusTimeSuffix = "",
    className,
  }) => {
    return (
      <Tooltip
        placement="top-start"
        title={
          <StatusTooltipTitle
            status={status}
            statusTimePrefix={statusTimePrefix}
            statusTimeSuffix={statusTimeSuffix}
            statusUpdatedAt={statusUpdatedAt}
          />
        }
      >
        <span className={className}>
          <StateIcon status={status} />
        </span>
      </Tooltip>
    );
  }
);

export default StateIconWithTooltip;
