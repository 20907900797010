import { memo } from "react";
import { VirtualizedTable } from "components";
import {
  dataRoomTablePrimitiveTypePresentation,
  legacyDataRoomTablePrimitiveTypeToColumnDataType,
} from "models";

interface ResultSchemaProps {
  schema?: {
    namedColumns?: { name: string; columnType: { primitiveType: number } }[];
  };
}

const ResultSchema: React.FC<ResultSchemaProps> = ({ schema }) => {
  if (schema?.namedColumns?.length) {
    const { namedColumns } = schema;
    return (
      <VirtualizedTable
        columns={[
          {
            dataKey: "name",
            label: "Column name",
          },
          {
            dataKey: "columnType",
            label: "Data type",
          },
        ]}
        maxRowsShown={schema?.namedColumns?.length > 5 ? 10 : 5}
        rowCount={namedColumns.length}
        rowGetter={({ index }) => ({
          columnType: dataRoomTablePrimitiveTypePresentation.get(
            legacyDataRoomTablePrimitiveTypeToColumnDataType.get(
              namedColumns[index].columnType.primitiveType
            )!
          ),
          name: namedColumns[index].name,
        })}
        withHeader={true}
      />
    );
  }
  return null;
};

ResultSchema.displayName = "ResultSchema";

export default memo(ResultSchema);
