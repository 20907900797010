import {
  type ActiveMarketsQuery,
  type MatchingColumnFormat,
  type TableColumnHashingAlgorithm,
} from "@decentriq/graphql/types";

// NOTE: Came from "features/mediaPortalShared/hooks/useActiveMarkets/useActiveMarkets"
export type ActiveMarket = Omit<
  ActiveMarketsQuery["publisherMarkets"]["nodes"][number],
  "__typename"
>;

// NOTE: Came from "features/MediaInsightsDcr/types"
export interface PublishMediaDataRoomInput {
  advertiserEmails: string[];
  agencyEmails: string[];
  dataPartnerEmails: string[];
  enableAdvertiserAudienceDownload: boolean;
  enableExclusionTargeting: boolean;
  enableInsights: boolean;
  enableLookalike: boolean;
  enableRemarketing: boolean;
  enableRuleBasedAudiences: boolean;
  hideAbsoluteValuesFromInsights: boolean;
  mainAdvertiserEmail: string;
  mainPublisherEmail: string;
  matchingIdFormat: MatchingColumnFormat;
  matchingIdHashingAlgorithm?: TableColumnHashingAlgorithm;
  name: string;
  observerEmails: string[];
  publisherEmails: string[];
}

// NOTE: Came from "features/mediaDataRoom/types"
export enum MediaDataRoomUserRole {
  Advertiser = "advertiser",
  Agency = "agency",
  DataPartner = "data partner",
  Observer = "observer",
  Publisher = "publisher",
}

// NOTE: Came from "models/dataRoom/dataRoom.new"
export enum CollaborationTypes {
  /**
   * @deprecated This collaboration type is deprecated for new DCRs after inroduciton of the `RuleBasedAudiences` collaboration type
   */
  ExclusionTargeting = "Exclusion targeting",
  Insights = "Insights",
  Lookalike = "Lookalike audiences",
  Measurements = "Measurement",
  Remarketing = "Remarketing",
  RuleBasedAudiences = "Rule-based audiences",
}

// NOTE: Came from "features/MediaInsightsDcr/contexts/MediaInsightsDcrCreateWizardContext/CreationWIzardStepperContext"
export enum MediaDataRoomOrganizationRole {
  ADVERTISER = "Advertiser",
  DATA_PARTNER = "Data partner",
  PUBLISHER = "Publisher",
}

// NOTE: Came from "models/dataRoom/matchingId"
export enum RawMatchingID {
  EMAIL = "email",
  HASHED_EMAIL = "hashed_email",
  HASHED_PHONE_NUMBER = "hashed_phone_number",
  PHONE_NUMBER = "phone_number",
  STRING = "string",
}

// NOTE: Came from "features/MediaInsightsDcr/models"
export enum RawSupportedFeatures {
  ENABLE_ADVERTISER_AUDIENCE_DOWNLOAD = "ENABLE_ADVERTISER_AUDIENCE_DOWNLOAD",
  ENABLE_EXTENDED_LAL_QUALITY_STATS = "ENABLE_EXTENDED_LAL_QUALITY_STATS",
  ENABLE_MODEL_PERFORMANCE_EVALUATION = "ENABLE_MODEL_PERFORMANCE_EVALUATION",
  HIDE_ABSOLUTE_VALUES_FROM_INSIGHTS = "HIDE_ABSOLUTE_VALUES_FROM_INSIGHTS",
}
