import { type ApolloCache, type FetchResult } from "@apollo/client";
import {
  useCreatePublishedDataRoomIsHiddenMutation,
  usePublishedDataRoomIsHiddenQuery,
  usePublishedLookalikeMediaDataRoomIsHiddenQuery,
  usePublishedMediaDataRoomIsHiddenQuery,
  usePublishedMediaInsightsDcrIsHiddenQuery,
  useUpdatePublishedDataRoomIsHiddenMutation,
} from "@decentriq/graphql/dist/hooks";
import {
  type CreatePublishedDataRoomIsHiddenMutation,
  DataRoomUserSettingsFragment,
  MyDataRoomsDocument,
  type MyDataRoomsQuery,
  SharedWithMeDataRoomsDocument,
  type SharedWithMeDataRoomsQuery,
  type UpdatePublishedDataRoomIsHiddenMutation,
} from "@decentriq/graphql/dist/types";
import { useCallback } from "react";
import { DataRoomTypeNames } from "models";

interface usePublishedDataRoomIsHiddenArgs {
  id: string;
  __typename: DataRoomTypeNames;
  skip?: boolean;
}

interface usePublishedDataRoomIsHiddenResult {
  isHidden: boolean;
  loading: boolean;
  setIsHidden: (
    isHidden: boolean
  ) =>
    | Promise<FetchResult<UpdatePublishedDataRoomIsHiddenMutation>>
    | Promise<FetchResult<CreatePublishedDataRoomIsHiddenMutation>>;
  setting: boolean;
}

const usePublishedDataRoomIsHidden = ({
  id: dataRoomId,
  __typename,
  skip = false,
}: usePublishedDataRoomIsHiddenArgs): usePublishedDataRoomIsHiddenResult => {
  const { data: dcrData, loading: isDcrDataLoading } =
    usePublishedDataRoomIsHiddenQuery({
      skip: skip || __typename !== DataRoomTypeNames.PublishedDataRoom,
      variables: { id: dataRoomId },
    });
  const { data: mediaDcrData, loading: isMediaDcrDataLoading } =
    usePublishedMediaDataRoomIsHiddenQuery({
      skip: skip || __typename !== DataRoomTypeNames.PublishedMediaDataRoom,
      variables: { id: dataRoomId },
    });
  const {
    data: lookalikeMediaDcrData,
    loading: isLookalikeMediaDcrDataLoading,
  } = usePublishedLookalikeMediaDataRoomIsHiddenQuery({
    skip:
      skip || __typename !== DataRoomTypeNames.PublishedLookalikeMediaDataRoom,
    variables: { id: dataRoomId },
  });
  const { data: mediaInsightsDcrData, loading: isMediaInsightsDcrDataLoading } =
    usePublishedMediaInsightsDcrIsHiddenQuery({
      skip: skip || __typename !== DataRoomTypeNames.PublishedMediaInsightsDcr,
      variables: { id: dataRoomId },
    });
  const userSettingsId =
    dcrData?.publishedDataRoom?.userSettings?.id ||
    mediaDcrData?.publishedMediaDataRoom?.userSettings?.id ||
    lookalikeMediaDcrData?.publishedLookalikeMediaDataRoom?.userSettings?.id ||
    mediaInsightsDcrData?.publishedMediaInsightsDcr?.userSettings?.id;
  const isHidden =
    dcrData?.publishedDataRoom?.userSettings?.isHidden ||
    mediaDcrData?.publishedMediaDataRoom?.userSettings?.isHidden ||
    lookalikeMediaDcrData?.publishedLookalikeMediaDataRoom?.userSettings
      ?.isHidden ||
    mediaInsightsDcrData?.publishedMediaInsightsDcr?.userSettings?.isHidden;
  const updateCache = useCallback(
    (cache: ApolloCache<any>, isHidden: boolean) => {
      if (!isHidden) {
        return;
      }
      cache.updateQuery<MyDataRoomsQuery>(
        { query: MyDataRoomsDocument },
        (data) => {
          return data
            ? {
                ...data,
                dataRooms: {
                  ...data.dataRooms,
                  nodes: data?.dataRooms.nodes.filter(
                    ({ id }) => id !== dataRoomId
                  ),
                },
              }
            : null;
        }
      );
      cache.updateQuery<SharedWithMeDataRoomsQuery>(
        { query: SharedWithMeDataRoomsDocument },
        (data) => {
          return data
            ? {
                ...data,
                dataRooms: {
                  ...data.dataRooms,
                  nodes: data?.dataRooms.nodes.filter(
                    ({ id }) => id !== dataRoomId
                  ),
                },
              }
            : null;
        }
      );
    },
    [dataRoomId]
  );
  const [
    createPublishedDataRoomIsHiddenMutation,
    { loading: createPublishedDataRoomIsHiddenLoading },
  ] = useCreatePublishedDataRoomIsHiddenMutation({
    update: (cache, { data }) => {
      const isHidden =
        data?.publishedDataRoom.createUserSettings.isArchived || false;
      cache.modify({
        fields: {
          userSettings: () => {
            const userSettingsRef = cache.writeFragment({
              data: data?.publishedDataRoom.createUserSettings,
              fragment: DataRoomUserSettingsFragment,
            });
            return userSettingsRef;
          },
        },
        id: cache.identify({
          __typename,
          id: dataRoomId,
        }),
      });
      updateCache(cache, isHidden);
    },
  });
  const [
    updatePublishedDataRoomIsHiddenMutation,
    { loading: updatePublishedDataRoomIsHiddenLoading },
  ] = useUpdatePublishedDataRoomIsHiddenMutation({
    update: (cache, { data }) => {
      const isHidden =
        data?.publishedDataRoom.updateUserSettings.isHidden || false;
      cache.modify({
        fields: {
          isArchived: () => isHidden,
        },
        id: cache.identify({
          __typename: "DataRoomUserSettings",
          id: data?.publishedDataRoom.updateUserSettings.id,
        }),
      });
      updateCache(cache, isHidden);
    },
  });
  const setIsHidden = (isHidden: boolean) => {
    return userSettingsId
      ? updatePublishedDataRoomIsHiddenMutation({
          refetchQueries: isHidden
            ? undefined
            : [
                { query: MyDataRoomsDocument },
                { query: SharedWithMeDataRoomsDocument },
              ],
          variables: { id: userSettingsId, isHidden },
        })
      : createPublishedDataRoomIsHiddenMutation({
          refetchQueries: isHidden
            ? undefined
            : [
                { query: MyDataRoomsDocument },
                { query: SharedWithMeDataRoomsDocument },
              ],
          variables: { isHidden, publishedDataRoomId: dataRoomId },
        });
  };
  const setting = userSettingsId
    ? updatePublishedDataRoomIsHiddenLoading
    : createPublishedDataRoomIsHiddenLoading;
  const loading =
    isDcrDataLoading ||
    isMediaDcrDataLoading ||
    isLookalikeMediaDcrDataLoading ||
    isMediaInsightsDcrDataLoading;
  return {
    isHidden: Boolean(isHidden),
    loading,
    setIsHidden,
    setting,
  };
};

export default usePublishedDataRoomIsHidden;
