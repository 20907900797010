import { Drawer, List, styled } from "@mui/joy";
import { useBoolean } from "ahooks";
import { memo } from "react";
import { SidebarContent, SidebarProvider } from "features/sidebar";

export const SIDEBAR_WIDTH: number = 60;

export const SidebarContentWrapper = styled(List)(({ theme }) => ({
  alignItems: "center",
  display: "flex",
  flexDirection: "column",
  height: "100%",
  minWidth: `${SIDEBAR_WIDTH}px`,
  overflowX: "hidden",
  overflowY: "auto",
  padding: theme.spacing(1),
  paddingBottom: 0,
  width: "100%",
}));

const Sidebar = memo(() => {
  const [
    isSidebarExpanded,
    {
      setTrue: expandSidebar,
      setFalse: collapseSidebar,
      toggle: toggleSidebar,
    },
  ] = useBoolean(false);
  return (
    <>
      <Drawer
        disablePortal={true}
        onClose={collapseSidebar}
        open={isSidebarExpanded}
        slotProps={{
          backdrop: {
            sx: { opacity: 0 },
          },
        }}
        sx={{
          "& .MuiDrawer-content": {
            position: "absolute",
            transform: "none",
            transition: "width 0.2s linear",
            visibility: "visible",
            width: isSidebarExpanded ? "250px" : `${SIDEBAR_WIDTH}px`,
          },
          position: "relative",
          zIndex: 10,
        }}
      >
        <SidebarProvider
          value={{
            collapseSidebar,
            expandSidebar,
            isSidebarExpanded,
            toggleSidebar,
          }}
        >
          <SidebarContentWrapper>
            <SidebarContent />
          </SidebarContentWrapper>
        </SidebarProvider>
      </Drawer>
    </>
  );
});

Sidebar.displayName = "Sidebar";

export default Sidebar;
