import {
  useDraftSyntheticComputeNodeQuery,
  usePublishedSyntheticComputeNodeQuery,
} from "@decentriq/graphql/dist/hooks";
import {
  type DraftNodeConnection,
  type DraftSyntheticNode,
  type PublishedNodeConnection,
  type PublishedSyntheticNode,
} from "@decentriq/graphql/dist/types";
import {
  useComputeNodesVars,
  useDataRoom,
  usePublishedDataRoom,
  useRequest,
} from "contexts";
import {
  mapDraftDataRoomErrorToSnackbar,
  mapErrorToGeneralSnackbar,
  useDataRoomSnackbar,
} from "hooks";

const useSyntheticComputeNode = (
  computeNodeId: string
): {
  node: PublishedSyntheticNode | DraftSyntheticNode | undefined;
  hasDependency: boolean;
  dependencyId?: string;
} => {
  const { enqueueSnackbar } = useDataRoomSnackbar();
  const { isPublished } = useDataRoom();
  const { dataRoomId: dcrHash, driverAttestationHash } = usePublishedDataRoom();
  const { commitId } = useRequest();
  const { executionContext } = useComputeNodesVars();
  const shouldUseDraft =
    !isPublished ||
    executionContext === "development" ||
    executionContext === "requests";
  // draft & development
  const { data: draftData } = useDraftSyntheticComputeNodeQuery({
    onError: (error) => {
      enqueueSnackbar(
        ...mapDraftDataRoomErrorToSnackbar(
          error,
          "The computation could not be retrieved. Please try again by refreshing the page."
        )
      );
    },
    skip: !shouldUseDraft,
    variables: { computeNodeId },
  });
  // published
  const { data: publishedData } = usePublishedSyntheticComputeNodeQuery({
    fetchPolicy: "cache-only",
    onError: (error) => {
      enqueueSnackbar(
        ...mapErrorToGeneralSnackbar(
          error,
          "The computation could not be retrieved. Please try again by refreshing the page."
        )
      );
    },
    skip: shouldUseDraft,
    variables: { commitId, computeNodeId, dcrHash, driverAttestationHash },
  });
  const publishedNode = publishedData?.publishedNode as
    | PublishedSyntheticNode
    | undefined;
  const draftNode = draftData?.draftNode as DraftSyntheticNode | undefined;
  return {
    dependencyId: shouldUseDraft
      ? (draftNode?.dependency as DraftNodeConnection | undefined)?.draftNode
          ?.id ||
        (draftNode?.dependency as PublishedNodeConnection | undefined)
          ?.computeNodeId
      : publishedNode?.dependency?.id,
    hasDependency: shouldUseDraft
      ? !!draftNode?.dependency
      : !!publishedNode?.dependency,
    node: shouldUseDraft ? draftNode : publishedNode,
  };
};

export default useSyntheticComputeNode;
