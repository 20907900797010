import { withAuthenticationRequired } from "@auth0/auth0-react";
import { type ComponentType } from "react";
import { withKeychain } from "wrappers";

const PrivateRoute = ({
  component,
  ...props
}: {
  component: ComponentType;
}) => {
  const KeychainWrapped = withKeychain(withAuthenticationRequired(component));
  return <KeychainWrapped {...props} />;
};

export default PrivateRoute;
