import { createContext, useContext } from "react";
import { DataRoomType } from "models";

export interface DataRoomErrorReportContextValue {
  dataRoomId: string;
  dataRoomType?: DataRoomType;
  getDcrHLRepresentation?: () => Promise<string>;
  getAuditLog?: () => Promise<string>;
  getDevelopmentInfo?: () => Promise<string>;
  getRequestsInfo?: () => Promise<string>;
}

const DataRoomErrorReportContext =
  createContext<DataRoomErrorReportContextValue>({
    dataRoomId: "__default__",
    dataRoomType: DataRoomType.DataScience,
  });

export const DataRoomErrorReportProvider = DataRoomErrorReportContext.Provider;

export const useDataRoomErrorReport = () =>
  useContext(DataRoomErrorReportContext);
