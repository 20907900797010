import {
  useDataConnectorJobQuery,
  useDataConnectorJobResultLazyQuery,
} from "@decentriq/graphql/dist/hooks";
import { DataConnectorJobType } from "@decentriq/graphql/dist/types";
import { useCallback, useState } from "react";
import { EXTERNAL_CONNECTIONS_TYPES } from "features/datasets/components/ExternalConnections";

type useExternalConnectionDataHookReturn = {
  connectionType: string | undefined;
  createdAt: string | undefined;
  finishedAt: string | undefined;
  name: string | undefined;
  status: string | undefined;
  type: EXTERNAL_CONNECTIONS_TYPES;
};

const useExternalConnectionData = (
  connectionId: string
): useExternalConnectionDataHookReturn => {
  const { data: { dataConnectorJob } = {} } = useDataConnectorJobQuery({
    skip: !connectionId,
    variables: { id: connectionId },
  });
  return {
    connectionType: (dataConnectorJob?.targetType ??
      dataConnectorJob?.sourceType)!,
    createdAt: dataConnectorJob?.createdAt,
    finishedAt: dataConnectorJob?.finishedAt,
    name: dataConnectorJob?.name,
    status: dataConnectorJob?.status,
    type:
      dataConnectorJob?.type === DataConnectorJobType.ExportDataset
        ? EXTERNAL_CONNECTIONS_TYPES.EXPORT
        : EXTERNAL_CONNECTIONS_TYPES.IMPORT,
  };
};

type useExternalConnectionResultHookReturn = [
  () => void,
  { error: string; isLoading: boolean },
];

const useExternalConnectionResult = (
  connectionId: string
): useExternalConnectionResultHookReturn => {
  const [error, setError] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // Fetch enclave data to show on expanded item
  const [fetchDataConnectorJobResult] = useDataConnectorJobResultLazyQuery({
    variables: { id: connectionId },
  });

  const fetchExternalConnectionResult = useCallback(async (): Promise<void> => {
    setIsLoading(true);
    const { data: { dataConnectorJob } = {} } =
      await fetchDataConnectorJobResult();
    setError(dataConnectorJob?.result?.error || "");
    setIsLoading(false);
  }, [fetchDataConnectorJobResult]);

  return [fetchExternalConnectionResult, { error, isLoading }];
};

export { useExternalConnectionData, useExternalConnectionResult };
