import {
  ColumnDataType,
  type SyntheticMaskType,
} from "@decentriq/graphql/dist/types";
import { testIds } from "@decentriq/utils";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Checkbox,
  FormControl,
  IconButton,
  Option,
  Select,
  Tooltip,
  Typography,
} from "@mui/joy";
import { ListItem } from "@mui/material";
import { teal } from "@mui/material/colors";
import { memo } from "react";
import {
  dataRoomSyntheticDataMaskingTypePresentation as dataRoomSyntheticDataMaskTypePresentation,
  dataRoomSyntheticDataNumericMaskingType,
  tableColumnDataTypePresentation,
} from "models";

interface MaskingConfigRecordConstructorProps {
  index: number;
  maskedColumnId?: string;
  isSelected: boolean;
  isExisting: boolean;
  isDeleted: boolean;
  disabled?: boolean;
  readonly?: boolean;
  name: string;
  dataType: ColumnDataType;
  maskType?: SyntheticMaskType;
  onCheck: (
    column: {
      dataType: ColumnDataType;
      name: string;
    },
    index: number,
    maskedColumnId?: string
  ) => void;
  onMaskUpdate: (maskedColumnId: string, maskType: SyntheticMaskType) => void;
  onUncheck: (maskedColumnId: string) => void;
  onDelete: (maskedColumnId: string) => void;
}

const MaskingConfigRecordConstructor: React.FC<MaskingConfigRecordConstructorProps> =
  memo(
    ({
      index,
      maskedColumnId,
      name,
      dataType,
      isSelected,
      isExisting,
      isDeleted,
      maskType,
      onCheck,
      onMaskUpdate,
      onUncheck,
      onDelete,
      disabled,
    }) => {
      const style = {
        display: "flex",
        marginBottom: 4,
        padding: 0,
      };
      return (
        <ListItem style={style}>
          <div style={{ flex: 5 }}>
            {name}
            {isDeleted || !isExisting ? (
              <span
                style={{
                  backgroundColor: isDeleted ? "#d32f2f" : teal["600"],
                  borderRadius: "3px",
                  color: "white",
                  marginLeft: ".5rem",
                  padding: "2px",
                  verticalAlign: "middle",
                }}
              >
                {isDeleted ? "Deleted" : "New"}
              </span>
            ) : null}
          </div>
          <div style={{ display: "flex", flex: 2, justifyContent: "center" }}>
            {tableColumnDataTypePresentation.get(dataType)}
          </div>
          <div style={{ display: "flex", flex: 2, justifyContent: "center" }}>
            <Tooltip enterDelay={500} title={""}>
              <div style={{ display: "flex" }}>
                <Checkbox
                  checked={isSelected}
                  disabled={disabled || isDeleted}
                  onChange={(event: any) => {
                    if (isDeleted) return;
                    if (isSelected) {
                      onUncheck(maskedColumnId!);
                    } else {
                      onCheck(
                        {
                          dataType,
                          name,
                        },
                        index,
                        maskedColumnId
                      );
                    }
                  }}
                  style={{ minWidth: 124 }}
                />
              </div>
            </Tooltip>
          </div>
          <div
            style={{
              alignItems: "center",
              display: "flex",
              flex: 3,
              justifyContent:
                isSelected && isDeleted ? "space-between" : "center",
            }}
          >
            {isSelected && !isDeleted ? (
              <FormControl>
                <Tooltip
                  enterDelay={500}
                  placement="top"
                  title="" // Data type
                >
                  <Select
                    data-testid={`${testIds.computeNode.computeNodeEditor.maskingHelper}${name}`}
                    disabled={disabled || isDeleted}
                    onChange={(event, value) => {
                      if (isDeleted) return;
                      onMaskUpdate(maskedColumnId!, value as SyntheticMaskType);
                    }}
                    sx={{
                      maxWidth: 240,
                      minWidth: 144,
                      textAlign: "center",
                      width: "100%",
                    }}
                    value={maskType}
                  >
                    {Array.from(
                      dataRoomSyntheticDataMaskTypePresentation.entries()
                    )
                      .filter(
                        ([value]) =>
                          dataType === ColumnDataType.Text ||
                          dataRoomSyntheticDataNumericMaskingType.includes(
                            value
                          )
                      )
                      .map(([value, label], index) => (
                        <Option
                          data-testid={`${testIds.computeNode.computeNodeEditor.maskingHelper}${value}`}
                          key={index}
                          value={value}
                        >
                          {label}
                        </Option>
                      ))}
                  </Select>
                </Tooltip>
              </FormControl>
            ) : null}
            {isDeleted && (
              <>
                {isSelected ? (
                  <Typography level="body-md">
                    {dataRoomSyntheticDataMaskTypePresentation.get(maskType!)}
                  </Typography>
                ) : undefined}
                <IconButton
                  onClick={() => {
                    onDelete(maskedColumnId!);
                  }}
                >
                  <FontAwesomeIcon fixedWidth={true} icon={faTimes} />
                </IconButton>
              </>
            )}
          </div>
        </ListItem>
      );
    }
  );

MaskingConfigRecordConstructor.displayName = "MaskingConfigRecordConstructor";

export default MaskingConfigRecordConstructor;
