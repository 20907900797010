import { InfoTooltip } from "@decentriq/components";
import { Box, styled, Typography } from "@mui/joy";
import { memo } from "react";

const DataLabStatisticsTileBox = styled(Box)({
  alignItems: "center",
  display: "flex",
  flexDirection: "column",
  height: "100%",
  justifyContent: "center",
  width: "100%",
});

type DataLabStatisticsTileProps = {
  title: string;
  tooltipTitle: string;
  value: number;
};

const DataLabStatisticsTile: React.FC<DataLabStatisticsTileProps> = ({
  title,
  tooltipTitle,
  value,
}) => {
  const formatter = Intl.NumberFormat("en-US", { notation: "compact" });
  return (
    <DataLabStatisticsTileBox>
      <Typography
        fontWeight={600}
        level="h2"
        sx={({ palette }) => ({ color: palette.primary[700] })}
      >
        {formatter.format(value)}
      </Typography>
      <Typography level="body-sm" textAlign="center">
        {title}
        <InfoTooltip tooltip={tooltipTitle} top="2px" />
      </Typography>
    </DataLabStatisticsTileBox>
  );
};

DataLabStatisticsTile.displayName = "DataLabStatisticsTile";

export default memo(DataLabStatisticsTile);
