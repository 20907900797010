import {
  faUpload,
  type IconDefinition,
} from "@fortawesome/pro-light-svg-icons";
import { faAngleDown } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  ButtonGroup,
  IconButton,
  ListItemDecorator,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/joy";
import { ClickAwayListener } from "@mui/material";
import { Fragment, memo, useMemo } from "react";
import { usePopupState } from "hooks";
import { bindToggle } from "hooks/usePopupState/usePopupState";
import { type DataType } from "models";
import DataNodeDataButton from "./DataNodeDataButton";

interface DataNodeReprovisionButtonProps {
  id: string;
  label: string;
  onUpload: () => void;
  datasetHash?: string;
  dataType: DataType;
  testing?: boolean;
}

const DataNodeReprovisionButton: React.FC<DataNodeReprovisionButtonProps> = ({
  id,
  label,
  onUpload,
  datasetHash,
  dataType,
  testing,
}) => {
  const popupId = "data-node-reprovision-menu";
  const popupState = usePopupState({ popupId });
  const { anchorEl, isOpen, close } = popupState;
  const options = useMemo<
    { label: string; icon: IconDefinition; tooltip: string }[]
  >(
    () => [
      {
        icon: faUpload,
        label: `Reprovision ${label}`,
        tooltip: `This will replace the current ${label} with a new ${label} provisioned by you. All derived computation results will be reset.`,
      },
    ],
    [label]
  );
  const onMenuItemClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number
  ) => {
    close();
    if (index === 0) onUpload();
  };
  return (
    <Fragment>
      <ButtonGroup color={testing ? "neutral" : "primary"} role="group">
        <DataNodeDataButton
          dataType={dataType}
          datasetId={id}
          datasetManifestHash={datasetHash}
          onClick={close}
          testing={testing}
        />
        <IconButton {...bindToggle(popupState)}>
          <FontAwesomeIcon icon={faAngleDown} />
        </IconButton>
      </ButtonGroup>
      <ClickAwayListener
        mouseEvent="onMouseDown"
        onClickAway={(event) => {
          if ((event.target as HTMLElement).closest("button") !== anchorEl) {
            close();
          }
        }}
      >
        <Menu
          anchorEl={anchorEl}
          id={popupId}
          onClose={close}
          open={isOpen}
          placement="bottom-end"
          sx={{ "--ListItemDecorator-size": "1.625rem" }}
        >
          {options.map((option, index) => (
            <Tooltip key={index} placement="left" title={option.tooltip}>
              <MenuItem
                key={option.label}
                onClick={(event) => onMenuItemClick(event, index)}
              >
                <ListItemDecorator>
                  <FontAwesomeIcon fixedWidth={true} icon={option.icon} />
                </ListItemDecorator>
                {option.label}
              </MenuItem>
            </Tooltip>
          ))}
        </Menu>
      </ClickAwayListener>
    </Fragment>
  );
};

export default memo(DataNodeReprovisionButton);
