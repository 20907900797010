import { useDataConnectorJobQuery } from "@decentriq/graphql/dist/hooks";

type ConnectionTooltipData = {
  bucketName: string | undefined;
  objectName: string | undefined;
};

const useGoogleCloudStorageConnectionConfigurationData = (
  connectionId: string
): ConnectionTooltipData => {
  const { data: { dataConnectorJob } = {} } = useDataConnectorJobQuery({
    skip: !connectionId,
    variables: { id: connectionId },
  });

  const config = dataConnectorJob?.config;

  return {
    bucketName: config?.bucketName,
    objectName: config?.objectName,
  };
};

export default useGoogleCloudStorageConnectionConfigurationData;
