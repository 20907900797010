import {
  DqDatasetImportFormProvider,
  type DqDatasetImportFormProviderSchemaType,
  DqDatasetSyncDrawer,
  type LocalDataSourceType,
} from "@decentriq/components";
import { useCreateDatasetImportMutation } from "@decentriq/graphql/dist/hooks";
import {
  type CreateDatasetImportMutation,
  DataSourceType,
} from "@decentriq/graphql/dist/types";
import { useBoolean } from "@decentriq/hooks";
import { Fragment, memo, useCallback, useEffect, useState } from "react";
import { Wizard } from "react-use-wizard";
import { mapDraftDataRoomErrorToSnackbar, useDataRoomSnackbar } from "hooks";
import {
  CreateImportStep,
  DatasetImportLocalDialog,
  SelectSyncStep,
  ShowStatusStep,
} from "./components";
import { DatasetImportProvider, useDatasetImport } from "./contexts";

interface DatasetImportDialogProps {
  renderTrigger: (open: () => void) => React.ReactNode;
}

const DatasetImportDialog: React.FC<DatasetImportDialogProps> = memo(
  ({ renderTrigger }) => {
    const [isOpen, { setTrue: open, setFalse: close }] = useBoolean(false);
    const { enqueueSnackbar } = useDataRoomSnackbar();
    const { setDatasetImportId, setIsImportCreated, setDatasetImportError } =
      useDatasetImport();
    const [sync, setSync] = useState<
      [DataSourceType | null, LocalDataSourceType | null]
    >([null, null]);
    const [syncType, syncSubtype] = sync || [];
    const [createDatasetImportMutation] = useCreateDatasetImportMutation();
    const onSubmit = useCallback(
      async (
        createDatasetImportMutationVariables: DqDatasetImportFormProviderSchemaType
      ) => {
        await createDatasetImportMutation({
          onCompleted: ({
            createDatasetImport,
          }: CreateDatasetImportMutation) => {
            setDatasetImportId(createDatasetImport?.dataConnectorJob.id);
            setIsImportCreated(true);
          },
          onError: (error) => {
            setDatasetImportError(error?.message);
            enqueueSnackbar(
              ...mapDraftDataRoomErrorToSnackbar(
                error,
                "The importing process could not get started. Please try again by refreshing the page."
              )
            );
          },
          variables: { input: createDatasetImportMutationVariables },
        });
      },
      [
        createDatasetImportMutation,
        setDatasetImportId,
        setIsImportCreated,
        setDatasetImportError,
        enqueueSnackbar,
      ]
    );
    const isLocalDataImport = syncType === DataSourceType.Local;
    const isExternalDataImport = syncType && !isLocalDataImport;
    const isDrawerOpen = Boolean(
      (isOpen && syncType === null) || (isOpen && isExternalDataImport)
    );
    const isModalOpen = Boolean(isOpen && isLocalDataImport);
    useEffect(() => {
      if (!isOpen) {
        setSync([null, null]);
      }
    }, [isOpen]);
    return (
      <Fragment>
        {renderTrigger(open)}
        <DqDatasetImportFormProvider
          syncType={isExternalDataImport ? syncType : undefined}
        >
          <DqDatasetSyncDrawer onClose={close} open={isDrawerOpen}>
            <Wizard key={isOpen.toString()}>
              {/*
                NOTE: This wizard like many others expects children steps to be mounted and won't update state when they change.
                Currently some steps do not support the local data import `syncType` because it is currently handled by a different dialog.
                To avoid TypeScript complaints these steps are conditionally rendered for now.
                Eventually both `Fragment` and ternary will go away.
              */}
              <SelectSyncStep onClose={close} onSelect={setSync} />
              <Fragment>
                {isExternalDataImport ? (
                  <CreateImportStep
                    onClose={close}
                    onSubmit={onSubmit}
                    syncType={syncType}
                  />
                ) : null}
              </Fragment>
              <Fragment>
                {isExternalDataImport ? (
                  <ShowStatusStep onClose={close} syncType={syncType} />
                ) : null}
              </Fragment>
            </Wizard>
          </DqDatasetSyncDrawer>
        </DqDatasetImportFormProvider>
        <DatasetImportLocalDialog
          dataType={isLocalDataImport ? syncSubtype : null}
          onClose={close}
          open={isModalOpen}
        />
      </Fragment>
    );
  }
);
DatasetImportDialog.displayName = "ImportExternalDataDialog";

const WrappedDatasetImportDialog: React.FC<DatasetImportDialogProps> = memo(
  (props) => {
    return (
      <DatasetImportProvider>
        <DatasetImportDialog {...props} />
      </DatasetImportProvider>
    );
  }
);
WrappedDatasetImportDialog.displayName = "WrappedDatasetImportDialog";

export default WrappedDatasetImportDialog;
