import {
  data_connector_job,
  type EnclaveSpecification,
  import_dataset,
} from "@decentriq/core";
import {
  type CreateDatasetImportPayload,
  type DataConnectorJob,
  DataConnectorJobDocument,
  DataConnectorJobFragment,
  type DataConnectorJobQuery,
  type DataConnectorJobQueryVariables,
  type DataConnectorJobResult,
  DataConnectorJobResultFragment,
  DataConnectorJobsDocument,
  type DataConnectorJobsQuery,
  type DataConnectorJobsQueryVariables,
  type DataImportExportStatus,
  type MutationCreateDatasetImportArgs,
  type MutationPollDatasetImportArgs,
  PermutiveServiceProvider,
} from "@decentriq/graphql/dist/types";
import { Key } from "@decentriq/utils";
import * as forge from "node-forge";
import { type Keychain, KeychainItemKind } from "services/keychain";
import { type ApiCoreContextValue } from "contexts";
import { logWarning } from "utils";
import {
  getLatestEnclaveSpecsPerType,
  getLowLevelDependencyNodeId,
  idAsHash,
} from "utils/apicore";
import { type LocalResolverContext } from "wrappers/ApolloWrapper/models";

export const makeCreateDatasetImportResolver =
  (
    client: ApiCoreContextValue["client"],
    sessionManager: ApiCoreContextValue["sessionManager"],
    getKeychain: () => Keychain
  ) =>
  async (
    _obj: null,
    args: MutationCreateDatasetImportArgs,
    context: LocalResolverContext,
    _info: any
  ): Promise<CreateDatasetImportPayload> => {
    const {
      s3,
      gcs,
      compute,
      datasetName,
      snowflake,
      azure,
      salesforce,
      permutive,
    } = args.input;
    const encryptionKey = new Key();

    let enclaveSpecifications;
    let enclaveSpecs: Map<string, EnclaveSpecification>;

    try {
      enclaveSpecifications = await client.getEnclaveSpecifications();
      enclaveSpecs = getLatestEnclaveSpecsPerType(enclaveSpecifications);
    } catch (error) {
      throw new Error(error as string);
    }

    // Permutive connector uploads at least 3 datasets, so, all of their ids should be stored and looped over
    let datasetImportIds = [];

    if (s3 !== undefined) {
      const { credentials, sourceConfig } = s3!;
      const session = await sessionManager.get();
      const datasetImportId = await import_dataset.importDatasetS3(
        client,
        session,
        enclaveSpecs,
        {
          credentials,
          datasetName,
          encryptionKey,
          name: datasetName,
          sourceConfig,
        }
      );
      datasetImportIds.push(datasetImportId);
    } else if (snowflake !== undefined) {
      const { credentials, sourceConfig } = snowflake!;
      const session = await sessionManager.get();
      const datasetImportId = await import_dataset.importDatasetSnowflake(
        client,
        session,
        enclaveSpecs,
        {
          credentials,
          datasetName,
          encryptionKey,
          name: datasetName,
          sourceConfig,
        }
      );
      datasetImportIds.push(datasetImportId);
    } else if (azure !== undefined) {
      const { credentials } = azure!;
      const session = await sessionManager.get();
      const datasetImportId = await import_dataset.importDatasetAzure(
        client,
        session,
        enclaveSpecs,
        {
          credentials,
          datasetName,
          encryptionKey,
          name: datasetName,
        }
      );
      datasetImportIds.push(datasetImportId);
    } else if (gcs !== undefined) {
      const { credentials, bucketName, objectName } = gcs!;
      const session = await sessionManager.get();
      const datasetImportId = await import_dataset.importDatasetGCS(
        client,
        session,
        enclaveSpecs,
        {
          bucketName,
          credentials,
          datasetName,
          encryptionKey,
          name: datasetName,
          objectName,
        }
      );
      datasetImportIds.push(datasetImportId);
    } else if (salesforce !== undefined) {
      const { domainUrl, apiName, productType, credentials } = salesforce!;
      const session = await sessionManager.get();
      const datasetImportId = await import_dataset.importDatasetSalesforce(
        client,
        session,
        enclaveSpecs,
        {
          apiName,
          credentials,
          datasetName,
          domainUrl,
          encryptionKey,
          name: datasetName,
          productType,
        }
      );
      datasetImportIds.push(datasetImportId);
    } else if (permutive !== undefined) {
      const { credentials, datasets, configuration, serviceProvider } =
        permutive!;
      const session = await sessionManager.get();
      if (serviceProvider === PermutiveServiceProvider.S3) {
        const { aws: awsPermutiveConfiguration } = configuration!;
        const { aws: awsPermutiveCredentials } = credentials!;
        const permutiveDatasetsIds = await Promise.all(
          Object.values(datasets).map(async (permutiveDatasetName) => {
            const permutiveDatasetId = await import_dataset.importDatasetS3(
              client,
              session,
              enclaveSpecs,
              {
                credentials: {
                  accessKey: awsPermutiveCredentials?.accessKey!,
                  secretKey: awsPermutiveCredentials?.secretKey!,
                },
                datasetName: permutiveDatasetName!,
                encryptionKey,
                name: permutiveDatasetName!,
                sourceConfig: {
                  bucket: awsPermutiveConfiguration?.bucket!,
                  objectKey: permutiveDatasetName!,
                  region: awsPermutiveConfiguration?.region!,
                },
              }
            );
            return permutiveDatasetId;
          })
        );
        datasetImportIds.push(...permutiveDatasetsIds);
      } else if (
        serviceProvider === PermutiveServiceProvider.GoogleCloudStorage
      ) {
        const { gcs: gcsPermutiveConfiguration } = configuration!;
        const { gcs: gcsPermutiveCredentials } = credentials!;
        const permutiveDatasetsIds = await Promise.all(
          Object.values(datasets).map(async (permutiveDatasetName) => {
            const permutiveDatasetId = await import_dataset.importDatasetGCS(
              client,
              session,
              enclaveSpecs,
              {
                bucketName: gcsPermutiveConfiguration?.bucketName!,
                credentials: gcsPermutiveCredentials?.credentials!,
                datasetName: permutiveDatasetName,
                encryptionKey,
                name: permutiveDatasetName,
                objectName: permutiveDatasetName,
              }
            );
            return permutiveDatasetId;
          })
        );
        datasetImportIds.push(...permutiveDatasetsIds);
      }
    } else if (compute !== undefined) {
      const {
        computeNodeId,
        dataRoomId,
        driverAttestationHash,
        shouldImportAsRaw,
        shouldImportAllFiles,
        importFileWithName,
        renameFileTo,
        isHighLevelNode = true,
        parameters,
      } = compute!;
      const session = await sessionManager.get({ driverAttestationHash });

      let lowLevelComputeNodeId;

      let parameterObject: { [key: string]: string } | undefined;
      if (parameters) {
        parameterObject = Object.fromEntries(
          parameters.map((param) => [param.computeNodeName, param.content])
        );
      }

      if (isHighLevelNode) {
        lowLevelComputeNodeId = await getLowLevelDependencyNodeId(
          session,
          dataRoomId,
          computeNodeId
        );
      } else {
        lowLevelComputeNodeId = computeNodeId;
      }

      const importOptions = {
        computeNodeId: lowLevelComputeNodeId,
        dataRoomId,
        datasetName,
        encryptionKey,
        importFileWithName: importFileWithName || undefined,
        name: datasetName,
        parameters: parameterObject,
        renameFileTo: renameFileTo || undefined,
        shouldImportAllFiles: shouldImportAllFiles || false,
        shouldImportAsRaw: shouldImportAsRaw || false,
      };
      const datasetImportId = await import_dataset.importDatasetCompute(
        client,
        session,
        enclaveSpecs,
        importOptions
      );
      datasetImportIds.push(datasetImportId);
    } else {
      throw new Error("No source type configured");
    }

    let dataConnectorJobs: DataConnectorJob[] = [];

    for (const datasetImportId of datasetImportIds) {
      await getKeychain().insertItem({
        id: datasetImportId,
        kind: KeychainItemKind.PendingDatasetImport,
        value: encryptionKey.toHex(),
      });

      const response = await context.client.query<
        DataConnectorJobQuery,
        DataConnectorJobQueryVariables
      >({
        query: DataConnectorJobDocument,
        variables: {
          id: datasetImportId,
        },
      });
      const newDatasetImport = response.data.dataConnectorJob;
      dataConnectorJobs.push(newDatasetImport);

      const importsQuery = context.cache.readQuery<
        DataConnectorJobsQuery,
        DataConnectorJobsQueryVariables
      >({
        query: DataConnectorJobsDocument,
        variables: { filter: null },
      });

      const currentImports = importsQuery
        ? importsQuery!.dataConnectorJobs?.nodes
        : [];
      context.cache.writeQuery<
        DataConnectorJobsQuery,
        DataConnectorJobsQueryVariables
      >({
        data: {
          dataConnectorJobs: {
            __typename: "DataConnectorJobCollection",
            nodes: [newDatasetImport, ...currentImports],
          },
        },
        query: DataConnectorJobsDocument,
        variables: {
          filter: null,
        },
      });

      context.cache.modify({
        fields: {
          dataConnectorJobs: (existing = {}) => {
            const datasetImportRef = context.cache.writeFragment({
              data: response.data.dataConnectorJob,
              fragment: DataConnectorJobFragment,
            });
            return {
              ...existing,
              nodes: [datasetImportRef, ...(existing?.nodes || [])],
            };
          },
        },
      });
    }

    // TODO: rewrite to datasetImport: { nodes: [...newDatasetImports] } across the app in order to handle multiple Permutive datasets
    return {
      dataConnectorJob: dataConnectorJobs[dataConnectorJobs.length - 1],
    };
  };

export const makePollDatasetImportResolver =
  (
    client: ApiCoreContextValue["client"],
    _sessionManager: ApiCoreContextValue["sessionManager"],
    getKeychain: () => Keychain
  ) =>
  async (
    _obj: null,
    args: MutationPollDatasetImportArgs,
    context: LocalResolverContext,
    _info: any
  ): Promise<DataConnectorJobResult> => {
    const datasetImportId = args.id;

    const enclaveSpecifications = await client.getEnclaveSpecifications();

    let importResult;
    let error;
    let success;

    try {
      importResult = await data_connector_job.getDataConnectorJobResult({
        client,
        dataConnectorJobId: datasetImportId,
        options: {
          additionalEnclaveSpecs: enclaveSpecifications,
        },
      });
      success = true;
    } catch (e) {
      error = e.message;
      success = false;
    }

    // Check the keychain for 'pending dataset import' entries that will contain the
    // key in binary form, with which the dataset was encrypted.
    // If found, insert a new dataset entry into the keychain that maps the manifest
    // hash of the newly created dataset to the encryption key.
    const keychain = getKeychain();
    const keychainItems = await keychain.getItems();
    const matchingPendingImports = keychainItems.filter((item) => {
      return (
        item.kind === KeychainItemKind.PendingDatasetImport &&
        item.id === datasetImportId
      );
    });

    if (matchingPendingImports.length > 0) {
      if (matchingPendingImports.length > 1) {
        logWarning(
          `Multiple pending imports found in keychain for import with id '${datasetImportId}'`
        );
      }

      // Delete matching imports from the keychain so they are not checked again
      // (as a safety measure we delete all of them, although only a single one should exist).
      // This is done regardless of whether the import failed or not.
      await keychain.removeItems(matchingPendingImports);

      // Insert new dataset->key entries in case the import was succesful.
      if (importResult !== undefined) {
        const encryptionKeyBytes = forge.util.binary.hex.decode(
          matchingPendingImports[0].value
        );
        for (const datasetMeta of importResult.datasets) {
          try {
            await keychain.insertItem({
              id: datasetMeta.manifestHash,
              kind: KeychainItemKind.Dataset,
              // The UI stores the encryption key this way
              value: idAsHash(encryptionKeyBytes)!,
            });
          } catch (error) {
            logWarning(
              "Error when trying to insert new dataset->key mapping for finished import: ",
              error
            );
          }
        }
      }
    }

    const result = {
      error: error ? error : null,
      success,
    };

    // Fetch finishedAt value
    const response = await context.client.query<
      DataConnectorJobQuery,
      DataConnectorJobQueryVariables
    >({
      fetchPolicy: "network-only",
      query: DataConnectorJobDocument,
      variables: {
        id: datasetImportId,
      },
    });
    const { finishedAt } = response?.data?.dataConnectorJob || {};

    // Update cached DatasetImport with result and status field
    context.client.writeFragment({
      data: {
        finishedAt,
        id: datasetImportId,
        result,
        status: (result.success
          ? "SUCCESS"
          : "FAILED") as DataImportExportStatus,
      },
      fragment: DataConnectorJobResultFragment,
      id: context.cache.identify({
        __typename: "DataConnectorJob",
        id: datasetImportId,
      }),
    });

    return result;
  };
