import { faTrashCan } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@mui/joy";
import { useBoolean } from "ahooks";
import { memo } from "react";
import { DeactivateDataRoomDialog } from "components";

type DeactivateDataRoomButtonProps = {
  dataRoomId: string;
  closeDataRoomDetailsDialog: () => void;
};

const DeactivateDataRoomButton: React.FC<DeactivateDataRoomButtonProps> = ({
  dataRoomId,
  closeDataRoomDetailsDialog,
}) => {
  const [
    isDeactivateDataRoomDialogOpen,
    {
      setTrue: openDeactivateDataRoomDialog,
      setFalse: closeDeactivateDataRoomDialog,
    },
  ] = useBoolean(false);

  return (
    <>
      <Button
        color="danger"
        onClick={(event) => {
          event.stopPropagation();
          openDeactivateDataRoomDialog();
        }}
        startDecorator={<FontAwesomeIcon fixedWidth={true} icon={faTrashCan} />}
        sx={{ marginTop: 1, width: "fit-content" }}
      >
        Deactivate DCR
      </Button>
      <DeactivateDataRoomDialog
        closeDataRoomDetailsDialog={closeDataRoomDetailsDialog}
        dataRoomId={dataRoomId}
        onCancel={closeDeactivateDataRoomDialog}
        open={isDeactivateDataRoomDialogOpen}
      />
    </>
  );
};

DeactivateDataRoomButton.displayName = "DeactivateDataRoomButton";

export default memo(DeactivateDataRoomButton);
