import {
  type data_science_commit,
  type data_science_data_room,
  type proto,
  type types,
} from "@decentriq/core";
import {
  type CompleteDraftDataRoomQuery,
  type CompletePublishedDataRoomQuery,
  type CompleteSubmittedDataRoomRequestQuery,
  type DraftNode,
  type DraftNodeConnection,
  type DraftTableLeafNodeColumn,
  PublishedDataRoomSource,
  type PublishedNodeConnection,
  type PublishedRawLeafNode,
  type PublishedTableLeafNode,
  type PublishedTableLeafNodeColumn,
  ScriptingLanguage,
  type TableColumnFormatType,
  type TableColumnHashingAlgorithm,
  WorkerTypeShortName,
} from "@decentriq/graphql/dist/types";
import { get_dependencies } from "sql-deps-extraction";
import { v4 as uuidv4 } from "uuid";
import {
  type PublishedComputeNodeTypeNames,
  type PublishedDataNodeTypeNames,
} from "models";
import {
  BuildError,
  dataScienceToPublishedDataType,
  dataScienceToPublishedMaskedColumn,
  dataScienceToPublishedScriptingLanguage,
  draftDataRoomConnectionToDraftNodeId,
  draftDataTypeToHighLevelFormatType,
  draftToDataScienceDataType,
  draftToDataScienceMaskedColumn,
  draftToDataScienceScriptingLanguage,
  draftToHighLevelFormatType,
  draftToHighLevelHashingAlgorithm,
  EnclaveSpecificationManager,
  EnclaveSpecName,
  type NodePermissionsType,
  type PermissionsType,
} from "../shared";
import { toHex } from "..";

type DataRoom = data_science_data_room.DataScienceDataRoomV3;
type Commit = data_science_data_room.DataScienceCommitV3;
type Configuration = data_science_data_room.DataScienceDataRoomConfigurationV3;

function unwrapDataRoom(
  dcr: data_science_data_room.DataScienceDataRoom
): DataRoom {
  if ("v3" in dcr) {
    return dcr.v3;
  } else {
    throw new Error("Cannot get DCR, unsupported version.");
  }
}

function wrapDataRoom(
  dcr: DataRoom
): data_science_data_room.DataScienceDataRoom {
  return {
    v3: dcr,
  };
}

function wrapCommit(commit: Commit): data_science_commit.DataScienceCommit {
  return {
    v3: commit,
  };
}

function unwrapCommit(commit: data_science_commit.DataScienceCommit): Commit {
  if ("v3" in commit) {
    return commit.v3;
  } else {
    throw new Error("Cannot get Commit, unsupported version.");
  }
}

export function rebaseCommit(
  versionedCommit: data_science_commit.DataScienceCommit,
  newHistoryPin: string,
  usedSpecIds: string[]
): void {
  const commit = unwrapCommit(versionedCommit);
  const commitKind = commit.kind;
  commit.id = uuidv4();
  commit.historyPin = newHistoryPin;
  if ("addComputation" in commitKind) {
    const addCommit = commitKind.addComputation;
    addCommit.enclaveSpecifications = addCommit.enclaveSpecifications.filter(
      (spec) => !usedSpecIds.includes(spec.id)
    );
  } else {
    throw new Error("Unsupported commit kind type");
  }
}

export function changeCommitExecutionPermissions(
  versionedCommit: data_science_commit.DataScienceCommit,
  user: string
) {
  const commit = unwrapCommit(versionedCommit);
  const commitKind = commit.kind;
  if ("addComputation" in commitKind) {
    const addCommit = commitKind.addComputation;
    addCommit.analysts = [user];
  } else {
    throw new Error("Unsupported commit kind type");
  }
}

function getConfiguration(
  dcr: data_science_data_room.DataScienceDataRoom
): Configuration {
  const dataScienceDataRoom = unwrapDataRoom(dcr);
  if ("interactive" in dataScienceDataRoom) {
    return dataScienceDataRoom.interactive
      .initialConfiguration as Configuration;
  } else if ("static" in dcr) {
    return dataScienceDataRoom.static;
  } else {
    throw new Error("Cannot get DCR configuration, invalid DCR structure.");
  }
}

function translateDataScienceDataRoomConfiguration(
  configuration: data_science_data_room.DataScienceDataRoomConfigurationV3,
  enableInteractivity: boolean,
  driverAttestationHash: string,
  dcrId: string,
  isStopped: boolean,
  publishedDatasetByNode: Map<string, proto.gcg.IPublishedDataset>
): CompletePublishedDataRoomQuery["publishedDataRoom"] {
  const nodeTypeNamesMap = new Map<
    string,
    {
      __typename: CompletePublishedDataRoomQuery["publishedDataRoom"]["publishedNodes"][number]["__typename"];
      name: string;
    }
  >();
  for (const node of configuration.nodes) {
    nodeTypeNamesMap.set(node.id, {
      __typename: dataScienceToPublishedTypename(node),
      name: node.name,
    });
  }
  const participants: CompletePublishedDataRoomQuery["publishedDataRoom"]["participants"] =
    [];
  let owner = undefined;
  for (const participant of configuration.participants) {
    const permissions: PermissionsType = [];
    for (const permission of participant.permissions) {
      if ("manager" in permission) {
        if (owner) {
          throw new Error("Multiple owners");
        }
        owner = {
          __typename: "User",
          email: participant.user,
        };
      } else if ("analyst" in permission) {
        const analystPermission = permission.analyst;
        permissions.push({
          __typename: "PublishedAnalystPermission",
          node: {
            __typename: nodeTypeNamesMap.get(analystPermission.nodeId)! as any,
            commitId: null,
            dcrHash: dcrId,
            driverAttestationHash,
            id: analystPermission.nodeId,
          },
        });
      } else if ("dataOwner" in permission) {
        const dataOwnerPermission = permission.dataOwner;
        permissions.push({
          __typename: "PublishedDataOwnerPermission",
          node: {
            __typename: nodeTypeNamesMap.get(
              dataOwnerPermission.nodeId
            )! as any,
            commitId: null,
            dcrHash: dcrId,
            driverAttestationHash,
            id: dataOwnerPermission.nodeId,
          },
        });
      } else {
        throw new Error("Unsupported permission");
      }
    }
    participants.push({
      permissions,
      userEmail: participant.user,
    });
  }
  if (!owner) {
    throw new Error("No owner");
  }
  const permissionsMap = new Map<string, NodePermissionsType>();
  for (const participant of participants) {
    for (const permission of participant.permissions) {
      const nodePermissions = permissionsMap.get(permission.node.id);
      const { node, ...rest } = permission;
      const newPermission = rest as NodePermissionsType[number];
      newPermission.participant = participant;
      if (nodePermissions) {
        nodePermissions.push(newPermission);
      } else {
        permissionsMap.set(permission.node.id, [newPermission]);
      }
    }
  }
  type PublishedNodesType =
    CompletePublishedDataRoomQuery["publishedDataRoom"]["publishedNodes"];
  const publishedNodes: PublishedNodesType = configuration.nodes.map((node) => {
    return translateDataScienceNodeToPublishedNode(
      node,
      driverAttestationHash,
      dcrId,
      null,
      nodeTypeNamesMap,
      permissionsMap.get(node.id) || [],
      publishedDatasetByNode
    );
  });
  return {
    description: configuration.description || "",
    driverAttestationHash,
    enableAutomergeFeature: false,
    enableDevelopment: configuration.enableDevelopment,
    enableInteractivity,
    enablePostWorker: configuration.enablePostWorker,
    enableServersideWasmValidation:
      configuration.enableServersideWasmValidation,
    enableSqliteWorker: configuration.enableSqliteWorker,
    enableTestDatasets: configuration.enableTestDatasets,
    id: configuration.id,
    isOwner: true,
    isStopped,
    name: configuration.title,
    owner,
    participants,
    publishedNodes,
    source: PublishedDataRoomSource.Web,
  };
}

function applyInteractiveDataScienceDcrCommit(
  current: data_science_data_room.InteractiveDataScienceDataRoomV3,
  commit: data_science_data_room.DataScienceCommitV2
): data_science_data_room.InteractiveDataScienceDataRoomV3 {
  let currentConfiguration = current.initialConfiguration;
  const commitKind = commit.kind;

  if ("addComputation" in commitKind) {
    const addComputation = commitKind.addComputation;

    // Check whether there are new participants in the data room
    for (const analyst of addComputation.analysts) {
      const participant = currentConfiguration.participants.find(
        (participant) => participant.user === analyst
      );
      const analystPermission = {
        analyst: {
          nodeId: addComputation.node.id,
        },
      };
      if (!participant) {
        currentConfiguration.participants.push({
          permissions: [analystPermission],
          user: analyst,
        });
      } else {
        participant.permissions.push(analystPermission);
      }
    }

    // The commit builder would already have de-duplicated the attestation specs,
    // simply add all new specs to the DCR
    for (const enclaveSpec of addComputation.enclaveSpecifications) {
      currentConfiguration.enclaveSpecifications.push(enclaveSpec);
    }

    currentConfiguration.nodes.push(addComputation.node);
  } else {
    throw new Error(
      "Unsupported kind of commit, cannot apply to data room configuration"
    );
  }

  return current;
}

/** Flatten the given data science DCR in place */
export function flattenInteractiveDataScienceDataRoom(
  dcr: data_science_data_room.DataScienceDataRoom
): data_science_data_room.DataScienceDataRoom {
  const dataScienceDataRoom = unwrapDataRoom(dcr);
  if ("interactive" in dataScienceDataRoom) {
    let interactiveDataRoom = dataScienceDataRoom.interactive;
    for (const commit of interactiveDataRoom.commits) {
      applyInteractiveDataScienceDcrCommit(interactiveDataRoom, commit);
    }
    interactiveDataRoom.commits = [];
  }
  return wrapDataRoom(dataScienceDataRoom);
}

export function translateDataScienceDataRoom(
  dcr: data_science_data_room.DataScienceDataRoom,
  driverAttestationHash: string,
  dcrId: string,
  isStopped: boolean,
  publishedDatasetByNode: Map<string, proto.gcg.IPublishedDataset>
): CompletePublishedDataRoomQuery["publishedDataRoom"] {
  const dataScienceDataRoom = unwrapDataRoom(dcr);
  let publishedDataRoom: CompletePublishedDataRoomQuery["publishedDataRoom"];
  if ("static" in dataScienceDataRoom) {
    publishedDataRoom = translateDataScienceDataRoomConfiguration(
      dataScienceDataRoom.static,
      false,
      driverAttestationHash,
      dcrId,
      isStopped,
      publishedDatasetByNode
    );
  } else if ("interactive" in dataScienceDataRoom) {
    let flattenedInteractiveDcr = unwrapDataRoom(
      flattenInteractiveDataScienceDataRoom(wrapDataRoom(dataScienceDataRoom))
    );
    if ("interactive" in flattenedInteractiveDcr) {
      publishedDataRoom = {
        ...translateDataScienceDataRoomConfiguration(
          flattenedInteractiveDcr.interactive.initialConfiguration,
          true,
          driverAttestationHash,
          dcrId,
          isStopped,
          publishedDatasetByNode
        ),
        enableAutomergeFeature:
          flattenedInteractiveDcr.interactive.enableAutomergeFeature,
      };
    } else {
      throw new Error("Flattening operation returned a non-interactive DCR");
    }
  } else {
    throw new Error("Unsupported configuration");
  }
  return publishedDataRoom;
}

export function translateDataScienceCommit(
  submittedDataRoomRequestId: string,
  dcr: data_science_data_room.DataScienceDataRoom,
  dcrCommit: data_science_commit.DataScienceCommit,
  driverAttestationHash: string,
  dcrId: string,
  commitId: string
): CompleteSubmittedDataRoomRequestQuery["submittedDataRoomRequest"] {
  const commit = unwrapCommit(dcrCommit);
  const configuration = getConfiguration(dcr);
  const nodeTypeNamesMap = new Map<
    string,
    {
      __typename: PublishedComputeNodeTypeNames | PublishedDataNodeTypeNames;
      name: string;
    }
  >(
    configuration.nodes.map((node) => [
      node.id,
      {
        __typename: dataScienceToPublishedTypename(node),
        name: node.name,
      },
    ])
  );
  if ("addComputation" in commit.kind) {
    const addComputation = commit.kind.addComputation;
    const nodePermissions: NodePermissionsType = addComputation.analysts.map(
      (analyst) => ({
        __typename: "PublishedAnalystPermission",
        participant: {
          userEmail: analyst,
        },
      })
    );
    return {
      __typename: "SubmittedDataRoomRequest",
      id: submittedDataRoomRequestId,
      node: translateDataScienceNodeToPublishedNode(
        addComputation.node,
        driverAttestationHash,
        dcrId,
        commitId,
        nodeTypeNamesMap,
        nodePermissions,
        undefined
      ),
    };
  } else {
    throw new Error("Unsupported commit kind");
  }
}

export function buildDataScienceCommit(
  draftNode: DraftNode,
  analysts: Array<string>,
  dataRoomId: string,
  historyPin: string,
  availableSpecs: Map<string, types.EnclaveSpecification>,
  usedSpecs: string[],
  nodeNamesMap: Map<string, string>,
  isDevMode: boolean
): data_science_commit.DataScienceCommit {
  const enclaveSpecificationsManager = new EnclaveSpecificationManager(
    availableSpecs,
    usedSpecs || []
  );
  const node = translateDraftNodeToDataScienceNode(
    draftNode,
    enclaveSpecificationsManager,
    nodeNamesMap,
    isDevMode
  );
  const commit: Commit = {
    enclaveDataRoomId: dataRoomId,
    historyPin,
    id: uuidv4(),
    kind: {
      addComputation: {
        analysts,
        enclaveSpecifications:
          enclaveSpecificationsManager.getAddedEnclaveSpecifications(),
        node,
      },
    },
    name: "",
  };
  return wrapCommit(commit);
}

export function buildDataScienceDataRoom(
  draftDataRoom: CompleteDraftDataRoomQuery["draftDataRoom"],
  rootCertificatePem: Uint8Array,
  availableSpecs: Map<string, types.EnclaveSpecification>,
  isDraftMode: boolean,
  dcrSecretIdBase64?: string
): data_science_data_room.DataScienceDataRoom {
  // setup enclave specification manager
  const enclaveSpecificationsManager = new EnclaveSpecificationManager(
    availableSpecs,
    []
  );
  // translate participants
  if (
    !draftDataRoom.participants.nodes.some(
      (p) =>
        p.userEmail.toLowerCase() === draftDataRoom.owner?.email.toLowerCase()
    )
  ) {
    throw new BuildError(
      `As a DCR Creator, you must also be in the participants list. Please access the PERMISSIONS tab and add yourself as a participant.`
    );
  }
  const participants: Array<data_science_data_room.Participant> = [];
  participants.push({
    permissions: [
      {
        manager: {},
      },
    ],
    user: draftDataRoom.owner!.email,
  });
  for (const participant of draftDataRoom.participants.nodes) {
    const permissions = participant.permissions.nodes.map((permission) => {
      switch (permission.__typename) {
        case "DraftAnalystPermission": {
          return {
            analyst: {
              nodeId: permission.node.id,
            },
          };
        }
        case "DraftDataOwnerPermission": {
          return {
            dataOwner: {
              nodeId: permission.node.id,
            },
          };
        }
        default: {
          throw new BuildError(
            `Unknown permission type '${permission.__typename}' for participant ${participant.userEmail}`
          );
        }
      }
    });
    if (participant.userEmail === draftDataRoom.owner!.email) {
      participants[0].permissions.push(...permissions);
    } else {
      participants.push({
        permissions,
        user: participant.userEmail,
      });
    }
  }
  // translate nodes
  const nodeNamesMap = new Map<string, string>(
    draftDataRoom.draftNodes.nodes.map((node) => {
      return [node.name, node.id];
    })
  );
  let sortedNodes = draftDataRoom.draftNodes.nodes;
  if (
    draftDataRoom.dataNodesOrder.length > 0 ||
    draftDataRoom.computeNodesOrder.length > 0
  ) {
    const nodesOrderMap = new Map();
    draftDataRoom.dataNodesOrder.forEach((id, index) =>
      nodesOrderMap.set(id, index)
    );
    draftDataRoom.computeNodesOrder.forEach((id, index) =>
      nodesOrderMap.set(id, index + draftDataRoom.dataNodesOrder.length)
    );
    sortedNodes = sortedNodes
      .slice()
      .sort(
        (a, b) =>
          (nodesOrderMap.get(a.id) || 0) - (nodesOrderMap.get(b.id) || 0)
      );
  }
  const nodes = sortedNodes.map((node) =>
    translateDraftNodeToDataScienceNode(
      node,
      enclaveSpecificationsManager,
      nodeNamesMap,
      isDraftMode
    )
  );
  const configuration: Configuration = {
    description: draftDataRoom.description,
    enableDevelopment: true,
    enablePostWorker: true,
    enableSafePythonWorkerStacktrace: true,
    enableServersideWasmValidation: true,
    enableSqliteWorker: true,
    enableTestDatasets: true,
    enclaveRootCertificatePem: new TextDecoder().decode(rootCertificatePem),
    enclaveSpecifications:
      enclaveSpecificationsManager.getAddedEnclaveSpecifications(),
    id: uuidv4(),
    nodes,
    participants,
    title: draftDataRoom.title,
  };
  if (dcrSecretIdBase64) {
    configuration.dcrSecretIdBase64 = dcrSecretIdBase64;
  }
  if (draftDataRoom.enableInteractivity) {
    const interactiveDcr: DataRoom = {
      interactive: {
        commits: [],
        enableAutomergeFeature: true,
        enablePostWorker: true,
        enableSafePythonWorkerStacktrace: true,
        enableServersideWasmValidation: true,
        enableSqliteWorker: true,
        enableTestDatasets: true,
        initialConfiguration: configuration,
      },
    };
    return wrapDataRoom(interactiveDcr);
  } else {
    const staticDcr: DataRoom = {
      static: configuration,
    };
    return wrapDataRoom(staticDcr);
  }
}

function translateDraftNodeToDataScienceNode(
  node: CompleteDraftDataRoomQuery["draftDataRoom"]["draftNodes"]["nodes"][number],
  enclaveSpecificationsManager: EnclaveSpecificationManager,
  nodeNamesMap: Map<string, string>,
  isDraftMode: boolean
): data_science_data_room.NodeV2 {
  try {
    switch (node.__typename) {
      case "DraftRawLeafNode": {
        return {
          id: node.id,
          kind: {
            leaf: {
              isRequired: node.isRequired,
              kind: {
                raw: {},
              },
            },
          },
          name: node.name,
        };
      }
      case "DraftTableLeafNode": {
        let sortedColumns = node.columns.nodes;
        if (!sortedColumns.length) {
          throw new Error("No table columns provided");
        }
        if (node.columnsOrder.length > 0) {
          const columnsOrderMap = new Map(
            node.columnsOrder.map((id, index) => {
              return [id, index];
            })
          );
          sortedColumns = sortedColumns
            .slice()
            .sort(
              (a, b) =>
                (columnsOrderMap.get(a.id) || 0) -
                (columnsOrderMap.get(b.id) || 0)
            );
        }

        const driverSpecId =
          enclaveSpecificationsManager.getEnclaveSpecificationId(
            EnclaveSpecName.GCG
          );
        const pythonSpecificationId =
          enclaveSpecificationsManager.getEnclaveSpecificationId(
            EnclaveSpecName.PYTHON
          );
        let uniqueness: data_science_data_room.UniquenessValidationRule | null =
          null;
        if (node.uniqueColumnIds) {
          const columnIndicesById = sortedColumns
            .map((element, index) => ({ element, index }))
            .reduce(
              (accum, curr) => accum.set(curr.element.id, curr),
              new Map()
            );
          uniqueness = {
            uniqueKeys: node.uniqueColumnIds.map((columnIds) => {
              const columnIndices = columnIds.map((id) => {
                const entry = columnIndicesById.get(id);
                if (entry) {
                  return entry.index;
                } else {
                  throw new Error(
                    `Cannot find column with id '${id}' referenced in a set of columns with a unique validation`
                  );
                }
              });
              return { columns: columnIndices };
            }),
          };
        }
        let numRows: null | data_science_data_room.NumRowsValidationRule = null;
        if (node.maxRows != null || node.minRows != null) {
          numRows = {
            atLeast: node.minRows != null ? node.minRows : null,
            atMost: node.maxRows != null ? node.maxRows : null,
          };
        }

        const validationNode = {
          pythonSpecificationId,
          staticContentSpecificationId: driverSpecId,
          validation: {
            allowEmpty: node.allowEmpty,
            numRows,
            uniqueness,
          },
        };

        const hlNode = {
          id: node.id,
          kind: {
            leaf: {
              isRequired: node.isRequired,
              kind: {
                table: {
                  columns: sortedColumns.map(
                    draftDataRoomToDataScienceDataRoomColumn
                  ),
                  validationNode,
                },
              },
            },
          },
          name: node.name,
        };

        return hlNode;
      }
      case "DraftSqlNode": {
        const sqlSpecId =
          enclaveSpecificationsManager.getEnclaveSpecificationId(
            EnclaveSpecName.SQL
          );
        let tableDependencies: Array<string>;
        try {
          tableDependencies = JSON.parse(get_dependencies(node.statement!));
        } catch (err) {
          throw new Error(`Invalid SQL statement, '${err}'`);
        }

        const privacyFilter:
          | data_science_data_room.SqlNodePrivacyFilter
          | undefined = node.privacyFilter?.isEnabled
          ? {
              minimumRowsCount: node.privacyFilter.minimumRows,
            }
          : undefined;
        return {
          id: node.id,
          kind: {
            computation: {
              kind: {
                sql: {
                  dependencies: tableDependencies.map((tableName) => {
                    let nodeId = nodeNamesMap.get(tableName);
                    if (nodeId === undefined) {
                      throw new Error(
                        `Invalid SQL statement table, no matching data source with table name '${tableName}'`
                      );
                    }
                    return {
                      nodeId,
                      tableName,
                    };
                  }),
                  privacyFilter,
                  specificationId: sqlSpecId,
                  statement: node.statement!,
                },
              },
            },
          },
          name: node.name,
        };
      }
      case "DraftSqliteNode": {
        const sqliteSpecId =
          enclaveSpecificationsManager.getEnclaveSpecificationId(
            EnclaveSpecName.SQLITE
          );
        const driverSpecId =
          enclaveSpecificationsManager.getEnclaveSpecificationId(
            EnclaveSpecName.GCG
          );
        let tableDependencies: Array<string>;
        try {
          tableDependencies = JSON.parse(get_dependencies(node.statement!));
        } catch (err) {
          throw new Error(`Invalid SQL statement, '${err}'`);
        }

        return {
          id: node.id,
          kind: {
            computation: {
              kind: {
                sqlite: {
                  dependencies: tableDependencies.map((tableName) => {
                    let nodeId = nodeNamesMap.get(tableName);
                    if (nodeId === undefined) {
                      throw new Error(
                        `Invalid SQL statement table, no matching data source with table name '${tableName}'`
                      );
                    }
                    return {
                      nodeId,
                      tableName,
                    };
                  }),
                  enableLogsOnError: isDraftMode,
                  enableLogsOnSuccess: isDraftMode,
                  privacyFilter: undefined,
                  sqliteSpecificationId: sqliteSpecId,
                  statement: node.statement!,
                  staticContentSpecificationId: driverSpecId,
                },
              },
            },
          },
          name: node.name,
        };
      }
      case "DraftMatchNode": {
        const matchSpecId =
          enclaveSpecificationsManager.getEnclaveSpecificationId(
            EnclaveSpecName.PYTHON
          );
        return {
          id: node.id,
          kind: {
            computation: {
              kind: {
                match: {
                  config: JSON.stringify(node.config),
                  dependencies: node.dependencies.nodes.map((dep) =>
                    draftDataRoomConnectionToDraftNodeId(
                      dep as DraftNodeConnection | PublishedNodeConnection
                    )
                  ),
                  enableLogsOnError: true,
                  enableLogsOnSuccess: true,
                  output: "/output",
                  specificationId: matchSpecId,
                  staticContentSpecificationId: "decentriq.driver",
                },
              },
            },
          },
          name: node.name,
        };
      }
      case "DraftScriptingNode": {
        let scriptingSpecId;
        switch (node.scriptingLanguage) {
          case ScriptingLanguage.Python: {
            scriptingSpecId =
              enclaveSpecificationsManager.getEnclaveSpecificationId(
                EnclaveSpecName.PYTHON
              );
            break;
          }
          case ScriptingLanguage.R: {
            scriptingSpecId =
              enclaveSpecificationsManager.getEnclaveSpecificationId(
                EnclaveSpecName.R
              );
            break;
          }
          default: {
            throw new Error(
              `Unsupported scripting language '${node.scriptingLanguage}'`
            );
          }
        }
        const staticContentSpecId =
          enclaveSpecificationsManager.getEnclaveSpecificationId(
            EnclaveSpecName.GCG
          );
        const mainScript = node.scripts.nodes.find(
          (script) => script.isMainScript
        );
        if (mainScript === undefined) {
          throw new Error("No main script found");
        }
        if (mainScript.name === undefined || mainScript.name === null) {
          throw new Error("Main script name is undefined");
        }
        if (!mainScript.content) {
          throw new Error(`Main script '${mainScript.name}' is empty`);
        }
        const additionalScripts = node.scripts.nodes
          .filter((script) => !script.isMainScript)
          .map((script) => {
            if (script.name === undefined || script.name === null) {
              throw new Error("Script name is undefined");
            }
            if (!script.content) {
              throw new Error(`Script '${script.name}' is empty`);
            }
            return {
              content: script.content,
              name: script.name,
            };
          });
        if (!node.output) {
          throw new Error("Output location is empty");
        }
        return {
          id: node.id,
          kind: {
            computation: {
              kind: {
                scripting: {
                  additionalScripts: additionalScripts,
                  dependencies: node.dependencies.nodes.map((dep) =>
                    draftDataRoomConnectionToDraftNodeId(
                      dep as DraftNodeConnection | PublishedNodeConnection
                    )
                  ),
                  enableLogsOnError: isDraftMode,
                  enableLogsOnSuccess: isDraftMode,
                  mainScript: {
                    content: mainScript.content,
                    name: mainScript.name,
                  },
                  output: node.output,
                  scriptingLanguage: draftToDataScienceScriptingLanguage(
                    node.scriptingLanguage
                  ),
                  scriptingSpecificationId: scriptingSpecId,
                  staticContentSpecificationId: staticContentSpecId,
                },
              },
            },
          },
          name: node.name,
        };
      }
      case "DraftS3SinkNode": {
        const s3SpecId = enclaveSpecificationsManager.getEnclaveSpecificationId(
          EnclaveSpecName.S3
        );
        if (!node.endpoint) {
          throw new Error("Empty endpoint");
        }
        if (!node.region) {
          throw new Error("Empty region");
        }
        if (!node.credentialsDependency) {
          throw new Error("No credentials dependecy provided");
        }
        if (!node.uploadDependency) {
          throw new Error("No upload dependecy provided");
        }
        return {
          id: node.id,
          kind: {
            computation: {
              kind: {
                s3Sink: {
                  credentialsDependencyId: draftDataRoomConnectionToDraftNodeId(
                    node.credentialsDependency as
                      | DraftNodeConnection
                      | PublishedNodeConnection
                  ),
                  endpoint: node.endpoint,
                  region: node.region,
                  specificationId: s3SpecId,
                  uploadDependencyId: draftDataRoomConnectionToDraftNodeId(
                    node.uploadDependency as
                      | DraftNodeConnection
                      | PublishedNodeConnection
                  ),
                },
              },
            },
          },
          name: node.name,
        };
      }
      case "DraftSyntheticNode": {
        const syntheticSpecId =
          enclaveSpecificationsManager.getEnclaveSpecificationId(
            EnclaveSpecName.SYNTHETIC_DATA
          );
        const staticContentSpecId =
          enclaveSpecificationsManager.getEnclaveSpecificationId(
            EnclaveSpecName.GCG
          );
        if (!node.dependency) {
          throw new Error("Dependency not selected");
        }
        return {
          id: node.id,
          kind: {
            computation: {
              kind: {
                syntheticData: {
                  columns: node.columns.nodes.map(
                    draftToDataScienceMaskedColumn
                  ),
                  dependency: draftDataRoomConnectionToDraftNodeId(
                    node.dependency as
                      | DraftNodeConnection
                      | PublishedNodeConnection
                  ),
                  enableLogsOnError: isDraftMode,
                  enableLogsOnSuccess: isDraftMode,
                  epsilon: node.accuracy,
                  outputOriginalDataStatistics: node.includeReportWithStats,
                  staticContentSpecificationId: staticContentSpecId,
                  synthSpecificationId: syntheticSpecId,
                },
              },
            },
          },
          name: node.name,
        };
      }
      case "DraftPostNode": {
        const postSpecId =
          enclaveSpecificationsManager.getEnclaveSpecificationId(
            EnclaveSpecName.POST
          );
        if (!node.dependency) {
          throw new Error("Dependency not selected");
        }
        return {
          id: node.id,
          kind: {
            computation: {
              kind: {
                post: {
                  dependency: draftDataRoomConnectionToDraftNodeId(
                    node.dependency as
                      | DraftNodeConnection
                      | PublishedNodeConnection
                  ),
                  specificationId: postSpecId,
                  useMockBackend: false,
                },
              },
            },
          },
          name: node.name,
        };
      }
      default: {
        throw new Error(`Unknown node type ${node.__typename}`);
      }
    }
  } catch (err) {
    const message = err instanceof Error ? err.message : String(err);
    throw new BuildError(message, {
      nodeId: node.id,
      nodeName: node?.name,
    });
  }
}

function draftDataRoomToDataScienceDataRoomColumn(
  column: DraftTableLeafNodeColumn
): data_science_data_room.TableLeafNodeColumnV2 {
  return {
    dataFormat: {
      dataType: draftToDataScienceDataType(column.dataType),
      isNullable: column.isNullable,
    },
    name: column.name,
    validation: {
      allowNull: column.isNullable,
      formatType: column.formatType
        ? draftToHighLevelFormatType(column.formatType)
        : draftDataTypeToHighLevelFormatType(column.dataType),
      hashWith: column.hashWith
        ? draftToHighLevelHashingAlgorithm(column.hashWith)
        : undefined,
      name: column.name,
    },
  };
}

function translateDataScienceNodeToPublishedNode(
  node: data_science_data_room.NodeV2,
  driverAttestationHash: string,
  dcrId: string,
  commitId: string | null,
  nodeTypeNamesMap: Map<
    string,
    {
      name: string;
      __typename: PublishedComputeNodeTypeNames | PublishedDataNodeTypeNames;
    }
  >,
  nodePermissions: NodePermissionsType,
  publishedDatasetByNode: Map<string, proto.gcg.IPublishedDataset> | undefined
): CompletePublishedDataRoomQuery["publishedDataRoom"]["publishedNodes"][number] {
  const nodeKind = node.kind;
  if ("leaf" in nodeKind) {
    const leafNodeRequired = nodeKind.leaf.isRequired;
    const leafNodeKind = nodeKind.leaf.kind;
    let dataset;
    if (publishedDatasetByNode) {
      const publishedDataset =
        publishedDatasetByNode && publishedDatasetByNode.get(node.id);
      if (publishedDataset) {
        dataset = {
          datasetHash: toHex(publishedDataset.datasetHash!),
          leafId: publishedDataset.leafId!,
          timestamp: publishedDataset.timestamp,
          user: publishedDataset.user!,
        };
      }
    }

    if ("table" in leafNodeKind) {
      const tableNode = leafNodeKind.table;

      const tableValidationSettings = tableNode.validationNode.validation;

      let publishedNode: PublishedTableLeafNode = {
        __typename: "PublishedTableLeafNode",
        allowEmpty: tableValidationSettings?.allowEmpty || false,
        columns: tableNode.columns.map(
          dataScienceDataRoomToPublishedDataRoomColumn
        ),
        commitId,
        dataRoomId: undefined,
        dataset: dataset !== undefined ? dataset : null,
        dcrHash: dcrId,
        driverAttestationHash,
        id: node.id,
        isRequired: leafNodeRequired,
        maxRows:
          tableValidationSettings?.numRows?.atMost != null
            ? tableValidationSettings.numRows.atMost
            : null,
        minRows:
          tableValidationSettings?.numRows?.atLeast != null
            ? tableValidationSettings.numRows.atLeast
            : null,
        name: node.name,
        permissions: nodePermissions,
        testDataset: null,
        uniqueColumnIds: getUniqueColumnNamesIfSpecified(tableNode),
      };

      return publishedNode;
    } else if ("raw" in leafNodeKind) {
      const rawNode: PublishedRawLeafNode = {
        __typename: "PublishedRawLeafNode",
        commitId,
        dataRoomId: undefined,
        dataset: dataset !== undefined ? dataset : null,
        dcrHash: dcrId,
        driverAttestationHash,
        id: node.id,
        isRequired: leafNodeRequired,
        name: node.name,
        permissions: nodePermissions,
        testDataset: null,
      };
      return rawNode;
    } else {
      throw new Error("Unknown leaf node kind");
    }
  } else if ("computation" in nodeKind) {
    const computationNodeKind = nodeKind.computation.kind;
    if ("sql" in computationNodeKind) {
      const sqlNode = computationNodeKind.sql;
      let privacyFilter;
      if (sqlNode.privacyFilter) {
        privacyFilter = {
          isEnabled: true,
          minimumRows: sqlNode.privacyFilter.minimumRowsCount,
        };
      } else {
        privacyFilter = {
          isEnabled: false,
          minimumRows: 0,
        };
      }
      return {
        __typename: "PublishedSqlNode",
        commitId,
        computationType: WorkerTypeShortName.Sql,
        dataRoomId: undefined,
        dcrHash: dcrId,
        dependencies: [],
        driverAttestationHash,
        id: node.id,
        name: node.name,
        permissions: nodePermissions,
        privacyFilter,
        statement: sqlNode.statement,
      };
    } else if ("sqlite" in computationNodeKind) {
      const sqliteNode = computationNodeKind.sqlite;
      return {
        __typename: "PublishedSqliteNode",
        commitId,
        computationType: WorkerTypeShortName.Sqlite,
        dataRoomId: undefined,
        dcrHash: dcrId,
        dependencies: [],
        driverAttestationHash,
        id: node.id,
        name: node.name,
        permissions: nodePermissions,
        privacyFilter: {
          isEnabled: false,
          minimumRows: 0,
        },
        statement: sqliteNode.statement,
      };
    } else if ("match" in computationNodeKind) {
      const matchNode = computationNodeKind.match;
      return {
        __typename: "PublishedMatchNode",
        commitId,
        computationType: WorkerTypeShortName.Match,
        config: matchNode.config ? JSON.parse(matchNode.config) : null,
        dataRoomId: undefined,
        dcrHash: dcrId,
        dependencies: [],
        driverAttestationHash,
        id: node.id,
        name: node.name,
        permissions: nodePermissions,
      };
    } else if ("scripting" in computationNodeKind) {
      const scriptingNode = computationNodeKind.scripting;
      const scripts = [
        {
          content: scriptingNode.mainScript.content,
          isMainScript: true,
          name: scriptingNode.mainScript.name,
        },
        ...scriptingNode.additionalScripts.map(
          (script: data_science_data_room.Script) => ({
            content: script.content,
            isMainScript: false,
            name: script.name,
          })
        ),
      ];
      const scriptingLanguage = dataScienceToPublishedScriptingLanguage(
        scriptingNode.scriptingLanguage
      );
      return {
        __typename: "PublishedScriptingNode",
        commitId,
        computationType:
          scriptingLanguage === ScriptingLanguage.Python
            ? WorkerTypeShortName.Python
            : WorkerTypeShortName.R,
        dataRoomId: undefined,
        dcrHash: dcrId,
        dependencies: scriptingNode.dependencies.map((node_id: string) => ({
          __typename: nodeTypeNamesMap.get(node_id)!.__typename,
          commitId,
          dcrHash: dcrId,
          driverAttestationHash,
          id: node_id,
          name: nodeTypeNamesMap.get(node_id)!.name,
        })),
        driverAttestationHash,
        id: node.id,
        name: node.name,
        output: scriptingNode.output,
        permissions: nodePermissions,
        scriptingLanguage,
        scripts,
      };
    } else if ("s3Sink" in computationNodeKind) {
      const s3SinkNode = computationNodeKind.s3Sink;
      return {
        __typename: "PublishedS3SinkNode",
        commitId,
        computationType: WorkerTypeShortName.S3,
        credentialsDependency: {
          __typename: nodeTypeNamesMap.get(s3SinkNode.credentialsDependencyId)!
            .__typename,
          commitId,
          dcrHash: dcrId,
          driverAttestationHash,
          id: s3SinkNode.credentialsDependencyId,
          name: nodeTypeNamesMap.get(s3SinkNode.credentialsDependencyId)!.name,
        },
        dataRoomId: undefined,
        dcrHash: dcrId,
        driverAttestationHash,
        endpoint: s3SinkNode.endpoint,
        id: node.id,
        name: node.name,
        permissions: nodePermissions,
        region: s3SinkNode.region,
        uploadDependency: {
          __typename: nodeTypeNamesMap.get(s3SinkNode.uploadDependencyId)!
            .__typename,
          commitId,
          dcrHash: dcrId,
          driverAttestationHash,
          id: s3SinkNode.uploadDependencyId,
          name: nodeTypeNamesMap.get(s3SinkNode.uploadDependencyId)!.name,
        },
      };
    } else if ("syntheticData" in computationNodeKind) {
      const syntheticDataNode = computationNodeKind.syntheticData;
      return {
        __typename: "PublishedSyntheticNode",
        accuracy: syntheticDataNode.epsilon,
        columns: syntheticDataNode.columns.map(
          dataScienceToPublishedMaskedColumn
        ),
        commitId,
        computationType: WorkerTypeShortName.Synthetic,
        dataRoomId: undefined,
        dcrHash: dcrId,
        dependency: {
          __typename: nodeTypeNamesMap.get(syntheticDataNode.dependency)!
            .__typename,
          commitId,
          dcrHash: dcrId,
          driverAttestationHash,
          id: syntheticDataNode.dependency,
          name: nodeTypeNamesMap.get(syntheticDataNode.dependency)!.name,
        },
        driverAttestationHash,
        id: node.id,
        includeReportWithStats: syntheticDataNode.outputOriginalDataStatistics,
        name: node.name,
        permissions: nodePermissions,
      };
    } else if ("post" in computationNodeKind) {
      const postNode = computationNodeKind.post;
      return {
        __typename: "PublishedPostNode",
        commitId,
        computationType: WorkerTypeShortName.Post,
        dataRoomId: undefined,
        dcrHash: dcrId,
        dependency: {
          __typename: nodeTypeNamesMap.get(postNode.dependency)!.__typename,
          commitId,
          dcrHash: dcrId,
          driverAttestationHash,
          id: postNode.dependency,
          name: nodeTypeNamesMap.get(postNode.dependency)!.name,
        },
        driverAttestationHash,
        id: node.id,
        name: node.name,
        permissions: nodePermissions,
      };
    } else {
      throw new Error(
        `Unknown computation node kind:\n${JSON.stringify(
          computationNodeKind,
          null,
          2
        )}`
      );
    }
  } else {
    throw new Error("Unknown node kind");
  }
}

function dataScienceDataRoomToPublishedDataRoomColumn(
  column: data_science_data_room.TableLeafNodeColumnV2
): PublishedTableLeafNodeColumn {
  const validation = column.validation;

  return {
    __typename: "PublishedTableLeafNodeColumn",
    dataType: dataScienceToPublishedDataType(column.dataFormat.dataType),
    formatType:
      validation.formatType != null
        ? (validation.formatType as TableColumnFormatType)
        : null,
    hashWith:
      validation.hashWith != null
        ? (validation.hashWith as TableColumnHashingAlgorithm)
        : null,
    isNullable: column.dataFormat.isNullable,
    name: column.name,
  };
}

function getUniqueColumnNamesIfSpecified(
  tableNode: data_science_data_room.TableLeafNodeV2
): string[][] | null {
  const tableValidationSettings = tableNode.validationNode.validation;
  if (tableValidationSettings && tableValidationSettings.uniqueness) {
    const columnNameTuples = tableValidationSettings.uniqueness.uniqueKeys.map(
      (keys: data_science_data_room.ColumnTuple) => {
        return keys.columns.map((index: number) => {
          return tableNode.columns[index].name;
        });
      }
    );
    return columnNameTuples;
  }
  return null;
}

function dataScienceToPublishedTypename(
  node: data_science_data_room.NodeV2
): CompletePublishedDataRoomQuery["publishedDataRoom"]["publishedNodes"][number]["__typename"] {
  const nodeKind = node.kind;
  if ("leaf" in nodeKind) {
    const leafNodeKind = nodeKind.leaf.kind;
    if ("table" in leafNodeKind) {
      return "PublishedTableLeafNode";
    } else if ("raw" in leafNodeKind) {
      return "PublishedRawLeafNode";
    } else {
      throw new Error("Unknown leaf node kind");
    }
  } else if ("computation" in nodeKind) {
    const computationNodeKind = nodeKind.computation.kind;
    if ("sql" in computationNodeKind) {
      return "PublishedSqlNode";
    } else if ("sqlite" in computationNodeKind) {
      return "PublishedSqliteNode";
    } else if ("match" in computationNodeKind) {
      return "PublishedMatchNode";
    } else if ("scripting" in computationNodeKind) {
      return "PublishedScriptingNode";
    } else if ("s3Sink" in computationNodeKind) {
      return "PublishedS3SinkNode";
    } else if ("syntheticData" in computationNodeKind) {
      return "PublishedSyntheticNode";
    } else if ("post" in computationNodeKind) {
      return "PublishedPostNode";
    } else {
      throw new Error(
        `Unknown computation node kind:\n${JSON.stringify(
          computationNodeKind,
          null,
          2
        )}`
      );
    }
  } else {
    throw new Error("Unknown node kind");
  }
}
