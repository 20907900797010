import { memo } from "react";
import CreationWizardConfigurationWrapper, {
  type CreationWizardConfigurationWrapperProps,
} from "./CreationWizardConfigurationContext";
import CreationWizardDataPartnerWrapper from "./CreationWizardDataPartnerContext";
import CreationWizardPublisherWrapper from "./CreationWizardPublisherContext";
import CreationWizardStepperWrapper from "./CreationWIzardStepperContext";

export interface MediaInsightsDcrCreateWizardWrapperProps
  extends CreationWizardConfigurationWrapperProps {
  restartCreation: () => void;
}

const MediaInsightsDcrCreateWizardWrapper =
  memo<MediaInsightsDcrCreateWizardWrapperProps>(
    ({ children, restartCreation, ...configurationWrapperProps }) => {
      return (
        <CreationWizardStepperWrapper restartCreation={restartCreation}>
          <CreationWizardPublisherWrapper>
            <CreationWizardDataPartnerWrapper>
              <CreationWizardConfigurationWrapper
                {...configurationWrapperProps}
              >
                {children}
              </CreationWizardConfigurationWrapper>
            </CreationWizardDataPartnerWrapper>
          </CreationWizardPublisherWrapper>
        </CreationWizardStepperWrapper>
      );
    }
  );

MediaInsightsDcrCreateWizardWrapper.displayName =
  "MediaInsightsDcrCreateWizardWrapper";

export default MediaInsightsDcrCreateWizardWrapper;
