import { useCallback } from "react";
import { useEffectiveSearchParams } from "contexts";

const useDataRoomTestingNavigationState = () => {
  const { searchParams, setSearchParams } = useEffectiveSearchParams();
  const testing = searchParams.get("test_mode") === "on";
  const handleSetTesting = useCallback(
    (testing: boolean) => {
      setSearchParams({
        test_mode: testing ? "on" : null,
      });
    },
    [setSearchParams]
  );
  return { setTesting: handleSetTesting, testing: Boolean(testing) };
};

export default useDataRoomTestingNavigationState;
