import { DqLoader } from "@decentriq/components";
import { faBan, faTriangleExclamation } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert, Button, Typography } from "@mui/joy";
import { useKeyPress } from "ahooks";
import { saveAs } from "file-saver";
import { useSnackbar } from "notistack";
import { memo, useCallback, useState } from "react";
import {
  EmptyStateContainer,
  MediaDataRoomInsightsTabs,
} from "features/mediaDataRoom/components";
import {
  useMediaDataRoom,
  useMediaDataRoomInsightsData,
} from "features/mediaDataRoom/contexts";

const EmptyStateDisplay: React.FC<{ label: string }> = ({ label }) => {
  return (
    <EmptyStateContainer>
      <DqLoader label={label} />
    </EmptyStateContainer>
  );
};

const MediaDataRoomInsights: React.FC = () => {
  const { isDeactivated, isPublisher } = useMediaDataRoom();
  const { enqueueSnackbar } = useSnackbar();
  const {
    publishedDatasetsHashes: { hasRequiredData },
    datasets: { error: fetchingError, isLoading: datasetsLoading },
    isPublisherAudienceBeingUpdated,
    insights: {
      retry,
      loading: insightsLoading,
      error: insightsFetchingError,
      status: insightsFetchingStatus,
      computeResults,
    },
    overlapStatistics: { computeResults: { isEmpty: isEmptyStatistics } = {} },
  } = useMediaDataRoomInsightsData();

  const [retryInProgress, setRetryInProgress] = useState(false);

  const retryComputations = useCallback(async () => {
    if (isDeactivated) return;
    setRetryInProgress(true);
    try {
      retry();
    } finally {
      setRetryInProgress(false);
    }
  }, [isDeactivated, retry]);

  // Download segments.json file on Cmd+Shift+S click
  useKeyPress(["meta.shift.s"], async () => {
    if (
      insightsLoading ||
      datasetsLoading ||
      isPublisherAudienceBeingUpdated ||
      !hasRequiredData ||
      isDeactivated ||
      insightsFetchingError ||
      fetchingError
    ) {
      return;
    }
    const segmentsJSON = JSON.parse(
      (await computeResults?.segmentsRawFile.async("string")) ?? ""
    );
    try {
      // The file could be parsed to blob straight away in the .async method
      // However, this approach is applied in order to prettify JSON so
      // it looks like JSON rather then plain string
      const segmentsBlob = new Blob([JSON.stringify(segmentsJSON, null, 2)], {
        type: "application/json",
      });
      saveAs(segmentsBlob, "segments.json");
      enqueueSnackbar("segments.json file is being downloaded");
    } catch {
      enqueueSnackbar("Cannot download segments.json file", {
        variant: "error",
      });
    }
  });

  if (isDeactivated || insightsFetchingError || fetchingError) {
    return (
      <EmptyStateContainer gap={2}>
        <FontAwesomeIcon
          fixedWidth={true}
          icon={faTriangleExclamation}
          size="2x"
        />
        <Typography>Insights could not be retrieved</Typography>
        {!isDeactivated && (
          <Button
            loading={retryInProgress}
            onClick={retryComputations}
            variant="plain"
          >
            Retry
          </Button>
        )}
      </EmptyStateContainer>
    );
  }

  if (datasetsLoading) {
    return <EmptyStateDisplay label="Connecting to the enclave" />;
  }

  if (isPublisherAudienceBeingUpdated) {
    return (
      <EmptyStateContainer>
        <FontAwesomeIcon fixedWidth={true} icon={faBan} size="2x" />
        <Typography level="body-sm" mt={1}>
          Publisher audience is being updated.
        </Typography>
        <Typography level="body-sm">
          {isPublisher
            ? "Please provision a datalab again."
            : "Activation will be available again soon."}{" "}
        </Typography>
      </EmptyStateContainer>
    );
  }

  if (!hasRequiredData) {
    return <Alert>Waiting for both parties to provision data.</Alert>;
  }

  if (insightsLoading) {
    return (
      <EmptyStateDisplay
        label={
          insightsFetchingStatus === "COMPUTING"
            ? "Computing..."
            : insightsFetchingStatus === "FETCHING"
              ? "Retrieving insights..."
              : ""
        }
      />
    );
  }

  if (isEmptyStatistics) {
    return (
      <EmptyStateContainer>
        <FontAwesomeIcon fixedWidth={true} icon={faBan} size="2x" />
        <Typography level="body-sm" mt={1}>
          There are not enough individuals in the overlap.
        </Typography>
        <Typography level="body-sm">
          Please check if the Matching ID is compatible.
        </Typography>
      </EmptyStateContainer>
    );
  }
  return <MediaDataRoomInsightsTabs />;
};

MediaDataRoomInsights.displayName = "MediaDataRoomInsights";

export default memo(MediaDataRoomInsights);
