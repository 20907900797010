import React, {
  createContext,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { ClientSideKeychain, UserKeychainStatus } from "services/keychain";
import { Loading } from "components";
import { useKeychain } from "contexts";
import {
  ResetKeychainPassword,
  SetupKeychain,
  UnlockKeychain,
} from "features/keychain";
import {
  CommonSnackbarOrigin,
  mapErrorToGeneralSnackbar,
  useGeneralSnackbar,
  useSafeContext,
} from "hooks";
import { getEffectiveErrorMessage } from "utils";
import KeychainError from "./KeychainError";

export enum KeychainStatus {
  initial,
  loading,
  unset,
  locked,
  unlocked,
  error,
  unknown,
}

export interface KeychainSetupContextValue {
  status: KeychainStatus;
  errorMessage: string | undefined;
  checkKeychainStatus: () => void;
  unlockKeychain: (password: string) => Promise<void>;
  createKeychain: (password: string) => Promise<void>;
  resetKeychain: () => Promise<void>;
  emptyKeychain: (password: string) => Promise<boolean>;
  changeKeychainPassword: (
    oldPassword: string,
    newPassword: string
  ) => Promise<boolean>;
}

export const defaultKeychainSetupContextValue: KeychainSetupContextValue = {
  changeKeychainPassword: async () => false,
  checkKeychainStatus: () => {},
  createKeychain: async () => {},
  emptyKeychain: async () => false,
  errorMessage: undefined,
  resetKeychain: async () => {},
  status: KeychainStatus.initial,
  unlockKeychain: async () => {},
};

const KeychainSetupContext = createContext<KeychainSetupContextValue | null>(
  null
);

KeychainSetupContext.displayName = "KeychainSetupContext";

export const KeychainSetupProvider = KeychainSetupContext.Provider;

export const useKeychainSetup = () => useSafeContext(KeychainSetupContext);

const useClientSideKeychain = () => {
  const keychain = useKeychain();
  if (keychain instanceof ClientSideKeychain) {
    return keychain;
  }
  throw new Error("Keychain is not an instance of ClientSideKeychain.");
};

const userKeychainSetupStatusToKeychainStatus: Record<
  UserKeychainStatus,
  KeychainStatus
> = {
  [UserKeychainStatus.Unset]: KeychainStatus.unset,
  [UserKeychainStatus.Locked]: KeychainStatus.locked,
  [UserKeychainStatus.Unlocked]: KeychainStatus.unlocked,
};

const KeychainSetupWrapper: React.FC<React.PropsWithChildren> = memo(
  ({ children }) => {
    const { enqueueSnackbar } = useGeneralSnackbar({
      origin: CommonSnackbarOrigin.KEYCHAIN,
    });
    const [status, setStatus] = useState<KeychainStatus>(
      KeychainStatus.initial
    );
    const [errorMessage, setErrorMessage] = useState<string | undefined>();
    const keychain = useClientSideKeychain();
    const checkKeychainStatus = useCallback(async () => {
      setStatus(KeychainStatus.loading);
      try {
        const keychainStatus = await keychain.statusOrInitilize();
        setStatus(
          (keychainStatus &&
            userKeychainSetupStatusToKeychainStatus[keychainStatus]) ??
            KeychainStatus.unknown
        );
      } catch (error) {
        setErrorMessage(
          getEffectiveErrorMessage(error, "Something went wrong.")
        );
        setStatus(KeychainStatus.error);
      }
    }, [keychain]);

    const unlockKeychain = useCallback(
      async (password: string) => {
        try {
          const payload = await keychain.unlock(password);
          if (payload && !payload.error) {
            setStatus(KeychainStatus.unlocked);
          } else {
            throw new Error(payload?.error?.message);
          }
        } catch (error) {
          enqueueSnackbar(
            ...mapErrorToGeneralSnackbar(
              error,
              `Keychain could not be unlocked.`
            )
          );
        }
      },
      [keychain, enqueueSnackbar]
    );

    const createKeychain = useCallback(
      async (password: string) => {
        try {
          await keychain.create(password);
          setStatus(KeychainStatus.unlocked);
        } catch (error) {
          enqueueSnackbar(
            ...mapErrorToGeneralSnackbar(
              error,
              `Keychain setup could not be completed.`
            )
          );
        }
      },
      [keychain, enqueueSnackbar]
    );

    const resetKeychain = useCallback(async () => {
      try {
        await keychain.reset();
        setStatus(KeychainStatus.unset);
      } catch (error) {
        enqueueSnackbar(
          ...mapErrorToGeneralSnackbar(
            error,
            `Keychain password could not be reset.`
          )
        );
      }
    }, [keychain, enqueueSnackbar]);

    const emptyKeychain = useCallback(
      async (password: string) => {
        try {
          await keychain.empty(password);
          enqueueSnackbar(`Keychain has been successfully emptied.`);
          return true;
        } catch (error) {
          enqueueSnackbar(
            ...mapErrorToGeneralSnackbar(
              error,
              `Keychain could not be emptied.`
            )
          );
          return false;
        }
      },
      [keychain, enqueueSnackbar]
    );

    const changeKeychainPassword = useCallback(
      async (oldPassword: string, newPassword: string) => {
        try {
          await keychain.changePassword(oldPassword, newPassword);
          enqueueSnackbar(`Password has been successfully changed.`);
          return true;
        } catch (error) {
          enqueueSnackbar(
            ...mapErrorToGeneralSnackbar(
              error,
              `Keychain password could not be changed.`
            )
          );
          return false;
        }
      },
      [keychain, enqueueSnackbar]
    );

    const value = useMemo(
      () => ({
        changeKeychainPassword,
        checkKeychainStatus,
        createKeychain,
        emptyKeychain,
        errorMessage,
        resetKeychain,
        status,
        unlockKeychain,
      }),
      [
        status,
        errorMessage,
        checkKeychainStatus,
        unlockKeychain,
        emptyKeychain,
        changeKeychainPassword,
        createKeychain,
        resetKeychain,
      ]
    );

    return (
      <KeychainSetupProvider value={value}>{children}</KeychainSetupProvider>
    );
  }
);

export default KeychainSetupWrapper;

export const withKeychain = <P extends object>(
  Component: React.ComponentType<P>
): React.FC<P> => {
  return function WithKeychain(props: P): JSX.Element {
    const [isForgotPasswordOpened, setIsForgotPasswordOpened] =
      useState<boolean>(false);
    const { status, checkKeychainStatus, errorMessage } = useKeychainSetup();
    const isInitial = status === KeychainStatus.initial;
    const isUnset = status === KeychainStatus.unset;
    const isLocked = status === KeychainStatus.locked;
    const isUnlocked = status === KeychainStatus.unlocked;
    const isError = status === KeychainStatus.error;
    useEffect(() => {
      if (!isInitial) {
        return;
      }
      checkKeychainStatus();
    }, [isInitial, checkKeychainStatus]);
    if (isUnlocked) {
      return <Component {...props} />;
    }
    if (isUnset) {
      return <SetupKeychain />;
    }
    if (isLocked) {
      return (
        <>
          <UnlockKeychain
            onForgotPassword={() => setIsForgotPasswordOpened(true)}
            open={!isForgotPasswordOpened}
          />
          <ResetKeychainPassword
            onCancel={() => setIsForgotPasswordOpened(false)}
            open={isForgotPasswordOpened}
          />
        </>
      );
    }
    if (isError) {
      return <KeychainError message={errorMessage} />;
    }
    return <Loading />;
  };
};
