import { memo, useMemo } from "react";
import {
  type DataRoomErrorReportContextValue,
  DataRoomErrorReportProvider,
} from "contexts";
import { DraftDataRoomActions, PublishedDataRoomActions } from "features";
import { MediaDataRoomActions as MediaInsightsDcrActions } from "features/MediaInsightsDcr";
import { DataRoomType, DataRoomTypeNames } from "models";

interface DataRoomActionsProps {
  __typename: string;
  id: string;
  inline?: boolean;
  actions: any;
}

const dataRoomTypenameToDataRoomType = new Map<DataRoomTypeNames, DataRoomType>(
  [
    [DataRoomTypeNames.PublishedDataRoom, DataRoomType.DataScience],
    [
      DataRoomTypeNames.PublishedLookalikeMediaDataRoom,
      DataRoomType.LookalikeMedia,
    ],
    [DataRoomTypeNames.PublishedMediaDataRoom, DataRoomType.Media],
  ]
);

const DataRoomActions: React.FC<DataRoomActionsProps> = memo((props) => {
  const { __typename, id } = props;
  const errorReportContextValue = useMemo<DataRoomErrorReportContextValue>(
    () => ({
      dataRoomId: id,
      dataRoomType: dataRoomTypenameToDataRoomType.get(
        __typename as DataRoomTypeNames
      ),
    }),
    [id, __typename]
  );
  if (id) {
    switch (__typename) {
      case DataRoomTypeNames.DraftDataRoom:
        return <DraftDataRoomActions {...props} />;
      case DataRoomTypeNames.PublishedDataRoom:
        return (
          <DataRoomErrorReportProvider value={errorReportContextValue}>
            <PublishedDataRoomActions {...props} />
          </DataRoomErrorReportProvider>
        );
      case DataRoomTypeNames.PublishedMediaInsightsDcr:
        return (
          <DataRoomErrorReportProvider value={errorReportContextValue}>
            <MediaInsightsDcrActions {...props} />
          </DataRoomErrorReportProvider>
        );
      default:
        return null;
    }
  } else {
    return null;
  }
});

DataRoomActions.displayName = "DataRoomActions";

export default DataRoomActions;
