import { createContext, useContext } from "react";

export const SelectionsContext = createContext({
  all: undefined,
  allSelected: () => {},
  areSelected: () => {},
  isSelected: () => {},
  noneSelected: () => {},
  partiallySelected: () => {},
  select: () => {},
  selectAll: () => {},
  selectSome: () => {},
  selected: [],
  setAll: () => {},
  setSelected: () => {},
  toggle: () => {},
  toggleAll: () => {},
  toggleSome: () => {},
  unSelect: () => {},
  unSelectAll: () => {},
  unSelectSome: () => {},
});

export const SelectionsProvider = SelectionsContext.Provider;

export const useSelections = () => useContext(SelectionsContext);

export default useSelections;
