import { useComputeNodesVars } from "contexts";
import CommittedComputeNodeActions from "../CommittedComputeNodeActions/CommittedComputeNodeActions";
import DevComputeNodeActions from "../DevComputeNodeActions/DevComputeNodeActions";
import RequestComputeNodeActions from "../RequestComputeNodeActions/RequestComputeNodeActions";
import SubmittedRequestComputeNodeActions from "../SubmittedRequestComputeNodeActions/SubmittedRequestComputeNodeActions";

interface PublishedComputeNodeActionsProps {
  computeNodeId: string;
}

const PublishedComputeNodeActions: React.FC<
  PublishedComputeNodeActionsProps
> = ({ computeNodeId }) => {
  const { executionContext } = useComputeNodesVars();
  switch (executionContext) {
    case "development":
      return <DevComputeNodeActions computeNodeId={computeNodeId} />;
    case "requests":
      return <RequestComputeNodeActions computeNodeId={computeNodeId} />;
    case "submitted_requests":
      return (
        <SubmittedRequestComputeNodeActions computeNodeId={computeNodeId} />
      );
    case "committed":
      return <CommittedComputeNodeActions computeNodeId={computeNodeId} />;
    default:
      return <></>;
  }
};

export default PublishedComputeNodeActions;
