import { useAuth0 } from "@auth0/auth0-react";
import { utils } from "@decentriq/core";
import { useSnackbar } from "notistack";
import { useCallback, useMemo, useState } from "react";
import { itemToEntry } from "services/keychain";
import { useApiCore, useKeychain } from "contexts";
import { logInfo } from "utils";
import { useEnclaveTokenStorage } from "wrappers";

const enum MigrationStatus {
  NotStarted,
  Migrating,
  Completed,
}

interface UseKeychainMigrationHookResult {
  migrate: () => Promise<void>;
  migrationStatus: MigrationStatus;
  progress: number | null;
}

const useKeychainMigration = (): UseKeychainMigrationHookResult => {
  const { user } = useAuth0();
  const email = user?.email;
  const { client, sessionManager } = useApiCore();
  const keychain = useKeychain();
  const { state: storageEnclaveToken } = useEnclaveTokenStorage();
  const { enqueueSnackbar } = useSnackbar();

  const [migrationStatus, setMigrationStatus] = useState<MigrationStatus>(
    MigrationStatus.NotStarted
  );
  const [numberOfKeychainItems, setNumberOfKeychainItems] = useState<
    number | null
  >(null);
  const [numberOfMigratedItems, setNumberOfMigratedItems] = useState<
    number | null
  >(null);

  const migrate = useCallback(async () => {
    if (!client || !keychain || !email) {
      enqueueSnackbar(
        "Migration failed, please refresh. If the problem persists, contact support@decentriq.com.",
        { variant: "error" }
      );
      throw new Error("Keychain migration failed");
    }
    await client.markMigrationStarted().catch(() => {
      logInfo("Migration already started");
    });

    let sessionV2, keychainItems, migratedItems;
    try {
      sessionV2 = await sessionManager.getV2();
      sessionV2.setAuthToken({
        type: "enclave-access",
        value: JSON.parse(storageEnclaveToken!).token,
      });
      keychainItems = (await keychain.getItems()).map(itemToEntry);
      setNumberOfKeychainItems(keychainItems.length);
      setMigrationStatus(MigrationStatus.Migrating);
      migratedItems = await client.getKeychainMigratedItems();
      setNumberOfMigratedItems(migratedItems.length);
    } catch (error) {
      enqueueSnackbar(
        "Migration failed, please refresh. If the problem persists, contact support@decentriq.com.",
        { variant: "error" }
      );
      throw new Error("Keychain migration failed");
    }

    const migratedItemsSet = new Set<string>();
    for (const item of migratedItems) {
      migratedItemsSet.add(`${item.kind}-${item.key}`);
    }
    for (const item of keychainItems) {
      if (migratedItemsSet.has(`${item.kind}-${item.key}`)) {
        continue;
      } else {
        const secret = utils.keychainEntryToSecret(item, email);
        const secret_id = await sessionV2.createSecret(secret);
        await client.migrateKeychainItem(item.key, item.kind, secret_id);
        setNumberOfMigratedItems((prev) => (prev !== null ? prev + 1 : null));
      }
    }
    await client.markMigrationCompleted();
    setMigrationStatus(MigrationStatus.Completed);
  }, [
    client,
    email,
    enqueueSnackbar,
    keychain,
    sessionManager,
    storageEnclaveToken,
  ]);

  const progress = useMemo(() => {
    if (
      numberOfKeychainItems === null ||
      numberOfMigratedItems === null ||
      numberOfKeychainItems === 0
    ) {
      return null;
    }
    return (numberOfMigratedItems * 100) / numberOfKeychainItems;
  }, [numberOfKeychainItems, numberOfMigratedItems]);

  return {
    migrate,
    migrationStatus,
    progress,
  };
};

export default useKeychainMigration;
