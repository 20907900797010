import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/joy";

interface DataNodeItemProps {
  title: string;
  toggleCollapse?: () => void;
  isCollapsed: boolean;
  actions: React.ReactNode;
  content: React.ReactNode;
  description?: React.ReactNode;
}

const DataNodeItem: React.FC<DataNodeItemProps> = ({
  description,
  title,
  toggleCollapse,
  actions,
  content,
  ...restProps
}) => {
  const collapsible: boolean = Boolean(toggleCollapse);
  const isCollapsed = collapsible && restProps.isCollapsed;
  return collapsible ? (
    <Accordion
      expanded={!isCollapsed}
      onChange={toggleCollapse}
      variant="outlined"
    >
      <AccordionSummary
        slotProps={{ indicator: { sx: { ml: 0.5, order: -1 } } }}
      >
        <Typography level="title-md" sx={{ flex: 1 }}>
          {title}
          {description && (
            <Typography
              component="span"
              level="body-sm"
              sx={{ marginLeft: "0.75rem" }}
            >
              {description}
            </Typography>
          )}
        </Typography>
        {actions}
      </AccordionSummary>
      <AccordionDetails>{content}</AccordionDetails>
    </Accordion>
  ) : (
    <Accordion expanded={Boolean(content)} variant="outlined">
      <AccordionSummary
        slotProps={{
          button: { sx: { pl: 1.5 } },
          indicator: { sx: { display: "none", ml: 0.5, order: -1 } },
        }}
      >
        <Typography level="title-md" sx={{ flex: 1 }}>
          {title}
          {description && (
            <Typography
              component="span"
              level="body-sm"
              sx={{ marginLeft: "0.75rem" }}
            >
              {description}
            </Typography>
          )}
        </Typography>
        {actions}
      </AccordionSummary>
      {content ? <AccordionDetails>{content}</AccordionDetails> : null}
    </Accordion>
  );
};

export default DataNodeItem;
