import { useMemo } from "react";
import {
  getAggregationPresentation,
  useSelectedAudienceAggregations,
} from "features/MediaInsightsDcr";

// Returns aggregations for the selected audience type
const useMediaDataRoomAggregationData = (selectedAudienceType: string) => {
  const aggregations = useSelectedAudienceAggregations(selectedAudienceType);

  const aggregationData = useMemo(
    () =>
      aggregations.map(({ aggregation = [], id }) => {
        return {
          label: aggregation
            .map(({ column }) => getAggregationPresentation(column))
            .join("-"),
          value: id,
        };
      }),
    [aggregations]
  );

  return aggregationData;
};

export default useMediaDataRoomAggregationData;
