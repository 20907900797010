import { faChartSimple } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Modal,
  ModalDialog,
  Stack,
  Typography,
  useTheme,
} from "@mui/joy";
import { memo } from "react";

interface DataLabValidationConfirmationDialogProps {
  open: boolean;
  onClose: () => void;
}

const DataLabValidationConfirmationDialog =
  memo<DataLabValidationConfirmationDialogProps>(({ open, onClose }) => {
    const { breakpoints } = useTheme();
    return (
      <Modal onClose={onClose} open={open}>
        <ModalDialog
          maxWidth={breakpoints.values.xs}
          role="alertdialog"
          size="sm"
        >
          <DialogTitle level="h4">
            <Stack alignItems="center">
              <FontAwesomeIcon
                fixedWidth={true}
                icon={faChartSimple}
                size="2x"
              />
              Statistics computation started
            </Stack>
          </DialogTitle>
          <DialogContent sx={{ textAlign: "center" }}>
            <Stack>
              <Typography level="body-sm">
                Your statistics computation job has been submitted.
              </Typography>
              <Typography level="body-sm">
                This process may take up to several hours. You can close the
                dialog and come back later.
              </Typography>
            </Stack>
          </DialogContent>
          <DialogActions sx={{ margin: "0 auto", width: "fit-content" }}>
            <Button color="primary" onClick={onClose} size="sm" variant="solid">
              Close
            </Button>
          </DialogActions>
        </ModalDialog>
      </Modal>
    );
  });

DataLabValidationConfirmationDialog.displayName =
  "DataLabValidationConfirmationDialog";

export default DataLabValidationConfirmationDialog;
