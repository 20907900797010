import { useBoolean } from "ahooks";
import { memo } from "react";
import { type Audience } from "features/mediaDataRoom/models";
import DeleteAudienceDialog from "./DeleteAudienceDialog";

interface DeleteAudienceButtonProps {
  renderTrigger: (open: () => void) => React.ReactNode;
  audience: Audience;
}

const DeleteAudienceButton = memo(
  ({ renderTrigger, audience }: DeleteAudienceButtonProps) => {
    const [
      isDeleteAudienceDialogOpen,
      {
        setFalse: closeDeleteAudienceDialog,
        setTrue: openDeleteAudienceDialog,
      },
    ] = useBoolean(false);
    return (
      <>
        {renderTrigger(openDeleteAudienceDialog)}
        <DeleteAudienceDialog
          audience={audience}
          onClose={closeDeleteAudienceDialog}
          open={isDeleteAudienceDialogOpen}
        />
      </>
    );
  }
);

DeleteAudienceButton.displayName = "DeleteAudienceButton";

export default DeleteAudienceButton;
