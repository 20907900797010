import {
  faChevronDown,
  faChevronRight,
  faClone,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Tooltip, Typography } from "@mui/joy";
import { SimpleTreeView, TreeItem } from "@mui/x-tree-view";
import { memo } from "react";
import { useCopyToClipboard, useDataRoomSnackbar, useNodes } from "hooks";
import {
  type ComputeNodeTypeNames,
  DataNodeTypeNames,
  tableLeafNodePrimitiveTypePresentation,
} from "models";

const getNodeLabel = (
  nodeTypename: ComputeNodeTypeNames | DataNodeTypeNames
) => {
  switch (nodeTypename) {
    case DataNodeTypeNames.DraftTableLeafNode:
    case DataNodeTypeNames.PublishedTableLeafNode:
      return "TABLE";
    case DataNodeTypeNames.DraftRawLeafNode:
    case DataNodeTypeNames.PublishedRawLeafNode:
      return "FILE";
    default:
      return "";
  }
};

type FileExplorerProps = {
  fullHeight?: boolean;
};

const FileExplorer: React.FC<FileExplorerProps> = ({ fullHeight = false }) => {
  const { enqueueSnackbar } = useDataRoomSnackbar();
  const [, copyToClipboard] = useCopyToClipboard();
  const { nodes = [] } = useNodes();
  const computeNodes = nodes.filter(
    ({ __typename }) =>
      __typename === DataNodeTypeNames.DraftTableLeafNode ||
      __typename === DataNodeTypeNames.PublishedTableLeafNode
  );
  return (
    <Box
      sx={{
        background: "#f7f7f7",
        height: fullHeight ? "100%" : "380px",
        overflow: "scroll",
        padding: "10px",
        width: "100%",
      }}
    >
      <Typography level="body-sm">Table browser</Typography>
      <SimpleTreeView
        slots={{
          collapseIcon: () => <FontAwesomeIcon icon={faChevronDown} />,
          expandIcon: () => <FontAwesomeIcon icon={faChevronRight} />,
        }}
      >
        {computeNodes.map((node, index) => (
          <TreeItem
            itemId={`${index}-table-${node.name}`}
            key={`${index}-table-${node.name}`}
            label={
              <Box
                style={{
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Box
                  sx={{
                    alignItems: "center",
                    display: "flex",
                    gap: "0.25rem",
                    lineHeight: "1.5rem",
                    overflow: "hidden",
                  }}
                >
                  <Typography
                    level="body-sm"
                    style={{ overflow: "hidden", whiteSpace: "nowrap" }}
                  >
                    {node?.name || ""}
                  </Typography>
                  <Typography
                    component="span"
                    level="body-sm"
                    style={{
                      backgroundColor: "rgba(0, 0, 0, 0.08)",
                      borderRadius: "3px",
                      color: "black",
                      lineHeight: "0.75rem",
                      padding: "0.125rem",
                    }}
                  >
                    {getNodeLabel(node.__typename!)}
                  </Typography>
                </Box>
                <Tooltip title="Click to copy data node name">
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      copyToClipboard(node.name);
                      enqueueSnackbar(`'${node.name}' copied to clipboard`);
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <FontAwesomeIcon icon={faClone} />
                  </div>
                </Tooltip>
              </Box>
            }
          >
            {(node.__typename === DataNodeTypeNames.DraftTableLeafNode
              ? node.columns.nodes
              : node.__typename === DataNodeTypeNames.PublishedTableLeafNode
                ? node.columns
                : []
            ).map((column) => (
              <TreeItem
                itemId={`${index}-table-${node.name}-column-${column.name}`}
                key={`${index}-table-${node.name}-column-${column.name}`}
                label={
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box
                      sx={{
                        alignItems: "center",
                        display: "flex",
                        flexWrap: "wrap",
                        gap: "0.25rem",
                        lineHeight: "1.5rem",
                        overflow: "hidden",
                      }}
                    >
                      <Typography display="inline" level="body-sm">
                        {column.name}
                      </Typography>
                      <Typography
                        component="span"
                        level="body-sm"
                        style={{
                          backgroundColor: "rgba(0, 0, 0, 0.08)",
                          borderRadius: "3px",
                          color: "black",
                          marginLeft: ".5rem",
                          padding: "2px",
                          verticalAlign: "middle",
                        }}
                      >
                        {
                          tableLeafNodePrimitiveTypePresentation[
                            column.dataType
                          ]
                        }
                      </Typography>
                    </Box>
                    <Tooltip title="Click to copy column name">
                      <div
                        onClick={() => {
                          copyToClipboard(column.name);
                          enqueueSnackbar(
                            `'${column.name}' copied to clipboard`
                          );
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        <FontAwesomeIcon icon={faClone} />
                      </div>
                    </Tooltip>
                  </div>
                }
              />
            ))}
          </TreeItem>
        ))}
      </SimpleTreeView>
    </Box>
  );
};

FileExplorer.displayName = "FileExplorer";

export default memo(FileExplorer);
