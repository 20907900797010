import {
  useDraftComputeNodeQuery,
  usePublishedComputeNodeQuery,
} from "@decentriq/graphql/dist/hooks";
import {
  type DraftComputationNode,
  type Maybe,
  type PublishedComputationNode,
} from "@decentriq/graphql/dist/types";
import {
  useComputeNodesVars,
  useDataRoom,
  usePublishedDataRoom,
  useRequest,
} from "contexts";
import {
  mapDraftDataRoomErrorToSnackbar,
  mapErrorToGeneralSnackbar,
  useDataRoomSnackbar,
} from "hooks";

const useComputeNode = (computeNodeId: string) => {
  const { enqueueSnackbar } = useDataRoomSnackbar();
  const { isPublished } = useDataRoom();
  const { dataRoomId, driverAttestationHash } = usePublishedDataRoom();
  const { commitId } = useRequest();
  const { executionContext } = useComputeNodesVars();
  const shouldUseDraft =
    !isPublished ||
    executionContext === "development" ||
    executionContext === "requests";
  // draft & development
  const { data: draftData } = useDraftComputeNodeQuery({
    fetchPolicy: "cache-and-network",
    onError: (error) => {
      enqueueSnackbar(
        ...mapDraftDataRoomErrorToSnackbar(
          error,
          "The computation could not be retrieved. Please try again by refreshing the page."
        )
      );
    },
    skip: !shouldUseDraft,
    variables: { computeNodeId },
  });
  // published
  const { data: publishedData } = usePublishedComputeNodeQuery({
    fetchPolicy: "cache-only",
    onError: (error) => {
      enqueueSnackbar(
        ...mapErrorToGeneralSnackbar(
          error,
          "The computation could not be retrieved. Please try again by refreshing the page."
        )
      );
    },
    skip: shouldUseDraft,
    variables: {
      commitId,
      computeNodeId,
      dcrHash: dataRoomId,
      driverAttestationHash,
    },
  });
  return (
    (draftData?.draftNode as Maybe<DraftComputationNode>) ||
    (publishedData?.publishedNode as Maybe<PublishedComputationNode>)
  );
};

export default useComputeNode;
