import { Stack, Typography } from "@mui/joy";
import { Fragment, memo } from "react";
import { KeychainActions, KeychainItemsList } from "features/keychain";

const KeychainPage = memo(() => (
  <Fragment>
    <Stack
      alignItems="center"
      direction="row"
      justifyContent="space-between"
      sx={{ minHeight: 68, p: 2 }}
    >
      <Typography level="h4">Keychain</Typography>
      <KeychainActions />
    </Stack>
    <KeychainItemsList />
  </Fragment>
));

export default KeychainPage;
