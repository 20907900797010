// @ts-nocheck
import { useAuth0 } from "@auth0/auth0-react";
import {
  AppConfigurationClient,
  type ConfigurationSetting,
  type FeatureFlagValue,
  parseFeatureFlag,
} from "@azure/app-configuration";
import { useMemo, useState } from "react";
import { useConfiguration } from "contexts";
import { logError } from "utils";

// Corresponds to the Azure Feature flag UI. To create such a filter, choose Custom as a feature flag filter type, then specify these fields as filter parameters.
interface Filter {
  type: FilterType;
  field: keyof FeatureUser;
  value: any;
}

// The data the filter will be run against.
interface FeatureUser {
  email: string; // auth0 email
}

enum FilterType {
  ContainsAny = "ContainsAny",
  ContainsNone = "ContainsNone",
}

const useFeatureFlag = (flagKey) => {
  const {
    configuration: { azureAppConfigurationConnectionString },
  } = useConfiguration();
  const [enabled, setEnabled] = useState(false);
  const { user: auth0User } = useAuth0();
  const isAuthUserSet = auth0User == null;
  useMemo(async () => {
    if (!flagKey || !flagKey.toString().trim().length) {
      logError("A feature flag key must be supplied.");
      return;
    }
    if (auth0User?.email == null) {
      logError(
        `Email not set for auth0 user, cannot evaluate feature flag ${flagKey}`
      );
      return;
    }
    const featureUser: FeatureUser = {
      email: auth0User.email,
    };
    if (auth0User == null) {
      return;
    }
    const appConfigurationClient = new AppConfigurationClient(
      azureAppConfigurationConnectionString
    );
    try {
      const configurationSetting =
        await appConfigurationClient.getConfigurationSetting(
          {
            key: `.appconfig.featureflag/${flagKey}`,
          },
          {
            requestOptions: {
              customHeaders: {
                "Cache-Control": "no-cache,no-store,max-age=0",
              },
            },
          }
        );
      const featureFlag = parseFeatureFlag(configurationSetting);
      if (isFeatureFlagEnabled(featureFlag, featureUser)) {
        setEnabled(true);
      }
    } catch (error) {
      logError(error);
    }
  }, [azureAppConfigurationConnectionString, flagKey, isAuthUserSet]);

  return enabled;
};

const useAppConfiguration = (configKey, defaultValue) => {
  const {
    configuration: { azureAppConfigurationConnectionString },
  } = useConfiguration();
  const [configurationValue, setConfigurationValue] = useState(defaultValue);
  useMemo(async () => {
    if (!configKey || !configKey.length) {
      logError("A configuration key must be supplied.");
      return;
    }
    const appConfigurationClient = new AppConfigurationClient(
      azureAppConfigurationConnectionString
    );
    try {
      const configurationSetting =
        await appConfigurationClient.getConfigurationSetting(
          {
            key: configKey,
          },
          {
            requestOptions: {
              customHeaders: {
                "Cache-Control": "no-cache,no-store,max-age=0",
              },
            },
          }
        );
      if (configurationSetting.value == null) {
        logError(`App configuration "${configKey}" is not set remotely`);
        return;
      }
      switch (configurationSetting.contentType) {
        case "application/json": {
          setConfigurationValue(JSON.parse(configurationSetting.value));
          break;
        }
        case "text/plain": {
          setConfigurationValue(configurationSetting.value);
          break;
        }
        default: {
          logError(
            `App Configuration "${configKey}" has unknown Content type "${configurationSetting.contentType}"`
          );
          return;
        }
      }
    } catch (error) {
      logError(error);
    }
  }, [azureAppConfigurationConnectionString, configKey]);

  return configurationValue;
};

/// Returns true if the feature flag is enabled and applies to `featureUser`.
/// If there are errors during the processing of the feature flag, false is returned and a corresponding error is printed to console.
function isFeatureFlagEnabled(
  featureFlag: ConfigurationSetting<FeatureFlagValue>,
  featureUser: FeatureUser
): boolean {
  let flagKey = featureFlag.key;
  if (!featureFlag.value.enabled) {
    return false;
  }
  if (featureFlag.value.conditions.clientFilters.length === 0) {
    return true;
  }
  for (const filterRaw of featureFlag.value.conditions.clientFilters) {
    const filter = filterRaw.parameters as Filter | null;
    if (filter == null) {
      logError(
        `Feature flag filter parameters not set for feature "${flagKey}", filter "${filterRaw.name}"`
      );
      return false;
    }
    const fieldValue = featureUser[filter.field];
    switch (filter.type) {
      case FilterType.ContainsAny: {
        if (typeof fieldValue !== "string") {
          logError(
            `Feature "${flagKey}"'s filter "${filterRaw.name}"'s type is ${filter.type} which only accepts string fields`
          );
          return false;
        }
        if (!Array.isArray(filter.value)) {
          logError(
            `Feature "${flagKey}"'s filter "${filterRaw.name}"'s type is ${filter.type} which only accepts an array of strings as "value"`
          );
          return false;
        }
        if (
          (filter.value as string[]).some((member) =>
            fieldValue.includes(member)
          )
        ) {
          return true;
        }
        break;
      }
      case FilterType.ContainsNone: {
        if (typeof fieldValue !== "string") {
          logError(
            `Feature "${flagKey}"'s filter "${filterRaw.name}"'s type is ${filter.type} which only accepts string fields`
          );
          return false;
        }
        if (!Array.isArray(filter.value)) {
          logError(
            `Feature "${flagKey}"'s filter "${filterRaw.name}"'s type is ${filter.type} which only accepts an array of strings as "value"`
          );
          return false;
        }
        if (
          (filter.value as string[]).every(
            (member) => !fieldValue.includes(member)
          )
        ) {
          return true;
        }
        break;
      }
      default: {
        logError(
          `Feature "${flagKey}"'s filter "${filterRaw.name}" has unknown type "${filter.type}"`
        );
        return false;
      }
    }
  }
  return false;
}

export { useAppConfiguration, useFeatureFlag };
