import { useDataConnectorJobQuery } from "@decentriq/graphql/dist/hooks";

type ConnectionTooltipData = {
  bucket: string | undefined;
  objectKey: string | undefined;
  region: string | undefined;
};

const useS3ConnectionConfigurationData = (
  connectionId: string
): ConnectionTooltipData => {
  const { data: { dataConnectorJob } = {} } = useDataConnectorJobQuery({
    skip: !connectionId,
    variables: { id: connectionId },
  });

  const config = dataConnectorJob?.config;

  return {
    bucket: config?.bucket,
    objectKey: config?.key,
    region: config?.region,
  };
};

export default useS3ConnectionConfigurationData;
