import { faFileExport } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@mui/joy";
import { memo } from "react";
import DatasetExport from "./DatasetExport";

interface DatasetExportButtonProps {
  manifestHash: string;
}

const DatasetExportButton = memo<DatasetExportButtonProps>(
  ({ manifestHash }) => (
    <DatasetExport
      manifestHash={manifestHash}
      renderTrigger={(open) => (
        <Button
          color="primary"
          onClick={open}
          startDecorator={
            <FontAwesomeIcon fixedWidth={true} icon={faFileExport} />
          }
          variant="solid"
        >
          Export dataset
        </Button>
      )}
    />
  )
);

DatasetExportButton.displayName = "DatasetExportButton";

export default DatasetExportButton;
