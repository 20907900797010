import { createContext, useContext } from "react";

export const ViewContext = createContext({
  blocks: [],
  blocksById: [],
  blocksSelections: () => {},
  blocksSelectionsAttributes: () => {},
  blocksSelectionsMeta: () => {},
  groups: [],
  groupsById: {},
  groupsSelections: () => {},
  orders: [],
  ordersById: {},
  ordersSelections: () => {},
  ordersSelectionsReverse: () => {},
  setViewAs: () => {},
  setWithMultiselections: () => {},
  setWithSelections: () => {},
  view: undefined,
  viewAs: "cards",
  withMultiselections: false,
  withSelections: false,
});

export const ViewProvider = ViewContext.Provider;

export const useView = () => useContext(ViewContext);

export default useView;
