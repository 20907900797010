import { faCheck } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Step, StepIndicator, Stepper, Typography } from "@mui/joy";
import { Fragment, memo, useMemo } from "react";
import {
  AdvertiserAudienceGeneratorStep,
  useAdvertiserAudienceGenerator,
} from "../../contexts/AdvertiserAudienceGeneratorContext";

const mapGenerationStepToPresentation = new Map<
  AdvertiserAudienceGeneratorStep,
  string
>([
  [AdvertiserAudienceGeneratorStep.SELECT_TYPE, "Audience type"],
  [AdvertiserAudienceGeneratorStep.CONFIGURATION, "Audience configuration"],
  [AdvertiserAudienceGeneratorStep.SUMMARY, "Audience preview"],
]);

const AdvertiserAudienceGeneratorHeader: React.FC = memo(() => {
  const { currentStep, generationSteps } = useAdvertiserAudienceGenerator();
  const currentStepIndex = useMemo(
    () => generationSteps.indexOf(currentStep),
    [currentStep, generationSteps]
  );
  return (
    <Fragment>
      <Typography fontWeight={700} level="h3" mb={3}>
        Create a new audience
      </Typography>
      <Stepper size="sm">
        {generationSteps.map((step, index) => (
          <Step
            indicator={
              <StepIndicator
                color={currentStepIndex <= index - 1 ? "neutral" : "primary"}
                variant="solid"
              >
                {currentStepIndex <= index ? (
                  index + 1
                ) : (
                  <FontAwesomeIcon fixedWidth={true} icon={faCheck} />
                )}
              </StepIndicator>
            }
            key={step}
            sx={[
              currentStepIndex > index &&
                index !== 2 && {
                  "&::after": { bgcolor: "primary.solidBg" },
                },
            ]}
          >
            {mapGenerationStepToPresentation.get(step)}
          </Step>
        ))}
      </Stepper>
    </Fragment>
  );
});

AdvertiserAudienceGeneratorHeader.displayName =
  "AdvertiserAudienceGeneratorHeader";

export default AdvertiserAudienceGeneratorHeader;
