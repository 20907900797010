import { DqLoader } from "@decentriq/components";
import { Stack } from "@mui/joy";
import { memo } from "react";

type AudienceQualityStatisticsLoadingProps = {
  direction?: "row" | "column";
};

const AudienceQualityStatisticsLoading: React.FC<
  AudienceQualityStatisticsLoadingProps
> = ({ direction = "column" }) => {
  return (
    <Stack
      alignItems="center"
      flexDirection={direction}
      justifyContent={direction === "row" ? "flex-start" : "center"}
      spacing={0.5}
    >
      <DqLoader label="Computing..." />
    </Stack>
  );
};

AudienceQualityStatisticsLoading.displayName =
  "AudienceQualityStatisticsLoading";

export default memo(AudienceQualityStatisticsLoading);
