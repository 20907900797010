import { type Key } from "@decentriq/utils";
import { useCallback } from "react";
import useCommonDataNodeActions from "features/dataNodes/hooks/useCommonDataNodeActions";
import { useDatasetDelete } from "features/datasets";
import { useMediaDataRoomHandlers } from "features/mediaDataRoom/contexts";
import { mapMediaDataRoomErrorToSnackbar, useDataRoomSnackbar } from "hooks";

interface AdvertiserDataNodeHookPayload {
  datasetHash: string;
  driverAttestationHash: string;
}

const useAdvertiserDataNodeActions = ({
  datasetHash,
}: AdvertiserDataNodeHookPayload) => {
  const { enqueueSnackbar } = useDataRoomSnackbar();
  const { publishAdvertiserDataset, unpublishAdvertiserDataset } =
    useMediaDataRoomHandlers();
  const [deleteDataset] = useDatasetDelete({
    manifestHash: datasetHash,
  });
  const onDatasetDeprovision = useCallback(async () => {
    try {
      await unpublishAdvertiserDataset();
      enqueueSnackbar("Your data has been deprovisioned.");
    } catch (error) {
      enqueueSnackbar(
        ...mapMediaDataRoomErrorToSnackbar(error, `Can't deprovision data`)
      );
    }
  }, [unpublishAdvertiserDataset, enqueueSnackbar]);
  const onDatasetDelete = useCallback(async () => {
    if (!(await deleteDataset())) {
      return;
    }
    try {
      await unpublishAdvertiserDataset();
      enqueueSnackbar("Your data has been deleted.");
    } catch (error) {
      enqueueSnackbar(
        ...mapMediaDataRoomErrorToSnackbar(error, "Can't delete data")
      );
    }
  }, [deleteDataset, enqueueSnackbar, unpublishAdvertiserDataset]);
  const onPublishAdvertiserDataset = useCallback(
    async ({ key, manifestHash }: { key: Key; manifestHash: string }) => {
      await publishAdvertiserDataset({
        dataset: {
          datasetHash: manifestHash,
          encryptionKey: key,
        },
      });
    },
    [publishAdvertiserDataset]
  );
  const {
    activeDataRoomUpload,
    addOrUpdateUploading,
    currentUserEmail,
    dataNodeForIngestion,
    handleAwaitedDataUploadingResult,
    handleConnectFromKeychain,
    handleDataDeprovision,
    handleDataDelete,
    handleIngestData,
    handleUploadClose,
    isUploadFailed,
    isUploadSuccessful,
    isUploadValidating,
    removeUploading,
    resetUploadings,
    setActiveDataRoomUpload,
    setDataNodeForIngestion,
    uploadings,
  } = useCommonDataNodeActions({
    onAfterIngestData: onPublishAdvertiserDataset,
    onConnectDataset: onPublishAdvertiserDataset,
    onDatasetDelete,
    onDatasetDeprovision,
  });
  return {
    activeDataRoomUpload,
    addOrUpdateUploading,
    currentUserEmail,
    dataNodeForIngestion,
    handleAwaitedDataUploadingResult,
    handleConnectFromKeychain,
    handleDataDelete,
    handleDataDeprovision,
    handleIngestData,
    handleUploadClose,
    isUploadFailed,
    isUploadSuccessful,
    isUploadValidating,
    removeUploading,
    resetUploadings,
    setActiveDataRoomUpload,
    setDataNodeForIngestion,
    uploadings,
  };
};

export default useAdvertiserDataNodeActions;
