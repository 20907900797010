import { DqTable } from "@decentriq/components";
import { faCircleCheck, faCircleInfo } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Stack, Tooltip, Typography } from "@mui/joy";
import { type MRT_ColumnDef } from "material-react-table";
import { useMemo, useState } from "react";
import { useMediaDataRoom } from "features/mediaDataRoom/contexts";
import {
  type Audience,
  type AudienceKind,
  audienceTypePresentationMap,
} from "features/mediaDataRoom/models";
import {
  ActionsMenu,
  AudienceCreationDate,
  AudienceSize,
  AudienceStatusLabel,
  ExportAudienceButton,
  MediaDataRoomAudienceDrawer,
} from "./components";

interface MediaDataRoomAudiencesTableProps {
  audiences: Audience[];
  isFiltered?: boolean;
}

const MediaDataRoomAudiencesTable: React.FC<
  MediaDataRoomAudiencesTableProps
> = ({ audiences, isFiltered }) => {
  const {
    isPublisher,
    isAdvertiser,
    isObserver,
    isAgency,
    supportedFeatures: { enableAdvertiserAudienceDownload, hideAbsoluteValues },
  } = useMediaDataRoom();
  const [selectedAudienceId, setSelectedAudienceId] = useState<string | null>(
    null
  );
  // TODO: This will eventually be migrated to the audiences context state
  const selectedAudienceWithSeedAudienceName = useMemo<
    (Audience & { seedAudienceName: string | null }) | null
  >(() => {
    if (!selectedAudienceId) {
      return null;
    }
    const audience = audiences.find((a) => a.id === selectedAudienceId);
    if (!audience) {
      return null;
    }
    return {
      ...audience,
      seedAudienceName:
        audience.kind !== "advertiser"
          ? (audiences.find((a) => a.id === audience.source_ref)?.mutable
              ?.name ?? null)
          : null,
    };
  }, [audiences, selectedAudienceId]);
  const hasPublisherAccessColumn = !isObserver && !isPublisher;
  const hasExportButton =
    isPublisher ||
    ((isAdvertiser || isAgency) && enableAdvertiserAudienceDownload);
  const noRecordsToDisplay = useMemo(() => {
    if (isFiltered) {
      return "No results found";
    }
    return isPublisher
      ? "The advertiser has not made any audience available for activation yet."
      : "The model is ready, please create your first audience.";
  }, [isPublisher, isFiltered]);
  const columns = useMemo<MRT_ColumnDef<Audience>[]>(
    () => [
      // Name
      {
        accessorKey: "mutable.name",
        grow: true,
        header: "Audience name",
      },
      // Kind
      {
        Cell: ({ cell }) => {
          const kind = cell.getValue<AudienceKind>();
          return audienceTypePresentationMap.get(kind) || "N/A";
        },
        accessorKey: "kind",
        header: "Audience type",
      },
      // Size
      {
        Cell: ({
          row: {
            original: { id, kind, audience_size },
          },
        }) => <AudienceSize audienceSize={audience_size} id={id} kind={kind} />,
        accessorKey: "audience_size",
        header: "Audience size",
      },
      // Creation Date
      {
        Cell: ({ cell }) => (
          <AudienceCreationDate
            createdAt={cell.getValue<Audience["mutable"]>().created_at}
          />
        ),
        accessorKey: "mutable",
        header: "Creation date",
      },
      // Status
      {
        Cell: ({
          row: {
            original: {
              mutable: { status },
              id,
            },
          },
        }) => {
          return <AudienceStatusLabel id={id} status={status} />;
        },
        Header: () => (
          <Stack alignItems="center" direction="row" gap={1}>
            Status
            <Tooltip
              title={
                <Typography level="body-sm" sx={{ color: "white" }}>
                  Published: Audience is live and available for campaigns.
                  <br />
                  Ready: Audience is generated and ready but not published.
                  <br />
                  Computing: Audience is still processing and will be available
                  when complete.
                </Typography>
              }
            >
              <FontAwesomeIcon icon={faCircleInfo} />
            </Tooltip>
          </Stack>
        ),
        header: "Status",
        id: "mutable.status",
      },
    ],
    []
  );
  return (
    <>
      <DqTable
        columns={columns}
        data={audiences}
        displayColumnDefOptions={{
          "mrt-row-actions": {
            enableResizing: false,
            grow: false,
            header: "Actions",
            muiTableBodyCellProps: {
              align: hasExportButton ? "justify" : "right",
              onClick: (event) => event.stopPropagation(),
              sx: {
                minWidth:
                  "max(calc(var(--col-mrt_row_actions-size)* 1px), 36px)",
              },
            },
            muiTableHeadCellProps: {
              align: "left",
              sx: {
                fontWeight: "semiBold",
                minWidth:
                  "max(calc(var(--header-mrt_row_actions-size)* 1px), 36px)",
              },
            },
            size: 200,
          },
        }}
        emptyStateIcon={isPublisher || isFiltered ? undefined : faCircleCheck}
        enableRowActions={hasExportButton || isAdvertiser}
        getRowId={(row) =>
          `${row.id}-${row.kind}-${row.reach ?? row.audience_size}`
        }
        initialState={{
          columnVisibility: {
            audience_size: !hideAbsoluteValues,
            status: hasPublisherAccessColumn,
          },
        }}
        localization={{
          noRecordsToDisplay,
        }}
        muiTableBodyRowProps={({ row }) => ({
          onClick: () => setSelectedAudienceId(row.original.id),
          sx: { cursor: "pointer" },
        })}
        muiTablePaperProps={{
          sx: {
            display: "flex",
            flex: 1,
            flexDirection: "column",
            overflow: "hidden",
            width: "100%",
          },
        }}
        renderRowActions={({ row }) => (
          <Stack
            direction="row"
            flex={1}
            gap={1}
            justifyContent={hasExportButton ? "space-between" : "center"}
          >
            {hasExportButton && (
              <ExportAudienceButton audience={row.original} />
            )}
            {isAdvertiser && (
              <ActionsMenu
                audience={row.original}
                mode={hasExportButton ? "dropdown" : "icons"}
              />
            )}
          </Stack>
        )}
      />
      <MediaDataRoomAudienceDrawer
        audience={selectedAudienceWithSeedAudienceName}
        hasExportButton={hasExportButton}
        onClose={() => setSelectedAudienceId(null)}
        open={Boolean(selectedAudienceWithSeedAudienceName)}
        seedAudienceName={
          selectedAudienceWithSeedAudienceName?.seedAudienceName ?? null
        }
      />
    </>
  );
};

export default MediaDataRoomAudiencesTable;
