// TODO: Fix joy migration
import { faCheck, faInfoCircle } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Divider,
  Modal,
  ModalDialog,
  Typography,
} from "@mui/joy";
import { memo } from "react";

interface SubmittedRequestMergeDialogProps {
  open: boolean;
  loading?: boolean;
  onCancel: () => void;
  onConfirm: () => void;
}

const SubmittedRequestMergeDialog: React.FC<
  SubmittedRequestMergeDialogProps
> = ({ open, loading, onCancel, onConfirm }) => {
  return (
    <Modal open={open}>
      <ModalDialog>
        <DialogContent>
          <Box sx={{ mb: 1, textAlign: "center" }}>
            <FontAwesomeIcon fixedWidth={true} icon={faInfoCircle} size="3x" />
          </Box>
          <Typography
            align="center"
            color="textPrimary"
            gutterBottom={true}
            level="title-md"
            style={{ lineHeight: 1.25 }}
          >
            <strong>
              This will integrate the approved computation to the data clean
              room
            </strong>
          </Typography>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button disabled={loading} onClick={onCancel}>
            Go back to review
          </Button>
          <Button
            data-testid="popup_publish_dcr_btn"
            loading={loading}
            loadingPosition="start"
            onClick={onConfirm}
            startDecorator={<FontAwesomeIcon icon={faCheck} />}
          >
            Integrate
          </Button>
        </DialogActions>
      </ModalDialog>
    </Modal>
  );
};

SubmittedRequestMergeDialog.displayName = "SubmittedRequestMergeDialog";

export default memo(SubmittedRequestMergeDialog);
