import {
  type IconProp,
  type SizeProp,
} from "@fortawesome/fontawesome-svg-core";
import { faEmptySet as fatEmptySet } from "@fortawesome/pro-thin-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Stack, Typography } from "@mui/joy";
import { type SxProps } from "@mui/material";

type EmptyDataProps = {
  icon?: IconProp;
  iconSize?: SizeProp;
  primaryText?: string;
  secondaryText?: string;
  sx?: SxProps;
};

const EmptyData: React.FC<EmptyDataProps> = ({
  icon = fatEmptySet,
  iconSize = "4x",
  primaryText,
  secondaryText,
  sx,
}) => {
  return (
    <Stack
      sx={{
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        height: "100%",
        justifyContent: "center",
        width: "100%",
        ...sx,
      }}
    >
      {icon ? (
        <FontAwesomeIcon fixedWidth={true} icon={icon} size={iconSize} />
      ) : null}
      {primaryText ? <Typography>{primaryText}</Typography> : null}
      {secondaryText ? <Typography>{secondaryText}</Typography> : null}
    </Stack>
  );
};

export default EmptyData;
