import { Stack, Typography } from "@mui/joy";
import { Fragment, memo } from "react";
import { UsersList } from "components";

const UsersPage = () => {
  return (
    <Fragment>
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        sx={{ minHeight: 68 - 16, pt: 2, px: 2 }}
      >
        <Typography level="title-md">Manage Users</Typography>
      </Stack>
      <UsersList />
    </Fragment>
  );
};

export default memo(UsersPage);
