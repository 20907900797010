import { isEqual } from "lodash";
import { useSelectedAudienceAggregations } from "features/MediaInsightsDcr";

type AudienceAggregationIdByNameHookParams = {
  audienceType: string;
  aggregationNames: string[];
};

/*
  Some of the aggregations created based on the multiple columns (e.g. interest and gender or interest and age)
  Hence, aggregationNames is passed as an array, so if the single value is there, then aggregation id will be found by this single column
  Otherwise - match all of the passed columns
 */
const useAudienceAggregationIdByName = ({
  audienceType,
  aggregationNames = [],
}: AudienceAggregationIdByNameHookParams): string | undefined => {
  const aggregations = useSelectedAudienceAggregations(audienceType);
  const sortedAggregationNames = aggregationNames.sort((column1, column2) =>
    column1.localeCompare(column2)
  );
  const genderAggregationId = aggregations.find(({ aggregation }) => {
    const aggregationColumnNames = aggregation
      .map(({ column }) => column)
      .sort((column1, column2) => column1.localeCompare(column2));
    return isEqual(aggregationColumnNames, sortedAggregationNames);
  })?.id;

  return genderAggregationId;
};

export default useAudienceAggregationIdByName;
