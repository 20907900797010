// TODO: Fix joy migration
import { Box, Typography } from "@mui/joy";
import { memo, useEffect, useState } from "react";
import { mapErrorToGeneralSnackbar, useDataRoomSnackbar } from "hooks";
import { getMatchNodeResult } from "utils/apicore";
import { ResultSchema, ResultStatistics } from "./components";

export type MatchNodeComputationResultPaneProps = {
  id: string;
  bytes: Uint8Array;
};

const MatchNodeComputationResultPane: React.FC<MatchNodeComputationResultPaneProps> =
  memo(({ id, bytes }) => {
    const { enqueueSnackbar } = useDataRoomSnackbar();
    const [result, setResult] = useState<any>({});
    useEffect(() => {
      getMatchNodeResult(bytes)
        .then(setResult)
        .catch((error) => {
          enqueueSnackbar(
            ...mapErrorToGeneralSnackbar(error, "Failed to get result")
          );
        });
    }, [bytes, enqueueSnackbar]);
    const { data, schema } = result || {};
    return result ? (
      <Box sx={{ mt: 1 }}>
        <Typography color="inherit" level="title-md">
          Statistics
        </Typography>
        {data ? (
          <Box sx={{ mt: 1 }}>
            <ResultStatistics data={data} id={id} />
          </Box>
        ) : null}
        <Typography color="inherit" level="title-md" sx={{ mt: 3.5 }}>
          Output schema
        </Typography>
        {schema ? <ResultSchema schema={schema} /> : null}
      </Box>
    ) : null;
  });
MatchNodeComputationResultPane.displayName = "MatchNodeComputationResultPane";

export default MatchNodeComputationResultPane;
