import {
  useDataConnectorJobsQuery,
  usePollDatasetExportMutation,
  usePollDatasetImportMutation,
} from "@decentriq/graphql/dist/hooks";
import {
  type DataConnectorJob,
  DataConnectorJobType,
  DataImportExportStatus,
  DatasetsDocument,
} from "@decentriq/graphql/dist/types";
import { useEffect } from "react";
import { useKeychainSetup } from "wrappers";
import { KeychainItemKind, KeychainStatus } from "../services/keychain";
import { useKeychainItems } from "./keychain/useKeychainItems";

const usePendingImportsFromKeychain = (itemKind: KeychainItemKind) => {
  const { items: keychainItems } = useKeychainItems();
  const pendingDatasetImports = keychainItems.filter((item) => {
    return item.kind === itemKind;
  });
  return pendingDatasetImports;
};

/**
 * This hook's job is to poll the result of all pending dataset imports.
 * For each import we'll trigger the process of polling the enclave for the import
 * result (the manifest hash of the new dataset), so that we can update the keychain
 * with the new key->manifestHash mapping.
 * This is necessary, as the user might have closed the browser window
 * in an earlier dataset import attempt, without having waited for the import
 * to finish.
 */
export default function useDatasetImportExportWatcher() {
  const { status: keychainStatus } = useKeychainSetup();

  // When a new dataset import data connector job is created,
  // this component's useEffect callback should run again
  // to trigger the import polling procedure.
  const allDataConnectorJobs = useDataConnectorJobsQuery({
    variables: {
      filter: null,
    },
  });

  const [pollDatasetImportMutation] = usePollDatasetImportMutation({
    refetchQueries: [{ query: DatasetsDocument }],
  });
  const [pollDatasetExportMutation] = usePollDatasetExportMutation({
    refetchQueries: [{ query: DatasetsDocument }],
  });

  // Note that this effect depends on `allDatasetImports`. If a new dataset import
  // is being created by the import wizard, there will also be a new keychain
  // item for a pending dataset import.
  // Ideally this component would directly depend on the keychain items cache
  // (or whatever other state management system we end up using).
  const pendingImports = usePendingImportsFromKeychain(
    KeychainItemKind.PendingDatasetImport
  );
  useEffect(() => {
    const completePendingImports = async () =>
      await Promise.all(
        pendingImports.map(async (datasetImport) => {
          await pollDatasetImportMutation({
            variables: { id: datasetImport.id },
          });
        })
      );
    if (keychainStatus === KeychainStatus.unlocked) {
      completePendingImports();
    }
  }, [
    keychainStatus,
    allDataConnectorJobs.data,
    pendingImports,
    pollDatasetImportMutation,
  ]);

  useEffect(() => {
    const pendingDatasetExports = (
      allDataConnectorJobs?.data?.dataConnectorJobs?.nodes || []
    ).filter(
      ({ status, type }: DataConnectorJob) =>
        type === DataConnectorJobType.ExportDataset &&
        status === DataImportExportStatus.Pending
    );
    async function completePendingExports() {
      await Promise.all(
        pendingDatasetExports.map(async (datasetExport: DataConnectorJob) => {
          await pollDatasetExportMutation({
            variables: { id: datasetExport.id },
          });
        })
      );
    }
    completePendingExports();
  }, [
    allDataConnectorJobs?.data?.dataConnectorJobs?.nodes,
    pollDatasetExportMutation,
  ]);
}
