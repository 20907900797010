import { DqTable } from "@decentriq/components";
import { faEmptySet } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert, Box, CircularProgress, Tooltip, Typography } from "@mui/joy";
import { type MRT_Cell, type MRT_ColumnDef } from "material-react-table";
import { Fragment, useCallback, useMemo } from "react";
import { KeychainItemKind } from "services/keychain/types";
import { makeStyles } from "tss-react/mui";
import { useKeychainItems } from "hooks/keychain/useKeychainItems";
import PreviewDatasetButton from "../PreviewDatasetButton/PreviewDatasetButton";
import { DeleteKeychainItemButton } from "..";

const useStyle = makeStyles()({
  contentText: {
    display: "block",
    marginBlockEnd: "0.7em",
    marginBlockStart: "0.3em",
    marginBottom: "2rem",
    marginInlineEnd: "0px",
    marginInlineStart: "0px",
  },
  icon: {
    height: "5em",
    marginBottom: "0.5em",
    width: "5em !important",
  },
});

const KeychainItemsList: React.FC = () => {
  const { classes } = useStyle();
  const { loading, error, items } = useKeychainItems({
    kinds: useMemo(() => [KeychainItemKind.Dataset], []),
  });

  type KeychainItem = Omit<(typeof items)[number], "__typename">;
  const Cell = useCallback(
    ({ cell }: { cell: MRT_Cell<KeychainItem> }) => (
      <Tooltip placement="top-start" title={cell.renderValue<string>()}>
        <Typography
          component="span"
          level="inherit"
          noWrap={true}
          textColor="inherit"
        >
          {cell.renderValue<string>()}
        </Typography>
      </Tooltip>
    ),
    []
  );
  const columns = useMemo<MRT_ColumnDef<KeychainItem>[]>(
    () => [
      {
        Cell,
        accessorFn: (row) => {
          if (row.kind === KeychainItemKind.Dataset) {
            return "Encryption key for dataset";
          } else if (row.kind === KeychainItemKind.PendingDatasetImport) {
            return "Encryption key for dataset being imported";
          }
          return "Other";
        },
        header: "Content type",
        id: "kind",
        size: 100,
      },
      {
        Cell,
        accessorFn: (row) => {
          if (row.kind === KeychainItemKind.Dataset) {
            return `Dataset ID: ${row.id}`;
          } else if (row.kind === KeychainItemKind.PendingDatasetImport) {
            return `Import ID: ${row.id}`;
          }
          return row.id;
        },
        header: "Description",
        id: "id",
        size: 380,
      },
    ],
    [Cell]
  );
  return (
    <Box
      sx={{
        alignItems: "stretch",
        backgroundColor: "common.white",
        display: "flex",
        flex: 1,
        flexDirection: "column",
        justifyContent: "stretch",
        overflow: "hidden",
      }}
    >
      {loading ? (
        <Box
          alignItems="center"
          display="flex"
          height="100%"
          justifyContent="center"
          padding="1rem"
        >
          <CircularProgress sx={{ "--CircularProgress-size": "1.5rem" }} />
        </Box>
      ) : error ? (
        <Alert color="danger">
          {error ||
            "Keychain items could not be retrieved. Please try again by refreshing the page."}
        </Alert>
      ) : items.length ? (
        <DqTable
          columns={columns}
          data={items}
          enableRowActions={true}
          muiTableBodyRowProps={{
            sx: {
              "& > .MuiTableCell-root:first-child": { pl: 2 },
              "& > .MuiTableCell-root:last-child": { pr: 2 },
            },
          }}
          muiTableHeadRowProps={{
            sx: {
              "& > .MuiTableCell-root:first-child": { pl: 2 },
              "& > .MuiTableCell-root:last-child": {
                "& .Mui-TableHeadCell-ResizeHandle-Wrapper": {
                  right: "-1rem",
                },
                pr: 2,
              },
            },
          }}
          muiTablePaperProps={{
            sx: {
              display: "flex",
              flex: 1,
              flexDirection: "column",
              height: "100%",
              overflow: "hidden",
              width: "100%",
            },
          }}
          muiTableProps={{
            style: {
              "--col-mrt_row_actions-size": 36 * 2 + 16,
              "--header-mrt_row_actions-size": 36 * 2 + 16,
            } as React.CSSProperties,
          }}
          renderRowActions={({ row }) => {
            return (
              <Fragment>
                {row.original.kind === KeychainItemKind.Dataset && (
                  <PreviewDatasetButton manifestHash={row.original.id} />
                )}
                <DeleteKeychainItemButton
                  id={row.original.id}
                  kind={row.original.kind}
                />
              </Fragment>
            );
          }}
        />
      ) : (
        <Box
          alignItems="center"
          display="flex"
          flexDirection="column"
          height="100%"
          justifyContent="center"
          paddingBottom="3rem"
          width="100%"
        >
          <FontAwesomeIcon
            className={classes.icon}
            fixedWidth={true}
            height="5em !important"
            icon={faEmptySet}
            width={24}
          />
          <Typography level="title-md">
            <strong>Your Keychain is empty</strong>
          </Typography>
          <Box sx={{ marginTop: "2.5rem" }}>
            <Typography>
              <p className={classes.contentText}>
                The Keychain lets you confidentially store dataset encryption
                <br />
                keys and other secrets.
              </p>
            </Typography>
            <Typography>
              <p className={classes.contentText}>
                Among other features, this enables you to reuse datasets across
                <br />
                data clean rooms without having to re-upload them to Decentriq.
              </p>
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default KeychainItemsList;
