import {
  ColumnDataType,
  type DraftTableLeafNodeColumn,
  type PublishedTableLeafNodeColumn,
  SyntheticMaskType,
  type SyntheticNodeColumn,
} from "@decentriq/graphql/dist/types";
import isEqual from "lodash/isEqual";

export const mapColumnDataTypeToMaskType = (
  dataType: ColumnDataType
): SyntheticMaskType =>
  dataType === ColumnDataType.Text
    ? SyntheticMaskType.GenericString
    : SyntheticMaskType.GenericNumber;

export const isSameColumnMasked = (
  column: PublishedTableLeafNodeColumn | DraftTableLeafNodeColumn,
  maskedColumn: SyntheticNodeColumn
) =>
  isEqual(
    {
      dataType: column.dataType,
      name: column.name,
    },
    { dataType: maskedColumn.dataType, name: maskedColumn.name }
  );

export const getDefaultConfigsForColumns = (
  columns: DraftTableLeafNodeColumn[],
  maskedColumns: SyntheticNodeColumn[] = []
): SyntheticNodeColumn[] =>
  columns.map((c, index) => {
    const previouslyMaskedColumn = maskedColumns.find((mC) =>
      isSameColumnMasked(c, mC)
    );
    const maskedColumn: SyntheticNodeColumn = {
      dataType: c.dataType,
      id: c.id,
      index,
      maskType: previouslyMaskedColumn
        ? previouslyMaskedColumn.maskType
        : mapColumnDataTypeToMaskType(c.dataType),
      shouldMaskColumn: true,
    };
    return maskedColumn;
  });
