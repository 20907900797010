import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import { useMemo } from "react";
import { useApiCore } from "contexts";

interface ApiTokenActionsHookResult {
  createApiToken: (tokenName: string) => Promise<string>;
  revokeApiToken: (token: string) => Promise<void>;
}

const useApiTokenActions = (): ApiTokenActionsHookResult => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const { getSessionV2 } = useApiCore();
  const { mutate: createApiTokenMutation } = useMutation({
    mutationFn: async (tokenName: string) => {
      try {
        const session = await getSessionV2();
        return await session.createApiToken(tokenName);
      } catch (error) {
        throw new Error(`Generating API token failed: ${error}`);
      }
    },
    onError: (error) => {
      enqueueSnackbar(error.message, {
        persist: true,
        variant: "error",
      });
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ["apiTokens"] });
      return data;
    },
  });
  const { mutate: revokeApiTokenMutation } = useMutation({
    mutationFn: async (token: string) => {
      try {
        const session = await getSessionV2();
        return await session.deleteApiToken(token);
      } catch (error) {
        throw new Error(`Revoking API token failed: ${error}`);
      }
    },
    onError: (error) => {
      enqueueSnackbar(error.message, {
        persist: true,
        variant: "error",
      });
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ["apiTokens"] });
      return data;
    },
  });
  return useMemo(
    () => ({
      createApiToken:
        createApiTokenMutation as ApiTokenActionsHookResult["createApiToken"],
      revokeApiToken:
        revokeApiTokenMutation as ApiTokenActionsHookResult["revokeApiToken"],
    }),
    [createApiTokenMutation, revokeApiTokenMutation]
  );
};

export default useApiTokenActions;
