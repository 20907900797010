import { Navigate, useResolvedPath, useRoutes } from "react-router-dom";
import {
  DataPartnerPortal,
  DataPartnerPortalTabs,
} from "features/dataPartnerPortal";

const DataPartnerPortalPage: React.FC = () => {
  const resolvedPath = useResolvedPath("");
  const url = resolvedPath.pathname;

  const element = useRoutes([
    {
      element: (
        <DataPartnerPortal
          activeTab={DataPartnerPortalTabs.DATAROOMS}
          baseUrl={url}
        />
      ),
      path: DataPartnerPortalTabs.DATAROOMS,
    },
    {
      element: (
        <DataPartnerPortal
          activeTab={DataPartnerPortalTabs.CONFIGURATIONS}
          baseUrl={url}
        />
      ),
      path: DataPartnerPortalTabs.CONFIGURATIONS,
    },
    {
      element: <Navigate to={`${url}/${DataPartnerPortalTabs.DATAROOMS}`} />,
      path: "/",
    },
    {
      element: <Navigate to={`${url}/${DataPartnerPortalTabs.DATAROOMS}`} />,
      path: "*",
    },
  ]);

  return element;
};

DataPartnerPortalPage.displayName = "DataPartnerPortalPage";

export default DataPartnerPortalPage;
