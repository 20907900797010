import {
  useOrganizationLicenseQuery,
  useUpdateOrganizationLicenseMutation,
} from "@decentriq/graphql/dist/hooks";
import { OrganizationState } from "@decentriq/graphql/dist/types";
import { Box, Chip, FormControl, FormLabel, Option, Select } from "@mui/joy";
import capitalize from "lodash/capitalize";
import { memo, useCallback } from "react";
import { CommonSnackbarOrigin, useGeneralSnackbar, useUserRole } from "hooks";
import { getEffectiveErrorMessage } from "utils";
import OrganizationLicenseTypesTooltip from "./OrganizationLicenseTypesTooltip";

interface OrganizationLicenseEditorProps {
  organizationId: string;
}

const OrganizationLicenseEditor: React.FC<OrganizationLicenseEditorProps> = ({
  organizationId,
}) => {
  const { enqueueSnackbar } = useGeneralSnackbar({
    origin: CommonSnackbarOrigin.ADMIN,
  });
  const { isSuperAdmin } = useUserRole();
  const { data: organizationLicenseData } = useOrganizationLicenseQuery({
    variables: { organizationId },
  });
  const { state } = organizationLicenseData?.organization || {};
  const [updateOrganizationLicenseMutation] =
    useUpdateOrganizationLicenseMutation({
      onCompleted: () => {
        enqueueSnackbar("Organization licensing has been updated");
      },
    });
  const updateOrganizationLicense = useCallback(
    async (value: string) => {
      try {
        await updateOrganizationLicenseMutation({
          update: (cache) => {
            cache.modify({
              fields: {
                state: () => {
                  return value;
                },
              },
              id: cache.identify({
                __typename: "Organization",
                id: organizationId,
              }),
            });
          },
          variables: {
            organizationId,
            state: value as OrganizationState,
          },
        });
      } catch (error) {
        enqueueSnackbar("Organization license status could not be updated.", {
          context: getEffectiveErrorMessage(error),
          persist: true,
          variant: "error",
        });
      }
    },
    [organizationId, enqueueSnackbar, updateOrganizationLicenseMutation]
  );
  const ORGANIZATION_STATE_MENU_ITEMS = [
    {
      disabled:
        state === OrganizationState.Passive ||
        state === OrganizationState.Subscribed,
      title: OrganizationState.Subscribed,
      value: OrganizationState.Subscribed,
    },
    {
      disabled:
        state === OrganizationState.Passive ||
        state === OrganizationState.Suspended ||
        state === OrganizationState.Archived,
      title: OrganizationState.Suspended,
      value: OrganizationState.Suspended,
    },
    {
      disabled:
        state === OrganizationState.Passive ||
        state === OrganizationState.Archived,
      title: OrganizationState.Trial,
      value: OrganizationState.Trial,
    },
    {
      disabled: true,
      title: OrganizationState.Passive,
      value: OrganizationState.Passive,
    },
  ];
  return (
    <FormControl sx={{ mb: 1 }}>
      <FormLabel>
        License status
        {isSuperAdmin && (
          <span style={{ verticalAlign: "middle", whiteSpace: "nowrap" }}>
            &nbsp;
            <OrganizationLicenseTypesTooltip />
          </span>
        )}
      </FormLabel>
      {isSuperAdmin ? (
        <Select<OrganizationState>
          defaultValue={state}
          onChange={(event, value) => {
            if (value) {
              updateOrganizationLicense(value);
            }
          }}
          renderValue={({ value }) => {
            if (state === OrganizationState.Archived) {
              return capitalize(OrganizationState.Archived);
            }
            return capitalize(value || state);
          }}
          value={state}
        >
          {ORGANIZATION_STATE_MENU_ITEMS.map(({ disabled, title, value }) => (
            <Option disabled={disabled} key={value} value={value}>
              {capitalize(title)}
            </Option>
          ))}
        </Select>
      ) : (
        <Box>
          <Chip>{state?.toUpperCase()}</Chip>
        </Box>
      )}
    </FormControl>
  );
};

OrganizationLicenseEditor.displayName = "OrganizationLicenseEditor";

export default memo(OrganizationLicenseEditor);
