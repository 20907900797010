import { useAuth0 } from "@auth0/auth0-react";
import { useLocation } from "react-router-dom";

interface SignupWrapperProps {
  children?: React.ReactNode;
}

const SignupWrapper: React.FC<SignupWrapperProps> = ({ children }) => {
  const { pathname } = useLocation();
  const { loginWithRedirect } = useAuth0();
  if (pathname.startsWith("/signup")) {
    loginWithRedirect({
      authorizationParams: {
        screen_hint: "signup",
      },
    });
  } else {
    return children;
  }
};
SignupWrapper.displayName = "SignupWrapper";

export default SignupWrapper;
